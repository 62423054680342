import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  useTheme,
} from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import getDataTypeIcon from '@/utils/get-data-type-icon'

type FieldListItemProps = {
  field: ProjectGridField
  children?: React.ReactNode
  includeIcon?: boolean
  indented?: boolean
  isButton?: boolean
  selected?: boolean // Only used when isButton is true
  onSelectField?: () => void // Only used when isButton is true
  sx?: SxProps
}

export default function FieldListItem({
  field,
  children,
  includeIcon = true,
  indented,
  isButton,
  selected,
  onSelectField,
  sx,
}: FieldListItemProps) {
  const theme = useTheme()
  const Icon = getDataTypeIcon(field.project_grid_field_type?.code)
  const commonProps = {
    sx: {
      ml: indented ? 3 : undefined,
      borderLeft: indented ? `solid 1px ${theme.palette.divider}` : undefined,
      ...sx,
    },
  }

  const allChildren = (
    <>
      {includeIcon && (
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Icon fontSize="small" />
        </ListItemIcon>
      )}
      <ListItemText>{field.name}</ListItemText>
      {children}
    </>
  )

  return isButton ? (
    <ListItemButton
      selected={selected}
      onClick={() => {
        onSelectField?.()
      }}
      {...commonProps}
    >
      {allChildren}
    </ListItemButton>
  ) : (
    <ListItem {...commonProps}>{allChildren}</ListItem>
  )
}
