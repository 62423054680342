import {
  Stack,
  Box,
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import SRTextField from './SRTextField'
import { ReactNode, useState } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { FormValues } from './SalesRabbitDemo'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

type SRPowerConsumptionProps = {
  formValues: FormValues
  updateFormValue: (key: string, value: string) => void
  children: ReactNode
}

export default function SRPowerConsumption({
  formValues,
  updateFormValue,
  children,
}: SRPowerConsumptionProps) {
  const [type, setType] = useState('power')
  const [interval, setInterval] = useState('monthly')

  return (
    <Stack spacing={2}>
      {children}
      <Box>
        <FormControl>
          <FormLabel id="type-group">
            <Typography variant="body2">Type</Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="type-group"
            name="type-group"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <FormControlLabel
              value="power"
              control={<Radio />}
              label="Power Usage"
            />
            <FormControlLabel
              value="bill"
              control={<Radio />}
              label="Bill ($)"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <FormControl>
        <FormLabel id="interval-group">
          <Typography variant="body2">Interval</Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="interval-group"
          name="interval-group"
          value={interval}
          onChange={(e) => setInterval(e.target.value)}
        >
          <FormControlLabel value="annual" control={<Radio />} label="Annual" />
          <FormControlLabel
            value="monthly"
            control={<Radio />}
            label="Monthly"
          />
        </RadioGroup>
      </FormControl>

      {interval === 'annual' ? (
        <SRTextField
          label={`Annual Power Usage (${type === 'power' ? 'kWh' : '$'})`}
          value={
            type === 'power'
              ? formValues.annualKwh.value
              : formValues.annualCost.value
          }
          fromDocument={
            type === 'power'
              ? formValues.annualKwh.fromDocument
              : formValues.annualCost.fromDocument
          }
          onChange={({ target }) =>
            updateFormValue(
              type === 'power' ? 'annualKwh' : 'annualCost',
              target.value,
            )
          }
        />
      ) : (
        <Grid2 container spacing={2}>
          {months.map((month) => {
            const monthValues =
              formValues[`${month}${type === 'power' ? 'Kwh' : 'Cost'}`]
            return (
              <Grid2 key={month} sm={3} xs={4}>
                <SRTextField
                  label={`${month} ${type === 'power' ? '(kWh)' : '($)'}`}
                  value={monthValues.value}
                  fromDocument={monthValues.fromDocument}
                  onChange={({ target }) =>
                    updateFormValue(
                      `${month}${type === 'power' ? 'Kwh' : 'Cost'}`,
                      target.value,
                    )
                  }
                />
              </Grid2>
            )
          })}
        </Grid2>
      )}
    </Stack>
  )
}
