import { BulkResponse, BulkResponseError } from '@/types/bulk-response'

export default function batchErrorHandler<ItemType>(
  results: BulkResponse<ItemType>,
) {
  const errorResults = results.filter((result) => result.errors)
  errorResults.forEach((result) => {
    const entries = Object.entries(result.errors) as [
      string,
      BulkResponseError[],
    ][]
    entries.forEach(([propertyKey, errors]) => {
      errors.forEach((error) => {
        console.error(
          `Batch Create/Update/Delete Error: \n\tStatus: ${result.status_code} \n\tStatus Text: ${result.status_text} \n\tAction: ${result.action} \n\tKey: ${propertyKey} \n\tCode: ${error.code}\n\tMessage: ${error.message} \n\tItem ID: ${result.id}`,
        )
      })
    })
  })
  return errorResults
}
