import PageTitle from '@/components/PageTitle'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import OrganizationsDetails from '@/components/organizations/OrganizationsDetails'

export default function OrganizationsPage() {
  return (
    <>
      <PageTitle>Organizations</PageTitle>
      <Breadcrumb label="Organizations" url="/settings/organizations" />
      <OrganizationsDetails />
    </>
  )
}
