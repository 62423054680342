import IntegerField from '@/components/inputs/IntegerField'
import DecimalField from '@/components/inputs/DecimalField'
import { TextField, Typography } from '@mui/material'
import {
  MRT_Cell,
  MRT_Column,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table'
import {
  DataListColumnType,
  DataListEntry,
  DataListEntryCellValue,
} from '@/types/data-lists'
import DateTimeValueInput from '@/components/inputs/DateTimeValueInput'
import useTableNavigation from './useTableNavigation'
import { CellData, RowData } from './constructTableRows'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useUpdateDataListEntryCellValue } from '@/service-library/hooks/data-list-entry-cell-values'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import queryKeys from '@/service-library/query-keys'
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query'
import PaginatedResponse from '@/types/paginated-response'
import { useCreateDataListEntryCell } from '@/service-library/hooks/data-list-entry-cells'
import SmartAddressEditDialog from './SmartAddressEditDialog'

type EntryCellEditInputProps = {
  columnId: string
  name: string
  cell: MRT_Cell<RowData>
  column: MRT_Column<RowData>
  row: MRT_Row<RowData>
  table: MRT_TableInstance<RowData>
  createNewRow: () => void
  dataListColumnType: DataListColumnType
  listQueryKey: QueryKey
}

const smartAddressTypeId = '0190175d-2a9c-e04e-e440-9ed3827d5c82'

export default function EntryCellEditInput({
  columnId,
  name,
  cell,
  row,
  table,
  createNewRow,
  dataListColumnType,
  listQueryKey,
}: EntryCellEditInputProps) {
  const navigate = useTableNavigation(cell, row, table, createNewRow)
  const queryClient = useQueryClient()

  const { createDataListEntryCell } = useCreateDataListEntryCell({
    sideEffectQueryKeys: [queryKeys.dataListEntries.lists()],
    onMutate: (newCell) => {
      // This is awful, and normally the service library handles this for us, but this data is very layered
      queryClient.setQueryData<InfiniteData<PaginatedResponse<DataListEntry>>>(
        listQueryKey,
        (oldData) => {
          if (!oldData) return
          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                results: page.results.map((entry) => {
                  if (entry.id !== newCell.data_list_entry_id) return entry
                  return {
                    ...entry,
                    data_list_entry_cells: [
                      ...entry.data_list_entry_cells,
                      newCell,
                    ],
                  }
                }),
              }
            }),
          }
        },
      )
    },
    onError: () => {
      showErrorSnackbar('Failed to update cell value.')
    },
  })

  const { updateDataListEntryCellValue } = useUpdateDataListEntryCellValue({
    sideEffectQueryKeys: [queryKeys.dataListEntries.lists()],
    onMutate: (updatedCellValue) => {
      // This is awful, and normally the service library handles this for us, but this data is very layered
      queryClient.setQueryData<InfiniteData<PaginatedResponse<DataListEntry>>>(
        listQueryKey,
        (oldData) => {
          if (!oldData) return
          return {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                results: page.results.map((entry) => {
                  return {
                    ...entry,
                    data_list_entry_cells: entry.data_list_entry_cells.map(
                      (cell) => {
                        if (
                          cell.id !== updatedCellValue.data_list_entry_cell_id
                        )
                          return cell
                        return {
                          ...cell,
                          data_list_entry_cell_values:
                            cell.data_list_entry_cell_values.map(
                              (cellValue) => {
                                if (cellValue.id !== updatedCellValue.id)
                                  return cellValue
                                return updatedCellValue
                              },
                            ),
                        }
                      },
                    ),
                  }
                }),
              }
            }),
          }
        },
      )
    },
    onError: () => {
      showErrorSnackbar('Failed to update cell value.')
    },
  })

  function handleSubmit(value: string, keepEditing?: boolean) {
    const { dataEntryId, entryCellValueId, values, entryCellId } = row.original[
      columnId
    ] as CellData

    if (entryCellValueId) {
      updateDataListEntryCellValue({
        ...values[0],
        data_list_entry_cell_id: entryCellId as string,
        value,
      })
    } else if (value) {
      const newCellId = generateUuid()
      createDataListEntryCell({
        id: newCellId,
        data_list_column_id: columnId,
        data_list_entry_id: dataEntryId,
        data_list_entry_cell_values: [
          {
            id: generateUuid(),
            data_list_entry_cell_id: newCellId,
            value,
            sort_order: 100000,
          },
        ],
        sort_order: 0,
      })
    }
    if (!keepEditing) table.setEditingCell(null)
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    handleSubmit(e.target.value)
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    const targetValue = (e.target as HTMLInputElement).value
    const onComplete = () => handleSubmit(targetValue, true)
    switch (e.code) {
      case 'Enter':
      case 'NumpadEnter':
        handleSubmit(targetValue)
        navigate.down({ createNewRowOnLast: true })
        break

      case 'Tab':
        // Prevent native focus switch so we can control where focus goes
        e.preventDefault()
        e.stopPropagation()

        if (e.shiftKey) {
          navigate.left({ onComplete })
        } else {
          navigate.right({ onComplete })
        }
        break

      case 'ArrowUp':
        navigate.up({ onComplete })
        break

      case 'ArrowDown':
        navigate.down({ onComplete })
        break
    }
  }

  const type = dataListColumnType.postgres_data_type

  if (type === 'int') {
    return (
      <IntegerField
        autoFocus
        value={parseInt(cell.getValue<DataListEntryCellValue[]>()?.[0]?.value)}
        InputProps={{
          onBlur: handleBlur,
          onKeyDown: handleKeyDown,
        }}
        size="small"
        variant="outlined"
        sx={{
          width: '100%',
        }}
      />
    )
  }

  if (type === 'decimal') {
    return (
      <DecimalField
        autoFocus
        value={parseFloat(
          cell.getValue<DataListEntryCellValue[]>()?.[0]?.value,
        )}
        InputProps={{
          onBlur: handleBlur,
          onKeyDown: handleKeyDown,
        }}
        size="small"
        variant="outlined"
        sx={{
          width: '100%',
        }}
      />
    )
  }

  if (type === 'date' || type === 'datetime' || type === 'time') {
    const dateISOString = cell.getValue<DataListEntryCellValue[]>()?.[0]?.value
    const dayjsFormat = dayjs(dateISOString)
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimeValueInput
          onKeyDown={(e) => handleKeyDown(e)}
          value={dayjsFormat}
          handleSubmit={handleSubmit}
          type={type}
          onClose={() => table.setEditingCell(null)}
          variant="outlined"
          autoFocus
          sx={{
            width: '100%',
          }}
          // DateTimeValueInput handles blurring internally
        />
      </LocalizationProvider>
    )
  }

  const value = cell.getValue<DataListEntryCellValue[]>()
  const textValue = value?.[0]?.value || ''

  if (dataListColumnType.id === smartAddressTypeId) {
    return (
      <>
        <Typography noWrap>{textValue}</Typography>
        <SmartAddressEditDialog
          value={textValue}
          handleSubmit={handleSubmit}
          onClose={() => {
            table.setEditingCell(null)
          }}
        />
      </>
    )
  }

  return (
    <TextField
      name={name}
      size="small"
      variant="outlined"
      defaultValue={textValue}
      hiddenLabel
      autoFocus
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      sx={{
        width: '100%',
      }}
    />
  )
}
