import { forwardRef } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

export type DecimalFieldProps = {
  value?: number | string
  onChange?: (newValue: number | '') => void // allowing to pass an empty string so final value can be restored
} & Omit<Partial<NumberFormatProps>, 'onChange' | 'size'> &
  Omit<TextFieldProps, 'onChange' | 'value'>

const DecimalField = forwardRef(function DecimalField(
  { value, onChange, ...rest }: DecimalFieldProps,
  ref,
) {
  return (
    <NumberFormat
      // @ts-expect-error -- Ignore for now
      customInput={TextField}
      isNumericString
      decimalSeparator={'.'}
      value={value}
      onChange={(e) => {
        if (onChange) {
          const newValue = parseFloat(e.target.value)
          onChange(isNaN(newValue) ? '' : newValue)
        }
      }}
      inputRef={ref}
      {...rest}
    />
  )
})

export default DecimalField
