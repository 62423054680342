import { Box, Stack } from '@mui/material'
import { DocumentChip } from '@/types/documents'
import { useDocumentImagePageContext } from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'

type FieldMiniChipsProps = {
  chips: DocumentChip[]
  hidden?: boolean
}

export default function FieldMiniChips({ chips, hidden }: FieldMiniChipsProps) {
  const { imagePage } = useDocumentImagePageContext()
  const cappedChips = chips.slice(0, 50)

  return (
    <Stack
      direction="row"
      spacing={0.25}
      useFlexGap
      sx={{
        justifyContent: 'flex-end',
        pointerEvents: 'none',
        flexWrap: 'wrap',
        maxWidth: '100%',
        visibility: hidden ? 'hidden' : undefined,
      }}
    >
      {cappedChips.map((chip) => {
        const chipIsInCurrentPage = imagePage === chip.document_page_number
        return (
          <Box
            key={chip.id}
            id={chip.id}
            sx={{
              height: '5px',
              width: '10px',
              backgroundColor: (theme) =>
                chipIsInCurrentPage
                  ? theme.palette.primary.main
                  : theme.palette.neutral.main,
              borderRadius: 4,
            }}
            tabIndex={-1}
          />
        )
      })}
    </Stack>
  )
}
