/* eslint-disable @typescript-eslint/no-explicit-any */
import useZerapixTable, { ZerapixTableOptions } from './useZerapixTable'
import useInfiniteQuery from '@/services/hooks/useInfiniteQuery'

type UseInfiniteScrollTableOptions<DataType extends Record<string, any>> =
  ZerapixTableOptions<DataType> & {
    query: ReturnType<typeof useInfiniteQuery>
  }

export default function useInfiniteScrollZerapixTable<
  DataType extends Record<string, any>,
>({ query, ...options }: UseInfiniteScrollTableOptions<DataType>) {
  const {
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isInitialLoading,
  } = query

  return useZerapixTable<DataType>({
    ...options,
    state: {
      ...options.state,
      showSkeletons: isLoading,
      showProgressBars:
        isInitialLoading || isFetchingNextPage || isFetchingPreviousPage,
    },
  })
}
