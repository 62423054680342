import generateUuid from '@/utils/generate-uuid'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export type NodeProblem = {
  nodeId: string
  handleId?: string
  id: string
  message: string
  type: string
  severity: 'warning' | 'error'
  unique?: boolean
}

type ProblemsContextValue = {
  problems: NodeProblem[]
  addProblem: (newProblem: Omit<NodeProblem, 'id'>) => NodeProblem
  removeProblem: (problem: NodeProblem) => void
  setProblems: Dispatch<SetStateAction<NodeProblem[]>>
}

const ProblemsContext = createContext<ProblemsContextValue>(
  {} as ProblemsContextValue,
)

export const useNodeProblems = () => useContext(ProblemsContext)

type ProblemsProviderProps = {
  children: ReactNode
}

export default function ProblemsProvider({ children }: ProblemsProviderProps) {
  const [problems, setProblems] = useState<NodeProblem[]>([])

  const addProblem = useCallback((newProblem: Omit<NodeProblem, 'id'>) => {
    const newProb = { ...newProblem, id: generateUuid() }
    setProblems((curr) => {
      if (newProblem.unique) {
        const exists = curr.find(({ nodeId, handleId, type }) => {
          return (
            nodeId === newProb.nodeId &&
            handleId === newProb.handleId &&
            type === newProb.type
          )
        })
        if (exists) return curr
      }
      return [...curr, newProb]
    })
    return newProb
  }, [])

  const removeProblem = useCallback((problem: NodeProblem) => {
    setProblems((curr) => {
      return curr.filter((prob) => prob.id !== problem.id)
    })
  }, [])

  const value = useMemo(
    () => ({ problems, addProblem, removeProblem, setProblems }),
    [addProblem, problems, removeProblem],
  )

  return (
    <ProblemsContext.Provider value={value}>
      {children}
    </ProblemsContext.Provider>
  )
}
