import { useMemo, useRef } from 'react'
import { ViewAgenda, Visibility } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import ValidationFlagsButton from '@/components/flags/ValidationFlagsButton'
import { ComparisonModeOptions } from './ComparisonView'

export type OptionsKeyType = keyof typeof optionsForValidation

const optionsForValidation = {
  relevant: 'Relevant Fields',
  all: 'All Fields',
  flagged: 'Fields with Flags',
}

const optionsForComparison = {
  image: 'Image',
  all_fields: 'All Fields',
  relevant_fields: 'Relevant Fields',
}

type VisibleFieldsControlProps = {
  documentView: WorkflowState['default_document_view']
}

export default function VisibleFieldsControl({
  documentView,
}: VisibleFieldsControlProps) {
  const overlay = useOverlay()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const [selectedOptionKey, setSelectedOptionKey] =
    useLocalStorage<OptionsKeyType>(
      'validation-visible-fields-option',
      'relevant',
    )

  const [selectedComparisonMode, setSelectedComparisonMode] =
    useLocalStorage<ComparisonModeOptions>('comparison-selected-mode', 'image')

  const isValidationView = documentView === 'validation'
  const { options, buttonLabel } = useMemo(
    () =>
      isValidationView
        ? {
            options: optionsForValidation,
            buttonLabel: optionsForValidation[selectedOptionKey],
          }
        : {
            options: optionsForComparison,
            buttonLabel: optionsForComparison[selectedComparisonMode],
          },
    [isValidationView, selectedComparisonMode, selectedOptionKey],
  )

  return (
    <>
      {isValidationView && (
        <ValidationFlagsButton
          selectedOptionKey={selectedOptionKey}
          setSelectedOptionKey={setSelectedOptionKey}
        />
      )}
      <Button
        startIcon={
          isValidationView ? (
            <Visibility />
          ) : (
            <ViewAgenda
              sx={{
                transform: 'rotate(90deg)',
              }}
            />
          )
        }
        onClick={overlay.toggle}
        ref={anchorRef}
        variant="text"
      >
        {buttonLabel}
      </Button>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
        elevation={16}
      >
        {Object.entries(options).map(([key, value]) => (
          <MenuItem
            dense
            key={key}
            onClick={() => {
              isValidationView
                ? setSelectedOptionKey(key as OptionsKeyType)
                : setSelectedComparisonMode(key as ComparisonModeOptions)
              overlay.close()
            }}
            selected={
              isValidationView
                ? key === selectedOptionKey
                : key === selectedComparisonMode
            }
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
