import PageTitle from '@/components/PageTitle'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material'

export default function TeamsPage() {
  return (
    <Container maxWidth="md" sx={{ pb: 2 }}>
      <PageTitle>Teams</PageTitle>
      <Breadcrumb label="Teams" url="/settings/teams" />
      <Outlet />
    </Container>
  )
}
