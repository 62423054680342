import {
  Autocomplete,
  AutocompleteProps,
  ListItem,
  TextField,
  TextFieldProps,
} from '@mui/material'

export type BaseDataType = {
  id: string
  name: string
}

type ListAutocompleteProps<DataType> = {
  selected: DataType | null
  setSelected: (newItem: DataType) => void
  options: DataType[]
  helperText?: string
  variant?: TextFieldProps['variant']
  label: string
  inputSize?: 'medium' | 'small'
  textFieldProps?: TextFieldProps
} & Partial<AutocompleteProps<DataType, false, true | false, false, 'div'>>

export default function ListAutocomplete<DataType extends BaseDataType>({
  selected,
  setSelected,
  options,
  helperText,
  variant = 'filled',
  label,
  inputSize,
  textFieldProps,
  ...props
}: ListAutocompleteProps<DataType>) {
  return (
    <Autocomplete<DataType, false, true | false, false>
      fullWidth
      autoComplete
      autoSelect
      autoHighlight
      value={selected}
      disableClearable
      onChange={(event, value) => setSelected(value as DataType)}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) =>
        value.id ? option.id === value.id : true
      }
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            helperText={helperText}
            label={label}
            size={inputSize}
            variant={variant}
            InputLabelProps={{ shrink: true }}
            {...textFieldProps}
          />
        )
      }}
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id} dense>
            {option.name}
          </ListItem>
        )
      }}
      {...props}
    />
  )
}
