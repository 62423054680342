import { useLocation } from 'react-router-dom'

export default function useLocationHelpers() {
  const { pathname, search } = useLocation()

  function appendCurrentParamsToUrl(url: string) {
    return url.includes('?')
      ? `${url}${search.substring(1)}`
      : `${url}${search}`
  }

  function getCurrentPathWithParams() {
    return `${pathname}${search}`
  }

  return { appendCurrentParamsToUrl, getCurrentPathWithParams }
}
