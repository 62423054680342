import { getDocumentOriginalFileData } from '@/services/documents'
import { showErrorSnackbar, showInfoSnackbar } from './snackbars'
import { closeSnackbar } from 'notistack'

export default function downloadFile(fileUrl: string, fileName: string) {
  return fetch(fileUrl)
    .then((response) => {
      if (!response.ok) throw response
      return response.blob()
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = window.document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      window.document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    })
}

export function handleDownloadOriginalDocument(documentId: string) {
  showInfoSnackbar('Preparing Download...', { key: 'downloading' })
  getDocumentOriginalFileData({
    documentId: documentId as string,
  }).then(({ data }) => {
    const { file_url_get, file_ext, name } = data
    const documentNameWithExtension =
      !file_ext ||
      // including the period in case for some weird reason the name (without the extension) actually ends like that
      name.slice(-(file_ext.length + 1)).toLowerCase() === `.${file_ext}`
        ? name
        : `${name}.${file_ext}`
    downloadFile(file_url_get as string, documentNameWithExtension).catch(
      (e) => {
        showErrorSnackbar(
          e.status === 404
            ? 'There was a problem with this document. Please contact support.'
            : 'Failed to download document. Please try again later.',
          {
            onEnter: () => {
              closeSnackbar('downloading')
            },
          },
        )
      },
    )
  })
}
