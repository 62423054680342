import { ReactNode, useContext, createContext, useMemo } from 'react'
import useLogixNodeTypeSections from '@/services/hooks/useLogixNodeTypeSections'
import useLogixNodeTypes from '@/services/hooks/useLogixNodeTypes'
import { LogixNodeType, LogixNodeTypeSection } from '@/types/logix'

type NodeIndexProviderValue = {
  categories: [LogixNodeTypeSection, LogixNodeType[]][]
  nodeTypes: LogixNodeType[]
}

const NodeIndexContext = createContext<NodeIndexProviderValue>(
  {} as NodeIndexProviderValue,
)

type NodeIndexProviderProps = {
  children: ReactNode
}

export const useNodeIndex = () => useContext(NodeIndexContext)

export default function NodeIndexProvider({
  children,
}: NodeIndexProviderProps) {
  const { sections = [] } = useLogixNodeTypeSections()
  const { nodeTypes = [] } = useLogixNodeTypes()

  const value = useMemo(() => {
    return {
      categories: sections.map((section) => [
        section,
        nodeTypes.filter((nodeType) => nodeType.section_id === section.id),
      ]) as NodeIndexProviderValue['categories'],
      nodeTypes,
    }
  }, [nodeTypes, sections])

  return (
    <NodeIndexContext.Provider value={value}>
      {children}
    </NodeIndexContext.Provider>
  )
}
