import { createContext, ReactNode, useContext } from 'react'
import useLocalStorage from '@/hooks/useLocalStorage'

type DemoModeContextValue = ReturnType<typeof useLocalStorage<boolean>>

const DemoModeContext = createContext<DemoModeContextValue>(
  {} as DemoModeContextValue,
)

export const useDemoModeContext = () => useContext(DemoModeContext)

type DemoModeProviderProps = {
  children: ReactNode
}

export default function DemoModeProvider({ children }: DemoModeProviderProps) {
  const [demoMode, setDemoMode] = useLocalStorage('demo-mode', false)
  return (
    <DemoModeContext.Provider value={[demoMode, setDemoMode]}>
      {children}
    </DemoModeContext.Provider>
  )
}
