import axios from '../auth-v2-axios'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'

import queryKeys from '../query-keys'
import useMutation, { UseMutationOptions } from '../core-hooks/useMutation'
import useBatchedRequest from '../core-hooks/useBatchedRequest'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { TeamVisibleOrg } from '@/types/team-visible-orgs'

const service = buildService<TeamVisibleOrg>({
  pathCategory: 'team_visible_orgs',
})

export function useGetTeamVisibleOrg({
  id,
  ...options
}: UseGetDetailOptions<TeamVisibleOrg>) {
  const query = useGetDetail<TeamVisibleOrg>({
    id,
    queryKey: queryKeys.teamVisibleOrgs.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    teamVisibleOrg: query.data,
    ...query,
  }
}

export function useCreateTeamVisibleOrg(
  options: UseCreateDetailOptions<TeamVisibleOrg> = {},
) {
  const mutation = useCreateDetail<TeamVisibleOrg>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createTeamVisibleOrg: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateTeamVisibleOrg(
  options: UseUpdateDetailOptions<TeamVisibleOrg> = {},
) {
  const mutation = useUpdateDetail<TeamVisibleOrg>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateTeamVisibleOrg: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteTeamVisibleOrg(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<TeamVisibleOrg>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteTeamVisibleOrg: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetTeamVisibleOrgs({
  filters,
  ...options
}: UseGetListOptions<TeamVisibleOrg> = {}) {
  const query = useGetList<TeamVisibleOrg>({
    filters,
    queryKey: queryKeys.teamVisibleOrgs.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    teamVisibleOrgs: query.allData,
    ...query,
  }
}

export function useUpdateTeamVisibleOrgs(
  options: UseUpdateListOptions<TeamVisibleOrg> = {},
) {
  const mutation = useUpdateList<TeamVisibleOrg>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateTeamVisibleOrgs: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteTeamVisibleOrgs({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<TeamVisibleOrg>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteTeamVisibleOrgs: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Delete By Team And Org
 * Used to remove orgs from a team.
 */
type MutateVariables = Omit<TeamVisibleOrg, 'id'>[]

export function useDeleteByTeamAndOrg({
  sideEffectQueryKeys,
  onIdle,
  ...options
}: UseMutationOptions<void, unknown, MutateVariables> & {
  onIdle?: (data: void) => void
} = {}) {
  const batchedRequest = useBatchedRequest<TeamVisibleOrg, void>({
    sideEffectQueryKeys,
    requestFn: (teamOrgsToRemove) =>
      axios.delete<{ team_id: string; org_id: string }[], void>(
        `/v2/pd/team_visible_orgs/delete_by_teams_and_orgs`,
        {
          data: teamOrgsToRemove,
        },
      ),
    onIdle,
  })

  const query = useMutation<void, unknown, MutateVariables>({
    mutationFn: (teamOrgs) => {
      const teamOrgsWithFakeIds = teamOrgs.map((teamOrg) => ({
        id: teamOrg.org_id,
        ...teamOrg,
      }))
      return batchedRequest(teamOrgsWithFakeIds)
    },
    ...options,
  })
  return { ...query, deleteByTeamAndOrgs: query.mutate }
}
