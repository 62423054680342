import { useMemo, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import debounce from 'lodash.debounce'
import DecimalField from '@/components/inputs/DecimalField'
import { PropertyFieldProps } from './PropertyField'

type PropertyDecimalFieldProps = PropertyFieldProps

export default function PropertyDecimalField({
  propertyId,
  property,
  required,
  variant,
}: PropertyDecimalFieldProps) {
  const { field } = useController({ name: propertyId, rules: { required } })
  const [internalValue, setInternalValue] = useState(field.value)
  const { formState, setValue } = useFormContext()
  const { errors } = formState

  const { display } = property

  const debouncedFormUpdate = useMemo(
    () =>
      debounce((newValue) => {
        setValue(propertyId, newValue, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }, 200),
    [propertyId, setValue],
  )
  return (
    <DecimalField
      required={required}
      error={!!errors[propertyId]}
      label={display?.label || 'MISSING LABEL'}
      InputLabelProps={{ shrink: true }}
      helperText={display.description}
      variant={variant}
      {...field}
      // The props below must come after {...field} so it can override some of field's values
      onChange={(newValue) => {
        setInternalValue(newValue)
        debouncedFormUpdate(newValue)
      }}
      value={internalValue}
    />
  )
}
