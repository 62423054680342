import { useNavigate } from 'react-router-dom'
import { MRT_ColumnDef } from 'material-react-table'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { Team } from '@/types/teams'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import { useGetTeams } from '@/service-library/hooks/teams'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import InfiniteScrollTable from '@/components/zerapix-table/InfiniteScrollTable'
import useInfiniteScrollZerapixTable from '@/components/zerapix-table/useInfiniteScrollZerapixTable'
import AddEditTeamDialog from './AddEditTeamDialog'

const columns: MRT_ColumnDef<Team>[] = [
  {
    accessorKey: 'name',
    header: 'Team Name',
  },
]

export default function TeamsTable() {
  const { hasEditingPermission } = usePermission()
  const navigate = useNavigate()

  const { rootOrganization } = useRootOrganization()
  const query = useGetTeams({
    filters: {
      owner_org_id: rootOrganization.id,
      is_system_managed: 'false',
      limit: '25',
    },
    enabled: !!rootOrganization.id,
  })

  const { teams = [] } = query

  const addOverlay = useOverlay()

  const canAddTeam = hasEditingPermission('edit_teams')

  const table = useInfiniteScrollZerapixTable<Team>({
    query,
    columns,
    data: teams,
    title: 'Teams',
    initialState: {
      density: 'comfortable',
    },
    renderTableActions: canAddTeam
      ? () => (
          <Button
            onClick={addOverlay.open}
            startIcon={<AddIcon />}
            variant="text"
          >
            New Team
          </Button>
        )
      : undefined,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/settings/teams/${row.original.id}`)
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  })

  return (
    <>
      <InfiniteScrollTable<Team> query={query} table={table} />

      {canAddTeam && <AddEditTeamDialog overlay={addOverlay} />}
    </>
  )
}
