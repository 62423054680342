import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'

export default function FlagsFilter() {
  const { documentFilters, handleOnDocumentFilterChange } =
    useDocumentFiltersContext()

  const onChangeHandler = (flagCode: string, checked: boolean) => {
    handleOnDocumentFilterChange((prev) => {
      if (!prev) return { flagCodes: [flagCode], orgIds: [], teamIds: [] }

      const newFilters = { ...prev }
      if (checked) {
        newFilters.flagCodes = [...newFilters.flagCodes, flagCode]
      } else {
        newFilters.flagCodes = newFilters.flagCodes.filter(
          (id) => id !== flagCode,
        )
      }
      return newFilters
    })
  }

  return (
    documentFilters && (
      <Stack direction="column" spacing={1}>
        <Typography>Flags</Typography>
        <Stack spacing={0.5}>
          <FormControlLabel
            label="Has Errors"
            control={
              <Checkbox
                sx={{ padding: 0 }}
                color="error"
                checked={documentFilters.flagCodes.includes('error')}
                onChange={(_e, checked) => onChangeHandler('error', checked)}
              />
            }
            componentsProps={{ typography: { variant: 'body2' } }}
            sx={{ mx: 0, columnGap: 0.5 }}
          />
          <FormControlLabel
            label="Has Warnings"
            control={
              <Checkbox
                sx={{ padding: 0 }}
                color="warning"
                checked={documentFilters.flagCodes.includes('warning')}
                onChange={(_e, checked) => onChangeHandler('warning', checked)}
              />
            }
            componentsProps={{ typography: { variant: 'body2' } }}
            sx={{ mx: 0, columnGap: 0.5 }}
          />
        </Stack>
      </Stack>
    )
  )
}
