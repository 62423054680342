/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react'
import {
  MRT_Column,
  MRT_FilterOptionMenu,
  MRT_Header,
  MRT_Localization,
  MRT_TableInstance,
} from 'material-react-table'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  IconButton,
  InputAdornment,
  TextFieldProps,
  Tooltip,
} from '@mui/material'
import { FieldTypeName } from '@/types/fields'
import DateField from '@/components/validation/fields/DateField'
import DateTimeField from '@/components/validation/fields/DateTimeField'
import TimeField from '@/components/validation/fields/TimeField'

export type CustomFilterComponentProps<TData extends Record<string, any>> = {
  fieldTypeCode: FieldTypeName
  column: MRT_Column<TData>
  header: MRT_Header<TData>
  rangeFilterIndex?: number
  table: MRT_TableInstance<TData>
  width?: number
}

export default function CustomFilterComponent<
  TData extends Record<string, any>,
>({
  fieldTypeCode,
  column,
  header,
  table,
  rangeFilterIndex,
}: CustomFilterComponentProps<TData>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const isRange = rangeFilterIndex !== undefined

  const filterValue = column.getFilterValue()
  const value = (
    isRange ? (filterValue as string[])?.[rangeFilterIndex] : filterValue
  ) as string

  const onChange = (value: string) => {
    column.setFilterValue((prev: string[] | string) => {
      let newValue = Array.isArray(prev) ? [...prev] : prev
      if (isRange) {
        if (!Array.isArray(newValue)) {
          newValue = []
        }
        newValue[rangeFilterIndex] = value
      } else {
        newValue = value
      }
      return newValue || undefined
    })
  }

  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const filterFn = column.columnDef._filterFn || ''
  const filterModeFn = `filter${filterFn
    .charAt(0)
    .toUpperCase()
    .concat(filterFn.slice(1))}`

  const commonProps = {
    onChange,
    value,
    inputRef,
    margin: 'none' as TextFieldProps['margin'],
    FormHelperTextProps: {
      sx: {
        overflow: 'hidden',
        maxWidth: inputRef.current?.closest('div')?.clientWidth || 'auto',
        whiteSpace: 'nowrap',
      },
    },
    fullWidth: true,
    helperText:
      rangeFilterIndex === 0
        ? 'From'
        : rangeFilterIndex === 1
        ? 'To'
        : `Filter Mode: ${
            table.options.localization[filterModeFn as keyof MRT_Localization]
          }`,
    InputProps: {
      startAdornment:
        !isRange || rangeFilterIndex === 0 ? (
          <InputAdornment position="start">
            <Tooltip arrow title={table.options.localization.changeFilterMode}>
              <IconButton
                onClick={handleFilterMenuOpen}
                size="small"
                sx={{ height: '1.75rem', width: '1.75rem' }}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ) : null,
    },
    variant: 'standard' as TextFieldProps['variant'],
    minDate:
      isRange && rangeFilterIndex === 1
        ? (filterValue as string[])?.[0]
        : undefined,
    maxDate:
      isRange && rangeFilterIndex === 0
        ? (filterValue as string[])?.[1]
        : undefined,
  }

  let component
  switch (fieldTypeCode) {
    case 'date':
      component = <DateField {...commonProps} />
      break
    case 'time':
      component = <TimeField {...commonProps} />
      break
    case 'datetime':
      component = <DateTimeField {...commonProps} />
      break
    default:
      component = null
  }

  return component ? (
    <>
      {component}
      <MRT_FilterOptionMenu
        anchorEl={anchorEl}
        header={header}
        setAnchorEl={setAnchorEl}
        table={table}
        setFilterValue={onChange}
      />
    </>
  ) : null
}
