import { useMemo, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import debounce from 'lodash.debounce'
import { InputAdornment, Link, TextField, Typography } from '@mui/material'
import { PropertyFieldProps } from './PropertyField'

type PropertyTextFieldProps = PropertyFieldProps & {
  multiline?: boolean
}

export default function PropertyTextField({
  propertyId,
  property,
  required,
  variant,
  multiline,
}: PropertyTextFieldProps) {
  const { field } = useController({ name: propertyId, rules: { required } })
  const [internalValue, setInternalValue] = useState(field.value)
  const { formState } = useFormContext()
  const { errors } = formState

  const { display } = property

  const debouncedFormUpdate = useMemo(
    () => debounce(field.onChange, 200),
    [field.onChange],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.target.value)
    debouncedFormUpdate(event)
  }

  const description =
    property.code === 'regex' ? (
      <Typography variant="caption">
        {display.description}{' '}
        <Link
          href="https://regex101.com/"
          target="_blank"
          sx={{ textDecoration: 'none' }}
        >
          (Regex 101)
        </Link>
      </Typography>
    ) : (
      display.description
    )

  return (
    <TextField
      required={required}
      error={!!errors[propertyId]}
      label={display?.label || 'MISSING LABEL'}
      InputLabelProps={{ shrink: true }}
      helperText={description}
      variant={variant}
      {...field}
      // The props below must come after {...field} so it can override some of field's values
      InputProps={{
        startAdornment:
          property.code === 'regex' ? (
            <InputAdornment position="start">r&quot;</InputAdornment>
          ) : null,
        endAdornment:
          property.code === 'regex' ? (
            // Adding padding so it can be at the same level as the startAdornment which is pushed by the label
            <InputAdornment position="end" sx={{ pt: 2 }}>
              &quot;
            </InputAdornment>
          ) : null,
      }}
      onChange={handleChange}
      value={internalValue}
      multiline={multiline}
    />
  )
}
