import { ContentCategoryItem } from '@/types/content-categories'
import axios from './auth-v2-axios'
import PaginatedResponse from '@/types/paginated-response'

export function getContentCategoryItems(projectId: string) {
  const searchParams = new URLSearchParams({
    limit: '10000',
    project_id: projectId,
    fields__only: 'id,name',
  })
  return axios.get<PaginatedResponse<ContentCategoryItem>>(
    `/v2/pd/project_content_category_items?${searchParams}`,
  )
}

export function createContentCategoryItem(newItem: ContentCategoryItem) {
  return axios.post('/v2/pd/project_content_category_items', newItem)
}

export function updateContentCategoryItem(updatedItem: ContentCategoryItem) {
  return axios.patch(
    `/v2/pd/project_content_category_items/${updatedItem.id}`,
    updatedItem,
  )
}

export function deleteContentCategoryItem(id: string) {
  return axios.delete<void>(`/v2/pd/project_content_category_items/${id}`)
}
