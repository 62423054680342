import { useEffect, useMemo, useState } from 'react'
import {
  Card,
  ListItem,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { GroupOption } from '@/types/metrics'
import { Organization } from '@/types/organizations'
import { Project } from '@/types/projects'
import useMetricsSearchParams from '@/hooks/useMetricsSearchParams'
import { useGetProjects } from '@/service-library/hooks/projects'
import { useGetBillingEventMetrics } from '@/service-library/hooks/billing-events'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { convertToDate } from '@/utils/date-metrics'
import GeneralInfoBox from '@/components/data-visualization/GeneralInfoBox'
import ListAutocomplete from '@/components/list-autocomplete/ListAutocomplete'
import TimeBarChart from '@/components/charts/TimeBarChart'
import OrganizationPickerWithDialog from '@/components/organization-select/OrganizationPickerWithDialog'
import PageTitle from '@/components/PageTitle'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import MetricsCommonFilters from './MetricsCommonFilters'

type DocumentsProcessedProps = {
  onlyCustomers?: boolean
}

const labelMap: Record<string, string> = {
  count: 'Documents Processed',
  page_count: 'Pages Processed',
  avg_page_count: 'Average Page per Document',
}

export const allOrganizations = {
  id: 'all',
  name: 'All Organizations',
  color: 'blue',
  code: null,
  parent_org_id: null,
  org_type: null,
  org_type_id: null,
}

const allProjects: Project = {
  id: 'all',
  processing_active: false,
  setup_state: 'complete',
  source_folder_url: '',
  name: 'All Projects',
  color: 'blue',
  org_id: '',
  project_category_id: 'category_id',
}

export default function DocumentsProcessed({
  onlyCustomers,
}: DocumentsProcessedProps) {
  const theme = useTheme()
  const { values, updateValues } = useMetricsSearchParams()

  const startDateState = useState(convertToDate(values.from || 'start'))
  const endDateState = useState(convertToDate(values.to || 'end', false))
  const groupedByState = useState<GroupOption>(
    (values.grouped_by as GroupOption) || (onlyCustomers ? 'month' : 'day'),
  )

  const [internalBillingEventsData, setInternalBillingEventsData] = useState<
    typeof billingEventsData
  >([])

  const { rootOrganization } = useRootOrganization()
  const {
    organizations,
    isLoading: organizationsIsLoading,
    isFetchingAll,
  } = useAllOrganizations(
    onlyCustomers
      ? {
          filters: {
            customer_id__isnull: 'false',
            fields__include: 'customer,billing_events_summary',
            customer__fields__include: 'subscriptions',
            customer__subscriptions__fields__include: 'subscription_type',
          },
        }
      : {
          filters: {
            self_and_descendants_for_id: rootOrganization.id,
          },
        },
  )

  const defaultOrganization = useMemo(() => {
    if (organizations.length && values.org_id) {
      return (
        organizations.find(({ id }) => id === values.org_id) || allOrganizations
      )
    }
    return allOrganizations
  }, [organizations, values.org_id])

  const [selectedOrg, setSelectedOrg] =
    useState<Organization>(defaultOrganization)

  const { projects, isLoading: projectsIsLoading } = useGetProjects({
    refetchOnWindowFocus: false,
    filters: {
      org_id: rootOrganization.id,
      limit: '1000',
    },
  })

  const setUpProjects = projects.filter(
    (project) => project.setup_state === 'complete',
  )

  const defaultProject = useMemo(() => {
    if (!onlyCustomers && setUpProjects.length && values.project_id) {
      return (
        setUpProjects.find(({ id }) => id === values.project_id) || allProjects
      )
    }
    return allProjects
  }, [onlyCustomers, setUpProjects, values.project_id])

  const [selectedProject, setSelectedProject] =
    useState<Project>(defaultProject)

  const orgId = useMemo(() => {
    if (onlyCustomers || selectedOrg.id === allOrganizations.id)
      return undefined
    return selectedOrg.id
  }, [onlyCustomers, selectedOrg.id])

  const rootOrgIds = useMemo(() => {
    if (onlyCustomers) {
      return selectedOrg.id === allOrganizations.id
        ? organizations.map(({ id }) => id)
        : [selectedOrg.id]
    }
    return selectedOrg.id === allOrganizations.id
      ? [rootOrganization.id]
      : undefined
  }, [onlyCustomers, organizations, rootOrganization.id, selectedOrg.id])

  const { billingEventMetrics, isLoading } = useGetBillingEventMetrics({
    startDate: startDateState[0],
    endDate: endDateState[0],
    groupedBy: groupedByState[0],
    filters: {
      metadata__owner_org_id: orgId,
      metadata__project_id:
        selectedProject.id !== allProjects.id ? selectedProject.id : undefined,
      metadata__for_tree_on_owner_org_id__in: rootOrgIds?.join(),
    },

    enabled: onlyCustomers ? !organizationsIsLoading : undefined,
  })

  const billingEventsData = useMemo(
    () => billingEventMetrics?.results || [],
    [billingEventMetrics?.results],
  )

  useEffect(() => {
    if (!organizationsIsLoading) {
      setSelectedOrg(defaultOrganization)
    }
  }, [defaultOrganization, organizationsIsLoading])

  useEffect(() => {
    if (!projectsIsLoading) {
      setSelectedProject(defaultProject)
    }
  }, [defaultProject, projectsIsLoading])

  useEffect(() => {
    !isLoading &&
      billingEventsData &&
      setInternalBillingEventsData(billingEventsData)
  }, [billingEventsData, isLoading])

  return (
    <>
      <PageTitle>Metrics - Documents</PageTitle>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={3} sx={{ ml: 12.5, mr: 9, my: 3 }}>
          {onlyCustomers ? (
            <ListAutocomplete<Organization>
              autoSelect={false}
              options={[allOrganizations, ...organizations]}
              selected={selectedOrg}
              setSelected={(organization) => {
                setSelectedOrg(organization)
                updateValues({ org_id: organization.id })
              }}
              label="Customers"
              fullWidth={false}
              getOptionLabel={(option) =>
                onlyCustomers
                  ? option.customer?.name || option.name
                  : option.name
              }
              renderOption={(props, option) => {
                return (
                  <ListItem {...props} key={option.id} dense>
                    {onlyCustomers
                      ? option.customer?.name || option.name
                      : option.name}
                  </ListItem>
                )
              }}
              sx={{ width: '180px' }}
            />
          ) : (
            <Card sx={{ width: '180px' }}>
              <OrganizationPickerWithDialog
                allNodeIsIncluded
                currentOrganization={selectedOrg}
                organizations={[allOrganizations, ...organizations]}
                rootTreeOrgId={rootOrganization.id}
                isFetching={isFetchingAll}
                onSave={(organization) => {
                  setSelectedOrg(organization)
                  updateValues({ org_id: organization.id })
                }}
              />
            </Card>
          )}

          {!onlyCustomers && (
            <ListAutocomplete<Project>
              autoSelect={false}
              options={[allProjects, ...setUpProjects]}
              selected={selectedProject}
              setSelected={(project) => {
                setSelectedProject(project)
                updateValues({ project_id: project.id })
              }}
              label="Project"
              fullWidth={false}
              sx={{ width: '180px' }}
            />
          )}
          <MetricsCommonFilters
            startDateState={startDateState}
            endDateState={endDateState}
            groupedByState={groupedByState}
            updateValues={updateValues}
          />
        </Stack>
      </LocalizationProvider>
      <Grid container spacing={2} ml={11.5} mr={9} my={2}>
        {!billingEventMetrics && isLoading && (
          <>
            <Grid xs={6} sm={4}>
              <Skeleton height={100} />
            </Grid>
            <Grid xs={6} sm={4}>
              <Skeleton height={100} />
            </Grid>
            <Grid xs={6} sm={4}>
              <Skeleton height={100} />
            </Grid>
          </>
        )}

        {billingEventMetrics &&
          Object.entries(billingEventMetrics)
            .slice(0, 3)
            .map(([label, value]) => {
              let realValue: string | number = 0
              if (value && typeof value === 'number') {
                realValue = Number.isInteger(value) ? value : value.toFixed(3)
              }
              return (
                <Grid key={label} xs={6} sm={4}>
                  <GeneralInfoBox label={labelMap[label]} value={realValue} />
                </Grid>
              )
            })}

        {!onlyCustomers && (
          <Grid xs={12}>
            <Card elevation={0} sx={{ borderRadius: 2, pl: 2, pr: 5.5, py: 3 }}>
              <TimeBarChart
                from={new Date(startDateState[0])}
                to={new Date(endDateState[0])}
                data={internalBillingEventsData}
                colors={theme.palette.primary.main}
                dataSum={
                  billingEventMetrics ? billingEventMetrics.count : undefined
                }
                label={
                  <Typography component="h2" variant="h5" sx={{ pl: 6.5 }}>
                    Documents Processed
                  </Typography>
                }
                groupedBy={groupedByState[0]}
                margin={{ right: 40 }}
                yKey="doc_count"
                yLegend="Count"
              />
            </Card>
          </Grid>
        )}

        <Grid xs={12}>
          <Card elevation={0} sx={{ borderRadius: 2, pl: 2, pr: 5.5, py: 3 }}>
            <TimeBarChart
              from={new Date(startDateState[0])}
              to={new Date(endDateState[0])}
              data={internalBillingEventsData}
              colors={theme.palette.primary.main}
              dataSum={
                billingEventMetrics ? billingEventMetrics.count : undefined
              }
              label={
                <Typography component="h2" variant="h5" sx={{ pl: 6.5 }}>
                  Pages Processed
                </Typography>
              }
              groupedBy={groupedByState[0]}
              margin={{ right: 40 }}
              yKey="page_count"
              yLegend="Count"
            />
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
