import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import {
  EventStatusDetails,
  WorkflowStateEventTriggerBody,
} from '@/types/workflow-state-events'
import { createDetail, getList, updateDetail } from '../request-wrappers'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'

/**
 * MARK: Action Hook: Get Workflow State Event Statuses
 * Gets the statuses for workflow state events currently in motion.
 */
export function useWorkflowStateEventStatuses(
  options: UseGetListOptions<EventStatusDetails>,
) {
  const query = useGetList<EventStatusDetails>({
    queryKey: queryKeys.events.list(
      {
        filters: options.filters,
      },
      ['event_statuses'],
    ),
    serviceFn: (serviceFnOptions) => {
      return getList<EventStatusDetails>({
        url: `/v2/pd-workflow/events`,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    eventStatuses: query.allData,
    ...query,
  }
}

/**
 * MARK: Action Hook: Fail Event
 * Fails an on-going event.
 */
export function useFailEvent(
  options: UseUpdateDetailOptions<{ id: string }> = {},
) {
  const mutation = useUpdateDetail<{ id: string }>({
    serviceFn: ({ item, ...serviceFnOptions }) => {
      return updateDetail({
        url: `/v2/pd-workflow/events/${item.id}/fail`,
        item,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    failEvent: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Trigger Workflow State Event
 * Triggers a workflow state event.
 */
export function useTriggerWorkflowStateEvent(
  options: UseCreateDetailOptions<WorkflowStateEventTriggerBody> = {},
) {
  const mutation = useCreateDetail<WorkflowStateEventTriggerBody>({
    serviceFn: (serviceFnOptions) => {
      return createDetail({
        url: '/v2/pd-workflow/events',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    triggerWorkflowStateEvent: mutation.mutateAsync,
    ...mutation,
  }
}
