import { Typography } from '@mui/material'
import DeleteConfirmDialog from '@/components/delete-confirm-dialog/DeleteConfirmDialog'
import { OverlayState } from '@/hooks/useOverlay'
import { DataList } from '@/types/data-lists'

type DeleteFieldDialogProps = {
  dataList: DataList
  overlay: OverlayState
  onDelete: () => void
}

export default function DeleteDataListDialog({
  dataList,
  onDelete,
  overlay,
}: DeleteFieldDialogProps) {
  return (
    <DeleteConfirmDialog
      overlay={overlay}
      title="Delete List"
      onDelete={onDelete}
      textToMatch={dataList.name}
    >
      <Typography>
        Deleting a list may cause data loss. Are you sure you want to delete it?
      </Typography>
    </DeleteConfirmDialog>
  )
}
