import { useState } from 'react'

export default function useContextMenuPosition() {
  const [position, setPosition] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)

  const handleUpdatePosition = (x: number, y: number) => {
    setPosition({
      mouseX: x + 2, // Slide over 2px to the right so the menu isn't directly under the mouse
      mouseY: y - 6, // Slide up 6px so it doesn't feel like the menu is not positioned correctly
    })
  }

  return {
    position,
    handleUpdatePosition,
  }
}
