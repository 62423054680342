import { useEffect, useMemo, useState } from 'react'
import { MRT_ColumnFiltersState, MRT_FilterOption } from 'material-react-table'
import { DocumentFilteringOption } from '@/types/documents'
import { ProjectGridField } from '@/types/fields'
import { ProjectGrid } from '@/types/projects'
import getColumnFilterModeOptions, {
  getEndpointFilterMode,
} from '@/utils/getColumnFilterModeOptions'

type UseColumnOrderingOptions = {
  fields: ProjectGridField[]
  selectedGrids: Record<string, ProjectGrid>
  baseGridId?: string
}

export type MetadataId =
  | 'fileColId'
  | 'rowOrBaseId'
  | 'organizationColId'
  | 'submitterColId'
  | 'pageCountColId'
  | 'uploadColId'
  | 'enteredAtColId'

const metadataColumnIds: MetadataId[] = [
  'fileColId',
  'organizationColId',
  'submitterColId',
  'pageCountColId',
  'uploadColId',
  'enteredAtColId',
]

export default function useColumnFilters({
  fields,
  selectedGrids,
  baseGridId,
}: UseColumnOrderingOptions) {
  const filterFns = useMemo(() => {
    return [...fields, ...metadataColumnIds].reduce((acc, field) => {
      const isMetadataColumn = typeof field === 'string'
      const id = isMetadataColumn ? field : field.id
      const filterModeOptions = getColumnFilterModeOptions(
        isMetadataColumn ? field : field.project_grid_field_type.code,
      )
      return { ...acc, [id]: filterModeOptions[0] }
    }, {})
  }, [fields])

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
  const [columnFilterFns, setColumnFilterFns] =
    useState<Record<string, MRT_FilterOption>>(filterFns)

  const filteringLogic = useMemo(() => {
    const defaultSortingLogic: {
      filtering: DocumentFilteringOption[]
      fieldType?: 'base' | 'table'
      gridId?: string
    } = {
      filtering: [],
      fieldType: undefined,
      gridId: undefined,
    }

    if (!baseGridId) return defaultSortingLogic

    // Place metadata at the end so that we can determine the field type to use
    const filtersWithMetadataLast = columnFilters.toSorted((filter) =>
      filter.id.endsWith('ColId') ? 1 : -1,
    )

    const selectedGridsIds = Object.keys(selectedGrids)

    return filtersWithMetadataLast.reduce((acc, columnFilter) => {
      // By default we use the document rows endpoint
      if (
        selectedGridsIds.length === 1 &&
        selectedGridsIds.includes(baseGridId)
      ) {
        acc.fieldType = 'table'
      }

      let filterId = ''
      let filterMode = columnFilterFns[columnFilter.id]
      let value = columnFilter.value as (string | number)[] | string | number

      if (filterMode === 'equals' && value === 'empty-checkbox') {
        filterMode = 'empty'
      }

      if (Array.isArray(value)) {
        if (
          value.every(
            (val) =>
              val !== '' &&
              val !== undefined &&
              (typeof val !== 'number' || !isNaN(val)),
          ) &&
          filterMode === 'betweenInclusive'
        ) {
          value = value.join(',')
        } else {
          value = ''
        }
      } else if (filterMode === 'empty') {
        value = 'True'
      } else if (filterMode === 'notEmpty') {
        value = 'False'
      }

      // Must convert value to string before trying to trim it (can't trim on a number)
      const valueIsValid = value !== undefined && `${value}`.trim() !== ''

      if (valueIsValid) {
        const isMetadataColumn = columnFilter.id.endsWith('ColId')

        if (isMetadataColumn) {
          // Metadata columns will always be at the end. Thus, if there is not
          // a field type, it means no project fields filters are included
          if (!acc.fieldType) {
            acc.fieldType = 'table' // 'table' since we use the document rows endpoint by default
            if (selectedGridsIds.includes(baseGridId)) {
              acc.fieldType = selectedGridsIds.length > 1 ? 'base' : 'table'
            }
          }

          filterId = `${columnFilter.id}${getEndpointFilterMode(
            filterMode,
            columnFilter.id as MetadataId,
          )}`
        } else {
          const field = fields.find(
            (field) => field.id === columnFilter.id,
          ) as ProjectGridField

          filterId = `grid-field-${columnFilter.id}${getEndpointFilterMode(
            filterMode,
            field.project_grid_field_type.code,
          )}`

          if (!acc.fieldType) {
            acc.gridId = field.project_grid_id
            acc.fieldType = acc.gridId === baseGridId ? 'base' : 'table'
          }
        }
        acc.filtering.push({ filterId, value: value as string })
      }

      return acc
    }, defaultSortingLogic)
  }, [baseGridId, columnFilterFns, columnFilters, fields, selectedGrids])

  useEffect(() => {
    if (fields.length) {
      setColumnFilterFns((prev) => {
        const newFilterFns = { ...prev }
        fields.forEach(({ id, project_grid_field_type }) => {
          const filterModeOptions = getColumnFilterModeOptions(
            project_grid_field_type.code,
          )
          if (filterModeOptions && !Object.hasOwn(newFilterFns, id)) {
            newFilterFns[id] = filterModeOptions[0]
          }
        })
        return newFilterFns
      })
    }
  }, [fields])

  return {
    columnFilters,
    columnFilterFns,
    filteringLogic,
    setColumnFilters,
    setColumnFilterFns,
  }
}
