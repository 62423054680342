import { Stack, Typography } from '@mui/material'
import { useEffect } from 'react'

export default function CredentialAuthenticatedPage() {
  useEffect(() => {
    window.close()
  }, [])

  return (
    <Stack spacing={4} mt={4}>
      <Typography textAlign="center" variant="h3" component="h1">
        Authenticated!
      </Typography>
      <Typography textAlign="center" sx={{ fontSize: 48 }}>
        🎉
      </Typography>
      <Typography textAlign="center">
        You are welcome to close this window if it does not close on its own.
      </Typography>
    </Stack>
  )
}
