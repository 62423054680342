import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Stack,
  TextField,
} from '@mui/material'
import { DataListColumn } from '@/types/data-lists'
import { ProjectGridField } from '@/types/fields'
import { useGetDataList } from '@/service-library/hooks/data-lists'
import LabeledData from '@/components/labeled-data/LabeledData'

type PickerListInfoProps = {
  field: ProjectGridField & {
    params: Required<ProjectGridField['params']>
  }
}

export default function PickerListInfo({ field }: PickerListInfoProps) {
  const { setValue, watch } = useFormContext()
  const params = watch('params')
  const paramsSchema = field.project_grid_field_type.params_schema

  const { dataList, isLoading } = useGetDataList({
    id: field.params?.data_list_id || field.metadata.data_list_id || '',
    filters: {
      fields__include: 'data_list_columns',
    },
  })

  const selectedColumns = useMemo(() => {
    if (!dataList) return []
    const tempColumns = []
    if (params?.data_list_column_names) {
      ;(params.data_list_column_names as string[]).forEach((columnName) => {
        const column = dataList.data_list_columns?.find(
          (column) => column.name === columnName,
        )
        column && tempColumns.push(column)
      })
    } else if (field.metadata.data_list_column_id) {
      const column = dataList.data_list_columns?.find(
        (column) => column.id === field.metadata.data_list_column_id,
      )
      column && tempColumns.push(column)
    }
    return tempColumns
  }, [
    dataList,
    field.metadata.data_list_column_id,
    params?.data_list_column_names,
  ])

  const columnsForPredictions = useMemo(() => {
    if (!dataList || !params?.default_prediction_context_columns) return []
    const tempColumns: DataListColumn[] = []

    ;(params.default_prediction_context_columns as string[]).forEach(
      (columnName) => {
        const column = dataList.data_list_columns?.find(
          (column) => column.name === columnName,
        )
        column && tempColumns.push(column)
      },
    )

    return tempColumns
  }, [dataList, params.default_prediction_context_columns])

  const nonSystemManagedColumns =
    dataList?.data_list_columns?.filter(
      ({ is_system_managed }) => !is_system_managed,
    ) || []

  return dataList ? (
    <Card variant="outlined" sx={{ p: 2 }} component={Stack} spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <LabeledData label="List" data={dataList.name} />
        <Button
          variant="text"
          size="small"
          component={Link}
          to={`/settings/lists/${dataList.id}`}
          target="_blank"
        >
          View List
        </Button>
      </Stack>
      <Autocomplete
        multiple
        value={selectedColumns}
        autoHighlight
        disableCloseOnSelect
        getOptionLabel={(option) => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        options={nonSystemManagedColumns}
        onChange={(_e, options) => {
          setValue(
            'params',
            {
              ...params,
              data_list_column_names: options.map(({ name }) => name),
            },
            { shouldDirty: true },
          )
        }}
        noOptionsText="No items available."
        renderInput={(params) => (
          <TextField
            {...params}
            label="List Columns"
            error={!selectedColumns.length}
            helperText={
              selectedColumns.length
                ? 'The values from these columns will be concatenated to represent each option in the picker.'
                : "This field can't be empty."
            }
          />
        )}
      />
      <Autocomplete
        multiple
        value={columnsForPredictions}
        autoHighlight
        disableCloseOnSelect
        getOptionLabel={(option) => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        options={nonSystemManagedColumns}
        onChange={(_e, options) => {
          setValue(
            'params',
            {
              ...params,
              default_prediction_context_columns: options.map(
                ({ name }) => name,
              ),
            },
            { shouldDirty: true },
          )
        }}
        noOptionsText="No items available."
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              paramsSchema.properties.default_prediction_context_columns.display
                .label
            }
            helperText={
              paramsSchema.properties.default_prediction_context_columns.display
                .description
            }
          />
        )}
      />
    </Card>
  ) : isLoading ? null : (
    <Alert severity="error">
      The list this picker was assigned to was deleted. Please reach out to
      Pixydocs support to fix this.
    </Alert>
  )
}
