import { memo } from 'react'
import { Panel } from 'reactflow'
import {
  Close,
  LegendToggle,
  TripOrigin,
  DataArray,
  DataObject,
} from '@mui/icons-material'
import {
  Typography,
  IconButton,
  Stack,
  Paper,
  Card,
  Collapse,
  Tooltip,
  Box,
} from '@mui/material'
import { DataType } from './types'
import useLocalStorage from '@/hooks/useLocalStorage'
import KeyboardShortcutsTable from '@/components/keyboard-shortcuts-popover/KeyboardShortcutsTable'
import typeColorMap from './helpers/typeColorMap'
import { dataTypeTooltipMap } from './handles/BaseHandle'

function LegendPanel() {
  const [expanded, setExpanded] = useLocalStorage(
    'editor-legend-expanded',
    true,
  )

  return (
    <Panel
      position="top-right"
      style={{ width: 220, display: 'flex', flexDirection: 'row-reverse' }}
    >
      {!expanded && (
        <Paper
          sx={{
            borderRadius: '50%',
            width: 'fit-content',
            height: 'fit-content',
          }}
        >
          <Tooltip title="Legend & Controls" enterDelay={1000}>
            <IconButton onClick={() => setExpanded(true)} size="small">
              <LegendToggle />
            </IconButton>
          </Tooltip>
        </Paper>
      )}
      <Collapse in={expanded}>
        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            py={1}
            pl={1.5}
            pr={1}
          >
            <Typography sx={{ userSelect: 'none' }}>
              Legend & Controls
            </Typography>
            {expanded && (
              <IconButton onClick={() => setExpanded(false)} size="small">
                <Close fontSize="inherit" />
              </IconButton>
            )}
          </Stack>
          <Stack spacing={1} px={1.5} py={1}>
            <div>
              <Typography variant="caption" color="text.secondary">
                Data Types
              </Typography>
              <Stack spacing={1} p={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box
                    style={{
                      position: 'unset',
                      transition: '0.1s',
                      backgroundColor: 'white',
                      borderRadius: '2px',
                      border: 'none',
                      height: 12,
                      width: 16,
                      clipPath:
                        'polygon(0% 0%, 70% 0%, 100% 50%, 70% 100%, 0% 100%)',
                    }}
                  />
                  <Typography variant="body2">Execution Line</Typography>
                </Stack>

                {Object.entries(typeColorMap)
                  .filter(([key]) => key !== 'execution' && key !== 'object')
                  .map(([key, value]) => (
                    <Stack
                      key={key}
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <TripOrigin htmlColor={value} sx={{ fontSize: 16 }} />
                      <Typography
                        variant="body2"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {dataTypeTooltipMap[key as DataType]}
                      </Typography>
                    </Stack>
                  ))}

                <Stack direction="row" spacing={1} alignItems="center">
                  <DataObject
                    sx={{ ml: -0.1, mr: -0.3, color: typeColorMap.object }}
                    fontSize="small"
                  />
                  <Typography variant="body2">Object</Typography>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <DataArray sx={{ ml: -0.1, mr: -0.3 }} fontSize="small" />
                  <Typography variant="body2">List of Items</Typography>
                </Stack>
              </Stack>
            </div>
            <div>
              <Typography variant="caption" color="text.secondary">
                Controls
              </Typography>
              <Box p={1}>
                <KeyboardShortcutsTable
                  keyboardShortcutsMap={{
                    Delete: 'Backspace or Delete',
                    'Drag Selection': 'Shift + Click and Drag',
                    'Snap to Grid': 'Hold Shift while Dragging',
                  }}
                />
              </Box>
            </div>
          </Stack>
        </Card>
      </Collapse>
    </Panel>
  )
}

export default memo(LegendPanel)
