import { useEffect, useMemo } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Stack, Button, FormHelperText, Link } from '@mui/material'
import { OrgType, Organization } from '@/types/organizations'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetOrgTypes } from '@/service-library/hooks/org-types'
import {
  useCreateOrganization,
  useGetOrganization,
  useUpdateOrganization,
} from '@/service-library/hooks/organizations'
import queryKeys from '@/service-library/query-keys'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import {
  FormAutocomplete,
  FormTextField,
  PixyDocsForm,
} from '@/components/forms'
import { useRootOrganization } from './RootOrganizationProvider'

type AddEditOrganizationDialogProps = {
  overlay: OverlayState
  organization?: Organization
  parentOrgId?: string
}

type FormValues = {
  name: string
  code: string
  org_type: OrgType | null
}

export default function AddEditOrganizationDialog({
  overlay,
  organization,
  parentOrgId,
}: AddEditOrganizationDialogProps) {
  const navigate = useNavigate()

  const { queryKey } = useGetOrganization({
    id: organization?.id || '',
    filters: {
      fields__include: 'org_type',
    },
    enabled: false,
  })

  const { rootOrganization } = useRootOrganization()

  const { orgTypes } = useGetOrgTypes({
    filters: {
      limit: '1000',
      owner_org_id: rootOrganization?.id,
    },
    enabled: overlay.isOpen,
  })

  const { createOrganization } = useCreateOrganization({
    sideEffectQueryKeys: [queryKeys.organizations.lists()],
    onError: () => {
      showErrorSnackbar(
        'Unable to create organization. Please try again later.',
      )
    },
    onSuccess: (newOrg) => {
      navigate(`/settings/organizations/${newOrg.id}`)
    },
  })

  const { updateOrganization } = useUpdateOrganization({
    detailQueryKey: queryKey,
    sideEffectQueryKeys: [queryKeys.organizations.lists()],
    onError: () => {
      showErrorSnackbar('Unable to update organization')
    },
  })

  const defaultValues = useMemo(
    () => ({
      name: organization?.name || '',
      code: organization?.code || '',
      org_type: organization?.org_type || null,
    }),
    [organization?.code, organization?.name, organization?.org_type],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const {
    formState: { isDirty, isValid },
    register,
    reset,
  } = methods

  const handleSubmit = (values: FormValues) => {
    values.name = values.name.trim()
    values.code = values.code.trim()

    const orgTypeId = values.org_type ? values.org_type.id : null

    if (!organization) {
      if (parentOrgId) {
        createOrganization({
          ...values,
          id: generateUuid(),
          color: 'blue',
          parent_org_id: parentOrgId,
          org_type_id: orgTypeId,
        })
      }
    } else {
      const updatedOrganization = {
        ...organization,
        ...values,
        org_type_id: orgTypeId,
      }
      if (
        JSON.stringify({
          name: organization.name,
          code: organization.code,
          org_type_id: organization.org_type_id,
        }) !==
        JSON.stringify({
          name: updatedOrganization.name,
          code: updatedOrganization.code,
          org_type_id: updatedOrganization.org_type_id,
        })
      ) {
        updateOrganization(updatedOrganization)
      }
    }
    overlay.close()
  }

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  return (
    <Dialog
      maxWidth="sm"
      {...overlay}
      title={`${organization?.name ? 'Edit' : 'New'} Organization`}
    >
      <PixyDocsForm methods={methods} onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={4}>
            <FormTextField
              autoFocus
              label="Name"
              fullWidth
              helperText="Provide a name for the organization. This is what will be shown in the UI."
              required
              {...register('name', {
                validate: (value) => value.trim().length > 0,
              })}
            />

            <FormTextField
              label="Identifier"
              fullWidth
              helperText="Give your organization a unique identifier."
              required
              {...register('code', {
                validate: (value) => value.trim().length > 0,
              })}
            />
            <div>
              <FormAutocomplete
                name="org_type"
                label="Type"
                options={orgTypes}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value.id
                }
                getOptionLabel={(option) => option?.name || ''}
              />
              <FormHelperText sx={{ mx: 1.75, mt: 0.5 }}>
                You can manage organization types{' '}
                <Link
                  component={RouterLink}
                  to="../organization-types"
                  underline="none"
                  target="_blank"
                >
                  here
                </Link>
                .
              </FormHelperText>
            </div>
          </Stack>
        </DialogContent>

        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
