import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import { Box, SxProps } from '@mui/material'

export const SizeContext = createContext({ height: 0, width: 0 })

export const useContainerSize = () => useContext(SizeContext)

type SizeContainerProps = {
  children: ReactNode
  sx?: SxProps
}

export default function SizeProvider({ children, sx }: SizeContainerProps) {
  const [el, setEl] = useState<HTMLDivElement | null>(null)
  const [size, setSize] = useState({ height: 0, width: 0 })

  useEffect(() => {
    if (!el) return
    const observer = new ResizeObserver(() => {
      setSize({
        height: el?.clientHeight || 0,
        width: el?.clientWidth || 0,
      })
    })
    observer.observe(el)
    return () => {
      observer.disconnect()
    }
  }, [el])

  return (
    <SizeContext.Provider value={size}>
      <Box
        sx={{ height: '100%', width: '100%', ...sx }}
        ref={(el: HTMLDivElement) => setEl(el)}
      >
        {children}
      </Box>
    </SizeContext.Provider>
  )
}
