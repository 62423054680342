import { useRef } from 'react'
import { ViewSidebar } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { useDocumentCurrentWorkflowViewContext } from '../image-zoom-pan/providers/DocumentCurrentWorkflowViewProvider'

export default function DocumentViewControl() {
  const overlay = useOverlay()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const {
    allowedDocumentCurrentWorkflowViews,
    documentCurrentWorkflowView,
    setDocumentCurrentWorkflowView,
  } = useDocumentCurrentWorkflowViewContext()
  if (allowedDocumentCurrentWorkflowViews.length === 1) return null

  return (
    <>
      <Button
        startIcon={<ViewSidebar />}
        onClick={overlay.toggle}
        ref={anchorRef}
        variant="text"
      >
        {documentCurrentWorkflowView}
      </Button>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
        elevation={16}
      >
        {allowedDocumentCurrentWorkflowViews.map((view) => (
          <MenuItem
            key={view}
            value={view}
            sx={{
              textTransform: 'capitalize',
            }}
            selected={view === documentCurrentWorkflowView}
            onClick={() => {
              setDocumentCurrentWorkflowView(view)
              overlay.close()
            }}
          >
            {view}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
