import { DataType } from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validInsertPairs: Record<DataType, Record<any, any>> = {
  integer: {
    decimal: 'integer-to-decimal',
    string: 'integer-to-string',
  },
  decimal: {
    string: 'decimal-to-string',
  },
  text: {},
  boolean: {
    string: 'boolean-to-string',
  },
  date: {},
  time: {},
  datetime: {
    date: 'datetime-to-date',
    time: 'datetime-to-time',
  },
  object: {}, // TODO: Add inserted map node for array of objects when object structures are different
  any: {},
  execution: {},
}

export default validInsertPairs
