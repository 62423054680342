import { NodeProps } from 'reactflow'
import BaseNode from './BaseNode'
import { Stack } from '@mui/material'
import { KeyboardBackspace } from '@mui/icons-material'

export default function ConversionNode({ data, ...props }: NodeProps) {
  return (
    <BaseNode
      middleContent={
        <Stack sx={{ height: 20, alignItems: 'center' }} direction="row">
          <KeyboardBackspace sx={{ mt: -1, transform: 'rotate(180deg)' }} />
        </Stack>
      }
      data={{ ...data, title: undefined }}
      hideHeading
      {...props}
    />
  )
}
