import BaseIntegerField, {
  IntegerFieldProps as BaseIntegerFieldProps,
} from '@/components/inputs/IntegerField'

type IntegerFieldProps = {
  onChange: (value: string) => void
  value: string
} & Omit<BaseIntegerFieldProps, 'onChange' | 'value'>

export default function IntegerField({
  onChange,
  ...props
}: IntegerFieldProps) {
  return (
    <BaseIntegerField
      autoComplete="off"
      onChange={(value) => onChange(value.toString())} // BaseIntegerField returns the value instead of the event
      {...props}
    />
  )
}
