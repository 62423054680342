import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'

type DrawerPortalContextValue = {
  portalNode: Element
  setPortalNode: Dispatch<SetStateAction<Element>>
  secondPortalNode: Element
  setSecondPortalNode: Dispatch<SetStateAction<Element>>
}

const DrawerPortalContext = createContext<DrawerPortalContextValue>(
  [] as unknown as DrawerPortalContextValue,
)

export const useDrawerPortalContext = () => useContext(DrawerPortalContext)

export default function DrawerPortalProvider({
  children,
}: {
  children: ReactNode
}) {
  const [portalNode, setPortalNode] = useState<Element>(
    document.createElement('div'),
  )
  const [secondPortalNode, setSecondPortalNode] = useState<Element>(
    document.createElement('div'),
  )

  return (
    <DrawerPortalContext.Provider
      value={{
        portalNode,
        setPortalNode,
        secondPortalNode,
        setSecondPortalNode,
      }}
    >
      {children}
    </DrawerPortalContext.Provider>
  )
}
