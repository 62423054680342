import { AutocompleteProps, TextFieldProps } from '@mui/material'
import { ProjectCategory } from '@/types/projects'
import ListAutocomplete from '@/components/list-autocomplete/ListAutocomplete'
import { useGetProjectCategories } from '@/service-library/hooks/project-categories'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'

type ProjectCategoryAutocompleteProps = {
  selected: ProjectCategory
  onCategoryChange: (category: ProjectCategory) => void
  helperText?: string
  variant?: TextFieldProps['variant']
  label?: string
  size?: string
} & Partial<AutocompleteProps<ProjectCategory, false, true, false, 'div'>>

export default function ProjectCategoryAutocomplete({
  selected,
  onCategoryChange,
  variant,
  label = 'Project Category',
  size = 'small',
  ...props
}: ProjectCategoryAutocompleteProps) {
  const { rootOrganization } = useRootOrganization()
  const { projectCategories = [] } = useGetProjectCategories({
    filters: {
      limit: '1000',
      org_id: rootOrganization.id,
    },
  })

  return (
    <ListAutocomplete<ProjectCategory>
      autoSelect={false}
      inputSize={size}
      label={label}
      selected={selected}
      setSelected={(value) => {
        if (!value) return
        onCategoryChange(value)
      }}
      variant={variant}
      {...props}
      options={projectCategories}
    />
  )
}
