import useLogixNodeTypes from '@/services/hooks/useLogixNodeTypes'
import BaseNode from '../nodes/BaseNode'
import { useMemo } from 'react'
import IterationNode from '../nodes/IterationNode'
import ConversionNode from '../nodes/ConversionNode'
import GroupNode from '../nodes/GroupNode'
import CreateObjectNode from '../nodes/create-object-node/CreateObjectNode'

// If it isn't listed here, it uses the BaseNode
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const nodeTypeComponentMap: Record<string, any> = {
  'for-each': IterationNode,
  'list-map': IterationNode,
  'integer-to-decimal': ConversionNode,
  'integer-to-text': ConversionNode,
  'decimal-to-text': ConversionNode,
  'boolean-to-text': ConversionNode,
  'datetime-to-date': ConversionNode,
  'datetime-to-time': ConversionNode,
  'group-nodes': GroupNode,
  'create-object': CreateObjectNode,
}

export default function useNodeTypeIndex() {
  const { nodeTypes = [] } = useLogixNodeTypes()

  // Create a record with the key being the LogixNodeType.code and the value being a React Component
  return useMemo(
    () =>
      nodeTypes.reduce<Record<string, typeof BaseNode>>((acc, nodeType) => {
        const Component = nodeTypeComponentMap[nodeType.code]
        acc[nodeType.code] = Component || BaseNode
        return acc
      }, {}),
    [nodeTypes],
  )
}
