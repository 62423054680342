import { useEffect, useMemo, useState } from 'react'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Alert, Box, Stack } from '@mui/material'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { OrgTree, Organization } from '@/types/organizations'
import {
  addSelectionProperties,
  createOrganizationMap,
  getFilteredOrgsNodesData,
  getNodesToExpandForMultipleOrgs,
} from '@/utils/organization'
import OrganizationsSearchInput from '@/components/organizations/OrganizationsSearchInput'
import OrganizationTreeItemWithCheckbox from '@/components/organizations-tree/OrganizationTreeItemWithCheckbox'

type OrganizationTreeWithImplicitInheritanceSearchProps = {
  organizations: Organization[]
  rootTreeOrgId: string
  selectedOrgsIds: string[]
  checkboxIsDisabled?: boolean
  label?: string
  helperText?: string
  showSearchInput?: boolean
  onDeselectOrg?: (orgId: string) => void
  onSelectOrg?: (orgId: string) => void
  setSelectedOrgs?: (orgs: Organization[]) => void
}

export default function OrganizationTreeWithImplicitInheritanceSearch({
  organizations,
  rootTreeOrgId,
  selectedOrgsIds,
  onDeselectOrg,
  onSelectOrg,
  setSelectedOrgs,
  checkboxIsDisabled,
  helperText,
  label,
  showSearchInput = true,
}: OrganizationTreeWithImplicitInheritanceSearchProps) {
  const [inputValue, setInputValue] = useState('')

  const selectedOrgs = useMemo(
    () => organizations.filter(({ id }) => selectedOrgsIds.includes(id)),
    [selectedOrgsIds, organizations],
  )

  const organizationMap = useMemo(
    () => createOrganizationMap(organizations),
    [organizations],
  )

  const defaultExpanded = useMemo(
    () => getNodesToExpandForMultipleOrgs(selectedOrgs, organizationMap),
    [selectedOrgs, organizationMap],
  )

  const [expanded, setExpanded] = useState(defaultExpanded)

  const { visibleOrgIds, nodesToExpand } = useMemo(
    () =>
      getFilteredOrgsNodesData({
        organizations,
        organizationMap,
        defaultExpanded,
        searchedValue: inputValue,
      }),
    [defaultExpanded, inputValue, organizationMap, organizations],
  )

  const { orgTree = {}, implicitlySelectedIds = [] } = addSelectionProperties(
    organizationMap[rootTreeOrgId],
    selectedOrgsIds,
  )

  useEffect(() => {
    if (inputValue || !expanded.length) {
      setExpanded(nodesToExpand)
    }
  }, [expanded.length, inputValue, nodesToExpand])

  return (
    <Stack spacing={2} direction="row">
      {showSearchInput && (
        <Box sx={{ width: 300 }}>
          <OrganizationsSearchInput
            label={label}
            helperText={helperText}
            inputValue={inputValue}
            setInputValue={setInputValue}
            selected={selectedOrgs}
            implicitlySelectedIds={implicitlySelectedIds}
            setSelected={(orgs) => {
              setSelectedOrgs?.(orgs)
              setExpanded(
                getNodesToExpandForMultipleOrgs(orgs, organizationMap),
              )
            }}
            disableCloseOnSelect
            organizations={organizations}
          />
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        {visibleOrgIds.length ? (
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={
              !inputValue && !expanded.length ? [rootTreeOrgId] : expanded
            }
            multiSelect
            selected={selectedOrgsIds}
            onNodeToggle={(e, ids) => {
              // When clicking on a checkbox, onNodeToggle gets triggered before the checkbox
              // onChange, so we prevent default expand/collapse behavior here.
              if ((e.target as HTMLElement).tagName !== 'INPUT') {
                setExpanded(ids)
              }
            }}
          >
            <OrganizationTreeItemWithCheckbox
              checkboxIsDisabled={checkboxIsDisabled}
              orgTree={orgTree as OrgTree}
              searchTerm={inputValue}
              getIsVisible={(id: string) => visibleOrgIds.includes(id)}
              onChange={(checked, orgId) => {
                if (checked) {
                  onSelectOrg?.(orgId)
                } else {
                  onDeselectOrg?.(orgId)
                }
              }}
            />
          </TreeView>
        ) : (
          <Alert severity="info">No results found.</Alert>
        )}
      </Box>
    </Stack>
  )
}
