import { Container } from '@mui/material'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import PageTitle from '@/components/PageTitle'
import UserPreferences from '@/components/users/UserPreferences'

export default function PreferencesPage() {
  return (
    <Container maxWidth="md">
      <PageTitle>My Preferences</PageTitle>
      <Breadcrumb label="My Preferences" url="/settings/my-preferences" />
      <UserPreferences />
    </Container>
  )
}
