import BaseHandle from './BaseHandle'
import { Position } from 'reactflow'
import { PartialHandleProps } from '../types'
import { LogixHandle } from '@/types/logix'

type InputHandleProps = PartialHandleProps & {
  logixHandle: LogixHandle
  style?: React.CSSProperties
}

export default function InputHandle(props: InputHandleProps) {
  return <BaseHandle position={Position.Left} type="target" {...props} />
}
