import { useTopBarPortalContext } from './TopBarPortalProvider'
import { Box } from '@mui/material'

export default function TopBarPortal() {
  const { setPortalNode } = useTopBarPortalContext()
  return (
    <Box
      ref={setPortalNode}
      data-portal-id="top-bar-portal"
      sx={{ position: 'relative' }}
    />
  )
}
