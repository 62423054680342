import { Box, Typography, useTheme } from '@mui/material'
import { DragEvent, MouseEventHandler } from 'react'
import { LogixNodeType } from '@/types/logix'

type NodeListItemProps = {
  nodeType: LogixNodeType
  onClick?: MouseEventHandler<HTMLLIElement>
  draggable?: boolean
}

export default function NodeListItem({
  nodeType,
  ...props
}: NodeListItemProps) {
  const theme = useTheme()

  const onDragStart = (event: DragEvent, nodeTypeCode: string) => {
    if (!event.dataTransfer) return
    event.dataTransfer.setData('application/reactflow', nodeTypeCode)
    event.dataTransfer.effectAllowed = 'move'
  }

  return (
    <Box
      component="li"
      sx={{
        listStyle: 'none',
        py: 0.5,
        px: 1.5,
        cursor: 'grab',
        transition: 'all .1s',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      }}
      {...props}
      onDragStart={(event) => onDragStart(event, nodeType.code)}
      draggable
    >
      <Typography variant="body2">{nodeType.title}</Typography>
    </Box>
  )
}
