import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { DocumentFlag } from '@/types/flags'

const service = buildService<DocumentFlag>({
  pathCategory: 'document_flags',
})

export function useGetDocumentFlag({
  id,
  ...options
}: UseGetDetailOptions<DocumentFlag>) {
  const query = useGetDetail<DocumentFlag>({
    id,
    queryKey: queryKeys.documentFlags.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    documentFlag: query.data,
    ...query,
  }
}

export function useCreateDocumentFlag(
  options: UseCreateDetailOptions<DocumentFlag> = {},
) {
  const mutation = useCreateDetail<DocumentFlag>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDocumentFlag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDocumentFlag(
  options: UseUpdateDetailOptions<DocumentFlag> = {},
) {
  const mutation = useUpdateDetail<DocumentFlag>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDocumentFlag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentFlag(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<DocumentFlag>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDocumentFlag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDocumentFlags({
  filters,
  ...options
}: UseGetListOptions<DocumentFlag> = {}) {
  const query = useGetList<DocumentFlag>({
    filters,
    queryKey: queryKeys.documentFlags.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    documentFlags: query.allData,
    ...query,
  }
}

export function useUpdateDocumentFlags(
  options: UseUpdateListOptions<DocumentFlag> = {},
) {
  const mutation = useUpdateList<DocumentFlag>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDocumentFlags: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentFlags({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DocumentFlag>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDocumentFlags: mutation.mutateAsync,
    ...mutation,
  }
}
