import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import useMutation, { UseMutationOptions } from './useMutation'
import PaginatedResponse from '@/types/paginated-response'
import deleteInMultipleRequests from '../utils/delete-in-multiple-requests'
import { ListQueryKeyOption } from '../query-types'
import { DeleteRequestOptions, RequestOptions } from '../request-types'
import { AxiosResponse } from 'axios'

export type UseDeleteListOptions = UseMutationOptions<
  void,
  unknown,
  string[],
  unknown
> &
  RequestOptions &
  ListQueryKeyOption

type ServiceFn = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (
    options: DeleteRequestOptions & { ids: string[] },
  ) => Promise<AxiosResponse<void>>
}

/**
 * This hook is used to update an existing item and, optionally, update a related list query with the new item.
 */
export default function useDeleteList<T extends { id: string }>({
  serviceFn,
  sideEffectQueryKeys,
  listQueryKey,
  axiosOptions,
  onMutate,
  onError,
  onSettled,
  ...options
}: UseDeleteListOptions & ServiceFn) {
  const queryClient = useQueryClient()

  return useMutation({
    sideEffectQueryKeys,
    mutationFn: (ids) => {
      if (ids.length > 200) {
        return deleteInMultipleRequests(
          (ids) => serviceFn({ ids, axiosOptions }),
          ids,
        ).then(({ data }) => data)
      }
      return serviceFn({
        ids,
        axiosOptions,
      }).then(({ data }) => data)
    },
    onMutate: async (ids) => {
      let previous: InfiniteData<PaginatedResponse<T>> | undefined = undefined

      if (listQueryKey) {
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        previous = queryClient.getQueryData(listQueryKey)

        // Update the list data in cache if it exists
        queryClient.setQueryData<InfiniteData<PaginatedResponse<T>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            const pages = old?.pages?.map((page) => {
              return {
                ...page,
                results: page.results.filter((storedItem) => {
                  const matchingItem = ids.find((id) => id === storedItem.id)
                  return !matchingItem
                }),
              }
            })
            return {
              ...old,
              pages,
            }
          },
        )
      }

      // Run user supplied onMutate function if available
      onMutate?.(ids)

      // Return the previous data in case we need to reset it in onError
      return { previous }
    },
    onError: (error, ids, context) => {
      // Run user supplied onError function if available
      onError?.(error, ids, context)

      if (!listQueryKey || !context?.previous) return
      // Reset
      queryClient.setQueryData(listQueryKey, context?.previous)
    },
    onSettled: (...args) => {
      onSettled?.(...args)

      if (listQueryKey)
        queryClient.invalidateQueries({ queryKey: listQueryKey })
    },
    ...options,
  })
}
