import { Stack } from '@mui/material'
import { ReactNode } from 'react'

type HorizontalTabsProps = {
  children: ReactNode
}

export default function HorizontalTabs({ children }: HorizontalTabsProps) {
  return (
    <Stack direction="row" spacing={0.5}>
      {children}
    </Stack>
  )
}
