import booleanIntersects from '@turf/boolean-intersects'
import { polygon as polygonConstructor } from '@turf/helpers'
import pointInPolygon from 'point-in-polygon'
import { SelectedField } from '@/components/validation/providers/SelectedFieldProvider'
import { DocumentChip } from '@/types/documents'
import { ProjectGridField } from '@/types/fields'
import { Project } from '@/types/projects'

export type Size = {
  width: number
  height: number
}

export type Position = {
  x: number
  y: number
}

// Top left, top right, bottom right, and bottom left
export function orderPoints(points: Position[]) {
  // Sort the points based on their y values
  const sortedPoints = points.toSorted((a, b) => b.y - a.y)

  const topPoints = sortedPoints.slice(0, 2)
  topPoints.sort((a, b) => a.x - b.x)

  const bottomPoints = sortedPoints.slice(2)
  bottomPoints.sort((a, b) => b.x - a.x)

  return [...topPoints, ...bottomPoints]
}

export function scalePosition({
  width,
  height,
  position,
}: {
  width: number
  height: number
  position: Position
}) {
  return {
    x: position.x * width,
    y: position.y * height,
  }
}

export function drawChip({
  canvasSize,
  context,
  polygon,
}: {
  canvasSize: Size
  context: CanvasRenderingContext2D
  polygon: Position[]
}) {
  const { width, height } = canvasSize
  context.beginPath()
  polygon.forEach((coordinates, index) => {
    const drawFunction = (x: number, y: number) =>
      index === 0 ? context.moveTo(x, y) : context.lineTo(x, y)
    const scaledPosition = scalePosition({
      width,
      height,
      position: coordinates,
    })
    drawFunction(scaledPosition.x, scaledPosition.y)
  })
  context.closePath()
  context.stroke()
}

export function revertPointScaling({
  axis,
  imagePosition,
  imageSize,
  value,
}: {
  axis: 'x' | 'y'
  imagePosition: Position
  imageSize: Size
  value: number
}) {
  if (axis === 'x') {
    return (value - imagePosition.x) / imageSize.width
  }
  return (value - imagePosition.y) / imageSize.height
}

export function convertMouseEventPoint({
  event,
  canvas,
}: {
  event: React.MouseEvent
  canvas: HTMLCanvasElement
}) {
  const divRect = (event.target as HTMLCanvasElement).getBoundingClientRect()
  const widthScale = canvas.width / divRect.width
  const heightScale = canvas.height / divRect.height

  return scalePosition({
    width: widthScale,
    height: heightScale,
    position: {
      x: event.clientX - divRect.left,
      y: event.clientY - divRect.top,
    },
  })
}

export function getChipsInRangeSelection({
  image,
  selectionMode,
  chips,
  polygon,
  selectedField,
}: {
  image: HTMLImageElement
  selectionMode: 'selecting' | 'deselecting' | 'multi-selecting'
  chips: DocumentChip[]
  polygon: Position[]
  selectedField: SelectedField | null
}) {
  const polygonCoordinates = polygon.map(({ x, y }) => [x, y])
  // adding first coordinates since polygonConstructor needs to have the
  // first and the last coordinates to be the same
  const newPolygon = polygonConstructor([
    [...polygonCoordinates, polygonCoordinates[0]],
  ])

  return chips.filter((chip) => {
    const falseConditionOne =
      selectionMode === 'selecting' &&
      // We don't allow range selection for checkbox field, so we should never select a checkbox chip
      (chip.text === 'FalseBox' ||
        chip.text === 'TrueBox' ||
        // We don't need to re-select a chip that is already assigned to the selected field
        (selectedField?.field.id === chip.project_grid_field_id &&
          selectedField?.document_row_id === chip.document_row_id))

    const falseConditionTwo =
      selectionMode === 'deselecting' &&
      // When a field is selected, we only deselect chips assigned to that field
      ((selectedField &&
        chip.project_grid_field_id !== selectedField.field.id) ||
        // When there is not a field selected, we only deselect chips assigned to a field
        (!selectedField && !chip.project_grid_field_id))

    if (falseConditionOne || falseConditionTwo) return false

    const chipCoordinates = chip.pbox.map((coordinates) =>
      scalePosition({
        position: coordinates,
        height: image.height,
        width: image.width,
      }),
    )
    const newChipCoordinates = chipCoordinates.map(({ x, y }) => [x, y])
    const chipPolygon = polygonConstructor([
      [...newChipCoordinates, newChipCoordinates[0]],
    ])

    return booleanIntersects(newPolygon, chipPolygon)
  })
}

export function getIntersectingChips({
  image,
  chips,
  point,
  additionalCondition = () => true,
}: {
  image: HTMLImageElement
  chips: DocumentChip[]
  point: Position
  additionalCondition?: (chip: DocumentChip) => boolean
}): DocumentChip[] {
  const clickedChips = chips.filter((chip) => {
    if (!additionalCondition(chip)) return false

    const chipCornerCoordinates = chip.pbox.map((coordinates) =>
      scalePosition({
        position: coordinates,
        height: image.height,
        width: image.width,
      }),
    )
    const chipCoordinatesArray = chipCornerCoordinates.map((corners) =>
      Object.values(corners),
    )

    return pointInPolygon(Object.values(point), chipCoordinatesArray)
  })
  return clickedChips
}

export const rotatePoint = ({
  point,
  rotationDegree,
  image,
}: {
  point: Position
  rotationDegree: number
  image: HTMLImageElement
}) => {
  switch (rotationDegree) {
    case 90: {
      return {
        x: point.y,
        y: -point.x + image.height,
      }
    }
    case 180: {
      return {
        x: -point.x + image.width,
        y: -point.y + image.height,
      }
    }
    case 270: {
      return {
        x: -point.y + image.width,
        y: point.x,
      }
    }
    default: {
      return point
    }
  }
}

export function getSelectionMode(
  event: React.MouseEvent,
  project: Project,
  field?: ProjectGridField,
) {
  const { shiftKey, metaKey, altKey } = event

  const isSelecting = shiftKey && !metaKey && !altKey

  const tableGrids =
    project.project_grids
      ?.filter((grid) => !!grid.parent_project_grid_id)
      .map(({ id }) => id) || []

  const isMultiSelecting =
    !shiftKey &&
    metaKey &&
    altKey &&
    field &&
    tableGrids.includes(field.project_grid_id)

  const isDeselecting = shiftKey && !metaKey && altKey

  if (isSelecting) return 'selecting'
  if (isDeselecting) return 'deselecting'
  if (isMultiSelecting) return 'multi-selecting'
  return null
}

export function getChipsOnRows(
  chips: DocumentChip[],
  canvas: HTMLCanvasElement,
): DocumentChip[][] {
  // Separate chips that are on the same row based on x axis into groups
  const chipsOnRows: DocumentChip[][] = []

  chips.forEach((chip, index) => {
    const { y } = chip.pbox[0]
    if (index === 0) {
      chipsOnRows.push([chip])
      return
    }

    const scaledY = y * canvas.height

    const addedToExistingRow = chipsOnRows.some((rowOfChips) => {
      const { y: rowY } = rowOfChips[0].pbox[0]
      const scaledRowY = rowY * canvas.height
      if (Math.abs(scaledY - scaledRowY) < 20) {
        rowOfChips.push(chip)
        // Break out as soon as we add it to a row
        return true
      }
    })

    if (!addedToExistingRow) {
      // If we didn't add it to an existing row, create a new row
      chipsOnRows.push([chip])
    }
  })

  return chipsOnRows
}

/**
 * Returns the top-left coordinate and bottom right coordinate for a frame for an array of chips.
 */
export function getCornerCoordinates(chips: DocumentChip[]) {
  return chips.reduce<{
    topLeftX: number
    topLeftY: number
    bottomRightX: number
    bottomRightY: number
  }>(
    (acc, chip) => {
      const topLeftX = chip.pbox[0].x
      const topLeftY = chip.pbox[0].y
      const bottomRightX = chip.pbox[2].x
      const bottomRightY = chip.pbox[2].y
      const newValues = { ...acc }
      if (topLeftX < acc.topLeftX) newValues.topLeftX = topLeftX
      if (topLeftY < acc.topLeftY) newValues.topLeftY = topLeftY
      if (bottomRightX > acc.bottomRightX) newValues.bottomRightX = bottomRightX
      if (bottomRightY > acc.bottomRightY) newValues.bottomRightY = bottomRightY
      return newValues
    },
    {
      topLeftX: 10000000,
      topLeftY: 10000000,
      bottomRightX: 0,
      bottomRightY: 0,
    },
  )
}
