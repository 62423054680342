import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { Typography, Button } from '@mui/material'
import { Team } from '@/types/teams'
import { OverlayState } from '@/hooks/useOverlay'
import { useDeleteTeam } from '@/service-library/hooks/teams'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type DeleteTeamDialogProps = {
  overlay: OverlayState
  team: Team
}

export default function DeleteTeamDialog({
  overlay,
  team,
}: DeleteTeamDialogProps) {
  const navigate = useNavigate()
  const { deleteTeam, isLoading } = useDeleteTeam({
    onSuccess: () => {
      navigate('../')
    },
    onError: () => {
      showErrorSnackbar('Unable to delete team. Please try again later.')
      overlay.close()
    },
  })

  return (
    <Dialog {...overlay} title="Delete Team" maxWidth="xs">
      <DialogContent>
        <Typography>
          You are about to delete this team. Are you sure?
        </Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>
          {team.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Users: {team.users?.length ?? 0}
        </Typography>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            deleteTeam(team.id)
          }}
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
