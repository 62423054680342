import { DataListEntry } from '@/types/data-lists'
import { Chip, Stack, TextField } from '@mui/material'
import { useRef } from 'react'
import { MRT_TableInstance } from 'material-react-table'
import { RowData } from './constructTableRows'
import {
  useCreateDataListEntryCellValue,
  useDeleteDataListEntryCellValue,
} from '@/service-library/hooks/data-list-entry-cell-values'
import generateUuid from '@/utils/generate-uuid'
import { useCreateDataListEntryCell } from '@/service-library/hooks/data-list-entry-cells'
import { QueryKey } from '@tanstack/react-query'

type MultiValueEditProps = {
  dataListEntry?: DataListEntry
  dataListColumnId: string
  table: MRT_TableInstance<RowData>
  entriesQueryKey: QueryKey
}

export default function MultiValueEdit({
  dataListEntry,
  dataListColumnId,
  table,
  entriesQueryKey,
}: MultiValueEditProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const { createDataListEntryCell } = useCreateDataListEntryCell({
    sideEffectQueryKeys: [entriesQueryKey],
  })

  const { createDataListEntryCellValue } = useCreateDataListEntryCellValue({
    sideEffectQueryKeys: [entriesQueryKey],
  })

  const { deleteDataListEntryCellValue } = useDeleteDataListEntryCellValue({
    sideEffectQueryKeys: [entriesQueryKey],
  })

  if (!dataListEntry) return null

  // [sc-10439] To prevent errors when more than one cell in the same row has the same column id
  const dataListEntryCells = dataListEntry.data_list_entry_cells.filter(
    ({ data_list_column_id }) => dataListColumnId === data_list_column_id,
  )
  let dataListEntryCell = dataListEntryCells[0]
  if (dataListEntryCells.length > 1) {
    dataListEntryCell =
      dataListEntryCells.find(
        (cell) => cell.data_list_entry_cell_values.length,
      ) || dataListEntryCell
  }

  return (
    <Stack spacing={1}>
      <TextField
        inputRef={inputRef}
        name="multivalue-edit"
        variant="outlined"
        label="Values"
        placeholder="Add Value..."
        fullWidth
        autoFocus
        onBlur={() => {
          table.setEditingCell(null)
        }}
        onKeyDown={({ code, target }) => {
          if (code === 'Enter' || code === 'NumpadEnter') {
            const value = (target as HTMLInputElement).value.trim()
            if (!value) return
            if (!dataListEntryCell) {
              const cellId = generateUuid()
              createDataListEntryCell({
                id: cellId,
                data_list_column_id: dataListColumnId,
                data_list_entry_id: dataListEntry.id,
                data_list_entry_cell_values: [
                  {
                    id: generateUuid(),
                    value,
                    data_list_entry_cell_id: cellId,
                    sort_order: 0,
                  },
                ],
                sort_order: 0,
              })
            } else {
              createDataListEntryCellValue({
                id: generateUuid(),
                value,
                data_list_entry_cell_id: dataListEntryCell.id,
                sort_order: 0,
              })
            }
            if (inputRef.current) inputRef.current.value = ''
          }
        }}
      />
      <Stack direction="row" spacing={0.25}>
        {dataListEntryCell?.data_list_entry_cell_values.map(
          (dataListEntryCellValue) => (
            <Chip
              key={dataListEntryCellValue.value}
              label={dataListEntryCellValue.value}
              size="small"
              onMouseDown={(e) => {
                // Keep the cell open when clicking on a chip, don't lose focus on the textfield
                e.preventDefault()
              }}
              onDelete={() => {
                inputRef.current?.focus()
                deleteDataListEntryCellValue(dataListEntryCellValue.id)
              }}
            />
          ),
        )}
      </Stack>
    </Stack>
  )
}
