import { WorkflowStateAction } from '@/types/workflow-state-actions'
import AuditEntry from '@/components/document-dialog/AuditEntry'
import { Stack, Typography } from '@mui/material'

type CreateAuditTrailEntryDisplayProps = {
  action: WorkflowStateAction
}

export default function CreateAuditTrailEntryDisplay({
  action,
}: CreateAuditTrailEntryDisplayProps) {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        Add this audit trail entry to the document:
      </Typography>
      <AuditEntry
        name={action.params.name}
        description={action.params.description}
        userName="(User Name)"
        created_at="(Date and Time)"
      />
    </Stack>
  )
}
