import { Button } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type ReadOnlyOrganizationDialogProps = {
  onCancel: () => void
  onContinue: () => void
  overlay: OverlayState
  isBulkChange?: boolean
}

export default function ReadOnlyOrganizationDialog({
  onCancel,
  onContinue,
  overlay,
  isBulkChange = false,
}: ReadOnlyOrganizationDialogProps) {
  return (
    <Dialog title="Read-Only Access" {...overlay}>
      <DialogContent>
        You only have read access to the organization you&apos;re attempting to
        move {isBulkChange ? 'these documents' : 'this document'} to. If you
        move {isBulkChange ? 'them' : 'it'}, you will not be able to move{' '}
        {isBulkChange ? 'them' : 'it'} again.
      </DialogContent>
      <DialogFooter>
        <Button
          variant="text"
          onClick={() => {
            overlay.close()
            onCancel()
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => {
            overlay.close()
            onContinue()
          }}
        >
          Continue
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
