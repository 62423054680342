import { TextField, TextFieldProps } from '@mui/material'

type TextInputFieldProps = {
  onChange: (newValue: string) => void
} & Omit<TextFieldProps, 'onChange'>

export default function TextInputField({
  onChange,
  ...props
}: TextInputFieldProps) {
  return (
    <TextField
      autoComplete="off"
      onChange={(e) => onChange(e.target.value)}
      {...props}
    />
  )
}
