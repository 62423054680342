import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { Project } from '@/types/projects'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useGetProjects,
  useUpdateProject,
} from '@/service-library/hooks/projects'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'

type FormValues = {
  name: string
}

type EditProjectDialogProps = {
  overlay: OverlayState
  project: Project
  updateProject: ReturnType<typeof useUpdateProject>['updateProject']
}

export default function EditProjectNameDialog({
  overlay,
  project,
  updateProject,
}: EditProjectDialogProps) {
  const { projects } = useGetProjects({
    refetchOnWindowFocus: false,
    filters: {
      org_id: project.org_id,
      fields__only: 'id,name',
      limit: '1000',
    },
  })

  const methods = useForm<FormValues>({
    defaultValues: {
      name: project.name,
    },
    mode: 'onChange', // triggers validation onChange
  })

  const { formState, register, reset } = methods
  const nameError = formState.errors.name

  function onSubmit(values: FormValues) {
    updateProject({
      ...project,
      ...values,
    })
    overlay.close()
  }

  useEffect(() => {
    if (!overlay.isOpen) {
      reset({
        name: project.name,
      })
    }
  }, [project.name, overlay.isOpen, reset])

  return (
    <Dialog title="Edit Project" {...overlay} maxWidth="xs">
      <PixyDocsForm methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <FormTextField
            helperText={nameError ? nameError.message : ''}
            error={!!nameError}
            label="Name"
            fullWidth
            required
            requiredErrorText="Name cannot be empty."
            {...register('name', {
              validate: (value) => {
                if (!value.trim().length) return 'Name cannot be empty.'

                if (
                  projects.some(
                    ({ id, name }) =>
                      name.toLowerCase() === value.toLowerCase().trim() &&
                      id !== project.id,
                  )
                )
                  return 'Name must be unique.'

                return true
              },
            })}
          />
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!formState.isDirty || !formState.isValid}
          >
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
