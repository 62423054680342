import { TimePicker } from '@mui/x-date-pickers'
import { TextFieldProps } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'

type TimeFieldProps = {
  onChange: (newValue: string) => void
  value: string
  label?: string
  disabled?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  minDate?: string
  maxDate?: string
  isInTable?: boolean
} & Omit<TextFieldProps, 'onChange' | 'value'>

const convertDateToTime = (date: string) => {
  const components = date.split(':')
  const dayjsTime = dayjs()
    .hour(+components[0])
    .minute(+components[1])
    .second(+components[2])
  return dayjsTime.isValid() ? dayjsTime : undefined
}

export default function TimeField({
  onChange,
  value,
  label,
  disabled,
  inputRef,
  minDate,
  maxDate,
  isInTable,
  ...props
}: TimeFieldProps) {
  let displayValue: Dayjs | null = null
  if (value) {
    displayValue = convertDateToTime(value) || null
  }

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue === null) {
      onChange('')
    } else if (newValue?.isValid()) {
      onChange(newValue.format('HH:mm:ss'))
    }
  }

  return (
    <TimePicker<Dayjs>
      value={displayValue}
      onChange={handleChange}
      slotProps={{
        openPickerIcon: {
          fontSize: isInTable ? 'small' : 'medium',
        },
        openPickerButton: {
          size: 'small',
          tabIndex: -1,
        },
        textField: {
          autoComplete: 'off',
          ...props,
        },
      }}
      label={label}
      disabled={disabled}
      inputRef={inputRef}
      minTime={minDate ? convertDateToTime(minDate) : undefined}
      maxTime={maxDate ? convertDateToTime(maxDate) : undefined}
    />
  )
}
