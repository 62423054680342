import { Tooltip, Typography } from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import { getIdleTime, prettifyDate } from '@/utils/getFormattedDateTimeString'
import LeftBorderedBox from '@/components/validation/LeftBorderedBox'
import SelectMenu from '@/components/select-menu/SelectMenu'

type WorkflowStateSelectMenuProps = {
  selectedWorkflowState: WorkflowState
  workflowStates: WorkflowState[]
  onChange: (workflowState: WorkflowState) => void
  dense?: boolean
  hideLabel?: boolean
  selectedWorkflowStateEnteredAt?: string
}

export default function WorkflowStateSelectMenu({
  selectedWorkflowState,
  workflowStates,
  onChange,
  dense,
  hideLabel,
  selectedWorkflowStateEnteredAt,
}: WorkflowStateSelectMenuProps) {
  const getThemeColor = useGetThemeColor()
  const filteredWorkflowStates = workflowStates.filter(
    ({ code }) => code !== 'processing',
  )

  return (
    <SelectMenu<WorkflowState>
      title="Workflow State"
      selectedLabel={
        <Typography
          component="div"
          variant={dense ? 'caption' : 'body2'}
          color={getThemeColor(selectedWorkflowState?.color)}
          noWrap
        >
          <b>{selectedWorkflowState.name}</b>
          {selectedWorkflowStateEnteredAt && (
            <Tooltip
              enterDelay={500}
              title={`Entered Workflow State: ${prettifyDate(
                selectedWorkflowStateEnteredAt,
              )}`}
            >
              <Typography
                color="text.secondary"
                variant="caption"
                component="p"
                noWrap
                sx={{
                  lineHeight: 1,
                  fontSize: dense ? 11 : undefined,
                }}
              >
                {getIdleTime(selectedWorkflowStateEnteredAt)}
              </Typography>
            </Tooltip>
          )}
        </Typography>
      }
      options={filteredWorkflowStates.map((workflowState) => ({
        label: (
          <LeftBorderedBox color={getThemeColor(workflowState.color)}>
            {workflowState.name}
          </LeftBorderedBox>
        ),
        key: workflowState.id,
        value: workflowState,
      }))}
      onChange={onChange}
      dense={dense}
      hideLabel={hideLabel}
    />
  )
}
