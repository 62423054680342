import { createContext, useContext, ReactNode, useMemo } from 'react'
import { Document } from '@/types/documents'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import useSessionStorage from '@/hooks/useSessionStorage'
import { useDocumentChipsContext } from '@/components/validation/providers/DocumentChipsProvider'

type DocumentImagePageContextValue = {
  imagePage: number
  nonBlankPages: number[]
  pageCount: number
  setImagePage: (
    getNewPage: (prevPage: number) => number,
    isArrow?: boolean,
  ) => void
  thumbnailsOverlay: OverlayState
}

const DocumentImagePageContext = createContext<DocumentImagePageContextValue>(
  {} as DocumentImagePageContextValue,
)

export const useDocumentImagePageContext = () =>
  useContext(DocumentImagePageContext)

type DocumentImagePageProviderProps = {
  children: ReactNode
  document?: Document
}

export default function DocumentImagePageProvider({
  children,
  document,
}: DocumentImagePageProviderProps) {
  const thumbnailsOverlay = useOverlay()
  const [imagePage, setImagePage] = useSessionStorage(
    `validation-image-page-${document?.id}`,
    1,
  )
  const { documentChips } = useDocumentChipsContext()

  const nonBlankPages = useMemo(() => {
    if (document && documentChips.length) {
      // array of boolean values depending on whether the page (index) has chips
      const pagesAndChips = Array.from({ length: document.page_count }).map(
        () => false,
      )
      documentChips.forEach((chip) => {
        pagesAndChips[chip.document_page_number - 1] = true
      })

      // array of pages that have chips
      const tempNonBlankPages: number[] = []
      pagesAndChips.forEach((value, index) => {
        if (value === true) {
          tempNonBlankPages.push(index + 1)
        }
      })
      return tempNonBlankPages
    }
    // Default to the first page when document hasn't loaded yet
    return [1]
  }, [document, documentChips])

  const handleImagePageChange = (
    getNewPage: (prevPage: number) => number,
    isArrow?: boolean,
  ) => {
    setImagePage((prevPage) => {
      const newPage = getNewPage(prevPage)
      if (!isArrow || nonBlankPages.includes(newPage)) return newPage
      const asksForPrevPage = newPage < prevPage
      return asksForPrevPage
        ? (nonBlankPages.findLast((page) => page < newPage) as number)
        : (nonBlankPages.find((page) => page > newPage) as number)
    })
  }

  return (
    <DocumentImagePageContext.Provider
      value={{
        imagePage,
        pageCount: document?.document_pages.length ?? 1,
        nonBlankPages,
        setImagePage: handleImagePageChange,
        thumbnailsOverlay,
      }}
    >
      {children}
    </DocumentImagePageContext.Provider>
  )
}
