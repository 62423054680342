import { Node } from 'reactflow'

type InsertNodeBetweenOptions = {
  sourceNode: Node
  targetNode: Node
  xOffset?: number
  yOffset?: number
  targetNodeIsSourceNodeChild?: boolean
}

export default function getPointBetween({
  sourceNode,
  targetNode,
  xOffset = 0,
  yOffset = 0,
  targetNodeIsSourceNodeChild,
}: InsertNodeBetweenOptions) {
  // If they have the same parent or if there isn't position absolute, use position
  // (we use absolute positioning in case we are dealing with children nodes)
  let { x: x1, y: y1 } =
    sourceNode.parentNode === targetNode.parentNode ||
    !sourceNode.positionAbsolute
      ? sourceNode.position
      : sourceNode.positionAbsolute
  let { x: x2, y: y2 } =
    sourceNode.parentNode === targetNode.parentNode ||
    !targetNode.positionAbsolute
      ? targetNode.position
      : targetNode.positionAbsolute

  // if target node is source node child, use extent value to get the source y position
  if (targetNodeIsSourceNodeChild) {
    ;({ x: x1, y: y1 } = { x: 0, y: +(targetNode.extent?.[0][1] || 0) })
    ;({ x: x2, y: y2 } = targetNode.position)
  }

  const lowestX = Math.min(x1, x2)
  const lowestY = Math.min(y1, y2)
  const highestX = Math.max(x1, x2)
  const highestY = Math.max(y1, y2)

  return {
    x: (highestX + lowestX) / 2 + xOffset,
    y: (highestY + lowestY) / 2 + yOffset,
  }
}
