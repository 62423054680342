import { OrgTree, OrgType, Organization } from '@/types/organizations'

export function getOrgName(organization: Organization) {
  return organization.id === 'all' || organization.parent_org_id !== null
    ? organization.name
    : organization.name.concat(' (Root)')
}

export function getOrganizationType(
  organization: Organization,
  organizationTypes: OrgType[],
) {
  return organizationTypes.find((type) => type.id === organization.org_type_id)
}

export function addSelectionProperties(
  orgTree: OrgTree,
  selectedOrgIds: string[],
) {
  const implicitlySelectedIds: string[] = []
  function updateOrgSelection(currentOrg: OrgTree, parentOrg?: OrgTree) {
    const isSelected = selectedOrgIds.includes(currentOrg.id)
    currentOrg.selected = isSelected || !!parentOrg?.selected
    currentOrg.inheritedSelection =
      parentOrg?.selected && !isSelected ? true : false

    if (currentOrg.inheritedSelection) {
      implicitlySelectedIds.push(currentOrg.id)
    }
    currentOrg.subOrganizations.forEach((subOrgTree) =>
      updateOrgSelection(subOrgTree, currentOrg),
    )
  }

  updateOrgSelection(orgTree)
  return {
    orgTree,
    implicitlySelectedIds,
  }
}

export function createOrganizationMap(organizations: Organization[]) {
  const organizationMap: Record<string, OrgTree> = {}
  organizations.forEach((organization) => {
    organizationMap[organization.id] = {
      ...organization,
      subOrganizations: [],
    }
  })

  organizations.forEach((organization) => {
    if (organization.parent_org_id) {
      // Since we are adding to the existing array, all the instances that reference the
      // array will get updated, so the sub orgs will continue to get populated
      organizationMap[organization.parent_org_id].subOrganizations.push(
        organizationMap[organization.id],
      )
    }
  })

  return organizationMap
}

export function getNodesToExpand(
  selectedId: string,
  organizationMap: Record<string, OrgTree>,
) {
  const expanded = []
  let currentOrgId = selectedId
  while (organizationMap[currentOrgId]?.parent_org_id) {
    const parentOrgId = organizationMap[currentOrgId].parent_org_id as string
    expanded.push(parentOrgId)
    currentOrgId = parentOrgId
  }
  return expanded
}

export function getNodesToExpandForMultipleOrgs(
  organizations: Organization[],
  organizationMap: Record<string, OrgTree>,
) {
  return organizations.reduce<string[]>((acc, org) => {
    if (!org.parent_org_id || acc.includes(org.parent_org_id)) return acc
    const orgTreeNodes = getNodesToExpand(org.id, organizationMap)
    orgTreeNodes.forEach((id) => {
      if (!acc.includes(id)) acc.push(id)
    })
    return acc
  }, [])
}

export function getNodeDescendantsIds(orgTree: OrgTree) {
  const descendantsIds: string[] = []

  function updateDescendantsIds(subOrgTree: OrgTree) {
    descendantsIds.push(subOrgTree.id)
    subOrgTree.subOrganizations.forEach(updateDescendantsIds)
  }

  orgTree.subOrganizations.forEach(updateDescendantsIds)

  return descendantsIds
}

export function getFilteredOrgsNodesData({
  organizations,
  organizationMap,
  defaultExpanded,
  searchedValue,
}: {
  organizations: Organization[]
  organizationMap: Record<string, OrgTree>
  defaultExpanded: string[]
  searchedValue: string
}) {
  if (!searchedValue) {
    return {
      visibleOrgIds: organizations.map(({ id }) => id),
      nodesToExpand: defaultExpanded,
    }
  }
  const filteredOrgs = organizations.filter(({ name }) =>
    name.toLowerCase().includes(searchedValue.toLowerCase()),
  )

  const nodesToExpand = filteredOrgs.reduce<string[]>((acc, org) => {
    if (!org.parent_org_id || acc.includes(org.parent_org_id)) return acc
    const orgTreeNodes = getNodesToExpand(org.id, organizationMap)
    orgTreeNodes.forEach((id) => {
      if (!acc.includes(id)) acc.push(id)
    })
    return acc
  }, [])

  const visibleOrgIds: string[] = [
    ...filteredOrgs.map(({ id }) => id),
    ...nodesToExpand,
  ]
  return {
    visibleOrgIds,
    nodesToExpand,
  }
}
