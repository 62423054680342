/* eslint-disable @typescript-eslint/no-explicit-any */
import PaginatedResponse from '@/types/paginated-response'
import axios from '@/services/auth-v2-axios'
import useQuery from '@/services/hooks/useQuery'
import { UseQueryOptions } from '@tanstack/react-query'

type UseForeignKeyListEndpointProps = {
  url?: string
  enabled?: boolean
} & UseQueryOptions<any[]>

export default function useForeignKeyListEndpoint({
  url = '',
  enabled,
  ...rest
}: UseForeignKeyListEndpointProps) {
  const query = useQuery({
    queryKey: [url],
    queryFn: () => {
      return axios
        .get<PaginatedResponse<any>>(url)
        .then(({ data }) => data.results)
    },
    enabled: !!(enabled && url),
    ...rest,
  })

  return query
}
