import { ReactNode } from 'react'
import { Container, DropResult } from 'react-smooth-dnd'
import { arrayMoveImmutable } from 'array-move'

type DraggableListProps<DataType> = {
  data: DataType[]
  children: ReactNode
  onChange: (listItems: DataType[]) => void
  ghostParent?: HTMLDivElement | null
  lockAxis?: 'x' | 'y'
  orientation?: 'horizontal' | 'vertical'
  dragHandleSelector?: string
}

export function DraggableList<DataType>({
  data,
  children,
  onChange,
  ghostParent,
  lockAxis = 'y',
  orientation = 'vertical',
  dragHandleSelector = '.drag-handle',
}: DraggableListProps<DataType>) {
  const onDrop = ({ removedIndex, addedIndex }: DropResult) => {
    onChange(
      arrayMoveImmutable(data, removedIndex as number, addedIndex as number),
    )
  }

  return (
    // @ts-expect-error -- Container can have children
    <Container
      behaviour="contain"
      dragHandleSelector={dragHandleSelector}
      lockAxis={lockAxis}
      orientation={orientation}
      onDrop={onDrop}
      getGhostParent={() => ghostParent || document.body} // https://github.com/kutlugsahin/react-smooth-dnd/issues/56#issuecomment-545904390
    >
      {children}
    </Container>
  )
}
