import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import PageTitle from '@/components/PageTitle'

export default function UsersPage() {
  return (
    <Container maxWidth="lg">
      <PageTitle>Users</PageTitle>
      <Breadcrumb label="Users" url="/settings/users" />
      <Outlet />
    </Container>
  )
}
