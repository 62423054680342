import { Alert } from '@mui/material'

export default function NotTrainedBanner() {
  return (
    <Alert variant="filled" severity="info">
      This model has not been trained yet. Add at least ten documents to the
      training batch to get started!
    </Alert>
  )
}
