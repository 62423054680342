import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { WorkflowState } from '@/types/workflows'
import { createDetail, getDetail } from '../request-wrappers'

const service = buildService<WorkflowState>({
  pathCategory: 'workflow_states',
})

export function useGetWorkflowState({
  id,
  ...options
}: UseGetDetailOptions<WorkflowState>) {
  const query = useGetDetail<WorkflowState>({
    id,
    queryKey: queryKeys.workflowStates.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    workflowState: query.data,
    ...query,
  }
}

export function useCreateWorkflowState(
  options: UseCreateDetailOptions<WorkflowState> = {},
) {
  const mutation = useCreateDetail<WorkflowState>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateWorkflowState(
  options: UseUpdateDetailOptions<WorkflowState> = {},
) {
  const mutation = useUpdateDetail<WorkflowState>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteWorkflowState(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<WorkflowState>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetWorkflowStates({
  filters,
  ...options
}: UseGetListOptions<WorkflowState> = {}) {
  const query = useGetList<WorkflowState>({
    filters,
    queryKey: queryKeys.workflowStates.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    workflowStates: query.allData,
    ...query,
  }
}

export function useUpdateWorkflowStates(
  options: UseUpdateListOptions<WorkflowState> = {},
) {
  const mutation = useUpdateList<WorkflowState>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateWorkflowStates: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteWorkflowStates({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<WorkflowState>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteWorkflowStates: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Create Workflow State with Defaults
 * Creates a workflow state, but also creates defaults for it, such as on_enter and on_exit actions.
 */
export function useCreateWorkflowStateWithDefaults(
  options: UseCreateDetailOptions<WorkflowState> = {},
) {
  const mutation = useCreateDetail<WorkflowState>({
    serviceFn: (serviceFnOptions) => {
      return createDetail({
        url: '/v2/pd/workflow_states/create_with_defaults',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    createWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Get Workflow State Field Counts
 * Gets the counts for fields within a workflow state.
 */
type FieldCounts = { field_counts: Record<string, number> }
export function useGetWorkflowStateFieldCounts({
  id, // This is the workflow state ID
  ...options
}: UseGetDetailOptions<FieldCounts>) {
  const query = useGetDetail<FieldCounts>({
    id,
    queryKey: queryKeys.workflowStates.detail(
      { id, filters: options.filters },
      ['field_counts'],
    ),
    serviceFn: (serviceFnOptions) => {
      return getDetail({
        url: `/v2/pd/workflow_states/${id}/field_count`,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    fieldCounts: query.data?.field_counts,
    ...query,
  }
}

/**
 * MARK: Action Hook: Get Workflow State Picker Item Counts
 * Gets the counts for fields within a workflow state.
 */
type ItemCounts = { item_counts: Record<string, number> }
export function useGetWorkflowStatePickerItemCounts({
  id, // This is the workflow state ID
  pickerFieldId, // The ID of the picker field you want counts for
  ...options
}: UseGetDetailOptions<ItemCounts> & {
  pickerFieldId: string
}) {
  const query = useGetDetail<ItemCounts>({
    id,
    queryKey: queryKeys.workflowStates.detail(
      { id, filters: options.filters },
      ['item_counts', pickerFieldId],
    ),
    serviceFn: ({ id, filters, axiosOptions }) => {
      return getDetail({
        url: `/v2/pd/workflow_states/${id}/picker_item_count`,
        filters: {
          picker_field_id: pickerFieldId,
          ...filters,
        },
        axiosOptions,
      })
    },
    ...options,
  })

  return {
    itemCounts: query.data?.item_counts,
    ...query,
  }
}
