import { MenuItem } from '@mui/material'
import { FormSelect } from '../forms'
import { ProjectGridField } from '@/types/fields'

type TargetFieldSelectProps = {
  projectGridFields: ProjectGridField[]
  required?: boolean
}

export default function TargetFieldSelect({
  projectGridFields = [],
  required = false,
}: TargetFieldSelectProps) {
  return (
    <FormSelect
      name="target_field_name"
      label="Field"
      size="small"
      required={required}
      InputLabelProps={{ shrink: true }}
    >
      {projectGridFields.map((projectGridField) => {
        return (
          <MenuItem key={projectGridField.id} value={projectGridField.name}>
            {projectGridField.name}
          </MenuItem>
        )
      })}
    </FormSelect>
  )
}
