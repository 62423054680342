import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { MRT_ColumnDef } from 'material-react-table'
import { Add } from '@mui/icons-material'
import { Stack, Typography, Button, useTheme } from '@mui/material'
import { Group } from '@/types/groups'
import { User } from '@/types/users'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import { useGetTeams } from '@/service-library/hooks/teams'
import { useGetUsers } from '@/service-library/hooks/users'
import BaseUsersTable from '@/components/user-table/BaseUsersTable'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import AddUserDialog from './AddUserDialog'

export default function UsersTable() {
  const theme = useTheme()
  const navigate = useNavigate()
  const { rootOrganization } = useRootOrganization()
  const { hasEditingPermission } = usePermission()

  const { users, isLoading } = useGetUsers({
    filters: {
      limit: '1000',
      owner_org_id: rootOrganization.id,
      fields__include: 'pd_groups_ids,pd_groups,teams_ids',
    },
  })

  const { teams } = useGetTeams({
    filters: {
      owner_org_id: rootOrganization.id,
      is_system_managed: 'false',
      limit: '1000',
      fields__only: 'id,name',
    },
    enabled: !!rootOrganization.id,
  })

  const teamsNameMap: Record<string, string> = useMemo(
    () => teams.reduce((acc, team) => ({ ...acc, [team.id]: team.name }), {}),
    [teams],
  )

  const addOverlay = useOverlay()

  const canAddUser = hasEditingPermission('edit_users')

  const columns: MRT_ColumnDef<User>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        enableClickToCopy: true,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableClickToCopy: true,
      },
      {
        accessorKey: 'pd_groups',
        header: 'Permission Groups',
        enableSorting: false,
        Cell: ({ cell }) => (
          <Stack spacing={0.5}>
            {(cell.getValue() as Group[]).map(({ id, name }) => (
              <Typography key={id} variant="body2">
                {name}
              </Typography>
            ))}
            <Typography variant="body2">User</Typography>
          </Stack>
        ),
      },
      {
        accessorKey: 'teams_ids',
        header: 'Teams',
        enableSorting: false,
        Cell: ({ cell }) => (
          <Stack spacing={0.5}>
            {(cell.getValue() as string[]).map((id) => {
              if (!teamsNameMap[id]) return null
              return (
                <Typography key={id} variant="body2">
                  {teamsNameMap[id]}
                </Typography>
              )
            })}
          </Stack>
        ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        Cell: ({ cell }) => (
          <Typography variant="body2">
            {cell.getValue() ? 'Active' : 'Inactive'}
          </Typography>
        ),
      },
    ],
    [teamsNameMap],
  )

  return (
    <>
      <BaseUsersTable
        users={users}
        columns={columns}
        enableMultiRowSelection={false}
        isLoading={isLoading}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            navigate(`/settings/users/${row.original.id}`)
          },
          sx: {
            cursor: 'pointer',
            'button, p': {
              color: row.original.is_active ? '' : theme.palette.text.disabled,
            },
          },
        })}
        renderTableActions={
          canAddUser
            ? () => (
                <Button
                  disabled={!canAddUser}
                  onClick={() => {
                    addOverlay.open()
                  }}
                  startIcon={<Add />}
                  variant="text"
                >
                  New User
                </Button>
              )
            : undefined
        }
      />
      {canAddUser && <AddUserDialog overlay={addOverlay} />}
    </>
  )
}
