import { useNavigate } from 'react-router-dom'
import { Alert, Button } from '@mui/material'
import { ProjectModelVersion } from '@/types/project-models'
import { useDeleteProjectModelVersion } from '@/service-library/hooks/project-model-versions'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'

type TrainInfoBannerProps = {
  modelVersion: ProjectModelVersion
}

export default function TrainInfoBanner({
  modelVersion,
}: TrainInfoBannerProps) {
  const navigate = useNavigate()
  const { deleteProjectModelVersion } = useDeleteProjectModelVersion({
    sideEffectQueryKeys: [
      queryKeys.projectModels.detail({
        id: modelVersion.project_model_id,
      }),
    ],
    onError: () => {
      showErrorSnackbar('Unable to delete version. Please try again later.')
    },
    onSuccess: () => {
      showSuccessSnackbar('Version Deleted')
      navigate('..', { relative: 'path' })
    },
  })

  return (
    <Alert
      variant="filled"
      severity={modelVersion.training_status === 'error' ? 'error' : 'info'}
      action={
        <Button
          color="inherit"
          size="small"
          variant="text"
          onClick={() => {
            deleteProjectModelVersion(modelVersion.id)
          }}
        >
          Delete{' '}
          {modelVersion.training_status === 'error' ? 'Failed' : 'Canceled'}{' '}
          Version
        </Button>
      }
    >
      {modelVersion.training_status === 'error'
        ? 'This version of the model failed to train properly. If the issue persists after retrying, please contact support.'
        : 'The training for this version of the model was canceled.'}
    </Alert>
  )
}
