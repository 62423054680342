import { NodeProps } from 'reactflow'
import GroupNode from './GroupNode'
import { NodeData } from '../types'

export default function IterationNode({ data, ...props }: NodeProps<NodeData>) {
  const iterationInputs = data.logixNode.node_type.type_in_handles.filter(
    ({ area }) => area === 'iteration-right',
  )
  const iterationOutputs = data.logixNode.node_type.type_out_handles.filter(
    ({ area }) => area === 'iteration-left',
  )

  return (
    <GroupNode
      data={data}
      internalInputs={iterationInputs}
      internalOutputs={iterationOutputs}
      {...props}
    />
  )
}
