import { forwardRef } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { FieldValues, useController, FieldPath } from 'react-hook-form'

type FormTextFieldProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label?: string
  requiredErrorText?: string
} & TextFieldProps

const FormTextField = forwardRef(function FormTextField<
  FormValues extends FieldValues,
>(
  {
    name,
    required,
    requiredErrorText,
    ...props
  }: FormTextFieldProps<FormValues>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { field } = useController<FormValues>({
    name,
    rules: {
      required: required && requiredErrorText,
    },
  })
  return <TextField required={required} {...props} {...field} inputRef={ref} />
})

export default FormTextField
