import { OrgType } from '@/types/organizations'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<OrgType>({
  pathCategory: 'org_types',
})

export function useGetOrgType({
  id,
  ...options
}: UseGetDetailOptions<OrgType>) {
  const query = useGetDetail<OrgType>({
    id,
    queryKey: queryKeys.orgTypes.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    orgType: query.data,
    ...query,
  }
}

export function useCreateOrgType(
  options: UseCreateDetailOptions<OrgType> = {},
) {
  const mutation = useCreateDetail<OrgType>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createOrgType: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateOrgType(
  options: UseUpdateDetailOptions<OrgType> = {},
) {
  const mutation = useUpdateDetail<OrgType>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateOrgType: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteOrgType(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<OrgType>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteOrgType: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetOrgTypes({
  filters,
  ...options
}: UseGetListOptions<OrgType> = {}) {
  const query = useGetList<OrgType>({
    filters,
    queryKey: queryKeys.orgTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    orgTypes: query.allData,
    ...query,
  }
}

export function useUpdateOrgTypes(options: UseUpdateListOptions<OrgType> = {}) {
  const mutation = useUpdateList<OrgType>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateOrgTypes: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteOrgTypes({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<OrgType>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteOrgTypes: mutation.mutateAsync,
    ...mutation,
  }
}
