import { Box, Tooltip, useTheme } from '@mui/material'
import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  memo,
  useRef,
} from 'react'
import { useDocumentFiltersContext } from '../document-filters/DocumentFiltersProvider'
import { Height } from '@mui/icons-material'
import { DragonFillRow, useDragonFillContext } from './DragonFillProvider'
import { DocumentRow, DocumentRowValue } from '@/types/documents'
import { MRT_TableInstance, MRT_Row, MRT_Column } from 'material-react-table'
import { indigo } from '@mui/material/colors'
import { useCreateUpdateDocumentRowValuesWithCleanup } from '@/service-library/hooks/document-row-values'
import queryKeys from '@/service-library/query-keys'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'

type PickerCellProps = {
  // Got this type from MRT
  value:
    | string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | undefined
  table: MRT_TableInstance<Required<DocumentRow>>
  row: MRT_Row<Required<DocumentRow>>
  column: MRT_Column<Required<DocumentRow>>
}

function PickerCell({ value, table, row, column }: PickerCellProps) {
  const theme = useTheme()
  const { documentFilters } = useDocumentFiltersContext()
  const [dragonFillRows, setDragonFillRows] = useDragonFillContext()

  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { createOrUpdateDocumentRowValues } =
    useCreateUpdateDocumentRowValuesWithCleanup({
      workflowId: selectedWorkflow.id,
      sideEffectQueryKeys: [queryKeys.documentRows.lists()],
      onError: () => {
        showErrorSnackbar(
          'Failed to update some document values. Please try again later.',
        )
      },
    })

  const cellContainerRef = useRef<HTMLDivElement>(null)
  const isDraggingRef = useRef(false)
  const dragonFillRowsRef = useRef<DragonFillRow[]>([])
  const startYPositionRef = useRef(0)

  // @ts-expect-error -- Not worth typing
  const { field } = column.columnDef.meta

  const documentRowValueForCurrentRow = row.original.document_row_values.find(
    (docRowValue) => docRowValue.project_grid_field_id === field.id,
  )

  function onMouseDown(event: React.MouseEvent) {
    event.preventDefault()
    event.stopPropagation()

    isDraggingRef.current = true
    const { height: cellHeight = 0, top = 0 } =
      cellContainerRef.current?.getBoundingClientRect() || {}
    startYPositionRef.current = top

    function onMouseMove(event: MouseEvent) {
      event.stopPropagation()
      event.preventDefault()
      if (!isDraggingRef.current) return
      const diff = event.clientY - startYPositionRef.current
      const numberOfRowsSelected = Math.floor(diff / (cellHeight + 1))

      // Set highlighted values based on number of rows selected and the current row index
      const rows = table.getRowModel().rows
      const selectedRows =
        numberOfRowsSelected > 0
          ? // For positive number, select rows that come after the current row
            rows.slice(row.index, row.index + numberOfRowsSelected + 1)
          : // For negative number, select rows that come before the current row
            rows.slice(row.index + numberOfRowsSelected, row.index + 1)

      const newDragonFillRows = selectedRows.map<DragonFillRow>((tableRow) => {
        const documentRowValue = tableRow.original.document_row_values.find(
          (docRowValue) => docRowValue.project_grid_field_id === field.id,
        )
        return {
          id: `row-${tableRow.index}-field-${field.id}`,
          documentRowValue: documentRowValue || null,
          documentRowId: tableRow.original.id,
        }
      })

      dragonFillRowsRef.current = newDragonFillRows
      setDragonFillRows(newDragonFillRows)
    }

    function onMouseUp(event: MouseEvent) {
      event.stopPropagation()
      event.preventDefault()
      isDraggingRef.current = false
      startYPositionRef.current = 0

      window.removeEventListener('mousemove', onMouseMove)

      // Update and create document row values
      const newDocumentRowValues = dragonFillRowsRef.current.map(
        (dragonFillRow) => {
          return {
            ...documentRowValueForCurrentRow,
            document_row_id: dragonFillRow.documentRowId,
            id: dragonFillRow.documentRowValue?.id || generateUuid(),
          }
        },
      ) as DocumentRowValue[]

      createOrUpdateDocumentRowValues(
        // Filter out the original row value since it doesn't need to be updated
        newDocumentRowValues.filter(
          ({ id }) => id !== documentRowValueForCurrentRow?.id,
        ),
      )

      dragonFillRowsRef.current = []
      // Push to next event cycle so other click events firing (like the one to navigate to the doc) don't interfere
      setTimeout(() => {
        setDragonFillRows([])
      }, 0)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp, { once: true })
  }

  const isHighlightedCell = dragonFillRows.find(
    ({ id }) => id === `row-${row.index}-field-${field.id}`,
  )
  const isFirstHighlightedCell =
    dragonFillRows[0]?.id === `row-${row.index}-field-${field.id}`
  const isLastHighlightedCell =
    dragonFillRows.at(-1)?.id === `row-${row.index}-field-${field.id}`

  return (
    <Box
      ref={cellContainerRef}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1000000,
        border: 'solid 2px',
        borderColor: isHighlightedCell ? indigo[500] : 'transparent',
        borderBottomColor: isLastHighlightedCell ? indigo[500] : 'transparent',
        borderTopColor: isFirstHighlightedCell ? indigo[500] : 'transparent',
        borderTopLeftRadius: isFirstHighlightedCell ? 4 : 0,
        borderTopRightRadius: isFirstHighlightedCell ? 4 : 0,
        borderBottomLeftRadius: isLastHighlightedCell ? 4 : 0,
        borderBottomRightRadius: isLastHighlightedCell ? 4 : 0,
      }}
    >
      {value}
      {documentFilters?.orgIds.length === 1 &&
        dragonFillRows.length === 0 &&
        documentRowValueForCurrentRow && (
          <Tooltip title="Drag Up or Down to Fill" enterDelay={1500}>
            <Box
              role="button"
              onMouseDown={onMouseDown}
              sx={{
                height: '100%',
                display: 'none',
                '*:hover > &': {
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}
            >
              <Height sx={{ fontSize: 16 }} />
            </Box>
          </Tooltip>
        )}
    </Box>
  )
}

export default memo(PickerCell)
