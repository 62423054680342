import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { MRT_SortingState, MRT_Updater } from 'material-react-table'
import { useViewsContext } from './ViewsProvider'

type ColumnSortingContextValue = {
  sorting: MRT_SortingState
  handleSortingChange: (
    columnsSortingUpdaterOrValue: MRT_Updater<MRT_SortingState>,
  ) => void // we use this function when we need to enable the user to save/update view
  resetColumnSorting: () => void
  setSorting: Dispatch<SetStateAction<MRT_SortingState>>
}

const ColumnSortingContext = createContext<ColumnSortingContextValue>(
  {} as ColumnSortingContextValue,
)

export const useColumnSortingContext = () => useContext(ColumnSortingContext)

type ColumnSortingProviderProps = {
  children: ReactNode
}

export default function ColumnSortingProvider({
  children,
}: ColumnSortingProviderProps) {
  const { selectedView, handleChange } = useViewsContext()
  const [sorting, setSorting] = useState<MRT_SortingState>(
    selectedView.state.sorting || [],
  )

  const handleSortingChange = (
    columnsSortingUpdaterOrValue: MRT_Updater<MRT_SortingState>,
  ) => {
    handleChange()
    setSorting(columnsSortingUpdaterOrValue)
  }

  const resetColumnSorting = () => {
    setSorting(selectedView.state.sorting || [])
  }

  return (
    <ColumnSortingContext.Provider
      value={{
        sorting,
        handleSortingChange,
        resetColumnSorting,
        setSorting,
      }}
    >
      {children}
    </ColumnSortingContext.Provider>
  )
}
