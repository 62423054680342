import { Fragment } from 'react'
import { Alert, Box, Skeleton, Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import {
  CustomVariable,
  CustomVariableValue as TSCustomVariableValue,
} from '@/types/custom-variables'
import { Organization } from '@/types/organizations'
import usePermission from '@/hooks/usePermission'
import {
  useCreateCustomVariableValue,
  useDeleteCustomVariableValue,
  useGetCustomVariableValues,
  useUpdateCustomVariableValue,
} from '@/service-library/hooks/custom-variable-values'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import LargeHeading from '@/components/large-heading/LargeHeading'
import CustomVariableValue from './CustomVariableValue'

type CustomVariablesPerOrgProps = {
  customVariables: CustomVariable[]
  organization?: Organization
}
export default function CustomVariablesPerOrg({
  customVariables,
  organization,
}: CustomVariablesPerOrgProps) {
  const { hasEditingPermission } = usePermission()

  const { customVariableValues, isLoading, queryKey } =
    useGetCustomVariableValues({
      filters: {
        owner_org_id: organization?.id || '',
        limit: '1000',
      },
      enabled: !!organization?.id,
    })

  const { createCustomVariableValue } = useCreateCustomVariableValue({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Failed to update value. Please try again later.')
    },
  })
  const { deleteCustomVariableValue } = useDeleteCustomVariableValue({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Failed to delete value. Please try again later.')
    },
  })
  const { updateCustomVariableValue } = useUpdateCustomVariableValue({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Failed to update value. Please try again later.')
    },
  })

  function handleCreateUpdateValue({
    value,
    customVariableId,
    customVariableValue,
  }: {
    value: string
    customVariableId: string
    customVariableValue?: TSCustomVariableValue
  }) {
    if (!organization) return
    if (!customVariableValue) {
      createCustomVariableValue({
        id: generateUuid(),
        value,
        custom_variable_id: customVariableId,
        owner_org_id: organization.id,
      })
    } else {
      updateCustomVariableValue({ ...customVariableValue, value })
    }
  }

  return (
    <Stack spacing={2} sx={{ p: 2, pt: 3, flexGrow: 1, overflow: 'auto' }}>
      {organization ? (
        <>
          <LargeHeading
            heading={organization.name}
            subHeading={
              organization.parent_org_id ? 'Organization' : 'Root Organization'
            }
          />
          <Box>
            <Typography variant="h6">Custom Variables</Typography>
            <Typography variant="body2" color="text.secondary">
              These variables can be used in custom python scripts and logix
              boards. These values are specific to this organization.
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Custom variables are inherited from parent organizations.
            </Typography>
          </Box>
          <Grid2 container spacing={1} sx={{ alignItems: 'center' }}>
            <Grid2 direction="row" xs={5}>
              <Typography variant="body2">Variable</Typography>
            </Grid2>
            <Grid2 direction="row" xs={7}>
              <Typography variant="body2">Value</Typography>
            </Grid2>

            {customVariables.map((customVariable) => {
              const variableValue = customVariableValues.find(
                ({ custom_variable_id }) =>
                  custom_variable_id === customVariable.id,
              )
              return (
                <Fragment key={customVariable.id}>
                  <Grid2 direction="row" xs={5}>
                    <Typography noWrap>{customVariable.name}</Typography>
                  </Grid2>
                  <Grid2
                    direction="row"
                    xs={6} // for some reason using 7 makes it wrap
                    container
                    sx={{ alignItems: 'center' }}
                  >
                    {isLoading ? (
                      <Skeleton width="80%" height="35px" />
                    ) : (
                      <CustomVariableValue
                        canEdit={hasEditingPermission(
                          'edit_orgs',
                          organization.id,
                        )}
                        isRootOrg={!organization.parent_org_id}
                        variableValue={variableValue}
                        handleOnBlur={(newValue) => {
                          handleCreateUpdateValue({
                            value: newValue,
                            customVariableId: customVariable.id,
                            customVariableValue: variableValue,
                          })
                        }}
                        onDelete={deleteCustomVariableValue}
                      />
                    )}
                  </Grid2>
                </Fragment>
              )
            })}
          </Grid2>
        </>
      ) : (
        <Alert severity="error">
          Unable to load variables for this organization. You may not have
          access or this organization does not exist.
        </Alert>
      )}
    </Stack>
  )
}
