import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { ProjectMapping } from '@/types/project-mappings'

const service = buildService<ProjectMapping>({
  pathCategory: 'project_mappings',
})

export function useGetProjectMapping({
  id,
  ...options
}: UseGetDetailOptions<ProjectMapping>) {
  const query = useGetDetail<ProjectMapping>({
    id,
    queryKey: queryKeys.projectMappings.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectMapping: query.data,
    ...query,
  }
}

export function useCreateProjectMapping(
  options: UseCreateDetailOptions<ProjectMapping> = {},
) {
  const mutation = useCreateDetail<ProjectMapping>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectMapping: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectMapping(
  options: UseUpdateDetailOptions<ProjectMapping> = {},
) {
  const mutation = useUpdateDetail<ProjectMapping>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectMapping: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectMapping(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<ProjectMapping>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectMapping: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectMappings({
  filters,
  ...options
}: UseGetListOptions<ProjectMapping> = {}) {
  const query = useGetList<ProjectMapping>({
    filters,
    queryKey: queryKeys.projectMappings.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectMappings: query.allData,
    ...query,
  }
}

export function useUpdateProjectMappings(
  options: UseUpdateListOptions<ProjectMapping> = {},
) {
  const mutation = useUpdateList<ProjectMapping>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectMappings: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectMappings({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectMapping>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectMappings: mutation.mutateAsync,
    ...mutation,
  }
}
