/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '@/services/auth-v2-axios'
import useQuery from '@/services/hooks/useQuery'
import { UseQueryOptions } from '@tanstack/react-query'

type UseForeignKeyDetailEndpointProps<T> = {
  url: string
  id: string
  enabled?: boolean
} & UseQueryOptions<T>

export default function useForeignKeyDetailEndpoint<T>({
  url = '',
  id,
  enabled,
  ...rest
}: UseForeignKeyDetailEndpointProps<T>) {
  const query = useQuery({
    queryKey: [url, id],
    queryFn: () => {
      return axios.get<T>(`${url}/${id}`).then(({ data }) => data)
    },
    enabled: !!(enabled && url),
    ...rest,
  })

  return query
}
