import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { MRT_TableOptions } from 'material-react-table'
import {
  Stack,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@mui/material'
import { PersonRemove } from '@mui/icons-material'
import { Team } from '@/types/teams'
import { User } from '@/types/users'
import {
  useUpdateTeamUsers,
  useDeleteByTeamAndUsers,
} from '@/service-library/hooks/team-users'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import BaseUsersTable from '@/components/user-table/BaseUsersTable'
import UserSearchInput from '@/components/users/UserSearchInput'

type TeamUsersTableProps = {
  // The team to display users for.
  team: Team

  // Boolean to know if user has the necessary permissions.
  canAddRemoveUsers: boolean

  // The query key to use when updating the team.
  detailQueryKey: QueryKey

  // The text used for the buttons to remove users from the team.
  removeText?: string
} & Partial<MRT_TableOptions<User>>

export default function TeamUsersTable({
  team,
  canAddRemoveUsers,
  detailQueryKey,
  removeText = 'Remove from Team',
  ...props
}: TeamUsersTableProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { updateTeamUsers: createTeamUsers } = useUpdateTeamUsers({
    onError: () => {
      showErrorSnackbar('Unable to add user(s). Please try again later.')
      queryClient.invalidateQueries(detailQueryKey)
    },
  })

  const { deleteByTeamAndUsers } = useDeleteByTeamAndUsers({
    onMutate: (removedTeamUsers) => {
      const userIds = removedTeamUsers.users.map(({ id }) => id)
      const newUsers = team.users?.filter((user) => !userIds.includes(user.id))
      queryClient.setQueryData(detailQueryKey, {
        ...team,
        users: newUsers,
        users_ids: newUsers?.map((user) => user.id),
      })
    },
    onError: (_e, teamUsers) => {
      showErrorSnackbar('Unable to remove user(s). Please try again later.')
      queryClient.setQueryData(detailQueryKey, {
        ...team,
        users: [...(team.users || []), ...teamUsers.users],
        users_ids: [
          ...(team.users_ids || []),
          ...teamUsers.users.map((user) => user.id),
        ],
      })
    },
  })

  function handleAddUserToTeam(user: User) {
    if (!user.id) return
    createTeamUsers([
      {
        id: generateUuid(),
        team_id: team.id,
        user_id: user.id,
      },
    ])
    const newUsers = [user, ...(team.users || [])]
    queryClient.setQueryData(detailQueryKey, {
      ...team,
      users: newUsers,
      users_ids: newUsers.map((user) => user.id),
    })
  }

  function handleRemoveUsersFromTeam(users: User[]) {
    deleteByTeamAndUsers({ team, users })
  }

  // Sort by name for the time being. Will add proper, backend-supported sorting later.
  const displayedUsers = [...(team.users || [])].sort((a, b) => {
    if (!a.name || !b.name) return 0
    return a.name.localeCompare(b.name)
  })

  return (
    <Stack spacing={2}>
      {canAddRemoveUsers && (
        <Box sx={{ maxWidth: 300 }}>
          <UserSearchInput
            onUserClick={handleAddUserToTeam}
            label="Add User"
            placeholder="Search for a user..."
            disabledItemIDs={team.users?.map((user) => user.id)}
          />
        </Box>
      )}

      <BaseUsersTable
        users={displayedUsers}
        enableRowSelection={canAddRemoveUsers}
        enableMultiRowSelection={canAddRemoveUsers}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            navigate(`/settings/users/${row.original.id}`)
          },
          sx: {
            cursor: 'pointer',
          },
        })}
        renderTableActions={({ table }) => {
          const { rows } = table.getSelectedRowModel()
          return (
            <>
              {rows.length > 0 ? (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Button
                    variant="text"
                    startIcon={<PersonRemove />}
                    onClick={() => {
                      handleRemoveUsersFromTeam(rows.map((row) => row.original))
                      table.resetRowSelection()
                    }}
                  >
                    {removeText} ({rows.length})
                  </Button>
                </Stack>
              ) : null}
            </>
          )
        }}
        renderRowActionMenuItems={
          canAddRemoveUsers
            ? ({ closeMenu, row }) => {
                return [
                  <MenuItem
                    key="remove"
                    onClick={() => {
                      closeMenu()
                      handleRemoveUsersFromTeam([row.original])
                      if (row.getIsSelected()) row.toggleSelected() // The row is removed from the table, but not from the selected state
                    }}
                  >
                    <ListItemIcon>
                      <PersonRemove />
                    </ListItemIcon>
                    <ListItemText primary={removeText} />
                  </MenuItem>,
                ]
              }
            : undefined
        }
        {...props}
      />
    </Stack>
  )
}
