import { useMemo } from 'react'
import { HandleProps, useNodeId, useStore } from 'reactflow'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { LogixHandle } from '@/types/logix'
import { getEdgeConnectedToHandle } from '../helpers/logix-helpers'
import useConditionProblem from '../hooks/useConditionProblem'
import { useEdgesContext } from '../EdgesProvider'
import { NodeProblem } from '../ProblemsProvider'
import BaseExecutionHandle from './BaseExecutionHandle'

type ExecutionHandleProps = HandleProps & {
  logixHandle: LogixHandle
  hideLabel?: boolean
  style?: React.CSSProperties
}

export default function ExecutionHandle({
  logixHandle,
  hideLabel,
  ...props
}: ExecutionHandleProps) {
  const { edges } = useEdgesContext()

  const { id, name, area } = logixHandle

  const connectionClickStartHandle = useStore(
    (state) => state.connectionClickStartHandle,
  )

  const nodeId = useNodeId()

  const edge = edges ? getEdgeConnectedToHandle(id, edges) : undefined

  const isClickStartHandle =
    connectionClickStartHandle &&
    connectionClickStartHandle.handleId === id &&
    connectionClickStartHandle.nodeId === nodeId

  const { position } = props
  const isInput = position === 'left'

  const direction = isInput ? 'row-reverse' : 'row'
  const justifyContent = isInput ? 'left' : 'right'

  const problem = useMemo(
    () => ({
      condition: !edge,
      enabled: isInput,
      problem: {
        message: 'Missing execution line connection.',
        type: 'execution-input-missing-connection',
        severity: 'error',
        unique: true,
      } as NodeProblem,
    }),
    [edge, isInput],
  )
  useConditionProblem(problem)

  return (
    <Stack
      direction={direction}
      alignItems="center"
      spacing={0.5}
      justifyContent={justifyContent}
      sx={{ transform: 'translateX(4px)' }}
    >
      {name && !hideLabel && (
        <Typography
          variant="caption"
          color={edge && area !== 'node' ? undefined : 'text.secondary'}
          sx={{ lineHeight: '12px' }}
        >
          {name}
        </Typography>
      )}

      <Tooltip
        title="Execution Line"
        disableInteractive
        placement="top"
        enterDelay={1500}
      >
        {/* Box here so it can handle ref forwarding from Tooltip */}
        <Box>
          <BaseExecutionHandle
            {...props}
            id={id}
            // Execution handles can only have one edge
            isConnectable={isInput || (!isInput && !edge)}
            isClickStartHandle={isClickStartHandle}
          />
        </Box>
      </Tooltip>
    </Stack>
  )
}
