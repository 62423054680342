import {
  AccountCircleOutlined,
  BarChart,
  BlurCircular,
  ChatBubbleOutlineOutlined,
  ExpandMore,
  MenuBookOutlined,
  TuneOutlined,
  WidgetsOutlined,
} from '@mui/icons-material'
import { Divider, Drawer, Stack, Typography } from '@mui/material'

export default function SRDrawer() {
  return (
    <Drawer
      sx={{ width: 250 }}
      PaperProps={{ sx: { width: 250, pt: '60px' } }}
      variant="permanent"
    >
      <Stack direction="row" alignItems="center" sx={{ p: 2 }} spacing={1}>
        <BarChart fontSize="small" />
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Leaderboards
        </Typography>
        <ExpandMore />
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ p: 2 }} spacing={1}>
        <BlurCircular fontSize="small" />
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          SalesHub
        </Typography>
        <ExpandMore />
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ p: 2 }} spacing={1}>
        <ChatBubbleOutlineOutlined fontSize="small" />
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Messages
        </Typography>
        <ExpandMore />
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ p: 2 }} spacing={1}>
        <AccountCircleOutlined fontSize="small" />
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Users
        </Typography>
      </Stack>

      <Divider />

      <Stack direction="row" alignItems="center" sx={{ p: 2 }} spacing={1}>
        <MenuBookOutlined fontSize="small" />
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Learn
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ p: 2 }} spacing={1}>
        <TuneOutlined fontSize="small" />
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Settings
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ p: 2 }} spacing={1}>
        <WidgetsOutlined fontSize="small" />
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Integrations
        </Typography>
        <ExpandMore />
      </Stack>
    </Drawer>
  )
}
