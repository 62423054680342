import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useAuthentication } from '@/components/auth/AuthProvider'

export default function usePermission() {
  const { user } = useAuthentication()
  const { rootOrganization } = useRootOrganization()

  const hasEditingPermission = (permission: string, orgId?: string) => {
    if (!user) return false
    const organizationId = orgId || rootOrganization.id
    return (
      user.resolved_permission_codes.includes('*superuser*') ||
      (user.resolved_permission_codes.includes(permission) &&
        user.read_write_org_ids.includes(organizationId))
    )
  }

  return { hasEditingPermission }
}
