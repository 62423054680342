import { MRT_Updater, MRT_VisibilityState } from 'material-react-table'
import { FormControlLabel, Checkbox } from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import { createDataLineageColumn } from '@/utils/field-utils'
import { OnFieldChange } from './AllFieldsFilter'

type FieldFilterProps = {
  fields: ProjectGridField[]
  field: ProjectGridField
  columnVisibility: MRT_VisibilityState
  handleColumnVisibilityChange: (
    columnVisibilityUpdaterOrValue: MRT_Updater<MRT_VisibilityState>,
  ) => void
  onFieldChange: OnFieldChange
}

export default function FieldFilter({
  fields,
  field,
  columnVisibility,
  handleColumnVisibilityChange,
  onFieldChange,
}: FieldFilterProps) {
  const hideDataLineageColumns = (fieldId: string) => {
    handleColumnVisibilityChange((prev) => {
      const columns = createDataLineageColumn({ fieldId, groupValue: false })
      return {
        ...prev,
        ...columns,
      }
    })
  }

  return (
    <FormControlLabel
      key={field.id}
      label={field.name}
      control={
        <Checkbox
          sx={{ padding: 0 }}
          color="neutral"
          checked={!!columnVisibility[field.id]}
          onChange={({ target }) => {
            !target.checked && hideDataLineageColumns(field.id)
            onFieldChange({
              fields,
              fieldId: field.id,
              checked: target.checked,
            })
          }}
        />
      }
      componentsProps={{ typography: { variant: 'body2' } }}
      sx={{ mx: 0, columnGap: 0.5 }}
    />
  )
}
