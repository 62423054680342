import { useRef, useState, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Container, Typography, Stack } from '@mui/material'
import SetupStep from './SetupStep'
import FieldsStep from './FieldsStep'
import { Project } from '@/types/projects'
import { useTransition, animated } from '@react-spring/web'
import { useGetProject } from '@/service-library/hooks/projects'

const steps = [
  {
    label: 'Setup',
    key: 'details',
  },
  {
    label: 'Fields',
    key: 'field_config',
  },
]

type ProjectWizardProps = {
  projects: Project[]
  existingProject: Project
}

export default function ProjectWizard({
  projects,
  existingProject,
}: ProjectWizardProps) {
  const location = useLocation()
  const currentStep = steps.find((step) => {
    return location.pathname.includes(step.key)
  })
  const navigate = useNavigate()
  const activeStep = steps.findIndex((step) => step.key === currentStep?.key)

  const [project, setProject] = useState<Project>(existingProject)

  const { project: fullProject, isLoading } = useGetProject({
    id: existingProject.id,
    filters: {
      fields__include: 'project_category,project_grids',
      project_grids__fields__include:
        'project_grid_fields,sub_project_grid_fields',
      project_grids__project_grid_fields__fields__include:
        'project_grid_field_type,project_grid_id,included_grid_fields,included_grid_fields_ids,project_grid_field_rules,name,has_category_model,project_model_id',
      project_grids__project_grid_fields__project_grid_field_rules__fields__include:
        'rule',
    },
  })

  const prevStepRef = useRef<number>(activeStep)

  const mountedRef = useRef<HTMLDivElement>(null)
  const goBackwards = prevStepRef.current < activeStep
  const transitions = useTransition(location, {
    immediate: !mountedRef.current, // This makes it not animate on initial render
    from: {
      transform: `translateX(${goBackwards ? '100%' : '-100%'})`,
      opacity: 1,
    },
    enter: { transform: 'translateX(0vw)', opacity: 1 },
    leave: {
      transform: `translateX(${goBackwards ? '-100%' : '100%'})`,
      opacity: 0,
    },
  })

  useEffect(() => {
    prevStepRef.current = activeStep
  }, [activeStep, location])

  useEffect(() => {
    // If they end up on an invalid step, send them back to the first step
    if (activeStep === -1) {
      navigate(`/projects/new/setup${location.search}`, { replace: true })
    }
  })

  useEffect(() => {
    if (existingProject.id && !isLoading) {
      setProject(fullProject as Project)
    }
  }, [existingProject.id, fullProject, isLoading])

  return (
    <Container maxWidth="xl" sx={{ pt: 6, pb: 2 }}>
      <Stack spacing={6} alignItems="center" sx={{ width: '100%' }}>
        <Typography variant="h3">Project Setup</Typography>

        <Container maxWidth="lg" sx={{ width: '100%' }}>
          <div
            style={{
              position: 'relative',
              maxWidth: '100%',
            }}
            ref={mountedRef}
          >
            {transitions((styles, item) => (
              <animated.div
                style={{
                  ...styles,
                  position: 'absolute',
                  width: '100%',
                }}
              >
                <Stack justifyContent="space-between">
                  <Routes location={item}>
                    <Route
                      path="details"
                      element={
                        <SetupStep
                          project={project}
                          projects={projects}
                          setProject={setProject}
                        />
                      }
                    />
                    <Route
                      path="field_config"
                      element={<FieldsStep project={project} />}
                    />
                  </Routes>
                </Stack>
              </animated.div>
            ))}
          </div>
        </Container>
      </Stack>
    </Container>
  )
}
