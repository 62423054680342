import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  MRT_Column,
  MRT_ColumnOrderState,
  MRT_Updater,
} from 'material-react-table'
import { DocumentRow } from '@/types/documents'
import { useViewsContext } from './ViewsProvider'

type ColumnOrderContextValue = {
  columnOrder: string[]
  draggingColumn: MRT_Column<Required<DocumentRow>> | null
  handleColumnOrderChange: (
    columnsOrderUpdaterOrValue: MRT_Updater<MRT_ColumnOrderState>,
  ) => void // we use this function when we need to enable the user to save/update view
  resetColumnOrder: () => void
  setColumnOrder: Dispatch<SetStateAction<string[]>>
  setDraggingColumn: Dispatch<
    SetStateAction<MRT_Column<Required<DocumentRow>> | null>
  >
}

const ColumnOrderContext = createContext<ColumnOrderContextValue>(
  {} as ColumnOrderContextValue,
)

export const useColumnOrderContext = () => useContext(ColumnOrderContext)

type ColumnOrderProviderProps = {
  children: ReactNode
}

export default function ColumnOrderProvider({
  children,
}: ColumnOrderProviderProps) {
  const { selectedView, handleChange } = useViewsContext()
  const [columnOrder, setColumnOrder] = useState<string[]>(
    selectedView.state.column_order,
  )
  const [draggingColumn, setDraggingColumn] = useState<MRT_Column<
    Required<DocumentRow>
  > | null>(null)

  const getColumnOrderIds = useCallback(
    (columnsOrderIds: string[]) => {
      const draggingColumnId = draggingColumn?.id as string

      const updatedColumnsOrderIds = [...columnsOrderIds]
      const draggingColIndex = updatedColumnsOrderIds.findIndex(
        (id) => id === draggingColumnId,
      )

      const nextFieldColId =
        // Using optional chaining operator in case draggingColIndex is the last index in the array
        updatedColumnsOrderIds[draggingColIndex + 1]?.length >= 36
          ? updatedColumnsOrderIds[draggingColIndex + 1].substring(0, 36)
          : undefined

      const nextFieldColIndex =
        (nextFieldColId &&
          updatedColumnsOrderIds.findIndex(
            (id) => id === `${nextFieldColId}-original`,
          )) ||
        -1
      let realIndex =
        nextFieldColIndex > 0 ? nextFieldColIndex : draggingColIndex

      if (draggingColIndex !== realIndex) {
        updatedColumnsOrderIds.splice(draggingColIndex, 1)
        realIndex = realIndex > draggingColIndex ? realIndex - 1 : realIndex
        updatedColumnsOrderIds.splice(realIndex, 0, draggingColumnId)
      }

      const meta = draggingColumn?.columnDef.meta || {}

      // @ts-expect-error -- Not worth typing
      const { columnType, subGroupIds = [] } = meta

      if (columnType === 'primary') {
        const firstSubGroupColIndex = updatedColumnsOrderIds.findIndex(
          (id) => id === subGroupIds[0],
        )
        // Remove subgroups columns from the array
        updatedColumnsOrderIds.splice(
          firstSubGroupColIndex,
          subGroupIds?.length,
        )
        // Place subgroups columns before "parent" column
        updatedColumnsOrderIds.splice(
          realIndex > firstSubGroupColIndex ? realIndex - 3 : realIndex,
          0,
          ...subGroupIds,
        )
      }
      return updatedColumnsOrderIds
    },
    [draggingColumn?.columnDef.meta, draggingColumn?.id],
  )

  const resetColumnOrder = () => {
    setColumnOrder(selectedView.state.column_order)
  }

  const handleColumnOrderChange = (
    columnsOrderUpdaterOrValue: MRT_Updater<MRT_ColumnOrderState>,
  ) => {
    if (!draggingColumn) return
    handleChange()
    if (columnsOrderUpdaterOrValue instanceof Function) {
      setColumnOrder((prev = []) => {
        const columnsOrderIds = columnsOrderUpdaterOrValue(prev)
        return getColumnOrderIds(columnsOrderIds) || prev
      })
    } else {
      setColumnOrder(getColumnOrderIds(columnsOrderUpdaterOrValue))
    }
  }

  return (
    <ColumnOrderContext.Provider
      value={{
        columnOrder,
        draggingColumn,
        handleColumnOrderChange,
        resetColumnOrder,
        setColumnOrder,
        setDraggingColumn,
      }}
    >
      {children}
    </ColumnOrderContext.Provider>
  )
}
