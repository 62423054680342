import { useState } from 'react'
import { Box, Tooltip } from '@mui/material'
import { Organization } from '@/types/organizations'
import { useAuthentication } from '@/components/auth/AuthProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import useOverlay from '@/hooks/useOverlay'
import LabeledData from '@/components/labeled-data/LabeledData'
import ReadOnlyOrganizationDialog from './ReadOnlyOrganizationDialog'
import OrganizationPickerWithDialog from './OrganizationPickerWithDialog'
import OrganizationWithParent from './OrganizationWithParent'
import useOrganizationTypeName from './useOrganizationTypeName'

type OrganizationAssignmentSelectProps = {
  isFetching: boolean
  selectedOrganization: Organization
  organizations: Organization[]
  onChange: (organization: Organization) => void
  dense?: boolean
  disabled?: boolean
  isBulkChange?: boolean
  showParentOrg?: boolean
}

export default function OrganizationAssignmentSelect({
  isFetching,
  selectedOrganization,
  organizations,
  onChange,
  dense,
  disabled,
  isBulkChange = false,
  showParentOrg = false,
}: OrganizationAssignmentSelectProps) {
  const { user } = useAuthentication()
  const readOnlyOverlay = useOverlay()
  const { project } = useProjectContext()
  const [readOnlyOrg, setReadOnlyOrg] = useState<Organization | null>(null)

  const cantEdit =
    disabled ||
    (selectedOrganization.id !== 'multiple' &&
      !user?.read_write_org_ids.includes(selectedOrganization.id))

  const organizationTypeName = useOrganizationTypeName({
    organization: selectedOrganization,
  })

  return (
    <>
      {cantEdit ? (
        <Tooltip
          arrow
          enterDelay={1000}
          title="Read-Only Access"
          disableInteractive
        >
          <Box
            sx={{
              px: 1,
              pb: 0.5,
              pt: 1,
              borderRadius: 2,
            }}
          >
            {showParentOrg ? (
              <OrganizationWithParent
                organization={selectedOrganization}
                organizations={organizations}
                dense={dense}
              />
            ) : (
              <LabeledData
                label={organizationTypeName}
                data={selectedOrganization.name}
                dense={dense}
              />
            )}
          </Box>
        </Tooltip>
      ) : (
        <OrganizationPickerWithDialog
          currentOrganization={selectedOrganization}
          dense={dense}
          isFetching={isFetching}
          title="Assign to Organization"
          organizations={organizations}
          rootTreeOrgId={project.org_id}
          showParentOrg={showParentOrg}
          onSave={(organization) => {
            if (!user?.read_write_org_ids.includes(organization.id)) {
              setReadOnlyOrg(organization)
              readOnlyOverlay.open()
            } else {
              onChange(organization)
            }
          }}
        />
      )}

      {readOnlyOrg && (
        <ReadOnlyOrganizationDialog
          overlay={readOnlyOverlay}
          onContinue={() => {
            onChange(readOnlyOrg)
          }}
          onCancel={() => setReadOnlyOrg(null)}
          isBulkChange={isBulkChange}
        />
      )}
    </>
  )
}
