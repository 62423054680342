import { Card } from '@mui/material'
import ModelVersionsFieldsChart from '../data-visualization/ModelVersionsFieldsChart'
import {
  MetricsProjectModelVersion,
  ProjectModelVersion,
} from '@/types/project-models'
import { useProjectContext } from '../project-dashboard/ProjectProvider'

type ModelVersionsAccuracyCardProps = {
  modelVersions: ProjectModelVersion[]
}

export default function ModelVersionFieldAccuracyCard({
  modelVersions,
}: ModelVersionsAccuracyCardProps) {
  const { project } = useProjectContext()
  const filteredVersions = modelVersions.filter(
    ({ training_status }) =>
      training_status !== 'error' && training_status !== 'canceled',
  )
  return (
    <Card
      sx={{ borderRadius: 2, py: 2, px: 3, overflow: 'visible' }}
      elevation={0}
    >
      <ModelVersionsFieldsChart
        modelVersions={
          filteredVersions as unknown as MetricsProjectModelVersion[]
        }
        projectId={project.id}
        height={240}
        titleVariant="body1"
      />
    </Card>
  )
}
