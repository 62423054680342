import { Container } from '@mui/material'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import PageTitle from '@/components/PageTitle'
import ProfileDetails from '@/components/users/ProfileDetails'

export default function ProfilePage() {
  return (
    <Container maxWidth="md">
      <PageTitle>My Profile</PageTitle>
      <Breadcrumb label="My Profile" url="/settings/my-profile" />
      <ProfileDetails />
    </Container>
  )
}
