import { RefObject, useCallback, useEffect, useRef } from 'react'

type UseFixedTableHeaderOptions = {
  containerRef: RefObject<HTMLDivElement> | null
}

export default function useFixedTableHeader({
  containerRef,
}: UseFixedTableHeaderOptions) {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableHeadRef = useRef<HTMLTableSectionElement>(null)
  const tableHeadRowRef = useRef<HTMLTableRowElement>(null)
  const isIntersectingRef = useRef<boolean>(false)

  const updateHeaderPosition = useCallback(() => {
    const containerEl = containerRef?.current
    const tableContainerEl = tableContainerRef.current
    const tableHeadRowEl = tableHeadRowRef.current
    if (containerEl && tableContainerEl && tableHeadRowEl) {
      const { scrollTop: containerScrollTop } = containerEl
      const {
        offsetTop: tableContainerOffsetTop,
        clientHeight: tableContainerClientHeight,
      } = tableContainerEl
      // We want to set transform when the tableContainerOffsetTop is past the container,
      // but only if it's still in view. (The 50 is to account for the last row.)
      if (
        containerScrollTop > tableContainerOffsetTop &&
        containerScrollTop <
          tableContainerOffsetTop + tableContainerClientHeight - 50
      ) {
        const yTranslation = Math.floor(
          Math.abs(containerScrollTop - tableContainerOffsetTop),
        )
        tableHeadRowEl.style.setProperty(
          'transform',
          `translateY(${yTranslation}px)`,
        )
      } else {
        tableHeadRowEl.style.removeProperty('transform')
      }
    }

    if (!isIntersectingRef.current) {
      requestAnimationFrame(updateHeaderPosition)
    } else {
      tableHeadRowEl?.style.removeProperty('transform')
    }
  }, [containerRef])

  useEffect(() => {
    const containerEl = containerRef?.current
    const tableHeadEl = tableHeadRef.current
    const tableHeadRowEl = tableHeadRowRef.current

    const translate = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const { isIntersecting } = entry
        isIntersectingRef.current = isIntersecting
        if (!isIntersecting) {
          requestAnimationFrame(updateHeaderPosition)
        } else {
          tableHeadRowEl?.style.removeProperty('transform')
        }
      })
    }

    const observer = new IntersectionObserver(translate, {
      root: containerEl,
      threshold: 1,
    })

    if (tableHeadEl) observer.observe(tableHeadEl)

    return () => {
      tableHeadRowEl?.style.removeProperty('transform')
      observer.disconnect()
    }
  }, [containerRef, updateHeaderPosition])

  return { tableContainerRef, tableHeadRef, tableHeadRowRef }
}
