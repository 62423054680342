import { createContext, ReactNode, useContext } from 'react'
import { Project } from '@/types/projects'
import { useGetProjectGrids } from '@/service-library/hooks/project-grids'

type ProjectGridsProviderValue = ReturnType<typeof useGetProjectGrids>

const ProjectGridsContext = createContext<ProjectGridsProviderValue>(
  {} as ProjectGridsProviderValue,
)

export const useProjectGridsContext = () => useContext(ProjectGridsContext)

type ProjectGridsProviderProps = {
  children: ReactNode
  project?: Project
}

export default function ProjectGridsProvider({
  children,
  project,
}: ProjectGridsProviderProps) {
  const query = useGetProjectGrids({
    filters: {
      limit: '1000',
      project_id: project?.id,
      fields__include: 'project_grid_fields',
      project_grid_fields__fields__include:
        'project_grid_field_type,has_category_model',
    },
    enabled: !!project?.id,
  })

  return (
    <ProjectGridsContext.Provider value={query}>
      {children}
    </ProjectGridsContext.Provider>
  )
}
