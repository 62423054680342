import { Stack, Button, CircularProgress, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ProjectDocumentRule } from '@/types/rules'
import { DraggableList } from '@/components/lists/DraggableList'
import useOverlay from '@/hooks/useOverlay'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { Dispatch, SetStateAction } from 'react'
import {
  useCreateProjectDocumentRule,
  useDeleteProjectDocumentRule,
  useGetProjectDocumentRules,
  useUpdateProjectDocumentRules,
} from '@/service-library/hooks/project-document-rules'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import RuleCard from '@/components/rules/RuleCard'
import AddDocumentRuleDialog from './AddDocumentRuleDialog'

type DocumentRulesProps = {
  selectedRule: ProjectDocumentRule | null
  setSelectedRule: Dispatch<SetStateAction<ProjectDocumentRule | null>>
}

export default function DocumentRules({
  selectedRule,
  setSelectedRule,
}: DocumentRulesProps) {
  const { project } = useProjectContext()
  const featureFlags = useFeatureFlagContext()

  const {
    projectDocumentRules = [],
    isLoading,
    queryKey,
  } = useGetProjectDocumentRules({
    filters: {
      project_id: project.id,
      fields__include: 'type,workflow_states_ids,dependent_fields_ids',
      limit: '1000',
    },
    enabled: !!project,
  })

  const { createProjectDocumentRule } = useCreateProjectDocumentRule({
    listQueryKey: queryKey,
    onMutate: (newDocumentRule) => {
      setSelectedRule(newDocumentRule)
    },
    onSuccess: () => {
      showSuccessSnackbar('Document Rule Created')
    },
    onError: () => {
      showErrorSnackbar(
        'Failed to create document rule. Please try again later.',
      )
      setSelectedRule(null)
    },
  })

  const { deleteProjectDocumentRule } = useDeleteProjectDocumentRule({
    listQueryKey: queryKey,
    onMutate: () => {
      setSelectedRule(null)
    },
    onError: () => {
      showErrorSnackbar('Failed to delete field rule. Please try again later.')
    },
  })

  const { updateProjectDocumentRules } = useUpdateProjectDocumentRules({
    listQueryKey: queryKey,
    useSortOrder: true,
    onMutate: (updatedDocRules) => {
      if (selectedRule) {
        const updatedSelectedRule = updatedDocRules.find(
          ({ id }) => selectedRule.id === id,
        )
        if (updatedSelectedRule) {
          setSelectedRule(updatedSelectedRule)
        }
      }
    },
  })

  const reorderDocumentRules = (
    reorderedDocumentRules: ProjectDocumentRule[],
  ) => {
    const sortedDocumentRules = reorderedDocumentRules.map((item, index) => ({
      ...item,
      sort_order: index,
    }))
    updateProjectDocumentRules(sortedDocumentRules)
  }

  const addRuleOverlay = useOverlay()

  return (
    <Stack p={!featureFlags.quality_control_ui ? 2 : 0} spacing={4}>
      {isLoading && (
        <Box sx={{ p: 4 }}>
          <CircularProgress size={40} />
        </Box>
      )}

      {!isLoading && (
        <>
          {/* MARK: Document Rules */}
          <Stack spacing={1}>
            <Box>
              {projectDocumentRules.length > 0 && (
                <DraggableList
                  data={projectDocumentRules}
                  onChange={reorderDocumentRules}
                >
                  {projectDocumentRules.map((documentRule) => (
                    <RuleCard
                      key={documentRule.id}
                      isSelected={selectedRule?.id === documentRule.id}
                      onClick={() => {
                        setSelectedRule(documentRule)
                      }}
                      onDelete={() => {
                        deleteProjectDocumentRule(documentRule.id)
                      }}
                      name={documentRule.type?.name || ''}
                      description={documentRule.type?.description || ''}
                      funcName={documentRule.params.function_name}
                      enabled={documentRule.is_enabled}
                      onEnableDisable={(checked) => {
                        updateProjectDocumentRules([
                          { ...documentRule, is_enabled: checked },
                        ])
                      }}
                    />
                  ))}
                </DraggableList>
              )}
            </Box>

            <div>
              <Button
                variant="text"
                startIcon={<AddIcon />}
                onClick={addRuleOverlay.open}
              >
                Add Rule
              </Button>
              <AddDocumentRuleDialog
                overlay={addRuleOverlay}
                onSubmit={createProjectDocumentRule}
              />
            </div>
          </Stack>
        </>
      )}
    </Stack>
  )
}
