import { useMemo } from 'react'
import { useGetWorkflows } from '@/service-library/hooks/workflows'
import { Document } from '@/types/documents'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'

type UseGetDocumentWorkflowAndStateOptions = {
  document?: Document
  enabled: boolean
  useCurrentProject?: boolean
}

export default function useGetDocumentWorkflowAndState({
  document,
  enabled,
  useCurrentProject,
}: UseGetDocumentWorkflowAndStateOptions) {
  const { workflows } = useGetWorkflows({
    filters: {
      limit: '1000',
      project_id: document?.project_id,
      fields__include: 'workflow_states',
    },
    refetchOnWindowFocus: false,
    enabled: !!document && !useCurrentProject && enabled,
  })

  const { workflows: currentProjectWorkflows } = useWorkflowsContext()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  return useMemo(() => {
    if (!enabled) return {}

    if (useCurrentProject) {
      const stateInCurrentWorkflow = selectedWorkflow?.workflow_states?.find(
        ({ id }) => document?.workflow_states_ids?.includes(id),
      )
      if (stateInCurrentWorkflow) {
        return {
          workflowId: stateInCurrentWorkflow.workflow_id,
          workflowStateId: stateInCurrentWorkflow.id,
        }
      }
    }

    const workflowsToUse = useCurrentProject
      ? currentProjectWorkflows
      : workflows
    const productionDataWorkflowsIds = workflowsToUse
      .filter((workflow) => workflow.code === 'basic')
      .map(({ id }) => id)

    if (!workflowsToUse.length || !productionDataWorkflowsIds.length) return {}

    const workflowStateMap = workflowsToUse.reduce<Record<string, string>>(
      (acc, workflow) => {
        const workflowState = workflow.workflow_states?.find(({ id }) => {
          return document?.workflow_states_ids?.includes(id)
        })
        if (workflowState) acc[workflow.id] = workflowState.id
        return acc
      },
      {},
    )

    for (const productionDataWorkflowId of productionDataWorkflowsIds) {
      if (Object.hasOwn(workflowStateMap, productionDataWorkflowId)) {
        return {
          workflowId: productionDataWorkflowId,
          workflowStateId: workflowStateMap[productionDataWorkflowId],
        }
      }
    }

    const [firstWorkflowId, firstWorkflowStateId] =
      Object.entries(workflowStateMap)[0] || []

    return {
      workflowId: firstWorkflowId,
      workflowStateId: firstWorkflowStateId,
    }
  }, [
    currentProjectWorkflows,
    document?.workflow_states_ids,
    enabled,
    selectedWorkflow?.workflow_states,
    useCurrentProject,
    workflows,
  ])
}
