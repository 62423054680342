import { RefObject, useCallback, useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

export type UseFetchMoreOnBottomReachedOptions = {
  isFetching: ReturnType<typeof useInfiniteQuery>['isFetching']
  hasNextPage: ReturnType<typeof useInfiniteQuery>['hasNextPage']
  tableContainerRef: RefObject<HTMLDivElement>
  fetchNextPage: ReturnType<typeof useInfiniteQuery>['fetchNextPage']
  offset?: number
}

export default function useFetchMoreOnBottomReached({
  isFetching,
  hasNextPage,
  tableContainerRef,
  fetchNextPage,
  offset = 400,
}: UseFetchMoreOnBottomReachedOptions) {
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement: HTMLDivElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement

        // once the user has scrolled within {offset} of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < offset &&
          !isFetching &&
          hasNextPage
        ) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, hasNextPage, isFetching, offset],
  )

  useEffect(() => {
    if (tableContainerRef.current) {
      const tableHeight =
        tableContainerRef.current.querySelector('table')?.clientHeight
      if (
        tableHeight &&
        tableHeight < tableContainerRef.current.clientHeight &&
        hasNextPage &&
        !isFetching
      ) {
        fetchNextPage()
      }
    }
  }, [fetchNextPage, hasNextPage, isFetching, tableContainerRef])

  return {
    fetchMoreOnBottomReached,
  }
}
