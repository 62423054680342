import PageTitle from '@/components/PageTitle'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import CustomVariablesDetails from '@/components/custom-variables/CustomVariablesDetails'

export default function CustomVariablesPage() {
  return (
    <>
      <PageTitle>Custom Variables</PageTitle>
      <Breadcrumb label="Custom Variables" url="/settings/custom-variables" />
      <CustomVariablesDetails />
    </>
  )
}
