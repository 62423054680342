import { useMemo } from 'react'
import { useColumnOrderContext } from '@/components/workflows/ColumnOrderProvider'
import { useColumnSortingContext } from '@/components/workflows/ColumnSortingProvider'
import { useColumnVisibilityContext } from '@/components/workflows/ColumnVisibilityProvider'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'

export default function useCurrentViewState() {
  const { columnOrder } = useColumnOrderContext()
  const { sorting } = useColumnSortingContext()
  const { columnVisibility } = useColumnVisibilityContext()
  const { documentFilters } = useDocumentFiltersContext()

  return useMemo(
    () => ({
      column_order: columnOrder,
      column_visibility: columnVisibility,
      flag_codes: documentFilters?.flagCodes || [],
      org_ids: documentFilters?.orgIds || [],
      sorting,
      team_ids: documentFilters?.teamIds || [],
    }),
    [
      columnOrder,
      columnVisibility,
      documentFilters?.flagCodes,
      documentFilters?.orgIds,
      documentFilters?.teamIds,
      sorting,
    ],
  )
}
