import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { User } from '@/types/users'
import { OverlayState } from '@/hooks/useOverlay'
import { useUpdateCurrentUser } from '@/service-library/hooks/users'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'

type FormValues = {
  email: string
}

type UpdateEmailDialogProps = {
  detailQueryKey: QueryKey
  user: User
  overlay: OverlayState
}

export default function UpdateEmailDialog({
  detailQueryKey,
  user,
  overlay,
}: UpdateEmailDialogProps) {
  const { updateCurrentUser, isLoading } = useUpdateCurrentUser({
    detailQueryKey,
    onSettled: () => {
      overlay.close()
    },
    onError: () => {
      showErrorSnackbar(
        'Unable to update your information. Please try again later.',
      )
    },
  })

  const methods = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  })

  const {
    formState: { isDirty, isValid },
    register,
    reset,
  } = methods

  function handleUpdateEmail(values: FormValues) {
    const { email } = values
    if (
      (!user.change_email_to && email !== user.email) ||
      (user.change_email_to && email !== user.change_email_to)
    ) {
      updateCurrentUser({ ...user, change_email_to: email.trim() })
    } else {
      overlay.close()
    }
  }

  useEffect(() => {
    if (overlay.isOpen) {
      reset({
        email: '',
      })
    }
  }, [overlay.isOpen, reset])

  return (
    <Dialog maxWidth="sm" {...overlay} title="Update Email">
      <PixyDocsForm methods={methods} onSubmit={handleUpdateEmail}>
        <DialogContent>
          <FormTextField
            autoFocus
            fullWidth
            type="email"
            label="Email"
            required
            {...register('email', {
              pattern: /^[^@ ]+@[0-9A-Za-z-]+(\.[0-9A-Za-z-]+)+$/,
            })}
          />
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!isDirty || !isValid}
            loading={isLoading}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
