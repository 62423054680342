import { DataListEntryCell } from '@/types/data-lists'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<DataListEntryCell>({
  pathCategory: 'data_list_entry_cells',
})

export function useGetDataListEntryCell({
  id,
  ...options
}: UseGetDetailOptions<DataListEntryCell>) {
  const query = useGetDetail<DataListEntryCell>({
    id,
    queryKey: queryKeys.dataListEntryCells.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    dataListEntryCell: query.data,
    ...query,
  }
}

export function useCreateDataListEntryCell(
  options: UseCreateDetailOptions<DataListEntryCell> = {},
) {
  const mutation = useCreateDetail<DataListEntryCell>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDataListEntryCell: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDataListEntryCell(
  options: UseUpdateDetailOptions<DataListEntryCell> = {},
) {
  const mutation = useUpdateDetail<DataListEntryCell>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDataListEntryCell: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataListEntryCell(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<DataListEntryCell>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDataListEntryCell: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDataListEntryCells({
  filters,
  ...options
}: UseGetListOptions<DataListEntryCell> = {}) {
  const query = useGetList<DataListEntryCell>({
    filters,
    queryKey: queryKeys.dataListEntryCells.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    dataListEntryCells: query.allData,
    ...query,
  }
}

export function useUpdateDataListEntryCells(
  options: UseUpdateListOptions<DataListEntryCell> = {},
) {
  const mutation = useUpdateList<DataListEntryCell>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDataListEntryCells: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataListEntryCells({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DataListEntryCell>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDataListEntryCells: mutation.mutateAsync,
    ...mutation,
  }
}
