import { useMemo } from 'react'
import { UseInfiniteQueryOptions } from '@tanstack/react-query'
import PaginatedResponse from '@/types/paginated-response'
import useInfiniteQuery from './useInfiniteQuery'

export type UseInfinitePaginationQueryOptions<T> = UseInfiniteQueryOptions<
  T,
  unknown,
  T
> & {
  paginator?: 'cursor' | 'page' | 'offset'
}

/**
 * This hook is meant to be used by other service hooks in place of useInfiniteQuery.
 * It provides the logic managing cursor pagination for requests.
 * It does not provide pagination values for managing the UI.
 */
export default function useInfinitePaginationQuery<T>({
  queryKey,
  queryFn,
  paginator = 'cursor',
  ...options
}: UseInfinitePaginationQueryOptions<PaginatedResponse<T>>) {
  const query = useInfiniteQuery<
    PaginatedResponse<T>,
    unknown,
    PaginatedResponse<T>
  >({
    queryKey,
    queryFn,
    getNextPageParam: (lastPage) => {
      if (!lastPage.next) {
        return undefined
      }
      const url = new URL(lastPage.next)
      return url.searchParams.get(paginator)
    },
    getPreviousPageParam: (firstPage) => {
      if (!firstPage.previous) {
        return undefined
      }
      const url = new URL(firstPage.previous)
      return url.searchParams.get(paginator)
    },
    ...options,
  })

  const allData = useMemo(
    () =>
      query.data?.pages?.reduce<T[]>(
        (acc, page) => [...acc, ...page.results],
        [],
      ) ?? [],
    [query.data?.pages],
  )

  return {
    allData,
    ...query,
  }
}
