import { ReactNode, useState } from 'react'
import { Delete, Edit } from '@mui/icons-material'
import {
  IconButton,
  IconButtonProps,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material'
import EditableNameField from './EditableNameField'

type CommonProps = {
  groupName: string
  name: string
  showTooltip?: boolean
  typographyProps?: TypographyProps & { component?: React.ElementType }
}

type LabelProps = CommonProps & {
  isEditing: boolean
  handleOnBlur: (name: string) => void
}

type EditableNameWithActionsProps = CommonProps & {
  canBeEdited: boolean
  onDelete: () => void
  onUpdate: (name: string) => void
  deleteDisabledMessage?: string
  iconsSize?: IconButtonProps['size']
  fixedActions?: ReactNode
}

const Label = ({
  groupName,
  isEditing,
  name,
  showTooltip,
  handleOnBlur,
  typographyProps,
}: LabelProps) => {
  return isEditing ? (
    <EditableNameField name={name} handleOnBlur={handleOnBlur} />
  ) : (
    <Tooltip
      title={
        showTooltip
          ? `This is a default ${groupName}. It can't be edited or deleted.`
          : ''
      }
      enterDelay={1000}
    >
      <Typography {...typographyProps}>{name}</Typography>
    </Tooltip>
  )
}

export default function EditableNameWithActions({
  canBeEdited,
  onDelete,
  onUpdate,
  deleteDisabledMessage,
  iconsSize,
  fixedActions,
  ...rest
}: EditableNameWithActionsProps) {
  const [showActionButtons, setShowActionButtons] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  function handleOnEdit(internalName: string) {
    setIsEditing(false)
    onUpdate(internalName)
  }

  return (
    <Stack
      alignItems="center"
      direction="row"
      onMouseEnter={() => setShowActionButtons(true)}
      onMouseLeave={() => setShowActionButtons(false)}
      spacing={1}
    >
      <Label isEditing={isEditing} handleOnBlur={handleOnEdit} {...rest} />
      {!isEditing && fixedActions}
      {canBeEdited && showActionButtons && !isEditing && (
        <Stack direction="row">
          <IconButton
            onClick={(e) => {
              e.preventDefault()
              setIsEditing(true)
            }}
            size={iconsSize}
          >
            <Edit color="primary" fontSize="inherit" />
          </IconButton>

          <Tooltip title={deleteDisabledMessage || ''} enterDelay={1000}>
            <span>
              <IconButton
                disabled={!!deleteDisabledMessage}
                onClick={(e) => {
                  e.preventDefault()
                  onDelete()
                }}
                size={iconsSize}
              >
                <Delete
                  color={deleteDisabledMessage ? 'disabled' : 'error'}
                  fontSize="inherit"
                />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  )
}
