import { useGetProjects } from '@/service-library/hooks/projects'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import { Stack, Typography } from '@mui/material'

export default function ProjectTotalCounts() {
  const { rootOrganization } = useRootOrganization()
  const { projects = [], isLoading: projectsIsLoading } = useGetProjects({
    filters: {
      limit: '1000',
      org_id: rootOrganization.id,
      fields__only: 'id,name,basic_states_stats',
    },
  })

  return projectsIsLoading ? null : (
    <Stack spacing={1}>
      {projects.map((project) => {
        const total = Object.values(project.basic_states_stats || {}).reduce(
          (acc, { count }) => {
            return acc + count
          },
          0,
        )
        return (
          <Stack
            direction="row"
            spacing={2}
            key={project.id}
            sx={{ minWidth: 300 }}
          >
            <Typography sx={{ flexGrow: 1 }}>{project.name}</Typography>
            <Typography>
              <b>{total}</b>
            </Typography>
          </Stack>
        )
      })}
    </Stack>
  )
}
