import { DateTimePicker } from '@mui/x-date-pickers'
import { TextFieldProps } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'

type DateTimeFieldProps = {
  onChange: (newValue: string) => void
  value: string
  label?: string
  disabled?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  minDate?: string
  maxDate?: string
  isInTable?: boolean
} & Omit<TextFieldProps, 'onChange' | 'value'>

export default function DateTimeField({
  onChange,
  value,
  label,
  disabled,
  inputRef,
  minDate,
  maxDate,
  isInTable,
  ...props
}: DateTimeFieldProps) {
  let displayValue: Dayjs | null = null
  if (value) {
    displayValue = dayjs(value)
  }

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue === null) {
      onChange('')
    } else if (newValue?.isValid()) {
      onChange(newValue.toISOString())
    }
  }

  return (
    <DateTimePicker
      value={displayValue}
      onChange={handleChange}
      slotProps={{
        openPickerIcon: {
          fontSize: isInTable ? 'small' : 'medium',
        },
        openPickerButton: {
          size: 'small',
          tabIndex: -1,
        },
        textField: {
          autoComplete: 'off',
          ...props,
        },
      }}
      label={label}
      disabled={disabled}
      inputRef={inputRef}
      minDateTime={minDate ? dayjs(minDate) : undefined}
      maxDateTime={maxDate ? dayjs(maxDate) : undefined}
    />
  )
}
