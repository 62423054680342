import { useBreadcrumbPortalContext } from './BreadcrumbPortalProvider'
import { Box } from '@mui/material'

export default function BreadcrumbPortal() {
  const [, setPortalNode] = useBreadcrumbPortalContext()
  return (
    <nav aria-label="Breadcrumb">
      <Box sx={{ display: 'flex', px: 0.5 }} ref={setPortalNode} />
    </nav>
  )
}
