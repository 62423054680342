import { Button, Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Project } from '@/types/projects'
import useOverlay from '@/hooks/useOverlay'
import CancelSetupDialog from './CancelSetupDialog'

type StepActionsProps = {
  onNext?: () => void
  onBack?: () => void
  nextDisabled?: boolean
  backDisabled?: boolean
  nextLabel?: string
  nextLoading?: boolean
  project: Project
}

export default function StepActions({
  onNext,
  onBack,
  nextDisabled,
  backDisabled,
  nextLabel = 'Next',
  nextLoading,
  project,
}: StepActionsProps) {
  const cancelOverlay = useOverlay()

  return (
    <Stack
      pt={4}
      pb={4}
      direction="row"
      justifyContent="space-between"
      sx={{ width: '100%' }}
      spacing={2}
    >
      <Button variant="text" color="error" onClick={cancelOverlay.open}>
        Cancel Setup
      </Button>
      <CancelSetupDialog overlay={cancelOverlay} project={project} />

      <Stack direction="row" spacing={2}>
        <Button
          size="medium"
          variant="text"
          onClick={onBack}
          disabled={backDisabled}
        >
          Back
        </Button>
        <LoadingButton
          size="medium"
          onClick={onNext}
          variant="contained"
          disabled={nextDisabled || nextLoading}
          loading={nextLoading}
        >
          {nextLabel}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
