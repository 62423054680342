import { Dispatch, SetStateAction } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { Save } from '@mui/icons-material'
import { QueryKey } from '@tanstack/react-query'
import { View } from '@/types/views'
import {
  useDeleteSavedView,
  useUpdateSavedView,
} from '@/service-library/hooks/saved-views'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import EditableNameWithActions from '@/components/editable-name/EditableNameWithActions'
import { useViewsContext } from '@/components/workflows/ViewsProvider'
import useCurrentViewState from './useCurrentViewState'

export type ViewNameWithActionsProps = {
  selected: boolean
  view: View
  viewsContext: ReturnType<typeof useViewsContext>
}

type SaveViewIconButtonProps = {
  view: View
  listQueryKey: QueryKey
  setSavedEnabled: Dispatch<SetStateAction<boolean>>
  disabled?: boolean
}

function SaveViewIconButton({
  view,
  listQueryKey,
  setSavedEnabled,
  disabled,
}: SaveViewIconButtonProps) {
  const { updateSavedView } = useUpdateSavedView({
    listQueryKey,
    onSuccess: () => {
      showSuccessSnackbar('View Updated')
    },
    onError: () => {
      showErrorSnackbar('Failed to update view. Please try again later.')
    },
  })

  const currentViewState = useCurrentViewState()

  return (
    <Tooltip title="Save Changes" enterDelay={1000}>
      <IconButton
        size="small"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault()
          updateSavedView({
            ...view,
            state: currentViewState,
          })
          setSavedEnabled(false)
        }}
      >
        <Save fontSize="inherit" />
      </IconButton>
    </Tooltip>
  )
}

export default function ViewNameWithActions({
  selected,
  view,
  viewsContext,
}: ViewNameWithActionsProps) {
  const { id, name } = view
  const { canEdit, savedEnabled, setSavedEnabled, queryKey } = viewsContext

  const { updateSavedView } = useUpdateSavedView({
    listQueryKey: queryKey,
    onSuccess: () => {
      showSuccessSnackbar('View Updated')
    },
    onError: () => {
      showErrorSnackbar('Unable to update view. Please try again later.')
    },
  })

  const { deleteSavedView } = useDeleteSavedView({
    listQueryKey: queryKey,
    onSuccess: () => {
      showSuccessSnackbar('View Deleted')
    },
    onError: () => {
      showErrorSnackbar('Unable to delete view. Please try again later.')
    },
  })

  function handleUpdate(internalName: string) {
    if (internalName && internalName !== name) {
      const updatedView = { ...view, name: internalName }
      updateSavedView(updatedView)
    }
  }

  const canBeEdited = canEdit && id !== 'default'

  return (
    <EditableNameWithActions
      canBeEdited={canBeEdited}
      groupName="view"
      name={name}
      showTooltip={!canBeEdited}
      onDelete={() => deleteSavedView(view.id)}
      onUpdate={handleUpdate}
      iconsSize="small"
      typographyProps={{ variant: 'body2' }}
      fixedActions={
        savedEnabled && canEdit && selected && view.id !== 'default' ? (
          <SaveViewIconButton
            view={view}
            listQueryKey={queryKey}
            setSavedEnabled={setSavedEnabled}
          />
        ) : null
      }
    />
  )
}
