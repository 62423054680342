import { ReactNode, useRef } from 'react'
import { Menu, IconButton, IconButtonProps, MenuProps } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import MoreIcon from '@mui/icons-material/MoreVert'

type MenuOverlayProps = {
  overlay: OverlayState
  children: ReactNode
  icon?: ReactNode
  IconButtonProps?: Partial<IconButtonProps>
  MenuProps?: Partial<MenuProps>
}

export default function IconButtonMenu({
  overlay,
  children,
  icon = <MoreIcon />,
  IconButtonProps = {},
  MenuProps = {},
}: MenuOverlayProps) {
  const anchorRef = useRef<HTMLButtonElement>(null)
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={(e) => {
          e.stopPropagation()
          overlay.toggle(e)
        }}
        {...IconButtonProps}
      >
        {icon}
      </IconButton>

      <Menu
        anchorEl={anchorRef.current}
        open={overlay.isOpen}
        onClose={overlay.close}
        {...MenuProps}
      >
        {children}
      </Menu>
    </>
  )
}
