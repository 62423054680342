import { Stack } from '@mui/material'
import LabeledData from '../labeled-data/LabeledData'
import { Organization } from '@/types/organizations'
import { KeyboardArrowRight } from '@mui/icons-material'
import { getOrgName } from '@/utils/organization'
import useOrganizationTypeName from './useOrganizationTypeName'

type OrganizationWithParentProps = {
  organization: Organization
  organizations: Organization[]
  dense?: boolean
}

export default function OrganizationWithParent({
  organization,
  organizations,
  dense = false,
}: OrganizationWithParentProps) {
  const parentOrganization = organizations.find(
    ({ id }) => id === organization.parent_org_id,
  ) as Organization

  const organizationTypeName = useOrganizationTypeName({
    organization,
  })
  const parentOrganizationTypeName = useOrganizationTypeName({
    organization: parentOrganization,
  })

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ minWidth: 0 }}>
      {parentOrganization && parentOrganization.parent_org_id && (
        <>
          <LabeledData
            label={parentOrganizationTypeName}
            data={parentOrganization.name} // Don't need to use getOrgName here because we never show root org here
            dense={dense}
          />
          <KeyboardArrowRight sx={{ fontSize: 18 }} />
        </>
      )}

      <LabeledData
        label={organizationTypeName}
        data={getOrgName(organization)}
        dense={dense}
      />
    </Stack>
  )
}
