import { Fragment } from 'react'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

type KeyboardShortcutsTableProps = {
  keyboardShortcutsMap: Record<string, string>
}

export default function KeyboardShortcutsTable({
  keyboardShortcutsMap,
}: KeyboardShortcutsTableProps) {
  return (
    <Grid container rowSpacing={1}>
      {Object.entries(keyboardShortcutsMap).map(([label, keyboardShortcut]) => (
        <Fragment key={keyboardShortcut}>
          <Grid xs={6}>
            <Typography align="left" variant="body2">
              {label}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography color="text.secondary" align="right" variant="body2">
              {keyboardShortcut}
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}
