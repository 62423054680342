/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Ulid } from 'id128'

/** IMPORTANT! */
/** "But ZACH why do we have to import all this directly from id128 and do this setup!?"
 *
 * Put simply, its clear the author isn't used to working in a web environment with bundlers.
 * When web code is bundled for production, one of the steps is "minification". This is when
 * the bundler will reduce all the variable names to one letter, de-dupe names, get rid of
 * white-space, etc.
 *
 * The id128 library _dynamically_ creates its names for its exports. This is dangerous to do.
 * The bundler we use (vite) and others (like webpack) tend to do minification by default. The
 * result is that it minifies all of the file names within the packages _before_ the package exports
 * them with their _now minified names_. However, in our code, we're expecting them to be named
 * what they are named before they get minified. As a result, it breaks out code.
 *
 * So, to get around that, we're just importing and building the code we need ourselves. Yeehaw.
 */

import { Uuid } from 'id128/src/id/uuid'
import { Uuid4 } from 'id128/src/id/uuid-4'
import UuidCoder from 'id128/src/coder/uuid'
import HexCoder from 'id128/src/coder/hex'
import { VersionedIdFactory } from 'id128/src/factory/versioned-id'

const UuidFactory = new VersionedIdFactory({
  abstract_id: Uuid,
  versioned_ids: [Uuid4],
  canonical_coder: UuidCoder,
  raw_coder: HexCoder,
})

export default function generateUuid() {
  const randUlid = Ulid.generate()
  const ulid = Ulid.fromCanonical(randUlid.toCanonical())
  const uuid = UuidFactory.versioned_ids[0].fromRaw(ulid.toRaw()).toCanonical()
  return uuid.toLowerCase()
}
