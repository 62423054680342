import { createContext, ReactNode, useContext } from 'react'
import { QueryKey } from '@tanstack/react-query'
import { ProjectGridField } from '@/types/fields'
import { Project, ProjectGrid } from '@/types/projects'
import { getBaseGrid } from '@/utils/field-utils'

type ProjectProviderValue = {
  project: Project
  queryKey: QueryKey
  fieldsMap: Record<
    string,
    ProjectGridField & { parentField: ProjectGridField | null }
  >
}

const ProjectContext = createContext<ProjectProviderValue>(
  {} as ProjectProviderValue,
)

export const useProjectContext = () => useContext(ProjectContext)

type ProjectProviderProps = {
  children: ReactNode
  project: Project
  queryKey: QueryKey
}

export default function ProjectProvider({
  children,
  project,
  queryKey,
}: ProjectProviderProps) {
  const baseGrid = getBaseGrid(project.project_grids || [])

  const gridsMap =
    project.project_grids?.reduce<Record<string, ProjectGrid>>((acc, grid) => {
      acc[grid.id] = grid
      return acc
    }, {}) || {}

  const fields =
    project.project_grids?.flatMap(
      ({ project_grid_fields }) => project_grid_fields,
    ) || []

  const fieldsMap = fields.reduce<
    Record<string, ProjectGridField & { parentField: ProjectGridField | null }>
  >((acc, field) => {
    acc[field.id] = {
      ...field,
      parentField:
        field.project_grid_id === baseGrid?.id
          ? null
          : gridsMap[field.project_grid_id].sub_project_grid_fields[0],
    }
    return acc
  }, {})

  return (
    <ProjectContext.Provider value={{ project, queryKey, fieldsMap }}>
      {children}
    </ProjectContext.Provider>
  )
}
