import { TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { LogixHandle } from '@/types/logix'

type HandleTextInputProps = {
  logixHandle: LogixHandle
  updateHandleManualValue: (updatedValue: LogixHandle['manual_value']) => void
}

export default function HandleTextInput({
  logixHandle,
  updateHandleManualValue,
}: HandleTextInputProps) {
  const { id } = logixHandle

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    updateHandleManualValue(event.target.value)
  }

  return (
    <TextField
      name={`text-${id}`}
      color="neutral"
      size="small"
      variant="outlined"
      sx={{ width: 150 }}
      onChange={onChange}
      value={logixHandle.manual_value || ''}
      inputProps={{
        sx: {
          px: 1,
          py: 0.5,
          fontSize: 12,
        },
      }}
    />
  )
}
