import { useMemo } from 'react'
import { Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Document } from '@/types/documents'
import { ProjectGridField } from '@/types/fields'
import { useContainerSize } from '@/components/size-provider/SizeProvider'
import FieldTable from './FieldTable'
import Field from './Field'

const getColumnSpan = (
  cellMinWidth: number,
  containerWidth: number,
  numberOfCells: number,
) => {
  const columns = containerWidth / cellMinWidth
  let columnSpan =
    columns < numberOfCells
      ? Math.ceil((12 * cellMinWidth) / containerWidth)
      : Math.ceil(12 / numberOfCells)

  // If the column span is 5, it means only 10 out of the 12 spaces will be used,
  // so we "round" it to 6 to not have empty spaces
  if (columnSpan === 5) {
    columnSpan = 6
    // If the column span is bigger 6, a similar case happens,
    // so we "round" it to 12 to not have empty spaces
  } else if (columnSpan > 6) {
    columnSpan = 12
  }
  return columnSpan
}

type FieldGridProps = {
  document: Document
  fields: ProjectGridField[]
}

export default function FieldGrid({ document, fields }: FieldGridProps) {
  const { width } = useContainerSize()

  const fieldsToCalculateColumns = useMemo(() => {
    let gridIndex = fields.findIndex(
      (field) => field.project_grid_field_type.postgres_data_type === 'grid',
    )
    if (gridIndex < 0) return fields.length

    const columns = fields.slice(gridIndex + 1).reduce((acc, field, index) => {
      const realIndex = index + gridIndex + 1
      const isTableField =
        field.project_grid_field_type.postgres_data_type === 'grid'
      if (isTableField) {
        gridIndex = realIndex
        return acc
      }
      return Math.max(acc, realIndex - gridIndex)
    }, gridIndex)

    return columns
  }, [fields])

  return (
    <Grid
      key={document.id} // Force remount when document changes so fields will clear and update values properly
      container
      spacing={1}
      p={1}
      pt={
        fields?.[0]?.project_grid_field_type.postgres_data_type === 'grid'
          ? 0
          : 1
      }
      disableEqualOverflow
    >
      {fields.map((field) => {
        const isGridField =
          field.project_grid_field_type.postgres_data_type === 'grid'

        return (
          <Grid
            key={field.id}
            xs={
              isGridField
                ? 12
                : getColumnSpan(260, width, fieldsToCalculateColumns)
            }
          >
            {isGridField ? (
              <Box sx={{ mx: -1 }}>
                <FieldTable tableField={field} />
              </Box>
            ) : (
              <Field field={field} />
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}
