import { ReactNode, forwardRef } from 'react'
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'
import { FieldValues, useController, FieldPath } from 'react-hook-form'

type FormMultiSelectCheckboxProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label: ReactNode
} & CheckboxProps

const FormMultiSelectCheckbox = forwardRef(function FormTextField<
  FormValues extends FieldValues,
>(
  { name, label, value, ...props }: FormMultiSelectCheckboxProps<FormValues>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { field } = useController<FormValues>({ name })
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          {...field}
          {...props}
          ref={ref}
          value={value}
          checked={field.value.includes(value)}
          onChange={(event, checked) => {
            const values = checked
              ? [...field.value, event.target.value]
              : field.value.filter(
                  (item: typeof value) => item !== event.target.value,
                )
            field.onChange(values)
          }}
        />
      }
      sx={{
        width: 'fit-content',
      }}
    />
  )
})

export default FormMultiSelectCheckbox
