import { useEffect, useMemo } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { TextField, Autocomplete, ListItem } from '@mui/material'
import constructQueryParams from './construct-query-params'
import { PropertyFieldProps } from './PropertyField'
import useForeignKeyListEndpoint from './useForeignKeyListEndpoint'

type PropertyMultiSelectFromForeignKeyProps = PropertyFieldProps

export default function PropertyMultiSelectFromForeignKey({
  propertyId,
  property,
  required,
  variant,
  dependencyValues,
}: PropertyMultiSelectFromForeignKeyProps) {
  const { field: formField } = useController({
    name: propertyId,
    rules: { required },
  })
  const { formState, resetField, setValue } = useFormContext()
  const { errors } = formState

  const { display, foreignKey } = property

  const { listEndpoint, listQueryParams = {} } = foreignKey || {}

  const { queryParams } = constructQueryParams({
    queryParams: listQueryParams,
    otherDependencyValues: dependencyValues,
  })

  const { data = [], isLoading } = useForeignKeyListEndpoint({
    url: `${listEndpoint}?${queryParams?.toString()}&limit=1000`,
    enabled: listEndpoint,
  })

  const currentValues = useWatch({ name: propertyId })

  const values = useMemo(
    () => data.filter(({ id }) => currentValues.includes(id)),
    [data, currentValues],
  )

  const existsInData = formField.value.length
    ? data.some(({ id }) => formField.value.includes(id))
    : true

  useEffect(() => {
    // If the selected item no longer exists (i.e. the data set changes), reset the value
    if (data?.length > 0 && !existsInData) {
      resetField(propertyId, { defaultValue: [] })
    }
  }, [data, propertyId, existsInData, resetField])

  return (
    <Autocomplete
      {...formField}
      disableCloseOnSelect
      value={values}
      multiple
      options={data}
      loading={isLoading}
      getOptionLabel={(option) => option.name}
      onChange={(_e, values) => {
        const ids = values.map(({ id }) => id)
        setValue(propertyId, ids, { shouldDirty: true })
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            required: required ? values.length === 0 : false, // workaround to allow submitting form https://github.com/mui/material-ui/issues/21663#issuecomment-732298594
          }}
          required={required}
          error={!!errors[propertyId]}
          label={display?.label || 'MISSING LABEL'}
          helperText={display.description}
          InputLabelProps={{ shrink: true }}
          variant={variant}
        />
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id} dense>
            {option.name}
          </ListItem>
        )
      }}
    />
  )
}
