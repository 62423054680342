import useGet, { UseGetOptions } from '@/service-library/core-hooks/useGet'
import { getDetail } from '@/service-library/request-wrappers'
import { Address } from '@/types/addresses'

/**
 * MARK: Action Hook: Get Smart Address
 * Takes a string address and runs it against address lookup endpoint.
 */
export function useGetSmartAddress(options: UseGetOptions<Address> = {}) {
  const query = useGet<Address>({
    queryKey: ['address_lookup', options.filters],
    serviceFn: (options) => {
      return getDetail<Address>({
        url: '/v2/pd-rules/address_lookup',
        ...options,
      })
    },
    ...options,
  })

  return {
    address: query.data,
    ...query,
  }
}
