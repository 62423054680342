/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from 'react-hook-form'
import { PixyDocsForm } from '../forms'
import ActionParamFields from '../workflow-state-page/ActionParamFields'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Box, Button, Stack } from '@mui/material'
import { useMemo } from 'react'
import { getDefaultValue } from '@/utils/rules'

type ActionEditFormProps = {
  action: WorkflowStateAction
  onSave: (updatedAction: WorkflowStateAction) => void
  onCancel: () => void
}

export default function ActionEditForm({
  action,
  onSave,
  onCancel,
}: ActionEditFormProps) {
  const defaultValues = useMemo(() => {
    const paramDefaultValues: Record<string, any> = {}
    if (action.action_type?.params_schema.properties) {
      Object.entries(
        action.action_type.params_schema.properties as Record<string, any>,
      ).forEach(([paramName, paramStructure]) => {
        paramDefaultValues[paramName] =
          action.params?.[paramName] || getDefaultValue(paramStructure)
      })
    }
    return paramDefaultValues as Record<string, any>
  }, [action])

  const methods = useForm({
    defaultValues,
  })

  function onSubmit(data: Record<string, string>) {
    onSave({
      ...action,
      params: data,
    })
  }

  return (
    <PixyDocsForm methods={methods} onSubmit={onSubmit}>
      <ActionParamFields action={action} />

      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="text"
          onClick={() => {
            onCancel()
          }}
        >
          Cancel
        </Button>
        <Button type="submit" disabled={!methods.formState.isValid}>
          Save
        </Button>
      </Stack>
    </PixyDocsForm>
  )
}
