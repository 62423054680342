import { FormHelperText } from '@mui/material'
import { FormCheckbox } from '@/components/forms'
import { PropertyFieldProps } from './PropertyField'

type PropertyCheckboxProps = PropertyFieldProps

export default function PropertyCheckbox({
  propertyId,
  property,
  required,
}: PropertyCheckboxProps) {
  const { display } = property

  return (
    <div>
      <FormCheckbox
        name={propertyId}
        label={display.label}
        required={required}
      />
      {display.description && (
        <FormHelperText>{display.description}</FormHelperText>
      )}
    </div>
  )
}
