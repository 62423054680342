import { useCallback, useMemo } from 'react'
import useLogixNodeTypes from '@/services/hooks/useLogixNodeTypes'
import {
  Connection,
  Edge,
  EdgeChange,
  EdgeSelectionChange,
  Node,
  NodeChange,
  NodeSelectionChange,
  OnConnectEnd,
  OnConnectStart,
  useReactFlow,
} from 'reactflow'
import useBulkDeleteLogixNodes from '@/services/hooks/useBulkDeleteLogixNodes'
import useUpdateLogixNode from '@/services/hooks/useUpdateLogixNode'
import useBoardState from './useBoardState'
import { LogixBoard, LogixHandle } from '@/types/logix'
import { getQueryKey } from '@/services/hooks/useLogixBoard'
import useBulkUpdateLogixNodes from '@/services/hooks/useBulkUpdateLogixNodes'
import { getHandleFromNode } from '../helpers/logix-helpers'
import useBulkDeleteLogixEdges from '@/services/hooks/useBulkDeleteLogixEdges'
import getNodeFromPosition from '../helpers/getNodeFromPosition'
import { OverlayState } from '@/hooks/useOverlay'
import useContextMenuPosition from '@/hooks/useContextMenuPosition'
import { useNodeProblems } from '../ProblemsProvider'
import useFilteredNodeTypes from './useFilteredNodeTypes'
import useAddNode from './useAddNode'
import useConnectHandles from './useConnectHandles'

type UseEventHandlersOptions = {
  state: ReturnType<typeof useBoardState>
  reactFlowRef: React.RefObject<HTMLDivElement>
  updateContextMenuPosition: ReturnType<
    typeof useContextMenuPosition
  >['handleUpdatePosition']
  contextMenuOverlay: OverlayState
  setFilteringValues: ReturnType<
    typeof useFilteredNodeTypes
  >['setFilteringValues']
  connectingInfo: {
    connectingNode?: Node
    connectingHandle?: LogixHandle
  }
  setConnectingInfo: React.Dispatch<
    React.SetStateAction<{
      connectingNode?: Node | undefined
      connectingHandle?: LogixHandle | undefined
    }>
  >
  containerRef: React.RefObject<HTMLDivElement>
}

export default function useEventHandlers({
  state,
  reactFlowRef,
  updateContextMenuPosition,
  contextMenuOverlay,
  setFilteringValues,
  connectingInfo,
  setConnectingInfo,
  containerRef,
}: UseEventHandlersOptions) {
  const board = state.board as LogixBoard
  const { setSelection } = state

  const { connectingHandle, connectingNode } = connectingInfo

  const reactFlowInstance = useReactFlow()
  const { setProblems } = useNodeProblems()

  const addNode = useAddNode({ board, containerRef })

  const { updateLogixNode, updateLogixNodesInCache } = useUpdateLogixNode()
  const { bulkUpdateLogixNodes } = useBulkUpdateLogixNodes()
  const { bulkDeleteLogixNodes } = useBulkDeleteLogixNodes({
    boardId: board.id,
  })

  const { bulkDeleteLogixEdges } = useBulkDeleteLogixEdges()

  const { nodeTypes = [] } = useLogixNodeTypes()

  const connectHandles = useConnectHandles({ containerRef })

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    if (event.dataTransfer) event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault()
      const code = event.dataTransfer?.getData('application/reactflow')
      const nodeType = nodeTypes.find((nodeType) => nodeType.code === code)

      if (!nodeType) return

      addNode({
        nodeType,
        position: {
          clientX: event.clientX,
          clientY: event.clientY,
        },
      })
    },
    [addNode, nodeTypes],
  )

  // This updates node positions in client cache, but *not in the backend*
  const updateNodePositionsInCache = useCallback(
    (nodes: Node[]) => {
      updateLogixNodesInCache(
        getQueryKey(board.id),
        nodes.map((node) => ({
          id: node.id,
          board_id: board.id,
          position: {
            x: node.position.x,
            y: node.position.y,
            h: node.data.logixNode.position.h,
            w: node.data.logixNode.position.w,
          },
        })),
      )
    },
    [board.id, updateLogixNodesInCache],
  )

  const updateNodePosition = useCallback(
    (node: Node) => {
      updateLogixNode({
        id: node.id,
        board_id: board.id,
        position: {
          x: node.position.x,
          y: node.position.y,
          h: node.data.logixNode.position.h,
          w: node.data.logixNode.position.w,
        },
      })
    },
    [board.id, updateLogixNode],
  )

  // This updates node positions in the backend
  const bulkUpdateNodePositions = useCallback(
    (nodes: Node[]) => {
      bulkUpdateLogixNodes(
        nodes.map((node) => ({
          id: node.id,
          board_id: board.id,
          position: {
            x: node.position.x,
            y: node.position.y,
            h: node.data.logixNode.position.h,
            w: node.data.logixNode.position.w,
          },
        })),
      )
    },
    [board.id, bulkUpdateLogixNodes],
  )

  const onNodeDrag = useCallback(
    (event: React.MouseEvent, draggedNode: Node, draggedNodes: Node[]) => {
      updateNodePositionsInCache(draggedNodes)
    },
    [updateNodePositionsInCache],
  )

  // Only fires when dragging multiple items
  const onSelectionDrag = useCallback(
    (event: React.MouseEvent, nodes: Node[]) => {
      updateNodePositionsInCache(nodes)
    },
    [updateNodePositionsInCache],
  )

  const onNodeDragStop = useCallback(
    (event: React.MouseEvent, node: Node, nodes: Node[]) => {
      if (nodes.length > 1) {
        bulkUpdateNodePositions(nodes)
      } else {
        updateNodePosition(node)
      }
    },
    [bulkUpdateNodePositions, updateNodePosition],
  )

  const onSelectionDragStop = useCallback(
    (event: React.MouseEvent, nodes: Node[]) => {
      bulkUpdateNodePositions(nodes)
    },
    [bulkUpdateNodePositions],
  )

  const onNodesDelete = useCallback(
    (deletedNodes: Node[]) => {
      const deletedNodesIds = deletedNodes.map(({ id }) => id)
      bulkDeleteLogixNodes(deletedNodesIds)
      setProblems((prev) =>
        prev.filter((problem) => !deletedNodesIds.includes(problem.nodeId)),
      )
    },
    [bulkDeleteLogixNodes, setProblems],
  )

  const onEdgesDelete = useCallback(
    (deletedEdges: Edge[]) => {
      bulkDeleteLogixEdges(deletedEdges.map(({ id }) => id))
    },
    [bulkDeleteLogixEdges],
  )

  const onNodesChange = useCallback(
    (nodeChanges: NodeChange[]) => {
      const selectionChanges = nodeChanges.filter(
        ({ type }) => type === 'select',
      ) as NodeSelectionChange[]

      // Update selection for any nodes that changed whether they are selected
      if (selectionChanges.length > 0) {
        setSelection((selection) => {
          const newNodeIds = { ...selection.nodeIds }
          selectionChanges.forEach(({ selected, id }) => {
            if (selected) newNodeIds[id] = true
            else delete newNodeIds[id]
          })
          return {
            nodeIds: newNodeIds,
            edgeIds: selection.edgeIds,
          }
        })
      }
    },
    [setSelection],
  )

  const onEdgesChange = useCallback(
    (edgeChanges: EdgeChange[]) => {
      const selectionChanges = edgeChanges.filter(
        ({ type }) => type === 'select',
      ) as EdgeSelectionChange[]

      // Update selection for any nodes that changed whether they are selected
      if (selectionChanges.length > 0) {
        setSelection((selection) => {
          selectionChanges.forEach(({ selected, id }) => {
            if (selected) selection.edgeIds[id] = true
            else delete selection.edgeIds[id]
          })
          return {
            nodeIds: selection.nodeIds,
            edgeIds: { ...selection.edgeIds },
          }
        })
      }
    },
    [setSelection],
  )

  const onConnect = useCallback(
    (connection: Connection) => {
      const {
        sourceHandle: sourceHandleId,
        targetHandle: targetHandleId,
        source: sourceNodeId,
        target: targetNodeId,
      } = connection
      if (!sourceHandleId || !targetHandleId || !sourceNodeId || !targetNodeId)
        return

      const sourceNode = reactFlowInstance.getNode(sourceNodeId)
      const targetNode = reactFlowInstance.getNode(targetNodeId)

      if (!sourceNode || !targetNode) return

      const sourceHandle = getHandleFromNode(
        sourceHandleId,
        sourceNode.data.logixNode,
      )
      const targetHandle = getHandleFromNode(
        targetHandleId,
        targetNode.data.logixNode,
      )

      if (!sourceHandle || !targetHandle) return

      // CONNECT THEM!
      connectHandles({
        sourceNode,
        targetNode,
        sourceHandle,
        targetHandle,
      })
    },
    [connectHandles, reactFlowInstance],
  )

  const onConnectStart: OnConnectStart = useCallback(
    (event, { nodeId, handleId }) => {
      if (!handleId || !nodeId) return
      const node = reactFlowInstance.getNode(nodeId)
      if (!node) return

      const handle = getHandleFromNode(handleId, node.data.logixNode)

      if (handle) {
        setConnectingInfo({
          connectingNode: node,
          connectingHandle: handle,
        })
      }
    },
    [reactFlowInstance, setConnectingInfo],
  )

  const onConnectEnd: OnConnectEnd = useCallback(
    (event) => {
      const currentNodes = reactFlowInstance.getNodes()
      const pane = reactFlowRef.current?.querySelector('div.react-flow__pane')
      // Only open the suggested node panel if the user ends dragging a handle onto an empty spot on the canvas
      const isIterationNodePane = (
        event.target as HTMLDivElement
      )?.classList.contains('node-children-container')

      if (
        (event.target === pane || isIterationNodePane) &&
        event instanceof MouseEvent &&
        reactFlowRef?.current
      ) {
        const reactFlowBounds = reactFlowRef.current.getBoundingClientRect()
        if (!reactFlowBounds) return

        // Get the node we landed on so we can filter out suggested nodes that can't go in them
        const targetNode = isIterationNodePane
          ? getNodeFromPosition(
              currentNodes || [],
              reactFlowInstance.project({
                x: event.x - reactFlowBounds.left,
                y: event.y - reactFlowBounds.top,
              }),
            )
          : undefined

        if (
          // Do nothing if we are starting from an iteration handle and trying to go outside the iteration loop
          // or connecting to another handle in the same node
          connectingHandle?.area === 'iteration' &&
          (event.target === pane ||
            (connectingNode &&
              targetNode &&
              connectingNode.id !== targetNode.id))
        ) {
          return
        }

        if (
          // Do nothing if we are starting from an iteration child node and trying to go outside the iteration loop
          connectingNode?.parentNode &&
          (event.target === pane ||
            (connectingNode &&
              targetNode &&
              connectingNode.parentNode !== targetNode.id))
        ) {
          const fullParentNode = currentNodes.find(
            ({ id }) => id === connectingNode.parentNode,
          )
          if (!fullParentNode?.data.allowNestedParents) return
        }

        if (
          // Do nothing if we are starting from a "regular" execution handle and trying to go outside scope (applies for iteration nodes)
          connectingNode &&
          targetNode &&
          connectingHandle?.data_type_code === 'execution' && // FIXME: This won't work anymore
          connectingHandle?.area !== 'iteration' &&
          targetNode.data.allowChildren &&
          !targetNode.data.allowNestedParents &&
          connectingNode.parentNode !== targetNode.id
        ) {
          return
        }

        event.preventDefault()

        setFilteringValues(connectingHandle, targetNode?.parentNode)

        // FIXME: Should not open the overlay if there aren't any suggested nodes
        updateContextMenuPosition(event.clientX, event.clientY)
        contextMenuOverlay.open(event)
      }
    },
    [
      connectingHandle,
      connectingNode,
      contextMenuOverlay,
      reactFlowInstance,
      reactFlowRef,
      setFilteringValues,
      updateContextMenuPosition,
    ],
  )

  return useMemo(
    () => ({
      onNodesChange,
      onEdgesChange,
      onNodeDrag,
      onNodeDragStop,
      onSelectionDrag,
      onSelectionDragStop,
      onNodesDelete,
      onConnect,
      onConnectStart,
      onConnectEnd,
      onEdgesDelete,
      onDragOver,
      onDrop,
    }),
    [
      onConnect,
      onConnectEnd,
      onConnectStart,
      onDragOver,
      onDrop,
      onEdgesChange,
      onEdgesDelete,
      onNodeDrag,
      onNodeDragStop,
      onNodesChange,
      onNodesDelete,
      onSelectionDrag,
      onSelectionDragStop,
    ],
  )
}
