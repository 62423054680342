/* eslint-disable @typescript-eslint/no-explicit-any */
import { MRT_Column } from 'material-react-table'
import { ViewWeek } from '@mui/icons-material'
import { ListItemIcon, MenuItem } from '@mui/material'
import { createDataLineageColumn } from '@/utils/field-utils'
import { useColumnVisibilityContext } from './ColumnVisibilityProvider'

type ToggleDataLineageButtonProps<TData extends Record<string, any>> = {
  closeMenu: () => void
  column: MRT_Column<TData>
}

export default function ToggleDataLineageButton<
  TData extends Record<string, any>,
>({ closeMenu, column }: ToggleDataLineageButtonProps<TData>) {
  const { handleColumnVisibilityChange } = useColumnVisibilityContext()

  const toggleDataLineage = () => {
    closeMenu()
    handleColumnVisibilityChange((prev) => {
      const dataLineageValues = Object.entries(prev)
        .filter(([key]) => key.includes(column.id))
        .map(([, value]) => value)
      const showDataLineageColumns = dataLineageValues.some((value) => !value)
      const columns = createDataLineageColumn({
        fieldId: column.id,
        groupValue: showDataLineageColumns,
      })
      return {
        ...prev,
        ...columns,
      }
    })
  }

  return (
    <MenuItem onClick={toggleDataLineage}>
      <ListItemIcon>
        <ViewWeek fontSize="small" />
      </ListItemIcon>
      Toggle Data Lineage Columns
    </MenuItem>
  )
}
