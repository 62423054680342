import { DocumentFlag, RowValueFlag } from '@/types/flags'
import { List } from '@mui/material'
import { RowValueFlagListItem, DocumentFlagListItem } from './FlagListItem'

type FlagsListProps = {
  flags: (RowValueFlag | DocumentFlag)[]
  onFlagClick?: (
    flag: RowValueFlag,
    cellToFocus: HTMLInputElement | HTMLDivElement | null,
  ) => void
}

export default function FlagsList({ flags, ...rest }: FlagsListProps) {
  return (
    <List disablePadding>
      {flags.map((flag) => {
        const isRowValueFlag = !!(flag as RowValueFlag).document_row_value_id
        return isRowValueFlag ? (
          <RowValueFlagListItem
            key={flag.id}
            flag={flag as RowValueFlag}
            {...rest}
          />
        ) : (
          <DocumentFlagListItem key={flag.id} flag={flag as DocumentFlag} />
        )
      })}
    </List>
  )
}
