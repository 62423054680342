import { scaleTime } from 'd3-scale'
import dayjs from 'dayjs'
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  blueGrey,
} from '@mui/material/colors'
import { GroupOption } from '@/types/metrics'

const colors = [
  red,
  deepPurple,
  lightBlue,
  green,
  yellow,
  deepOrange,
  blueGrey,
  pink,
  indigo,
  cyan,
  lightGreen,
  amber,
  brown,
  purple,
  blue,
  teal,
  lime,
  orange,
]

export const getColor = (index: number, colorIndex = 200) => {
  if (index > colors.length) {
    let colorShadeOffset = Math.floor((index + 1) / colors.length)
    // Condition to keep the shade range between 200 and 900
    if (colorShadeOffset > 7) {
      colorShadeOffset = colorShadeOffset % 7 ? (colorShadeOffset % 7) - 1 : 7
    }
    colorIndex += colorShadeOffset * 100
  }

  const mainColor: Record<number, string> = colors[index % colors.length]
  return mainColor[colorIndex]
}

export const getFormat = (groupedBy: GroupOption, includeYear: boolean) => {
  switch (groupedBy) {
    case 'hour':
      return `MMM DD h${includeYear ? ' A, YY' : ':mm A'}`
    case 'day':
      return `MMM DD${includeYear ? ', YY' : ''}`
    case 'month':
      return includeYear ? 'MMM, YY' : 'MMMM'
    case 'year':
      return 'YYYY'
  }
}

export const formatter = (
  value: string | Date,
  groupedBy: GroupOption,
  includeYear: boolean,
) => dayjs(value).format(getFormat(groupedBy, includeYear))

export const getTimeTicks = (
  from: Date,
  to: Date,
  groupedBy: GroupOption,
  includeYear: boolean,
  ticksQuantity?: number,
) => {
  const scale = scaleTime().domain([from, to]).nice()
  const ticks = Array.from(new Set(scale.ticks(ticksQuantity ?? 60)))
  return ticks.map((tick) => formatter(tick, groupedBy, includeYear))
}
