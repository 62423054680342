import BaseHandle from './BaseHandle'
import { Position } from 'reactflow'
import { PartialHandleProps } from '../types'
import { LogixHandle } from '@/types/logix'

type OutputHandleProps = PartialHandleProps & {
  logixHandle: LogixHandle
  style?: React.CSSProperties
}

export default function OutputHandle(props: OutputHandleProps) {
  return <BaseHandle position={Position.Right} type="source" {...props} />
}
