import { ReactNode, createContext, useContext } from 'react'
import { useProjectContext } from '../project-dashboard/ProjectProvider'
import { Workflow, WorkflowState } from '@/types/workflows'
import { useGetWorkflows } from '@/service-library/hooks/workflows'
import { useGetProjectModels } from '@/service-library/hooks/project-models'
import { sortBySortOrder } from '@/utils/field-utils'
import {
  CATEGORY_MODEL_TYPE_ID,
  OCR_MODEL_TYPE_ID,
} from '../models-page/helpers'

type WorkflowContextValue = ReturnType<typeof useGetWorkflows> & {
  workflows: Workflow[]
  productionDataWorkflow: Workflow | undefined
  labelTrainingWorkflow: Workflow | undefined
  getWorkflowByCode: (code: string) => Workflow | undefined
  getWorkflowStateByCode: (
    workflowCode: string,
    stateCode: string,
  ) => WorkflowState | undefined
  getAllWorkflowStates: () => WorkflowState[]
  getWorkflowsByModelType: () => {
    productionWorkflows: Workflow[]
    labelingModelWorkflows: Workflow[]
    categoryModelWorkflows: Workflow[]
  }
  getChildWorkflows: (workflow: Workflow) => Workflow[]
}

const WorkflowContext = createContext<WorkflowContextValue>({
  workflows: [],
  isLoading: false,
  productionDataWorkflow: undefined,
  labelTrainingWorkflow: undefined,
  getWorkflowByCode: () => undefined,
  getWorkflowStateByCode: () => undefined,
  getAllWorkflowStates: () => undefined,
  getWorkflowsByModelType: () => ({
    productionWorkflows: [],
    labelingModelWorkflows: [],
    categoryModelWorkflows: [],
  }),
  getChildWorkflows: () => [],
} as unknown as WorkflowContextValue)

type WorkflowsProviderProps = {
  children: ReactNode
}

export const useWorkflowsContext = () => useContext(WorkflowContext)

export default function WorkflowsProvider({
  children,
}: WorkflowsProviderProps) {
  const { project } = useProjectContext()
  // ZACH TODO: project_model isn't available yet as a nested serializer, but we'll probably want it for multiple workflows list dropdown thing
  const query = useGetWorkflows({
    filters: {
      limit: '1000',
      project_id: project.id,
      fields__include: 'workflow_states,project_model',
      workflow_states__fields__include:
        'workflow_id,excluded_project_grid_fields_ids',
    },
  })

  const { workflows } = query

  const workflowsWithOrderedStates = workflows.map((workflow) => ({
    ...workflow,
    workflow_states: sortBySortOrder(workflow?.workflow_states || []),
  }))

  // Get the root level models so we can show only workflows for root level models
  const { projectModels } = useGetProjectModels({
    filters: {
      limit: '1000',
      project_id: project.id,
      fields__only: 'id,project_model_type_id,parent_model_id',
      project_model_versions__fields__only: 'id,training_status,is_current',
      'project_model_type_id!': OCR_MODEL_TYPE_ID, // Filter out the OCR model type since we don't let the user manage it
    },
  })
  const parentProjectModels = projectModels.filter(
    (model) => !model.parent_model_id,
  )

  function getFirstNonModelWorkflow() {
    return workflows.find((workflow) => !workflow.project_model_id)
  }

  // Note: When there are multiple workflows with the same code (e.g. 'categorization') this will return the first one
  function getWorkflowByCode(code: string) {
    return workflows.find((workflow) => workflow.code === code)
  }

  function getWorkflowStateByCode(workflowCode: string, stateCode: string) {
    const workflow = getWorkflowByCode(workflowCode)
    return workflow?.workflow_states?.find((state) => state.code === stateCode)
  }

  function getAllWorkflowStates() {
    return workflows.flatMap((workflow) => workflow.workflow_states || [])
  }

  function getWorkflowsByModelType(): {
    productionWorkflows: Workflow[]
    labelingModelWorkflows: Workflow[]
    categoryModelWorkflows: Workflow[]
  } {
    return workflows.reduce<{
      productionWorkflows: Workflow[]
      labelingModelWorkflows: Workflow[]
      categoryModelWorkflows: Workflow[]
    }>(
      (acc, workflow) => {
        if (!workflow.project_model_id) {
          acc.productionWorkflows.push(workflow)
        } else {
          const model = parentProjectModels.find(
            (model) => model.id === workflow.project_model_id,
          )
          if (model?.project_model_type_id === CATEGORY_MODEL_TYPE_ID) {
            acc.categoryModelWorkflows.push(workflow)
          } else if (model) {
            acc.labelingModelWorkflows.push(workflow)
          }
        }
        return acc
      },
      {
        productionWorkflows: [],
        labelingModelWorkflows: [],
        categoryModelWorkflows: [],
      },
    )
  }

  function getChildWorkflows(workflow: Workflow): Workflow[] {
    // First get all child models so we can get the workflows for them
    const childModels = projectModels.filter(
      (model) => model.parent_model_id === workflow.project_model_id,
    )

    // Then get the workflows for each child model
    return workflows.filter((w) => {
      return childModels.some((model) => model.id === w.project_model_id)
    })
  }

  return (
    <WorkflowContext.Provider
      value={{
        ...query,
        workflows: workflowsWithOrderedStates,
        getWorkflowByCode,
        getWorkflowStateByCode,
        getAllWorkflowStates,
        productionDataWorkflow: getFirstNonModelWorkflow(),
        labelTrainingWorkflow: getWorkflowByCode('labeling'),
        getWorkflowsByModelType,
        getChildWorkflows,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  )
}
