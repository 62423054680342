import PageTitle from '@/components/PageTitle'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import OrganizationTypesTable from '@/components/organization-types/OrganizationTypesTable'

export default function OrganizationTypesPage() {
  return (
    <>
      <PageTitle>Organization Types</PageTitle>
      <Breadcrumb
        label="Organization Types"
        url="/settings/organization-types"
      />
      <OrganizationTypesTable />
    </>
  )
}
