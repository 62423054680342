import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Container, Stack, TextField, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useAuthentication } from '@/components/auth/AuthProvider'
import { verifyUserCode } from '@/services/users'
import queryKeys from '@/service-library/query-keys'
import { useUpdateCurrentUser } from '@/service-library/hooks/users'
import { showErrorSnackbar } from '@/utils/snackbars'
import { PixyDocsForm } from '@/components/forms'
import PageTitle from '@/components/PageTitle'

type FormValues = {
  code: string
}

export default function VerifyEmailPage() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const { user } = useAuthentication()
  const { updateCurrentUser } = useUpdateCurrentUser({
    onError: () => {
      showErrorSnackbar(
        'Unable to update your information. Please try again later.',
      )
    },
  })

  const code = searchParams.get('verification_code')
  const errorCode = searchParams.get('error_code')
  const defaultError = 'Something went wrong. Please try again later.'
  const [error, setError] = useState<string>(errorCode ? defaultError : '')

  const hideForm = error === defaultError || errorCode === '409'

  const methods = useForm<FormValues>({
    defaultValues: {
      code: '',
    },
  })

  const {
    formState: { isDirty, isSubmitSuccessful, isValid },
    register,
    reset,
  } = methods

  const header = code
    ? 'Verifying Email...'
    : hideForm
    ? 'Verify Email'
    : 'Enter Verification Code'

  const message = code
    ? 'This may take a moment.'
    : error
    ? error
    : 'To verify your email, enter the six-digit code you received.'

  function onSubmit(values: FormValues) {
    setSearchParams({ verification_code: values.code })
    if (error) setError('')
  }

  useEffect(() => {
    if (code) {
      verifyUserCode(code)
        .then(() => {
          queryClient.invalidateQueries({ queryKey: queryKeys.users.details() })
          navigate('/settings/my-profile', { state: { verifiedEmail: true } })
        })
        .catch((e) => {
          setSearchParams({ error_code: e.response.status })
          setError(
            e.response.status === 500
              ? 'Something went wrong. Please try again later or contact support if this keeps happening.'
              : e.response.data.detail,
          )
        })
    }
  }, [code, navigate, queryClient, setSearchParams])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        code: '',
      })
    }
  }, [isSubmitSuccessful, reset])

  if (!user) return null

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <PageTitle>Verify Email</PageTitle>
      <Stack spacing={3}>
        <Typography align="center" variant="h3" component="h1">
          {header}
        </Typography>
        <Typography align="center" color={error ? 'error' : ''}>
          {message}
        </Typography>

        {!code && !hideForm && (
          <>
            <PixyDocsForm methods={methods} onSubmit={onSubmit}>
              <Stack
                direction="row"
                justifyContent="center"
                spacing={1}
                alignItems="center"
              >
                <TextField
                  sx={{ maxWidth: 300 }}
                  variant="outlined"
                  placeholder="123456"
                  {...register('code', {
                    minLength: 6,
                    maxLength: 6,
                    pattern: /^\d+$/,
                  })}
                />
                <div>
                  <Button
                    type="submit"
                    size="medium"
                    sx={{ height: 40 }}
                    disabled={!isDirty || !isValid}
                  >
                    Verify
                  </Button>
                </div>
              </Stack>
            </PixyDocsForm>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button
                component={Link}
                to="/settings/my-profile"
                sx={{ width: 'fit-content' }}
                variant="text"
              >
                Go to My Profile
              </Button>
              <Button
                onClick={() => updateCurrentUser(user)}
                sx={{ width: 'fit-content' }}
                variant="text"
              >
                Resend Verification Email
              </Button>
            </Stack>
          </>
        )}

        {hideForm && (
          <Button
            component={Link}
            to="/settings/my-profile"
            sx={{ width: 'fit-content', mx: 'auto !important' }}
            variant="text"
          >
            Go to My Profile
          </Button>
        )}
      </Stack>
    </Container>
  )
}
