import { ReactNode } from 'react'
import { Box, DialogActions, Stack } from '@mui/material'

type DialogFooterProps = {
  children: ReactNode
  details?: ReactNode
}

export default function DialogFooter({ children, details }: DialogFooterProps) {
  return (
    <DialogActions>
      <Stack
        p={1}
        spacing={2}
        direction="row"
        sx={{ width: '100%' }}
        alignItems="center"
      >
        <Box sx={{ flexGrow: 1 }}>{details}</Box>
        {children}
      </Stack>
    </DialogActions>
  )
}
