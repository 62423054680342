import { ProjectGridField } from '@/types/fields'
import { ProjectGrid } from '@/types/projects'
import {
  ContentCategory,
  ContentCategoryItem,
} from '@/types/content-categories'

export const fieldTypes = {
  STANDARD: 'standard',
  TABLE: 'grid',
  EMPTY: 'empty',
}

export const getBaseGrid = (grids: ProjectGrid[]) => {
  return grids.length === 1
    ? grids[0]
    : grids.find(
        ({ parent_project_grid_id }) => parent_project_grid_id === null,
      )
}

export const getNonGridFields = (fields: ProjectGridField[]) => {
  return fields.filter((field) => field.project_grid_field_type.code !== 'grid')
}

export const getNonGridAndNonPickerFields = (fields: ProjectGridField[]) => {
  return fields.filter(
    (field) =>
      field.project_grid_field_type.code !== 'grid' &&
      field.project_grid_field_type.code !== 'picker',
  )
}

//TODO: Remove sorting for when the backend returns data sorted
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortBySortOrder = (array: any[]) => {
  return array.sort((a, b) => {
    if (a.sort_order !== b.sort_order) return a.sort_order - b.sort_order
    return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
  })
}

export const appendContentCategories = (
  fields: ProjectGridField[],
  categoriesContent: ContentCategory[],
) => {
  categoriesContent.forEach((category) => {
    const fieldIndex = fields.findIndex(
      (field) => field.project_content_category_id === category.id,
    )
    if (fieldIndex >= 0)
      //TODO: Remove sorting for when the backend returns data sorted
      fields[fieldIndex].category_items = sortBySortOrder(
        category?.project_content_category_items as ContentCategoryItem[],
      )
  })
  return fields
}

export const appendContentCategoriesToGrids = (
  grids: ProjectGrid[],
  categoriesContent: ContentCategory[],
) => {
  grids.forEach((grid) => {
    grid.project_grid_fields = appendContentCategories(
      grid.project_grid_fields,
      categoriesContent,
    )
  })
  return grids
}

export const appendSubGridFieldsToBaseGridAndCleanFields = ({
  baseGrid,
  grids,
}: {
  baseGrid: ProjectGrid
  grids: ProjectGrid[]
}) => {
  baseGrid.project_grid_fields.forEach((field) => {
    if (field.sub_project_grid_id) {
      const subGrid = grids.find(
        (grid) => grid.id === field.sub_project_grid_id,
      ) as ProjectGrid
      field.fields = sortBySortOrder(subGrid.project_grid_fields)
    }
  })
  return baseGrid
}

export const createDataLineageColumn = ({
  fieldId,
  gridValue,
  fieldValue,
  groupValue,
}: {
  fieldId: string
  gridValue?: boolean
  fieldValue?: boolean
  groupValue?: boolean
}) => {
  return ['original', 'rule', 'manual', 'final'].reduce((acc, type) => {
    const columnId = type === 'final' ? fieldId : `${fieldId}-${type}`
    let isVisible
    if (gridValue === false || fieldValue === false) {
      isVisible = false
    } else if (groupValue) {
      isVisible = groupValue
    } else {
      isVisible = type === 'final'
    }
    return {
      ...acc,
      [columnId]: isVisible,
    }
  }, {})
}

export const validateFieldName = (name: string, fieldNames: string[]) => {
  return name.trim().length > 0 && !fieldNames.includes(name.toLowerCase())
}

export const aggregateFunctionOptions = [
  { label: 'None', value: 'none' },
  { label: 'Sum', value: 'sum' },
  { label: 'Min', value: 'min' },
  { label: 'Max', value: 'max' },
]

export const numberFieldTypes = ['int', 'decimal', 'currency']

export const getAggregateValue = ({
  values,
  aggregateFunction,
}: {
  values: string[]
  aggregateFunction: 'sum' | 'min' | 'max'
}) => {
  switch (aggregateFunction) {
    case 'sum':
      return values.reduce(
        (acc: number, value: string) => acc + parseFloat(value),
        0,
      )
    case 'min':
      return Math.min(...values.map((value) => parseFloat(value)))
    case 'max':
      return Math.max(...values.map((value) => parseFloat(value)))
  }
}

export const getFieldsTree = (
  baseFields: ProjectGridField[],
  projectGrids?: ProjectGrid[],
) => {
  const tableGridFields = baseFields.reduce<Record<string, ProjectGridField[]>>(
    (acc, field) => {
      if (field.sub_project_grid_id) {
        const subGrid = projectGrids?.find(
          (grid) => grid.id === field.sub_project_grid_id,
        )

        acc[field.sub_project_grid_id] = sortBySortOrder(
          subGrid?.project_grid_fields || [],
        )
      }
      return acc
    },
    {},
  )

  return baseFields.map((field) => ({
    field,
    fields: field.sub_project_grid_id
      ? tableGridFields[field.sub_project_grid_id]
      : undefined,
  }))
}
