import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { ProjectModel } from '@/types/project-models'
import { OverlayState } from '@/hooks/useOverlay'
import { useUpdateProjectModel } from '@/service-library/hooks/project-models'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type FormValues = {
  name: string
}

type EditModelDialogProps = {
  detailQueryKey: QueryKey
  overlay: OverlayState
  model: ProjectModel
}

export default function EditModelDialog({
  detailQueryKey,
  overlay,
  model,
}: EditModelDialogProps) {
  const { updateProjectModel } = useUpdateProjectModel({ detailQueryKey })

  const methods = useForm<FormValues>({
    defaultValues: {
      name: model.name,
    },
  })

  function onSubmit(values: FormValues) {
    updateProjectModel({
      ...model,
      ...values,
    })
    overlay.close()
  }

  useEffect(() => {
    if (!overlay.isOpen) {
      methods.reset({
        name: model.name,
      })
    }
  }, [methods, model.name, overlay.isOpen])

  return (
    <Dialog title="Edit Model" {...overlay} maxWidth="xs">
      <PixyDocsForm methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <FormTextField name="name" label="Name" fullWidth required />
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
