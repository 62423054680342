import { ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'
import { Upload } from '@mui/icons-material'
import { Project } from '@/types/projects'
import DropZone from './DropZone'
import { useFileUploadContext } from '../upload-files/FileUploadProvider'

type ProjectCardDropZoneProps = {
  children: ReactNode
  project: Project
}

export default function ProjectCardDropZone({
  children,
  project,
}: ProjectCardDropZoneProps) {
  const { handleFilesSelectedToUpload } = useFileUploadContext()

  if (project.setup_state !== 'complete') return <>{children}</>

  return (
    <DropZone
      onDrop={handleFilesSelectedToUpload}
      overlayChildren={
        <Stack
          sx={{
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#0008',
          }}
        >
          <Upload sx={{ fontSize: 36 }} />
          <Typography variant="body1" textAlign="center">
            Upload Documents
          </Typography>
        </Stack>
      }
    >
      {children}
    </DropZone>
  )
}
