import { ReactNode } from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  StackProps,
} from '@mui/material'
import useLocalStorage from '@/hooks/useLocalStorage'

type SectionAccordionProps = {
  title: string
  children: ReactNode
  spacing?: StackProps['spacing']
}

export default function SectionAccordion({
  title,
  children,
  spacing = 0.5,
}: SectionAccordionProps) {
  const [expanded, setExpanded] = useLocalStorage(
    `document-dialog-section-expanded-${title}`,
    true,
  )

  return (
    <Accordion
      expanded={expanded}
      elevation={0}
      onChange={(_, expanded) => setExpanded(expanded)}
      sx={{
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          mb: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDown />}
        sx={{
          p: 0,
          mb: 0,
          minHeight: 'min-content !important',
          '& .MuiAccordionSummary-content': {
            margin: '0px !important',
          },
          '&.Mui-expanded': {
            mb: spacing,
          },
        }}
      >
        <Typography color="text.secondary" variant="h6" component="h3">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </Accordion>
  )
}
