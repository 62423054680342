import { ProjectGridFieldType } from '@/types/fields'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<ProjectGridFieldType>({
  pathCategory: 'project_grid_field_types',
})

export function useGetProjectGridFieldType({
  id,
  ...options
}: UseGetDetailOptions<ProjectGridFieldType>) {
  const query = useGetDetail<ProjectGridFieldType>({
    id,
    queryKey: queryKeys.projectGridFieldTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectGridFieldType: query.data,
    ...query,
  }
}

export function useGetProjectGridFieldTypes({
  filters,
  ...options
}: UseGetListOptions<ProjectGridFieldType> = {}) {
  const query = useGetList<ProjectGridFieldType>({
    filters,
    queryKey: queryKeys.projectGridFieldTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectGridFieldTypes: query.allData,
    ...query,
  }
}
