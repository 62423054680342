import { useMemo } from 'react'
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { ProjectCategory as ProjectCategoryT } from '@/types/projects'
import AddProjectCategoryDialog from '@/components/projects/AddProjectCategoryDialog'
import PageTitle from '@/components/PageTitle'
import ProjectCategory from '@/components/projects/ProjectCategory'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import { useGetProjects } from '@/service-library/hooks/projects'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useGetProjectCategories } from '@/service-library/hooks/project-categories'

export default function ProjectsPage() {
  const { rootOrganization } = useRootOrganization()
  const { hasEditingPermission } = usePermission()
  const {
    queryKey,
    projectCategories = [],
    isError: categoriesError,
    isLoading: categoriesIsLoading,
  } = useGetProjectCategories({
    filters: {
      limit: '1000',
      org_id: rootOrganization.id,
    },
  })

  const {
    projects = [],
    isError: projectsError,
    isLoading: projectsIsLoading,
  } = useGetProjects({
    filters: {
      limit: '1000',
      org_id: rootOrganization.id,
      fields__include: 'project_category,basic_states_stats',
    },
  })

  const addCategoryOverlay = useOverlay()

  const isLoading = projectsIsLoading || categoriesIsLoading
  const isError = categoriesError || projectsError

  const categoriesWithProjects = useMemo(() => {
    if (!isLoading) {
      const allCategoriesObj: Record<string, ProjectCategoryT> = [
        ...projectCategories,
      ].reduce(
        (all, category) => ({
          ...all,
          [category.id]: { ...category, projects: [] },
        }),
        {},
      )

      projects.forEach((project) => {
        const categoryId = project.project_category_id
        allCategoriesObj[categoryId].projects?.push(project)
      })

      return Object.values(allCategoriesObj)
    }
    return []
  }, [projectCategories, isLoading, projects])

  const canAddEdit = hasEditingPermission('edit_projects')

  return (
    <>
      <PageTitle>Projects</PageTitle>

      <Container maxWidth="md" sx={{ pt: 4, pb: 4 }}>
        <Stack spacing={6}>
          {isLoading && (
            <Typography textAlign="center" sx={{ mt: 4 }}>
              <CircularProgress />
            </Typography>
          )}

          {isError && (
            <Alert severity="error">
              Unable to load projects and categories. Please try again later.
            </Alert>
          )}

          {!isLoading && !isError && (
            <>
              {categoriesWithProjects.map((category) => (
                <ProjectCategory
                  key={category.id}
                  canAddEdit={canAddEdit}
                  category={category}
                />
              ))}

              {canAddEdit && (
                <div>
                  <Button
                    onClick={addCategoryOverlay.open}
                    startIcon={<AddIcon />}
                    variant="text"
                  >
                    New Category
                  </Button>
                </div>
              )}
            </>
          )}
        </Stack>

        {canAddEdit && (
          <AddProjectCategoryDialog
            overlay={addCategoryOverlay}
            listQueryKey={queryKey}
          />
        )}
      </Container>
    </>
  )
}
