import { useEffect, useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { ProjectGridField } from '@/types/fields'
import { ProjectGridFieldRule } from '@/types/rules'
import { useGetProjectGridFields } from '@/service-library/hooks/project-grid-fields'
import { useUpdateProjectGridFieldRule } from '@/service-library/hooks/project-grid-field-rules'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import {
  getBaseGrid,
  getFieldsTree,
  getNonGridFields,
  sortBySortOrder,
} from '@/utils/field-utils'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import FieldRules from '@/components/project-settings/field-rules/FieldRules'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import RuleSettings, { FormValues } from '@/components/rules/RuleSettings'
import FieldRulesFieldList from './FieldRulesFieldList'

export default function FieldRulesPage() {
  const { project } = useProjectContext()

  const { projectGridFields = [], isLoading } = useGetProjectGridFields({
    filters: {
      limit: '1000',
      project_id: project.id,
      fields__include: 'project_grid_field_rules,project_grid_field_type',
      project_grid_field_rules__fields_include: 'rule',
    },
  })

  const { updateProjectGridFieldRule, isLoading: isUpdatingRule } =
    useUpdateProjectGridFieldRule({
      sideEffectQueryKeys: [queryKeys.projectGridFieldRules.all],
      onSuccess: () => {
        showSuccessSnackbar('Field Rule Updated')
      },
      onError: () => {
        showErrorSnackbar('Field Rule Update Failed')
      },
    })

  const baseGrid = getBaseGrid(project.project_grids || [])
  const baseFields = sortBySortOrder(
    projectGridFields.filter(
      ({ project_grid_id }) => project_grid_id === baseGrid?.id,
    ),
  )

  const fieldInfos = getFieldsTree(baseFields, project.project_grids)

  const [selectedField, setSelectedField] = useState<ProjectGridField>(
    baseFields[0],
  )
  const [selectedRule, setSelectedRule] = useState<ProjectGridFieldRule | null>(
    null,
  )

  const updateFieldRule = async ({
    workflow_states_ids,
    dependent_fields,
    is_superuser_rule,
    ...rest
  }: FormValues) => {
    if (selectedRule) {
      return updateProjectGridFieldRule({
        ...selectedRule,
        dependent_fields_ids: dependent_fields.map(({ id }) => id),
        workflow_states_ids,
        is_superuser_rule,
        params: rest,
      })
    }
    return
  }

  useEffect(() => {
    setSelectedRule(null)
  }, [selectedField])

  useEffect(() => {
    if (!isLoading && !selectedField) {
      setSelectedField(baseFields[0])
    }
  }, [baseFields, isLoading, selectedField])

  if (isLoading || !selectedField) return null

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <ProjectBreadcrumb label="Field Rules" url="../field-rules" />
      <Grid2 container spacing={2} sx={{ maxWidth: '100%' }}>
        <Grid2 xs={12}>
          <Typography variant="h5" color="textPrimary">
            Field Rules
          </Typography>
        </Grid2>

        <Grid2 xs={3} sx={{ height: 'calc(100vh - 165px)' }}>
          <FieldRulesFieldList
            fieldInfos={fieldInfos}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
          />
        </Grid2>

        <Grid2 xs={9}>
          <Card sx={{ p: 1, height: '100%' }}>
            <Grid2 container columnSpacing={2}>
              {/* <Grid2 xs={12}>
                <RuleTesterSection />
                <Divider />
              </Grid2> */}
              <Grid2 xs={12}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Rules
                </Typography>
              </Grid2>
              <Grid2 xs={6}>
                <FieldRules
                  field={selectedField}
                  selectedRule={selectedRule}
                  setSelectedRule={setSelectedRule}
                />
              </Grid2>
              {selectedRule && (
                <Grid2 xs={6}>
                  <RuleSettings
                    key={selectedRule.id} // Completely rerender each time we change the selected rule
                    fieldId={selectedField.id}
                    isLoading={isUpdatingRule}
                    onSubmit={updateFieldRule}
                    rule={selectedRule}
                    nonGridFields={getNonGridFields(projectGridFields)}
                  />
                </Grid2>
              )}
            </Grid2>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  )
}
