import { useParams, Link } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { Alert, Button, CircularProgress, Stack } from '@mui/material'
import { useGetUser } from '@/service-library/hooks/users'
import { useAuthentication } from '@/components/auth/AuthProvider'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import UserDetails from './UserDetails'

export default function UserPage() {
  const { id } = useParams()
  const { user: loggedInUser, userQuery } = useAuthentication()
  const isCurrentUser = loggedInUser?.id === id
  const query = useGetUser({
    id: id || '',
    filters: {
      fields__include: 'pd_groups_ids,pd_groups,teams_ids',
    },
    enabled: !isCurrentUser,
  })

  const { user, isLoading, isError, queryKey } = isCurrentUser
    ? userQuery
    : query

  return (
    <>
      {user && <Breadcrumb label={user.name} url={`/settings/users/${id}`} />}

      {!isLoading && !user && (
        <Alert severity="error">
          Unable to load this user.{' '}
          {isError
            ? 'Please try again later.'
            : 'You may not have access or this user does not exist.'}
        </Alert>
      )}

      {isLoading ? (
        <Stack alignItems="center" sx={{ p: 4 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Button
            sx={{ ml: '-4px', my: 2 }}
            startIcon={<ArrowBack />}
            component={Link}
            to="../"
            variant="text"
          >
            Back to Users
          </Button>
          {user && <UserDetails user={user} detailQueryKey={queryKey} />}
        </>
      )}
    </>
  )
}
