import { useMemo, useState } from 'react'
import { useReactFlow } from 'reactflow'
import useLogixBoard from '@/services/hooks/useLogixBoard'
import convertLogixNode, { convertLogixEdge } from '../helpers/convertLogixNode'
import { useEdgesContext } from '../EdgesProvider'

export type Selection = {
  nodeIds: Record<string, boolean>
  edgeIds: Record<string, boolean>
}

type UseBoardStateProps = {
  boardId: string
}

/**
 * This hook is responsible for all stateful values for the board.
 */
export default function useBoardState({ boardId }: UseBoardStateProps) {
  const reactFlowInstance = useReactFlow()

  const { board } = useLogixBoard({ id: boardId })
  const { edges: logixEdges } = useEdgesContext()
  const { nodes: logixNodes } = board ?? { nodes: [] }

  const [selection, setSelection] = useState<Selection>({
    nodeIds: {},
    edgeIds: {},
  })

  const nodes = useMemo(() => {
    const converted = logixNodes.map(convertLogixNode).map((node) => {
      // We include the internal node state because it includes state we don't track
      const internalNode = reactFlowInstance?.getNode(node.id)
      return {
        ...internalNode,
        ...node,
        selected: !!selection.nodeIds[node.id],
      }
    })
    return converted
  }, [logixNodes, reactFlowInstance, selection.nodeIds])

  const edges = useMemo(() => {
    if (!logixEdges) return []
    return logixEdges.map(convertLogixEdge).map((edge) => {
      // We include the internal edge state because it includes state we don't track
      const internalEdge = reactFlowInstance?.getEdge(edge.id)
      return {
        ...internalEdge,
        ...edge,
        selected: !!selection.edgeIds[edge.id],
      }
    })
  }, [logixEdges, reactFlowInstance, selection.edgeIds])

  return {
    board,
    nodes,
    edges,
    selection,
    setSelection,
  }
}
