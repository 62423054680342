import { forwardRef } from 'react'
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  InputLabelProps,
  Select,
  SelectProps,
} from '@mui/material'
import { FieldValues, useController, FieldPath } from 'react-hook-form'

type FormSelectProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label: string
  helperText?: string
  InputLabelProps?: InputLabelProps
  selectProps?: SelectProps
} & FormControlProps

const FormSelect = forwardRef(function FormSelect<
  FormValues extends FieldValues,
>(
  {
    name,
    required,
    label,
    children,
    helperText,
    InputLabelProps,
    selectProps,
    ...props
  }: FormSelectProps<FormValues>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { field } = useController<FormValues>({
    name,
    rules: {
      required,
    },
  })

  return (
    <FormControl fullWidth required={required} {...props}>
      <InputLabel id={field.name} {...InputLabelProps}>
        {label}
      </InputLabel>
      <Select
        displayEmpty
        labelId={field.name}
        label={label}
        {...field}
        inputRef={ref}
        {...selectProps}
      >
        {children}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
})

export default FormSelect
