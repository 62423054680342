import { LogixEdge, LogixHandle, LogixNode } from '@/types/logix'

export function getHandlesFromLogixNode(node: LogixNode) {
  return {
    inputs: [...node.dyn_in_handles, ...node.node_type.type_in_handles],
    outputs: [...node.dyn_out_handles, ...node.node_type.type_out_handles],
  }
}

export function getHandleFromList(handleId: string, handles: LogixHandle[]) {
  return handles.find((handle) => handle.id === handleId)
}

export function getHandleFromNode(handleId: string, node: LogixNode) {
  const { inputs, outputs } = getHandlesFromLogixNode(node)
  return getHandleFromList(handleId, [...inputs, ...outputs])
}

export function getHandleOfMatchingType(
  handle: LogixHandle,
  handles: LogixHandle[],
  allowAny = false,
) {
  // FIXME: Should also work in reverse - if the filtering handle is an output, it should check against the type_out_handles
  return handles.find(({ data_type_code, is_list }) => {
    return (
      is_list === handle.is_list &&
      (data_type_code === handle.data_type_code ||
        data_type_code === 'any' ||
        allowAny)
    )
  })
}

export function getEdgeConnectedToHandle(handleId: string, edges: LogixEdge[]) {
  return edges.find(
    (edge) =>
      edge.source_handle_id === handleId || edge.target_handle_id === handleId,
  )
}
