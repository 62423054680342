import { ProjectGridFieldRule } from '@/types/rules'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<ProjectGridFieldRule>({
  pathCategory: 'project_grid_field_rules',
})

export function useGetProjectGridFieldRule({
  id,
  ...options
}: UseGetDetailOptions<ProjectGridFieldRule>) {
  const query = useGetDetail<ProjectGridFieldRule>({
    id,
    queryKey: queryKeys.projectGridFieldRules.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectGridFieldRule: query.data,
    ...query,
  }
}

export function useCreateProjectGridFieldRule(
  options: UseCreateDetailOptions<ProjectGridFieldRule> = {},
) {
  const mutation = useCreateDetail<ProjectGridFieldRule>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectGridFieldRule: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectGridFieldRule(
  options: UseUpdateDetailOptions<ProjectGridFieldRule> = {},
) {
  const mutation = useUpdateDetail<ProjectGridFieldRule>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectGridFieldRule: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectGridFieldRule(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<ProjectGridFieldRule>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectGridFieldRule: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectGridFieldRules({
  filters,
  ...options
}: UseGetListOptions<ProjectGridFieldRule> = {}) {
  const query = useGetList<ProjectGridFieldRule>({
    filters,
    queryKey: queryKeys.projectGridFieldRules.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectGridFieldRules: query.allData,
    ...query,
  }
}

export function useUpdateProjectGridFieldRules(
  options: UseUpdateListOptions<ProjectGridFieldRule> = {},
) {
  const mutation = useUpdateList<ProjectGridFieldRule>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectGridFieldRules: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectGridFieldRules({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectGridFieldRule>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectGridFieldRules: mutation.mutateAsync,
    ...mutation,
  }
}
