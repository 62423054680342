import {
  AccessTimeOutlined,
  ArrowDropDownCircleOutlined,
  AttachMoneyOutlined,
  CalendarTodayOutlined,
  CheckBoxOutlined,
  CorporateFareOutlined,
  CreditCardOutlined,
  EventOutlined,
  ExpandCircleDownOutlined,
  LocationOnOutlined,
  LocalPhoneOutlined,
  NumbersOutlined,
  ShortTextOutlined,
  TableRowsOutlined,
  BarChart,
} from '@mui/icons-material'
import { FieldTypeName } from '@/types/fields'

export default function getDataTypeIcon(data_type: FieldTypeName) {
  switch (data_type) {
    case 'category':
      return ArrowDropDownCircleOutlined
    case 'text':
      return ShortTextOutlined
    case 'int':
      return NumbersOutlined
    case 'decimal':
      return NumbersOutlined
    case 'date':
      return CalendarTodayOutlined
    case 'time':
      return AccessTimeOutlined
    case 'datetime':
      return EventOutlined
    case 'currency':
      return AttachMoneyOutlined
    case 'organization':
      return CorporateFareOutlined
    case 'cc_num':
      return CreditCardOutlined
    case 'address':
      return LocationOnOutlined
    case 'phone':
      return LocalPhoneOutlined
    case 'grid':
      return TableRowsOutlined
    case 'checkbox':
      return CheckBoxOutlined
    case 'picker':
      return ExpandCircleDownOutlined
    case 'bar_chart':
      return BarChart
    default:
      return ShortTextOutlined
  }
}
