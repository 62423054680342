import { useMemo } from 'react'
import {
  FormControlLabel,
  Stack,
  Typography,
  Checkbox,
  Autocomplete,
  ListItem,
  TextField,
} from '@mui/material'
import { Team } from '@/types/teams'
import { useGetTeams } from '@/service-library/hooks/teams'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'

type AutocompleteTeamsFilterProps = {
  teams: Team[]
  filterContext: ReturnType<typeof useDocumentFiltersContext>
}

const AutocompleteTeamsFilter = ({
  teams,
  filterContext,
}: AutocompleteTeamsFilterProps) => {
  const { documentFilters, includeFilter, handleOnDocumentFilterChange } =
    filterContext
  const onChangeHandler = (checkedTeams: Team[]) => {
    handleOnDocumentFilterChange((prev) => {
      const checkedTeamIds = checkedTeams.map(({ id }) => id)
      if (!prev) return { flagCodes: [], orgIds: [], teamIds: checkedTeamIds }

      const newFilters = { ...prev }
      newFilters.teamIds = checkedTeamIds
      return newFilters
    })
  }

  const selectedValues = useMemo(() => {
    if (includeFilter.teams && documentFilters?.teamIds) {
      return teams.filter(({ id }) => documentFilters.teamIds.includes(id))
    }
    return []
  }, [documentFilters?.teamIds, includeFilter.teams, teams])

  return (
    <Autocomplete
      multiple
      value={selectedValues}
      options={teams}
      onChange={(e, checkedTeams) => {
        onChangeHandler(checkedTeams)
      }}
      disableCloseOnSelect
      getOptionLabel={(team: Team) => team.name}
      renderOption={(props, team, { selected }) => (
        <ListItem {...props} dense disablePadding sx={{ columnGap: 0.5 }}>
          <Checkbox color="neutral" sx={{ padding: 0 }} checked={selected} />
          <Typography variant="body2">{team.name}</Typography>
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Filter by Team"
          sx={{
            '> div': {
              py: '4px !important',
            },
          }}
        />
      )}
      slotProps={{ paper: { elevation: 4 } }}
    />
  )
}

export default function TeamsFilter() {
  const { rootOrganization } = useRootOrganization()
  const { teams } = useGetTeams({
    filters: {
      owner_org_id: rootOrganization.id,
      is_system_managed: 'false',
      limit: '1000',
    },
    enabled: !!rootOrganization.id,
  })

  const filterContext = useDocumentFiltersContext()
  const { documentFilters, handleOnDocumentFilterChange } = filterContext

  const onChangeHandler = (teamId: string, checked: boolean) => {
    handleOnDocumentFilterChange((prev) => {
      if (!prev) return { flagCodes: [], orgIds: [], teamIds: [teamId] }
      const newFilters = { ...prev }
      if (checked) {
        newFilters.teamIds = [...newFilters.teamIds, teamId]
      } else {
        newFilters.teamIds = newFilters.teamIds.filter((id) => id !== teamId)
      }
      return newFilters
    })
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography>Teams</Typography>
      {documentFilters &&
        (teams.length >= 10 ? (
          <AutocompleteTeamsFilter
            filterContext={filterContext}
            teams={teams}
          />
        ) : (
          <Stack spacing={0.5}>
            {teams.map((team) => (
              <FormControlLabel
                key={team.id}
                label={team.name}
                control={
                  <Checkbox
                    color="neutral"
                    sx={{ padding: 0 }}
                    checked={documentFilters.teamIds.includes(team.id)}
                    onChange={(event, checked) =>
                      onChangeHandler(team.id, checked)
                    }
                  />
                }
                componentsProps={{ typography: { variant: 'body2' } }}
                sx={{ mx: 0, columnGap: 0.5, width: 'fit-content' }}
              />
            ))}
          </Stack>
        ))}
    </Stack>
  )
}
