import { MetadataId } from '@/hooks/useColumnFilters'
import { FieldTypeName } from '@/types/fields'
import { MRT_FilterOption } from 'material-react-table'

const filterModeOptionMapping = (isMetadataColumn: boolean) =>
  ({
    betweenInclusive: 'range',
    contains: 'icontains',
    empty: isMetadataColumn ? 'isnull' : 'isblank', // We don't show empty for metadata columns, but just in case
    endsWith: 'iendswith',
    equals: 'iexact',
    greaterThan: 'gt',
    greaterThanOrEqualTo: 'gte',
    lessThan: 'lt',
    lessThanOrEqualTo: 'lte',
    notEmpty: isMetadataColumn ? 'isnull' : 'isblank', // We don't show notEmpty for metadata columns, but just in case
    startsWith: 'istartswith',
  } as Record<MRT_FilterOption, string>)

export const getEndpointFilterMode = (
  filterMode: MRT_FilterOption,
  fieldType: FieldTypeName | MetadataId,
) => {
  switch (fieldType) {
    case 'pageCountColId':
    case 'uploadColId':
    case 'enteredAtColId':
    case 'currency':
    case 'int':
    case 'decimal':
    case 'date':
    case 'time':
    case 'datetime':
    case 'checkbox': {
      if (filterMode === 'equals') {
        return fieldType === 'pageCountColId' ? '' : '__exact'
      }
      return `__${
        filterModeOptionMapping(fieldType.endsWith('ColId'))[filterMode]
      }`
    }
    default:
      return `__${
        filterModeOptionMapping(fieldType.endsWith('ColId'))[filterMode]
      }`
  }
}

export default function getColumnFilterModeOptions(
  columnType: FieldTypeName | MetadataId,
) {
  switch (columnType) {
    case 'fileColId':
    case 'organizationColId':
    case 'submitterColId':
      return ['contains', 'equals', 'startsWith', 'endsWith']
    case 'address':
    case 'category':
    case 'cc_num':
    case 'phone':
    case 'picker':
    case 'text':
      return [
        'contains',
        'equals',
        'startsWith',
        'endsWith',
        'empty',
        'notEmpty',
      ]
    case 'currency':
    case 'int':
    case 'decimal':
    case 'pageCountColId':
    case 'uploadColId':
    case 'enteredAtColId':
    case 'date':
    case 'time':
    case 'datetime': {
      const baseFilters = [
        'equals',
        'greaterThan',
        'greaterThanOrEqualTo',
        'lessThan',
        'lessThanOrEqualTo',
        'betweenInclusive',
      ]
      if (['currency', 'int', 'decimal'].includes(columnType)) {
        return [...baseFilters, 'empty', 'notEmpty']
      }
      return baseFilters
    }
    case 'checkbox':
      return ['equals']
    default:
      return [
        'equals',
        'contains',
        'startsWith',
        'endsWith',
        'empty',
        'notEmpty',
      ]
  }
}
