import { Container, Typography, Stack, Button } from '@mui/material'
import PageTitle from '@/components/PageTitle'

export default function NotFound() {
  return (
    <Container maxWidth="md" sx={{ pt: 6, pb: 6 }}>
      <PageTitle>404 - Not Found</PageTitle>
      <Stack spacing={3}>
        <div>
          <Typography align="center" sx={{ fontSize: 160 }} color="primary">
            404
          </Typography>
          <Typography variant="h4" align="center">
            Not Found
          </Typography>
        </div>

        <Typography align="center">
          Looks like there&apos;s nothing here.
        </Typography>

        <Stack alignItems="center">
          <Button variant="outlined" href="/">
            Go to Home Page
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}
