import { DocumentRow } from '@/types/documents'
import { createContext, ReactNode, useContext } from 'react'

const DocumentRowContext = createContext<DocumentRow>({} as DocumentRow)

export const useDocumentRowContext = () => useContext(DocumentRowContext)

type DocumentRowProviderProps = {
  documentRow: DocumentRow
  children: ReactNode
}

export default function DocumentRowProvider({
  documentRow,
  children,
}: DocumentRowProviderProps) {
  return (
    <DocumentRowContext.Provider value={documentRow}>
      {children}
    </DocumentRowContext.Provider>
  )
}
