import { memo } from 'react'
import { Button, Card } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { ProjectGridField } from '@/types/fields'
import {
  ProjectModelTrainingField,
  ProjectModelType,
} from '@/types/project-models'
import useOverlay from '@/hooks/useOverlay'
import { useGetProjectModelTypeFieldTypeRestrictions } from '@/service-library/hooks/project-model-type-field-type-restrictions'
import EditTrainingFieldsDialog from './EditTrainingFieldsDialog'

type EditFieldNodeProps = {
  listQueryKey: QueryKey
  flow: 'in' | 'out'
  disabled: boolean
  fields: ProjectGridField[]
  projectModelId: string
  projectModelType: ProjectModelType
  modelTrainingFields: {
    inputs: ProjectModelTrainingField[]
    outputs: ProjectModelTrainingField[]
  }
}
function EditTrainingFieldNode({
  flow,
  disabled,
  projectModelType,
  ...rest
}: EditFieldNodeProps) {
  const addEditOverlay = useOverlay()

  const { projectModelTypeFieldTypeRestrictions } =
    useGetProjectModelTypeFieldTypeRestrictions({
      filters: {
        model_type_id: projectModelType.id,
      },
    })

  const restrictions = projectModelTypeFieldTypeRestrictions?.filter(
    (restriction) => restriction.flow === flow,
  )
  const noRestrictions = restrictions.length === 0

  return (
    <>
      <Card sx={{ p: 1.5, borderRadius: 2 }}>
        <Button
          variant="text"
          color="primary"
          disabled={disabled}
          onClick={addEditOverlay.open}
        >
          Edit {flow === 'in' ? 'Input' : 'Output'} Fields
        </Button>
      </Card>
      <EditTrainingFieldsDialog
        overlay={addEditOverlay}
        flow={flow}
        projectModelType={projectModelType}
        restrictedFieldTypes={
          noRestrictions
            ? undefined
            : {
                kind: restrictions[0].kind, // We check for the first one since the kind should unique per flow
                ids: restrictions.map(
                  (restriction) => restriction.field_type_id,
                ),
              }
        }
        {...rest}
      />
    </>
  )
}

export default memo(EditTrainingFieldNode)
