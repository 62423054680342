import { Dispatch, SetStateAction, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Stack } from '@mui/material'
import { Workflow, WorkflowState } from '@/types/workflows'
import useOverlay from '@/hooks/useOverlay'
import DashboardEventButton from './DashboardEventButton'
import ExportDocumentsDialog from './ExportDocumentsDialog'

type DocumentGridActionButtonsProps = {
  workflow: Workflow
  state: WorkflowState
  selectedDocumentIds: string[]
  tableHasData: boolean
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
  onViewSelected: () => void
}

export default function DocumentGridActionButtons({
  workflow,
  state,
  selectedDocumentIds,
  tableHasData,
  setSelectedRows,
  onViewSelected,
}: DocumentGridActionButtonsProps) {
  const exportOverlay = useOverlay()
  const { projectId } = useParams()
  const [exportAll, setExportAll] = useState(true)

  return (
    <Stack
      data-testid="dg-action-buttons"
      direction="row"
      spacing={1}
      flexGrow={1}
      justifyContent="flex-end"
    >
      {state.code === 'ready_for_export' && (
        <>
          <Button
            disabled={!selectedDocumentIds.length}
            variant="text"
            size="small"
            onClick={() => {
              exportOverlay.open()
              setExportAll(false)
            }}
          >
            Export Selected ({selectedDocumentIds.length})
          </Button>
          <Button
            disabled={!tableHasData}
            variant="text"
            size="small"
            onClick={() => {
              exportOverlay.open()
              setExportAll(true)
            }}
          >
            Export All
          </Button>
          <ExportDocumentsDialog
            overlay={exportOverlay}
            projectId={projectId as string}
            state={state}
            workflow={workflow}
            setSelectedRows={setSelectedRows}
            documentIds={exportAll ? undefined : selectedDocumentIds}
          />
        </>
      )}
      <Button
        disabled={!selectedDocumentIds.length}
        size="small"
        variant="text"
        onClick={onViewSelected}
      >
        View Selected ({selectedDocumentIds.length})
      </Button>

      <DashboardEventButton
        documentIds={selectedDocumentIds}
        workflowStateId={state.id}
      />
    </Stack>
  )
}
