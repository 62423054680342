import Markdown from 'react-markdown'
import { Link, Typography, List } from '@mui/material'

// We are just accounting for the old descriptions we created with html.
function htmlToMarkdown(html: string) {
  html = html.replace(/<a\s+href=['"](.*?)['"][^>]*>(.*?)<\/a>/gi, '[$2]($1)')

  html = html.replace(/<ul>/gi, '\n')
  html = html.replace(/<\/ul>/gi, '\n')
  html = html.replace(/<li>/gi, '\n- ')
  html = html.replace(/<\/li>/gi, '')

  // Remove any remaining HTML tags
  html = html.replace(/<\/?[^>]+>/g, '')

  // Replace multiple newlines with a single newline
  html = html.replace(/\n+/g, '\n')

  if (html.includes('user-script_id')) {
    html = `\`\`\`\n${html}\n\`\`\``
  }
  return html.trim()
}

export function cleanLongDescription(longDescription: string) {
  let newLongDescription =
    longDescription.includes('</a>') ||
    longDescription.includes('</ul>') ||
    (longDescription.includes('user-script_id') &&
      !longDescription.includes('```'))
      ? htmlToMarkdown(longDescription)
      : longDescription
  // Remove the last </li> if it exists - Left by mistake in the flag created by the flag data document rule
  if (newLongDescription.includes('</li>')) {
    newLongDescription = newLongDescription.replace('</li>', '')
  }
  return newLongDescription
}

type FlagLongDescriptionProps = {
  longDescription: string
}

export default function FlagLongDescription({
  longDescription,
}: FlagLongDescriptionProps) {
  return (
    <Markdown
      components={{
        a: ({ children, href }) => (
          <Link
            color="primary"
            href={href}
            target="_blank"
            onClick={(e) => e.stopPropagation()}
            underline="none"
            noWrap
            sx={{ display: 'block' }}
            variant="inherit"
          >
            {children}
          </Link>
        ),
        p: ({ children }) => (
          <Typography variant="inherit" sx={{ whiteSpace: 'pre-wrap' }}>
            {children}
          </Typography>
        ),
        code: ({ children }) => (
          <code
            style={{
              display: 'block',
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
            }}
          >
            {children}
          </code>
        ),
        ul: ({ children }) => (
          <List
            dense
            disablePadding
            sx={{ listStyleType: 'disc', pl: 2, fontSize: 'inherit' }}
          >
            {children}
          </List>
        ),
      }}
    >
      {cleanLongDescription(longDescription)}
    </Markdown>
  )
}
