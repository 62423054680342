import useCreateLogixNode from '@/services/hooks/useCreateLogixNode'
import { LogixBoard, LogixNodeType } from '@/types/logix'
import generateUuid from '@/utils/generate-uuid'
import { useReactFlow } from 'reactflow'

type UseAddNodeOptions = {
  board?: LogixBoard
  containerRef: React.RefObject<HTMLDivElement>
}

export default function useAddNode({ board, containerRef }: UseAddNodeOptions) {
  const reactFlowInstance = useReactFlow()
  const { createLogixNode } = useCreateLogixNode()

  return ({
    nodeType,
    position,
  }: {
    nodeType: LogixNodeType
    // If using coordinates within reactflow, use x/y. If using mouse click coordinates, use clientX/clientY.
    position: {
      x?: number
      y?: number
      clientX?: number
      clientY?: number
    }
  }) => {
    if (!board || !reactFlowInstance) return
    const { top = 0, left = 0 } =
      containerRef.current?.getBoundingClientRect() || {}

    const { x = 0, y = 0, clientX = 0, clientY = 0 } = position

    const internalPosition =
      x && y
        ? { x, y }
        : reactFlowInstance.project({
            x: clientX - left,
            y: clientY - top,
          })

    const newNodeId = generateUuid()
    createLogixNode({
      id: newNodeId,
      name: '',
      board_id: board.id,
      node_type_id: nodeType.id,
      node_type: nodeType,
      dyn_in_handles: [],
      dyn_out_handles: [],
      in_edges: [],
      out_edges: [],
      position: {
        x: internalPosition.x,
        y: internalPosition.y,
        h: 0,
        w: 0,
      },
    })
    return newNodeId
  }
}
