import { useNavigate } from 'react-router-dom'
import { Delete, Edit } from '@mui/icons-material'
import {
  Button,
  Container,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useOverlay from '@/hooks/useOverlay'
import {
  useDeleteWorkflow,
  useUpdateWorkflow,
} from '@/service-library/hooks/workflows'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import LargeHeading from '@/components/large-heading/LargeHeading'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import EditWorkflowDialog from './AddEditWorkflowDialog'
import DeleteWorkflowDialog from './DeleteWorkflowDialog'

const systemWorkflows = ['basic', 'labeling', 'categorization']

const commonCss = {
  content: '""',
  transition: 'all 0.1s',
  width: 4,
  display: 'inline-block',
  height: '90%',
  position: 'absolute',
  top: '5%',
  left: 0,
}

export default function WorkflowSettings() {
  const navigate = useNavigate()
  const getThemeColor = useGetThemeColor()
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { queryKey } = useWorkflowsContext()
  const editOverlay = useOverlay()
  const deleteOverlay = useOverlay()
  const isSystemWorkflow = systemWorkflows.includes(selectedWorkflow.code)

  const workflowStates =
    selectedWorkflow.workflow_states?.filter(
      ({ code }) => code !== 'processing',
    ) || []
  const initialWorkflowState = workflowStates?.find(
    ({ id }) => id === selectedWorkflow.initial_workflow_state_id,
  )

  const { deleteWorkflow } = useDeleteWorkflow({
    listQueryKey: queryKey,
    onMutate: () => {
      navigate('../general')
    },
    onSuccess: () => {
      showSuccessSnackbar('Workflow Deleted')
    },
    onError: () => {
      showErrorSnackbar('Unable to delete workflow. Please try again later.')
    },
  })

  const { updateWorkflow } = useUpdateWorkflow({
    listQueryKey: queryKey,
  })

  return (
    <Container maxWidth="md" sx={{ height: '100%', py: 1 }}>
      <ProjectBreadcrumb label="Workflow General" url="../workflow-general" />

      <Stack spacing={2}>
        <LargeHeading
          heading={selectedWorkflow.name}
          subHeading="Workflow Settings"
          actions={
            <>
              <Button
                variant="text"
                startIcon={<Edit />}
                onClick={editOverlay.open}
              >
                Edit
              </Button>
              <Tooltip
                enterDelay={1000}
                placement="bottom-start"
                title={
                  isSystemWorkflow
                    ? 'This workflow cannot be deleted. If you need this workflow removed, contact Pixydocs support.'
                    : ''
                }
              >
                <div>
                  <Button
                    disabled={isSystemWorkflow}
                    variant="text"
                    startIcon={<Delete />}
                    color="error"
                    onClick={deleteOverlay.open}
                  >
                    Delete
                  </Button>
                </div>
              </Tooltip>
              <CopyIDButton stringToCopy={selectedWorkflow.id} />
            </>
          }
        />
        <EditWorkflowDialog overlay={editOverlay} workflow={selectedWorkflow} />
        <DeleteWorkflowDialog
          name={selectedWorkflow.name}
          overlay={deleteOverlay}
          onDelete={() => {
            deleteOverlay.close()
            deleteWorkflow(selectedWorkflow.id)
          }}
        />

        {/* Select Initial Workflow State */}
        <TextField
          fullWidth
          label="Initial Workflow State"
          helperText="Documents added to this workflow will start in this workflow state."
          InputLabelProps={{ shrink: true }}
          select
          SelectProps={{
            sx: {
              '&::before': {
                background: getThemeColor(initialWorkflowState?.color),
                borderRadius: 2,
                ...commonCss,
              },
            },
            value: initialWorkflowState?.id || '',
          }}
          variant="outlined"
          sx={{ maxWidth: 400 }}
          onChange={(e) => {
            updateWorkflow({
              ...selectedWorkflow,
              initial_workflow_state_id: e.target.value,
            })
          }}
        >
          {workflowStates.length > 0 ? (
            workflowStates.map((state) => (
              <MenuItem
                key={state.id}
                value={state.id}
                sx={{
                  '&::before': {
                    background: getThemeColor(state.color),
                    ...commonCss,
                  },
                }}
              >
                {state.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No workflow states.</MenuItem>
          )}
        </TextField>
      </Stack>
    </Container>
  )
}
