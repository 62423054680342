import { memo } from 'react'
import { Theme, Typography } from '@mui/material'
import { TreeItem, TreeItemProps } from '@mui/x-tree-view/TreeItem'
import { OrgTree } from '@/types/organizations'
import HighlightedSearchTerm from '@/components/highlighted-search-term/HighlightedSearchTerm'

export type OrganizationTreeItemProps = {
  orgTree: OrgTree
  searchTerm?: string
  SubOrgComponent?: React.ElementType
  subOrgComponentProps?: Record<string, unknown>
  getIsDisabled?: (id: string) => boolean
  getIsVisible?: (id: string) => boolean
} & Omit<TreeItemProps, 'nodeId'>

type OrganizationTreeItemLabel = {
  code: string | null
  name: string
  searchTerm?: string
  size?: 'small' | 'regular'
}

export function OrganizationTreeItemLabel({
  code,
  name,
  searchTerm,
  size = 'regular',
}: OrganizationTreeItemLabel) {
  return (
    <Typography noWrap variant={size === 'small' ? 'body2' : undefined}>
      <HighlightedSearchTerm text={name} searchTerm={searchTerm} />
      {code && (
        <Typography
          component="span"
          variant={size === 'small' ? 'caption' : 'body2'}
          color="textSecondary"
        >
          {' '}
          ({code})
        </Typography>
      )}
    </Typography>
  )
}

function OrganizationTreeItem({
  label,
  orgTree,
  searchTerm,
  getIsDisabled,
  getIsVisible,
  SubOrgComponent = OrganizationTreeItem,
  subOrgComponentProps,
  ...props
}: OrganizationTreeItemProps) {
  const { id, subOrganizations } = orgTree
  const { sx, ContentProps, TransitionProps } = props
  const visible = getIsVisible ? getIsVisible(id) : true
  const disabled = getIsDisabled?.(id) || false

  return visible ? (
    <TreeItem
      {...props}
      // disabled={getIsDisabled?.(id) || false} TODO: Update when a parent node state doesn't override this https://github.com/mui/mui-x/issues/11575
      nodeId={id}
      label={
        label || (
          <OrganizationTreeItemLabel searchTerm={searchTerm} {...orgTree} />
        )
      }
      sx={{
        ml: 2,
        '.MuiTreeItem-iconContainer': {
          display: subOrganizations.length ? undefined : 'none !important',
        },
        ...sx,
      }}
      ContentProps={{
        ...ContentProps,
        // @ts-expect-error -- sx is a valid prop
        sx: {
          borderRadius: 2,
          opacity: disabled ? 0.38 : undefined,
          backgroundColor: disabled ? 'transparent' : undefined,
          ':hover': {
            backgroundColor: () => (disabled ? 'transparent' : undefined),
          },
          // @ts-expect-error -- sx is a valid prop
          ...ContentProps?.sx,
        },
      }}
      TransitionProps={{
        ...TransitionProps,
        // @ts-expect-error -- sx is a valid prop
        sx: {
          ml: '15px', // default is 17px os to account for border, we subtract 2px.
          borderLeft: (theme: Theme) => `2px dashed ${theme.palette.divider}`,
          // @ts-expect-error -- sx is a valid prop
          ...TransitionProps?.sx,
        },
      }}
    >
      {subOrganizations.map((orgTree) => {
        return (
          <SubOrgComponent
            key={orgTree.id}
            orgTree={orgTree}
            searchTerm={searchTerm}
            getIsDisabled={getIsDisabled}
            getIsVisible={getIsVisible}
            {...props}
            {...subOrgComponentProps}
          />
        )
      })}
    </TreeItem>
  ) : null
}

export default memo(OrganizationTreeItem)
