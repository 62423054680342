/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react'
import { useAuthentication } from '@/components/auth/AuthProvider'
import { useRegisterDocuments } from '@/service-library/hooks/documents'
import uploadToS3 from '@/services/upload-to-s3'
import generateUuid from '@/utils/generate-uuid'
import { Document } from '@/types/documents'
import { Organization } from '@/types/organizations'
import { showErrorSnackbar } from '@/utils/snackbars'

export default function useUploadFiles() {
  const [numberToUpload, setNumberToUpload] = useState(0)
  const { user } = useAuthentication()

  const { registerDocuments, error } = useRegisterDocuments()

  async function uploadFiles({
    projectId,
    orgId,
    files,
  }: {
    projectId: string
    orgId: string
    files: FileList
  }) {
    const allDocuments: Document[] = []
    const fileToDocumentObjects = Array.from(files).map((file) => ({
      file,
      document_id: generateUuid(),
    }))

    setNumberToUpload((prev) =>
      prev >= 0 // If for some reason we ever end up with a negative number, this would fix it
        ? prev + fileToDocumentObjects.length
        : fileToDocumentObjects.length,
    )

    // Split file objects into batches of 15
    const batches: {
      file: File
      document_id: string
    }[][] = []
    for (let i = 0; i < fileToDocumentObjects.length; i += 15) {
      batches.push(fileToDocumentObjects.slice(i, i + 15))
    }

    async function processBatch(batch: Document[]) {
      const promises = batch.map(async (document) => {
        if (!document) return
        const fileToDoc = fileToDocumentObjects.find(
          (fileToDoc) => fileToDoc.document_id === document.id,
        )
        return uploadToS3(document.upload_file_url!, fileToDoc!.file)
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            // Remove this document from the uploading list
            setNumberToUpload((prev) => prev - 1)
          })
      })
      await Promise.all(promises) // Wait for all uploads within the batch to finish
    }

    let error = false // If any batch fails, we will set this to true
    for (const batch of batches) {
      try {
        const documents = await registerDocuments(
          batch.map(
            (fileToDoc) =>
              ({
                name: fileToDoc.file.name,
                owner_org_id: orgId,
                project_id: projectId,
                submitter: user?.name,
                submitter_email: user?.email,
                id: fileToDoc.document_id,
              } as Document), // We don't need more data to register the document
          ),
        )
        allDocuments.push(...documents)
        await processBatch(documents)
      } catch {
        setNumberToUpload((prev) => prev - batch.length)
        error = true
      }
    }

    error &&
      showErrorSnackbar(
        'Some uploads may have failed. If this problem persists, please contact Pixydocs support.',
      )

    return allDocuments
  }

  function uploadFilesAndSetOrgs({
    projectId,
    org,
    files,
  }: {
    projectId: string
    org: Organization
    files: FileList
  }) {
    uploadFiles({
      projectId,
      orgId: org.id,
      files,
    })
  }

  return {
    uploadFiles,
    uploadFilesAndSetOrgs,
    numberToUpload,
    error,
  }
}
