import { useState } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import { Add, Delete, Edit } from '@mui/icons-material'
import { Button, Container, IconButton, Stack } from '@mui/material'
import { OrgType } from '@/types/organizations'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import {
  useDeleteOrgType,
  useGetOrgTypes,
} from '@/service-library/hooks/org-types'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import AddEditOrgTypeDialog from './AddEditOrgTypeDialog'

const columns: MRT_ColumnDef<OrgType>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
]

export default function OrganizationTypesTable() {
  const [type, setType] = useState<OrgType>()
  const { hasEditingPermission } = usePermission()
  const addEditOrgTypeOverlay = useOverlay()
  const { rootOrganization } = useRootOrganization()

  const { orgTypes, isLoading, queryKey } = useGetOrgTypes({
    filters: {
      limit: '1000',
      owner_org_id: rootOrganization?.id,
    },
  })

  const { deleteOrgType } = useDeleteOrgType({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to delete type. Please try again later.')
    },
  })

  const canEditOrg = hasEditingPermission('edit_orgs', rootOrganization.id)

  const table = useZerapixTable<OrgType>({
    title: 'Organization Types',
    columns,
    data: orgTypes,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 85,
      },
    },
    enableRowActions: canEditOrg,
    enablePagination: false,
    initialState: { density: 'comfortable' },
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            setType(row.original)
            addEditOrgTypeOverlay.open()
          }}
        >
          <Edit fontSize="small" />
        </IconButton>
        <IconButton
          color="error"
          size="small"
          onClick={() => {
            deleteOrgType(row.original.id)
          }}
        >
          <Delete fontSize="small" />
        </IconButton>
      </Stack>
    ),
    renderTableActions: canEditOrg
      ? () => {
          return (
            <Button
              onClick={() => {
                setType(undefined)
                addEditOrgTypeOverlay.open()
              }}
              startIcon={<Add />}
              variant="text"
            >
              New Type
            </Button>
          )
        }
      : undefined,
    state: { isLoading },
  })

  return (
    <Container maxWidth="sm">
      <ZerapixTable<OrgType> table={table} />

      <AddEditOrgTypeDialog
        overlay={addEditOrgTypeOverlay}
        organizationType={type}
        listQueryKey={queryKey}
      />
    </Container>
  )
}
