import { getExportFiles } from '@/services/export'
import useQuery from './useQuery'

export default function useExportFiles(projectId: string) {
  const query = useQuery({
    queryKey: ['export_files', { projectId }],
    queryFn: () => getExportFiles(projectId).then(({ data }) => data.results),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  })

  return { files: query.data, ...query }
}
