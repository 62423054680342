import { Skeleton, Stack, Typography } from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import { ProjectGridFieldRule } from '@/types/rules'
import { useGetDataListColumns } from '@/service-library/hooks/data-list-columns'
import {
  useGetDataListEntryCell,
  useGetDataListEntryCells,
} from '@/service-library/hooks/data-list-entry-cells'
import FieldInfoLabeledValue from './FieldInfoLabeledValue'
import { useGetDataListEntryCellValue } from '@/service-library/hooks/data-list-entry-cell-values'

type ListEntryDataProps = {
  enabled: boolean
  field: ProjectGridField
  refId: string
  listLookupFieldRule?: ProjectGridFieldRule
}

export default function ListEntryData({
  enabled,
  field,
  refId,
  listLookupFieldRule,
}: ListEntryDataProps) {
  const dataListId = listLookupFieldRule
    ? listLookupFieldRule.params?.data_list_id
    : field.params?.data_list_id || field.metadata?.data_list_id

  const { dataListColumns, isLoading: columnsIsLoading } =
    useGetDataListColumns({
      filters: {
        limit: '1000',
        data_list_id: dataListId,
        is_system_managed: 'false',
      },
      enabled: enabled && !!dataListId,
    })

  const { dataListEntryCellValue } = useGetDataListEntryCellValue({
    id: refId,
    filters: {
      fields__only: 'id,data_list_entry_cell_id',
    },
    enabled: enabled && !!listLookupFieldRule,
  })

  const { dataListEntryCell } = useGetDataListEntryCell({
    id: dataListEntryCellValue?.data_list_entry_cell_id || '',
    filters: {
      fields__only: 'id,data_list_entry_id',
    },
    enabled: enabled && !!listLookupFieldRule && !!dataListEntryCellValue,
  })

  const entryId = listLookupFieldRule
    ? dataListEntryCell?.data_list_entry_id
    : refId

  const { dataListEntryCells, isLoading: cellsIsLoading } =
    useGetDataListEntryCells({
      filters: {
        limit: dataListColumns.length.toString(),
        data_list_entry_id: entryId,
        data_list_column_id__in: dataListColumns
          .map((column) => column.id)
          .join(','),
        fields__include: 'data_list_entry_cell_values',
      },
      enabled: enabled && dataListColumns.length > 0 && !!entryId,
    })

  const isLoading = columnsIsLoading || cellsIsLoading

  return (
    <Stack
      flexGrow={1}
      spacing={isLoading ? 0.75 : 1.5}
      p={1.5}
      onMouseDown={(e) => e.preventDefault()}
    >
      <Typography component="h3" variant="subtitle1" sx={{ px: 0.5 }}>
        List Entry
      </Typography>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : !dataListEntryCells.length ? (
        <Typography color="error" variant="body2" sx={{ px: 0.5 }}>
          The item selected for this field either no longer exists or is from a
          different list. Please select a new one or re-run rules if necessary.
        </Typography>
      ) : (
        dataListColumns.map((column) => {
          return (
            <FieldInfoLabeledValue
              key={column.id}
              label={{ name: column.name }}
              value={
                dataListEntryCells?.find(
                  (cell) => cell.data_list_column_id === column.id,
                )?.data_list_entry_cell_values[0]?.value || null
              }
            />
          )
        })
      )}
    </Stack>
  )
}
