import PageTitle from '@/components/PageTitle'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import { Outlet } from 'react-router-dom'

export default function ListsPage() {
  return (
    <>
      <PageTitle>Lists</PageTitle>
      <Breadcrumb label="Lists" url="/settings/lists" />
      <Outlet />
    </>
  )
}
