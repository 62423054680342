import { createList } from '../request-wrappers'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import { SplitDocument } from '@/types/documents'

/**
 * MARK: Action Hook: Bulk Split Create Documents
 * Used to create documents by splitting a multi-page document.
 */
export function useBulkSplitCreateDocuments({
  id,
  ...options
}: UseUpdateListOptions<SplitDocument> & { id: string }) {
  const mutation = useUpdateList<SplitDocument>({
    serviceFn: (serviceFnOptions) => {
      return createList<SplitDocument>({
        url: `/v2/pd-ocr/break_up_multi_page_document/${id}`,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    bulkSplitCreateDocuments: mutation.mutateAsync,
    ...mutation,
  }
}
