import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import useMutation from './useMutation'
import { LogixEdge } from '@/types/logix'
import { bulkDeleteLogixEdges } from '@/services/logix'
import { useBoardContext } from '@/components/flow-editor/BoardProvider'
import { getQueryKey } from './useLogixEdges'

type UseBulkDeleteLogixEdgesOptions = UseMutationOptions<
  unknown,
  unknown,
  string[],
  { previous: LogixEdge[] }
>

export default function useBulkDeleteLogixEdges(
  options: UseBulkDeleteLogixEdgesOptions = {},
) {
  const queryClient = useQueryClient()
  const board = useBoardContext()
  const edgesQueryKey = getQueryKey(board.id)

  const mutation = useMutation({
    sideEffectQueryKeys: [['logix-board'], ['logix-edges']],
    mutationKey: ['delete-logix-edges'],
    mutationFn: (edgeIds: string[]) => bulkDeleteLogixEdges(edgeIds),
    onMutate: async (edgeIds) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: edgesQueryKey })

      const previous = queryClient.getQueryData(edgesQueryKey) as LogixEdge[]

      queryClient.setQueryData(
        edgesQueryKey,
        (currentEdges: LogixEdge[] = []) =>
          currentEdges.filter(({ id }) => !edgeIds.includes(id)),
      )

      return { previous }
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(edgesQueryKey, context?.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: edgesQueryKey })
    },
    ...options,
  })

  return { ...mutation, bulkDeleteLogixEdges: mutation.mutateAsync }
}
