import { Typography, Button } from '@mui/material'
import { ImporterEmail } from '@/types/importer-emails'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type DeleteImporterEmailDialogProps = {
  importerEmail: ImporterEmail
  overlay: OverlayState
  onDelete: () => void
}

export default function DeleteImporterEmailDialog({
  importerEmail,
  overlay,
  onDelete,
}: DeleteImporterEmailDialogProps) {
  return (
    <Dialog {...overlay} title="Delete Upload Email Address" maxWidth="xs">
      <DialogContent>
        <Typography>
          You are about to delete this email address. Are you sure?
        </Typography>

        <Typography sx={{ mt: 2 }}>
          <b>{importerEmail.email}</b>
        </Typography>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button onClick={onDelete} variant="contained" color="error">
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
