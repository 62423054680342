import { Box, List, Typography, useTheme } from '@mui/material'
import SettingsNavigationItem, {
  SettingsNavigationItemProps,
} from './SettingsNavigationItem'
import { useLocation } from 'react-router-dom'

type SettingsNavigationProps = {
  routes: SettingsNavigationItemProps[]
  label?: string
  subHeader?: string
}

export default function SettingsNavigation({
  routes,
  label,
  subHeader,
}: SettingsNavigationProps) {
  const theme = useTheme()
  const { pathname } = useLocation()
  const selectedRouteIndex = routes.findIndex((route) =>
    pathname.split('/').includes(route.to),
  )
  const markerTopPosition = Math.max(selectedRouteIndex * 40 + 4, 4)
  return (
    <Box sx={{ my: 3, mx: 4 }}>
      {label && (
        <>
          <Typography
            variant="caption"
            component="h5"
            color="text.secondary"
            sx={{ ml: 1.5 }}
          >
            {label}
          </Typography>
          {subHeader && (
            <Typography
              variant="caption"
              component="h6"
              color="primary.main"
              sx={{ ml: 1.5 }}
            >
              {subHeader}
            </Typography>
          )}
        </>
      )}
      <List
        disablePadding
        sx={{
          '&::before': {
            content: selectedRouteIndex > -1 ? '""' : undefined,
            height: 32,
            width: 4,
            background: theme.palette.primary.main,
            position: 'absolute',
            top: markerTopPosition,
            left: -8,
            borderRadius: 2,
            transition: 'top 0.2s ease-out',
          },
        }}
      >
        {routes.map((route) => {
          return <SettingsNavigationItem key={route.to} {...route} />
        })}
      </List>
    </Box>
  )
}
