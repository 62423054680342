import { ReactNode, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  FormControlLabel,
  Stack,
  IconButton,
  Checkbox,
} from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

type GridFieldsFilterProps = {
  checked: boolean
  children: ReactNode
  indeterminate: boolean
  label: ReactNode
  onChange: (checked: boolean) => void
}

export default function AccordionFilterGroup({
  checked,
  children,
  indeterminate,
  label,
  onChange,
}: GridFieldsFilterProps) {
  const [expanded, setExpanded] = useState(false)

  return (
    <Accordion
      disableGutters
      elevation={0} // Get rid of shadows
      expanded={expanded}
      sx={{
        background: 'transparent',
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded:first-of-type': {
          marginTop: '8px', // Fix spacing at top of first accordion when expanded
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              sx={{ padding: 0 }}
              color="neutral"
              checked={checked}
              indeterminate={indeterminate}
              onChange={({ target }) => {
                target.checked !== expanded && setExpanded(target.checked)
                onChange(target.checked)
              }}
            />
          }
          sx={{ mx: 0, columnGap: 0.5 }}
        />
        <IconButton
          onClick={() => setExpanded((prev) => !prev)}
          size="small"
          sx={{ p: 0 }}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Stack>
      <AccordionDetails sx={{ pb: 0.5 }}>
        <Stack sx={{ ml: 1 }} spacing={0.5}>
          {children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
