import { Dispatch, SetStateAction, useEffect } from 'react'
import { MRT_Updater, MRT_VisibilityState } from 'material-react-table'
import { Typography } from '@mui/material'
import { ProjectGrid } from '@/types/projects'
import { createDataLineageColumn } from '@/utils/field-utils'
import { useSelectedGridsContext } from '@/components/workflows/SelectedGridsProvider'
import FieldFilter from './FieldFilter'
import { OnFieldChange, OnGroupChange } from './AllFieldsFilter'
import AccordionFilterGroup from './AccordionFilterGroup'

type GridFieldsFilterProps = {
  grid: ProjectGrid
  fieldsChecked: number
  checked: boolean
  columnVisibility: MRT_VisibilityState
  indeterminate: boolean
  handleColumnVisibilityChange: (
    columnVisibilityUpdaterOrValue: MRT_Updater<MRT_VisibilityState>,
  ) => void
  onGridChange: OnGroupChange
  onFieldChange: OnFieldChange
  setColumnVisibility: Dispatch<SetStateAction<MRT_VisibilityState>>
}

export default function GridFieldsFilter({
  grid,
  fieldsChecked,
  checked,
  columnVisibility,
  indeterminate,
  handleColumnVisibilityChange,
  onGridChange,
  onFieldChange,
  setColumnVisibility,
}: GridFieldsFilterProps) {
  const { selectedGrids, setSelectedGridsIds } = useSelectedGridsContext()
  const fields = grid.project_grid_fields

  useEffect(() => {
    if (checked || indeterminate) {
      if (!Object.hasOwn(selectedGrids, grid.id)) {
        setSelectedGridsIds((prev) => [...prev, grid.id])
      }
    } else {
      if (Object.hasOwn(selectedGrids, grid.id))
        setSelectedGridsIds((prev) => prev.filter((id) => id !== grid.id))
    }
  }, [checked, grid, indeterminate, selectedGrids, setSelectedGridsIds])

  // If a new field gets added in another tab, we want it to be visible if its parent grid is selected
  useEffect(() => {
    fields.forEach((field) => {
      if (!Object.hasOwn(columnVisibility, field.id)) {
        setColumnVisibility((prev) => {
          const columns = createDataLineageColumn({
            fieldId: field.id,
            fieldValue: Object.hasOwn(selectedGrids, field.project_grid_id),
          })
          return {
            ...prev,
            ...columns,
          }
        })
      }
    })
  }, [columnVisibility, fields, selectedGrids, setColumnVisibility])

  return (
    <AccordionFilterGroup
      checked={checked}
      indeterminate={indeterminate}
      label={
        <Typography variant="body2">
          {grid.parent_project_grid_id === null
            ? 'Document'
            : grid.sub_project_grid_fields[0].name}
          {indeterminate ? ` (${fieldsChecked})` : ''}
        </Typography>
      }
      onChange={(checked) => {
        onGridChange({ fields, checked })
      }}
    >
      {fields.map((field) => (
        <FieldFilter
          key={field.id}
          fields={fields}
          field={field}
          onFieldChange={onFieldChange}
          columnVisibility={columnVisibility}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
        />
      ))}
    </AccordionFilterGroup>
  )
}
