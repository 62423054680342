import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { WorkflowStateEvent } from '@/types/workflow-state-events'

const service = buildService<WorkflowStateEvent>({
  pathCategory: 'workflow_state_events',
})

export function useGetWorkflowStateEvent({
  id,
  ...options
}: UseGetDetailOptions<WorkflowStateEvent>) {
  const query = useGetDetail<WorkflowStateEvent>({
    id,
    queryKey: queryKeys.workflowStateEvents.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    workflowStateEvent: query.data,
    ...query,
  }
}

export function useCreateWorkflowStateEvent(
  options: UseCreateDetailOptions<WorkflowStateEvent> = {},
) {
  const mutation = useCreateDetail<WorkflowStateEvent>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createWorkflowStateEvent: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateWorkflowStateEvent(
  options: UseUpdateDetailOptions<WorkflowStateEvent> = {},
) {
  const mutation = useUpdateDetail<WorkflowStateEvent>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateWorkflowStateEvent: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteWorkflowStateEvent(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<WorkflowStateEvent>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteWorkflowStateEvent: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetWorkflowStateEvents({
  filters,
  ...options
}: UseGetListOptions<WorkflowStateEvent> = {}) {
  const query = useGetList<WorkflowStateEvent>({
    filters,
    queryKey: queryKeys.workflowStateEvents.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    workflowStateEvents: query.allData,
    ...query,
  }
}

export function useUpdateWorkflowStateEvents(
  options: UseUpdateListOptions<WorkflowStateEvent> = {},
) {
  const mutation = useUpdateList<WorkflowStateEvent>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateWorkflowStateEvents: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteWorkflowStateEvents({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<WorkflowStateEvent>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteWorkflowStateEvents: mutation.mutateAsync,
    ...mutation,
  }
}
