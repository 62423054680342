import { FlagLevel } from '@/types/flags'
import { ButtonProps, useTheme } from '@mui/material'
import { pink } from '@mui/material/colors'

type UseFlagColorMapReturnType = Record<
  FlagLevel,
  { background: string; base: string; button: ButtonProps['color'] }
>
export default function useFlagColorMap(): UseFlagColorMapReturnType {
  const theme = useTheme()
  return {
    error: {
      background: '#311b17',
      base: theme.palette.error.main,
      button: 'error',
    },
    failure: { background: '#311b17', base: pink['A400'], button: 'pink' },
    warning: {
      background: '#322617',
      base: theme.palette.warning.main,
      button: 'warning',
    },
    info: {
      background: '#323232',
      base: theme.palette.text.primary,
      button: 'neutral',
    },
  }
}
