import { createContext, ReactNode, useContext, useEffect } from 'react'
import { Workflow } from '@/types/workflows'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import useStatefulSearchParam from '@/hooks/useStatefulSearchParam'

type SelectedWorkflowContextValue = {
  workflows: Workflow[]
  selectedWorkflow: Workflow
  setSelectedWorkflow: (newWorkflowId: string) => void
}

const SelectedWorkflowContext = createContext<SelectedWorkflowContextValue>(
  {} as SelectedWorkflowContextValue,
)

export const useSelectedWorkflowContext = () =>
  useContext(SelectedWorkflowContext)

type SelectedWorkflowProviderProps = {
  children: ReactNode
  defaultWorkflowId?: string
}

export default function SelectedWorkflowProvider({
  children,
}: SelectedWorkflowProviderProps) {
  const { workflows, productionDataWorkflow } = useWorkflowsContext()

  const { value: selectedWorkflowId, toggleValue: setSelectedWorkflowId } =
    useStatefulSearchParam({
      param: 'workflow',
      defaultValue: productionDataWorkflow?.id,
      fieldsToClearOnChange: ['workflow_state'],
    })

  const selectedWorkflow = workflows.find(({ id }) => id === selectedWorkflowId)

  // If the workflow is missing for some reason, set to default workflow.
  useEffect(() => {
    if (productionDataWorkflow && !selectedWorkflow) {
      setSelectedWorkflowId(productionDataWorkflow.id)
    }
  }, [
    productionDataWorkflow,
    selectedWorkflow,
    setSelectedWorkflowId,
    workflows,
  ])

  return (
    <SelectedWorkflowContext.Provider
      value={{
        workflows,
        selectedWorkflow: selectedWorkflow as Workflow,
        setSelectedWorkflow: setSelectedWorkflowId,
      }}
    >
      {workflows.length > 0 && children}
    </SelectedWorkflowContext.Provider>
  )
}
