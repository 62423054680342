import { Dispatch, SetStateAction } from 'react'
import { Typography, Button, Stack } from '@mui/material'
import { Warning } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { OverlayState } from '@/hooks/useOverlay'
import { useArchiveDocuments } from '@/service-library/hooks/documents'
import queryKeys from '@/service-library/query-keys'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type DeleteDocumentsDialogProps = {
  documentIds: string[]
  overlay: OverlayState
  refetch: () => void
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
}

export default function DeleteDocumentsDialog({
  documentIds,
  overlay,
  refetch,
  setSelectedRows,
}: DeleteDocumentsDialogProps) {
  const { archiveDocuments, isLoading, error } = useArchiveDocuments({
    sideEffectQueryKeys: [
      queryKeys.documents.lists(),
      queryKeys.documentRows.lists(),
    ],
    onSuccess: () => {
      overlay.close()
    },
    onSettled: () => {
      setSelectedRows({})
      refetch()
    },
  })

  return (
    <Dialog maxWidth="sm" {...overlay} title="Delete Documents">
      <DialogContent>
        {!!error && (
          <Stack direction="row" spacing={2} pb={2}>
            <Warning color="error" />
            <Typography color="error">{(error as Error).message}</Typography>
          </Stack>
        )}

        <Typography>
          Deleting documents will permanently remove them from the system.
          <br />
          Are you sure you want to proceed?
        </Typography>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={isLoading}
          onClick={() => {
            archiveDocuments(documentIds)
          }}
        >
          Delete
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
