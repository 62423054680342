import useForeignKeyDetailEndpoint from '@/components/project-settings/params-schema-property-fields/useForeignKeyDetailEndpoint'
import { Typography } from '@mui/material'

type ParamDisplayProps = {
  paramValue: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramSchema: any
}

export default function ParamDisplay({
  paramValue,
  paramSchema,
}: ParamDisplayProps) {
  const value = Array.isArray(paramValue)
    ? paramValue.map((v) => v.value).join(',')
    : paramValue

  const mustLoadData =
    !!paramSchema?.foreignKey && paramSchema?.format === 'uuid'

  const { data, isLoading } = useForeignKeyDetailEndpoint<{
    name: string
    code: string
  }>({
    url: paramSchema?.foreignKey?.listEndpoint,
    id: paramValue,
    enabled: mustLoadData,
  })

  const optionLabel = paramSchema?.options?.find(
    (option: { value: string }) => option.value === paramValue,
  )?.label

  return mustLoadData && isLoading ? null : (
    <Typography variant="body2">
      <b>{paramSchema?.display.label}:</b>{' '}
      {data?.name || data?.code || optionLabel || value}
    </Typography>
  )
}
