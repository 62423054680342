import { LogEntry } from '@/types/log-entries'
import queryKeys from '../query-keys'
import { UseCreateDetailOptions } from '../core-hooks/useCreateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import useMutation from '../core-hooks/useMutation'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import PaginatedResponse from '@/types/paginated-response'
import useBatchedRequest from '../core-hooks/useBatchedRequest'
import { createList } from '../request-wrappers'
import { sortBySortOrder } from '@/utils/field-utils'
import { UseUpdateListOptions } from '../core-hooks/useUpdateList'
import { notNull } from '@/utils/typescript-utils'
import { AxiosResponse } from 'axios'
import { BulkResponse } from '@/types/bulk-response'

const service = buildService<LogEntry>({
  pathCategory: 'log_entries',
  servicePath: '/v2/pd-activity/',
})

export function useCreateLogEntry({
  sideEffectQueryKeys,
  listQueryKey,
  filters,
  axiosOptions,
  onMutate,
  onError,
  onSettled,
  ...options
}: UseCreateDetailOptions<LogEntry> = {}) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    sideEffectQueryKeys,
    mutationFn: (item) => {
      return service
        .createDetail({
          item,
          filters,
          axiosOptions,
        })
        .then(({ data }) => data)
    },
    onMutate: async (item) => {
      let previous: InfiniteData<PaginatedResponse<LogEntry>> | undefined =
        undefined
      if (listQueryKey) {
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        previous = queryClient.getQueryData(listQueryKey)

        // Update the list data in cache if it exists
        queryClient.setQueryData<InfiniteData<PaginatedResponse<LogEntry>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            const pages = old?.pages.map((page, index) => {
              if (index !== old?.pages.length - 1) return page
              return {
                ...page,
                results: [...page.results, item],
              }
            })
            return {
              ...old,
              pages,
            }
          },
        )
      }
      // Run user supplied onMutate function if available
      onMutate?.(item)

      // Return the previous data in case we need to reset it in onError
      return { previous }
    },
    onError: (error, item, context) => {
      // Run user supplied onError function if available
      onError?.(error, item, context)

      if (!listQueryKey || !context?.previous) return
      // Reset
      queryClient.setQueryData(listQueryKey, context?.previous)
    },
    onSettled: (...args) => {
      onSettled?.(...args)

      if (listQueryKey)
        queryClient.invalidateQueries({ queryKey: listQueryKey })
    },
    ...options,
  })

  return {
    createLogEntry: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetLogEntries({
  filters,
  ...options
}: UseGetListOptions<LogEntry> = {}) {
  const query = useGetList<LogEntry>({
    filters,
    queryKey: queryKeys.logEntries.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    logEntries: query.allData,
    ...query,
  }
}

/**
 * MARK: Action Hook: Bulk Create Log Entries
 * Used to create multiple log entries at once.
 */
export function useBulkCreateLogEntries({
  sideEffectQueryKeys,
  useSortOrder,
  listQueryKey,
  filters,
  axiosOptions,
  onMutate,
  onError,
  onIdle,
  ...options
}: UseUpdateListOptions<LogEntry> = {}) {
  const queryClient = useQueryClient()

  const batchedRequest = useBatchedRequest<
    LogEntry,
    AxiosResponse<BulkResponse<LogEntry>>
  >({
    queryKey: listQueryKey,
    sideEffectQueryKeys,
    requestFn: (items) => {
      return createList({
        url: '/v2/pd-activity/log_entries/bulk-create',
        items,
        filters,
        axiosOptions,
      })
    },
    onIdle,
  })

  const mutation = useMutation({
    sideEffectQueryKeys,
    mutationFn: (items) =>
      batchedRequest(items).then((data) => {
        return data.data.map(({ result }) => result).filter(notNull)
      }),
    onMutate: async (items) => {
      let previous: InfiniteData<PaginatedResponse<LogEntry>> | undefined =
        undefined

      if (listQueryKey) {
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        previous = queryClient.getQueryData(listQueryKey)

        // Update the list data in cache if it exists
        queryClient.setQueryData<InfiniteData<PaginatedResponse<LogEntry>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            let newItems = [...items]

            // Replace existing items
            const pages = old?.pages.map((page) => {
              return {
                ...page,
                // This is very inefficient. Is there a way we can know which page it is in beforehand?
                // This doesn't work for creating items.
                results: page.results.map((storedItem) => {
                  const matchingItem = items.find(
                    (item) => item.id === storedItem.id,
                  )
                  if (matchingItem) {
                    newItems = newItems.filter(
                      (item) => item.id !== matchingItem.id,
                    )
                  }
                  return matchingItem || storedItem
                }),
              }
            })

            // We always put the new items in a new fake page so it will know to refetch the new page
            // if the items are actually in a new page. If they aren't actually in a new page, it will
            // still refetch appropriately. Trust us. We definitely know what we're doing.
            if (newItems.length > 0) {
              pages.push({
                results: newItems,
                next: null,
                previous: null,
                count: newItems.length,
              })
            }
            const newInfiniteResponse = {
              ...old,
              pages,
            }
            if (newItems.length > 0 || !useSortOrder) return newInfiniteResponse

            const newPages: PaginatedResponse<LogEntry>[] = []

            if (newInfiniteResponse.pages.length === 1) {
              newPages.push({
                next: null,
                previous: null,
                results: sortBySortOrder(newInfiniteResponse.pages[0].results),
              })
            } else {
              const allData = sortBySortOrder(
                newInfiniteResponse.pages?.reduce<LogEntry[]>(
                  (acc, page) => [...acc, ...page.results],
                  [],
                ),
              )

              const limit = newInfiniteResponse.pages[0].results.length
              for (let i = 0; i < allData.length; i += limit) {
                newPages.push({
                  next: null,
                  previous: null,
                  results: allData.slice(i, i + limit),
                })
              }
            }

            return {
              ...old,
              pages: newPages,
            }
          },
        )
      }
      // Run user supplied onMutate function if available
      onMutate?.(items)

      // Return the previous data in case we need to reset it in onError
      return { previous }
    },
    onError: (error, items, context) => {
      // Run user supplied onError function if available
      onError?.(error, items, context)

      if (!listQueryKey || !context?.previous) return
      // Reset
      queryClient.setQueryData(listQueryKey, context?.previous)
    },

    ...options,
  })

  return {
    createLogEntries: mutation.mutateAsync,
    ...mutation,
  }
}
