import { OverlayState } from '@/hooks/useOverlay'
import {
  useGetExportJobs,
  useUpdateExportJob,
} from '@/service-library/hooks/export-jobs'
import { Dialog, DialogContent } from '@/components/dialog'
import { Document } from '@/types/documents'
import ZerapixTable from '../zerapix-table/ZerapixTable'
import { ExportJob } from '@/types/export-jobs'
import useZerapixTable from '../zerapix-table/useZerapixTable'
import { useMemo } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { RemoveCircleOutline } from '@mui/icons-material'
import { MRT_ColumnDef } from 'material-react-table'

type ExportJobsDialogProps = {
  overlay: OverlayState
  document: Document
}

export default function ExportJobsDialog({
  overlay,
  document,
}: ExportJobsDialogProps) {
  const { exportJobs = [] } = useGetExportJobs({
    filters: {
      limit: '1000',
      document_id: document.id,
    },
    enabled: !!overlay.isOpen,
    refetchInterval: 2000,
  })

  const { updateExportJob } = useUpdateExportJob()

  function setExportJobToInvalid(exportJob: ExportJob) {
    updateExportJob({
      ...exportJob,
      state: 'invalid',
      status: 'Marked as invalid by a superuser.',
    })
  }

  const columns = useMemo<MRT_ColumnDef<ExportJob>[]>(() => {
    return [
      {
        header: 'ID',
        accessorKey: 'id',
        enableClickToCopy: true,
      },
      {
        header: 'State',
        accessorKey: 'state',
        Cell: ({ row }) => {
          let color = 'inherit'
          if (row.original.state === 'error') {
            color = 'red'
          } else if (row.original.state === 'completed') {
            color = 'green'
          }
          return (
            <span
              style={{
                color,
              }}
            >
              {row.original.state}
            </span>
          )
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
      },
      {
        header: 'Document ID',
        accessorKey: 'document_id',
        enableClickToCopy: true,
      },
      {
        header: 'Integration ID',
        accessorKey: 'integration_id',
        enableClickToCopy: true,
      },
      {
        header: 'Workflow State ID',
        accessorKey: 'workflow_state_id',
        enableClickToCopy: true,
      },
    ]
  }, [])

  const table = useZerapixTable<ExportJob>({
    columns,
    data: exportJobs,
    muiTablePaperProps: { elevation: 0 },
    enableColumnResizing: true,
    muiTableBodyRowProps: {
      sx: {
        minHeight: '40px',
      },
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box>
        <Tooltip title="Mark as invalid">
          <IconButton
            onClick={() => setExportJobToInvalid(row.original)}
            size="small"
          >
            <RemoveCircleOutline />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  return (
    <Dialog {...overlay} title="Export Jobs" maxWidth="xl">
      <DialogContent>
        <ZerapixTable<ExportJob> table={table} />
      </DialogContent>
    </Dialog>
  )
}
