import { useController, useFormContext } from 'react-hook-form'
import { TextField, MenuItem } from '@mui/material'
import { PropertyFieldProps } from './PropertyField'

type PropertySelectProps = PropertyFieldProps

export default function PropertySelect({
  propertyId,
  property,
  required,
  variant,
}: PropertySelectProps) {
  const { field } = useController({
    name: propertyId,
    rules: { required },
    defaultValue: 'error',
    shouldUnregister: true,
  })
  const { formState } = useFormContext()
  const { errors } = formState
  const { display } = property

  const options: {
    label: string
    value: string
  }[] = property.options

  return (
    <TextField
      required={required}
      error={!!errors[propertyId]}
      label={display?.label || 'MISSING LABEL'}
      InputLabelProps={{ shrink: true }}
      select
      helperText={display.description}
      variant={variant}
      {...field}
    >
      {options?.length > 0 ? (
        options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No options available.</MenuItem>
      )}
    </TextField>
  )
}
