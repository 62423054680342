import { XYPosition, Node } from 'reactflow'
import { NodeData } from '../types'

export default function getNodeFromPosition(
  nodes: Node<NodeData>[],
  position: XYPosition,
) {
  return nodes.findLast(
    (n) =>
      position.x > (n.positionAbsolute?.x || n.position.x) &&
      position.x < (n.positionAbsolute?.x || n.position.x) + (n.width || 0) &&
      position.y > (n.positionAbsolute?.y || n.position.y) &&
      position.y < (n.positionAbsolute?.y || n.position.y) + (n.height || 0),
  )
}
