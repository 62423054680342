import { ReactNode, forwardRef } from 'react'
import { FieldValues, useController, FieldPath } from 'react-hook-form'
import {
  FormControl,
  FormControlProps,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material'

type FormRadioGroupProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label?: ReactNode
  radioGroupProps?: RadioGroupProps
} & FormControlProps

const FormRadioGroup = forwardRef(function FormTextField<
  FormValues extends FieldValues,
>(
  {
    children,
    name,
    label,
    radioGroupProps,
    ...props
  }: FormRadioGroupProps<FormValues>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { field } = useController<FormValues>({ name })
  return (
    <FormControl {...props}>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup {...field} ref={ref} {...radioGroupProps}>
        {children}
      </RadioGroup>
    </FormControl>
  )
})

export default FormRadioGroup
