import {
  Typography,
  Button,
  Stack,
  List,
  ListItemButton,
  CircularProgress,
  Box,
  Alert,
  AlertTitle,
  ListItemIcon,
} from '@mui/material'
import { FolderZip, Warning } from '@mui/icons-material'
import { OverlayState } from '@/hooks/useOverlay'
import useExportFiles from '@/services/hooks/useExportFiles'
import downloadFile from '@/utils/download-file'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type DownloadExportDialogProps = {
  overlay: OverlayState
  exportFiles: ReturnType<typeof useExportFiles>
  isLoading: boolean
}

export default function DownloadExportDialog({
  overlay,
  exportFiles,
  isLoading,
}: DownloadExportDialogProps) {
  const { files = [], error } = exportFiles

  function handleDownload(fileUrl: string, fileName: string) {
    downloadFile(fileUrl, fileName).catch(() => {
      showErrorSnackbar('Failed to download document. Please try again later.')
    })
  }

  return (
    <Dialog {...overlay} maxWidth="sm" title="Download Export Files">
      <DialogContent>
        {!!error && (
          <Stack direction="row">
            <Warning color="error" />
            <Typography color="error">{(error as Error).message}</Typography>
          </Stack>
        )}
        {!isLoading && files.length === 0 && (
          <Stack spacing={2}>
            <Alert severity="info" variant="standard">
              <AlertTitle>No export files available for download.</AlertTitle>
              Files become available for download after successfully exporting
              documents to a valid file type (CSV, JSON, etc.).
            </Alert>
          </Stack>
        )}
        {isLoading ? (
          <Stack direction="row" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Box sx={{ overflowY: 'auto' }}>
            <List>
              {files.map((file) => {
                const dateString = new Date(
                  file.last_modified as string,
                ).toLocaleString()
                return (
                  <ListItemButton
                    key={file.file_name}
                    onClick={() =>
                      handleDownload(file.file_url, file.file_name)
                    }
                  >
                    <ListItemIcon>
                      <FolderZip />
                    </ListItemIcon>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: '100%' }}
                    >
                      <Typography>{file.file_name}</Typography>
                      <Typography>{dateString}</Typography>
                    </Stack>
                  </ListItemButton>
                )
              })}
            </List>
          </Box>
        )}
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
