import { DocumentFlag, RowValueFlag } from '@/types/flags'

export const getFlagsCount = (flags: (RowValueFlag | DocumentFlag)[]) => {
  return flags.reduce(
    (flagsCount, { flag_level }) => {
      if (flag_level in flagsCount) {
        flagsCount[flag_level]++
      }
      return flagsCount
    },
    { error: 0, warning: 0, info: 0, failure: 0 },
  )
}

export const getFlagsByLevel = (flags: (RowValueFlag | DocumentFlag)[]) => {
  return flags.reduce<{
    error: typeof flags
    warning: typeof flags
    info: typeof flags
    failure: typeof flags
  }>(
    (flagsByLevel, flag) => {
      if (flagsByLevel[flag.flag_level]) {
        flagsByLevel[flag.flag_level].push(flag)
      }
      return flagsByLevel
    },
    { error: [], warning: [], info: [], failure: [] },
  )
}

export const getFlagsCountFromLevel = (
  flagsByType: ReturnType<typeof getFlagsByLevel>,
) => {
  return {
    error: flagsByType.error.length,
    warning: flagsByType.warning.length,
    info: flagsByType.warning.length,
    failure: flagsByType.failure.length,
  }
}
