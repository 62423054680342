import {
  getDetail,
  createDetail,
  updateDetail,
  getList,
  updateList,
  deleteCall,
} from './request-wrappers'

import {
  RequestOptions,
  CreateDetailRequestOptions,
  UpdateDetailRequestOptions,
  UpdateListRequestOptions,
  DeleteRequestOptions,
} from './request-types'

export default function buildService<T extends { id: string }>({
  pathCategory,
  servicePath = '/v2/pd/',
}: {
  pathCategory: string
  servicePath?: string
}) {
  return {
    /** MARK: DETAIL FUNCTIONS */
    getDetail: ({ id, ...options }: RequestOptions & { id: string }) => {
      return getDetail<T>({
        url: `${servicePath}${pathCategory}/${id}`,
        ...options,
      })
    },
    createDetail: (options: CreateDetailRequestOptions<T>) => {
      return createDetail<T>({
        url: `${servicePath}${pathCategory}`,
        ...options,
      })
    },
    updateDetail: (options: UpdateDetailRequestOptions<T>) => {
      return updateDetail<T>({
        url: `${servicePath}${pathCategory}/${options.item.id}`,
        ...options,
      })
    },
    deleteDetail: ({
      id,
      ...options
    }: DeleteRequestOptions & { id: string }) => {
      return deleteCall({
        url: `${servicePath}${pathCategory}/${id}`,
        ...options,
      })
    },

    /** MARK: LIST FUNCTIONS */
    getList: (options: RequestOptions) => {
      return getList<T>({ url: `${servicePath}${pathCategory}`, ...options })
    },
    // createList doesn't exist because you can use updateList to create new items
    updateList: (options: UpdateListRequestOptions<T>) => {
      return updateList<T>({
        url: `${servicePath}${pathCategory}`,
        ...options,
      })
    },
    deleteList: ({
      ids,
      ...options
    }: DeleteRequestOptions & { ids: string[] }) => {
      return deleteCall({
        url: `${servicePath}${pathCategory}`,
        ...options,
        filters: {
          id__in: ids.join(','),
          ...options.filters,
        },
      })
    },
  }
}
