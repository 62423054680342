import { ReactNode, createContext, useContext } from 'react'
import { LogixBoard } from '@/types/logix'

const BoardContext = createContext<LogixBoard>({} as LogixBoard)

export const useBoardContext = () => useContext(BoardContext)

type BoardProviderProps = {
  children: ReactNode
  board: LogixBoard
}

export default function BoardProvider({ children, board }: BoardProviderProps) {
  return <BoardContext.Provider value={board}>{children}</BoardContext.Provider>
}
