import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { DataType } from '../../types'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { PixyDocsForm } from '@/components/forms'
import { dataTypeTooltipMap } from '../../handles/BaseHandle'
import typeColorMap from '../../helpers/typeColorMap'
import PropertiesTable from './PropertiesTable'
import generateUuid from '@/utils/generate-uuid'
import { LogixHandle } from '@/types/logix'

export type DataTypeOption = {
  id: DataType
  label: string
  color: string
}

export type Property = {
  id: string
  dataType: DataTypeOption
  label: string
  isArray: boolean
  properties?: Property[]
}

export type FormValues = { properties: Property[] }

type AddUserDialogProps = {
  inputs: LogixHandle[]
  overlay: OverlayState
  onSubmit: (properties: Property[]) => void
}

const nonSelectableTypes = ['execution', 'any']

export const dataTypeColorOptions = Object.entries(typeColorMap).reduce(
  (acc: DataTypeOption[], [primitiveType, color]) => {
    if (!nonSelectableTypes.includes(primitiveType))
      return [
        ...acc,
        {
          id: primitiveType as DataType,
          label: dataTypeTooltipMap[primitiveType as DataType],
          color,
        },
      ]
    return acc
  },
  [],
)

export const defaultOption = {
  dataType: dataTypeColorOptions[0],
  label: '',
  isArray: false,
  properties: undefined,
}

const convertInputsInFormValue = (inputs: LogixHandle[]): Property[] => {
  return inputs.map(({ id, data_type_code, name, is_list }) => {
    const childrenInputs = inputs.filter(
      (input) => input.parent_obj_handle_id === id,
    )
    return {
      id,
      dataType: {
        id: data_type_code,
        label: dataTypeTooltipMap[data_type_code],
        color: typeColorMap[data_type_code],
      },
      label: name || '',
      isArray: !!is_list,
      properties:
        childrenInputs.length > 0
          ? convertInputsInFormValue(childrenInputs)
          : undefined,
    }
  })
}

export default function AddEditObjectNodeDialog({
  inputs,
  overlay,
  onSubmit,
}: AddUserDialogProps) {
  const properties = useMemo(() => {
    if (inputs.length > 0) return convertInputsInFormValue(inputs)
    return [{ ...defaultOption, id: generateUuid() }]
  }, [inputs])

  const methods = useForm<FormValues>({
    defaultValues: {
      properties,
    },
  })

  const {
    formState: { isDirty, isValid },
    reset,
  } = methods

  const handleOnSubmit = ({ properties }: FormValues) => {
    onSubmit(properties)
    overlay.close()
  }

  useEffect(() => {
    reset({ properties })
  }, [overlay.isOpen, properties, reset])

  return (
    <Dialog maxWidth="md" {...overlay} title="Object Properties">
      <DialogContent disablePadding>
        <PixyDocsForm
          id="object-node-form"
          methods={methods}
          onSubmit={handleOnSubmit}
        >
          <PropertiesTable name="properties" />
        </PixyDocsForm>
      </DialogContent>
      <DialogFooter>
        <Button
          variant="text"
          onClick={() => {
            overlay.close()
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={!isDirty || !isValid}
          variant="contained"
          type="submit"
          form="object-node-form"
        >
          Save
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
