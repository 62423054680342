import { createContext, ReactNode, useContext, useEffect } from 'react'
import { Organization } from '@/types/organizations'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useGetOrganizations } from '@/service-library/hooks/organizations'

type RecentOrganizationsContextValue = ReturnType<
  typeof useLocalStorage<Organization[]>
>

const RecentOrganizationsContext =
  createContext<RecentOrganizationsContextValue>(
    {} as RecentOrganizationsContextValue,
  )

export const useRecentOrganizationsContext = () =>
  useContext(RecentOrganizationsContext)

type RecentOrganizationsProviderProps = {
  children: ReactNode
}

export default function RecentOrganizationsProvider({
  children,
}: RecentOrganizationsProviderProps) {
  const { rootOrganization } = useRootOrganization()
  const [recentOrgs, setRecentOrgs] = useLocalStorage<Organization[]>(
    `recent-upload-orgs-${rootOrganization.id}`,
    [],
  )

  const { organizations, isLoading } = useGetOrganizations({
    filters: {
      id__in: recentOrgs.map(({ id }) => id).join(','),
      limit: '5',
    },
    enabled: recentOrgs.length > 0,
    staleTime: 60 * 60 * 1000, // Only consider this data stale after an hour or when the query key changes (which changes when recent orgs are updated)
  })

  useEffect(() => {
    // If an organization gets deleted, we want to remove it from the recent orgs
    if (!isLoading && organizations.length !== recentOrgs.length) {
      setRecentOrgs(organizations)
    }
  }, [
    isLoading,
    organizations,
    organizations.length,
    recentOrgs.length,
    setRecentOrgs,
  ])

  return (
    <RecentOrganizationsContext.Provider value={[recentOrgs, setRecentOrgs]}>
      {children}
    </RecentOrganizationsContext.Provider>
  )
}
