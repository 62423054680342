import { useState } from 'react'
import { Slider, SliderProps, Typography, FormHelperText } from '@mui/material'
import { FieldValues, useController, FieldPath } from 'react-hook-form'

type FormSliderProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label: string | ((value: number | number[]) => string)
  helperText?: string
} & SliderProps

export default function FormSlider<FormValues extends FieldValues>({
  name,
  label,
  helperText,
  ...props
}: FormSliderProps<FormValues>) {
  const { field } = useController<FormValues>({ name })
  const [internalValue, setInternalValue] = useState<number | number[]>(
    field.value,
  )

  return (
    <div>
      <Typography variant="body2" sx={{ pb: 1 }}>
        {typeof label === 'string' ? label : label(field.value)}
      </Typography>
      <Slider
        data-testid="slider"
        valueLabelDisplay="auto"
        {...props}
        {...field}
        value={internalValue}
        onChange={(event, value) => setInternalValue(value)}
        onChangeCommitted={(event, value) => field.onChange(value)}
        sx={{ my: -1 }} // Tighten up surrounding text
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  )
}
