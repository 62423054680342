import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import useMutation from './useMutation'
import { LogixBoard, LogixHandle } from '@/types/logix'
import { updateLogixHandle } from '../logix'
import { getQueryKey } from './useLogixBoard'
import { useBoardContext } from '@/components/flow-editor/BoardProvider'

type UseUpdateLogixHandleOptions = UseMutationOptions<
  LogixHandle,
  unknown,
  LogixHandle,
  { previous: LogixBoard }
>

export default function useUpdateLogixHandle(
  options: UseUpdateLogixHandleOptions = {},
) {
  const board = useBoardContext()
  const queryClient = useQueryClient()

  // TODO: Optimistic updates for edges - can't do until we update how we get edges from backend
  // function updateLogixNodesInCache(
  //   queryKey: QueryKey,
  //   updatedNodes: LogixNodeUpdate[],
  // ) {
  //   const updatedNodeIds = updatedNodes.map((node) => node.id)

  //   queryClient.cancelQueries({ queryKey })
  //   queryClient.setQueryData(queryKey, (currentBoard?: LogixBoard) => {
  //     if (!currentBoard) return
  //     const currentNodes = currentBoard.nodes

  //     const newNodesList = currentNodes.map((node) => {
  //       if (updatedNodeIds.includes(node.id)) {
  //         return {
  //           ...node,
  //           ...updatedNodes.find((updatedNode) => updatedNode.id === node.id),
  //         }
  //       }
  //       return node
  //     })

  //     return {
  //       ...currentBoard,
  //       nodes: newNodesList,
  //     } as LogixBoard
  //   })
  // }

  const query = useMutation<
    LogixHandle,
    unknown,
    LogixHandle,
    { previous: LogixBoard }
  >({
    sideEffectQueryKeys: [['logix-board']],
    mutationFn: (updatedHandle: LogixHandle) =>
      updateLogixHandle(updatedHandle).then((res) => res.data),
    onMutate: async () => {
      const queryKey = getQueryKey(board.id)

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey) as LogixBoard
      // updateLogixNodesInCache(queryKey, [updatedNode])

      return { previous }
    },
    onError: (err, data, context) => {
      const queryKey = getQueryKey(board.id)
      queryClient.setQueryData(queryKey, context?.previous)
    },
    onSettled: () => {
      const queryKey = getQueryKey(board.id)
      queryClient.invalidateQueries({ queryKey })
    },
    ...options,
  })

  return { ...query, updateLogixHandle: query.mutate }
}
