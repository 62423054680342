import { ProjectModelVersionField } from '@/types/project-models'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<ProjectModelVersionField>({
  pathCategory: 'project_model_version_fields',
})

export function useGetProjectModelVersionField({
  id,
  ...options
}: UseGetDetailOptions<ProjectModelVersionField>) {
  const query = useGetDetail<ProjectModelVersionField>({
    id,
    queryKey: queryKeys.projectModelVersionFields.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectModelVersionField: query.data,
    ...query,
  }
}

export function useGetProjectModelVersionFields({
  filters,
  ...options
}: UseGetListOptions<ProjectModelVersionField> = {}) {
  const query = useGetList<ProjectModelVersionField>({
    filters,
    queryKey: queryKeys.projectModelVersionFields.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectModelVersionFields: query.allData,
    ...query,
  }
}
