import { Typography } from '@mui/material'
import DeleteConfirmDialog from '@/components/delete-confirm-dialog/DeleteConfirmDialog'
import { OverlayState } from '@/hooks/useOverlay'
import { ProjectGridField } from '@/types/fields'

type DeleteFieldDialogProps = {
  overlay: OverlayState
  name: ProjectGridField['name']
  onDelete: () => void
}

export default function DeleteFieldDialog({
  name,
  onDelete,
  overlay,
}: DeleteFieldDialogProps) {
  return (
    <DeleteConfirmDialog
      overlay={overlay}
      title="Delete Field"
      onDelete={onDelete}
      textToMatch={name}
    >
      <Typography>
        Deleting a field with associated data will remove that data from the
        current project.
      </Typography>
    </DeleteConfirmDialog>
  )
}
