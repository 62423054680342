import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

type PageTitleProps = {
  children: ReactNode
}

export default function PageTitle({ children }: PageTitleProps) {
  return (
    <Helmet>
      <title>{children}</title>
    </Helmet>
  )
}
