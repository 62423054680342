import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import useMutation from './useMutation'
import { bulkDeleteLogixNodes } from '../logix'
import { getQueryKey } from './useLogixBoard'
import { LogixBoard } from '@/types/logix'

type UseBulkDeleteLogixNodesOptions = UseMutationOptions<
  unknown,
  unknown,
  string[],
  { previous: LogixBoard }
> & {
  boardId: string
}

export default function useBulkDeleteLogixNodes({
  boardId,
  ...options
}: UseBulkDeleteLogixNodesOptions) {
  const queryClient = useQueryClient()

  const boardQueryKey = getQueryKey(boardId)

  const mutation = useMutation({
    sideEffectQueryKeys: [['logix-board']],
    mutationKey: ['delete-logix-nodes'],
    mutationFn: (nodeIds: string[]) => bulkDeleteLogixNodes(nodeIds),
    onMutate: async (nodeIds) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: boardQueryKey })

      const previous = queryClient.getQueryData(boardQueryKey) as LogixBoard
      queryClient.setQueryData(boardQueryKey, (currentBoard?: LogixBoard) => {
        if (!currentBoard) return
        return {
          ...currentBoard,
          nodes: currentBoard.nodes.filter(
            (node) => !nodeIds.includes(node.id),
          ),
        } as LogixBoard
      })

      return { previous }
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(boardQueryKey, context?.previous)
    },
    onSettled: () => {
      const queryKey = getQueryKey(boardId)
      queryClient.invalidateQueries({ queryKey })
    },
    ...options,
  })

  return { ...mutation, bulkDeleteLogixNodes: mutation.mutateAsync }
}
