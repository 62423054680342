import BasePhoneNumberTextField from '@/components/inputs/PhoneNumberTextField'
import { TextFieldProps } from '@mui/material'

export type PhoneNumberTextFieldProps = {
  value: string
  onChange?: (newValue: string | null) => void
} & Omit<TextFieldProps, 'onChange' | 'value'>

export default function PhoneNumberTextField({
  value,
  onChange,
  ...rest
}: PhoneNumberTextFieldProps) {
  return (
    <BasePhoneNumberTextField
      autoComplete="off"
      value={value}
      onChange={onChange}
      {...rest}
    />
  )
}
