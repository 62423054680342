import { AxiosResponse, AxiosRequestConfig } from 'axios'
import authAxios from './auth-v2-axios'
import axios from './v2-axios'

export type ExportFile = {
  file_name: string
  file_url: string
  last_modified: string | null
  processing: boolean
}

type ExportFilesResponse = {
  results: ExportFile[]
}

type BaseExportBody = {
  project_id?: string
  project_grid_field_ids?: string[] | null
  workflow_state_id?: string
  project_mapping_id?: string
}

export type ExportDocumentsBody = BaseExportBody & {
  group_by_org: boolean
  workflow_id: string
  full_data?: boolean
  document_ids?: string[]
  flask_query?: string
}

export type ExportOriginalDocumentsBody = BaseExportBody & {
  document_ids: string[]
  document_type_to_add?: 'csv' | 'json'
}

export function getExportFiles(projectId: string) {
  return authAxios.get<ExportFilesResponse>(
    `/v2/pd/export/list?project_id=${projectId}`,
  )
}

export function exportDocs(
  exportType: string,
  body: ExportDocumentsBody,
  config: AxiosRequestConfig,
): Promise<AxiosResponse<ExportFile>> {
  return axios.post(`/v2/pd/export/${exportType}`, body, config)
}

export function exportOriginalDocuments(body: ExportOriginalDocumentsBody) {
  return authAxios.post(`/v2/pd/export/original`, body)
}
