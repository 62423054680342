import { forwardRef } from 'react'
import { FieldValues, useController, FieldPath } from 'react-hook-form'
import IntegerField, { IntegerFieldProps } from '../inputs/IntegerField'

type FormIntegerFieldProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label: string
} & IntegerFieldProps

const FormIntegerField = forwardRef(function FormIntegerField<
  FormValues extends FieldValues,
>(
  { name, required, ...props }: FormIntegerFieldProps<FormValues>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { field } = useController<FormValues>({
    name,
    rules: {
      required,
    },
  })

  return <IntegerField {...props} {...field} inputRef={ref} />
})

export default FormIntegerField
