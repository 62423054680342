import { AxiosError } from 'axios'
import {
  useQuery as useQueryHook,
  UseQueryOptions as UseQueryBaseOptions,
  QueryKey,
} from '@tanstack/react-query'
import { useAuthentication } from '@/components/auth/AuthProvider'

type UseQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryBaseOptions<TQueryFnData, TError, TData, TQueryKey>

export default function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  onError,
  ...options
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  const { getFreshIdToken } = useAuthentication()
  const query = useQueryHook<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    onError: (error) => {
      if ((error as AxiosError)?.response?.status === 401) {
        getFreshIdToken()?.catch(() => {})
      }
      onError?.(error)
    },
  })

  return query
}
