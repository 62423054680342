import { useGetOrgTypes } from '@/service-library/hooks/org-types'
import { Organization } from '@/types/organizations'
import { getOrganizationType } from '@/utils/organization'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'

type UseOrganizationTypeNameOptions = {
  organization?: Organization
}

export default function useOrganizationTypeName({
  organization,
}: UseOrganizationTypeNameOptions) {
  const { rootOrganization } = useRootOrganization()

  const { orgTypes } = useGetOrgTypes({
    filters: {
      limit: '1000',
      owner_org_id: rootOrganization?.id,
    },
    enabled: !!organization,
  })
  return organization
    ? getOrganizationType(organization, orgTypes)?.name || 'Organization'
    : 'Organization'
}
