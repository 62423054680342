import { Dispatch, memo, SetStateAction, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Divider } from '@mui/material'
import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { OverlayState } from '@/hooks/useOverlay'
import queryKeys from '@/service-library/query-keys'
import useGetValidationDocument from '@/services/hooks/useGetValidationDocument'
import useGetDocumentWorkflowAndState from '@/hooks/useGetDocumentWorkflowAndState'
import useStatefulSearchParam from '@/hooks/useStatefulSearchParam'
import { Dialog } from '@/components/dialog'
import DrawerPortalProvider from '@/components/drawer/DrawerPortalProvider'
import DocumentViewProvider, {
  useDocumentCurrentWorkflowViewContext,
} from '@/components/image-zoom-pan/providers/DocumentCurrentWorkflowViewProvider'
import DocumentProvider from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import ComparisonView from '@/components/validation/ComparisonView'
import Validation from '@/components/validation/Validation'
import SelectedFieldProvider from '@/components/validation/providers/SelectedFieldProvider'
import DocumentChipsProvider from '@/components/validation/providers/DocumentChipsProvider'
import DocumentRowValuesProvider from '@/components/validation/providers/DocumentRowValuesProvider'
import FieldErrorProvider from '@/components/validation/providers/FieldErrorProvider'
import { useSelectedWorkflowStateContext } from '@/components/workflows/SelectedWorkflowStateProvider'
import ValidationOverlayHeader from './ValidationOverlayHeader'

const hotKeyConfig = {
  preventDefault: true,
  enableOnFormTags: true,
}

type CommonProps = {
  hasPreviousDocument: boolean
  hasNextDocument: boolean
  selectedIndex: number
  goToPreviousDocument: () => void
  goToNextDocument: () => void
  refetchTableData: (options?: {
    throwOnError: boolean
    cancelRefetch: boolean
  }) => Promise<UseQueryResult>
  setUseOnlySelected: Dispatch<SetStateAction<boolean>>
  documentsCount?: number
}

type ValidationOverlayProps = CommonProps & {
  overlay: OverlayState
  documentIdState: ReturnType<typeof useStatefulSearchParam>
  nextDocumentId?: string
}

type ValidationOverlayContentProps = CommonProps & {
  query: ReturnType<typeof useGetValidationDocument>
  overlay: OverlayState
  documentIsInFlight: boolean
  resetValue: (replace?: boolean) => void
}

function ValidationOverlayContent({
  query,
  overlay,
  documentsCount,
  documentIsInFlight,
  hasPreviousDocument,
  hasNextDocument,
  selectedIndex,
  goToPreviousDocument,
  goToNextDocument,
  refetchTableData,
  resetValue,
  setUseOnlySelected,
}: ValidationOverlayContentProps) {
  const { documentCurrentWorkflowView } =
    useDocumentCurrentWorkflowViewContext()

  useHotkeys(
    'alt+1',
    () => {
      if (!documentIsInFlight) {
        goToPreviousDocument()
      }
    },
    {
      ...hotKeyConfig,
      enabled: overlay.isOpen,
    },
    [documentIsInFlight, goToPreviousDocument],
  )

  useHotkeys(
    'alt+2',
    () => {
      if (!documentIsInFlight) {
        goToNextDocument()
      }
    },
    {
      ...hotKeyConfig,
      enabled: overlay.isOpen,
    },
    [documentIsInFlight, goToNextDocument],
  )

  return (
    <>
      <DocumentProvider
        query={query}
        isDisabled={documentCurrentWorkflowView === 'comparison'}
      >
        <DocumentRowValuesProvider>
          <FieldErrorProvider>
            <DocumentChipsProvider>
              {/* This helps preventing the field panel from showing up outside the overlay.*/}
              <DrawerPortalProvider>
                <SelectedFieldProvider>
                  <ValidationOverlayHeader
                    documentView={documentCurrentWorkflowView}
                    documentQuery={query}
                    onClose={() => {
                      overlay.close()
                      // clear doc param when closing overlay
                      resetValue(false)
                      refetchTableData()
                      setUseOnlySelected(false)
                    }}
                    hasPreviousDocument={
                      !documentIsInFlight && hasPreviousDocument
                    }
                    hasNextDocument={!documentIsInFlight && hasNextDocument}
                    selectedIndex={selectedIndex}
                    documentsCount={
                      documentIsInFlight ? undefined : documentsCount
                    }
                    goToPreviousDocument={goToPreviousDocument}
                    goToNextDocument={goToNextDocument}
                  />
                  <Divider />
                  {documentCurrentWorkflowView === 'validation' && (
                    <Validation documentQuery={query} />
                  )}
                  {documentCurrentWorkflowView === 'comparison' && (
                    <ComparisonView
                      documentQuery={query}
                      key={query.document?.id} // This is to make it so it rerender when the document changes
                    />
                  )}
                </SelectedFieldProvider>
              </DrawerPortalProvider>
            </DocumentChipsProvider>
          </FieldErrorProvider>
        </DocumentRowValuesProvider>
      </DocumentProvider>
    </>
  )
}

const ValidationOverlay = ({
  overlay,
  documentIdState,
  refetchTableData,
  setUseOnlySelected,
  nextDocumentId,
  ...props
}: ValidationOverlayProps) => {
  const { project } = useProjectContext()
  const navigate = useNavigate()

  const { selectedWorkflowState } = useSelectedWorkflowStateContext()

  const { value: documentId, resetValue } = documentIdState

  const query = useGetValidationDocument({
    id: documentId || '',
  })
  const { project_id, workflow_states_ids } = query.document || {}

  const needToUpdateUrl = useMemo(
    () =>
      !!query.document?.id &&
      (project_id !== project.id ||
        !workflow_states_ids?.includes(selectedWorkflowState?.id)),
    [
      query.document?.id,
      project_id,
      project.id,
      workflow_states_ids,
      selectedWorkflowState?.id,
    ],
  )

  const { workflowId, workflowStateId } = useGetDocumentWorkflowAndState({
    document: query.document,
    enabled: needToUpdateUrl,
    useCurrentProject: project_id === project.id,
  })

  const documentIsInFlight = query.document
    ? !!query.document.document_workflow_states?.find(
        ({ workflow_state_id }) =>
          workflow_state_id === selectedWorkflowState.id,
      )?.processing_started_at
    : true

  // This pre-fetches the next document and puts it into cache so it'll load immediately when the user switches to it
  const { document: nextDocument } = useGetValidationDocument({
    id: nextDocumentId || '',
    enabled: !documentIsInFlight && !!nextDocumentId,
  })

  const queryClient = useQueryClient()
  useNotifications({
    keys: [
      'pdWorkflow/events.event_complete',
      'pdWorkflow/events.event_failed',
    ],
    callback: ({ updated_entity_ids }) => {
      if (documentId && updated_entity_ids.includes(documentId)) {
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documents.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentRows.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentRowValues.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentChips.all },
          { cancelRefetch: false },
        )
      }
    },
  })

  // This pre-fetches the next document's image so it will load from cache
  useEffect(() => {
    if (!nextDocument || !overlay.isOpen) return
    const img = new Image()
    img.src = nextDocument?.document_pages?.[0]?.image_urls.full
  }, [nextDocument, overlay.isOpen])

  useEffect(() => {
    if (!overlay.isOpen && documentId) {
      overlay.open()
    }
  }, [documentId, overlay, overlay.isOpen, resetValue])

  // If the project ID or the workflow state of document doesn't match the ones in the url, update the url.
  useEffect(() => {
    if (needToUpdateUrl && workflowId && workflowStateId) {
      navigate(
        `/projects/${project_id}/documents?workflow=${workflowId}&workflow_state=${workflowStateId}&document_id=${documentId}`,
        {
          replace: true,
        },
      )
    }
  }, [
    documentId,
    navigate,
    needToUpdateUrl,
    project.id,
    project_id,
    query.document,
    selectedWorkflowState.id,
    workflowId,
    workflowStateId,
    workflow_states_ids,
  ])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DocumentViewProvider document={query.document}>
        <Dialog
          {...overlay}
          fullScreen
          onClose={() => {
            // clear doc param when closing overlay
            resetValue(false)
            refetchTableData()
            setUseOnlySelected(false)
          }}
        >
          <ValidationOverlayContent
            overlay={overlay}
            query={query}
            documentIsInFlight={documentIsInFlight}
            refetchTableData={refetchTableData}
            resetValue={resetValue}
            setUseOnlySelected={setUseOnlySelected}
            {...props}
          />
        </Dialog>
      </DocumentViewProvider>
    </LocalizationProvider>
  )
}

export default memo(ValidationOverlay)
