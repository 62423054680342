import { useMemo, useRef } from 'react'
import debouncePromise from '@/utils/debounce-promise'

type UseDebouncePromiseOptions<FnType> = {
  fn: FnType
  interval?: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useDebouncePromise<T extends (...args: any[]) => any>({
  fn,
  interval = 500,
}: UseDebouncePromiseOptions<T>) {
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const debouncedFn = useMemo(() => {
    return debouncePromise({
      fn,
      interval,
      timerRef,
    })
  }, [fn, interval])
  return debouncedFn
}
