import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { ProjectTag } from '@/types/project-tags'

const service = buildService<ProjectTag>({
  pathCategory: 'project_tags',
})

export function useGetProjectTag({
  id,
  ...options
}: UseGetDetailOptions<ProjectTag>) {
  const query = useGetDetail<ProjectTag>({
    id,
    queryKey: queryKeys.projectTags.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectTag: query.data,
    ...query,
  }
}

export function useCreateProjectTag(
  options: UseCreateDetailOptions<ProjectTag> = {},
) {
  const mutation = useCreateDetail<ProjectTag>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectTag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectTag(
  options: UseUpdateDetailOptions<ProjectTag> = {},
) {
  const mutation = useUpdateDetail<ProjectTag>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectTag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectTag(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<ProjectTag>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectTag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectTags({
  filters,
  ...options
}: UseGetListOptions<ProjectTag> = {}) {
  const query = useGetList<ProjectTag>({
    filters,
    queryKey: queryKeys.projectTags.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectTags: query.allData,
    ...query,
  }
}

export function useUpdateProjectTags(
  options: UseUpdateListOptions<ProjectTag> = {},
) {
  const mutation = useUpdateList<ProjectTag>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectTags: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectTags({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectTag>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectTags: mutation.mutateAsync,
    ...mutation,
  }
}
