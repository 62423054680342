import { ProjectModelVersionCategory } from '@/types/project-models'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<ProjectModelVersionCategory>({
  pathCategory: 'project_model_version_categories',
})

export function useGetProjectModelVersionCategory({
  id,
  ...options
}: UseGetDetailOptions<ProjectModelVersionCategory>) {
  const query = useGetDetail<ProjectModelVersionCategory>({
    id,
    queryKey: queryKeys.projectModelVersionCategories.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectModelVersionCategory: query.data,
    ...query,
  }
}

export function useGetProjectModelVersionCategories({
  filters,
  ...options
}: UseGetListOptions<ProjectModelVersionCategory> = {}) {
  const query = useGetList<ProjectModelVersionCategory>({
    filters,
    queryKey: queryKeys.projectModelVersionCategories.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectModelVersionCategories: query.allData,
    ...query,
  }
}
