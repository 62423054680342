import { Button } from '@mui/material'
import LabeledProgressIndicator from '@/components/labeled-progress-indicator/LabeledProgressIndicator'
import ExportingDocumentsDialog from './ExportingDocumentsDialog'
import useOverlay from '@/hooks/useOverlay'
import { useDocumentCount } from '../document-count-provider/DocumentCountProvider'

export default function ExportingIndicator() {
  const overlay = useOverlay()
  const { exportingCount } = useDocumentCount()

  return (
    <>
      {exportingCount > 0 && (
        <LabeledProgressIndicator
          color="primary"
          component={Button}
          componentProps={{
            onClick: overlay.open,
            sx: { textTransform: 'None' },
            variant: 'text',
          }}
        >
          {exportingCount} Document{exportingCount > 1 ? 's' : ''} Exporting
        </LabeledProgressIndicator>
      )}
      <ExportingDocumentsDialog overlay={overlay} />
    </>
  )
}
