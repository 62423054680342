import { ReactNode } from 'react'
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import {
  FieldValues,
  useController,
  FieldPath,
  useFormContext,
} from 'react-hook-form'

type AutocompletePropsType<OptionType> = AutocompleteProps<
  OptionType,
  true | false, // multiple
  true, // disable clearable
  false, // free solo - unused by us
  'div' // chip component - unused by us
>

type BaseOptionType = {
  id: string
}

type FormAutocompleteProps<FormValues extends FieldValues, OptionType> = Omit<
  AutocompletePropsType<OptionType>,
  'renderInput' | 'onChange'
> & {
  name: FieldPath<FormValues>
  label: string
  autoFocus?: boolean
  helperText?: ReactNode
  required?: boolean
  textFieldProps?: Omit<TextFieldProps, 'required'>
  onChange?: (newValue: OptionType | OptionType[] | null) => void
}

export default function FormAutocomplete<
  FormValues extends FieldValues,
  OptionType extends BaseOptionType,
>({
  name,
  label,
  autoFocus = false,
  helperText,
  options,
  required,
  onChange,
  textFieldProps,
  ...props
}: FormAutocompleteProps<FormValues, OptionType>) {
  const { field } = useController<FormValues>({ name, rules: { required } })
  const { setValue } = useFormContext()
  const value = field.value

  return (
    <Autocomplete
      {...props}
      {...field}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          autoFocus={autoFocus}
          helperText={helperText}
          {...textFieldProps}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...textFieldProps?.InputLabelProps,
          }}
          InputProps={{ ...params.InputProps, ...textFieldProps?.InputProps }}
          inputProps={{
            ...params.inputProps,
            ...textFieldProps?.inputProps,
            required: required
              ? Array.isArray(value)
                ? value.length === 0
                : required
              : false, // workaround to allow submitting form https://github.com/mui/material-ui/issues/21663#issuecomment-732298594
          }}
        />
      )}
      onChange={(e, newValue) => {
        // @ts-expect-error -- I have no idea how to fix this typescript error.
        setValue(name, newValue, { shouldDirty: true })
        onChange?.(newValue)
      }}
    />
  )
}
