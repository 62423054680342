import { TextField } from '@mui/material'
import { HandleStructure } from '../types'
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { LogixHandle } from '@/types/logix'

type HandleDateTimeInputProps = {
  logixHandle: LogixHandle
  updateHandleManualValue: (updatedValue: LogixHandle['manual_value']) => void
}

export default function HandleDateTimeInput({
  logixHandle,
  updateHandleManualValue,
}: HandleDateTimeInputProps) {
  function onChange(newValue: Dayjs | null) {
    updateHandleManualValue(
      newValue ? newValue.format('YYYY-MM-DDTHH:mm:ss') : null,
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker<Dayjs>
        value={
          logixHandle.manual_value
            ? dayjs(logixHandle.manual_value as string)
            : null
        }
        onChange={onChange}
        OpenPickerButtonProps={{
          sx: {
            p: 0.25,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            color="neutral"
            sx={{ width: 175 }}
            inputProps={{
              sx: {
                px: 1,
                py: 0.5,
                fontSize: 12,
              },
              ...params.inputProps,
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
