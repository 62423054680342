import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { Stack, TextField, Container } from '@mui/material'
import StepActions from './StepActions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ProjectCategory, Project } from '@/types/projects'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import ProjectCategoryAutocomplete from '@/components/projects/ProjectCategoryAutocomplete'
import {
  useCreateProjectWithDefaults,
  useUpdateProject,
} from '@/service-library/hooks/projects'
import generateUuid from '@/utils/generate-uuid'
import queryKeys from '@/service-library/query-keys'

type SetupStepProps = {
  setProject: Dispatch<SetStateAction<Project>>
  project: Project
  projects: Project[]
}

export default function SetupStep({
  project,
  projects,
  setProject,
}: SetupStepProps) {
  const navigate = useNavigate()
  const { rootOrganization } = useRootOrganization()

  const [nameError, setNameError] = useState<string>()

  const [searchParams] = useSearchParams()

  const { createProject, isLoading: isCreatingProject } =
    useCreateProjectWithDefaults({
      sideEffectQueryKeys: [queryKeys.projects.lists()],
      filters: {
        fields__include: 'project_category',
      },
      onSuccess: (createdProject) => {
        setProject(createdProject)
        searchParams.append('project_id', createdProject.id)
        navigate(`../field_config?${searchParams.toString()}`)
      },
    })

  const { updateProject, isLoading: isUpdatingProject } = useUpdateProject({
    onSuccess: () => {
      navigate(`../field_config?${searchParams.toString()}`)
    },
  })

  const handleNext = () => {
    if (project.id) {
      updateProject(project)
    } else {
      createProject({
        id: generateUuid(),
        name: project.name,
        color: project.color,
        setup_state: 'field_config',
        org_id: rootOrganization.id,
        processing_active: false,
        project_category_id: project.project_category_id,
        source_folder_url: '',
      })
    }
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nameIsNotAvailable = projects.some(
      ({ id, name }) =>
        name.toLowerCase() === event.target.value.toLowerCase().trim() &&
        id !== project.id,
    )
    if (nameIsNotAvailable) {
      setNameError('A project already exists with this name.')
    } else {
      setNameError('')
    }
    setProject((prev) => ({ ...prev, name: event.target.value }))
  }

  function handleCategoryChange(category: ProjectCategory) {
    setProject((prev) => ({
      ...prev,
      project_category_id: category.id,
      project_category: category,
    }))
  }

  return (
    <Container maxWidth="sm">
      <Stack spacing={4} sx={{ width: '100%' }}>
        <ProjectCategoryAutocomplete
          selected={project.project_category as ProjectCategory}
          onCategoryChange={handleCategoryChange}
          size="medium"
          variant="outlined"
        />

        <TextField
          variant="outlined"
          label="Project Name"
          size="medium"
          helperText={nameError}
          value={project.name}
          onChange={handleNameChange}
          error={!!nameError}
        />

        {/* Color picker isn't needed for now. We may need it in the future. */}
        {/* <Stack direction="row" spacing={4} alignItems="center">
            <Typography sx={{ opacity: 0.75 }}>Color</Typography>

            <PixyColorPicker
              color={theme.palette.projectColors[project.color]}
              onChange={handleColorChange}
              disabled={false}
            />
          </Stack> */}
      </Stack>
      <StepActions
        onNext={handleNext}
        onBack={() => navigate('/projects')}
        nextDisabled={!project.name || !!nameError}
        nextLoading={isCreatingProject || isUpdatingProject}
        project={project}
      />
    </Container>
  )
}
