import { ReactNode } from 'react'
import { NodeProps, Position } from 'reactflow'
import {
  Card,
  Stack,
  SxProps,
  Tooltip,
  Box,
  useTheme,
  Typography,
} from '@mui/material'
import InputHandle from '../handles/InputHandle'
import OutputHandle from '../handles/OutputHandle'
import { useNodeIndex } from '../NodeIndexProvider'
import { NodeData } from '../types'
import { useNodeProblems } from '../ProblemsProvider'
import ExecutionHandle from '../handles/ExecutionHandle'
import DataTypeInheritanceProvider from '../InheritancesProvider'
import NodeHeading from './NodeHeading'
import NodeDataProvider from '../NodeDataProvider'
import { getHandlesFromLogixNode } from '../helpers/logix-helpers'

type BaseNodeProps = NodeProps<NodeData> & {
  // If true, the heading will be hidden.
  hideHeading?: boolean

  // Displayed above the inputs and outputs
  topContent?: ReactNode

  // Displayed between the inputs and outputs
  middleContent?: ReactNode

  // Displayed below the inputs and outputs
  bottomContent?: ReactNode

  // Displayed below the node card
  subContent?: ReactNode

  cardRef?: React.Ref<HTMLDivElement>

  sx?: SxProps
  width?: number
  height?: number
  minWidth?: number
  minHeight?: number
  messages?: string[]
  canEditTitle?: boolean
}

export default function BaseNode({
  type,
  selected,
  topContent,
  middleContent,
  bottomContent,
  subContent,
  data,
  sx,
  height,
  width,
  minWidth = 100,
  minHeight = 0,
  id,
  cardRef,
  hideHeading,
  canEditTitle,
}: BaseNodeProps) {
  const theme = useTheme()

  const { nodeTypes } = useNodeIndex()
  const nodeType = nodeTypes.find(({ code }) => code === type)

  const { problems } = useNodeProblems()
  const nodeProblems = problems.filter(
    ({ nodeId, handleId }) => nodeId === id && !handleId,
  )

  const { logixNode } = data

  const { inputs, outputs } = getHandlesFromLogixNode(logixNode)

  const primaryInputs = inputs.filter(({ area }) => area === 'primary-left')
  const primaryOutputs = outputs.filter(({ area }) => area === 'primary-right')
  const secondaryInputs = inputs.filter(({ area }) => area === 'secondary-left')
  const secondaryOutputs = outputs.filter(
    ({ area }) => area === 'secondary-right',
  )

  const hasInputsOrOutputs =
    secondaryInputs.length > 0 || secondaryOutputs.length > 0 || middleContent

  return (
    <NodeDataProvider data={data}>
      <Stack
        sx={{
          height: height || '100%',
          width,
          minWidth,
          minHeight,
        }}
      >
        <Card
          raised
          square={!!subContent}
          className="react-flow-drag-handle"
          ref={cardRef}
          sx={{
            ...sx,
            flexShrink: 1,
            flexGrow: 0,
            minWidth,
            overflow: 'visible',
            borderTopLeftRadius: subContent ? 4 : undefined,
            borderTopRightRadius: subContent ? 4 : undefined,
            outline: selected
              ? `2px solid ${theme.palette.secondary.main}`
              : 'none',
          }}
        >
          {/* TODO: Fix this */}
          {primaryInputs.length > 0 &&
            primaryInputs.map((logixHandle) => {
              return (
                <ExecutionHandle
                  hideLabel
                  key={logixHandle.id}
                  type="target"
                  position={Position.Left}
                  logixHandle={logixHandle}
                  style={{
                    position: 'absolute',
                    top: 15,
                    left: -10,
                  }}
                />
              )
            })}

          {primaryOutputs.length > 0 &&
            primaryOutputs.map((logixHandle) => {
              return (
                <ExecutionHandle
                  hideLabel
                  key={logixHandle.id}
                  type="source"
                  position={Position.Right}
                  logixHandle={logixHandle}
                  style={{
                    position: 'absolute',
                    top: 15,
                    right: -6,
                  }}
                />
              )
            })}

          <Tooltip
            title={nodeType?.description || 'MISSING_DESCRIPTION'}
            placement="top"
            arrow
            enterDelay={1500}
          >
            <Box sx={{ px: 2, pt: 1, pb: hasInputsOrOutputs ? 1.5 : 1 }}>
              {nodeType && !hideHeading && (
                <NodeHeading
                  title={logixNode.name || nodeType.title}
                  canEditTitle={canEditTitle}
                />
              )}

              {nodeProblems?.length > 0 &&
                nodeProblems.map((problem) => {
                  return (
                    <Typography
                      key={problem.message}
                      component="div"
                      variant="caption"
                      color={problem.severity === 'error' ? 'error' : 'warning'}
                      sx={{ maxWidth: 220 }}
                    >
                      {problem.message}
                    </Typography>
                  )
                })}
            </Box>
          </Tooltip>

          <Box
            sx={{
              '&:not(:empty)': {
                px: 2,
                pb: 2,
              },
            }}
          >
            {topContent}
          </Box>

          {hasInputsOrOutputs && (
            <Stack
              direction="row"
              spacing={
                inputs.length > 0 && !middleContent && outputs.length > 0
                  ? 4
                  : 0
              }
              // Make the box for inputs/outputs slightly wider so we get better alignment
              sx={{
                pb: subContent ? 2.5 : 1.5,
                width: 'calc(100% + 12px)',
                ml: '-6px',
              }}
              justifyContent="space-between"
            >
              <Stack spacing={1}>
                {secondaryInputs.map((logixHandle, index) => {
                  return <InputHandle key={index} logixHandle={logixHandle} />
                })}
              </Stack>

              {middleContent}

              <Stack spacing={1} sx={{ ml: inputs.length === 0 ? 1 : 0 }}>
                {secondaryOutputs.map((logixHandle, index) => {
                  if (logixHandle.data_type_code === 'execution') {
                    return (
                      <ExecutionHandle
                        key={index}
                        type="source"
                        position={Position.Right}
                        logixHandle={logixHandle}
                      />
                    )
                  }
                  return <OutputHandle key={index} logixHandle={logixHandle} />
                })}
              </Stack>
            </Stack>
          )}
          <Box
            sx={{
              '&:not(:empty)': {
                pb: 1,
                textAlign: 'center',
              },
            }}
          >
            {bottomContent}
          </Box>
        </Card>
        {subContent && (
          <Box
            sx={{
              flexGrow: 1,
              height: '100%',
              background: '#ffffff19',
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            {subContent}
          </Box>
        )}
      </Stack>
    </NodeDataProvider>
  )
}
