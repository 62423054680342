import { useMemo, useCallback } from 'react'
import { ProjectGridField } from '@/types/fields'
import {
  useCreateProjectMapping,
  useGetProjectMappings,
} from '@/service-library/hooks/project-mappings'
import {
  useCreateProjectMappingField,
  useDeleteProjectMappingField,
  useGetProjectMappingFields,
  useUpdateProjectMappingField,
} from '@/service-library/hooks/project-mapping-fields'
import { useGetProjectMappingTypes } from '@/service-library/hooks/project-mapping-types'
import generateUuid from '@/utils/generate-uuid'

type UseCSVHeaderFieldProps = {
  projectId: string | undefined
  field: ProjectGridField
}

export default function useCSVHeaderField({
  projectId,
  field,
}: UseCSVHeaderFieldProps) {
  const { projectMappingTypes } = useGetProjectMappingTypes({
    filters: {
      project_id: projectId,
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  })

  const csvMappingType = projectMappingTypes.find(
    (mapping_type) => mapping_type.code === 'csv',
  )

  const {
    projectMappings,
    isLoading: mappingIsLoading,
    error: mappingError,
    queryKey,
  } = useGetProjectMappings({
    filters: {
      project_id: projectId,
      limit: '1',
      project_mapping_type__code: 'csv',
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  })

  const csvMapping = projectMappings[0]

  const projectGridFieldId = field?.id

  const {
    projectMappingFields,
    isLoading: fieldsIsLoading,
    error: fieldsError,
    queryKey: fieldsQueryKey,
  } = useGetProjectMappingFields({
    filters: {
      limit: '1',
      project_mapping_id: csvMapping?.id,
      project_grid_field_id: projectGridFieldId,
    },
    enabled: !!csvMapping?.id && !!projectGridFieldId,
  })

  const { createProjectMappingField } = useCreateProjectMappingField({
    listQueryKey: fieldsQueryKey,
  })
  const { updateProjectMappingField } = useUpdateProjectMappingField({
    listQueryKey: fieldsQueryKey,
  })
  const { deleteProjectMappingField } = useDeleteProjectMappingField({
    listQueryKey: fieldsQueryKey,
  })

  const { createProjectMapping } = useCreateProjectMapping({
    listQueryKey: queryKey,
    onSuccess: (data) => {
      const project_mapping_id = data.id
      createProjectMappingField({
        id: generateUuid(),
        name: data.name,
        project_mapping_id,
        project_grid_field_id: field.id,
      })
    },
  })

  const errors = useMemo(() => {
    if (mappingError || fieldsError) {
      return 'There was a problem retrieving field configuration data. Please try again later.'
    }
    return null
  }, [mappingError, fieldsError])

  const currentMappingField = useMemo(
    () => projectMappingFields[0],
    [projectMappingFields],
  )

  const saveNameChange = useCallback(
    (newValue: string) => {
      if (!field || !projectId) {
        return
      }
      if (!csvMapping?.id && csvMappingType?.id) {
        createProjectMapping({
          id: generateUuid(),
          name: 'CSV Export',
          project_id: projectId,
          project_mapping_type_id: csvMappingType?.id,
        })
      } else if (csvMapping?.id && !currentMappingField && newValue !== '') {
        createProjectMappingField({
          id: generateUuid(),
          name: newValue ?? '',
          project_mapping_id: csvMapping?.id,
          project_grid_field_id: field.id,
        })
      } else if (currentMappingField) {
        if (newValue === '') {
          deleteProjectMappingField(currentMappingField.id)
        } else {
          updateProjectMappingField({
            ...currentMappingField,
            name: newValue ?? '',
          })
        }
      }
    },
    [
      createProjectMapping,
      createProjectMappingField,
      csvMapping?.id,
      csvMappingType?.id,
      currentMappingField,
      deleteProjectMappingField,
      field,
      projectId,
      updateProjectMappingField,
    ],
  )

  const isCSVHeaderLoading = useMemo(() => {
    if (csvMapping?.id && !mappingIsLoading) {
      return fieldsIsLoading
    } else if (!csvMapping?.id) {
      return mappingIsLoading
    }
  }, [mappingIsLoading, fieldsIsLoading, csvMapping?.id])

  return {
    csvHeaderValue: currentMappingField?.name ?? '',
    saveNameChange,
    isCsvHeaderLoading: isCSVHeaderLoading,
    errors,
  }
}
