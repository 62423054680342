import { Dispatch, SetStateAction, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormControlLabel, Link, Radio, Stack } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { useCreateSavedView } from '@/service-library/hooks/saved-views'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import EditableNameField from '@/components/editable-name/EditableNameField'
import useCurrentViewState from './useCurrentViewState'

type NewViewRadioOptionProps = {
  listQueryKey: QueryKey
  setSavedEnabled: Dispatch<SetStateAction<boolean>>
  setSelectedViewId: (newValue: string) => void
}

export default function NewViewRadioOption({
  listQueryKey,
  setSavedEnabled,
  setSelectedViewId,
}: NewViewRadioOptionProps) {
  const { projectId } = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const { createSavedView } = useCreateSavedView({
    listQueryKey,
    onMutate: (newView) => {
      setSelectedViewId(newView.id)
      setSavedEnabled(false)
      setIsEditing(false)
    },
    onError: () => {
      showErrorSnackbar('Unable to create view. Please try again later.')
    },
  })

  const currentViewState = useCurrentViewState()
  return (
    <Stack direction="row">
      <FormControlLabel
        value="new-view-id"
        control={
          <Radio color="neutral" sx={{ padding: 0.5 }} size="small" disabled />
        }
        label={
          <Stack direction="row" spacing={0.5} alignItems="center">
            {isEditing ? (
              <EditableNameField
                name=""
                handleOnBlur={(name) => {
                  if (name) {
                    createSavedView({
                      id: generateUuid(),
                      name,
                      state: currentViewState,
                      project_id: projectId as string,
                    })
                  }
                }}
                placeholder="Name"
              />
            ) : (
              <Link
                component="button"
                onClick={() => {
                  setIsEditing(true)
                }}
                underline="none"
                variant="body2"
              >
                <i>Save New View</i>
              </Link>
            )}
          </Stack>
        }
        sx={{
          alignItems: 'flex-center',
          columnGap: 0.5,
          width: 'fit-content',
        }}
      />
    </Stack>
  )
}
