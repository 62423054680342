import { Dispatch, RefObject, SetStateAction, useState } from 'react'
import { ArrowDropDown, Clear } from '@mui/icons-material'
import { Autocomplete, TextField, TextFieldProps } from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import { ContentCategoryItem } from '@/types/content-categories'

type CategoryFieldProps = {
  field: ProjectGridField
  value: string
  onChange: (newCategoryId: string | null) => void
  inputRef: RefObject<HTMLInputElement | undefined>
  setDisableArrowKeys: Dispatch<SetStateAction<boolean>>
  isInTable?: boolean
} & Omit<TextFieldProps, 'onChange' | 'value'>

export default function CategoryField({
  field,
  value,
  onChange,
  inputRef,
  variant,
  label,
  isInTable,
  setDisableArrowKeys,
  ...props
}: CategoryFieldProps) {
  const category = field.category_items?.find(({ id }) => id === value) || null
  const [open, setOpen] = useState(false)

  const handleOpen = (e: React.SyntheticEvent<Element, Event>) => {
    if (
      e.type !== 'mousedown' &&
      (e as unknown as KeyboardEvent).key !== 'ArrowDown' &&
      (e as unknown as KeyboardEvent).key !== 'ArrowUp'
    ) {
      setOpen(true)
      setDisableArrowKeys(true)
    }
  }

  return (
    <Autocomplete<ContentCategoryItem>
      autoHighlight
      clearIcon={<Clear fontSize="inherit" />}
      popupIcon={<ArrowDropDown fontSize="inherit" />}
      disabled={props.disabled}
      fullWidth
      onChange={(event, newValue: ContentCategoryItem | null) => {
        onChange(newValue ? newValue.id : null)
      }}
      options={field.category_items || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, currentValue) =>
        option.name === currentValue.name
      }
      open={open}
      onOpen={handleOpen}
      onClose={() => {
        setOpen(false)
        setDisableArrowKeys(false)
      }}
      value={category}
      renderInput={(params) => (
        <TextField
          variant={variant}
          inputRef={inputRef}
          label={label}
          {...props}
          {...params}
          inputProps={{
            ...params.inputProps,
            ...props.inputProps,
          }}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...props.InputLabelProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          size: isInTable ? 'small' : undefined,
        },
        popupIndicator: {
          size: isInTable ? 'small' : undefined,
        },
        popper: {
          sx: {
            minWidth: 'fit-content',
            width: 'max-content',
          },
        },
      }}
    />
  )
}
