import { useState } from 'react'
import {
  Autocomplete,
  TextField,
  ListItemButton,
  ListItemText,
  AutocompleteProps,
} from '@mui/material'
import { User } from '@/types/users'
import { useGetUsers } from '@/service-library/hooks/users'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'

type UserSearchInputProps = {
  onUserClick: (user: User) => void
  label?: string
  placeholder?: string
  disabledItemIDs?: string[]
} & Partial<AutocompleteProps<User, false, true, false, 'div'>>

export default function UserSearchInput({
  onUserClick,
  label = 'Search Users',
  placeholder = 'Search Users...',
  disabledItemIDs = [],
  ...props
}: UserSearchInputProps) {
  const { rootOrganization } = useRootOrganization()
  const [internalValue, setInternalValue] = useState('')
  const [enabled, setEnabled] = useState(false)

  const { users, isLoading } = useGetUsers({
    filters: {
      limit: '1000',
      owner_org_id: rootOrganization.id,
    },
    enabled,
  })

  return (
    <Autocomplete<User, false, true, false>
      options={users}
      loading={isLoading}
      autoHighlight
      openOnFocus
      clearOnEscape
      clearOnBlur
      disableClearable
      disableCloseOnSelect
      inputValue={internalValue}
      onInputChange={(event, value, reason) => {
        setInternalValue(reason === 'reset' ? '' : value)
      }}
      onChange={(event, user) => {
        if (user) onUserClick(user)
      }}
      onOpen={() => {
        setEnabled(true)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
        />
      )}
      getOptionLabel={({ name }) => name}
      renderOption={(props, { name, id, email }) => {
        return (
          <ListItemButton
            component="li"
            {...props}
            key={id}
            aria-selected={false} // To avoid having a selected background since selecting it actually shows it as disabled
            disabled={disabledItemIDs.includes(id)}
            dense
          >
            <ListItemText primary={name} secondary={email} />
          </ListItemButton>
        )
      }}
      {...props}
    />
  )
}
