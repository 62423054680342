import { memo } from 'react'
import { Box, Card, Stack, Typography, useTheme } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { ProjectGridField } from '@/types/fields'

function Bar({ color }: { color: string }) {
  return (
    <Box
      sx={{
        width: 4,
        background: color,
        height: '28px',
      }}
    />
  )
}

function TrainingFieldNode({
  data,
}: {
  data: {
    label: string
    showSourceHandle: boolean
    showTargetHandle: boolean
    field: ProjectGridField
  }
}) {
  const theme = useTheme()
  const { label, showSourceHandle, showTargetHandle } = data
  return (
    <Card>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ pr: 1, textWrap: 'nowrap' }}
      >
        <Bar
          color={
            showSourceHandle
              ? theme.palette.orange.main
              : theme.palette.blue.main
          }
        />
        <Typography variant="body2" textAlign="left" noWrap>
          {label}
        </Typography>
      </Stack>
      {showSourceHandle && <Handle type="source" position={Position.Right} />}
      {showTargetHandle && <Handle type="target" position={Position.Left} />}
    </Card>
  )
}

export default memo(TrainingFieldNode)
