import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { Team } from '@/types/teams'
import { OverlayState } from '@/hooks/useOverlay'
import { useCreateTeam, useUpdateTeam } from '@/service-library/hooks/teams'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'

type AddEditTeamDialogProps = {
  overlay: OverlayState
  team?: Team
  detailQueryKey?: QueryKey
}

type FormValues = {
  name: string
}

export default function AddEditTeamDialog({
  overlay,
  team,
  detailQueryKey,
}: AddEditTeamDialogProps) {
  const navigate = useNavigate()
  const { rootOrganization } = useRootOrganization()

  const { createTeam } = useCreateTeam({
    onSuccess: (newTeam) => {
      navigate(`/settings/teams/${newTeam.id}`)
    },
    onError: () => {
      showErrorSnackbar('Unable to create team. Please try again later.')
    },
  })

  const { updateTeam } = useUpdateTeam({
    detailQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update team. Please try again later.')
    },
  })

  const defaultValues = useMemo(
    () => ({ name: team?.name || '' }),
    [team?.name],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const {
    formState: { isDirty, isValid },
    register,
    reset,
  } = methods

  const handleSubmit = (values: FormValues) => {
    values.name = values.name.trim()
    if (team && team.name !== values.name) {
      updateTeam({ ...team, ...values })
    } else if (!team) {
      createTeam({
        ...values,
        owner_org_id: rootOrganization.id,
        id: generateUuid(),
        color: 'blue',
        is_system_managed: false,
        sort_order: 1000000,
      })
    }
    overlay.close()
  }

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  return (
    <Dialog
      title={`${team?.name ? 'Edit' : 'New'} Team`}
      {...overlay}
      maxWidth="sm"
    >
      <PixyDocsForm methods={methods} onSubmit={handleSubmit}>
        <DialogContent>
          <FormTextField
            autoFocus
            fullWidth
            label="Name"
            required
            {...register('name', {
              validate: (value) => value.trim().length > 0,
            })}
          />
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
