import { Project } from '@/types/projects'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { createDetail } from '../request-wrappers'

const service = buildService<Project>({
  pathCategory: 'projects',
})

export function useGetProject({
  id,
  ...options
}: UseGetDetailOptions<Project>) {
  const query = useGetDetail<Project>({
    id,
    queryKey: queryKeys.projects.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    project: query.data,
    ...query,
  }
}

export function useCreateProject(
  options: UseCreateDetailOptions<Project> = {},
) {
  const mutation = useCreateDetail<Project>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProject: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProject(
  options: UseUpdateDetailOptions<Project> = {},
) {
  const mutation = useUpdateDetail<Project>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProject: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProject(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<Project>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProject: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjects({
  filters,
  ...options
}: UseGetListOptions<Project> = {}) {
  const query = useGetList<Project>({
    filters,
    queryKey: queryKeys.projects.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projects: query.allData,
    ...query,
  }
}

export function useUpdateProjects(options: UseUpdateListOptions<Project> = {}) {
  const mutation = useUpdateList<Project>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjects: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjects({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<Project>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjects: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Create Project with Defaults
 * Creates a new Project with default values.
 */
export function useCreateProjectWithDefaults(
  options: UseCreateDetailOptions<Project> = {},
) {
  const mutation = useCreateDetail<Project>({
    serviceFn: (serviceFnOptions) => {
      return createDetail({
        url: '/v2/pd/projects/create-with-defaults',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    createProject: mutation.mutateAsync,
    ...mutation,
  }
}
