import { forwardRef } from 'react'
import { FieldValues, useController, FieldPath } from 'react-hook-form'
import DateTimeValueInput, {
  DateTimeValueInputProps,
} from '../inputs/DateTimeValueInput'
import { DatePickerProps } from '@mui/x-date-pickers'

type FormDateTimeFieldProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label: string
} & Omit<
  DateTimeValueInputProps<DatePickerProps<Date>>,
  'onChange' | 'value' | 'renderInput'
>

const FormDateTimeField = forwardRef(function FormDateTimeField<
  FormValues extends FieldValues,
>(
  { name, required, ...props }: FormDateTimeFieldProps<FormValues>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { field } = useController<FormValues>({
    name,
    rules: {
      required,
    },
  })

  return <DateTimeValueInput {...props} {...field} inputRef={ref} />
})

export default FormDateTimeField
