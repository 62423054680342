import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Edit, Delete } from '@mui/icons-material'
import { Button, Stack, Tabs, Tab } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { Team } from '@/types/teams'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import LargeHeading from '@/components/large-heading/LargeHeading'
import TabPanel from '@/components/TabPanel'
import TeamUsersTable from '@/components/team-users-table/TeamUsersTable'
import AddEditTeamDialog from './AddEditTeamDialog'
import DeleteTeamDialog from './DeleteTeamDialog'
import TeamOrganizations from './TeamOrganizations'

type TeamDetailsProps = {
  team: Team
  detailQueryKey: QueryKey
}

const tabMap: Record<string, number> = {
  users: 0,
  organizations: 1,
}

export default function TeamDetails({
  team,
  detailQueryKey,
}: TeamDetailsProps) {
  const { hasEditingPermission } = usePermission()

  const editOverlay = useOverlay()
  const deleteOverlay = useOverlay()

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState(
    tabMap[searchParams.get('tab') || ''] || 0,
  )

  const canEditTeam = hasEditingPermission('edit_teams', team.owner_org_id)
  const canAddRemoveUsers = hasEditingPermission(
    'edit_users',
    team.owner_org_id,
  )

  useEffect(() => {
    setSearchParams(
      new URLSearchParams({ tab: Object.keys(tabMap)[selectedTab] }),
      { replace: true },
    )
  }, [searchParams, selectedTab, setSearchParams])

  return (
    <Stack spacing={2}>
      <div>
        <LargeHeading
          heading={team.name}
          subHeading="Team"
          actions={
            <>
              {canEditTeam && (
                <>
                  <Button
                    variant="text"
                    startIcon={<Edit />}
                    onClick={editOverlay.open}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    startIcon={<Delete />}
                    onClick={deleteOverlay.open}
                  >
                    Delete
                  </Button>
                  <AddEditTeamDialog
                    overlay={editOverlay}
                    team={team}
                    detailQueryKey={detailQueryKey}
                  />
                  <DeleteTeamDialog overlay={deleteOverlay} team={team} />
                </>
              )}
              <CopyIDButton stringToCopy={team.id} />
            </>
          }
        />
      </div>

      <Tabs
        value={selectedTab}
        onChange={(event, newTabIndex) => setSelectedTab(newTabIndex)}
      >
        <Tab label="Users" />
        <Tab label="Organizations" />
      </Tabs>

      <TabPanel value={selectedTab} index={0}>
        {team && (
          <TeamUsersTable
            team={team}
            canAddRemoveUsers={canAddRemoveUsers}
            detailQueryKey={detailQueryKey}
          />
        )}
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <TeamOrganizations
          canAddRemoveOrgs={canEditTeam}
          team={team}
          detailQueryKey={detailQueryKey}
        />
      </TabPanel>
    </Stack>
  )
}
