import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { RowValueFlag } from '@/types/flags'

const service = buildService<RowValueFlag>({
  pathCategory: 'row_value_flags',
})

export function useGetRowValueFlag({
  id,
  ...options
}: UseGetDetailOptions<RowValueFlag>) {
  const query = useGetDetail<RowValueFlag>({
    id,
    queryKey: queryKeys.rowValueFlags.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    rowValueFlag: query.data,
    ...query,
  }
}

export function useCreateRowValueFlag(
  options: UseCreateDetailOptions<RowValueFlag> = {},
) {
  const mutation = useCreateDetail<RowValueFlag>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createRowValueFlag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateRowValueFlag(
  options: UseUpdateDetailOptions<RowValueFlag> = {},
) {
  const mutation = useUpdateDetail<RowValueFlag>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateRowValueFlag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteRowValueFlag(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<RowValueFlag>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteRowValueFlag: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetRowValueFlags({
  filters,
  ...options
}: UseGetListOptions<RowValueFlag> = {}) {
  const query = useGetList<RowValueFlag>({
    filters,
    queryKey: queryKeys.rowValueFlags.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    rowValueFlags: query.allData,
    ...query,
  }
}

export function useUpdateRowValueFlags(
  options: UseUpdateListOptions<RowValueFlag> = {},
) {
  const mutation = useUpdateList<RowValueFlag>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateRowValueFlags: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteRowValueFlags({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<RowValueFlag>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteRowValueFlags: mutation.mutateAsync,
    ...mutation,
  }
}
