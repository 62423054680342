import { useEffect } from 'react'
import { UseGetListOptions } from '@/service-library/core-hooks/useGetList'
import { useGetDocuments } from '@/service-library/hooks/documents'
import { Document } from '@/types/documents'

export default function useGetAllDocuments(
  options: UseGetListOptions<Document> = {},
) {
  const query = useGetDocuments(options)
  const { hasNextPage, isFetching, isRefetching, fetchNextPage } = query

  const isFetchingAll = hasNextPage || (isFetching && !isRefetching)

  useEffect(() => {
    if (options.enabled && hasNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetching, options.enabled])

  return { isFetchingAll, ...query }
}
