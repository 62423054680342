import { useMemo, useState } from 'react'
import { LogixHandle } from '@/types/logix'
import useLogixNodeTypes from '@/services/hooks/useLogixNodeTypes'

export default function useFilteredNodeTypes() {
  const { nodeTypes = [] } = useLogixNodeTypes()

  const [filteringHandle, setFilteringHandle] = useState<LogixHandle | null>(
    null,
  )

  const [filteringParentNodeId, setFilteringParentNodeId] = useState<
    string | null
  >(null)

  function setFilteringValues(
    newFilteringHandle: LogixHandle | null = null,
    parentNodeId: string | null = null,
  ) {
    setFilteringHandle(newFilteringHandle)
    setFilteringParentNodeId(parentNodeId)
  }

  const filteredNodeTypes = useMemo(() => {
    if (!filteringHandle) return nodeTypes
    // FIXME: Use filteringParentNodeId to filter out nodes that are not valid
    const handlesToCheckAgainst = filteringHandle.area.includes('left')
      ? 'type_out_handles'
      : 'type_in_handles'

    return nodeTypes.filter((nodeType) => {
      return nodeType[handlesToCheckAgainst].some(
        ({ data_type_code }) =>
          data_type_code === filteringHandle.data_type_code ||
          (filteringHandle.data_type_code !== 'execution' &&
            data_type_code === 'any'),
      )
    })
  }, [filteringHandle, nodeTypes])

  return {
    filteredNodeTypes,
    setFilteringValues,
    filteringHandle,
  }
}
