import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Card, FormHelperText, Stack, Typography } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { ImporterEmail } from '@/types/importer-emails'
import { Organization } from '@/types/organizations'
import { Project } from '@/types/projects'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useCreateImporterEmail,
  useUpdateImporterEmail,
} from '@/service-library/hooks/importer-emails'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import OrganizationPickerWithDialog from '@/components/organization-select/OrganizationPickerWithDialog'

type AddEditImporterEmailDialogProps = {
  disabledOrgIds: string[]
  isFetchingOrgs: boolean
  listQueryKey: QueryKey
  organizations: Organization[]
  overlay: OverlayState
  project: Project
  importerEmail?: ImporterEmail
}

type FormValues = {
  org: Organization
  name: string
}

export default function AddEditImporterEmailDialog({
  disabledOrgIds,
  isFetchingOrgs,
  listQueryKey,
  organizations,
  overlay,
  project,
  importerEmail,
}: AddEditImporterEmailDialogProps) {
  const { createImporterEmail } = useCreateImporterEmail({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to create email. Please try again later.')
    },
  })

  const { updateImporterEmail } = useUpdateImporterEmail({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update email. Please try again later.')
    },
  })

  const firstAvailableOrg = organizations.find(
    ({ id }) => !disabledOrgIds.includes(id),
  )

  const defaultValues = useMemo(
    () => ({
      org: importerEmail?.org || firstAvailableOrg,
      name: importerEmail?.name || generateUuid(),
    }),
    [firstAvailableOrg, importerEmail?.name, importerEmail?.org],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const {
    clearErrors,
    formState: { dirtyFields, errors, isDirty, isValid },
    register,
    reset,
    setError,
    setValue,
    watch,
  } = methods

  const handleSubmit = (values: FormValues) => {
    values.name = values.name.trim()
    const orgId = values.org.id
    // We just use it for optimizing updates
    const placeholderEmail = `${values.name}@${window.location.host}`

    if (importerEmail) {
      const updatedImporterEmail = {
        ...importerEmail,
        ...values,
        org_id: orgId,
        email: placeholderEmail,
      }
      updateImporterEmail(updatedImporterEmail)
    } else {
      createImporterEmail({
        ...values,
        org_id: orgId,
        project_id: project.id,
        email: placeholderEmail,
        id: generateUuid(),
      })
    }
    overlay.close()
  }

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  return (
    <Dialog
      title={`${importerEmail ? 'Edit' : 'New'} Upload Email Address`}
      {...overlay}
      maxWidth="sm"
    >
      <PixyDocsForm methods={methods} onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={3}>
            <Card>
              <OrganizationPickerWithDialog
                currentOrganization={watch('org')}
                disabledOrgIds={disabledOrgIds}
                organizations={organizations}
                rootTreeOrgId={project.org_id}
                isFetching={isFetchingOrgs}
                onSave={(organization) => {
                  setValue('org', organization, { shouldDirty: true })
                }}
              />
            </Card>
            <Stack direction="row" alignItems="baseline" spacing={0.5}>
              <FormTextField
                error={!!errors.name}
                label="Email"
                sx={{ width: 335 }}
                required
                {...register('name', {
                  validate: (value) => /^[0-9A-Za-z.-]+$/.test(value),
                  onChange: (e) => {
                    if (!/^[0-9A-Za-z.-]+$/.test(e.target.value)) {
                      setError('name', {
                        type: 'manual',
                        message:
                          e.target.value.trim() === ''
                            ? 'Field cannot be empty'
                            : 'Only accepts numbers, letters, periods, and dashes.',
                      })
                    } else if (errors.name) {
                      clearErrors('name')
                    }
                  },
                })}
              />
              <Typography>@{window.location.host}</Typography>
            </Stack>
          </Stack>
          {!importerEmail && !dirtyFields.name && (
            <FormHelperText sx={{ ml: 1 }}>
              We generated this email address for you, but you can change it if
              you&apos;d like.
            </FormHelperText>
          )}
          {errors?.name && (
            <FormHelperText error sx={{ ml: 1 }}>
              {errors.name.message}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={importerEmail ? !isDirty || !isValid : !isValid}
          >
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
