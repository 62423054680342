import { useState } from 'react'
import Markdown from 'react-markdown'
import { Edit, Fullscreen } from '@mui/icons-material'
import {
  Stack,
  IconButton,
  DialogContent,
  Link,
  Button,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { DataList } from '@/types/data-lists'
import { OverlayState } from '@/hooks/useOverlay'
import { useUpdateDataList } from '@/service-library/hooks/data-lists'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogFooter } from '@/components/dialog'
import CodeEditor from '@/components/code-editor/CodeEditor'

type ViewEditDataListDescriptionDialogProps = {
  overlay: OverlayState
  dataList: DataList
  rootDetailQueryKey: QueryKey
}

const CHARACTER_LIMIT = 8000

export default function ViewEditDataListDescriptionDialog({
  overlay,
  dataList,
  rootDetailQueryKey,
}: ViewEditDataListDescriptionDialogProps) {
  const [fullScreen, setFullScreen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [draftDescription, setDraftDescription] = useState(
    dataList.description || '',
  )
  const [wrapEnabled, setWrapEnabled] = useState(false)
  const overCharacterLimit = draftDescription.length > CHARACTER_LIMIT

  const { updateDataList } = useUpdateDataList({
    detailQueryKey: rootDetailQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update description. Please try again later.')
    },
  })

  return (
    <Dialog
      {...overlay}
      title={
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography component="h2" variant="h6">
            {dataList.name}
          </Typography>
          <IconButton
            size="small"
            onClick={() => {
              setFullScreen((prev) => !prev)
            }}
          >
            <Fullscreen />
          </IconButton>
        </Stack>
      }
      fullScreen={fullScreen}
      TransitionProps={{
        onExited: () => {
          fullScreen && setFullScreen(false)
          if (isEditing) {
            setIsEditing(false)
            setDraftDescription(dataList.description || '')
          }
        },
      }}
    >
      {isEditing ? (
        <Stack
          alignItems="flex-end"
          sx={{ height: fullScreen ? 'calc(100vh - 128px)' : undefined }} // 128px is the height of the dialog header and footer
        >
          <FormControlLabel
            control={
              <Switch
                checked={wrapEnabled}
                onChange={() => setWrapEnabled(!wrapEnabled)}
              />
            }
            label="Wrap Lines"
            sx={{
              width: 'fit-content',
              p: 0.5,
            }}
          />
          <CodeEditor
            value={draftDescription}
            setValue={setDraftDescription}
            mode="markdown"
            wrapEnabled={wrapEnabled}
            characterLimit={CHARACTER_LIMIT}
            height="100%"
          />
        </Stack>
      ) : (
        <DialogContent>
          <Stack spacing={2}>
            <Button
              variant="text"
              startIcon={<Edit />}
              onClick={() => setIsEditing(true)}
              sx={{
                width: 'fit-content',
              }}
            >
              Edit
            </Button>
            {dataList.description ? (
              <Markdown
                components={{
                  a: ({ children, href }) => (
                    <Link color="primary" href={href} target="_blank">
                      {children}
                    </Link>
                  ),
                }}
              >
                {dataList.description}
              </Markdown>
            ) : (
              <Typography variant="caption" color="text.secondary">
                <i>Click &quot;Edit&quot; to add a description.</i>
              </Typography>
            )}
          </Stack>
        </DialogContent>
      )}
      <DialogFooter
        details={
          isEditing ? (
            <Typography
              variant="body2"
              sx={{
                width: 'max-content',
                px: 1,
                py: 0.5,
                borderRadius: 4,
                background: overCharacterLimit ? 'red' : 'transparent',
                transition: 'all 0.2s',
                fontWeight: overCharacterLimit ? 'bold' : 'normal',
              }}
            >
              {CHARACTER_LIMIT !== Infinity
                ? `${draftDescription.length}/${CHARACTER_LIMIT} characters`
                : ''}
            </Typography>
          ) : null
        }
      >
        {isEditing ? (
          <>
            <Button
              variant="text"
              onClick={() => {
                setIsEditing(false)
                setDraftDescription(dataList.description || '')
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsEditing(false)
                updateDataList({ ...dataList, description: draftDescription })
              }}
              disabled={overCharacterLimit}
            >
              Save
            </Button>
          </>
        ) : (
          <Button onClick={overlay.close}>Close</Button>
        )}
      </DialogFooter>
    </Dialog>
  )
}
