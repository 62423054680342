import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Stack, Button, Box } from '@mui/material'
import { TreeView } from '@mui/x-tree-view/TreeView'
import useHorizontalDrag from '@/hooks/useHorizontalDrag'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { useGetOrganization } from '@/service-library/hooks/organizations'
import { createOrganizationMap, getNodesToExpand } from '@/utils/organization'
import OrganizationTreeItem from '@/components/organizations-tree/OrganizationTreeItem'
import { OrgTreeSkeleton } from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import PermissionTooltip from '@/components/tooltip/PermissionTooltip'
import CustomVariablesPerOrg from './CustomVariablesPerOrg'
import GlobalVariablesDialog, {
  GlobalVariablesDialogProps,
} from './GlobalVariablesDialog'

export default function CustomVariablesDetails() {
  const { '*': id } = useParams()
  const navigate = useNavigate()
  const customVariablesOverlay = useOverlay()
  const { hasEditingPermission } = usePermission()

  const { rootOrganization } = useRootOrganization()
  const { organization } = useGetOrganization({
    id: rootOrganization.id,
    filters: {
      fields__include: 'custom_variables',
    },
  })

  const [expanded, setExpanded] = useState([rootOrganization.id])

  const {
    contentRef,
    dragDividerRef,
    dragDividerContainerRef,
    resizableContainerWith,
    bind,
  } = useHorizontalDrag({
    identifier: `custom-variables-orgs-width-${rootOrganization.id}`,
    defaultWidth: 350,
    minLeftSideWidth: 150,
    minRightSideWidth: 150,
  })

  const { organizations, isFetchingAll } = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootOrganization.id,
    },
  })

  const organizationMap = useMemo(
    () => (isFetchingAll ? {} : createOrganizationMap(organizations)),
    [isFetchingAll, organizations],
  )

  const rootOrganizationWithVariables =
    organization as GlobalVariablesDialogProps['organizationWithVariables']

  const selectedOrg = organizations.find((org) => org.id === id)

  const canEdit = hasEditingPermission('edit_orgs', rootOrganization.id)

  useEffect(() => {
    if (!id) navigate(rootOrganization.id)
  }, [id, navigate, rootOrganization.id])

  useEffect(() => {
    if (
      !isFetchingAll &&
      selectedOrg &&
      selectedOrg.id !== rootOrganization.id &&
      !expanded.includes(selectedOrg.parent_org_id || '')
    ) {
      setExpanded(getNodesToExpand(selectedOrg.id, organizationMap))
    }
  }, [
    expanded,
    id,
    isFetchingAll,
    organizationMap,
    rootOrganization.id,
    selectedOrg,
  ])

  return (
    <Stack
      ref={contentRef}
      direction="row"
      spacing={2}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        // Since every container will have its own scroll "space", we use this to ignore
        // the default padding that global settings have
        m: -2,
        mt: -3,
        height: 'calc(100vh - 50px)', // Subtracting 50px to account for the app top bar
      }}
    >
      <Stack direction="row" ref={dragDividerContainerRef}>
        <Stack
          spacing={2}
          sx={{
            width: resizableContainerWith,
            overflow: 'auto',
            p: 2,
            pt: 3,
          }}
        >
          <PermissionTooltip canEdit={canEdit}>
            {/* Added margin-left to match default margin on tree items */}
            <Button
              disabled={!canEdit}
              onClick={customVariablesOverlay.open}
              variant="text"
              sx={{ ml: 2 }}
            >
              Manage Variables
            </Button>
          </PermissionTooltip>
          {isFetchingAll ? (
            <OrgTreeSkeleton />
          ) : (
            <TreeView
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
              expanded={expanded}
              selected={id || rootOrganization.id}
              onNodeSelect={(_e, id) => {
                navigate(id)
              }}
              onNodeToggle={(_e, ids) => {
                setExpanded(ids)
              }}
            >
              <OrganizationTreeItem
                orgTree={organizationMap[rootOrganization.id]}
              />
            </TreeView>
          )}
        </Stack>
        <Box
          ref={dragDividerRef}
          sx={{
            width: 4,
            flexShrink: 0,
            transition: '0.1s',
            cursor: 'ew-resize',
            borderRadius: 40,
            '&:hover': {
              background: (theme) => theme.palette.primary.main,
            },
          }}
          {...bind()}
        />
      </Stack>
      {rootOrganizationWithVariables && (selectedOrg || !isFetchingAll) && (
        <CustomVariablesPerOrg
          customVariables={rootOrganizationWithVariables?.custom_variables}
          organization={selectedOrg}
        />
      )}

      {rootOrganizationWithVariables && (
        <GlobalVariablesDialog
          overlay={customVariablesOverlay}
          organizationWithVariables={rootOrganizationWithVariables}
        />
      )}
    </Stack>
  )
}
