import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'

type DisabledDocumentsContextValue = {
  disabledDocumentIds: string[]
  setDisabledDocumentIds: Dispatch<SetStateAction<string[]>>
}

const DisabledDocumentsContext = createContext<DisabledDocumentsContextValue>(
  {} as DisabledDocumentsContextValue,
)

export const useDisabledDocumentsContext = () =>
  useContext(DisabledDocumentsContext)

type DisabledDocumentsProviderProps = {
  children: ReactNode
}

export default function DisabledDocumentsProvider({
  children,
}: DisabledDocumentsProviderProps) {
  const [disabledDocumentIds, setDisabledDocumentIds] = useState<string[]>([])

  return (
    <DisabledDocumentsContext.Provider
      value={{
        disabledDocumentIds,
        setDisabledDocumentIds,
      }}
    >
      {children}
    </DisabledDocumentsContext.Provider>
  )
}
