import { useState } from 'react'
import PageTitle from '@/components/PageTitle'
import { Button, Container, Stack, Typography } from '@mui/material'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import CredentialCard from './CredentialCard'
import { Add } from '@mui/icons-material'
import useOverlay from '@/hooks/useOverlay'
import AddEditCredentialDialog from './AddEditCredentialDialog'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useGetOrgCredentials } from '@/service-library/hooks/org-credentials'
import { useGetOrgCredentialTypes } from '@/service-library/hooks/org-credential-types'

export default function CredentialsPage() {
  const overlay = useOverlay()
  const { rootOrganization } = useRootOrganization()
  const [selectedCredentialId, setSelectedCredentialId] = useState<string>()
  const { orgCredentials, queryKey } = useGetOrgCredentials({
    filters: {
      limit: '100',
      org_and_descendants_for_id: rootOrganization.id,
      fields__include: 'org',
    },
  })

  const { orgCredentialTypes } = useGetOrgCredentialTypes({
    filters: {
      limit: '100',
    },
  })

  const selectedCredential = selectedCredentialId
    ? orgCredentials.find(({ id }) => id === selectedCredentialId)
    : undefined

  return (
    <Container maxWidth="sm" sx={{ pb: 2 }}>
      <PageTitle>Credentials</PageTitle>
      <Breadcrumb label="Credentials" url="/settings/credentials" />

      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Credentials</Typography>
          <Button
            onClick={() => {
              setSelectedCredentialId(undefined)
              overlay.open()
            }}
            variant="text"
            startIcon={<Add />}
          >
            New Credential
          </Button>
        </Stack>
        {orgCredentials.map((credential) => (
          <CredentialCard
            key={credential.id}
            addEditOverlay={overlay}
            credential={credential}
            listQueryKey={queryKey}
            type={orgCredentialTypes.find(
              ({ id }) => id === credential.type_id,
            )}
            setSelectCredentialId={setSelectedCredentialId}
          />
        ))}
      </Stack>

      <AddEditCredentialDialog
        overlay={overlay}
        listQueryKey={queryKey}
        rootOrganization={rootOrganization}
        types={orgCredentialTypes}
        orgCredential={selectedCredential}
      />
    </Container>
  )
}
