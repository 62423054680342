import { useMemo } from 'react'
import { ProjectGridFieldRule, ProjectGridFieldRuleType } from '@/types/rules'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetProjectGridFieldRuleTypes } from '@/service-library/hooks/project-grid-field-rule-types'
import generateUuid from '@/utils/generate-uuid'
import AddRuleDialog, { FormValues } from '@/components/rules/AddRuleDialog'

type AddFieldRuleDialogProps = {
  overlay: OverlayState
  fieldId: string
  isTableField: boolean
  onSubmit: (rule: ProjectGridFieldRule) => void
}

export default function AddFieldRuleDialog({
  overlay,
  isTableField,
  fieldId,
  onSubmit,
}: AddFieldRuleDialogProps) {
  const featureFlags = useFeatureFlagContext()

  const { rules, isLoading } = useGetProjectGridFieldRuleTypes({
    filters: { limit: '1000' },
    refetchOnWindowFocus: false,
    enabled: overlay.isOpen,
  })

  const filteredRules = useMemo(
    () =>
      rules
        .filter(
          ({ code }) =>
            code !== 'autofill' || (code === 'autofill' && isTableField),
        )
        .filter(({ code }) => {
          return code === 'run_py' ? featureFlags.custom_python_rules : true
        }) || [],
    [featureFlags.custom_python_rules, isTableField, rules],
  )

  const handleCreateFieldRule = ({
    type,
    workflow_states_ids,
    dependent_fields,
    is_superuser_rule,
    ...rest
  }: FormValues<ProjectGridFieldRuleType>) => {
    onSubmit({
      params: rest || {},
      id: generateUuid(),
      sort_order: 1000001,
      workflow_states_ids,
      is_superuser_rule,
      dependent_fields_ids: dependent_fields.map(({ id }) => id),
      project_grid_field_id: fieldId,
      rule_id: type.id,
      rule: type,
      is_enabled: true,
    })
  }

  return (
    <AddRuleDialog
      isLoading={isLoading}
      overlay={overlay}
      ruleTypes={filteredRules}
      onSubmit={handleCreateFieldRule}
      fieldId={fieldId}
    />
  )
}
