import { ReactNode, useRef } from 'react'
import { ListItemButton, Menu, MenuItem } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import LabeledData from '../labeled-data/LabeledData'

type SelectMenuProps<ValueType> = {
  selectedLabel: ReactNode
  onChange: (newValue: ValueType) => void
  title?: string
  dense?: boolean
  square?: boolean
  hideLabel?: boolean
  options: {
    key: string
    label: ReactNode
    value: ValueType
  }[]
}

export default function SelectMenu<ValueType>({
  selectedLabel,
  onChange,
  title,
  hideLabel,
  options,
  dense,
  square,
}: SelectMenuProps<ValueType>) {
  const overlay = useOverlay()
  const anchorRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <ListItemButton
        sx={{
          px: dense ? 1 : 2,
          pb: dense ? 0.5 : 1,
          pt: 1,
          borderRadius: square ? 0 : 2,
        }}
        dense
        ref={anchorRef}
        onClick={overlay.toggle}
      >
        <LabeledData
          label={title}
          data={selectedLabel}
          hideLabel={hideLabel}
          dense={dense}
        />
      </ListItemButton>

      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
      >
        {options.map((option) => (
          <MenuItem
            dense={dense}
            key={option.key}
            onClick={() => {
              onChange(option.value)
              overlay.close()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
