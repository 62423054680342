import { DocumentRowValue } from '@/types/documents'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

type DragonFillProviderProps = {
  children: ReactNode
}

export type DragonFillRow = {
  id: `row-${number}-field-${string}`
  documentRowValue: DocumentRowValue | null
  documentRowId: string
}

const DragonFillContext = createContext<
  [DragonFillRow[], Dispatch<SetStateAction<DragonFillRow[]>>]
>([[], () => {}])

export const useDragonFillContext = () => useContext(DragonFillContext)

export default function DragonFillProvider({
  children,
}: DragonFillProviderProps) {
  const [dragonFillRows, setDragonFillRows] = useState<DragonFillRow[]>([])

  return (
    <DragonFillContext.Provider value={[dragonFillRows, setDragonFillRows]}>
      {children}
    </DragonFillContext.Provider>
  )
}
