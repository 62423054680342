import { useDrawerPortalContext } from './DrawerPortalProvider'
import { Box } from '@mui/material'

export default function DrawerPortal({
  anchor = 'left',
  additionalId,
}: {
  anchor?: 'left' | 'right'
  additionalId?: string
}) {
  const { setPortalNode, setSecondPortalNode } = useDrawerPortalContext()
  return (
    <Box
      ref={anchor === 'left' ? setPortalNode : setSecondPortalNode}
      data-portal-id={`drawers-${anchor}${
        additionalId ? `-${additionalId}` : ''
      }`}
      sx={{ position: 'relative' }}
    />
  )
}
