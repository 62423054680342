import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Stack } from '@mui/material'
import ManageWorkflowAssignmentDisplay from './action-displays/ManageWorkflowAssignmentDisplay'
import ParamDisplay from './action-displays/ParamDisplay'
import CreateAuditTrailEntryDisplay from './action-displays/CreateAuditTrailEntryDisplay'
import MoveCleanDocumentsDisplay from './action-displays/MoveCleanDocumentsDisplay'
import RunPredictionModelDisplay from './action-displays/RunPredictionModelDisplay'
import AddDocumentFlagDisplay from './action-displays/AddDocumentFlagDisplay'
import RunRulesDisplay from './action-displays/RunRulesDisplay'
import ExportToIntegrationsDisplay from './action-displays/ExportToIntegrationsDisplay'
import TriggerEventDisplay from './action-displays/TriggerEventDisplay'

type ActionParamsDisplayProps = {
  action: WorkflowStateAction
}

export default function ActionParamsDisplay({
  action,
}: ActionParamsDisplayProps) {
  const action_code = action.action_type?.code
  const paramEntries = Object.entries(action.params).toSorted(
    (a, b) =>
      action.action_type?.params_schema.properties[a[0]].sort_order -
      action.action_type?.params_schema.properties[b[0]].sort_order,
  )

  if (action_code === 'manage_workflow_assignment') {
    return <ManageWorkflowAssignmentDisplay action={action} />
  }
  if (action_code === 'create_audit_trail_entry') {
    return <CreateAuditTrailEntryDisplay action={action} />
  }
  if (action_code === 'move_clean_docs_to_workflow_state') {
    return <MoveCleanDocumentsDisplay action={action} />
  }
  if (action_code === 'run_prediction_model') {
    return <RunPredictionModelDisplay action={action} />
  }
  if (action_code === 'add_document_flag') {
    return <AddDocumentFlagDisplay action={action} />
  }
  if (action_code === 'run_rules_for_workflow_state') {
    return <RunRulesDisplay />
  }
  if (action_code === 'export_to_integrations') {
    return <ExportToIntegrationsDisplay />
  }
  if (action_code === 'trigger_event') {
    return <TriggerEventDisplay action={action} />
  }

  if (paramEntries.length === 0) return null

  return (
    <Stack direction="column" spacing={1}>
      {paramEntries.map(([paramName, paramValue]) => {
        if (paramValue === null) return null
        return (
          <ParamDisplay
            key={paramName}
            paramSchema={
              action.action_type?.params_schema.properties[paramName]
            }
            paramValue={paramValue}
          />
        )
      })}
    </Stack>
  )
}
