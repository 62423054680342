import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import { WorkflowStateActionType } from '@/types/workflow-state-actions'

const service = buildService<WorkflowStateActionType>({
  pathCategory: 'workflow_state_action_types',
})

export function useGetWorkflowStateActionType({
  id,
  ...options
}: UseGetDetailOptions<WorkflowStateActionType>) {
  const query = useGetDetail<WorkflowStateActionType>({
    id,
    queryKey: queryKeys.workflowStateActionTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    workflowStateActionType: query.data,
    ...query,
  }
}

export function useGetWorkflowStateActionTypes({
  filters,
  ...options
}: UseGetListOptions<WorkflowStateActionType> = {}) {
  const query = useGetList<WorkflowStateActionType>({
    filters,
    queryKey: queryKeys.workflowStateActionTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    workflowStateActionTypes: query.allData,
    ...query,
  }
}
