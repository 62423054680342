import { useCallback, useMemo, useState } from 'react'
import useLocalStorage from './useLocalStorage'

export type OverlayState = {
  isOpen: boolean
  close: () => void
  open: (event?: React.UIEvent | MouseEvent | TouchEvent) => void
  toggle: (event?: React.UIEvent | MouseEvent | TouchEvent) => void
  anchorEl: EventTarget | null
}

export type UseOverlayOptions = {
  initiallyOpen?: boolean
  localStorageKey?: string
}

export default function useOverlay({
  initiallyOpen = false,
  localStorageKey,
}: UseOverlayOptions = {}): OverlayState {
  const saveInLocalStorage = localStorageKey !== undefined

  const [isOpen, setIsOpen] = useState(initiallyOpen)
  const [isOpenLS, setIsOpenLS] = useLocalStorage(
    saveInLocalStorage ? localStorageKey : 'overlay-is-open',
    initiallyOpen,
  )

  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null)

  const close = useCallback(() => {
    if (saveInLocalStorage) {
      setIsOpenLS(false)
    } else {
      setIsOpen(false)
    }
    setAnchorEl(null)
  }, [saveInLocalStorage, setIsOpenLS])

  const open = useCallback(
    (event?: React.UIEvent | MouseEvent | TouchEvent) => {
      if (event) setAnchorEl(event.target)
      if (saveInLocalStorage) {
        setIsOpenLS(true)
      } else {
        setIsOpen(true)
      }
    },
    [saveInLocalStorage, setIsOpenLS],
  )

  const toggle = useCallback(
    (event?: React.UIEvent | MouseEvent | TouchEvent) => {
      if (event) setAnchorEl(event.target)
      if (saveInLocalStorage) {
        setIsOpenLS((prev) => !prev)
      } else {
        setIsOpen((prev) => !prev)
      }
    },
    [saveInLocalStorage, setIsOpenLS],
  )

  return useMemo(
    () => ({
      isOpen: saveInLocalStorage ? isOpenLS : isOpen,
      close,
      open,
      toggle,
      anchorEl,
    }),
    [anchorEl, close, isOpen, isOpenLS, open, saveInLocalStorage, toggle],
  )
}
