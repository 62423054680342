import { useNodeId, useReactFlow, useUpdateNodeInternals } from 'reactflow'
import { DataType, HandleStructure } from '../types'
import HandleCheckboxInput from './HandleCheckboxInput'
import HandleDateInput from './HandleDateInput'
import HandleIntegerInput from './HandleIntegerInput'
import HandleTextInput from './HandleTextInput'
import HandleDecimalInput from './HandleDecimalInput'
import HandleDateTimeInput from './HandleDateTimeInput'
import HandleTimeInput from './HandleTimeInput'
import { LogixHandle } from '@/types/logix'

type HandleInputProps = {
  logixHandle: LogixHandle
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inputComponentMap: Record<DataType, any> = {
  boolean: HandleCheckboxInput,
  date: HandleDateInput,
  time: HandleTimeInput,
  datetime: HandleDateTimeInput,
  integer: HandleIntegerInput,
  decimal: HandleDecimalInput,
  text: HandleTextInput,
  execution: () => null,
  object: () => null,
  any: () => null,
}

export default function HandleInput({ logixHandle }: HandleInputProps) {
  const nodeId = useNodeId()
  const flowInstance = useReactFlow()
  const InputComponent = inputComponentMap[logixHandle.data_type_code]

  // const { updateLogixHandle } = useUpdateLogix

  const updateNodeInternals = useUpdateNodeInternals()

  function updateHandleManualValue(updatedValue: LogixHandle['manual_value']) {
    // TODO: Implement this once manual values are properly stored on the backend.
  }

  return (
    <InputComponent
      logixHandle={logixHandle}
      updateHandleManualValue={updateHandleManualValue}
    />
  )
}
