/* eslint-disable @typescript-eslint/no-explicit-any */
import { MaterialReactTable } from 'material-react-table'
import useZerapixTable from './useZerapixTable'

export type ZerapixTableProps<DataType extends Record<string, any>> = {
  table: ReturnType<typeof useZerapixTable<DataType>>
}

export default function ZerapixTable<DataType extends Record<string, any>>({
  table,
}: ZerapixTableProps<DataType>) {
  return <MaterialReactTable table={table} />
}
