import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Settings, Fullscreen } from '@mui/icons-material'
import { Stack, Box, IconButton, Tooltip } from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import { OverlayState } from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import DescriptionDialog from '@/components/description-dialog/DescriptionDialog'

type WorkflowStateDescriptionDialogProps = {
  overlay: OverlayState
  projectId: string
  workflowState: WorkflowState
}

export default function WorkflowStateDescriptionDialog({
  overlay,
  projectId,
  workflowState,
}: WorkflowStateDescriptionDialogProps) {
  const [fullScreen, setFullScreen] = useState(false)
  const getThemeColor = useGetThemeColor()

  const { hasEditingPermission } = usePermission()
  const canEdit = hasEditingPermission('edit_projects')

  return (
    <DescriptionDialog
      fullScreen={fullScreen}
      overlay={overlay}
      title={
        <Stack direction="row" spacing={0.5} alignItems="center">
          <div>{workflowState?.name || ''}</div>
          {canEdit && (
            <Tooltip title="Go to Workflow State Settings">
              <IconButton
                component={RouterLink}
                size="small"
                to={`/projects/${projectId}/settings/workflow-states/${workflowState?.id}`}
                target="_blank"
                sx={{
                  // These are workarounds so that spacing and hovering look the same
                  // as the other IconButton in the Stack since the sizes are different.
                  p: 1,
                  ml: '12px !important',
                }}
              >
                <Settings fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            size="small"
            onClick={() => {
              setFullScreen((prev) => !prev)
            }}
          >
            <Fullscreen />
          </IconButton>
        </Stack>
      }
      subtitle={
        <Box
          sx={{
            borderRadius: 1,
            background: getThemeColor(workflowState?.color),
            height: 4,
            width: 100,
          }}
        />
      }
      description={workflowState?.description || ''}
      onExited={() => {
        fullScreen && setFullScreen(false)
      }}
    />
  )
}
