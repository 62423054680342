// Credit: https://github.com/uidotdev/usehooks/blob/main/index.js

import { useCallback, useEffect, useSyncExternalStore } from 'react'

// workaround for typescript error "Type 'T & Function' has no call signatures." https://github.com/microsoft/TypeScript/issues/37663#issuecomment-856961045
type Fn = (...args: unknown[]) => unknown
function getNexState<
  Provided,
  T = Provided extends Fn ? ReturnType<Provided> : Provided,
>(valueOrFn: Provided, store: string): T {
  return typeof valueOrFn === 'function' ? valueOrFn(store) : valueOrFn
}

const dispatchStorageEvent = (key: string, newValue?: string | null) => {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }))
}

const setLocalStorageItem = <T>(key: string, value: T) => {
  const stringifiedValue = JSON.stringify(value)
  window.localStorage.setItem(key, stringifiedValue)
  dispatchStorageEvent(key, stringifiedValue)
}

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key)
  dispatchStorageEvent(key, null)
}

const getLocalStorageItem = (key: string) => {
  return window.localStorage.getItem(key)
}

const useLocalStorageSubscribe = (callback: () => void) => {
  window.addEventListener('storage', callback)
  return () => window.removeEventListener('storage', callback)
}

const getLocalStorageServerSnapshot = () => {
  throw Error('useLocalStorage is a client-only hook')
}

export default function useLocalStorage<T>(key: string, initialValue: T) {
  const getSnapshot = () => getLocalStorageItem(key)

  const store = useSyncExternalStore(
    useLocalStorageSubscribe,
    getSnapshot,
    getLocalStorageServerSnapshot,
  )

  const setState = useCallback(
    (v: T | ((val: T) => T)) => {
      try {
        const nextState = getNexState(v, store ? JSON.parse(store) : store)
        if (nextState === undefined || nextState === null) {
          removeLocalStorageItem(key)
        } else {
          setLocalStorageItem(key, nextState)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
      }
    },
    [key, store],
  )

  useEffect(() => {
    if (
      getLocalStorageItem(key) === null &&
      typeof initialValue !== 'undefined'
    ) {
      setLocalStorageItem(key, initialValue)
    }
  }, [key, initialValue])

  return [store ? (JSON.parse(store) as T) : initialValue, setState] as const
}
