import { ReactNode } from 'react'
import { useTopBarPortalContext } from './TopBarPortalProvider'
import { Box } from '@mui/material'
import { createPortal } from 'react-dom'

type TopBarNavigationProps = {
  children: ReactNode
}

export default function TopBarNavigation({ children }: TopBarNavigationProps) {
  const { portalNode } = useTopBarPortalContext()
  return portalNode ? createPortal(<Box>{children}</Box>, portalNode) : null
}
