import { forwardRef } from 'react'
import { Box, BoxProps } from '@mui/material'
import { DocumentPage } from '@/types/documents'

type DocumentThumbnailProps = {
  image_urls: DocumentPage['image_urls']
} & Omit<BoxProps, 'component'>

const DocumentThumbnail = forwardRef(function DocumentThumbnail(
  { image_urls, sx, ...rest }: DocumentThumbnailProps,
  ref,
) {
  return (
    <Box
      ref={ref}
      component="img"
      loading="lazy"
      src={image_urls.thumbnail}
      onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = event.target as HTMLImageElement
        target.src = image_urls.full
        target.onerror = null
      }}
      sx={{
        height: 100,
        minHeight: 100,
        ...sx,
      }}
      {...rest}
    />
  )
})

export default DocumentThumbnail
