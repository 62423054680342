import { forwardRef } from 'react'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

export type CurrencyFieldProps = {
  currencySymbol?: string
  value: number | string
  onChange?: (newValue: number | '') => void // allowing to pass an empty string so final value can be restored
} & Omit<Partial<NumberFormatProps>, 'onChange' | 'size'> &
  Omit<TextFieldProps, 'onChange' | 'value'>

const CurrencyField = forwardRef(function CurrencyField(
  {
    currencySymbol,
    value,
    InputProps = {},
    onChange,
    ...rest
  }: CurrencyFieldProps,
  ref,
) {
  return (
    <NumberFormat
      // @ts-expect-error -- Ignore for now
      customInput={TextField}
      isNumericString
      thousandSeparator
      value={value}
      decimalScale={2}
      fixedDecimalScale
      onChange={(e) => {
        if (onChange) {
          const newValue = parseFloat(e.target.value.replaceAll(',', ''))
          onChange(isNaN(newValue) ? '' : newValue)
        }
      }}
      InputProps={{
        startAdornment: currencySymbol ? (
          <InputAdornment position="start">{currencySymbol}</InputAdornment>
        ) : null,
        ...InputProps,
      }}
      inputRef={ref}
      {...rest}
    />
  )
})

export default CurrencyField
