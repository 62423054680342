import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { OverlayState } from '@/hooks/useOverlay'
import { Button, CircularProgress, Link, Stack } from '@mui/material'
import DataListColumnFields from './DataListColumnFields'
import { PixyDocsForm } from '@/components/forms'
import { useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { DataListEntry } from '@/types/data-lists'
import { useGetDataList } from '@/service-library/hooks/data-lists'
import { useCreateDataListEntry } from '@/service-library/hooks/data-list-entries'

type AddListItemDialogProps = {
  overlay: OverlayState
  dataListId: string
  parentDataListId: string
  lookupDataListColumnId: string
  fieldValue?: string
  onAddItem?: (newEntry: DataListEntry) => void
}

export default function AddListItemDialog({
  overlay,
  dataListId,
  parentDataListId,
  lookupDataListColumnId,
  fieldValue,
  onAddItem,
}: AddListItemDialogProps) {
  const { dataList: parentDataList, isLoading } = useGetDataList({
    id: parentDataListId,
    filters: {
      fields__include: 'data_list_columns',
      data_list_columns__fields__include: 'data_list_column_type',
    },
    enabled: overlay.isOpen,
  })

  const { createDataListEntry } = useCreateDataListEntry({
    filters: {
      fields__include: 'data_list_entry_cells',
      data_list_entry_cells__fields__include: 'data_list_entry_cell_values',
    },
    onSuccess: (newEntry) => {
      showSuccessSnackbar('Item Added to List')
      onAddItem?.(newEntry)
    },
    onError: (error) => {
      console.error(error)
      showErrorSnackbar('Failed to add item to list. Please try again later.')
    },
  })

  // Since the form could contain multiple values, not worth typing here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSubmit(values: Record<string, any>) {
    overlay.close()

    const entryId = generateUuid()

    const data_list_entry_cells = Object.entries(values).map(
      ([data_list_column_id, value]) => {
        const cellId = generateUuid()
        return {
          id: cellId,
          data_list_column_id,
          data_list_entry_id: entryId,
          data_list_entry_cell_values: value
            ? [
                {
                  id: generateUuid(),
                  value,
                  data_list_entry_cell_id: cellId,
                  sort_order: 0,
                },
              ]
            : [],
          sort_order: 0,
        }
      },
    )

    return createDataListEntry({
      data_list_id: dataListId,
      id: entryId,
      data_list_entry_cells,
      sort_order: 0,
    })
  }

  const { data_list_columns = [] } = parentDataList || {}

  const visibleColumns = useMemo(() => {
    if (!parentDataList) return []
    return data_list_columns
      .filter(({ is_system_managed }) => !is_system_managed)
      .toSorted((a, b) => a.sort_order - b.sort_order)
  }, [data_list_columns, parentDataList])

  const defaultValues = useMemo(() => {
    if (!parentDataList) return {}
    return visibleColumns.reduce((acc, { id, data_list_column_type }) => {
      const type = data_list_column_type.postgres_data_type
      let defaultValue: string | null = ''
      if (type === 'date' || type === 'datetime' || type === 'time') {
        defaultValue = null
      }
      if (id === lookupDataListColumnId && fieldValue) {
        defaultValue = fieldValue
      }
      return {
        ...acc,
        [id]: defaultValue,
      }
    }, {})
  }, [fieldValue, lookupDataListColumnId, parentDataList, visibleColumns])

  const methods = useForm<Record<string, unknown>>({
    defaultValues,
  })
  const { reset, watch } = methods

  const firstFieldValue = visibleColumns[0]
    ? watch(visibleColumns[0].id)
    : undefined

  useEffect(() => {
    if (parentDataList) {
      reset(defaultValues)
    }
  }, [parentDataList, defaultValues, reset])

  return (
    <Dialog
      {...overlay}
      title="Add Item to List"
      subtitle={
        <>
          List:{' '}
          <Link
            href={`/settings/lists/${parentDataList?.id}?tab=data`}
            target="_blank"
          >
            {parentDataList?.name}
          </Link>
        </>
      }
      maxWidth="xs"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PixyDocsForm methods={methods} onSubmit={onSubmit}>
          <DialogContent>
            {isLoading && (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            )}
            {/* Checking for firstFieldValue so we don't get errors regarding going from uncontrolled to controlled.  */}
            {!isLoading && parentDataList && firstFieldValue !== undefined && (
              <DataListColumnFields visibleColumns={visibleColumns} />
            )}
          </DialogContent>
          <DialogFooter>
            <Button variant="text" onClick={overlay.close}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={Object.values(methods.getValues()).every(
                (value) => !value,
              )}
            >
              Save
            </Button>
          </DialogFooter>
        </PixyDocsForm>
      </LocalizationProvider>
    </Dialog>
  )
}
