import { useMemo } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { MRT_VisibilityState } from 'material-react-table'
import { Stack, Typography } from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import {
  createDataLineageColumn,
  getBaseGrid,
  getNonGridFields,
  sortBySortOrder,
} from '@/utils/field-utils'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import { useColumnVisibilityContext } from '@/components/workflows/ColumnVisibilityProvider'
import GridFieldsFilter from './GridFieldsFilter'
import MetadataFieldsFilter, { MetadataField } from './MetadataFieldsFilter'
import { useGetProjectGrids } from '@/service-library/hooks/project-grids'

export type OnFieldChange = ({
  fields,
  fieldId,
  checked,
}: {
  fields?: ProjectGridField[]
  fieldId: string
  checked: boolean
}) => void

export type OnGroupChange = ({
  fields,
  checked,
  isGridField,
}: {
  fields: (ProjectGridField | MetadataField)[]
  checked: boolean
  isGridField?: boolean
}) => void

export const getCheckedValues = (
  columnVisibility: MRT_VisibilityState,
  fields: (ProjectGridField | MetadataField)[],
) => {
  const visibilityValues = fields.map((field) => !!columnVisibility[field.id])
  const isChecked = visibilityValues.every((value) => value)
  const isNotChecked = visibilityValues.every((value) => !value)
  return {
    checked: isChecked || !isNotChecked,
    indeterminate: !isChecked && !isNotChecked,
    fieldsChecked: visibilityValues.filter((value) => value).length,
  }
}

type AllFieldsFilterProps = {
  showAllFields?: boolean
}

export default function AllFieldsFilter({
  showAllFields = false,
}: AllFieldsFilterProps) {
  const {
    columnVisibility,
    handleColumnVisibilityChange,
    setColumnVisibility,
  } = useColumnVisibilityContext()
  const { project } = useProjectContext()
  const { projectGrids } = useGetProjectGrids({
    filters: {
      limit: '1000',
      project_id: project.id,
      fields__include: 'project_grid_fields,sub_project_grid_fields',
      project_grid_fields__fields__include: 'project_grid_field_type',
    },
    initialListData: sortBySortOrder(project.project_grids || []),
    refetchOnWindowFocus: true,
  })
  const baseGrid = getBaseGrid(projectGrids)

  const gridsCopy = useMemo(
    () => (projectGrids && baseGrid?.id ? cloneDeep(projectGrids) : []),
    [baseGrid?.id, projectGrids],
  )

  const onGroupChange: OnGroupChange = ({
    fields,
    checked,
    isGridField = true,
  }) => {
    handleColumnVisibilityChange((prev) => {
      const fieldsVisibility = fields.reduce((acc, { id }) => {
        if (isGridField) {
          const columns = createDataLineageColumn({
            fieldId: id,
            gridValue: checked,
          })
          return { ...acc, ...columns }
        }
        return { ...acc, [id]: checked }
      }, {})
      return { ...prev, ...fieldsVisibility }
    })
  }

  const onFieldChange: OnFieldChange = ({ fields, fieldId, checked }) => {
    handleColumnVisibilityChange((prev) => {
      if (
        !checked ||
        Object.hasOwn(prev, fieldId) ||
        fieldId.endsWith('ColId') // If a metadata field was added after a view got saved, it won't be in the columnVisibility
      ) {
        return { ...prev, [fieldId]: checked }
      }
      // MaterialReactTable will take as visible any field that exists and does't have a specific value. This makes
      // it so that the first time a field is selected, the other fields are updated accordingly.
      const fieldsVisibility = fields?.reduce((acc, { id }) => {
        const columns = createDataLineageColumn({
          fieldId: id,
          fieldValue: id === fieldId ? checked : Object.hasOwn(prev, id),
        })
        return {
          ...acc,
          ...columns,
        }
      }, {})
      return { ...prev, ...fieldsVisibility }
    })
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack spacing={0.5}>
        <Typography variant="body1">Document</Typography>
        <MetadataFieldsFilter
          columnVisibility={columnVisibility}
          onFieldChange={onFieldChange}
        />
      </Stack>

      <Stack spacing={0.5}>
        {showAllFields && <Typography variant="body1">Fields</Typography>}

        {showAllFields &&
          gridsCopy.map((grid) => {
            grid.project_grid_fields =
              grid.parent_project_grid_id === null
                ? getNonGridFields(grid.project_grid_fields)
                : grid.project_grid_fields
            //TODO: Remove sorting for when the backend returns data sorted
            grid.project_grid_fields = sortBySortOrder(grid.project_grid_fields)
            const { checked, indeterminate, fieldsChecked } = getCheckedValues(
              columnVisibility,
              grid.project_grid_fields,
            )
            return grid.project_grid_fields.length > 0 ? (
              <GridFieldsFilter
                key={grid.id}
                grid={grid}
                fieldsChecked={fieldsChecked}
                checked={checked}
                indeterminate={indeterminate}
                onGridChange={onGroupChange}
                onFieldChange={onFieldChange}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                handleColumnVisibilityChange={handleColumnVisibilityChange}
              />
            ) : null
          })}
      </Stack>
    </Stack>
  )
}
