import { createContext, ReactNode, useContext } from 'react'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'

type OrganizationsContextValue = ReturnType<typeof useAllOrganizations>

const OrganizationsContext = createContext<OrganizationsContextValue>(
  {} as OrganizationsContextValue,
)

export const useOrganizationsContext = () => useContext(OrganizationsContext)

type OrganizationsProviderProps = {
  orgId: string
  children: ReactNode
}

export default function OrganizationsProvider({
  orgId,
  children,
}: OrganizationsProviderProps) {
  const query = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: orgId,
    },
  })

  return (
    <OrganizationsContext.Provider value={query}>
      {children}
    </OrganizationsContext.Provider>
  )
}
