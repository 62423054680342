import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import useMutation from './useMutation'
import { LogixBoard, LogixHandle } from '@/types/logix'
import { bulkUpdateCreateLogixHandles } from '../logix'
import { getQueryKey } from './useLogixBoard'
import { useBoardContext } from '@/components/flow-editor/BoardProvider'

type UseBulkUpdateCreateLogixHandlesOptions = UseMutationOptions<
  LogixHandle[],
  unknown,
  LogixHandle[],
  { previous: LogixBoard }
>

export default function useBulkUpdateCreateLogixHandles(
  options: UseBulkUpdateCreateLogixHandlesOptions = {},
) {
  const board = useBoardContext()
  const queryClient = useQueryClient()

  const query = useMutation<
    LogixHandle[],
    unknown,
    LogixHandle[],
    { previous: LogixBoard }
  >({
    sideEffectQueryKeys: [['logix-board']],
    mutationFn: (logixNodeUpdates: LogixHandle[]) =>
      bulkUpdateCreateLogixHandles(logixNodeUpdates).then((res) => res.data),
    onMutate: async () => {
      const queryKey = getQueryKey(board.id)

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey) as LogixBoard

      return { previous }
    },
    onError: (err, data, context) => {
      const queryKey = getQueryKey(board.id)
      queryClient.setQueryData(queryKey, context?.previous)
    },
    onSettled: () => {
      const queryKey = getQueryKey(board.id)
      queryClient.invalidateQueries({ queryKey })
    },
    ...options,
  })

  return {
    ...query,
    bulkUpdateCreateLogixHandles: query.mutate,
  }
}
