import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import useLocalStorage from '@/hooks/useLocalStorage'

const tabs = {
  dashboard: 'Dashboard',
  documents: 'Documents',
  data: 'Data',
  settings: 'Settings',
}

export default function UserPreferences() {
  const [projectPreferredTab, setProjectPreferredTab] = useLocalStorage(
    'project-preferred-tab',
    'documents',
  )

  return (
    <Stack spacing={4} sx={{ height: '100%' }}>
      <Typography variant="h4">My Preferences</Typography>
      <Stack spacing={2}>
        <div>
          <Typography variant="body1">Default Tab</Typography>
          <Typography variant="body2" color="text.secondary">
            Which tab is selected by default when entering a project.
          </Typography>
        </div>
        <RadioGroup
          value={projectPreferredTab}
          onChange={(e) => {
            setProjectPreferredTab(e.target.value)
          }}
          sx={{ ml: '5px !important' }}
        >
          {Object.entries(tabs).map(([key, value]) => (
            <FormControlLabel
              key={key}
              value={key}
              control={
                <Radio color="neutral" sx={{ padding: 0.5 }} size="small" />
              }
              label={value}
              sx={{
                columnGap: 0.5,
                width: 'fit-content',
              }}
            />
          ))}
        </RadioGroup>
      </Stack>
    </Stack>
  )
}
