import { ReactNode } from 'react'
import { Box, Stack } from '@mui/material'
import { DatumValue, LineSvgProps, Point, ResponsiveLine } from '@nivo/line'
import TooltipCard from './TooltipCard'
import constants from './chart-constants'

export type LineChartProps = {
  dataHasColor?: boolean
  height?: number | string
  label?: ReactNode
  tooltipContent?: (
    point: Point,
    stackedPoints: {
      id: string | number
      color?: string
      x?: DatumValue | null | undefined
      y?: DatumValue | null | undefined
    }[],
  ) => ReactNode
  xLegend?: string
  yLegend?: string
} & Omit<LineSvgProps, 'width' | 'height'>

export default function LineChart({
  data,
  dataHasColor,
  height = 420,
  label,
  tooltip,
  tooltipContent,
  axisBottom,
  axisLeft,
  margin,
  xFormat,
  xLegend,
  yFormat,
  yLegend,
  ...props
}: LineChartProps) {
  const noDataProps =
    data.length === 0
      ? {
          gridXValues: 0,
          gridYValues: 1,
          xScale: { min: 0, max: 1, type: 'linear' as const },
          yScale: { min: 0, max: 1, type: 'linear' as const },
          markers: [
            {
              axis: 'y' as const,
              value: 0.5,
              lineStyle: {
                stroke: 'transparent',
              },
              legend: 'No Data',
              textStyle: {
                fill: '#ffffff',
              },
              legendPosition: 'top' as const,
              legendOrientation: 'horizontal' as const,
            },
          ],
        }
      : {}

  return (
    <Box sx={{ height }}>
      {label && (
        <Stack direction="row" spacing={0.5}>
          {label}
        </Stack>
      )}
      {/* ResponsiveLine needs to be wrapped by a container with a set height */}
      <ResponsiveLine
        data={data}
        colors={dataHasColor ? (data) => data.color : undefined}
        margin={{
          top: constants.MARGIN_TOP,
          right: constants.MARGIN_RIGHT,
          bottom: xLegend
            ? constants.MARGIN_BOTTOM_WITH_LEGEND
            : constants.MARGIN_BOTTOM,
          left: yLegend
            ? constants.MARGIN_LEFT_WITH_LEGEND
            : constants.MARGIN_LEFT,
          ...margin,
        }}
        xFormat={xFormat}
        axisBottom={{
          format: xFormat,
          legend: xLegend,
          legendPosition: 'middle',
          legendOffset: 40,
          ...axisBottom,
          tickValues: data.length ? axisBottom?.tickValues : [0, 1],
        }}
        yFormat={yFormat}
        axisLeft={{
          format: yFormat,
          legend: yLegend,
          legendPosition: 'middle',
          legendOffset: -50,
          ...axisLeft,
          tickValues: data.length ? axisLeft?.tickValues : [0, 1],
        }}
        pointSize={9}
        tooltip={
          tooltipContent
            ? ({ point }) => {
                const allPointsData = data.flatMap(({ id, color, data }) =>
                  data.map((node) => ({ ...node, id, color })),
                )
                const stackedPoints = allPointsData.filter(
                  (pointData) =>
                    pointData.x == point.data.x && pointData.y == point.data.y,
                )
                return (
                  <TooltipCard>
                    {tooltipContent(point, stackedPoints)}
                  </TooltipCard>
                )
              }
            : tooltip
        }
        theme={{
          axis: {
            legend: {
              text: {
                fontSize: 14,
                fill: '#d2d2d2',
              },
            },
            ticks: {
              text: {
                fill: '#b3b3b3',
              },
            },
          },
          crosshair: {
            line: {
              stroke: '#9d9d9d',
            },
          },
          grid: {
            line: {
              stroke: '#686868',
            },
          },
        }}
        useMesh
        {...noDataProps}
        {...props}
      />
    </Box>
  )
}
