import { getDocumentsForSet } from '@/services/documents'
import { UseQueryOptions } from '@tanstack/react-query'
import { Document } from '@/types/documents'
import PaginatedResponse from '@/types/paginated-response'
import useQuery from './useQuery'

type DocumentSetOptions = {
  documentIds: string[]
  batchLimit?: number
  fieldsOnly?: string
  flagCodes?: string[]
  includeWorkflowStates?: boolean
}

type UseDocumentSetOptions = DocumentSetOptions & UseQueryOptions<Document[]>

type SplitFetchResponse = {
  data: PaginatedResponse<Document>
}

const getDocumentsInMultipleRequests = (
  documentIds: string[],
  options: Record<string, unknown>,
  batchLimit: number,
): Promise<SplitFetchResponse> => {
  const groups: string[][] = []
  if ('documentIds' in options) {
    delete options.documentIds
  }

  let adjustedBatchLimit = batchLimit

  // We reduce the batch limit if we have other filters since that increases the characters in the URL
  Object.values(options).forEach((value) => {
    if (value && Array.isArray(value) && value.length > 0) {
      adjustedBatchLimit -= value.length
    }
  })

  for (let i = 0; i < documentIds.length; i += adjustedBatchLimit) {
    groups.push(documentIds.slice(i, i + adjustedBatchLimit))
  }

  return new Promise((resolve, reject) => {
    Promise.all(
      groups.map((group) =>
        getDocumentsForSet({
          ...options,
          documentIds: group,
          limit: `${group?.length}`, // Convert to string
        }),
      ),
    )
      .then((responses) => {
        const combinedResponse = responses.reduce<SplitFetchResponse>(
          (acc, response) => {
            acc.data.results.push(...response.data.results)
            return acc
          },
          { data: { next: null, previous: null, results: [] } },
        )
        resolve(combinedResponse)
      })
      .catch(reject)
  })
}

export function getQueryKey({
  documentIds,
  fieldsOnly,
  flagCodes,
  includeWorkflowStates,
}: DocumentSetOptions) {
  const queryKey = ['documents', documentIds]
  if (includeWorkflowStates) queryKey.push('include-workflow-states')

  if (fieldsOnly) queryKey.push(fieldsOnly)

  if (flagCodes) {
    queryKey.push(...flagCodes)
  }

  return queryKey
}

export default function useDocumentSet({
  documentIds,
  fieldsOnly,
  batchLimit = 200,
  flagCodes,
  includeWorkflowStates = false,
  ...options
}: UseDocumentSetOptions) {
  const commonOptions = {
    documentIds,
    includeWorkflowStates,
    flagCodes,
    fieldsOnly,
  }
  const queryKey = getQueryKey({ ...commonOptions })
  const query = useQuery({
    queryKey,
    queryFn: () =>
      (documentIds?.length > batchLimit
        ? getDocumentsInMultipleRequests(documentIds, commonOptions, batchLimit)
        : getDocumentsForSet({
            limit: `${documentIds?.length}`, // Convert to string
            ...commonOptions,
          })
      ).then(({ data }) => data.results),
    enabled: options.enabled && documentIds?.length > 0,
    ...options,
  })

  return {
    documents: query.data,
    queryKey,
    ...query,
  }
}
