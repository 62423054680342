import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'

type BreadcrumbPortalContextValue = [
  Element | null,
  Dispatch<SetStateAction<Element | null>>,
]

const BreadcrumbPortalContext = createContext<BreadcrumbPortalContextValue>(
  [] as unknown as BreadcrumbPortalContextValue,
)

export const useBreadcrumbPortalContext = () =>
  useContext(BreadcrumbPortalContext)

export default function BreadcrumbPortalProvider({
  children,
}: {
  children: ReactNode
}) {
  const portalNodeState = useState<Element | null>(
    document.createElement('div'),
  )

  return (
    <BreadcrumbPortalContext.Provider value={portalNodeState}>
      {children}
    </BreadcrumbPortalContext.Provider>
  )
}
