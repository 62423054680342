import { ListItemButton, Typography, Stack, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'

type TopBarNavigationItemProps = {
  label: string
  to: string
  isSuperUserOnly?: boolean
  selected: boolean
  keepQueryParams?: boolean
}

export default function HorizontalTab({
  label,
  to,
  isSuperUserOnly,
  selected,
  keepQueryParams = false,
}: TopBarNavigationItemProps) {
  let color = isSuperUserOnly ? SUPER_USER_ONLY_COLOR : 'text.secondary'
  if (selected && !isSuperUserOnly) color = 'primary.main'

  const theme = useTheme()

  return (
    <ListItemButton
      component={Link}
      to={`${to}?${
        keepQueryParams ? new URLSearchParams(window.location.search) : ''
      }`}
      sx={{
        borderRadius: 2,
        py: 0.5,
        px: 1,
        color,
        textDecoration: 'none',
        '&:visited': {
          textDecoration: 'none',
        },
        '&::before': selected
          ? {
              content: '""',
              height: 3,
              width: 'calc(100% - 16px)',
              background: theme.palette.primary.main,
              position: 'absolute',
              bottom: 0,
              borderRadius: 3,
              transition: 'all 0.2s ease-out',
            }
          : undefined,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1">{label}</Typography>
      </Stack>
    </ListItemButton>
  )
}
