import { Card } from '@mui/material'
import FieldCountComparisonChart from '../data-visualization/FieldCountComparisonChart'
import { ProjectModelVersion } from '@/types/project-models'

type FieldCountComparisonChartCardProps = {
  currentVersion: ProjectModelVersion
}

export default function FieldCountComparisonChartCard({
  currentVersion,
}: FieldCountComparisonChartCardProps) {
  return (
    <Card
      sx={{ borderRadius: 2, py: 2, px: 3, overflow: 'visible' }}
      elevation={0}
    >
      <FieldCountComparisonChart height={240} modelVersion={currentVersion} />
    </Card>
  )
}
