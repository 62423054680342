import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

const filters = [
  'from',
  'to',
  'grouped_by',
  'project_id',
  'project_model_id',
  'show_by',
  'org_id',
]

export default function useMetricsSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()

  const filterValues: Record<string, string> = filters.reduce(
    (acc, filter) => ({ ...acc, [filter]: searchParams.get(filter) }),
    {},
  )

  const updateValues = useCallback(
    (keyValuePairs: Record<string, string>) => {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        Object.entries(keyValuePairs).forEach(([key, value]) => {
          if (value === 'all' || value === '') {
            newParams.delete(key)
          } else {
            newParams.set(key, value)
          }
        })
        return newParams
      })
    },
    [setSearchParams],
  )

  return {
    values: filterValues,
    updateValues,
  }
}
