import { useTheme } from '@mui/material'
import { orange } from '@mui/material/colors'

export default function Logo() {
  const theme = useTheme()

  const isDark = theme.palette.mode === 'dark'
  const zeraColor = isDark
    ? theme.palette.neutral.main
    : theme.palette.grey[800]
  const brandColor = isDark ? theme.palette.orange.dark : orange[600]

  return (
    <div
      style={{ margin: '-13px -16px -24px', height: 50, overflow: 'hidden' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 494.8 184.5"
        width="160"
      >
        <g>
          <g>
            <g>
              <g>
                <polygon
                  fill={brandColor}
                  points="159.2,118 143.3,97.4 127.4,118 120.3,118 139.7,92.7 120.3,67.6 127.5,67.6 143.3,88.1
					159.1,67.6 166.3,67.6 146.9,92.7 166.4,118 				"
                />
                <path
                  fill={brandColor}
                  d="M106.5,92.8c0-15.2-12.3-27.5-27.5-27.5S51.5,77.6,51.5,92.8l0,0l0,0l0,0l0,0v41.3h5.7v-24.5
					c5,6.5,12.9,10.7,21.8,10.7C94.2,120.3,106.5,108,106.5,92.8L106.5,92.8L106.5,92.8z M100.8,92.8L100.8,92.8
					c0,12-9.7,21.8-21.8,21.8c-12,0-21.8-9.7-21.8-21.8l0,0l0,0c0-12,9.7-21.8,21.8-21.8C91,71,100.8,80.8,100.8,92.8L100.8,92.8z"
                />
                <rect
                  x="110.9"
                  y="67.6"
                  fill={brandColor}
                  width="5.7"
                  height="50.5"
                />
                <path
                  fill={zeraColor}
                  d="M224.7,92.8c0,15.2,12.3,27.5,27.5,27.5s27.5-12.3,27.5-27.5c0-0.1,0-0.2,0-0.3V44.6h-9.2v27.7
					c-4.9-4.4-11.3-7-18.3-7C237,65.3,224.7,77.6,224.7,92.8z M233.9,92.8c0-10.1,8.2-18.3,18.3-18.3c10.1,0,18.3,8.1,18.3,18.2v0.1
					l0,0c0,10.1-8.2,18.3-18.3,18.3C242.1,111.2,233.9,103,233.9,92.8z"
                />
                <path
                  fill={zeraColor}
                  d="M312.5,65.3c-15.2,0-27.5,12.3-27.5,27.5s12.3,27.5,27.5,27.5S340,108,340,92.8S327.7,65.3,312.5,65.3z
					 M312.5,111.2c-10.1,0-18.3-8.2-18.3-18.3s8.2-18.3,18.3-18.3c10.1,0,18.3,8.2,18.3,18.3C330.9,103,322.7,111.2,312.5,111.2z"
                />
                <path
                  fill={brandColor}
                  d="M216.2,116.8V97.3V67.6h-5.7v29.7l0,0c0,9.5-7.8,17.3-17.3,17.3s-17.3-7.8-17.3-17.3l0,0V67.6h-5.7v29.7
					c0,12.7,10.3,23.1,23.1,23.1c6.9,0,13.1-3,17.3-7.9v4.3l0,0c0,9.5-7.8,17.3-17.3,17.3l0,0h-11.5v5.7h11.5l0,0
					C205.9,139.9,216.2,129.5,216.2,116.8L216.2,116.8z"
                />
                <path
                  fill={zeraColor}
                  d="M429.6,88.2L429.6,88.2L429.6,88.2h-16.1c-3.2,0-5.7-2.6-5.7-5.7c0-3.2,2.6-5.7,5.7-5.7l0,0h26.4v-9.2
					h-26.4c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9l0,0h16.1l0,0l0,0c3.2,0,5.7,2.6,5.7,5.7c0,3.2-2.6,5.7-5.7,5.7l0,0l0,0
					h-28.7v9.2h28.7l0,0l0,0c8.2,0,14.9-6.7,14.9-14.9C444.5,94.9,437.8,88.2,429.6,88.2z"
                />
                <path
                  fill={zeraColor}
                  d="M372.9,120.3c8.7,0,16.5-4.1,21.5-10.4l-7.2-5.6c-3.4,4.2-8.5,6.9-14.3,6.9c-10.1,0-18.3-8.2-18.3-18.3
					s8.2-18.3,18.3-18.3c5.8,0,10.9,2.7,14.3,6.9l7.2-5.6c-5-6.3-12.8-10.4-21.5-10.4c-15.2,0-27.5,12.3-27.5,27.5
					c0,1.4,0.1,2.8,0.3,4.2C347.7,110.2,359.1,120.3,372.9,120.3z"
                />
              </g>
            </g>
            <path
              fill={brandColor}
              d="M117.8,55.5c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4S117.8,53.3,117.8,55.5z"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
