import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { Edit } from '@mui/icons-material'
import { Button, Stack, TextField } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { useUpdateCurrentUser } from '@/service-library/hooks/users'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { useAuthentication } from '@/components/auth/AuthProvider'
import LargeHeading from '@/components/large-heading/LargeHeading'
import LabeledData from '@/components/labeled-data/LabeledData'
import UpdateEmailDialog from './UpdateEmailDialog'

export default function ProfileDetails() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { user, userQuery } = useAuthentication()
  const updateEmailOverlay = useOverlay()
  const [internalName, setInternalName] = useState<string>(user?.name as string)
  const [nameError, setNameError] = useState<string | null>(null)

  const { updateCurrentUser, isError } = useUpdateCurrentUser({
    detailQueryKey: userQuery.queryKey,
    onError: () => {
      showErrorSnackbar(
        'Unable to update your information. Please try again later.',
      )
    },
  })

  function validate(value: string) {
    if (!value.trim()) {
      return 'Name cannot be empty.'
    }
    return null
  }

  const debouncedUpdateUser = useMemo(
    () => debounce(updateCurrentUser, 250),
    [updateCurrentUser],
  )

  function handleChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    const { value } = event.target
    const error = validate(value)

    setInternalName(value)
    setNameError(error)

    if (error) {
      debouncedUpdateUser.cancel()
    } else if (value && user) {
      debouncedUpdateUser({ ...user, name: value.trim() })
    }
  }

  function handleBlur(
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    const { value } = event.target
    const error = validate(value)
    if ((error || isError) && user) {
      setInternalName(user.name)
      setNameError(null)
    }
  }

  useEffect(() => {
    if (state?.verifiedEmail) {
      showSuccessSnackbar('Email Verified')
      // We do this to clear state
      navigate(location.pathname, { replace: true })
    }
  }, [navigate, state?.verifiedEmail])

  if (!user) return null

  return (
    <Stack spacing={4} sx={{ height: '100%' }}>
      <LargeHeading heading={user?.name} subHeading="User Profile" />

      <TextField
        error={!!nameError}
        helperText={nameError}
        label="Name"
        sx={{ maxWidth: 350 }}
        value={internalName}
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <div>
        <LabeledData label="Email" data={user.email} />
        <Button
          onClick={updateEmailOverlay.open}
          variant="text"
          startIcon={<Edit fontSize="small" />}
          sx={{ my: 0.5, ml: -0.5 }}
        >
          Edit
        </Button>
      </div>

      {user.change_email_to && (
        <div>
          <LabeledData label="Unverified Email" data={user.change_email_to} />
          <Stack direction="row" spacing={1} sx={{ my: 0.5 }}>
            <Button component={Link} to="/verify-email" variant="text">
              Verify Email
            </Button>
            <Button onClick={() => updateCurrentUser(user)} variant="text">
              Resend Verification Email
            </Button>
          </Stack>
        </div>
      )}

      <UpdateEmailDialog
        detailQueryKey={userQuery.queryKey}
        user={user}
        overlay={updateEmailOverlay}
      />
    </Stack>
  )
}
