import { ReactNode, createContext, useContext } from 'react'
import useLogixEdges from '@/services/hooks/useLogixEdges'

type EdgesProviderValue = ReturnType<typeof useLogixEdges>

const EdgesContext = createContext<EdgesProviderValue>({} as EdgesProviderValue)

export const useEdgesContext = () => useContext(EdgesContext)

type EdgesProviderProps = {
  children: ReactNode
  boardId: string
}

export default function EdgesProvider({
  children,
  boardId,
}: EdgesProviderProps) {
  const query = useLogixEdges({ boardId })
  return (
    <EdgesContext.Provider value={{ ...query }}>
      {children}
    </EdgesContext.Provider>
  )
}
