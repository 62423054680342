import { AsYouType } from 'libphonenumber-js'
import { TextField, TextFieldProps } from '@mui/material'

export type PhoneNumberTextFieldProps = {
  value: string
  onChange?: (newValue: string | null) => void
} & Omit<TextFieldProps, 'onChange' | 'value'>

export default function PhoneNumberTextField({
  value,
  onChange,
  ...rest
}: PhoneNumberTextFieldProps) {
  return (
    <TextField
      type="tel"
      value={value}
      onChange={(e) => {
        const newValue = new AsYouType('US').input(e.target.value || '')
        if (onChange) {
          onChange(newValue)
        }
      }}
      onKeyDown={(e) => {
        const oldValue = (e.target as HTMLInputElement).value || ''
        // We do this to avoid the onChange removing the parenthesis and putting it back after formatting
        if (e.key === 'Backspace' && oldValue.endsWith(')') && onChange) {
          onChange(oldValue.slice(0, -1))
          e.stopPropagation()
        }
      }}
      {...rest}
    />
  )
}
