/* eslint-disable @typescript-eslint/no-explicit-any */
export default function constructQueryParams({
  queryParams = {},
  values,
  otherDependencyValues = {},
}: {
  queryParams: Record<
    string,
    | string
    | { static_value: string }
    | {
        dependent_property: string
        valid_property_values: string[]
        dependency_key: string
      }
  >
  values?: Record<string, any>
  otherDependencyValues?: Record<string, string>
}) {
  let hasAllDependencies = true

  const params = Object.entries(queryParams).reduce(
    (acc: Record<string, string>, [paramName, value]) => {
      // If it's just a string, it is a dependency on another param
      if (typeof value === 'string') {
        if (values && values[value]) {
          acc[paramName] = values[value]
        } else if (otherDependencyValues && otherDependencyValues[value]) {
          acc[paramName] = otherDependencyValues[value]
        } else {
          // If no value got assigned when it is a direct dependency, we set hasAllDependencies to false
          hasAllDependencies = false
        }
      }
      // If it's an object with a static_value, we just use that
      else if ('static_value' in value) {
        const { static_value } = value
        acc[paramName] = static_value
      }
      // If it's an object with a dependent_property, we check for that to add the query param
      else if (Object.hasOwn(value, 'dependent_property')) {
        const { dependent_property, valid_property_values, dependency_key } =
          value
        if (
          values &&
          otherDependencyValues &&
          valid_property_values.includes(values[dependent_property])
        ) {
          if (otherDependencyValues[dependency_key]) {
            acc[paramName] = otherDependencyValues[dependency_key]
          } else {
            // If otherDependencyValues doesn't have a value when the query is expecting one, we set hasAllDependencies to false
            hasAllDependencies = false
          }
        }
      }
      return acc
    },
    {},
  )

  return { queryParams: new URLSearchParams(params), hasAllDependencies }
}
