import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { DocumentChip } from '@/types/documents'
import { ProjectGridField } from '@/types/fields'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import FieldInfoDrawer from '@/components/validation//FieldInfoDrawer'
import { useDocumentChipsContext } from './DocumentChipsProvider'

export type SelectedField = {
  document_row_id: string
  field: ProjectGridField
}

type SelectedFieldContextValue = {
  selectedField: SelectedField | null
  selectedFieldChips: DocumentChip[]
  selectedFieldOverlay: OverlayState
  setSelectedField: Dispatch<SetStateAction<SelectedField | null>>
  isSelectedField: (
    fieldId: string | null,
    documentRowId: string | null,
  ) => boolean
}

const SelectedFieldContext = createContext<SelectedFieldContextValue>(
  {} as SelectedFieldContextValue,
)

export const useSelectedFieldContext = () => useContext(SelectedFieldContext)

type SelectedFieldProviderProps = {
  children: ReactNode
}

export default function SelectedFieldProvider({
  children,
}: SelectedFieldProviderProps) {
  const [selectedField, setSelectedField] = useState<SelectedField | null>(null)

  const selectedFieldOverlay = useOverlay({
    localStorageKey: 'selected-field-drawer-is-open',
  })

  const { getFieldChips } = useDocumentChipsContext()

  // Expensive check. Memoizing since this rerenders _a lot_.
  const selectedFieldChips = useMemo(
    () =>
      selectedField
        ? getFieldChips(selectedField.field.id, selectedField.document_row_id)
        : [],
    [getFieldChips, selectedField],
  )

  function isSelectedField(
    fieldId: string | null,
    documentRowId: string | null,
  ): boolean {
    return (
      selectedField?.document_row_id === documentRowId &&
      selectedField?.field.id === fieldId
    )
  }

  return (
    <SelectedFieldContext.Provider
      value={{
        selectedField,
        selectedFieldChips,
        selectedFieldOverlay,
        setSelectedField,
        isSelectedField,
      }}
    >
      {children}
      <FieldInfoDrawer
        overlay={selectedFieldOverlay}
        chips={selectedFieldChips}
        selectedField={selectedField}
      />
    </SelectedFieldContext.Provider>
  )
}
