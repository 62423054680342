import useGetThemeColor from '@/hooks/useGetThemeColor'
import { DocProjectTag } from '@/types/project-tags'
import { Box } from '@mui/material'

type DocProjectTagChip = {
  docProjectTag: DocProjectTag
  invisible?: boolean
}

export default function DocProjectTagChip({
  docProjectTag,
  invisible = false,
}: DocProjectTagChip) {
  const getThemeColor = useGetThemeColor()
  return (
    <Box
      sx={{
        p: 0.25,
        px: 0.5,
        background: getThemeColor(docProjectTag.project_tag?.color),
        fontSize: 10,
        borderRadius: 1,
        height: 'fit-content',
        fontWeight: 'bold',
        textWrap: 'nowrap',
        visibility: invisible ? 'hidden' : 'visible',
      }}
    >
      {docProjectTag.project_tag?.name}
    </Box>
  )
}
