import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import { CustomVariable } from '@/types/custom-variables'

const service = buildService<CustomVariable>({
  pathCategory: 'custom_variables',
})

export function useGetCustomVariable({
  id,
  ...options
}: UseGetDetailOptions<CustomVariable>) {
  const query = useGetDetail<CustomVariable>({
    id,
    queryKey: queryKeys.customVariables.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    customVariable: query.data,
    ...query,
  }
}

export function useGetCustomVariables({
  filters,
  ...options
}: UseGetListOptions<CustomVariable> = {}) {
  const query = useGetList<CustomVariable>({
    filters,
    queryKey: queryKeys.customVariables.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    customVariables: query.allData,
    ...query,
  }
}
