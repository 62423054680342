import dayjs, { Dayjs } from 'dayjs'
import {
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
} from '@mui/x-date-pickers'

type DatePickerProps = {
  onChange: (date: string) => void
  value: Date | Dayjs
  label?: string
  disabled?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  minDate?: Date | Dayjs
  maxDate?: Date | Dayjs
} & Omit<
  MUIDatePickerProps<Dayjs>,
  'minDate' | 'maxDate' | 'onChange' | 'renderInput' | 'value'
>

const getDayjsDate = (date: Dayjs | Date) => {
  return date instanceof Date ? dayjs(date) : date
}

export default function DatePicker({
  onChange,
  value,
  label,
  disabled,
  inputRef,
  minDate,
  maxDate,

  ...props
}: DatePickerProps) {
  const handleChange = (date: Dayjs | null) => {
    if (date?.isValid()) {
      onChange(date.format())
    }
  }

  return (
    <MUIDatePicker<Dayjs>
      value={getDayjsDate(value)}
      onChange={handleChange}
      label={label}
      disabled={disabled}
      inputRef={inputRef}
      minDate={minDate ? getDayjsDate(minDate) : undefined}
      maxDate={maxDate ? getDayjsDate(maxDate) : undefined}
      {...props}
      slotProps={{
        openPickerButton: { tabIndex: -1, size: 'small' },
        ...props.slotProps,
      }}
    />
  )
}
