import { ListItem, ListItemText, Typography } from '@mui/material'

type AuditEntryProps = {
  name: string
  description: string
  userName?: string
  created_at: string
}

export default function AuditEntry({
  name,
  description,
  userName,
  created_at,
}: AuditEntryProps) {
  return (
    <ListItem disablePadding>
      <ListItemText
        primary={name}
        secondary={
          <>
            <Typography variant="caption" color="text.secondary" component="p">
              {created_at} {userName && `- ${userName}`}
            </Typography>
            <Typography variant="caption" component="p">
              {description}
            </Typography>
          </>
        }
        primaryTypographyProps={{
          variant: 'body2',
        }}
        secondaryTypographyProps={{
          component: 'div',
        }}
      />
    </ListItem>
  )
}
