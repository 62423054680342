import useGetThemeColor from '@/hooks/useGetThemeColor'
import { useGetWorkflowState } from '@/service-library/hooks/workflow-states'
import { useGetWorkflow } from '@/service-library/hooks/workflows'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Box, Typography } from '@mui/material'
import PrimaryTextWrapper from './PrimaryTextWrapper'

type ManageWorkflowAssignmentDisplayProps = {
  action: WorkflowStateAction
}

const operationMap: Record<string, string> = {
  add: 'Add',
  move: 'Move',
  copy: 'Copy',
}

export default function ManageWorkflowAssignmentDisplay({
  action,
}: ManageWorkflowAssignmentDisplayProps) {
  const getThemeColor = useGetThemeColor()

  const { workflowState } = useGetWorkflowState({
    id: action.params.workflow_state_id,
    filters: {
      fields__only: 'id,name,color',
    },
  })

  const { workflow } = useGetWorkflow({
    id: action.params.workflow_id,
    filters: {
      fields__only: 'id,name',
    },
  })

  return (
    <Typography variant="body2" color="text.secondary">
      <PrimaryTextWrapper>
        {operationMap[action.params.operation]}
      </PrimaryTextWrapper>{' '}
      the document to{' '}
      <Box component="span" sx={{ color: getThemeColor(workflowState?.color) }}>
        {workflowState?.name}
      </Box>{' '}
      in the <PrimaryTextWrapper>{workflow?.name}</PrimaryTextWrapper> workflow.
    </Typography>
  )
}
