import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Card, Stack } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { Organization } from '@/types/organizations'
import { OrgCredential, OrgCredentialType } from '@/types/credentials'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useCreateOrgCredential,
  useUpdateOrgCredential,
} from '@/service-library/hooks/org-credentials'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import {
  FormAutocomplete,
  FormTextField,
  PixyDocsForm,
} from '@/components/forms'
import OrganizationPickerWithDialog from '@/components/organization-select/OrganizationPickerWithDialog'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'

type FormValues = {
  org: Organization
  name: string
  type: OrgCredentialType | null
}

type AddEditCredentialDialogProps = {
  overlay: OverlayState
  listQueryKey: QueryKey
  rootOrganization: Organization
  types: OrgCredentialType[]
  orgCredential?: OrgCredential
}

export default function AddEditCredentialDialog({
  overlay,
  listQueryKey,
  rootOrganization,
  types,
  orgCredential,
}: AddEditCredentialDialogProps) {
  const enableFetchingRef = useRef(false)
  const { organizations, isFetchingAll: isFetchingOrgs } = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootOrganization.id,
    },
    enabled: enableFetchingRef.current, // We don't want to make a call every time the dialog opens, just the first time
  })

  const defaultValues = useMemo(
    () => ({
      org: orgCredential?.org || rootOrganization,
      name: orgCredential?.name || '',
      type: orgCredential
        ? types.find(({ id }) => orgCredential?.type_id === id)
        : null,
    }),
    [orgCredential, rootOrganization, types],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const { formState, reset, setValue, watch } = methods

  const { createOrgCredential } = useCreateOrgCredential({
    listQueryKey,
    onSuccess: () => {
      showSuccessSnackbar('Credential Added')
    },
    onError: () => {
      showErrorSnackbar(
        'Failed to create or update credential. Please contact support.',
      )
    },
  })

  const { updateOrgCredential } = useUpdateOrgCredential({
    listQueryKey,
    onSuccess: () => {
      showSuccessSnackbar('Credential Updated')
    },
    onError: () => {
      showErrorSnackbar(
        'Failed to create or update credential. Please contact support.',
      )
    },
  })

  function handleCreateCredential(values: FormValues) {
    if (orgCredential) {
      updateOrgCredential({
        ...orgCredential,
        name: values.name.trim(),
        org_id: values.org.id,
      })
    } else {
      const orgCredentialType = values.type as OrgCredentialType
      createOrgCredential({
        id: generateUuid(),
        name: values.name.trim(),
        org_id: values.org.id,
        org: values.org,
        type_id: orgCredentialType.id,
        type: orgCredentialType,
        needs_auth: false, // integration services will set this to true if it needs it
        needs_secrets: true, // integration services will set this to false when it gets the tokens
      })
    }

    overlay.close()
  }

  const { isDirty, isValid } = formState

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  useEffect(() => {
    if (overlay.isOpen && !enableFetchingRef.current) {
      enableFetchingRef.current = true
    }
  }, [overlay.isOpen])

  return (
    <Dialog
      title={`${orgCredential ? 'Edit' : 'New'} Credential`}
      {...overlay}
      maxWidth="xs"
    >
      <PixyDocsForm methods={methods} onSubmit={handleCreateCredential}>
        <DialogContent>
          <Stack spacing={2}>
            <Card>
              <OrganizationPickerWithDialog
                currentOrganization={watch('org')}
                organizations={organizations}
                rootTreeOrgId={rootOrganization.id}
                isFetching={isFetchingOrgs}
                onSave={(organization) => {
                  setValue('org', organization, { shouldDirty: true })
                }}
              />
            </Card>

            <FormTextField name="name" label="Name" required />
            <FormAutocomplete
              disabled={!!orgCredential}
              required
              name="type"
              label="Type"
              options={types}
              isOptionEqualToValue={(option, value) => option?.id === value.id}
              disableClearable
              getOptionLabel={(option) => option?.name || ''}
            />
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button disabled={!isValid || !isDirty} type="submit">
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
