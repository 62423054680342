import { ReactNode } from 'react'
import Markdown from 'react-markdown'
import { Button, Link } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type DescriptionDialogProps = {
  overlay: OverlayState
  title: ReactNode
  subtitle: ReactNode
  description: string
  fullScreen?: boolean
  onExited?: () => void
}

export default function DescriptionDialog({
  overlay,
  title,
  subtitle,
  description,
  fullScreen,
  onExited,
}: DescriptionDialogProps) {
  return (
    <Dialog
      title={title}
      subtitle={subtitle}
      {...overlay}
      fullScreen={fullScreen}
      TransitionProps={{
        onExited: () => {
          onExited?.()
        },
      }}
    >
      <DialogContent>
        <Markdown
          components={{
            a: ({ children, href }) => (
              <Link color="primary" href={href} target="_blank">
                {children}
              </Link>
            ),
          }}
        >
          {description}
        </Markdown>
      </DialogContent>
      <DialogFooter>
        <Button onClick={overlay.close}>Close</Button>
      </DialogFooter>
    </Dialog>
  )
}
