import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
  ReactNode,
} from 'react'
import { ProjectModelVersion } from '@/types/project-models'
import { ExportFile } from '@/services/export'
import { useAuthentication } from '@/components/auth/AuthProvider'
import { WorkflowStateDocumentCount } from '@/types/document-workflow-states'

let tm: ReturnType<typeof setTimeout>

export type WebsocketData = {
  action: string
  updated_entity_ids: string[]
  error?: string
  model_version?: ProjectModelVersion
  export_response?: ExportFile
  workflow_state_counts?: WorkflowStateDocumentCount
}

type CallbackFunction = (data: WebsocketData) => void

type UseNotificationsProps = {
  keys: (string | undefined)[]
  callback: CallbackFunction
}

type NotificationProviderProps = {
  children: ReactNode
}

type NotificationProviderValue = {
  registerCallback: (callbackIds: string[], callback: CallbackFunction) => void
  unregisterCallback: (
    callbackIds: string[],
    callback: CallbackFunction,
  ) => void
}

const ping = (ws: WebSocket) => {
  if (ws.readyState !== ws.OPEN) return
  ws.send('{"route": "/ping"}')
  tm = setTimeout(() => ws.close(), 15000)
}

const pong = () => {
  clearTimeout(tm)
}

const NotificationContext = createContext<NotificationProviderValue>(
  {} as NotificationProviderValue,
)

function NotificationProvider({ children }: NotificationProviderProps) {
  const { authenticated, getIdToken } = useAuthentication()
  const attemptsCountRef = useRef(0)
  const [callbacks, setCallbacks] = useState<
    Record<string, CallbackFunction[]>
  >({})
  const [websocket, setWebsocket] = useState<WebSocket | null>(null)
  const openSocket = useCallback(() => {
    if (authenticated) {
      getIdToken((token) => {
        const newWebsocket = new WebSocket(
          import.meta.env.VITE_NOTIFICATION_SOCKET,
          token,
        )
        setWebsocket(newWebsocket)
      })
    }
  }, [authenticated, getIdToken])

  useEffect(() => {
    openSocket()
  }, [openSocket])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (websocket) {
      websocket.onopen = () => {
        interval = setInterval(() => ping(websocket), 20000)
      }

      websocket.onclose = (event) => {
        if (event.wasClean || attemptsCountRef.current < 2) {
          openSocket()
          !event.wasClean && attemptsCountRef.current++
        } else {
          attemptsCountRef.current = 0
        }
      }
    }

    return () => {
      clearInterval(interval)
      if (websocket?.readyState === 1) {
        websocket.close()
      }
    }
  }, [openSocket, websocket])

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data)
        const { action } = data as WebsocketData

        if (action === 'pong') {
          pong()
          return
        }

        data?.['updated_entity_ids']?.forEach((entityId: string) => {
          // Fire callback for each subscriber to each entityId
          callbacks[entityId]?.forEach((cb) => {
            cb(data)
          })
        })

        // Fire callback for each subscriber to the action
        callbacks[action]?.forEach((cb) => {
          cb(data)
        })
      }
    }
  }, [websocket, callbacks])

  const registerCallback = useCallback(
    (callbackIds: string[], callback: CallbackFunction) => {
      setCallbacks((callbacks) => {
        const newCallbacks = callbackIds.reduce(
          (acc, callbackId) => ({
            ...acc,
            [callbackId]: [...(callbacks[callbackId] || []), callback],
          }),
          {},
        )
        return {
          ...callbacks,
          ...newCallbacks,
        }
      })
    },
    [],
  )

  const unregisterCallback = useCallback(
    (callbackIds: string[], callback: CallbackFunction) => {
      setCallbacks((callbacks) => {
        const newCallbacks = {
          ...callbacks,
        }
        callbackIds.forEach((callbackId) => {
          newCallbacks[callbackId] = callbacks[callbackId].filter(
            (cb) => cb !== callback,
          )
        })

        return newCallbacks
      })
    },
    [],
  )

  const notificationHooks = useMemo(() => {
    return {
      registerCallback,
      unregisterCallback,
    }
  }, [registerCallback, unregisterCallback])

  return (
    <NotificationContext.Provider value={notificationHooks}>
      {children}
    </NotificationContext.Provider>
  )
}

function useNotifications({ keys, callback }: UseNotificationsProps) {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error(
      'useNotifications must be used within an NotificationProvider',
    )
  }

  const { registerCallback, unregisterCallback } = context

  // casting to string[] because ts keeps saying type is (string|undefined)[]
  const validKeys = useMemo(() => keys.filter((key) => key) as string[], [keys])

  useEffect(() => {
    if (validKeys.length) registerCallback(validKeys, callback)

    return () => {
      if (validKeys.length) unregisterCallback(validKeys, callback)
    }
  }, [callback, registerCallback, unregisterCallback, validKeys])

  return context
}

export { NotificationProvider, useNotifications }
