import { useEffect, useState } from 'react'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { Stack, Alert } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import NotFound from '@/pages/NotFound'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { notNull } from '@/utils/typescript-utils'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import Drawer from '@/components/drawer/Drawer'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import SettingsNavigation from '@/components/settings-navigation/SettingsNavigation'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'

const qualityRoutes: RouteDefinition[] = [
  {
    to: 'document-rules',
    label: 'Document Rules',
    keepQueryParams: true,
  },
  {
    to: 'field-rules',
    label: 'Field Rules',
    keepQueryParams: true,
  },
]

type RouteDefinition = {
  to: string
  label: string
  isSuperUserOnly?: boolean
  keepQueryParams?: boolean
}

export default function ProjectSettingsLayout() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const featureFlags = useFeatureFlagContext()
  const { hasEditingPermission } = usePermission()
  const { project } = useProjectContext()
  const [demoMode] = useDemoModeContext()
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const [errors, setErrors] = useState<string[] | null>(null)
  const drawerOverlay = useOverlay({ initiallyOpen: true })
  const isSuperUser = useIsSuperUser()

  const routes: RouteDefinition[] = [
    {
      to: 'general',
      label: 'General',
      keepQueryParams: true,
    },
    {
      to: 'sources',
      label: 'Sources',
      keepQueryParams: true,
    },
    {
      to: 'fields',
      label: 'Fields',
      keepQueryParams: true,
    },
    {
      to: 'integrations',
      label: 'Integrations',
      keepQueryParams: true,
    },
    !demoMode && isSuperUser
      ? {
          to: 'advanced-settings',
          label: 'Advanced Settings',
          keepQueryParams: true,
          isSuperUserOnly: true,
        }
      : null,
  ].filter(Boolean) as RouteDefinition[]

  const workflowRoutes: RouteDefinition[] = [
    {
      to: 'workflow-general',
      label: 'General',
      keepQueryParams: true,
    },
    {
      to: 'workflow-states',
      label: 'Workflow States',
      keepQueryParams: true,
    },
    selectedWorkflow.project_model_id
      ? {
          to: 'model-configuration',
          label: 'Model Configuration',
          keepQueryParams: true,
        }
      : null,
  ].filter(notNull)

  const canEditProject = hasEditingPermission('edit_projects', project.org_id)

  // We want to keep the query params to keep the workflow id, but we don't
  // want to keep the tab query param when not in the sources tab
  useEffect(() => {
    if (searchParams.get('tab') && !pathname.includes('sources')) {
      setSearchParams((prev) => {
        prev.delete('tab')
        return prev
      })
    }
  }, [pathname, searchParams, setSearchParams])

  if (!canEditProject) return <NotFound />

  return (
    <>
      <ProjectBreadcrumb
        label="Settings"
        url={`/projects/${project.id}/settings/general`}
      />
      <Drawer overlay={drawerOverlay} size="sm" transparent>
        <SettingsNavigation label="Project Settings" routes={routes} />
        {featureFlags.quality_control_ui && (
          <SettingsNavigation label="Quality Control" routes={qualityRoutes} />
        )}

        <SettingsNavigation
          label="Workflow Settings"
          routes={workflowRoutes}
          subHeader={selectedWorkflow.name}
        />
      </Drawer>

      <Stack direction="column" p={2} pt={3} width="100%" flexGrow={1}>
        {errors && <Alert severity="error">{errors}</Alert>}
        <Outlet
          context={{
            setWizardErrors: setErrors,
            isSettings: true,
          }}
        />
      </Stack>
    </>
  )
}
