import { Dialog, DialogContent, DialogFooter } from '../dialog'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import CodeEditor from './CodeEditor'
import { ReactNode, useState } from 'react'

type CodeEditorDialogProps = {
  overlay: OverlayState
  title: string
  description?: ReactNode
  value: string
  setValue: (value: string) => void
  onSave: (value: string) => void
  mode?: string
  characterLimit?: number
  wrapEnabledInitially?: boolean
}

export default function CodeEditorDialog({
  overlay,
  title,
  description,
  value,
  setValue,
  onSave,
  mode,
  characterLimit = Infinity,
  wrapEnabledInitially = false,
}: CodeEditorDialogProps) {
  const [wrapEnabled, setWrapEnabled] = useState(wrapEnabledInitially)

  const overCharacterLimit = value.length > characterLimit

  return (
    <Dialog {...overlay} title={title} maxWidth="lg">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {description ? <DialogContent>{description}</DialogContent> : <Box />}
        <Box sx={{ flexShrink: 0 }}>
          <FormControlLabel
            control={
              <Switch
                checked={wrapEnabled}
                onChange={() => setWrapEnabled(!wrapEnabled)}
              />
            }
            label="Wrap Lines"
          />
        </Box>
      </Stack>

      <Divider />

      <CodeEditor
        value={value}
        setValue={setValue}
        mode={mode}
        wrapEnabled={wrapEnabled}
        characterLimit={characterLimit}
      />
      <Divider />
      <DialogFooter
        details={
          <Typography
            variant="body2"
            sx={{
              width: 'max-content',
              px: 1,
              py: 0.5,
              borderRadius: 4,
              background: overCharacterLimit ? 'red' : 'transparent',
              transition: 'all 0.2s',
              fontWeight: overCharacterLimit ? 'bold' : 'normal',
            }}
          >
            {characterLimit !== Infinity
              ? `${value.length}/${characterLimit} characters`
              : ''}
          </Typography>
        }
      >
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button onClick={() => onSave(value)} disabled={overCharacterLimit}>
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
