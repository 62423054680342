import { useRef } from 'react'
import useLocalStorage from './useLocalStorageUIDotDev'
import { useDrag } from '@use-gesture/react'

type useHorizontalDragOptions = {
  identifier: string
  defaultWidth?: number
  minRightSideWidth?: number
  minLeftSideWidth?: number
}

export default function useHorizontalDrag({
  identifier,
  defaultWidth = 300,
  minRightSideWidth,
  minLeftSideWidth,
}: useHorizontalDragOptions) {
  const contentRef = useRef<HTMLDivElement>(null)
  const dragDividerRef = useRef<HTMLDivElement>(null)
  const dragDividerContainerRef = useRef<HTMLDivElement>(null)

  const [resizableContainerWith, setResizableContainerWith] = useLocalStorage(
    identifier,
    defaultWidth,
  )

  const bind = useDrag(({ delta: [ox] }) => {
    const content = contentRef.current
    const dragDividerContainer = dragDividerContainerRef.current
    const dragDivider = dragDividerRef.current
    if (!dragDividerContainer || !dragDivider || !content) return

    const newWidth = Math.min(
      minRightSideWidth
        ? content.clientWidth - minRightSideWidth
        : content.clientWidth,
      Math.max(
        minLeftSideWidth ? minLeftSideWidth : 0,
        dragDividerContainer.clientWidth + ox,
      ),
    )
    dragDividerContainer.style.width = `${newWidth}px`
    setResizableContainerWith(newWidth)
  })

  return {
    contentRef,
    dragDividerRef,
    dragDividerContainerRef,
    resizableContainerWith,
    bind,
  }
}
