import { Stack, Typography, useTheme } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import DropZone from './DropZone'
import { Organization } from '@/types/organizations'

type RecentOrgDropZoneProps = {
  organization: Organization
  onDrop: (files: FileList) => void
}

export default function RecentOrgDropZone({
  organization,
  onDrop,
}: RecentOrgDropZoneProps) {
  const theme = useTheme()

  return (
    <DropZone
      onDrop={onDrop}
      overlayChildren={
        <Stack
          sx={{
            height: '100%',
            width: '100%',
            border: `dashed 2px ${theme.palette.secondary.main}`,
            borderRadius: 2,
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(20px)',
          }}
        >
          <UploadIcon sx={{ fontSize: 48 }} />
        </Stack>
      }
    >
      <Stack
        sx={{
          px: 4,
          py: 6,
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          border: `dashed 2px ${theme.palette.divider}`,
          background: '#121212aa',
          borderRadius: 2,
        }}
      >
        <Typography textAlign="center">
          <b>{organization.name}</b>
        </Typography>
        <Typography variant="caption" textAlign="center">
          {organization.code}
        </Typography>
      </Stack>
    </DropZone>
  )
}
