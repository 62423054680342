import { OverlayState } from '@/hooks/useOverlay'
import {
  Box,
  FormControlLabel,
  FormGroup,
  Popover,
  Stack,
  Switch,
} from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import { useDocumentContext } from './providers/DocumentProvider'
import { useValidationOptionsContext } from './providers/ValidationOptionsProvider'
import LanguageSelect from './LanguageSelect'

type ValidationOptionsPopoverProps = {
  overlay: OverlayState
  anchorEl: HTMLElement | null
  documentView: WorkflowState['default_document_view']
}

export default function ValidationOptionsPopover({
  overlay,
  anchorEl,
  documentView,
}: ValidationOptionsPopoverProps) {
  const { document } = useDocumentContext()
  const {
    showOCR,
    setShowOCR,
    languageCode,
    setLanguageCode,
    showChipFrame,
    setShowChipFrame,
    showAllChipFrames,
    setShowAllChipFrames,
    showRowColumnChipFrames,
    setShowRowColumnChipFrames,
  } = useValidationOptionsContext()

  return (
    <Popover
      open={overlay.isOpen}
      onClose={overlay.close}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 16,
      }}
    >
      <Box sx={{ p: 1, pl: 2 }}>
        <FormGroup>
          <Stack direction="row" spacing={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={showOCR}
                  onChange={() => setShowOCR((prev) => !prev)}
                />
              }
              label="Show chip values"
            />
            {document?.primary_language_id &&
              !!document?.languages_ids?.length &&
              showOCR && (
                <LanguageSelect
                  languageIds={document.languages_ids}
                  languageCode={languageCode}
                  sourceLanguageCode={document.primary_language_id}
                  setLanguageCode={setLanguageCode}
                />
              )}
          </Stack>
          {documentView === 'validation' && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={showChipFrame}
                    onChange={() => setShowChipFrame((prev) => !prev)}
                  />
                }
                label="Show frame around chips attached to the selected field"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showAllChipFrames}
                    onChange={() => setShowAllChipFrames((prev) => !prev)}
                  />
                }
                label="Show frames around all selected chips when no field is selected"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showRowColumnChipFrames}
                    onChange={() => setShowRowColumnChipFrames((prev) => !prev)}
                  />
                }
                label="Show frames around rows and columns when a table field is selected"
              />
            </>
          )}
        </FormGroup>
      </Box>
    </Popover>
  )
}
