import { useReactFlow, Node } from 'reactflow'
import { LogixHandle } from '@/types/logix'
import { getHandleOfMatchingType } from '../helpers/logix-helpers'
import { ConnectHandlesParams } from './useConnectHandles'

type UseConnectAfterCreatedOptions = {
  connectHandles: ({
    sourceNode,
    targetNode,
    sourceHandle,
    targetHandle,
  }: ConnectHandlesParams) => void
}

type ConnectAfterCreatedParams = {
  nodeId: string
  connectingNode: Node
  connectingHandle: LogixHandle
}

export default function useConnectAfterCreated({
  connectHandles,
}: UseConnectAfterCreatedOptions) {
  const reactFlowInstance = useReactFlow()

  return ({
    nodeId,
    connectingNode,
    connectingHandle,
  }: ConnectAfterCreatedParams) => {
    setTimeout(() => {
      const newNode = reactFlowInstance.getNode(nodeId) as Node

      const isInput =
        connectingHandle.area === 'primary-left' ||
        connectingHandle.area === 'secondary-left' ||
        connectingHandle.area === 'iteration-right'

      const handlesToCheckAgainst = isInput
        ? 'type_out_handles'
        : 'type_in_handles'

      const targetHandle = getHandleOfMatchingType(
        connectingHandle,
        newNode.data.logixNode.node_type[handlesToCheckAgainst],
      )
      if (!targetHandle) return
      connectHandles({
        sourceNode: isInput ? newNode : connectingNode,
        targetNode: isInput ? connectingNode : newNode,
        sourceHandle: isInput ? targetHandle : connectingHandle,
        targetHandle: isInput ? connectingHandle : targetHandle,
      })
    }, 1)
  }
}
