import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { Button, Typography } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import {
  DataListColumn,
  DataListEntry,
  DataListEntryCellValue,
} from '@/types/data-lists'
import ZerapixTable from '../zerapix-table/ZerapixTable'
import { MRT_ColumnDef } from 'material-react-table'
import constructTableRows, { RowData } from './constructTableRows'
import MultiValueEdit from './MultiValueEdit'
import { useMemo } from 'react'
import getFormattedDateTimeString from '@/utils/getFormattedDateTimeString'
import useZerapixTable from '../zerapix-table/useZerapixTable'
import { QueryKey } from '@tanstack/react-query'

type ValueMappingsDialogProps = {
  overlay: OverlayState
  mappingColumnsData: {
    mainColumn: DataListColumn
    mappingColumns: DataListColumn[]
  }
  dataListColumns: DataListColumn[]
  dataListEntries: DataListEntry[]
  entriesQueryKey: QueryKey
}

export default function ValueMappingsDialog({
  overlay,
  mappingColumnsData,
  dataListColumns,
  dataListEntries,
  entriesQueryKey,
}: ValueMappingsDialogProps) {
  const { mainColumn, mappingColumns = [] } = mappingColumnsData

  const rows = constructTableRows(dataListEntries, dataListColumns)

  const columns = useMemo<MRT_ColumnDef<RowData>[]>(
    () => [
      ...[mainColumn, ...mappingColumns].map(
        (column): MRT_ColumnDef<RowData> => {
          const isMainColumn = column.id === mainColumn.id
          const { data_list_column_type } = column
          const { postgres_data_type } = data_list_column_type
          return {
            header: isMainColumn ? column.name : 'Mapped Values',
            accessorKey: `${column.id}.values`,
            Cell: ({ cell }) => {
              const values = cell.getValue<DataListEntryCellValue[]>() || []

              let displayedValue = ''
              if (
                postgres_data_type === 'date' ||
                postgres_data_type === 'datetime' ||
                postgres_data_type === 'time'
              ) {
                const firstValue = values[0]?.value
                // Format any date or time values so they are readable (they are stored in ISO format)
                displayedValue = firstValue
                  ? getFormattedDateTimeString(
                      firstValue,
                      postgres_data_type,
                    ) || ''
                  : ''
              } else {
                displayedValue = values.map(({ value }) => value).join(', ')
              }
              // Wrap with Typography so it will have our standard font size
              return <Typography>{displayedValue}</Typography>
            },
            enableEditing: !isMainColumn,
            Edit: ({ row, table }) => (
              <MultiValueEdit
                dataListEntry={dataListEntries.find(
                  ({ id }) => id === row.original.dataEntryId,
                )}
                dataListColumnId={column.id}
                table={table}
                entriesQueryKey={entriesQueryKey}
              />
            ),
          }
        },
      ),
    ],
    [dataListEntries, entriesQueryKey, mainColumn, mappingColumns],
  )

  const table = useZerapixTable<RowData>({
    columns,
    data: rows,
    enableEditing: true,
    editDisplayMode: 'cell',
    enableTopToolbar: false,
    muiTablePaperProps: { elevation: 0 },
    muiTableBodyRowProps: {
      sx: {
        minHeight: '40px',
      },
    },
  })

  return (
    <Dialog title="Edit Value Mappings" {...overlay} maxWidth="md">
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 4 }}>
          Double-click to edit mapping values.
        </Typography>
        <ZerapixTable<RowData> table={table} />
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
