import { Button, Stack, Alert, Typography } from '@mui/material'
import { DocumentFlag, RowValueFlag } from '@/types/flags'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import FullFlagsList from '@/components/flags/FullFlagsList'

type DocumentsWithErrorsDialogProps = {
  overlay: OverlayState
  onContinue: () => void
  inDashboard?: boolean
  // We pass flags when dialog is used on the validation page
  documentFlags?: DocumentFlag[]
  rowValueFlags?: RowValueFlag[]
}

export default function DocumentsWithErrorsDialog({
  overlay,
  onContinue,
  inDashboard,
  documentFlags = [],
  rowValueFlags = [],
}: DocumentsWithErrorsDialogProps) {
  return (
    <Dialog
      title={
        inDashboard
          ? 'Some Documents Have Error Flags'
          : 'Remaining Error Flags'
      }
      {...overlay}
      maxWidth="sm"
    >
      <DialogContent>
        {!inDashboard &&
          (documentFlags.length > 0 || rowValueFlags.length > 0) && (
            <Stack spacing={2}>
              {documentFlags.length > 0 && (
                <FullFlagsList title="Document Flags" flags={documentFlags} />
              )}
              {rowValueFlags.length > 0 && (
                <FullFlagsList title="Field Flags" flags={rowValueFlags} />
              )}
            </Stack>
          )}

        <Alert severity="warning" sx={{ mt: 4 }} variant="outlined">
          <Stack spacing={2}>
            <Typography>
              You can ignore these flags and continue, but this may result in
              errors moving forward. We recommend fixing the remaining flags
              before continuing.
            </Typography>
            <div>
              <Button variant="text" color="warning" onClick={onContinue}>
                Continue Anyway
              </Button>
            </div>
          </Stack>
        </Alert>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
