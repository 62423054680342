import { LogEntry } from '@/types/log-entries'
import { memo } from 'react'
import { useWorkflowsContext } from '../workflows-provider/WorkflowsProvider'
import { useGetWorkflowStateEvent } from '@/service-library/hooks/workflow-state-events'
import { useGetProjectModel } from '@/service-library/hooks/project-models'
import { useProjectContext } from '../project-dashboard/ProjectProvider'

const codes = {
  EVENT_TRIGGERED:
    'The {event_name} event for {state_name} for {workflow_name} was triggered.',
  EVENT_PROCESSED:
    'The {event_name} event for {state_name} for {workflow_name} was processed.',
  MODEL_PREDICTION_COMPLETE:
    'The {model_name} model using version {model_version} ran prediction.',
  CHIP_UNASSIGNED:
    'A chip with text "{chip_text}" was removed from {field_name}.',
  CHIP_ASSIGNED: 'A chip with text "{chip_text}" was assigned to {field_name}.',
  CHIP_ASSIGNED_TO_DIFFERENT_ROW:
    'A chip with text "{chip_text}" was assigned to {field_name} on a different row.',
  MANUAL_VALUE_SET: '{field_name} was manually set to {manual_value}.',
  MANUAL_VALUE_REMOVED: "{field_name}'s manual value was removed.",
  MANUAL_VALUE_SET_IN_TABLE:
    '{field_name} was manually set to {manual_value} in a row in {parent_field_name}.',
  MANUAL_VALUE_REMOVED_IN_TABLE:
    '{field_name} was manually removed from a row in {parent_field_name}.',
}

function EventLogEntry({ code, ref_objs }: LogEntry) {
  const { workflows } = useWorkflowsContext()
  const workflowStateEventId = ref_objs.find(
    (obj) => obj.key === 'event_name',
  )?.obj_id
  const { workflowStateEvent } = useGetWorkflowStateEvent({
    id: workflowStateEventId || '',
    filters: {
      fields__only: 'id,name,workflow_state_id',
    },
  })

  const workflow = workflows.find((workflow) =>
    workflow.workflow_states?.find(
      (state) => state.id === workflowStateEvent?.workflow_state_id,
    ),
  )
  const state = workflow?.workflow_states?.find(
    (state) => state.id === workflowStateEvent?.workflow_state_id,
  )

  let longDescription = codes[code as keyof typeof codes]
  longDescription = longDescription.replaceAll(
    '{event_name}',
    workflowStateEvent?.name || '',
  )
  longDescription = longDescription.replaceAll(
    '{state_name}',
    state?.name || '',
  )
  longDescription = longDescription.replaceAll(
    '{workflow_name}',
    workflow?.name || '',
  )

  return <>{workflowStateEvent && state && workflow ? longDescription : ''}</>
}

function PredictionLogEntry({ code, ref_objs }: LogEntry) {
  const { projectModel } = useGetProjectModel({
    id: ref_objs.find((obj) => obj.key === 'model_name')?.obj_id || '',
    filters: {
      fields__only: 'name,id',
    },
  })

  if (!projectModel) return <></>

  let longDescription = codes[code as keyof typeof codes]
  longDescription = longDescription.replaceAll(
    '{model_name}',
    projectModel?.name || '',
  )

  const version = +(
    ref_objs.find((obj) => obj.key === 'model_version')?.obj.version || 0
  )

  longDescription = longDescription.replaceAll(
    '{model_version}',
    `${Number.isInteger(version) ? `${version}.0` : version}`,
  )

  return <>{longDescription}</>
}

function ChipLogEntry({ code, ref_objs }: LogEntry) {
  const { fieldsMap } = useProjectContext()
  const fieldName =
    fieldsMap[ref_objs.find((obj) => obj.key === 'field_name')?.obj_id || '']
      ?.name || ''

  if (!fieldName) return <></>

  let longDescription = codes[code as keyof typeof codes]
  longDescription = longDescription.replaceAll(
    '{chip_text}',
    ref_objs.find((obj) => obj.key === 'chip_text')?.obj.text || '',
  )
  longDescription = longDescription.replaceAll('{field_name}', fieldName)
  return <>{longDescription}</>
}

function ManualValueLogEntry({ code, ref_objs }: LogEntry) {
  const { fieldsMap } = useProjectContext()
  const fieldName =
    fieldsMap[ref_objs.find((obj) => obj.key === 'field_name')?.obj_id || '']
      ?.name || ''

  if (!fieldName) return <></>

  let longDescription = codes[code as keyof typeof codes]
  longDescription = longDescription.replaceAll('{field_name}', fieldName)
  longDescription = longDescription.replaceAll(
    '{manual_value}',
    ref_objs.find((obj) => obj.key === 'manual_value')?.obj.value || '',
  )

  if (
    code === 'MANUAL_VALUE_SET_IN_TABLE' ||
    code === 'MANUAL_VALUE_REMOVED_IN_TABLE'
  ) {
    const parentFieldName =
      fieldsMap[
        ref_objs.find((obj) => obj.key === 'parent_field_name')?.obj_id || ''
      ]?.name || ''
    longDescription = longDescription.replaceAll(
      '{parent_field_name}',
      parentFieldName,
    )
    if (!parentFieldName) return <></>
  }
  return <>{longDescription}</>
}

function EntryLongDescription(entry: LogEntry) {
  const { code, long_description } = entry
  if (code === 'EVENT_TRIGGERED' || code === 'EVENT_PROCESSED') {
    return <EventLogEntry {...entry} />
  }
  if (code === 'MODEL_PREDICTION_COMPLETE') {
    return <PredictionLogEntry {...entry} />
  }
  if (
    code === 'CHIP_UNASSIGNED' ||
    code === 'CHIP_ASSIGNED' ||
    code === 'CHIP_ASSIGNED_TO_DIFFERENT_ROW'
  ) {
    return <ChipLogEntry {...entry} />
  }
  if (
    code === 'MANUAL_VALUE_UPDATED' ||
    code === 'MANUAL_VALUE_REMOVED' ||
    code === 'MANUAL_VALUE_UPDATED_IN_TABLE' ||
    code === 'MANUAL_VALUE_REMOVED_IN_TABLE'
  ) {
    return <ManualValueLogEntry {...entry} />
  }
  return <>{long_description}</>
}

export default memo(EntryLongDescription)
