import { Link } from 'react-router-dom'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import useOverlay from '@/hooks/useOverlay'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'

export default function SuperUserMenu() {
  const overlay = useOverlay()
  const [demoMode, setDemoMode] = useDemoModeContext()

  return (
    <div>
      <Tooltip title="Super User Menu">
        <IconButton
          onClick={overlay.toggle}
          size="small"
          id="super-user-button"
          sx={{ color: SUPER_USER_ONLY_COLOR }}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>

      <Menu
        id="super-user-menu"
        anchorEl={overlay.anchorEl as Element}
        open={overlay.isOpen}
        onClose={overlay.close}
        MenuListProps={{
          'aria-labelledby': 'super-user-button',
        }}
      >
        <MenuItem
          component={Link}
          to="/zerapix-management/customers"
          onClick={overlay.close}
        >
          Customers
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDemoMode((prev) => !prev)
            overlay.close()
          }}
        >
          Turn {demoMode ? 'Off' : 'On'} Demo Mode
        </MenuItem>
        <MenuItem
          component={Link}
          to="/zerapix-management/feature-flags"
          onClick={overlay.close}
        >
          Feature Flags
        </MenuItem>
        <MenuItem
          component={Link}
          to="/total-documents"
          onClick={overlay.close}
        >
          Document Counts
        </MenuItem>
      </Menu>
    </div>
  )
}
