import { updateList } from '../request-wrappers'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'

type DocumentToReprocess = {
  id: string // used for the batch request
  document_id: string
}

/**
 * MARK: Action Hook: Reprocess Documents
 * Used to reprocess a list of documents.
 */
export function useReprocessDocuments(
  options: UseUpdateListOptions<DocumentToReprocess> = {},
) {
  const mutation = useUpdateList<DocumentToReprocess>({
    serviceFn: (serviceFnOptions) => {
      return updateList<DocumentToReprocess>({
        url: '/v2/pd-ocr/process_documents',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    reprocessDocuments: mutation.mutateAsync,
    ...mutation,
  }
}
