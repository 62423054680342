import { Card, Stack, Typography, useTheme } from '@mui/material'
import { ProjectModelVersion } from '@/types/project-models'
import { ScatterPlotValue } from '@nivo/scatterplot'
import LineChart from '../charts/LineChart'

type ModelVersionAccuracyCardProps = {
  modelVersions: ProjectModelVersion[]
}

export default function ModelVersionAccuracyCard({
  modelVersions,
}: ModelVersionAccuracyCardProps) {
  const theme = useTheme()

  const filteredVersions = modelVersions
    .filter(
      ({ training_status }) =>
        training_status !== 'error' && training_status !== 'canceled',
    )
    .slice(-20)

  const data = [
    {
      id: 'versions',
      color: theme.palette.primary.main,
      data: filteredVersions.map((modelVersion) => {
        return {
          x: modelVersion.version,
          y: modelVersion.accuracy,
        }
      }),
    },
  ]

  return (
    <Card
      sx={{ borderRadius: 2, py: 2, px: 3, overflow: 'visible' }}
      elevation={0}
    >
      <LineChart
        curve="monotoneX"
        label={
          <Typography component="h2" variant="body1">
            Model Version Accuracy
          </Typography>
        }
        data={data}
        dataHasColor
        height={240}
        xFormat={(x: ScatterPlotValue) => `v${x}.0`}
        yFormat=".0%"
        yLegend="Accuracy"
        yScale={{
          min: 0,
          max: 1,
          type: 'linear',
        }}
        tooltipContent={(point) => {
          return (
            <Stack direction="row">
              <Stack p={1} maxWidth={170}>
                <Typography component="p" noWrap variant="caption">
                  <b>Version:</b> {point.data.xFormatted}
                </Typography>
                <Typography component="p" noWrap variant="caption">
                  <b>Accuracy:</b> {point.data.yFormatted}
                </Typography>
              </Stack>
            </Stack>
          )
        }}
      />
    </Card>
  )
}
