/* eslint-disable @typescript-eslint/no-explicit-any -- Ignore warning for now */
import {
  Stack,
  CircularProgress,
  Typography,
  CircularProgressProps,
} from '@mui/material'
import { ReactNode } from 'react'

type LabeledProgressIndicatorProps = {
  children: ReactNode
  color?: CircularProgressProps['color']
  component?: React.ElementType<any>
  componentProps?: Record<any, any>
  hideSpinner?: boolean
}

export default function LabeledProgressIndicator({
  children,
  color = 'secondary',
  component = 'div',
  componentProps = {},
  hideSpinner = false,
}: LabeledProgressIndicatorProps) {
  const { sx: componentSx, ...rest } = componentProps
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      component={component}
      sx={{ mx: 0, ...componentSx }}
      {...rest}
    >
      {!hideSpinner && (
        <CircularProgress size={18} color={color} sx={{ flexShrink: 0 }} />
      )}
      <Typography variant="body2" color="text.secondary" noWrap>
        {children}
      </Typography>
    </Stack>
  )
}
