import { ThemeColor } from '@/theme/usePixydocsTheme'
import { useTheme } from '@mui/material'
import useLocalStorage from './useLocalStorage'

export default function useGetThemeColor(): (colorName?: ThemeColor) => string {
  const theme = useTheme()
  const [themeMode] = useLocalStorage('pixydocs-theme-mode', 'dark')

  return (colorName?: ThemeColor) => {
    const themeColor = theme.palette[colorName || '']
    if (!themeColor) return theme.palette.text.secondary
    return themeColor[themeMode === 'dark' ? 'dark' : 'light']
  }
}
