import { WorkflowStateEvent } from '@/types/workflow-state-events'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import EventLane from './EventLane'
import { Draggable } from 'react-smooth-dnd'
import { QueryKey } from '@tanstack/react-query'
import { DraggableList } from '@/components/lists/DraggableList'
import { useUpdateWorkflowStateEvents } from '@/service-library/hooks/workflow-state-events'

type EventLanesProps = {
  title: ReactNode
  workflowStateEvents: WorkflowStateEvent[]
  listQueryKey: QueryKey
  isReorderable?: boolean
  showPrimaryEvent?: boolean
}

export default function EventLanes({
  title,
  workflowStateEvents,
  listQueryKey,
  isReorderable = false,
  showPrimaryEvent = false,
}: EventLanesProps) {
  const { updateWorkflowStateEvents } = useUpdateWorkflowStateEvents({
    listQueryKey,
    useSortOrder: true,
  })

  function onReorder(updatedWorkflowStateEvents: WorkflowStateEvent[]) {
    const updatedOrder = updatedWorkflowStateEvents.map((state, index) => ({
      ...state,
      sort_order: index,
    }))
    updateWorkflowStateEvents(updatedOrder)
  }

  const primaryEventId = showPrimaryEvent
    ? workflowStateEvents.find(
        ({ allow_manual_trigger }) => allow_manual_trigger,
      )?.id
    : undefined

  return (
    <Stack spacing={1}>
      <Typography variant="h6">{title} </Typography>
      <Paper sx={{ overflow: 'hidden' }}>
        {workflowStateEvents.length === 0 && (
          <Typography variant="body1" color="text.secondary" sx={{ p: 2 }}>
            No events
          </Typography>
        )}

        {isReorderable && workflowStateEvents.length > 0 ? (
          <DraggableList
            dragHandleSelector=".event-drag-handle"
            onChange={onReorder}
            data={workflowStateEvents}
          >
            {workflowStateEvents.map((event, eventIndex) => {
              return (
                // @ts-expect-error -- Draggable can have children
                <Draggable key={event.id}>
                  {eventIndex !== 0 && <Divider />}
                  <EventLane
                    isPrimaryEvent={primaryEventId === event.id}
                    workflowStateEvent={event}
                    listQueryKey={listQueryKey}
                    isDraggable
                  />
                </Draggable>
              )
            })}
          </DraggableList>
        ) : (
          workflowStateEvents.map((event, eventIndex) => {
            return (
              <Box sx={{ overflow: 'hidden' }} key={event.id}>
                {eventIndex !== 0 && <Divider />}
                <EventLane
                  isPrimaryEvent={primaryEventId === event.id}
                  workflowStateEvent={event}
                  listQueryKey={listQueryKey}
                />
              </Box>
            )
          })
        )}
      </Paper>
    </Stack>
  )
}
