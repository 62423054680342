import { OverlayState } from '@/hooks/useOverlay'
import { RowValueFlag, DocumentFlag } from '@/types/flags'
import {
  CircularProgress,
  Divider,
  Popover,
  PopoverProps,
  Stack,
} from '@mui/material'
import FullFlagsList from './FullFlagsList'

type FlagsDialogProps = {
  overlay: OverlayState
  documentFlags: DocumentFlag[]
  rowValueFlags: RowValueFlag[]
  isLoading?: boolean
  onRowValueFlagClick?: (
    flag: RowValueFlag,
    cellToFocus: HTMLInputElement | HTMLDivElement | null,
  ) => void
} & Omit<PopoverProps, 'open'>

export default function FlagsPopover({
  overlay,
  documentFlags = [],
  rowValueFlags = [],
  isLoading = false,
  onRowValueFlagClick,
  ...rest
}: FlagsDialogProps) {
  const hasDocumentFlags = documentFlags.length > 0
  const hasRowValueFlags = rowValueFlags.length > 0
  return (
    <Popover
      open={overlay.isOpen}
      anchorEl={overlay.anchorEl as Element}
      onClose={overlay.close}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      disableScrollLock
      disableEnforceFocus
      disableRestoreFocus
      {...rest}
    >
      <Stack spacing={2} sx={{ py: 2, maxWidth: 400, minWidth: 200 }}>
        {isLoading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} />
        ) : (
          <>
            {hasDocumentFlags && (
              <FullFlagsList title="Document Flags" flags={documentFlags} />
            )}
            {hasDocumentFlags && hasRowValueFlags && <Divider />}
            {hasRowValueFlags && (
              <FullFlagsList
                title="Field Flags"
                flags={rowValueFlags}
                onFlagClick={onRowValueFlagClick}
              />
            )}
          </>
        )}
      </Stack>
    </Popover>
  )
}
