import useGetThemeColor from '@/hooks/useGetThemeColor'
import { useGetWorkflowState } from '@/service-library/hooks/workflow-states'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Box, Typography } from '@mui/material'

type MoveCleanDocumentsDisplayProps = {
  action: WorkflowStateAction
}

export default function MoveCleanDocumentsDisplay({
  action,
}: MoveCleanDocumentsDisplayProps) {
  const getThemeColor = useGetThemeColor()

  const { workflowState } = useGetWorkflowState({
    id: action.params.workflow_state_id,
    filters: {
      fields__only: 'id,name,color',
    },
  })

  return (
    <Typography variant="body2" color="text.secondary">
      If the document does not have flags, move it to{' '}
      <Box component="span" sx={{ color: getThemeColor(workflowState?.color) }}>
        {workflowState?.name}
      </Box>
      .
    </Typography>
  )
}
