import {
  orange,
  blue,
  green,
  grey,
  teal,
  purple,
  indigo,
  blueGrey,
  yellow,
  red,
  pink,
  deepPurple,
} from '@mui/material/colors'
import { PaletteOptions, createTheme } from '@mui/material/styles'
import useLocalStorage from '@/hooks/useLocalStorage'

export const themeColorOptions = [
  'blue',
  'green',
  'orange',
  'teal',
  'purple',
  'indigo',
  'grey',
  'blueGrey',
  'yellow',
  'red',
  'pink',
  'deepPurple',
]

export const themeColors = [
  'primary',
  'secondary',
  'text',
  'error',
  'warning',
  'info',
  'success',
]

export const SUPER_USER_ONLY_COLOR = pink['A200']

export type ThemeColor = typeof themeColors[number] &
  typeof themeColorOptions[number]

export default function usePixydocsTheme() {
  const userDefaultTheme = window.matchMedia('(prefers-color-scheme: dark)')
  const [themeMode] = useLocalStorage<'light' | 'dark'>(
    'pixydocs-theme-mode',
    userDefaultTheme.matches ? 'dark' : 'light',
  )

  const theme = createTheme({
    palette: {
      tonalOffset: themeMode === 'dark' ? 0.05 : 0.2,
    },
  })

  const colorIndex = themeMode === 'dark' ? 400 : 700

  const themeOptions = {
    palette: {
      primary: {
        main: themeMode === 'dark' ? blue[300] : blue[500],
      },
      secondary: {
        main: orange[colorIndex],
      },
      neutral: theme.palette.augmentColor({
        color: {
          main: themeMode === 'dark' ? grey[100] : grey[900],
        },
        name: 'neutral',
      }),
      blue: theme.palette.augmentColor({
        color: {
          main: blue[colorIndex],
        },
        name: 'blue',
      }),
      green: theme.palette.augmentColor({
        color: {
          main: green[colorIndex],
        },
        name: 'green',
      }),
      orange: theme.palette.augmentColor({
        color: {
          main: orange[colorIndex],
        },
        name: 'orange',
      }),
      teal: theme.palette.augmentColor({
        color: {
          main: teal[colorIndex],
        },
        name: 'teal',
      }),
      purple: theme.palette.augmentColor({
        color: {
          main: purple[colorIndex],
        },
        name: 'purple',
      }),
      indigo: theme.palette.augmentColor({
        color: {
          main: indigo[colorIndex],
        },
        name: 'indigo',
      }),
      grey: theme.palette.augmentColor({
        color: {
          main: grey[colorIndex],
        },
        name: 'grey',
      }),
      blueGrey: theme.palette.augmentColor({
        color: {
          main: blueGrey[colorIndex],
        },
        name: 'blueGrey',
      }),
      yellow: theme.palette.augmentColor({
        color: {
          main: yellow[colorIndex],
        },
        name: 'yellow',
      }),
      red: theme.palette.augmentColor({
        color: {
          main: red[colorIndex],
        },
        name: 'red',
      }),
      pink: theme.palette.augmentColor({
        color: {
          main: pink[colorIndex],
        },
        name: 'pink',
      }),
      deepPurple: theme.palette.augmentColor({
        color: {
          main: deepPurple[colorIndex],
        },
        name: 'deepPurple',
      }),
      mode: themeMode,
    } as PaletteOptions,
    appDrawer: { width: 240 },
  }

  if (themeMode === 'light') {
    themeOptions.palette.background = {
      default: grey[100],
      paper: 'white',
    }
  } else {
    themeOptions.palette.background = {
      default: '#1a1a1a',
      paper: '#222',
    }
  }

  return createTheme(themeOptions)
}
