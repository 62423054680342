import { ReactNode } from 'react'
import {
  FormProvider,
  UseFormReturn,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form'

type PixyDocsFormProps<FormValues extends FieldValues> = {
  children: ReactNode
  methods: UseFormReturn<FormValues>
  onSubmit: SubmitHandler<FormValues>
  id?: string
  style?: React.CSSProperties
}

export default function PixyDocsForm<FormValues extends FieldValues>({
  children,
  methods,
  onSubmit,
  id,
  style,
}: PixyDocsFormProps<FormValues>) {
  return (
    <FormProvider {...methods}>
      <form
        id={id ?? 'form'}
        style={style}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  )
}
