import { createRoot } from 'react-dom/client'
import './index.css'
import Main from './Main'
// import reportWebVitals from './reportWebVitals'

import Amplify, { Auth } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: import.meta.env.VITE_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH',
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
  },
})

Auth.configure({
  oauth: {
    domain: import.meta.env.VITE_ZAPI_AUTH_DOMAIN,
    // Can include a list of scopes if you want to reduce the scopes the token will have
    // (ie: if you want a token with less rights, perhaps one that you want to send to a different website, etc).
    // Not including it will get a token with all scopes the client supports.
    // Example:
    //    `scope: ['phone', 'email', 'profile', 'openid'],`
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: 'code',
  },
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  // <React.StrictMode>
  <Main />,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
