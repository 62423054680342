import { Card } from '@mui/material'
import { ProjectModelVersion } from '@/types/project-models'
import CategoryModelItemCountChart from '@/components/data-visualization/CategoryModelItemCountChart'

type CategoryItemCountChartCardProps = {
  modelVersion: ProjectModelVersion
}

export default function CategoryItemCountChartCard({
  modelVersion,
}: CategoryItemCountChartCardProps) {
  return (
    <Card
      sx={{ borderRadius: 2, py: 2, px: 3, overflow: 'visible' }}
      elevation={0}
    >
      <CategoryModelItemCountChart modelVersion={modelVersion} height={240} />
    </Card>
  )
}
