import { ReactNode, useRef } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { Card, Popper } from '@mui/material'

type TooltipCardProps = {
  children: ReactNode
}

// Workaround so that the tooltip doesn't cause the page to overflow
export default function TooltipCard({ children }: TooltipCardProps) {
  const nodeRef = useRef<HTMLDivElement>(null)
  const style = useSpring({
    to: {
      opacity: 1,
    },
    from: {
      opacity: 0,
    },
    config: {
      clamp: true,
      tension: 500,
    },
    delay: 50,
  })

  return (
    <>
      <div ref={nodeRef} />
      {nodeRef.current && (
        <Popper anchorEl={nodeRef.current} disablePortal open placement="top">
          <animated.div style={style}>
            <Card sx={{ transform: 'translateY(-12px)' }}>{children}</Card>
          </animated.div>
        </Popper>
      )}
    </>
  )
}
