/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'

type UseQueueRequestOptions = {
  fn: (...args: any[]) => Promise<any>
  enabled: boolean
}

export default function useQueueRequest({
  fn,
  enabled,
}: UseQueueRequestOptions) {
  const [queuedFn, setQueuedFn] = useState<typeof fn>()

  useEffect(() => {
    if (enabled && queuedFn) {
      queuedFn()
      setQueuedFn(undefined)
    }
  }, [enabled, queuedFn])

  return (...args: any) => {
    setQueuedFn(() => () => fn(...args))
  }
}
