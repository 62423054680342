import { ProjectModel } from '@/types/project-models'
import { Card, CardActionArea, Typography } from '@mui/material'
import { blue, red } from '@mui/material/colors'
import { CATEGORY_MODEL_TYPE_ID } from './helpers'

type HandleTrainingButtonProps = {
  onClick: () => void
  status?: 'training' | 'canceling'
  disabled: boolean
  newOccurrencesCount: number
  model: ProjectModel
}

export default function HandleTrainingButton({
  onClick,
  disabled,
  status,
  newOccurrencesCount = 0,
  model,
}: HandleTrainingButtonProps) {
  const looksDisabled = disabled || status

  const isCategoryModel =
    model?.project_model_type_id === CATEGORY_MODEL_TYPE_ID

  const nonTrainingColor = looksDisabled ? 'text.secondary' : undefined

  const nonTrainingBackground = looksDisabled
    ? undefined
    : `linear-gradient(45deg, ${blue[500]} 30%, ${blue['A700']} 90%)`

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        borderRadius: 2,
        background: nonTrainingBackground,
        cursor: disabled ? 'not-allowed' : undefined,
        ':hover': {
          borderColor: status && !disabled ? red['A700'] : undefined,
          h6: {
            color: status && !disabled ? red['A700'] : undefined,
          },
        },
      }}
    >
      <CardActionArea
        sx={{
          p: 2,
          height: '100%',
          fontSize: 16,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
        onClick={() => {
          if (!disabled) {
            onClick()
          }
        }}
        disabled={disabled}
      >
        <Typography variant="h6" color={nonTrainingColor}>
          {status === 'training'
            ? 'Cancel Training'
            : status === 'canceling'
            ? 'Canceling Training'
            : 'Train Model'}
        </Typography>
        {model.project_model_versions.length > 0 && (
          <Typography variant="caption" color={nonTrainingColor}>
            {newOccurrencesCount} new {isCategoryModel ? 'item' : 'field'}{' '}
            occurrences
          </Typography>
        )}
      </CardActionArea>
    </Card>
  )
}
