import { Organization } from '@/types/organizations'
import { Skeleton, Stack, Link as MUILink, Button, Box } from '@mui/material'
import LabeledData from '@/components/labeled-data/LabeledData'
import { Link } from 'react-router-dom'
import { Add, Delete, Edit } from '@mui/icons-material'
import LargeHeading from '@/components/large-heading/LargeHeading'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import AddEditOrganizationDialog from './AddEditOrganizationDialog'
import DeleteOrganizationDialog from './DeleteOrganizationDialog'
import ChildrenOrganizations from './ChildrenOrganizations'
import CopyIDButton from '../copy-id-button/CopyIDButton'
import {
  useGetOrganization,
  useGetOrganizations,
} from '@/service-library/hooks/organizations'

type OrganizationDetailsProps = {
  organization: Organization
}

export default function OrganizationDetails({
  organization,
}: OrganizationDetailsProps) {
  const { hasEditingPermission } = usePermission()
  const addSubOrgOverlay = useOverlay()
  const editOverlay = useOverlay()
  const deleteOverlay = useOverlay()

  const { organizations = [], isLoading } = useGetOrganizations({
    filters: {
      parent_org_id: organization.id,
      ordering: '-parent_org_id,name,id',
      limit: '1000',
    },
    enabled: !!organization.id,
  })

  const { organization: parentOrganization, isLoading: isLoadingParent } =
    useGetOrganization({
      id: organization.parent_org_id as string,
      filters: {
        fields__include: 'org_type',
      },
      enabled: !!organization.parent_org_id,
    })

  const canEditOrg = hasEditingPermission('edit_orgs', organization.id)

  return (
    <Stack spacing={2} sx={{ p: 2, pt: 3, flexGrow: 1, overflow: 'auto' }}>
      <div>
        <LargeHeading
          heading={organization.name}
          subHeading={
            organization.parent_org_id ? 'Organization' : 'Root Organization'
          }
          actions={
            <>
              {organization.parent_org_id && canEditOrg ? (
                <>
                  <Button
                    variant="text"
                    startIcon={<Edit />}
                    onClick={editOverlay.open}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    startIcon={<Delete />}
                    onClick={deleteOverlay.open}
                  >
                    Delete
                  </Button>

                  <AddEditOrganizationDialog
                    overlay={editOverlay}
                    organization={organization}
                  />
                  <DeleteOrganizationDialog
                    overlay={deleteOverlay}
                    organization={organization}
                  />
                </>
              ) : undefined}
              <CopyIDButton stringToCopy={organization.id} />
            </>
          }
        />
      </div>
      {organization.parent_org_id && (
        <LabeledData
          label="Organization Type"
          data={organization.org_type?.name || 'None'}
          dataColor={organization.org_type ? undefined : 'text.secondary'}
        />
      )}
      {organization.code && (
        <LabeledData label="Organization Identifier" data={organization.code} />
      )}
      {organization.parent_org_id && (
        <LabeledData
          label="Parent Organization"
          data={
            <>
              {isLoadingParent ? (
                <Skeleton width={125} />
              ) : (
                <MUILink
                  component={Link}
                  underline="hover"
                  to={`../${parentOrganization?.id}`}
                >
                  {parentOrganization?.name}
                </MUILink>
              )}
            </>
          }
        />
      )}
      {/* Need Box to allow scrolling */}
      <Box>
        <ChildrenOrganizations
          organizations={organizations}
          isLoading={isLoading}
          noRecordsToDisplay="No sub-organizations have been added to this organization."
          title="Sub-Organizations"
          renderTableActions={
            canEditOrg
              ? () => {
                  return (
                    <Button
                      onClick={addSubOrgOverlay.open}
                      startIcon={<Add />}
                      variant="text"
                    >
                      New Organization
                    </Button>
                  )
                }
              : undefined
          }
        />
      </Box>

      <AddEditOrganizationDialog
        overlay={addSubOrgOverlay}
        parentOrgId={organization.id}
      />
    </Stack>
  )
}
