import { useCallback } from 'react'
import { useNodeId, useStore } from 'reactflow'

export default function useEdgeFromHandle(handleId: string) {
  const nodeId = useNodeId() // Gets the ID of the node the handle is a part of
  return useStore(
    useCallback(
      (state) =>
        state.edges.find((e) => {
          return (
            (e.source === nodeId && e.sourceHandle === handleId) ||
            (e.target === nodeId && e.targetHandle === handleId)
          )
        }),
      [handleId, nodeId],
    ),
  )
}
