import { useFormContext } from 'react-hook-form'
import {
  Stack,
  FormControl,
  FormLabel,
  FormGroup,
  Link,
  Skeleton,
  Alert,
  Typography,
} from '@mui/material'
import { Team } from '@/types/teams'
import { useGetTeams } from '@/service-library/hooks/teams'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { FormMultiSelectCheckbox } from '@/components/forms'
import OrganizationTreeWithImplicitInheritanceSearch from '@/components/organizations-tree/OrganizationTreeWithImplicitInheritanceSearch'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { OrgTreeSkeleton } from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'

type UserAssignmentProps = {
  rootOrgId: string
  isEdit?: boolean
}

export default function UserAssignment({
  rootOrgId,
  isEdit,
}: UserAssignmentProps) {
  const { rootOrganization } = useRootOrganization()
  const { teams, isLoading } = useGetTeams({
    filters: {
      fields__include: 'visible_orgs_ids',
      owner_org_id: rootOrganization.id,
      is_system_managed: 'false',
      limit: '1000',
    },
    enabled: !!rootOrganization.id,
  })

  const { organizations: allOrganizations = [], isFetchingAll } =
    useAllOrganizations({
      filters: {
        self_and_descendants_for_id: rootOrgId,
      },
      enabled: isLoading || (!isLoading && teams.length > 0),
    })

  const { watch } = useFormContext()

  const selectedTeamsIds = watch('teams_ids') || []

  const teamOrgIds = teams
    .filter(({ id }) => selectedTeamsIds.includes(id))
    .flatMap((team: Team) => team.visible_orgs_ids || [])

  return (
    <Stack spacing={2}>
      {isLoading || (teams.length > 0 && isFetchingAll) ? (
        <Stack direction="row" spacing={2} sx={{ px: 3 }}>
          <Stack spacing={2} sx={{ width: '250px', mt: 1 }}>
            <Skeleton variant="rounded" animation="pulse" />
            <Skeleton variant="rounded" animation="pulse" />
            <Skeleton variant="rounded" animation="pulse" />
            <Skeleton variant="rounded" animation="pulse" />
            <Skeleton variant="rounded" animation="pulse" />
          </Stack>
          <OrgTreeSkeleton />
        </Stack>
      ) : (
        <>
          {!isEdit && (
            <Alert severity="info" sx={{ mx: 2 }}>
              Add users to teams to give them access to organizations and their
              documents. Users can be created without assigning them to a team,
              but that user will not be able to see any documents.
            </Alert>
          )}
          {teams.length > 0 ? (
            <Stack direction="row" spacing={2} sx={{ px: 3 }}>
              <FormControl sx={{ rowGap: 1, width: '250px' }}>
                <FormLabel>Assign to Teams</FormLabel>

                <FormGroup>
                  {teams.map((team) => (
                    <FormMultiSelectCheckbox
                      key={team.id}
                      name="teams_ids"
                      label={team.name}
                      value={team.id}
                    />
                  ))}
                </FormGroup>
              </FormControl>

              {rootOrgId && (
                <OrganizationTreeWithImplicitInheritanceSearch
                  checkboxIsDisabled
                  label="Assign to Organizations"
                  helperText="Organizations that are already assigned to a team will be implicitly selected and can't be unselected."
                  organizations={allOrganizations}
                  rootTreeOrgId={rootOrgId}
                  selectedOrgsIds={teamOrgIds}
                  showSearchInput={false}
                />
              )}
            </Stack>
          ) : (
            <Typography sx={{ mx: '16px !important' }}>
              No teams have been created yet. You can create one on the{' '}
              <Link
                href="/settings/teams"
                target="_blank"
                sx={{ textDecoration: 'none' }}
              >
                Teams
              </Link>{' '}
              dashboard.
            </Typography>
          )}
        </>
      )}
    </Stack>
  )
}
