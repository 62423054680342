import { ReactNode } from 'react'
import { ThemeProvider, PaletteColor } from '@mui/material/styles'
import getComponentOverrides from './getComponentOverrides'
import usePixydocsTheme from './usePixydocsTheme'

declare module '@mui/material/styles' {
  interface Theme {
    appDrawer: {
      width: number
    }
  }

  interface Palette {
    neutral: PaletteColor
    orange: PaletteColor
    green: PaletteColor
    blue: PaletteColor
    teal: PaletteColor
    purple: PaletteColor
    indigo: PaletteColor
    blueGrey: PaletteColor
    yellow: PaletteColor
    red: PaletteColor
    pink: PaletteColor
    deepPurple: PaletteColor
  }

  interface ThemeOptions {
    appDrawer?: {
      width: number
    }
  }
}

declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
    pink: true
  }
  interface ChipPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
  interface AppBarPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
  interface CheckboxPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
  interface SwitchPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
  interface TextFieldPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
  interface RadioPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
  interface SvgIconPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
  interface CircularProgressPropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
  interface BadgePropsColorOverrides {
    neutral: true
    green: true
    orange: true
    blue: true
  }
}

export default function ZerapixThemeProvider({
  children,
}: {
  children: ReactNode
}) {
  const theme = usePixydocsTheme()
  theme.components = getComponentOverrides(theme)
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
