import { useEffect, useState } from 'react'
import { useNodeId, useReactFlow } from 'reactflow'
import useEdgeFromHandle from './useEdgeFromHandle'
import { useDataTypeInheritance } from '../InheritancesProvider'
import { LogixHandle } from '@/types/logix'
import { getHandleFromList } from '../helpers/logix-helpers'

// Possible inheritance situations:
// - Input inherits from connected output
// - Output inherits from input on the same node ("For Each" iteration item output)
// - Input inherits from sibling input ("Equals" node inputs)

export default function useSyncInheritances(logixHandle: LogixHandle) {
  const nodeId = useNodeId() // Gets the ID of the node the handle is a part of
  const reactFlowInstance = useReactFlow()
  const { inheritances, setInheritances } = useDataTypeInheritance()

  const { data_type_match_key, id: handleId } = logixHandle

  const groupKey = `${data_type_match_key}${nodeId}`
  const inheritance = inheritances[groupKey]

  // Get connected edge, if there is one
  const edge = useEdgeFromHandle(handleId)

  const [isInheritingFromEdge, setIsInheritingFromEdge] = useState(false)

  // On edge connect, update inheritance type
  useEffect(() => {
    if (!data_type_match_key) return

    // Only update inheritance key structure if there's an edge, a key, and no existing structure
    if (edge && !inheritance) {
      const {
        source: sourceNodeId,
        target: targetNodeId,
        targetHandle: targetHandleId,
        sourceHandle: sourceHandleId,
      } = edge

      if (!sourceNodeId || !targetNodeId || !sourceHandleId || !targetHandleId)
        return

      setIsInheritingFromEdge(true)

      const otherNodeId = edge.source === nodeId ? edge.target : edge.source

      const otherHandleId =
        edge.sourceHandle === logixHandle.id
          ? edge.targetHandle
          : edge.sourceHandle

      const otherNode = reactFlowInstance.getNode(otherNodeId)

      if (!otherNode || !otherHandleId) return

      const otherHandle = getHandleFromList(otherHandleId, [
        ...otherNode.data.logixNode.node_type.type_in_handles,
        ...otherNode.data.logixNode.node_type.type_out_handles,
        ...otherNode.data.logixNode.dyn_in_handles,
        ...otherNode.data.logixNode.dyn_out_handles,
      ])

      if (!otherHandle) return

      setInheritances((inheritances) => ({
        ...inheritances,
        [groupKey]: otherHandle.data_type_code,
      }))
    }
    // If the edge is removed, clear the inheritance structure for this key
    else if (!edge && inheritance && isInheritingFromEdge) {
      setInheritances((inheritances) => ({
        ...inheritances,
        [groupKey]: null,
      }))
      setIsInheritingFromEdge(false)
    }
  }, [
    edge,
    data_type_match_key,
    inheritance,
    isInheritingFromEdge,
    setInheritances,
    nodeId,
    logixHandle.id,
    reactFlowInstance,
    groupKey,
  ])
}
