import { Dispatch, SetStateAction } from 'react'
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  ListItem,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { Organization } from '@/types/organizations'
import { getOrgName } from '@/utils/organization'

type SearchAutocomplete = AutocompleteProps<
  Organization,
  true,
  true,
  false,
  'div'
>

type OrganizationsSearchInputProps = {
  organizations: Organization[]
  inputValue: SearchAutocomplete['inputValue']
  setInputValue: Dispatch<SetStateAction<string>>
  setSelected: (orgs: Organization[]) => void
  autoFocus?: boolean
  helperText?: string
  implicitlySelectedIds?: string[]
  isLoading?: boolean
  label?: string
  placeholder?: string
  selected?: Organization[]
  variant?: TextFieldProps['variant']
} & Omit<Partial<SearchAutocomplete>, 'onChange'>

export default function OrganizationsSearchInput({
  organizations,
  setInputValue,
  setSelected,
  autoFocus,
  helperText,
  implicitlySelectedIds,
  isLoading = false,
  label = 'Search Organizations',
  placeholder = 'Search Organizations...',
  selected,
  variant = 'filled',
  ...props
}: OrganizationsSearchInputProps) {
  return (
    <Autocomplete<Organization, true, true, false>
      multiple
      value={selected}
      autoHighlight
      options={organizations}
      clearOnEscape
      clearOnBlur
      disableClearable
      disableCloseOnSelect
      loading={isLoading}
      limitTags={1}
      onChange={(_e, orgs) => {
        if (orgs) setSelected(orgs)
      }}
      onInputChange={(_e, value, reason) => {
        if (reason === 'reset') {
          setTimeout(() => {
            setInputValue('')
          }, 100)
        } else {
          setInputValue(value)
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            autoFocus={autoFocus}
            helperText={helperText}
            label={label}
            placeholder={placeholder}
            variant={variant}
          />
        )
      }}
      getOptionLabel={(org) => getOrgName(org)}
      renderOption={(props, org, { selected }) => {
        const isImplicitlySelected = implicitlySelectedIds?.includes(org.id)
        return (
          <ListItem
            {...props}
            aria-disabled={!selected ? isImplicitlySelected : false}
            key={org.id}
            dense
            disablePadding
            sx={{ columnGap: 0.5 }}
          >
            <Checkbox
              color="neutral"
              sx={{ padding: 0 }}
              checked={isImplicitlySelected || selected}
              disabled={
                !selected && isImplicitlySelected ? isImplicitlySelected : false
              }
            />
            <Typography noWrap>
              {getOrgName(org)}
              {org.code && (
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {' '}
                  ({org.code})
                </Typography>
              )}
            </Typography>
          </ListItem>
        )
      }}
      renderTags={() => null} // Doing this so we don't get the selected values chips
      {...props}
    />
  )
}
