import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import {
  Stack,
  Button,
  Skeleton,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import {
  ProjectDocumentRuleType,
  ProjectGridFieldRule,
  ProjectGridFieldRuleType,
} from '@/types/rules'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import CommonRuleConfiguration from '@/components/rules/CommonRuleConfiguration'
import { FormAutocomplete, PixyDocsForm } from '@/components/forms'
import { getDefaultValue, RuleProperty } from '@/utils/rules'

export type FormValues<T> = {
  type: T
  workflow_states_ids: string[]
  dependent_fields: Pick<ProjectGridField, 'id'>[]
  is_superuser_rule: boolean
} & ProjectGridFieldRule['params'] // params type is the same for both document and rule types

type AddRuleDialogProps<
  T extends ProjectGridFieldRuleType | ProjectDocumentRuleType,
> = {
  isLoading: boolean
  overlay: OverlayState
  ruleTypes: T[]
  onSubmit: (values: FormValues<T>) => void
  fieldId?: string
}

function getPropertyFormValues(
  rule: ProjectGridFieldRuleType | ProjectDocumentRuleType,
) {
  const { params_schema } = rule

  // Sort the properties by sort_order
  const properties = Object.entries(
    (params_schema.properties as RuleProperty) || [],
  ).sort((p1, p2) => p1[1].sort_order - p2[1].sort_order)

  // We use 'params' for the default values, but if any are missing we need to give
  // them a default of an empty string to prevent uncontrolled-to-controlled errors.
  const defaultValues = properties.reduce((acc, [propertyId, property]) => {
    if (property.hidden) return acc
    return {
      ...acc,
      [propertyId]: getDefaultValue(property) ?? '',
    }
  }, {})

  return {
    defaultValues: {
      type: rule,
      dependent_fields: [],
      workflow_states_ids: [],
      is_superuser_rule: false,
      ...defaultValues,
    },
    properties,
  }
}

export default function AddRuleDialog<
  T extends ProjectGridFieldRuleType | ProjectDocumentRuleType,
>({ isLoading, ruleTypes, overlay, onSubmit, fieldId }: AddRuleDialogProps<T>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [currentProperties, setCurrentProperties] = useState<[string, any][]>(
    [],
  )

  const methods = useForm<FormValues<T>>({
    defaultValues: {
      type: undefined,
      dependent_fields: [],
      workflow_states_ids: [],
      is_superuser_rule: false,
    },
    // This will unregister values during unmount. Since we are doing this, we don't call reset since all values become undefined
    // when closing the dialog, and the logic in the useEffect will take care of setting the values again.
    shouldUnregister: true,
  })

  const {
    formState: { isValid },
    watch,
    setValue,
  } = methods

  const newType = watch('type')
  const newTypeRef = useRef(newType) // newType always returns a new object, so we use the ref to avoid unnecessary rerenders

  function onClose() {
    setCurrentProperties([])
    overlay.close()
  }

  function handleSubmit(values: FormValues<T>) {
    onSubmit(values)
    onClose()
  }

  useEffect(() => {
    if (
      newTypeRef.current &&
      JSON.stringify(newTypeRef.current) === JSON.stringify(newType)
    ) {
      return
    }

    const ruleToUse =
      !newType && ruleTypes[0]
        ? ruleTypes[0]
        : newType && newTypeRef.current.id !== newType.id
        ? newType
        : undefined

    if (ruleToUse) {
      const { defaultValues, properties } = getPropertyFormValues(ruleToUse)
      setCurrentProperties(properties)
      // We don't use reset because there are default values that we need to keep even when the dialog
      // doesn't show them (using reset would make "unregister" trigger and remove those values)
      Object.entries(defaultValues).forEach(([name, value]) => {
        setValue(name, value)
      })
      newTypeRef.current = ruleToUse
    }
  }, [newType, ruleTypes, setValue])

  return (
    <Dialog
      {...overlay}
      maxWidth="sm"
      onClose={onClose}
      title={`Add ${fieldId ? 'Field' : 'Document'} Rule`}
    >
      <DialogContent>
        <PixyDocsForm id="rule-form" methods={methods} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {isLoading || !newTypeRef.current?.id ? (
              <Stack spacing={2}>
                <Skeleton variant="rounded" height={30} />
                <Skeleton variant="rounded" height={30} />
                <Skeleton variant="rounded" height={30} />
              </Stack>
            ) : (
              <>
                <FormAutocomplete
                  name="type"
                  label="Rule"
                  autoHighlight
                  options={ruleTypes}
                  getOptionLabel={(option) => option?.name || ''}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  renderOption={(props, { name, id, description }) => {
                    return (
                      <ListItemButton component="li" {...props} key={id} dense>
                        <ListItemText
                          primary={name}
                          secondary={description}
                          secondaryTypographyProps={{ variant: 'caption' }}
                        />
                      </ListItemButton>
                    )
                  }}
                  disableClearable
                  helperText={newTypeRef.current.description}
                />
                <CommonRuleConfiguration
                  propertiesToRender={currentProperties}
                  ruleType={newTypeRef.current}
                  fieldId={fieldId}
                />
              </>
            )}
          </Stack>
        </PixyDocsForm>
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          form="rule-form"
          disabled={!isValid}
          variant="contained"
          type="submit"
        >
          Save
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
