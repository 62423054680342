import { ProjectMappingType } from '@/types/project-mappings'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<ProjectMappingType>({
  pathCategory: 'project_mapping_types',
})

export function useGetProjectMappingType({
  id,
  ...options
}: UseGetDetailOptions<ProjectMappingType>) {
  const query = useGetDetail<ProjectMappingType>({
    id,
    queryKey: queryKeys.projectMappingTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectMappingType: query.data,
    ...query,
  }
}

export function useGetProjectMappingTypes({
  filters,
  ...options
}: UseGetListOptions<ProjectMappingType> = {}) {
  const query = useGetList<ProjectMappingType>({
    filters,
    queryKey: queryKeys.projectMappingTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectMappingTypes: query.allData,
    ...query,
  }
}
