import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'

type NavigationRailPortalContext = {
  portalNode: Element
  setPortalNode: Dispatch<SetStateAction<Element>>
}

const NavigationRailPortalContext = createContext<NavigationRailPortalContext>(
  [] as unknown as NavigationRailPortalContext,
)

export const useNavigationRailPortalContext = () =>
  useContext(NavigationRailPortalContext)

export default function NavigationRailPortalProvider({
  children,
}: {
  children: ReactNode
}) {
  const [portalNode, setPortalNode] = useState<Element>(
    document.createElement('div'),
  )

  return (
    <NavigationRailPortalContext.Provider
      value={{
        portalNode,
        setPortalNode,
      }}
    >
      {children}
    </NavigationRailPortalContext.Provider>
  )
}
