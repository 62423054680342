import { Button } from '@mui/material'
import CodeEditorDialog from '../code-editor/CodeEditorDialog'
import useOverlay from '@/hooks/useOverlay'
import { useState } from 'react'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Edit } from '@mui/icons-material'

const ACTION_SCRIPT_TEMPLATE = `# This script can manage this document's workflow state, create audit trail entries, and much more.
# Ask support for any help you might need.

# Please note that this feature is still experimental and may not operate as expected.

def custom_action(document, workflow_states):
    return None
`

type CodeEditorButtonProps = {
  action: WorkflowStateAction
}

export default function CodeEditorButton({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: _action,
}: CodeEditorButtonProps) {
  const overlay = useOverlay()
  const [value, setValue] = useState('')

  return (
    <>
      <Button variant="outlined" startIcon={<Edit />} onClick={overlay.open}>
        Edit Script
      </Button>
      <CodeEditorDialog
        overlay={overlay}
        title="Custom Python Action"
        value={value || ACTION_SCRIPT_TEMPLATE}
        setValue={setValue}
        onSave={() => {
          overlay.close()
        }}
      />
    </>
  )
}
