/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from 'react'
import ZerapixTable, { ZerapixTableProps } from './ZerapixTable'
import useInfiniteQuery from '@/services/hooks/useInfiniteQuery'
import { useScrollContainer } from '@/components/scroll-container/ScrollContainer'

type InfiniteScrollTableProps<DataType extends Record<string, any>> =
  ZerapixTableProps<DataType> & {
    query: ReturnType<typeof useInfiniteQuery>
  }

export default function InfiniteScrollTable<
  DataType extends Record<string, any>,
>({ table, query }: InfiniteScrollTableProps<DataType>) {
  const { isFetching, fetchNextPage, hasNextPage } = query

  /**
   * This table _must_ have a scroll container provider above it in the tree and that
   * provider must have the nearest scrolling container as it's value. Otherwise this
   * will not work, since it relies on the position of the scroll container to determine
   * when to fetch more data.
   */
  const scrollContainer = useScrollContainer()

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement && !isFetching) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          hasNextPage &&
          !isFetching
        ) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, hasNextPage, isFetching],
  )

  // Check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(scrollContainer?.current)
  }, [fetchMoreOnBottomReached, scrollContainer])

  useEffect(() => {
    const containerEl = scrollContainer?.current
    const handler = (event: Event) => {
      fetchMoreOnBottomReached(event.target as HTMLDivElement)
    }
    containerEl?.addEventListener('scroll', handler)
    return () => {
      containerEl?.removeEventListener('scroll', handler)
    }
  }, [fetchMoreOnBottomReached, scrollContainer])

  return <ZerapixTable<DataType> table={table} />
}
