import { ReactNode, useRef, useEffect } from 'react'
import {
  Dialog as MUIDialog,
  DialogTitle,
  DialogProps as MUIDialogProps,
  Typography,
} from '@mui/material'
import type { OverlayState } from '@/hooks/useOverlay'

export type DialogProps = {
  title?: ReactNode
  subtitle?: ReactNode
  children?: ReactNode
  onClose?: () => void
} & OverlayState &
  Pick<
    MUIDialogProps,
    'fullWidth' | 'maxWidth' | 'fullScreen' | 'TransitionProps'
  >

export default function Dialog({
  children,
  title,
  subtitle,
  isOpen,
  close,
  onClose,

  // These props are unused, but passed in from OverlayState.
  open, // eslint-disable-line @typescript-eslint/no-unused-vars
  anchorEl, // eslint-disable-line @typescript-eslint/no-unused-vars
  toggle, // eslint-disable-line @typescript-eslint/no-unused-vars

  ...props
}: DialogProps) {
  const wasOpenRef = useRef(isOpen)

  const handleOnClose = () => {
    onClose?.()
    close()
  }

  // This runs onClose whenever the dialog closes.
  // Why not do it in useOverlay?
  // - This is closer to the dialog. Consumer may not want to define onClose at same level as useOverlay.
  // Why not use MUI's onClose?
  // - It only triggers when closed with their closing behaviors (esc key or click-away).
  // - We need it to trigger whenever the dialog is closed, even with overlay.close().
  useEffect(() => {
    if (isOpen !== wasOpenRef.current) {
      if (wasOpenRef.current) {
        onClose?.()
      }
      wasOpenRef.current === isOpen
    }
  }, [isOpen, onClose])

  return (
    <MUIDialog
      fullWidth
      open={isOpen}
      onClose={handleOnClose}
      {...props}
      scroll="paper"
    >
      {title && (
        <DialogTitle>
          {title}
          {subtitle && (
            <Typography variant="caption" component="div">
              {subtitle}
            </Typography>
          )}
        </DialogTitle>
      )}
      {children}
    </MUIDialog>
  )
}
