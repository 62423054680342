import {
  ReactNode,
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import { DialogContent as MUIDialogContent, Typography } from '@mui/material'

type DialogContentProps = {
  children: ReactNode
  disablePadding?: boolean
  // Set the height of the dialog content when it is scrollable
  setClientHeight?: Dispatch<SetStateAction<number | undefined>>
}

export default function DialogContent({
  children,
  disablePadding,
  setClientHeight,
}: DialogContentProps) {
  const [showDividers, setShowDividers] = useState(false)
  const contentRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const currentElement = contentRef.current

      const shouldShow =
        currentElement &&
        currentElement.scrollHeight > currentElement.clientHeight

      if (shouldShow) {
        setClientHeight?.(currentElement.clientHeight)
      }
      setShowDividers(!!shouldShow)
    })
    if (contentRef.current) observer.observe(contentRef.current)
    return () => {
      observer.disconnect()
    }
  }, [setClientHeight])

  return (
    <MUIDialogContent
      ref={contentRef}
      dividers={showDividers}
      sx={{ p: disablePadding ? 0 : undefined }}
    >
      <Typography component="div" sx={{ pt: 1 }}>
        {children}
      </Typography>
    </MUIDialogContent>
  )
}
