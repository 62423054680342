import { useCallback, useEffect, useRef, useState } from 'react'
import { useBeforeUnload, useLocation } from 'react-router-dom'

type UseTrackActiveTimeOptions = {
  onReport: (startTime: Date, endTime: Date) => void
}

const isActiveTimeMeaningful = (startTime: Date) => {
  return new Date().getTime() - startTime.getTime() > 1000
}

export default function useTrackActiveTime({
  onReport,
}: UseTrackActiveTimeOptions) {
  const location = useLocation()
  const startTimeRef = useRef<Date | null>(null)
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const [idle, setIdle] = useState<boolean>(false)

  const sendReportAndResetTime = useCallback(() => {
    if (startTimeRef.current && isActiveTimeMeaningful(startTimeRef.current)) {
      onReport(startTimeRef.current, new Date())
    }
    startTimeRef.current = null
  }, [onReport])

  useEffect(() => {
    setIdle(false)
    startTimeRef.current = new Date()

    // We call report time when the path changes
    return () => {
      sendReportAndResetTime()
    }
  }, [location.pathname, sendReportAndResetTime])

  // We call report time when user becomes idle
  useEffect(() => {
    if (idle) {
      sendReportAndResetTime()
    }
  }, [idle, sendReportAndResetTime])

  // We call report time before page unloads
  useBeforeUnload(
    useCallback(() => {
      sendReportAndResetTime()
    }, [sendReportAndResetTime]),
  )

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (startTimeRef.current) {
        setIdle(true)
      }
    }, 5000)
    const interval = setInterval(() => {
      timerRef.current = setTimeout(() => {
        if (startTimeRef.current) {
          setIdle(true)
        }
      }, 5000)
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const clearTimer = () => {
      setIdle(false)
      // We set the new starting time when user is active again
      if (!startTimeRef.current) {
        startTimeRef.current = new Date()
      }
      clearTimeout(timerRef.current)
    }
    window.addEventListener('mousedown', clearTimer)
    window.addEventListener('keydown', clearTimer)
    window.addEventListener('scroll', clearTimer)
    window.addEventListener('mousemove', clearTimer)

    return () => {
      window.removeEventListener('mousedown', clearTimer)
      window.removeEventListener('keydown', clearTimer)
      window.removeEventListener('scroll', clearTimer)
      window.removeEventListener('mousemove', clearTimer)
      clearTimeout(timerRef.current)
    }
  }, [])
}
