import { DataListEntryCellValue } from '@/types/data-lists'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<DataListEntryCellValue>({
  pathCategory: 'data_list_entry_cell_values',
})

export function useGetDataListEntryCellValue({
  id,
  ...options
}: UseGetDetailOptions<DataListEntryCellValue>) {
  const query = useGetDetail<DataListEntryCellValue>({
    id,
    queryKey: queryKeys.dataListEntryCellValues.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    dataListEntryCellValue: query.data,
    ...query,
  }
}

export function useCreateDataListEntryCellValue(
  options: UseCreateDetailOptions<DataListEntryCellValue> = {},
) {
  const mutation = useCreateDetail<DataListEntryCellValue>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDataListEntryCellValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDataListEntryCellValue(
  options: UseUpdateDetailOptions<DataListEntryCellValue> = {},
) {
  const mutation = useUpdateDetail<DataListEntryCellValue>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDataListEntryCellValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataListEntryCellValue(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<DataListEntryCellValue>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDataListEntryCellValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDataListEntryCellValues({
  filters,
  ...options
}: UseGetListOptions<DataListEntryCellValue> = {}) {
  const query = useGetList<DataListEntryCellValue>({
    filters,
    queryKey: queryKeys.dataListEntryCellValues.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    dataListEntryCellValues: query.allData,
    ...query,
  }
}

export function useUpdateDataListEntryCellValues(
  options: UseUpdateListOptions<DataListEntryCellValue> = {},
) {
  const mutation = useUpdateList<DataListEntryCellValue>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDataListEntryCellValues: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataListEntries({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DataListEntryCellValue>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDataListEntryCellValues: mutation.mutateAsync,
    ...mutation,
  }
}
