import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Project, ProjectGrid } from '@/types/projects'
import useOverlay from '@/hooks/useOverlay'
import {
  getBaseGrid,
  getNonGridFields,
  sortBySortOrder,
} from '@/utils/field-utils'
import DocumentDropZone from '@/components/drop-zone/DocumentDropZone'
import {
  DocumentRowsTable,
  DocumentTable,
} from '@/components/document-table/DashboardTable'
import { useFileUploadContext } from '@/components/upload-files/FileUploadProvider'
import ColumnVisibilityProvider from '@/components/workflows/ColumnVisibilityProvider'
import ColumnOrderProvider from '@/components/workflows/ColumnOrderProvider'
import ColumnSortingProvider from '@/components/workflows/ColumnSortingProvider'
import SelectedGridsProvider from '@/components/workflows/SelectedGridsProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import SelectedWorkflowStateProvider, {
  useSelectedWorkflowStateContext,
} from '@/components/workflows/SelectedWorkflowStateProvider'
import { useRecentOrganizationsContext } from '@/components/organizations/RecentOrganizationsProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import ProjectDashboardFilterDrawer from './ProjectDashboardFilterDrawer'

type ProjectDashboardDisplayProps = {
  project: Project
}

function ProjectDashboardDisplay({ project }: ProjectDashboardDisplayProps) {
  const { view } = useParams()
  const [isFullscreen, setIsFullscreen] = useState(false)

  const drawer = useOverlay({
    initiallyOpen: true,
    localStorageKey: `project-dashboard-drawer-open-${project.id}`,
  })

  const { selectedWorkflow } = useSelectedWorkflowContext()

  const baseGridWithoutGridFields = useMemo(() => {
    const baseGrid = getBaseGrid(
      project.project_grids as ProjectGrid[],
    ) as ProjectGrid
    return {
      ...baseGrid,
      //TODO: Remove sorting for when the backend returns data sorted
      project_grid_fields: sortBySortOrder(
        getNonGridFields(baseGrid.project_grid_fields),
      ),
    }
  }, [project.project_grids])

  const { handleFilesSelectedToUpload, handleConfirmUploadFiles } =
    useFileUploadContext()

  const [recentOrgs] = useRecentOrganizationsContext()

  const { selectedWorkflowState, setWorkflowState } =
    useSelectedWorkflowStateContext()

  return (
    <ColumnOrderProvider>
      <ColumnSortingProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SelectedGridsProvider
            baseGridWithoutGridFields={baseGridWithoutGridFields}
            grids={project.project_grids as ProjectGrid[]}
          >
            <ColumnVisibilityProvider
              baseGridNonGridFields={
                baseGridWithoutGridFields.project_grid_fields
              }
            >
              <ProjectDashboardFilterDrawer
                overlay={drawer}
                workflowStates={selectedWorkflow?.workflow_states || []}
                selectedWorkflowState={selectedWorkflowState}
                setWorkflowState={setWorkflowState}
                showAllFields={view === 'data'}
              />

              {selectedWorkflowState && (
                <DocumentDropZone
                  onDrop={handleFilesSelectedToUpload}
                  onOrgDrop={handleConfirmUploadFiles}
                  recentOrgs={recentOrgs}
                  isFullscreen={isFullscreen}
                >
                  <Box sx={{ flexGrow: 1, height: '100%' }}>
                    {view === 'documents' ? (
                      <DocumentTable
                        state={selectedWorkflowState}
                        workflow={selectedWorkflow}
                        isFullscreen={isFullscreen}
                        setIsFullscreen={setIsFullscreen}
                      />
                    ) : (
                      <DocumentRowsTable
                        state={selectedWorkflowState}
                        workflow={selectedWorkflow}
                        isFullscreen={isFullscreen}
                        setIsFullscreen={setIsFullscreen}
                      />
                    )}
                  </Box>
                </DocumentDropZone>
              )}
            </ColumnVisibilityProvider>
          </SelectedGridsProvider>
        </LocalizationProvider>
      </ColumnSortingProvider>
    </ColumnOrderProvider>
  )
}

export default function ProjectDashboard() {
  const { project } = useProjectContext()
  const { view } = useParams()

  return (
    <SelectedWorkflowStateProvider>
      {/* Using a key here makes it so it fully re-renders when switching views, so we don't get lingering side effects */}
      <ProjectDashboardDisplay key={view} project={project} />
    </SelectedWorkflowStateProvider>
  )
}
