import { useEffect, useMemo } from 'react'
import {
  Background,
  Edge,
  Node,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react'
import { ProjectModel, ProjectModelType } from '@/types/project-models'
import { Box, useTheme } from '@mui/material'
import { useGetProjectModelTrainingFields } from '@/service-library/hooks/project-model-training-fields'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import ModelNode from './ModelFieldNodes/ModelNode'
import AddFieldNode from './ModelFieldNodes/EditTrainingFieldNode'
import TrainingFieldNode from './ModelFieldNodes/TrainingFieldNode'
import useModelNodeMeasurements from './ModelFieldNodes/useModelNodeMeasurements'

type ModelOutputsDisplayProps = {
  projectModel: ProjectModel
}

const nodeTypes = {
  field: TrainingFieldNode,
  model: ModelNode,
}

const INPUTS_X = 100
const NODE_Y_GAP = 36
const FIELD_NODE_WIDTH = 200
const FIELD_NODE_HEIGHT = 28

export default function ModelInputOutputsDisplay({
  projectModel,
}: ModelOutputsDisplayProps) {
  const { fieldsMap } = useProjectContext()
  const theme = useTheme()

  const reactFlowInstance = useReactFlow()

  const modelNodeMeasurements = useModelNodeMeasurements({
    projectModel,
  })

  const {
    projectModelTrainingFields = [],
    queryKey,
    isLoading,
  } = useGetProjectModelTrainingFields({
    filters: {
      limit: '1000',
      project_model_id: projectModel.id,
    },
  })

  const { max_ins, max_outs } = projectModel.project_model_type || {}

  const inputModelTrainingFields = useMemo(
    () => projectModelTrainingFields.filter((field) => field.flow === 'in'),
    [projectModelTrainingFields],
  )

  const outputModelTrainingFields = useMemo(
    () => projectModelTrainingFields.filter((field) => field.flow === 'out'),
    [projectModelTrainingFields],
  )

  const inputStackYSize = (inputModelTrainingFields.length - 1) * NODE_Y_GAP
  const outputStackYSize = (outputModelTrainingFields.length - 1) * NODE_Y_GAP
  const mainMidYPoint = Math.max(inputStackYSize, outputStackYSize) / 2 + 100

  let inputYStartingPosition = 100
  let outputYStartingPosition = 100
  if (outputStackYSize > inputStackYSize) {
    inputYStartingPosition = mainMidYPoint - inputStackYSize / 2
  } else {
    outputYStartingPosition = mainMidYPoint - outputStackYSize / 2
  }

  const xGapInputs = Math.max(inputStackYSize / 2.5, 48)
  const xGapOutputs = Math.max(outputStackYSize / 2.5, 48)

  const [nodes, setNodes, onNodesChange] = useNodesState([] as Node[])
  const [edges, setEdges, onEdgesChange] = useEdgesState([] as Edge[])

  const initialNodes = useMemo(() => {
    const inputFieldNodes = inputModelTrainingFields.map(
      (inputField, index) => {
        return {
          id: inputField.id,
          type: 'field',
          data: {
            type: 'input',
            label: fieldsMap[inputField.project_grid_field_id].name,
            showSourceHandle: true,
            showTargetHandle: false,
          },
          draggable: false,
          position: {
            x: INPUTS_X,
            y: inputYStartingPosition + index * NODE_Y_GAP,
          },
          style: {
            background: 'transparent',
            color: theme.palette.text.primary,
            border: 'solid 0px transparent',
            padding: 0,
            width: FIELD_NODE_WIDTH,
          },
        }
      },
    )

    const modified_output_x =
      INPUTS_X + // Start of input nodes
      xGapInputs + // Gap between input nodes and model node
      FIELD_NODE_WIDTH + // account for width of the widest input node
      (modelNodeMeasurements.width || 0) + // account for width of the "model" node
      xGapOutputs // Gap between model node and output nodes
    const outputFieldNodes = outputModelTrainingFields.map(
      (outputField, index) => {
        return {
          id: outputField.id,
          type: 'field',
          data: {
            type: 'output',
            label: fieldsMap[outputField.project_grid_field_id].name,
            showSourceHandle: false,
            showTargetHandle: true,
          },
          draggable: false,
          position: {
            x: modified_output_x,
            y: outputYStartingPosition + index * NODE_Y_GAP,
          },
          style: {
            background: 'transparent',
            color: theme.palette.text.primary,
            border: 'solid 0px transparent',
            padding: 0,
            width: FIELD_NODE_WIDTH,
          },
        }
      },
    )

    return [
      ...inputFieldNodes,
      ...outputFieldNodes,
      {
        id: projectModel.id,
        type: 'model',
        data: {
          showTargetHandle: max_ins !== 0,
          showSourceHandle: max_outs !== 0,
          projectModel,
        },
        draggable: false,
        selectable: false,
        position: {
          x: INPUTS_X + xGapInputs + FIELD_NODE_WIDTH,
          y:
            mainMidYPoint -
            (modelNodeMeasurements.height || 1) / 2 +
            FIELD_NODE_HEIGHT / 2,
        },
        style: {
          background: 'transparent',
          color: theme.palette.text.primary,
          border: 'solid 0px transparent',
        },
      },
    ]
  }, [
    fieldsMap,
    inputModelTrainingFields,
    inputYStartingPosition,
    mainMidYPoint,
    max_ins,
    max_outs,
    modelNodeMeasurements.height,
    modelNodeMeasurements.width,
    outputModelTrainingFields,
    outputYStartingPosition,
    projectModel,
    theme.palette.text.primary,
    xGapInputs,
    xGapOutputs,
  ])

  const initialEdges = useMemo(() => {
    const fieldToModelEdges = projectModelTrainingFields.map(
      (trainingField) => {
        return {
          id: `${projectModel.id}-${trainingField.id}`,
          source:
            trainingField.flow === 'in' ? trainingField.id : projectModel.id,
          target:
            trainingField.flow === 'in' ? projectModel.id : trainingField.id,
          type: 'default',
          animated: true,
          selectable: false,
        }
      },
    )
    return fieldToModelEdges
  }, [projectModel.id, projectModelTrainingFields])

  useEffect(() => {
    setNodes(initialNodes)
  }, [initialNodes, setNodes])

  useEffect(() => {
    setEdges(initialEdges)
  }, [initialEdges, setEdges])

  useEffect(() => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView({
          padding: 1,
          duration: 500,
        })
      }, 200)
    }
  }, [reactFlowInstance, projectModel.id])

  if (isLoading) {
    return null
  }

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        height: '100%',
        '& svg': {
          overflow: 'visible !important', // Something in MUI is messing with this, so this is a hack to fix it.
          position: 'absolute !important',
        },
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
      >
        <Background />
        <Panel position="top-left">
          <AddFieldNode
            listQueryKey={queryKey}
            flow="in"
            disabled={max_ins == 0}
            fields={Object.values(fieldsMap)}
            projectModelId={projectModel.id}
            projectModelType={
              projectModel.project_model_type as ProjectModelType
            }
            modelTrainingFields={{
              inputs: inputModelTrainingFields,
              outputs: outputModelTrainingFields,
            }}
          />
        </Panel>
        <Panel position="top-right">
          <AddFieldNode
            listQueryKey={queryKey}
            flow="out"
            disabled={
              projectModel.project_model_type?.code === 'Category' ||
              max_outs == 0
            }
            fields={Object.values(fieldsMap)}
            projectModelId={projectModel.id}
            projectModelType={
              projectModel.project_model_type as ProjectModelType
            }
            modelTrainingFields={{
              inputs: inputModelTrainingFields,
              outputs: outputModelTrainingFields,
            }}
          />
        </Panel>
      </ReactFlow>
    </Box>
  )
}
