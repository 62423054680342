import { ProjectMappingField } from '@/types/project-mapping-fields'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<ProjectMappingField>({
  pathCategory: 'project_mapping_fields',
})

export function useGetProjectMappingField({
  id,
  ...options
}: UseGetDetailOptions<ProjectMappingField>) {
  const query = useGetDetail<ProjectMappingField>({
    id,
    queryKey: queryKeys.projectMappingFields.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectMappingField: query.data,
    ...query,
  }
}

export function useCreateProjectMappingField(
  options: UseCreateDetailOptions<ProjectMappingField> = {},
) {
  const mutation = useCreateDetail<ProjectMappingField>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectMappingField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectMappingField(
  options: UseUpdateDetailOptions<ProjectMappingField> = {},
) {
  const mutation = useUpdateDetail<ProjectMappingField>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectMappingField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectMappingField(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<ProjectMappingField>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectMappingField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectMappingFields({
  filters,
  ...options
}: UseGetListOptions<ProjectMappingField> = {}) {
  const query = useGetList<ProjectMappingField>({
    filters,
    queryKey: queryKeys.projectMappingFields.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectMappingFields: query.allData,
    ...query,
  }
}

export function useUpdateProjectMappingFields(
  options: UseUpdateListOptions<ProjectMappingField> = {},
) {
  const mutation = useUpdateList<ProjectMappingField>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectMappingFields: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectMappingFields({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectMappingField>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectMappingFields: mutation.mutateAsync,
    ...mutation,
  }
}
