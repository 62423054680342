import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'

type TopBarPortalContextValue = {
  portalNode: Element
  setPortalNode: Dispatch<SetStateAction<Element>>
}

const TopBarPortalContext = createContext<TopBarPortalContextValue>(
  [] as unknown as TopBarPortalContextValue,
)

export const useTopBarPortalContext = () => useContext(TopBarPortalContext)

export default function TopBarPortalProvider({
  children,
}: {
  children: ReactNode
}) {
  const [portalNode, setPortalNode] = useState<Element>(
    document.createElement('div'),
  )

  return (
    <TopBarPortalContext.Provider
      value={{
        portalNode,
        setPortalNode,
      }}
    >
      {children}
    </TopBarPortalContext.Provider>
  )
}
