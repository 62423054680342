import { Badge, Button } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import useExportFiles from '@/services/hooks/useExportFiles'
import DownloadExportDialog from './DownloadExportDialog'

type DownloadExportButtonProps = {
  projectId: string
}

export default function DownloadExportButton({
  projectId,
}: DownloadExportButtonProps) {
  const downloadOverlay = useOverlay()
  const exportFiles = useExportFiles(projectId)
  const totalFiles = (exportFiles.files ?? []).length
  const processedFiles = (exportFiles.files ?? []).filter(
    (file) => !file.processing,
  ).length
  const processingFiles = totalFiles - processedFiles
  const isLoading = processingFiles > 0 || exportFiles.isLoading

  return processedFiles > 0 ? (
    <Badge
      badgeContent={processedFiles}
      color="info"
      component="div"
      sx={{ maxWidth: 'max-content', mr: '8px !important' }}
    >
      <Button variant="text" size="small" onClick={downloadOverlay.open}>
        Exported Files
      </Button>
      <DownloadExportDialog
        overlay={downloadOverlay}
        isLoading={isLoading}
        exportFiles={exportFiles}
      />
    </Badge>
  ) : null
}
