import { memo } from 'react'
import { Card, Stack, Typography } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { ProjectModel } from '@/types/project-models'

function ModelNode({
  data,
}: {
  data: {
    showSourceHandle: boolean
    showTargetHandle: boolean
    projectModel: ProjectModel
  }
}) {
  const { showSourceHandle, showTargetHandle, projectModel } = data

  return (
    <Card
      sx={{
        py: 4,
        pl: showTargetHandle ? 12 : 4,
        pr: showSourceHandle ? 12 : 4,
        borderRadius: 2,
      }}
    >
      <Stack alignItems="center">
        <Typography variant="caption" textAlign="center" color="text.secondary">
          {projectModel.project_model_type?.name} Model
        </Typography>
        <Typography variant="h5" textAlign="center">
          <b>{projectModel.name}</b>
        </Typography>
      </Stack>

      {showSourceHandle && <Handle type="source" position={Position.Right} />}
      {showTargetHandle && <Handle type="target" position={Position.Left} />}

      {showTargetHandle && (
        <Typography
          variant="body2"
          textAlign="left"
          color="secondary.main"
          sx={{ position: 'absolute', top: '42.5%', left: 12 }}
        >
          <b>Inputs</b>
        </Typography>
      )}

      {showSourceHandle && (
        <Typography
          variant="body2"
          textAlign="left"
          color="primary.main"
          sx={{ position: 'absolute', top: '42.5%', right: 12 }}
        >
          <b>Outputs</b>
        </Typography>
      )}
    </Card>
  )
}

export default memo(ModelNode)
