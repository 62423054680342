import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import { useRegisterUser } from '@/service-library/hooks/users'
import { useGetGroups } from '@/service-library/hooks/groups'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { PixyDocsForm } from '@/components/forms'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { showErrorSnackbar } from '@/utils/snackbars'
import UserAssignment from './UserAssignment'
import UserForm from './UserCoreInfo'

type FormValues = {
  name: string
  email: string
  employee_identifier: string
  pd_groups_ids: string[]
  teams_ids: string[]
}

type AddUserDialogProps = {
  overlay: OverlayState
}

export default function AddUserDialog({ overlay }: AddUserDialogProps) {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const { hasEditingPermission } = usePermission()
  const { rootOrganization } = useRootOrganization()
  const groupsQuery = useGetGroups({
    enabled: overlay.isOpen,
    refetchOnWindowFocus: false,
  })

  const { registerUser } = useRegisterUser({
    onSuccess: ({ id }) => {
      navigate(`/settings/users/${id}`)
    },
    onError: () => {
      showErrorSnackbar('Unable to register user. Please try again later.')
    },
  })

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      email: '',
      employee_identifier: '',
      pd_groups_ids: [],
      teams_ids: [],
    },
  })

  const {
    formState: { isDirty, isValid },
    reset,
  } = methods

  function handleCreateUser(values: FormValues) {
    const newUser = {
      ...values,
      name: values.name.trim(),
      email: values.email.trim(),
      employee_identifier: values.employee_identifier.trim(),
      pd_groups: groupsQuery.groups?.filter(({ id }) =>
        values.pd_groups_ids.includes(id),
      ),
      owner_org_id: rootOrganization.id,
    }

    registerUser(newUser)
    overlay.close()
  }

  const canAssignToTeams = hasEditingPermission('edit_teams')
  const isUserInfoPage = page === 1

  useEffect(() => {
    if (!overlay.isOpen) {
      reset()
    } else {
      setPage(1) // Doing this here and not in onClose, so there is not a flickering because we're changing to the first page.
    }
  }, [overlay.isOpen, reset])

  return (
    <Dialog
      {...overlay}
      maxWidth={isUserInfoPage ? 'sm' : 'md'}
      title="Add User"
    >
      <DialogContent disablePadding>
        <PixyDocsForm
          id="user-form"
          methods={methods}
          onSubmit={handleCreateUser}
        >
          {isUserInfoPage ? (
            <UserForm overlay={overlay} groupsQuery={groupsQuery} />
          ) : (
            <UserAssignment rootOrgId={rootOrganization?.id} />
          )}
        </PixyDocsForm>
      </DialogContent>

      <DialogFooter>
        <Button
          variant="text"
          onClick={() => {
            if (isUserInfoPage) {
              overlay.close()
            } else {
              setPage(1)
            }
          }}
        >
          {isUserInfoPage || !canAssignToTeams ? 'Cancel' : 'Back'}
        </Button>
        {/* We can't put these buttons in a ternary condition because, for some unknown reason, it will also 
        submit the form when clicking on next. */}
        {isUserInfoPage && canAssignToTeams && (
          <Button
            disabled={!isDirty || !isValid} // This works because it only validates the current "page"
            onClick={() => {
              setPage(2)
            }}
          >
            Next
          </Button>
        )}
        {(!isUserInfoPage || !canAssignToTeams) && (
          <Button
            form="user-form"
            disabled={!isDirty || !isValid} // This works because it only validates the current "page"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        )}
      </DialogFooter>
    </Dialog>
  )
}
