import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  FormHelperText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import {
  DataList,
  DataListColumn,
  DataListColumnType,
} from '@/types/data-lists'
import { OverlayState } from '@/hooks/useOverlay'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import {
  FormAutocomplete,
  FormSwitch,
  FormTextField,
  PixyDocsForm,
} from '@/components/forms'
import { useGetDataListColumnTypes } from '@/service-library/hooks/data-list-column-types'
import {
  useCreateDataListColumn,
  useUpdateDataListColumn,
} from '@/service-library/hooks/data-list-columns'
import generateUuid from '@/utils/generate-uuid'
import { QueryKey } from '@tanstack/react-query'

type FormValues = {
  name: string
  data_list_column_type: DataListColumnType
  unique_values: boolean
}

type AddEditDataListColumnDialogProps = {
  dataList: DataList
  overlay: OverlayState
  dataListColumn?: DataListColumn
  onClose?: () => void
  listQueryKey: QueryKey
}

export default function AddEditDataListColumnDialog({
  dataList,
  overlay,
  dataListColumn,
  onClose,
  listQueryKey,
}: AddEditDataListColumnDialogProps) {
  const { dataListColumnTypes = [], isLoading } = useGetDataListColumnTypes({
    enabled: overlay.isOpen,
  })

  const { createDataListColumn } = useCreateDataListColumn({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to add column. Please try again later.')
    },
  })
  const { updateDataListColumn } = useUpdateDataListColumn({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update column. Please try again later.')
    },
  })

  const textType = useMemo(
    () =>
      dataListColumnTypes.find(
        (type) => type.name === 'Text',
      ) as DataListColumnType,
    [dataListColumnTypes],
  )

  const defaultValues = useMemo(
    () => ({
      name: dataListColumn?.name || '',
      data_list_column_type:
        dataListColumn?.data_list_column_type || textType || {},
      unique_values: dataListColumn?.unique_values || false,
    }),
    [
      dataListColumn?.name,
      dataListColumn?.data_list_column_type,
      dataListColumn?.unique_values,
      textType,
    ],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const {
    formState: { isDirty, isValid },
    register,
    reset,
    setValue,
    watch,
  } = methods

  function handleClose() {
    onClose?.()
    overlay.close()
  }

  function handleSubmit(values: FormValues) {
    if (dataListColumn) {
      updateDataListColumn({
        ...dataListColumn,
        ...values,
        data_list_column_type_id: values.data_list_column_type.id,
      })
    } else {
      createDataListColumn({
        id: generateUuid(),
        data_list_id: dataList.id,
        data_list_column_type_id: values.data_list_column_type.id,
        is_system_managed: false,
        sort_order: 100000,
        ...values,
      })
    }
    handleClose()
  }

  useEffect(() => {
    if (!dataListColumn && textType) {
      setValue('data_list_column_type', textType)
    }
  }, [dataListColumn, setValue, textType])

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  return (
    <Dialog
      {...overlay}
      title={`${dataListColumn ? 'Edit' : 'Add'} Column`}
      maxWidth="sm"
      onClose={handleClose}
    >
      <PixyDocsForm methods={methods} onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={4}>
            {/* adding check for type to avoid warnings for Autocomplete */}
            {isLoading || !watch('data_list_column_type').id ? (
              <>
                <Skeleton variant="rounded" height={30} />
                <Skeleton variant="rounded" height={30} />
                <Skeleton variant="rounded" height={30} />
              </>
            ) : (
              <>
                <FormTextField
                  autoFocus
                  fullWidth
                  label="Name"
                  required
                  {...register('name', {
                    validate: (value) => value.trim().length > 0,
                  })}
                />

                <FormAutocomplete
                  disabled={!!dataListColumn}
                  name="data_list_column_type"
                  label="Data Type"
                  options={dataListColumnTypes}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id
                  }
                  disableClearable
                  getOptionLabel={(option) => option?.name || ''}
                />
                <FormSwitch
                  disabled={
                    dataListColumn?.has_values && !dataListColumn?.unique_values
                  }
                  name="unique_values"
                  label={
                    <>
                      <Typography>Unique</Typography>
                      <FormHelperText disabled>
                        Require that each value needs to be unique for this
                        column.
                      </FormHelperText>
                    </>
                  }
                  sx={{ mt: -1 }}
                  tooltipTitle={
                    dataListColumn?.has_values && !dataListColumn?.unique_values
                      ? 'Cannot update this setting while the column has existing values.'
                      : ''
                  }
                />
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
