import { Organization } from '@/types/organizations'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<Organization>({
  pathCategory: 'orgs',
})

export function useGetOrganization({
  id,
  ...options
}: UseGetDetailOptions<Organization>) {
  const query = useGetDetail<Organization>({
    id,
    queryKey: queryKeys.organizations.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    organization: query.data,
    ...query,
  }
}

export function useCreateOrganization(
  options: UseCreateDetailOptions<Organization> = {},
) {
  const mutation = useCreateDetail<Organization>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createOrganization: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateOrganization(
  options: UseUpdateDetailOptions<Organization> = {},
) {
  const mutation = useUpdateDetail<Organization>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateOrganization: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteOrganization(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<Organization>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteOrganization: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetOrganizations({
  filters,
  ...options
}: UseGetListOptions<Organization> = {}) {
  const query = useGetList<Organization>({
    filters,
    queryKey: queryKeys.organizations.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    organizations: query.allData,
    ...query,
  }
}

export function useUpdateOrganizations(
  options: UseUpdateListOptions<Organization> = {},
) {
  const mutation = useUpdateList<Organization>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateOrganizations: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteOrganizations({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<Organization>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteOrganizations: mutation.mutateAsync,
    ...mutation,
  }
}
