import { ProjectCategory } from '@/types/projects'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import EditableNameWithActions from '@/components/editable-name/EditableNameWithActions'
import {
  useDeleteProjectCategory,
  useGetProjectCategories,
  useUpdateProjectCategory,
} from '@/service-library/hooks/project-categories'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'

type ProjectCategoryNameWithActionsProps = {
  canEdit: boolean
  category: ProjectCategory
}

export default function ProjectCategoryNameWithActions({
  canEdit,
  category,
}: ProjectCategoryNameWithActionsProps) {
  const { name, projects = [] } = category

  const { rootOrganization } = useRootOrganization()
  const { queryKey, projectCategories = [] } = useGetProjectCategories({
    filters: {
      limit: '1000',
      org_id: rootOrganization.id,
    },
    enabled: false, // Disabled this so it doesn't trigger refetches - we'll leave that to the projects page
  })

  const { updateProjectCategory } = useUpdateProjectCategory({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to update category. Please try again later.')
    },
  })

  const { deleteProjectCategory } = useDeleteProjectCategory({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to delete category. Please try again later.')
    },
    onSuccess: () => {
      showSuccessSnackbar('Category Deleted')
    },
  })

  function handleUpdate(internalName: string) {
    if (internalName && internalName !== name) {
      const updatedCategory = { ...category, name: internalName }
      updateProjectCategory(updatedCategory)
    }
  }

  function handleDelete() {
    deleteProjectCategory(category.id)
  }

  return (
    <EditableNameWithActions
      canBeEdited={canEdit}
      groupName="category"
      name={name}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      deleteDisabledMessage={
        projectCategories.length === 1
          ? 'There must be at least one category.'
          : projects.length > 0
          ? 'You must remove all projects in this category before it can be deleted.'
          : ''
      }
      typographyProps={{ component: 'h2', variant: 'h4' }}
    />
  )
}
