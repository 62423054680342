import { Dispatch, SetStateAction, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Settings } from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  Switch,
} from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import { useDocumentCount } from '@/components/document-count-provider/DocumentCountProvider'
import LeftBorderedBox from '@/components/validation/LeftBorderedBox'

type StateFilterProps = {
  workflowStates: WorkflowState[]
  selectedWorkflowState: WorkflowState
  setWorkflowState: (stateId: string) => void
  showCount?: boolean
  miniMode?: boolean
}

type StateHeadingProps = {
  showInFlightIndicator: boolean
  setShowInFlightIndicator: Dispatch<SetStateAction<boolean>>
}

function StatesHeading({
  showInFlightIndicator,
  setShowInFlightIndicator,
}: StateHeadingProps) {
  const [showSettings, setShowSettings] = useState(false)
  const settingsOverlay = useOverlay()

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onMouseEnter={() => setShowSettings(true)}
      onMouseLeave={() => {
        settingsOverlay.isOpen && settingsOverlay.close()
        setShowSettings(false)
      }}
    >
      <Typography variant="body1">Workflow States</Typography>

      <IconButton
        onClick={settingsOverlay.open}
        size="small"
        // We use visibility to prevent the IconButton from jumping (height difference)
        sx={{ mr: -0.5, visibility: showSettings ? 'visible' : 'hidden' }}
      >
        <Settings fontSize="inherit" />
      </IconButton>

      {settingsOverlay.anchorEl && (
        <Popover
          open={settingsOverlay.isOpen}
          onClose={() => {
            setShowSettings(false)
            settingsOverlay.close()
          }}
          anchorEl={settingsOverlay.anchorEl as Element}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 1, pl: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showInFlightIndicator}
                  onChange={() => setShowInFlightIndicator((prev) => !prev)}
                />
              }
              label="Show In-Flight Indicators"
            />
          </Box>
        </Popover>
      )}
    </Stack>
  )
}

export default function StateFilter({
  workflowStates,
  selectedWorkflowState,
  setWorkflowState,
  showCount,
  miniMode = false,
}: StateFilterProps) {
  const { projectId } = useParams()
  const theme = useTheme()
  const getThemeColor = useGetThemeColor()
  const [showInFlightIndicator, setShowInFlightIndicator] = useLocalStorage(
    `${projectId}-show-in-flight-indicator`,
    false,
  )

  const { countByWorkflowStateId } = useDocumentCount()

  return (
    <Stack direction="column" spacing={0.25}>
      {!miniMode && (
        <>
          <StatesHeading
            showInFlightIndicator={showInFlightIndicator}
            setShowInFlightIndicator={setShowInFlightIndicator}
          />
          {workflowStates.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              <i>No Workflow States</i>
            </Typography>
          )}
        </>
      )}
      <Stack>
        {workflowStates.map((state) => {
          if (state.code === 'processing') return
          const idleCount = countByWorkflowStateId?.[state.id]
            ? countByWorkflowStateId?.[state.id].total -
                countByWorkflowStateId?.[state.id].in_flight || '-'
            : '-'
          const isSelected = selectedWorkflowState.id === state.id
          const color = getThemeColor(state.color)
          const label = (
            <Typography variant="body2">
              {miniMode ? '' : state.name}
            </Typography>
          )
          return (
            <Tooltip
              key={state.id}
              title={state.name}
              placement="right"
              enterDelay={miniMode ? 0 : 5_000}
              disableHoverListener={!miniMode}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                role="button"
                onClick={() => {
                  setWorkflowState(state.id)
                }}
                sx={{
                  minHeight: 28,
                  position: 'relative',
                  cursor: 'pointer',
                  borderRadius: 1,
                  px: !miniMode ? 0.5 : 0,
                  pl: 0,
                  background: isSelected
                    ? theme.palette.action.selected
                    : undefined,
                  '&:hover': !miniMode
                    ? {
                        background: theme.palette.action.hover,
                        '& .description-button': {
                          display: 'inline-flex',
                        },
                      }
                    : {},
                }}
              >
                <Box sx={{ pl: 1.5 }}>
                  <LeftBorderedBox
                    color={color}
                    showMiniBorder={
                      showInFlightIndicator &&
                      !!countByWorkflowStateId?.[state.id]?.in_flight
                    }
                  >
                    <Typography variant="body2" component="div">
                      {label}
                    </Typography>
                  </LeftBorderedBox>
                </Box>

                {!miniMode && showCount && (
                  <Typography
                    component="span"
                    color="textSecondary"
                    variant="body2"
                  >
                    {idleCount}
                  </Typography>
                )}
              </Stack>
            </Tooltip>
          )
        })}
      </Stack>
    </Stack>
  )
}
