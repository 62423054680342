import { ProjectModel } from '@/types/project-models'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { getDetail } from '../request-wrappers'

const service = buildService<ProjectModel>({
  pathCategory: 'project_models',
})

export function useGetProjectModel({
  id,
  ...options
}: UseGetDetailOptions<ProjectModel>) {
  const query = useGetDetail<ProjectModel>({
    id,
    queryKey: queryKeys.projectModels.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectModel: query.data,
    ...query,
  }
}

export function useCreateProjectModel(
  options: UseCreateDetailOptions<ProjectModel> = {},
) {
  const mutation = useCreateDetail<ProjectModel>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectModel: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectModel(
  options: UseUpdateDetailOptions<ProjectModel> = {},
) {
  const mutation = useUpdateDetail<ProjectModel>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectModel: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectModel(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<ProjectModel>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectModel: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectModels({
  filters,
  ...options
}: UseGetListOptions<ProjectModel> = {}) {
  const query = useGetList<ProjectModel>({
    filters,
    queryKey: queryKeys.projectModels.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectModels: query.allData,
    ...query,
  }
}

export function useUpdateProjectModels(
  options: UseUpdateListOptions<ProjectModel> = {},
) {
  const mutation = useUpdateList<ProjectModel>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectModels: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectModels({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectModel>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectModels: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Get Child Org Model
 * Get the model for an org if available, or its inherited model from one of its parents.
 */
export function useGetOrgProjectModel({
  id,
  orgId,
  ...options
}: UseGetDetailOptions<ProjectModel> & { orgId: string }) {
  const query = useGetDetail<ProjectModel>({
    id,
    queryKey: queryKeys.projectModels.detail({ id, filters: options.filters }, [
      orgId,
    ]),
    serviceFn: ({ id, ...serviceFnOptions }) => {
      return getDetail<ProjectModel>({
        url: `/v2/pd/project_models/${id}/child_models/${orgId}`,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    projectModel: query.data,
    ...query,
  }
}
