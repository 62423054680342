import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import { ProjectModelTypeFieldTypeRestriction } from '@/types/project-models'

const service = buildService<ProjectModelTypeFieldTypeRestriction>({
  pathCategory: 'project_model_type_field_type_restrictions',
})

// Read Only Table

export function useGetProjectModelTypeFieldTypeRestriction({
  id,
  ...options
}: UseGetDetailOptions<ProjectModelTypeFieldTypeRestriction>) {
  const query = useGetDetail<ProjectModelTypeFieldTypeRestriction>({
    id,
    queryKey: queryKeys.projectModelTypeFieldTypeRestrictions.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectModelTypeFieldTypeRestriction: query.data,
    ...query,
  }
}

export function useGetProjectModelTypeFieldTypeRestrictions({
  filters,
  ...options
}: UseGetListOptions<ProjectModelTypeFieldTypeRestriction> = {}) {
  const query = useGetList<ProjectModelTypeFieldTypeRestriction>({
    filters,
    queryKey: queryKeys.projectModelTypeFieldTypeRestrictions.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectModelTypeFieldTypeRestrictions: query.allData,
    ...query,
  }
}
