import { useCallback, useRef } from 'react'
import {
  NodeProps,
  OnResize,
  OnResizeEnd,
  Position,
  useReactFlow,
} from 'reactflow'
import BaseNode from './BaseNode'
import { NodeResizeControl } from '@reactflow/node-resizer'
import { ArrowBackIos } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import InputHandle from '../handles/InputHandle'
import OutputHandle from '../handles/OutputHandle'
import ExecutionHandle from '../handles/ExecutionHandle'
import { LogixHandle } from '@/types/logix'
import useUpdateLogixNode from '@/services/hooks/useUpdateLogixNode'
import { getQueryKey } from '@/services/hooks/useLogixBoard'
import { useBoardContext } from '../BoardProvider'

type GroupNodeProps = NodeProps & {
  internalInputs: LogixHandle[]
  internalOutputs: LogixHandle[]
}

export default function GroupNode({
  data,
  internalInputs = [],
  internalOutputs = [],
  ...props
}: GroupNodeProps) {
  const reactFlowInstance = useReactFlow()
  const node = reactFlowInstance?.getNode(props.id)
  const { updateLogixNode, updateLogixNodesInCache } = useUpdateLogixNode()

  const board = useBoardContext()

  const cardRef = useRef<HTMLDivElement>(null)

  const onResize: OnResize = useCallback(
    (event, updatedValues) => {
      if (!node) return
      updateLogixNodesInCache(getQueryKey(board.id), [
        {
          id: node?.id,
          board_id: board.id,
          position: {
            x: node.position.x,
            y: node.position.y,
            h: updatedValues.height,
            w: updatedValues.width,
          },
        },
      ])
    },
    [board.id, node, updateLogixNodesInCache],
  )

  const onResizeEnd: OnResizeEnd = useCallback(
    (event, updatedValues) => {
      if (!node) return
      updateLogixNode({
        id: node.id,
        board_id: board.id,
        position: {
          x: updatedValues.x,
          y: updatedValues.y,
          h: updatedValues.height,
          w: updatedValues.width,
        },
      })
    },
    [board.id, node, updateLogixNode],
  )

  if (!node) return null

  return (
    <BaseNode
      data={data}
      height={node.data.logixNode.position.h}
      width={node.data.logixNode.position.w}
      minWidth={350}
      minHeight={200}
      cardRef={cardRef}
      canEditTitle
      subContent={
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              // Have to do this so the height of the stack is set on initial render - otherwise
              // it won't update until the box is resized by the user.
              height:
                cardRef.current && node?.height
                  ? node.height - cardRef.current.clientHeight
                  : '100%',
            }}
            className="node-children-container"
          >
            <Box
              sx={{
                height: '100%',
                borderRight:
                  internalOutputs.length > 0 ? 'solid 1px #ffffff22' : 'none',
                py: 2,
                pl: 1,
                pointerEvents: 'none',
              }}
            >
              <Stack spacing={1} sx={{ transform: 'translateX(6px)' }}>
                {internalOutputs.map((logixHandle, index) => {
                  if (logixHandle.data_type_code === 'execution') {
                    return (
                      <ExecutionHandle
                        key={logixHandle.id}
                        type="source"
                        position={Position.Right}
                        logixHandle={logixHandle}
                      />
                    )
                  }
                  return <OutputHandle key={index} logixHandle={logixHandle} />
                })}
              </Stack>
            </Box>
            <Box
              sx={{
                height: '100%',
                borderLeft:
                  internalInputs.length > 0 ? 'solid 1px #ffffff22' : 'none',
                py: 2,
                pr: 1,
                pointerEvents: 'none',
              }}
            >
              <Stack spacing={1} sx={{ transform: 'translateX(-6px)' }}>
                {internalInputs.map((logixHandle, index) => {
                  if (logixHandle.data_type_code === 'execution') {
                    return (
                      <Box
                        key={logixHandle.id}
                        sx={{ transform: 'translateX(-6px)' }}
                      >
                        <ExecutionHandle
                          type="target"
                          position={Position.Left}
                          logixHandle={logixHandle}
                        />
                      </Box>
                    )
                  }
                  return <InputHandle key={index} logixHandle={logixHandle} />
                })}
              </Stack>
            </Box>
          </Stack>

          <NodeResizeControl
            onResize={onResize}
            onResizeEnd={onResizeEnd}
            minWidth={350}
            minHeight={200}
            color="transparent"
            style={{ border: 'none' }}
          >
            <ArrowBackIos
              fontSize="small"
              color="neutral"
              sx={{
                transform: 'translate(-20px, -20px) rotate(225deg)',
                opacity: 0.5,
              }}
            />
          </NodeResizeControl>
        </>
      }
      {...props}
    />
  )
}
