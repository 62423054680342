import { useRef } from 'react'
import { MRT_Cell, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { RowData } from './constructTableRows'

type NavigationOptions = {
  matchIndex?: boolean
  createNewRowOnLast?: boolean
  onComplete?: () => void
}

export default function useTableNavigation(
  cell: MRT_Cell,
  row: MRT_Row<RowData>,
  table: MRT_TableInstance<RowData>,
  createNewRow: () => void,
) {
  const waitingForNewRowRef = useRef(false)
  const cells = row.getVisibleCells()
  const cellIndex = cells.findIndex(({ id }) => id === cell.id)
  const allRows = table.getRowModel().rows

  function navigateVertically(
    direction = 'down',
    { matchIndex, createNewRowOnLast, onComplete }: NavigationOptions = {},
  ) {
    const isDown = direction === 'down'
    const nextRowIndex = isDown ? row.index + 1 : row.index - 1

    const nextRow = table.getRow(
      Math.min(Math.max(0, nextRowIndex), allRows.length - 1).toString(),
    )
    if (nextRow !== row) {
      const nextRowCells = nextRow.getVisibleCells()
      // If matchIndex is true, then we go to the cell of the same index as the current cell
      // Otherwise, we navigate to either the first or the last cell
      if (matchIndex) {
        // @ts-expect-error -- Too complex of a fix to worry about here
        table.setEditingCell(nextRowCells[cellIndex])
      } else {
        const newRowCellIndex = isDown ? 1 : nextRowCells.length - 1
        // @ts-expect-error -- Too complex of a fix to worry about here
        table.setEditingCell(nextRowCells[newRowCellIndex])
      }
      // onComplete()
    } else if (isDown && createNewRowOnLast) {
      createNewRow()
      waitingForNewRowRef.current = true
    }
    onComplete?.()
  }

  function navigateHorizontally(
    direction = 'left',
    { onComplete }: NavigationOptions,
  ) {
    const isLeft = direction === 'left'
    const newIndex = isLeft ? cellIndex - 1 : cellIndex + 1

    // If we're not on the first column or the last column
    if (newIndex !== 0 && newIndex !== cells.length) {
      table.setEditingCell(cells[newIndex])
    } else {
      navigateVertically(isLeft ? 'up' : 'down')
    }
    onComplete?.()
  }

  function left(options: NavigationOptions) {
    return navigateHorizontally('left', options)
  }
  function right(options: NavigationOptions) {
    return navigateHorizontally('right', options)
  }
  function up(options: NavigationOptions) {
    return navigateVertically('up', { matchIndex: true, ...options })
  }
  function down(options: NavigationOptions) {
    return navigateVertically('down', { matchIndex: true, ...options })
  }

  return {
    left,
    right,
    up,
    down,
  }
}
