import { IntegrationType } from '@/types/integrations'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<IntegrationType>({
  pathCategory: 'integration_types',
})

export function useGetIntegrationType({
  id,
  ...options
}: UseGetDetailOptions<IntegrationType>) {
  const query = useGetDetail<IntegrationType>({
    id,
    queryKey: queryKeys.integrationTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    integrationType: query.data,
    ...query,
  }
}

export function useGetIntegrationTypes({
  filters,
  ...options
}: UseGetListOptions<IntegrationType> = {}) {
  const query = useGetList<IntegrationType>({
    filters,
    queryKey: queryKeys.integrationTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    integrationTypes: query.allData,
    ...query,
  }
}
