import {
  UserInteractionDocumentWorkflowState,
  UserInteractionDocumentWorkflowStateResponse,
} from '@/types/metrics'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import { createDetail, updateDetailPut } from '../request-wrappers'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'

/**
 * MARK: Action Hook: useStartUserInteractionDocumentWorkflowState
 * Used to create a new user interaction document workflow state
 */
export function useStartUserInteractionDocumentWorkflowState({
  ...options
}: UseCreateDetailOptions<
  UserInteractionDocumentWorkflowState,
  UserInteractionDocumentWorkflowStateResponse
> = {}) {
  const mutation = useCreateDetail<
    UserInteractionDocumentWorkflowState,
    UserInteractionDocumentWorkflowStateResponse
  >({
    serviceFn: (serviceFnOptions) => {
      return createDetail<
        UserInteractionDocumentWorkflowState,
        UserInteractionDocumentWorkflowStateResponse
      >({
        url: '/v2/pd-workflow/metrics/user_interaction/document_workflow_state/start',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    startUserInteractionDocumentWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: useEndUserInteractionDocumentWorkflowState
 * Used to end a user interaction document workflow state
 */
export function useEndUserInteractionDocumentWorkflowState({
  ...options
}: UseUpdateDetailOptions<{ id: string }> = {}) {
  const mutation = useUpdateDetail<{ id: string }>({
    serviceFn: (serviceFnOptions) => {
      return updateDetailPut<{ id: string }>({
        url: '/v2/pd-workflow/metrics/user_interaction/document_workflow_state/end',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    endUserInteractionDocumentWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}
