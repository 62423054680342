import { Button, ButtonProps } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'

type FileUploadButtonProps = {
  onFileUpload: (files: FileList) => void
  label?: string
  variant?: ButtonProps['variant']
  multiple?: boolean
  disabled?: boolean
  startIcon?: React.ReactNode
}

export default function FileUploadButton({
  onFileUpload,
  label = 'Upload Files',
  variant = 'text',
  multiple = false,
  ...props
}: FileUploadButtonProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    onFileUpload(event.target.files)
  }

  return (
    <Button
      component="label"
      variant={variant}
      startIcon={<UploadIcon />}
      {...props}
    >
      {label}
      <input
        onChange={handleChange}
        type="file"
        accept="image/*,.pdf"
        style={{ display: 'none' }}
        multiple={multiple}
      />
    </Button>
  )
}
