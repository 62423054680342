import { MRT_ColumnDef } from 'material-react-table'
import { Container } from '@mui/material'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import PageTitle from '@/components/PageTitle'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import { Source, sources } from '@/assets/openSourceData'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'

const columns: MRT_ColumnDef<Source>[] = [
  {
    header: 'Library',
    accessorKey: 'library',
    maxSize: 90,
  },
  {
    header: 'OS License',
    accessorKey: 'os_license',
  },
  {
    header: 'License Name',
    accessorKey: 'license_name',
    maxSize: 90,
  },
]

export default function OpenSourcePage() {
  const table = useZerapixTable<Source>({
    title: 'Open Source Licenses',
    columns,
    data: sources,
    enableSorting: true,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 160px)' } },
  })
  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <PageTitle>Open Source Licenses</PageTitle>
      <Breadcrumb label="Open Source Licenses" url="/open-source-licenses" />
      <ZerapixTable<Source> table={table} />
    </Container>
  )
}
