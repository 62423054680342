import { useMemo } from 'react'
import { Typography } from '@mui/material'

type HighlightedSearchTermProps = {
  text: string
  searchTerm?: string
}

export default function HighlightedSearchTerm({
  text,
  searchTerm,
}: HighlightedSearchTermProps) {
  const regex = useMemo(() => {
    const escapedSearchTerm = searchTerm?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    return new RegExp(`(${escapedSearchTerm})`, 'gi')
  }, [searchTerm])

  if (!searchTerm) {
    return (
      <Typography component="span" variant="inherit">
        {text}
      </Typography>
    )
  }

  return (
    <>
      {text.split(regex).map((part, index) => (
        <Typography
          component="span"
          variant="inherit"
          key={index}
          sx={{
            backgroundColor: (theme) =>
              regex.test(part) ? `${theme.palette.blue.main}77` : undefined,
          }}
        >
          {part}
        </Typography>
      ))}
    </>
  )
}
