import { MoreVert } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'

type FieldTableMenuProps = {
  tableHasRows: boolean
  onDeleteAllRows: () => void
}

export default function FieldTableMenu({
  tableHasRows,
  onDeleteAllRows,
}: FieldTableMenuProps) {
  const overlay = useOverlay()

  return (
    <div>
      <IconButton id="field-table-button" onClick={overlay.toggle} size="small">
        <MoreVert />
      </IconButton>

      <Menu
        id="field-table-menu"
        anchorEl={overlay.anchorEl as Element}
        open={overlay.isOpen}
        onClose={overlay.close}
        MenuListProps={{
          'aria-labelledby': 'field-table-button',
        }}
      >
        <MenuItem
          disabled={!tableHasRows}
          onClick={() => {
            onDeleteAllRows()
            overlay.close()
          }}
        >
          Delete All Rows
        </MenuItem>
      </Menu>
    </div>
  )
}
