import { useState } from 'react'
import NodeCategoryAccordion from './NodeCategoryAccordion'
import { useNodeIndex } from './NodeIndexProvider'
import NodeListItem from './NodeListItem'

export default function NodeCategoriesList() {
  const { categories } = useNodeIndex()

  const [openCategory, setOpenCategory] = useState<string>('')

  const displayedCategories = categories.filter(
    ([, nodeTypes]) => nodeTypes.length > 0,
  )

  return (
    <>
      {displayedCategories.map(([{ name }, nodeTypes]) => {
        const displayedNodeTypes = nodeTypes.filter(
          (nodeType) => !nodeType.is_hidden_in_picker,
        )

        return (
          <NodeCategoryAccordion
            title={name}
            key={name}
            expanded={openCategory === name}
            setAsOpenCategory={(expanded) => {
              setOpenCategory(expanded ? name : '')
            }}
          >
            {displayedNodeTypes.map((nodeType) => (
              <NodeListItem key={nodeType.code} nodeType={nodeType} />
            ))}
          </NodeCategoryAccordion>
        )
      })}
    </>
  )
}
