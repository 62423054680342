import { Dispatch, SetStateAction } from 'react'
import { Delete, Download, Edit, Refresh, Rule } from '@mui/icons-material'
import { ListItemIcon, MenuItem } from '@mui/material'
import { Workflow } from '@/types/workflows'
import useOverlay from '@/hooks/useOverlay'
import { useRunRules } from '@/service-library/hooks/documents'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import downloadFile from '@/utils/download-file'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import BulkDocumentDialog from '@/components/bulk-document-dialog/BulkDocumentDialog'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import IconButtonMenu from '@/components/icon-button-menu/IconButtonMenu'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import DeleteDocumentsDialog from './DeleteDocumentsDialog'
import DownloadOriginalDocumentsDialog from './DownloadOriginalDocumentsDialog'
import ReprocessDocumentsDialog from './ReprocessDocumentsDialog'
import { useSelectedWorkflowContext } from './SelectedWorkflowProvider'
import queryKeys from '@/service-library/query-keys'

type DocumentGridActionsMenuProps = {
  selectedDocumentIds: string[]
  workflow: Workflow
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
  refetchDocuments: () => void
}

export default function DocumentGridActionsMenu({
  selectedDocumentIds,
  workflow,
  setSelectedRows,
  refetchDocuments,
}: DocumentGridActionsMenuProps) {
  const menuOverlay = useOverlay()
  const bulkDocumentOverlay = useOverlay()
  const deleteDocsOverlay = useOverlay()
  const reprocessDocsOverlay = useOverlay()
  const downloadOriginalDocsOverlay = useOverlay()

  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { runRules } = useRunRules({
    workflowId: selectedWorkflow.id,
    sideEffectQueryKeys: [
      queryKeys.documents.lists(),
      queryKeys.documentRows.lists(),
    ],
  })
  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()

  const numberOfSelectedRows = selectedDocumentIds.length

  function handleClick(onClick: () => void) {
    menuOverlay.close()
    onClick()
  }

  // This is just a temporary solution for super users only
  async function bulkRunRules() {
    showInfoSnackbar('Validating Documents')
    setSelectedRows({})
    const responses = await Promise.allSettled(
      selectedDocumentIds.map((id) => runRules({ id })),
    )
    const errors = responses.find((response) => response.status === 'rejected')
    if (errors)
      showErrorSnackbar(
        'Some documents validation failed. Please try again later.',
      )
  }

  useNotifications({
    keys: ['export_original_complete', 'export_original_failed'],
    callback: ({ action, export_response, error }) => {
      if (action === 'export_original_failed') {
        showErrorSnackbar(
          'Failed to download documents. Please try again later.',
        )
      } else {
        if (export_response) {
          downloadFile(
            export_response.file_url,
            export_response.file_name,
          ).catch(() => {
            showErrorSnackbar(
              'Failed to download documents. Please try again later.',
            )
          })
        }
        if (error === 'not-found') {
          showErrorSnackbar(
            'Some documents failed to be included in the download. Please contact support.',
          )
        }
      }
    },
  })

  return (
    <>
      <IconButtonMenu overlay={menuOverlay}>
        <MenuItem
          onClick={() => handleClick(bulkDocumentOverlay.open)}
          disabled={numberOfSelectedRows === 0}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit Documents{' '}
          {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}
        </MenuItem>
        {workflow.code === 'basic' && (
          <MenuItem
            onClick={() => handleClick(reprocessDocsOverlay.open)}
            disabled={numberOfSelectedRows === 0}
          >
            <ListItemIcon>
              <Refresh />
            </ListItemIcon>
            Reprocess Documents{' '}
            {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => handleClick(deleteDocsOverlay.open)}
          disabled={numberOfSelectedRows === 0}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Delete Documents{' '}
          {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}
        </MenuItem>
        <MenuItem
          onClick={() => handleClick(downloadOriginalDocsOverlay.open)}
          disabled={numberOfSelectedRows === 0}
        >
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          Download Original Files{' '}
          {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}
        </MenuItem>
        {isSuperUser && !demoMode && (
          <MenuItem
            onClick={() => handleClick(bulkRunRules)}
            disabled={numberOfSelectedRows === 0}
            sx={{ color: SUPER_USER_ONLY_COLOR }}
          >
            <ListItemIcon>
              <Rule
                sx={{
                  color: SUPER_USER_ONLY_COLOR,
                }}
              />
            </ListItemIcon>
            Run Rules {numberOfSelectedRows ? `(${numberOfSelectedRows})` : ''}
          </MenuItem>
        )}
      </IconButtonMenu>

      {bulkDocumentOverlay.isOpen && (
        <BulkDocumentDialog
          documentIds={selectedDocumentIds}
          overlay={bulkDocumentOverlay}
          setSelectedRows={setSelectedRows}
        />
      )}

      <DeleteDocumentsDialog
        overlay={deleteDocsOverlay}
        documentIds={selectedDocumentIds}
        refetch={refetchDocuments}
        setSelectedRows={setSelectedRows}
      />

      <ReprocessDocumentsDialog
        overlay={reprocessDocsOverlay}
        documentIds={selectedDocumentIds}
        refetch={refetchDocuments}
        setSelectedRows={setSelectedRows}
      />

      <DownloadOriginalDocumentsDialog
        overlay={downloadOriginalDocsOverlay}
        documentIds={selectedDocumentIds}
        setSelectedRows={setSelectedRows}
      />
    </>
  )
}
