import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { View } from '@/types/views'
import useStatefulSearchParam from '@/hooks/useStatefulSearchParam'
import usePermission from '@/hooks/usePermission'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import { useGetSavedViews } from '@/service-library/hooks/saved-views'

type ViewsContextValue = ReturnType<typeof useGetSavedViews> & {
  allViews: View[]
  canEdit: boolean
  savedEnabled: boolean
  selectedView: View
  handleCancelChange: (onCancel: () => void) => void
  handleChange: () => void
  setSavedEnabled: Dispatch<SetStateAction<boolean>>
  setSelectedViewId: (newValue: string) => void
}

const ViewsContext = createContext<ViewsContextValue>({} as ViewsContextValue)

type ViewsProviderProps = {
  children: ReactNode
}

export const useViewsContext = () => useContext(ViewsContext)

export default function ViewsProvider({ children }: ViewsProviderProps) {
  const { pathname } = useLocation()
  const { view: dashboardView } = useParams()
  const { project } = useProjectContext()
  const { hasEditingPermission } = usePermission()
  const canEditProject = hasEditingPermission('edit_projects', project.org_id)
  const [savedEnabled, setSavedEnabled] = useState(false)

  const { savedViews, ...rest } = useGetSavedViews({
    filters: {
      project_id: project.id,
      limit: '100',
    },
  })

  const allViews = useMemo(
    () => [
      {
        id: 'default',
        name: 'Default',
        state: {
          column_order: [],
          sorting: [
            {
              id: 'enteredAtColId',
              desc: true,
            },
          ],
          org_ids: [],
          flag_codes: [],
          column_visibility: {},
          team_ids: [],
        },
        project_id: project.id,
      },
      ...savedViews,
    ],
    [project.id, savedViews],
  )

  const {
    value: selectedViewId,
    toggleValue: setSelectedViewId,
    isInUrl,
    resetValue,
  } = useStatefulSearchParam({
    param: 'view',
    defaultValue: allViews[0].id,
  })

  const handleCancelChange = (onCancel: () => void) => {
    if (savedEnabled) {
      setSavedEnabled(false)
      onCancel()
    }
  }

  const handleChange = () => {
    if (!savedEnabled && canEditProject) {
      setSavedEnabled(true)
    }
  }

  useEffect(() => {
    setSavedEnabled(false)
  }, [dashboardView])

  useEffect(() => {
    if (isInUrl && pathname.includes('settings')) {
      resetValue()
    }
  })

  return (
    <ViewsContext.Provider
      value={{
        allViews,
        savedViews,
        canEdit: canEditProject,
        savedEnabled,
        selectedView:
          // setting default view as fallback for when a view is deleted and selectedViewId doesn't get updated immediately or when flag is off
          allViews.find(({ id }) => id === selectedViewId) || allViews[0],
        handleCancelChange,
        handleChange,
        setSavedEnabled,
        setSelectedViewId,
        ...rest,
      }}
    >
      {children}
    </ViewsContext.Provider>
  )
}
