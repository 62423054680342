import { ProjectGridField } from '@/types/fields'
import { Badge, Card, List, ListSubheader, useTheme } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import FieldListItem from '@/components/project-settings/FieldListItem'

type FieldInfo = {
  field: ProjectGridField
  fields?: ProjectGridField[]
}

type FieldRulesFieldListProps = {
  fieldInfos: FieldInfo[]
  selectedField: ProjectGridField
  setSelectedField: Dispatch<SetStateAction<ProjectGridField>>
}

type FieldRulesFieldListItemProps = {
  field: ProjectGridField
  selectedField: ProjectGridField
  setSelectedField: Dispatch<SetStateAction<ProjectGridField>>
  indented?: boolean
}

function FieldRulesFieldListItem({
  field,
  selectedField,
  setSelectedField,
  indented,
}: FieldRulesFieldListItemProps) {
  return (
    <FieldListItem
      field={field}
      isButton
      indented={indented}
      selected={selectedField.id === field.id}
      onSelectField={() => setSelectedField(field)}
    >
      {(field.project_grid_field_rules?.length || 0) > 0 && (
        <Badge
          badgeContent={field?.project_grid_field_rules?.length}
          color="neutral"
        />
      )}
    </FieldListItem>
  )
}

export default function FieldRulesFieldList({
  fieldInfos,
  selectedField,
  setSelectedField,
}: FieldRulesFieldListProps) {
  const theme = useTheme()

  return (
    <Card sx={{ overflow: 'auto', height: '100%', position: 'relative' }}>
      <List dense>
        {fieldInfos.map((fieldInfo) => {
          return !fieldInfo.fields ? (
            <FieldRulesFieldListItem
              key={fieldInfo.field.id}
              field={fieldInfo.field}
              selectedField={selectedField}
              setSelectedField={setSelectedField}
            />
          ) : (
            <List key={fieldInfo.field.id} dense sx={{ my: 1 }}>
              <ListSubheader
                sx={{ background: theme.palette.background.paper, zIndex: 2 }}
              >
                {fieldInfo.field.name}
              </ListSubheader>
              {fieldInfo.fields.map((subField) => {
                return (
                  <FieldRulesFieldListItem
                    key={subField.id}
                    field={subField}
                    selectedField={selectedField}
                    setSelectedField={setSelectedField}
                    indented
                  />
                )
              })}
            </List>
          )
        })}
      </List>
    </Card>
  )
}
