import {
  useState,
  useMemo,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import { Warning } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Typography, Button, Stack, MenuItem, TextField } from '@mui/material'
import { Workflow, WorkflowState } from '@/types/workflows'
import { OverlayState } from '@/hooks/useOverlay'
import useExportDocuments from '@/services/hooks/useExportDocuments'
import { showInfoSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useColumnVisibilityContext } from './ColumnVisibilityProvider'

type ExportDocumentDialog = {
  overlay: OverlayState
  projectId: string
  state: WorkflowState
  workflow: Workflow
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
  documentIds?: string[]
}

const exportOptions = [
  'Export Header Fields',
  'Export Visible Fields',
  'Export All Fields',
]

export default function ExportDocumentsDialog({
  overlay,
  projectId,
  state,
  workflow,
  setSelectedRows,
  documentIds,
}: ExportDocumentDialog) {
  const {
    isLoading,
    exportDocuments,
    mappedFieldIds,
    allFieldsIds,
    csvMappingId,
    errors,
    setErrors,
  } = useExportDocuments({ projectId, enabled: overlay.isOpen })
  const [exportOption, setExportOption] = useState('')
  const [exportType, setExportType] = useState('csv')
  const { columnVisibility } = useColumnVisibilityContext()

  useEffect(() => {
    if (csvMappingId && (mappedFieldIds?.length ?? 0) > 0) {
      setExportOption(exportOptions[0])
    } else {
      setExportOption(exportOptions[1])
    }
  }, [csvMappingId, mappedFieldIds])

  const handleTypeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setExportType(e.target.value)
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setExportOption(e.target.value)
  }

  const filteredOptions = useMemo(() => {
    if (csvMappingId && (mappedFieldIds?.length ?? 0) > 0) {
      return exportOptions
    }

    return exportOptions.filter(
      (option) => option !== 'Export CSV Header Fields',
    )
  }, [csvMappingId, mappedFieldIds])

  const getExportFields = useCallback(() => {
    if (exportOption === exportOptions[0]) {
      return mappedFieldIds
    } else if (exportOption === exportOptions[1]) {
      const fieldsIds = Object.entries(columnVisibility)
        .filter(
          ([id, visibility]) => !id.endsWith('ColId') && visibility === true,
        )
        .map(([key]) => key)
      return fieldsIds
    } else if (exportOption === exportOptions[2]) {
      return allFieldsIds
    }
    return []
  }, [allFieldsIds, columnVisibility, exportOption, mappedFieldIds])

  const handleExport = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    exportDocuments(
      projectId,
      exportType,
      getExportFields(),
      workflow.id,
      state.id,
      documentIds,
    )
      .then(() => {
        setExportType('csv')
        showInfoSnackbar('Export Starting...')
        setSelectedRows({})
        overlay.close()
      })
      .catch(() => {})
  }

  const handleCloseDialog = () => {
    errors && setErrors(null)
    overlay.close()
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      {...overlay}
      onClose={handleCloseDialog}
      title="Export Documents"
    >
      <DialogContent>
        <Stack spacing={2}>
          {errors && (
            <Stack direction="row" pl={1} pr={1}>
              <Warning color="error" />
              <Typography color="error">{errors}</Typography>
            </Stack>
          )}
          <TextField
            select
            variant="filled"
            size="small"
            label="Destination"
            value={exportType}
            onChange={handleTypeChange}
            sx={{ width: '100%' }}
          >
            <MenuItem value="csv">CSV</MenuItem>
            <MenuItem value="simple-json">Simple JSON</MenuItem>
            <MenuItem value="full-json">Full JSON</MenuItem>
          </TextField>

          {(exportType === 'csv' || exportType === 'simple-json') && (
            <TextField
              select
              variant="filled"
              size="small"
              label="Fields to Export"
              value={exportOption}
              onChange={handleChange}
              sx={{ width: '100%' }}
            >
              {filteredOptions.map((option) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Stack>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={handleCloseDialog} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleExport}
        >
          Export
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
