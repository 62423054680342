import { useEffect, useRef } from 'react'
import { NodeProblem, useNodeProblems } from '../ProblemsProvider'
import { useNodeId } from 'reactflow'

type UseConditionProblemProps = {
  condition: boolean
  problem: Omit<NodeProblem, 'id' | 'nodeId'>
  enabled?: boolean
}

export default function useConditionProblem({
  condition,
  problem, // MUST BE MEMOIZED!
  enabled = true,
}: UseConditionProblemProps) {
  const nodeId = useNodeId() as string
  const { addProblem, removeProblem } = useNodeProblems()
  const problemRef = useRef<NodeProblem | undefined>()

  useEffect(() => {
    if (enabled) {
      if (!problemRef.current && condition) {
        problemRef.current = addProblem({ nodeId, ...problem })
      } else if (problemRef.current && !condition) {
        removeProblem(problemRef.current)
        problemRef.current = undefined
      }
    }
  }, [addProblem, condition, enabled, nodeId, problem, removeProblem])
}
