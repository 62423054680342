import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Organization } from '@/types/organizations'
import ListAutocomplete from '@/components/list-autocomplete/ListAutocomplete'
import { useRootOrganization } from './RootOrganizationProvider'

export default function RootOrganizationPicker() {
  const navigate = useNavigate()
  const { rootOrganizations, rootOrganization, setRootOrganization } =
    useRootOrganization()

  function changeRootOrganization(newOrg: Organization) {
    setRootOrganization(newOrg)
    // Always send the user back to the root of the app when they change the root organization
    navigate('/projects')
  }

  return rootOrganizations.length ? (
    <Box
      sx={{
        flexShrink: 0,
        minWidth: rootOrganizations.length > 1 ? 250 : 'fit-content',
        px: 1,

        textAlign: 'right',
      }}
    >
      {rootOrganizations.length > 1 ? (
        <ListAutocomplete<Organization>
          options={rootOrganizations}
          selected={rootOrganization}
          setSelected={changeRootOrganization}
          variant="outlined"
          size="small"
          label="Root Organization"
        />
      ) : (
        <Typography>{rootOrganizations[0].name}</Typography>
      )}
    </Box>
  ) : null
}
