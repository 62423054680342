import { AxiosError } from 'axios'
import {
  useInfiniteQuery as useInfiniteQueryHook,
  UseInfiniteQueryOptions as UseInfiniteQueryBaseOptions,
  QueryKey,
} from '@tanstack/react-query'
import { useAuthentication } from '@/components/auth/AuthProvider'

type UseInfiniteQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = UseInfiniteQueryBaseOptions<
  TQueryFnData,
  TError,
  TData,
  TQueryFnData,
  TQueryKey
>

export default function useInfiniteQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  onError,
  ...options
}: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryKey>) {
  const { getFreshIdToken } = useAuthentication()

  const query = useInfiniteQueryHook<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    onError: (error) => {
      if ((error as AxiosError)?.response?.status === 401) {
        getFreshIdToken()?.catch(() => {})
      }
      onError?.(error)
    },
  })

  return query
}
