import { Box, Stack, useTheme } from '@mui/material'
import { themeColorOptions } from '@/theme/usePixydocsTheme'

export type PixyColorPickerProps = {
  colors?: string[]
  selectedColor: string
  setSelectedColor: (color: string) => void
}

export default function PixyColorPicker({
  colors = themeColorOptions,
  selectedColor,
  setSelectedColor,
}: PixyColorPickerProps) {
  const theme = useTheme()

  return (
    <Stack
      direction="row"
      spacing={1.25}
      justifyContent="center"
      alignItems="center"
    >
      {colors.map((color) => {
        const isSelectedColor = selectedColor === color
        return (
          <Box
            key={color}
            sx={{
              borderRadius: '50%',
              width: 20,
              height: 20,
              transform: `scale(${isSelectedColor ? 1.3 : 1})`,
              backgroundColor: isSelectedColor
                ? // @ts-expect-error -- Not worth typing
                  theme.palette[color].main
                : 'transparent',
              cursor: 'pointer',
              // @ts-expect-error -- Not worth typing
              border: `4px solid ${theme.palette[color].main}`,
              transition: 'all 0.2s',
            }}
            onClick={() => {
              setSelectedColor(color)
            }}
          />
        )
      })}
    </Stack>
  )
}
