import { Box } from '@mui/material'
import { FocusEventHandler, ReactNode } from 'react'

type FocusContainerProps = {
  children: ReactNode
  focusContainerRef: React.RefObject<HTMLDivElement>
  isSelectedField: boolean
  isUserEditable: boolean
  onFocus: () => void
  onBlur: () => void
}

export default function FocusContainer({
  children,
  focusContainerRef,
  isSelectedField,
  isUserEditable,
  onFocus,
  onBlur,
}: FocusContainerProps) {
  // In react onFocus is really the "focusin" event, rather than just "focus".
  // React normalizes all events so they bubble. This means onBlur will fire
  // whenever focus changes _within_ the element that owns the event, not only
  // when focus on the element itself changes.
  const handleBlur: FocusEventHandler = (event) => {
    // This checks if focus has left the container for the field. This way if
    // we want to tab to a child that only shows when this is the selected field,
    // like the "yes" button for adding list lookup mappings, we can without it closing.
    // This only works if the focusContainerRef has a tabIndex so it can will show
    // as relatedTarget if the clicked element isn't focusable.
    // Otherwise relatedTarget would be null.
    if (
      !focusContainerRef.current?.contains(event.relatedTarget) &&
      isSelectedField
    ) {
      onBlur()
    }
  }

  return (
    <Box
      // Assigning a className to use it in querySelector when needed
      className="focus-container"
      ref={focusContainerRef}
      onBlur={handleBlur}
      onFocus={() => onFocus()} // Whenever any child within our focus container element gets focus, this fires.
      tabIndex={isUserEditable ? -1 : 0}
      sx={{
        '&:focus-visible': {
          outline: 'none',
        },
      }}
    >
      {children}
    </Box>
  )
}
