import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { Workflow } from '@/types/workflows'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useCreateWorkflow,
  useUpdateWorkflowAndRelated,
} from '@/service-library/hooks/workflows'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'

type FormValues = {
  name: string
}

type AddEditWorkflowDialogProps = {
  overlay: OverlayState
  workflow?: Workflow
}

export default function AddEditWorkflowDialog({
  overlay,
  workflow,
}: AddEditWorkflowDialogProps) {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { queryKey, workflows } = useWorkflowsContext()
  const { updateWorkflowAndRelated } = useUpdateWorkflowAndRelated({
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to update workflow. Please try again later.')
    },
  })

  const { createWorkflow } = useCreateWorkflow({
    listQueryKey: queryKey,
    onSuccess: (newWorkflow) => {
      navigate(`./settings/workflow-states?workflow=${newWorkflow.id}`)
    },
    onError: () => {
      showErrorSnackbar('Unable to create workflow. Please try again later.')
    },
  })

  const defaultValues = useMemo(
    () => ({ name: workflow?.name || '' }),
    [workflow?.name],
  )

  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onChange', // triggers validation onChange
  })
  const { formState, register, reset } = methods
  const nameError = formState.errors.name

  function onSubmit(values: FormValues) {
    values.name = values.name.trim()
    if (workflow && workflow.name !== values.name) {
      updateWorkflowAndRelated({ ...workflow, ...values })
    } else if (!workflow) {
      createWorkflow({
        ...values,
        id: generateUuid(),
        code: values.name.toLowerCase().replace(/\s/g, '_'),
        project_id: projectId as string,
        project_model_id: null,
        initial_workflow_state_id: null,
      })
    }
    overlay.close()
  }

  useEffect(() => {
    if (!overlay.isOpen) {
      reset(defaultValues)
    }
  }, [overlay.isOpen, defaultValues, reset])

  return (
    <Dialog
      title={`${workflow?.name ? 'Edit' : 'New'} Workflow`}
      {...overlay}
      maxWidth="xs"
    >
      <PixyDocsForm methods={methods} onSubmit={onSubmit}>
        <DialogContent>
          <FormTextField
            label="Name"
            fullWidth
            required
            requiredErrorText="Name cannot be empty."
            helperText={nameError ? nameError.message : ''}
            error={!!nameError}
            {...register('name', {
              validate: (value) => {
                if (!value.trim().length) return 'Name cannot be empty.'

                if (
                  workflows.some(
                    ({ id, name }) =>
                      name.toLowerCase() === value.toLowerCase().trim() &&
                      id !== workflow?.id,
                  )
                )
                  return 'Name must be unique.'

                return true
              },
            })}
          />
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!formState.isDirty || !formState.isValid}
          >
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
