import { useEffect, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import {
  useGetDataListSyncingStatus,
  useSyncDataList,
} from '@/service-library/hooks/data-lists'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'

type SyncHelperProps = {
  dataListId: string
}

export default function SyncHelper({ dataListId }: SyncHelperProps) {
  const [isSyncing, setIsSyncing] = useState(false)
  const { dataListSyncStatus } = useGetDataListSyncingStatus({
    id: dataListId,
    refetchInterval: isSyncing ? 10000 : undefined,
  })

  const { syncDataList } = useSyncDataList()

  useEffect(() => {
    if (dataListSyncStatus) {
      setIsSyncing(dataListSyncStatus.status === 'syncing')
    }
  }, [dataListSyncStatus])

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Button
        disabled={isSyncing}
        onClick={() => {
          syncDataList({ id: dataListId })
          setIsSyncing(true)
        }}
        size="small"
        variant="text"
      >
        {isSyncing ? 'Syncing' : 'Sync List'}
      </Button>
      {dataListSyncStatus && (
        <Typography color="text.secondary" variant="caption">
          {dataListSyncStatus.status === 'error'
            ? 'Sync failed. Please contact Pixydocs support.'
            : `Last Synced: ${prettifyDate(
                dataListSyncStatus?.last_sync_date,
              )}`}
        </Typography>
      )}
    </Stack>
  )
}
