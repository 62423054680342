import { FormSlider } from '@/components/forms'
import { PropertyFieldProps } from './PropertyField'

type PropertySliderProps = Pick<PropertyFieldProps, 'propertyId' | 'property'>

export default function PropertySlider({
  propertyId,
  property,
}: PropertySliderProps) {
  const { display } = property

  return (
    <FormSlider
      name={propertyId}
      label={(value) => `${display.label}: ${value}%`}
      color={display.color}
      helperText={display.description}
    />
  )
}
