import { useHotkeys } from 'react-hotkeys-hook'
import { Pagination, PaginationItem } from '@mui/material'
import { altHotKeyStr } from '@/utils/get-os'
import KeyboardShortcutsTooltip from '@/components/keyboard-shortcuts-tooltip/KeyboardShortcutsTooltip'
import { useDocumentImagePageContext } from './providers/DocumentImagePageProvider'

export default function PaginationControls() {
  const { imagePage, nonBlankPages, pageCount, setImagePage } =
    useDocumentImagePageContext()

  useHotkeys(
    'alt+3',
    () => {
      if (imagePage > nonBlankPages[0]) {
        setImagePage((prev) => prev - 1, true)
      }
    },
    {
      preventDefault: true,
      enableOnFormTags: true,
    },
    [setImagePage],
  )
  useHotkeys(
    'alt+4',
    () => {
      if (imagePage < (nonBlankPages.at(-1) || 0)) {
        setImagePage((prev) => prev + 1, true)
      }
    },
    {
      preventDefault: true,
      enableOnFormTags: true,
    },
    [setImagePage],
  )

  return (
    <Pagination
      count={pageCount}
      page={imagePage}
      onChange={(event, newPage) => {
        setImagePage(
          () => newPage,
          (event.target as HTMLElement).tagName !== 'BUTTON',
        )
      }}
      renderItem={(item) => {
        const { disabled, page, type } = item
        const isBlankPage =
          type === 'page' && !nonBlankPages.includes(page || 0)
        const typeIsPrev = type === 'previous'
        const typeIsNext = type === 'next'

        let tooltip = ''
        let isDisabled = disabled
        const keyboardShortcuts = []

        if (typeIsPrev || typeIsNext) {
          isDisabled = typeIsPrev
            ? imagePage <= nonBlankPages[0]
            : imagePage >= nonBlankPages[nonBlankPages.length - 1]
          tooltip = `${typeIsPrev ? 'Previous' : 'Next'} Page`
          keyboardShortcuts.push(`${altHotKeyStr} + ${typeIsPrev ? '3' : '4'}`)
        } else if (isBlankPage) {
          tooltip = 'No text detected on this page'
        }

        return (
          <KeyboardShortcutsTooltip
            title={tooltip}
            enterDelay={1000}
            disableInteractive
            arrow
            keyboardShortcuts={keyboardShortcuts}
          >
            <PaginationItem
              {...item}
              disabled={isDisabled}
              // This prevents losing field focus when changing pages
              onMouseDown={(e) => e.preventDefault()}
              sx={{
                color: (theme) =>
                  isBlankPage
                    ? theme.palette.secondary.main
                    : theme.palette.neutral.main,
              }}
            />
          </KeyboardShortcutsTooltip>
        )
      }}
      size="small"
      sx={{ '>ul': { justifyContent: 'center' } }}
    />
  )
}
