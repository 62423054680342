import { useContext, useState, useRef } from 'react'
import { FeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import {
  Typography,
  Stack,
  Checkbox,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'

type FeatureFlagItemProps = {
  value: boolean
  name: string
  storedValue?: boolean
  remoteValue?: boolean
  updateFlag: (key: string, value: boolean) => void
}

function FeatureFlagItem({
  name,
  value,
  storedValue,
  remoteValue,
  updateFlag,
}: FeatureFlagItemProps) {
  const [expanded, setExpanded] = useState(false)
  const checkboxRef = useRef<HTMLLabelElement>(null)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target
    updateFlag(name, checked)
  }

  const hasStoredValue = storedValue !== undefined
  const storedValueStr = storedValue ? 'True' : 'False'
  const remoteValueStr = remoteValue ? 'True' : 'False'

  return (
    <Accordion
      expanded={expanded}
      onChange={(event) => {
        if (checkboxRef.current?.contains(event.target as Node)) return
        setExpanded((prev) => !prev)
      }}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        <Stack
          component="label"
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          ref={checkboxRef}
        >
          <Checkbox
            checked={value}
            edge="start"
            name={name}
            onChange={handleChange}
            color={
              hasStoredValue && storedValue !== remoteValue
                ? 'secondary'
                : 'primary'
            }
          />
          <Typography>{name}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="div" variant="caption" color="text.secondary">
          Remote Value: {remoteValue !== undefined ? remoteValueStr : 'Not Set'}
        </Typography>
        {hasStoredValue && (
          <Typography component="div" variant="caption" color="text.secondary">
            Local Value: {hasStoredValue ? storedValueStr : 'Not Set'}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default function FeatureFlagsPage() {
  const {
    featureFlags,
    remoteFlags,
    storedFlags,
    updateFlag,
    clearStoredFlags,
  } = useContext(FeatureFlagContext)

  const flagEntries = Object.entries(featureFlags).filter(
    ([key]) => key !== 'is_internal_user',
  )

  return (
    <Container maxWidth="sm">
      <Breadcrumb label="Feature Flags" url="/settings/flags" />
      <Stack sx={{ p: 2 }} spacing={2}>
        <div>
          <Typography variant="h4">Feature Flags</Typography>
          <Typography variant="caption" color="text.secondary">
            This page is only visible to staff.
          </Typography>
        </div>

        <div>
          <Button onClick={clearStoredFlags} variant="text">
            Reset all to default
          </Button>
        </div>

        <div>
          {flagEntries.map(([key, value]) => {
            return (
              <FeatureFlagItem
                key={key}
                name={key}
                value={value}
                storedValue={storedFlags[key]}
                remoteValue={remoteFlags[key]}
                updateFlag={updateFlag}
              />
            )
          })}
        </div>
      </Stack>
    </Container>
  )
}
