import { Autocomplete, Box, List, ListItem, TextField } from '@mui/material'
import { Dispatch, ReactNode, SetStateAction, forwardRef } from 'react'
import NodeListItem from './NodeListItem'
import { LogixNodeType } from '@/types/logix'

type NodeSearchAutocompleteProps = {
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  autoFocus?: boolean
  autoHighlight?: boolean
  onSelect?: (selectedItem: LogixNodeType | null) => void
  value?: LogixNodeType | null
  useDefaultListItems?: boolean
  alwaysShowOptions?: boolean
  options?: LogixNodeType[]
}

function NodeSearchAutocomplete(
  {
    searchValue,
    setSearchValue,
    autoFocus = false,
    autoHighlight = false,
    onSelect,
    value,
    useDefaultListItems = false,
    alwaysShowOptions = false,
    options = [],
  }: NodeSearchAutocompleteProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const displayedOptions = options.filter(
    (nodeType) => !nodeType.is_hidden_in_picker,
  )

  // If we're displaying a subset of options, sort them by search priority
  if (options)
    displayedOptions.sort(
      (a, b) => (b.search_priority || 0) - (a.search_priority || 0),
    )

  const shouldDisplayOptions = !!searchValue || alwaysShowOptions

  return (
    <Autocomplete
      options={displayedOptions}
      inputValue={searchValue}
      open={shouldDisplayOptions}
      selectOnFocus
      autoHighlight={autoHighlight}
      disableCloseOnSelect
      onInputChange={(event, newValue) => setSearchValue(newValue)}
      getOptionLabel={(nodeType) => nodeType.title}
      clearOnBlur={false}
      noOptionsText="No Results"
      popupIcon={null}
      ref={ref}
      value={value}
      onChange={(event, newValue) => {
        onSelect?.(newValue)
      }}
      renderOption={
        useDefaultListItems
          ? (props, nodeType) => (
              <ListItem {...props} key={nodeType.code}>
                {nodeType.title}
              </ListItem>
            )
          : (props, nodeType) => {
              return <NodeListItem key={nodeType.code} nodeType={nodeType} />
            }
      }
      renderInput={(params) => (
        <Box sx={{ px: 1.5, pb: 1 }}>
          <TextField
            name="node-search"
            placeholder="Search Nodes"
            variant="standard"
            sx={{ width: '100%' }}
            autoFocus={autoFocus}
            {...params}
          />
        </Box>
      )}
      PaperComponent={({ children }) => <List disablePadding>{children}</List>}
      PopperComponent={forwardRef(({ children }, ref) => (
        <Box
          ref={ref}
          sx={{ display: shouldDisplayOptions ? undefined : 'none' }}
        >
          {children as ReactNode}
        </Box>
      ))}
      ListboxProps={{
        // Remove this, since it prevents default, which stops drag and drop from working
        onMouseDown: () => {},
      }}
    />
  )
}

export default forwardRef(NodeSearchAutocomplete)
