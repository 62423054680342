import PageTitle from '@/components/PageTitle'
import { Outlet } from 'react-router-dom'

export default function MetricsPage() {
  return (
    <>
      <PageTitle>Metrics</PageTitle>
      <Outlet />
    </>
  )
}
