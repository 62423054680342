import { RowValueFlag, DocumentFlag } from '@/types/flags'
import { Box, Typography } from '@mui/material'
import FlagsList from './FlagsList'

type FullFlagsListProps = {
  title: string
  flags: (RowValueFlag | DocumentFlag)[]
  onFlagClick?: (
    flag: RowValueFlag,
    cellToFocus: HTMLInputElement | HTMLDivElement | null,
  ) => void
}

export default function FullFlagsList({
  title,
  flags,
  ...rest
}: FullFlagsListProps) {
  const errors = flags.filter(({ flag_level }) => flag_level === 'error')
  const warnings = flags.filter(({ flag_level }) => flag_level === 'warning')
  const infos = flags.filter(({ flag_level }) => flag_level === 'info')
  const failures = flags.filter(({ flag_level }) => flag_level === 'failure')

  const hasErrors = errors.length > 0
  const hasWarnings = warnings.length > 0
  const hasInfos = infos.length > 0
  const hasFailures = failures.length > 0

  return (
    <Box>
      {/* Checking if flags length is gt than 0 since we'll also show doc flags here */}
      {flags.length > 0 && (
        <>
          <Typography sx={{ px: 2 }} variant="overline">
            {title}
          </Typography>
          <Box>
            {hasFailures && <FlagsList flags={failures} {...rest} />}
            {hasErrors && <FlagsList flags={errors} {...rest} />}
            {hasWarnings && <FlagsList flags={warnings} {...rest} />}
            {hasInfos && <FlagsList flags={infos} {...rest} />}
          </Box>
        </>
      )}
    </Box>
  )
}
