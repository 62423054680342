import { useCallback, useEffect } from 'react'
import { NodeProps, useStore } from 'reactflow'
import { Button, Tooltip } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import BaseNode from '../BaseNode'
import AddEditObjectNodeDialog, { Property } from './AddEditObjectNodeDialog'
import { LogixHandle } from '@/types/logix'
import useBulkUpdateCreateLogixHandles from '@/services/hooks/useBulkUpdateCreateLogixHandles'
import useBulkDeleteLogixHandles from '@/services/hooks/useBulkDeleteLogixHandles'
import generateUuid from '@/utils/generate-uuid'

// We do not want this hook used elsewhere, so we didn't make it its own file
function useEdges(nodeId: string) {
  return (
    useStore(
      useCallback(
        (state) =>
          state.edges.filter((e) => {
            return e.source === nodeId || e.target === nodeId
          }),
        [nodeId],
      ),
    ) || []
  )
}

export default function CreateObjectNode({ data, ...props }: NodeProps) {
  const addEditObjectOverlay = useOverlay()

  const connectedEdges = useEdges(props.id)

  const canEdit = connectedEdges.length === 0

  const inputHandles = data.logixNode.dyn_in_handles as LogixHandle[]
  const outputHandles = data.logixNode.dyn_out_handles as LogixHandle[]
  const objectOutHandle = outputHandles[0]

  const { bulkUpdateCreateLogixHandles } = useBulkUpdateCreateLogixHandles()
  const { bulkDeleteLogixHandles } = useBulkDeleteLogixHandles()

  const onSubmit = (properties: Property[]) => {
    const handles = properties.reduce<LogixHandle[]>((acc, property) => {
      acc.push({
        id: property.id,
        data_type_code: property.dataType.id,
        name: property.label,
        allow_manual_value: false,
        required: false,
        area: 'secondary-left',
        is_list: property.isArray,
        manual_value: null,
        data_type_match_key: '',
        owner_board_id: '',
        parent_obj_handle_id: objectOutHandle.id,
        type_in_nodes_ids: [],
        type_out_nodes_ids: [],
        dyn_in_nodes_ids: [props.id],
      })
      property.properties?.forEach((subProperty) => {
        acc.push({
          id: subProperty.id,
          data_type_code: subProperty.dataType.id,
          name: subProperty.label,
          allow_manual_value: false,
          required: false,
          area: 'hidden',
          is_list: subProperty.isArray,
          manual_value: null,
          data_type_match_key: '',
          owner_board_id: '',
          parent_obj_handle_id: property.id,
          type_in_nodes_ids: [],
          type_out_nodes_ids: [],
          dyn_in_nodes_ids: [props.id],
        })
      })
      return acc
    }, [])

    const handlesToDelete = inputHandles.filter((handle) => {
      return !handles.find((h) => h.id === handle.id)
    })

    bulkUpdateCreateLogixHandles(handles)

    if (handlesToDelete.length > 0) {
      bulkDeleteLogixHandles(handlesToDelete.map((handle) => handle.id))
    }
  }

  useEffect(() => {
    if (!objectOutHandle) {
      bulkUpdateCreateLogixHandles([
        {
          id: generateUuid(),
          data_type_code: 'object',
          name: 'Object',
          allow_manual_value: false,
          required: false,
          area: 'secondary-right',
          is_list: false,
          manual_value: null,
          data_type_match_key: '',
          owner_board_id: '',
          type_in_nodes_ids: [],
          type_out_nodes_ids: [],
          parent_obj_handle_id: '',
          dyn_out_nodes_ids: [props.id],
        },
      ])
    }
  }, [bulkUpdateCreateLogixHandles, objectOutHandle, props.id])

  return (
    <>
      <BaseNode
        middleContent={
          inputHandles.length > 0 ? undefined : (
            <Button
              onClick={() => {
                addEditObjectOverlay.open()
              }}
              sx={{ ml: 1 }}
              variant="outlined"
            >
              Configure
            </Button>
          )
        }
        bottomContent={
          inputHandles.length > 0 ? (
            <Tooltip
              title={
                canEdit
                  ? ''
                  : 'Connections must be removed before this object can be edited.'
              }
              enterDelay={1000}
            >
              <span>
                <Button
                  disabled={!canEdit}
                  onClick={addEditObjectOverlay.open}
                  variant="text"
                >
                  Edit
                </Button>
              </span>
            </Tooltip>
          ) : undefined
        }
        data={{
          ...data,
          logixNode: {
            ...data.logixNode,
            dyn_out_handles:
              inputHandles.length > 0 ? data.logixNode.dyn_out_handles : [],
          },
        }}
        {...props}
      />
      <AddEditObjectNodeDialog
        inputs={inputHandles}
        overlay={addEditObjectOverlay}
        onSubmit={onSubmit}
      />
    </>
  )
}
