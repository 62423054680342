import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { ProjectGrid } from '@/types/projects'
import { getNonGridFields, sortBySortOrder } from '@/utils/field-utils'

type SelectedGridsContextValue = {
  selectedGrids: Record<string, ProjectGrid>
  setSelectedGridsIds: Dispatch<SetStateAction<string[]>>
}

const SelectedGridsContext = createContext<SelectedGridsContextValue>(
  {} as SelectedGridsContextValue,
)

export const useSelectedGridsContext = () => useContext(SelectedGridsContext)

type SelectedGridsProviderProps = {
  baseGridWithoutGridFields: ProjectGrid
  grids: ProjectGrid[]
  children: ReactNode
}

export default function SelectedGridsProvider({
  baseGridWithoutGridFields,
  grids,
  children,
}: SelectedGridsProviderProps) {
  const sortedGrids = useMemo(() => sortBySortOrder(grids), [grids])

  const defaultGridId =
    grids.length === 0 || baseGridWithoutGridFields.project_grid_fields.length
      ? baseGridWithoutGridFields.id
      : (sortedGrids.find(
          ({ project_grid_fields }) =>
            getNonGridFields(project_grid_fields).length,
        )?.id as string)

  const [selectedGridsIds, setSelectedGridsIds] = useState<string[]>([
    defaultGridId,
  ])

  const selectedGrids: Record<string, ProjectGrid> = useMemo(
    () =>
      sortedGrids.reduce((acc, grid) => {
        if (!selectedGridsIds.includes(grid.id)) return acc
        const cleanedGrid = {
          ...grid,
          project_grid_fields: getNonGridFields(grid.project_grid_fields),
        }
        return {
          ...acc,
          [grid.id]: cleanedGrid,
        }
      }, {}),
    [selectedGridsIds, sortedGrids],
  )

  return (
    <SelectedGridsContext.Provider
      value={{
        selectedGrids,
        setSelectedGridsIds,
      }}
    >
      {children}
    </SelectedGridsContext.Provider>
  )
}
