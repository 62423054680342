import BaseCurrencyField, {
  CurrencyFieldProps as BaseCurrencyFieldProps,
} from '@/components/inputs/CurrencyField'

type CurrencyFieldProps = {
  onChange: (value: string) => void
  value: string
} & Omit<BaseCurrencyFieldProps, 'onChange' | 'value' | 'currencySymbol'>

export default function CurrencyField({
  onChange,
  inputProps,
  ...props
}: CurrencyFieldProps) {
  return (
    <BaseCurrencyField
      currencySymbol="$"
      autoComplete="off"
      decimalScale={undefined}
      onChange={(value) => onChange(value.toString())} // BaseCurrencyField returns the value instead of the event
      inputProps={{
        ...inputProps,
        autoComplete: 'off',
        style: {
          textAlign: 'right',
          ...inputProps?.style,
        },
      }}
      {...props}
    />
  )
}
