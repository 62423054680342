import {
  DragIndicator,
  Delete,
  Edit,
  MoreVert,
  Pause,
  PlayArrow,
} from '@mui/icons-material'
import {
  Stack,
  Typography,
  IconButton,
  Paper,
  Menu,
  MenuItem,
  ListItemIcon,
  useTheme,
  Box,
} from '@mui/material'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { useRef } from 'react'
import ActionEditForm from './ActionEditForm'
import useOverlay from '@/hooks/useOverlay'
import ActionParamsDisplay from './ActionParamsDisplay'

type ActionCardProps = {
  onDelete: (action: WorkflowStateAction) => void
  onCancel: (action: WorkflowStateAction) => void
  onSave: (action: WorkflowStateAction) => void
  action: WorkflowStateAction
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
}

export default function ActionCard({
  onDelete,
  onCancel,
  isEditing,
  setIsEditing,
  onSave,
  action,
}: ActionCardProps) {
  const actionMenuOverlay = useOverlay()
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mx: 1,
        width: 325,
        border: 'solid 1px transparent',
        borderColor: isEditing
          ? 'secondary.main'
          : theme.palette.mode === 'light'
          ? theme.palette.divider
          : 'transparent',
        // We can't use opacity here because we want the menu to be fully opaque (default).
        background:
          isEditing || action.is_enabled
            ? theme.palette.background.default
            : `${theme.palette.background.default}50`,
      }}
    >
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <DragIndicator
            className="drag-handle"
            sx={{
              transform: 'translateX(-4px)',
              cursor: 'grab',
              ':active': { cursor: 'grabbing' },
              opacity: isEditing || action.is_enabled ? undefined : 0.5,
            }}
          />

          <Typography
            variant="body2"
            sx={{
              flexGrow: 1,
              transform: 'translateX(-4px)',
              opacity: isEditing || action.is_enabled ? undefined : 0.5,
            }}
          >
            <b>{action.action_type?.name}</b>
          </Typography>

          <Stack direction="row" spacing={0.5} alignItems="center">
            <IconButton
              onClick={actionMenuOverlay.open}
              size="small"
              ref={menuButtonRef}
            >
              <MoreVert fontSize="inherit" />
            </IconButton>
            <Menu
              open={actionMenuOverlay.isOpen}
              anchorEl={menuButtonRef.current}
              onClose={actionMenuOverlay.close}
            >
              <MenuItem
                onClick={() => {
                  actionMenuOverlay.close()
                  onSave({
                    ...action,
                    is_enabled: !action.is_enabled,
                  })
                }}
              >
                <ListItemIcon>
                  {action.is_enabled ? (
                    <Pause fontSize="small" />
                  ) : (
                    <PlayArrow fontSize="small" />
                  )}
                </ListItemIcon>
                {action.is_enabled ? 'Disable' : 'Enable'}
              </MenuItem>
              {Object.keys(action.params).length > 0 && (
                <MenuItem
                  onClick={() => {
                    actionMenuOverlay.close()
                    setIsEditing(true)
                  }}
                >
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  Edit
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  onDelete(action)
                  actionMenuOverlay.close()
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                Delete
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>

        {isEditing ? (
          <ActionEditForm
            action={action}
            onSave={(updatedAction) => {
              onSave(updatedAction)
              setIsEditing(false)
            }}
            onCancel={() => {
              onCancel(action)
              setIsEditing(false)
            }}
          />
        ) : (
          <Box
            sx={{
              opacity: action.is_enabled ? undefined : 0.5,
            }}
          >
            <ActionParamsDisplay action={action} />
          </Box>
        )}
      </Stack>
    </Paper>
  )
}
