import { useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { DocumentRow } from '@/types/documents'

type UseTableHotKeysOptions = {
  enabled: boolean
  documentRow: DocumentRow
  cellPosition?: {
    column: number
    row: number
  }
  tableGrid?: {
    columns: number
    rows: number
  }
  tableBodyEl?: HTMLTableSectionElement | null
  deleteDocumentRow?: (id: string) => void
  insertDocumentRow?: (position: 'before' | 'after') => void
}

type SetFocusParams = {
  cellPosition: {
    column: number
    row: number
  }
  tableBodyEl: HTMLTableSectionElement
}

const setFocus = ({ cellPosition, tableBodyEl }: SetFocusParams) => {
  const targetRow = tableBodyEl.querySelector(
    `tr:nth-child(${cellPosition.row})`,
  )
  let cellToFocus: HTMLInputElement | HTMLDivElement = targetRow?.querySelector(
    `td:nth-child(${cellPosition.column}) input`,
  ) as HTMLInputElement

  if ((cellToFocus as HTMLInputElement).disabled) {
    cellToFocus = targetRow?.querySelector(
      `td:nth-child(${cellPosition.column}) div.focus-container`,
    ) as HTMLDivElement
  }
  cellToFocus.focus()
  if ('select' in cellToFocus) cellToFocus.select()
}

export default function useTableHotKeys({
  enabled,
  cellPosition,
  documentRow,
  tableGrid,
  tableBodyEl,
  deleteDocumentRow,
  insertDocumentRow,
}: UseTableHotKeysOptions) {
  useHotkeys(
    ['up', 'down'],
    (_e, handler) => {
      if (cellPosition && tableGrid && tableBodyEl) {
        switch (handler?.keys?.join('')) {
          case 'up': {
            if (cellPosition.row > 1) {
              setFocus({
                cellPosition: {
                  row: cellPosition.row - 1,
                  column: cellPosition.column,
                },
                tableBodyEl,
              })
            }
            break
          }
          case 'down': {
            if (cellPosition.row < tableGrid.rows) {
              setFocus({
                cellPosition: {
                  row: cellPosition.row + 1,
                  column: cellPosition.column,
                },
                tableBodyEl,
              })
            }
            break
          }
        }
      }
    },
    {
      enabled,
      enableOnFormTags: true,
      preventDefault: enabled,
    },
    [cellPosition?.row, cellPosition?.column, tableGrid?.rows, tableBodyEl],
  )

  const focusTimerRef = useRef<NodeJS.Timeout | null>()
  const cellPositionToFocusRef = useRef<number | null>(null)

  useHotkeys(
    ['mod+alt+shift+up', 'mod+alt+shift+down'],
    (_e, handler) => {
      const keysPressed = handler?.keys?.join('')
      const insertAbove = keysPressed === 'up'
      if (insertDocumentRow) {
        insertDocumentRow(insertAbove ? 'before' : 'after')
        if (cellPosition && tableGrid && tableBodyEl) {
          // Clear any existing timeout so we don't focus on every new cell created, just the last one
          if (focusTimerRef.current) clearTimeout(focusTimerRef.current)

          // If inserting above, we want the position we're going to focus to be the same as the one we started on
          // But if inserting below, we want to increment one for each row we add
          if (!cellPositionToFocusRef.current) {
            cellPositionToFocusRef.current = insertAbove
              ? cellPosition.row
              : cellPosition.row + 1
          }

          // Has to be in a timeout so the row will be rendered before we try to focus on it
          focusTimerRef.current = setTimeout(() => {
            setFocus({
              cellPosition: {
                row: cellPositionToFocusRef.current || 0,
                column: cellPosition.column,
              },
              tableBodyEl,
            })
            cellPositionToFocusRef.current = null
            focusTimerRef.current = null
          }, 0)
        }
      }
    },
    {
      enabled,
      enableOnFormTags: true,
      preventDefault: enabled,
    },
    [
      cellPosition?.row,
      cellPosition?.column,
      documentRow.id,
      tableGrid?.rows,
      tableBodyEl,
    ],
  )

  useHotkeys(
    'mod+alt+shift+delete',
    () => {
      if (deleteDocumentRow) {
        deleteDocumentRow(documentRow.id)
        if (cellPosition && tableGrid && tableBodyEl) {
          if (tableGrid.rows !== 1) {
            setTimeout(() => {
              setFocus({
                cellPosition: {
                  row:
                    cellPosition.row === tableGrid.rows
                      ? cellPosition.row - 1
                      : cellPosition.row,
                  column: cellPosition.column,
                },
                tableBodyEl,
              })
            }, 0)
          }
        }
      }
    },
    {
      enabled,
      enableOnFormTags: true,
      preventDefault: enabled,
    },
    [
      cellPosition?.row,
      cellPosition?.column,
      documentRow,
      tableGrid?.rows,
      tableBodyEl,
    ],
  )
}
