import AceEditor, { IAceEditorProps } from 'react-ace'

import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-markdown'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/theme-github_dark'
import 'ace-builds/src-noconflict/ext-language_tools'
import { useTheme } from '@mui/material'

type CodeEditorProps = {
  value: string
  setValue: (value: string) => void
  mode?: string
  wrapEnabled?: boolean
  characterLimit?: number
} & IAceEditorProps

export default function CodeEditor({
  value,
  setValue,
  mode = 'python',
  wrapEnabled = false,
  ...props
}: CodeEditorProps) {
  const theme = useTheme()

  function onChange(newValue: string) {
    setValue(newValue)
  }

  return (
    <AceEditor
      style={{ width: '100%', minHeight: 400 }}
      mode={mode}
      theme={theme.palette.mode === 'dark' ? 'github_dark' : 'github'}
      name="UNIQUE_ID_OF_DIV"
      onChange={onChange}
      tabSize={4}
      value={value}
      wrapEnabled={wrapEnabled}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
      }}
      {...props}
    />
  )
}
