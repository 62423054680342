import { ReactNode } from 'react'
import { Stack, Card } from '@mui/material'

const positionMap = {
  right: {
    bottom: 16,
    left: 16,
  },
  left: {
    right: 16,
    bottom: 16,
  },
  top: {
    bottom: 16,
    left: 16,
  },
  bottom: {
    right: 16,
    top: 16,
  },
}

type NavigationPanelProps = {
  controls: ReactNode[]
  layout: 'right' | 'left' | 'top' | 'bottom'
}

export default function NavigationPanel({
  controls,
  layout,
}: NavigationPanelProps) {
  return (
    <Stack
      direction={layout === 'left' ? 'row-reverse' : 'row'}
      spacing={2}
      sx={{
        position: 'absolute',
        zIndex: 4,
        ...positionMap[layout],
      }}
    >
      {controls.map((control, index) => {
        return (
          <Card
            key={index}
            elevation={4}
            sx={{
              borderRadius: 8,
              padding: 0.5,
            }}
          >
            {control}
          </Card>
        )
      })}
    </Stack>
  )
}
