import { useCallback } from 'react'
import { Node } from 'reactflow'
import getPointBetween from '../helpers/getPointBetween'
import validInsertPairs from '../helpers/valid-insert-pairs'
import { LogixHandle } from '@/types/logix'
import useCreateLogixEdge from '@/services/hooks/useCreateLogixEdge'
import generateUuid from '@/utils/generate-uuid'
import { useNodeIndex } from '../NodeIndexProvider'
import useAddNode from './useAddNode'
import useConnectAfterCreated from './useConnectAfterCreated'
import useUpdateLogixHandle from '@/services/hooks/useUpdateLogixHandle'
import { useBoardContext } from '../BoardProvider'

type UseConnectHandlesOptions = {
  containerRef: React.RefObject<HTMLDivElement>
}

export type ConnectHandlesParams = {
  sourceNode: Node
  targetNode: Node
  sourceHandle: LogixHandle
  targetHandle: LogixHandle
}

export default function useConnectHandles({
  containerRef,
}: UseConnectHandlesOptions) {
  const board = useBoardContext()
  const { createLogixEdge } = useCreateLogixEdge({ boardId: board.id })
  const { updateLogixHandle } = useUpdateLogixHandle()

  const addNode = useAddNode({ board, containerRef })

  const { nodeTypes } = useNodeIndex()

  let connectAfterCreated: ReturnType<typeof useConnectAfterCreated> = () => {}

  const connectHandles = useCallback(
    ({
      sourceNode,
      targetNode,
      sourceHandle,
      targetHandle,
    }: ConnectHandlesParams) => {
      const nodeTypeCodeToInsert =
        sourceHandle &&
        targetHandle &&
        validInsertPairs[sourceHandle.data_type_code]?.[
          targetHandle.data_type_code
        ]

      // If we should insert a node between the two instead of connecting them directly, do that here
      if (nodeTypeCodeToInsert) {
        const nodeTypeToInsert = nodeTypes.find(
          ({ code }) => code === nodeTypeCodeToInsert,
        )

        if (!nodeTypeToInsert) {
          console.error('Node type code is missing from node type index')
          return
        }

        const targetNodeIsSourceNodeChild =
          sourceHandle.area === 'iteration' &&
          sourceNode.id === targetNode.parentNode

        const midPoint = getPointBetween({
          sourceNode,
          targetNode,
          targetNodeIsSourceNodeChild,
        })

        // Insert the node between
        const newNodeId = addNode({
          nodeType: nodeTypeToInsert,
          position: {
            x: midPoint.x,
            y: midPoint.y,
          },
        })

        connectAfterCreated({
          nodeId: newNodeId,
          connectingHandle: sourceHandle,
          connectingNode: sourceNode,
        })
        connectAfterCreated({
          nodeId: newNodeId,
          connectingHandle: targetHandle,
          connectingNode: targetNode,
        })
      } else {
        createLogixEdge({
          id: generateUuid(),
          source_node_id: sourceNode.id,
          target_node_id: targetNode.id,
          source_handle_id: sourceHandle.id,
          target_handle_id: targetHandle.id,
        })

        // If the target handle is an "any" handle, we need to update it's parent_obj_handle
        if (targetHandle.data_type_code === 'any') {
          updateLogixHandle({
            ...targetHandle,
            parent_obj_handle_id: sourceHandle.id,
          })
        }
      }
    },
    [addNode, createLogixEdge, nodeTypes, updateLogixHandle],
  )

  connectAfterCreated = useConnectAfterCreated({
    connectHandles,
  })

  return connectHandles
}
