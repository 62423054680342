import { useGetDocuments } from '@/service-library/hooks/documents'
import { Stack, Typography } from '@mui/material'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import ProjectTotalCounts from './ProjectTotalCounts'

// This shows the total counts of documents processed across all root organizations you have access to.
export default function TotalDocumentsPage() {
  const { rootOrganization } = useRootOrganization()

  const { organizations } = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootOrganization.id,
      fields__only: 'id',
    },
  })

  const { data } = useGetDocuments({
    paginator: 'offset',
    filters: {
      limit: '1',
      fields__only: 'id',
    },
    refetchInterval: 10000,
  })

  const { data: rootOrgData } = useGetDocuments({
    paginator: 'offset',
    filters: {
      limit: '1',
      owner_org_id__in: organizations.map(({ id }) => id).join(','),
      fields__only: 'id',
    },
    refetchInterval: 10000,
  })

  return (
    <Stack spacing={5} sx={{ py: 5 }} alignItems="center">
      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h1">
          <b>{data?.pages[0].count}</b>
        </Typography>
        <Typography variant="h6">Documents Processed</Typography>
      </Stack>

      <Stack justifyContent="center" alignItems="center">
        <Typography variant="h1">
          <b>{rootOrgData?.pages[0].count}</b>
        </Typography>
        <Typography variant="h6">From {rootOrganization.name}</Typography>
      </Stack>

      <ProjectTotalCounts />
    </Stack>
  )
}
