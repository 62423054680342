import { Typography } from '@mui/material'

export default function RunRulesDisplay() {
  return (
    <Typography variant="body2" color="text.secondary">
      Run all field and document rules for the document&apos;s current workflow
      state.
    </Typography>
  )
}
