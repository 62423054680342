import { QueryKey, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { BaseQueryOptions } from '../query-types'
import { RequestOptions } from '../request-types'

export type UseGetDetailOptions<T> = BaseQueryOptions<T> &
  RequestOptions & {
    /** ID for the item to be fetched. */
    id: string
  }

type ServiceFn<T> = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (
    options: RequestOptions & { id: string },
  ) => Promise<AxiosResponse<T>>
}

/**
 * This hook fetches a single item from the backend.
 */
export default function useGetDetail<T>({
  queryKey,
  id,
  filters,
  axiosOptions,
  enabled,
  serviceFn,
  ...options
}: UseGetDetailOptions<T> & ServiceFn<T> & { queryKey: QueryKey }) {
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      return serviceFn({
        id,
        filters,
        axiosOptions: { signal, ...axiosOptions },
      }).then((res) => res.data)
    },
    enabled: !!id && enabled,
    ...options,
  })

  return {
    ...query,
    queryKey,
  }
}
