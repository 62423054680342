import { FlagCount, FlagLevel } from '@/types/flags'
import useFlagColorMap from './useFlagColorMap'

export type FlagsCount = {
  documentFlags: FlagCount
  rowsFlags: FlagCount
}

export default function useFlagPriorityColor(flagsCount: FlagsCount) {
  const colorMap = useFlagColorMap()
  const { documentFlags, rowsFlags } = flagsCount

  let priorityLevel: FlagLevel | undefined = undefined

  if (documentFlags.failure || rowsFlags.failure) {
    priorityLevel = 'failure'
  } else if (documentFlags.error || rowsFlags.error) {
    priorityLevel = 'error'
  } else if (documentFlags.warning || rowsFlags.warning) {
    priorityLevel = 'warning'
  } else if (documentFlags.info || rowsFlags.info) {
    priorityLevel = 'info'
  }

  // We return colors for warnings and errors, but not info flags
  return {
    priorityLevel,
    color: priorityLevel ? colorMap[priorityLevel] : undefined,
  }
}
