import { Draggable } from 'react-smooth-dnd'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Add, DragIndicator } from '@mui/icons-material'
import {
  Box,
  Button,
  Container,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import useOverlay from '@/hooks/useOverlay'
import queryKeys from '@/service-library/query-keys'
import {
  useGetWorkflowStates,
  useUpdateWorkflowStates,
} from '@/service-library/hooks/workflow-states'
import { DraggableList } from '@/components/lists/DraggableList'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import AddEditWorkflowStateDialog from './AddEditWorkflowStateDialog'

export default function WorkflowStatesPage() {
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { appendCurrentParamsToUrl } = useLocationHelpers()

  const getThemeColor = useGetThemeColor()
  const newWorkflowStateOverlay = useOverlay()

  const { workflowStates: savedWorkflowStates, queryKey } =
    useGetWorkflowStates({
      filters: {
        limit: '1000',
        workflow_id: selectedWorkflow.id,
      },
      staleTime: 0, // Prevent firing off this request since we have initialData
      initialListData: selectedWorkflow.workflow_states,
    })

  const displayableWorkflowStates = useMemo(() => {
    return (
      savedWorkflowStates
        ?.filter(({ code }) => code !== 'processing')
        .toSorted((a, b) => a.sort_order - b.sort_order) || []
    )
  }, [savedWorkflowStates])

  const { updateWorkflowStates } = useUpdateWorkflowStates({
    listQueryKey: queryKey,
    sideEffectQueryKeys: [queryKeys.workflows.all],
    useSortOrder: true,
  })

  function onChange(updatedOrderWorkflowStates: WorkflowState[]) {
    const updatedWorkflowStates = updatedOrderWorkflowStates.map(
      (state, index) => ({
        ...state,
        sort_order: index,
      }),
    )

    updateWorkflowStates(updatedWorkflowStates)
  }

  return (
    <>
      <ProjectBreadcrumb label="Workflow States" url="../workflow-states" />
      <Container maxWidth="xs">
        <Stack spacing={4}>
          <Box>
            <Stack spacing={1}>
              <Typography variant="h5">{selectedWorkflow.name}</Typography>

              {displayableWorkflowStates.length > 0 && (
                <Stack spacing={0}>
                  <DraggableList
                    data={displayableWorkflowStates}
                    onChange={onChange}
                  >
                    {displayableWorkflowStates.map((state) => {
                      return (
                        // @ts-expect-error -- Draggable can have children
                        <Draggable key={state.id}>
                          <Stack
                            spacing={0.5}
                            key={state.id}
                            direction="row"
                            alignItems="center"
                          >
                            <Box
                              sx={{
                                background: getThemeColor(state.color),
                                width: 4,
                                height: 24,
                                borderRadius: 4,
                              }}
                            />
                            <ListItemButton
                              component={Link}
                              to={appendCurrentParamsToUrl(state.id)}
                              sx={{
                                borderRadius: 2,
                                py: 0.25,
                                px: 1,
                              }}
                            >
                              <ListItemText
                                sx={{ flexGrow: 1 }}
                                primary={state.name}
                              />
                              {selectedWorkflow.initial_workflow_state_id ===
                                state.id && (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  Initial State
                                </Typography>
                              )}
                            </ListItemButton>

                            <DragIndicator
                              className="drag-handle"
                              sx={{
                                cursor: 'grab',
                                color: 'text.secondary',
                                ':active': { cursor: 'grabbing' },
                              }}
                            />
                          </Stack>
                        </Draggable>
                      )
                    })}
                  </DraggableList>
                </Stack>
              )}

              <Box sx={{ transform: 'translateX(-6px)' }}>
                <Button
                  variant="text"
                  startIcon={<Add />}
                  onClick={newWorkflowStateOverlay.open}
                >
                  Add Workflow State
                </Button>
              </Box>
              <AddEditWorkflowStateDialog
                overlay={newWorkflowStateOverlay}
                workflowId={selectedWorkflow.id}
                listQueryKey={queryKey}
              />
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  )
}
