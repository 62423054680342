import PaginatedResponse from '@/types/paginated-response'
import axios from './auth-v2-axios'
import { ContentCategory } from '@/types/content-categories'

export function getContentCategories(projectId: string) {
  const searchParams = new URLSearchParams({
    project_id: projectId,
    limit: '1000',
    fields__include: 'project_content_category_items',
    project_content_category_items__fields__include:
      'project_content_category_id',
  })
  return axios.get<PaginatedResponse<Required<ContentCategory>>>(
    `/v2/pd/project_content_categories?${searchParams.toString()}`,
  )
}
