import { useEffect, useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { ListItem } from '@mui/material'
import { FormAutocomplete } from '@/components/forms'
import { useGetDataLists } from '@/service-library/hooks/data-lists'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import { ProjectGridFieldType } from '@/types/fields'

export default function PickerListFields({
  paramsSchema,
}: {
  paramsSchema: ProjectGridFieldType['params_schema']
}) {
  const { rootOrganization } = useRootOrganization()
  const { dataLists, isLoading } = useGetDataLists({
    filters: {
      limit: '100',
      org_id: rootOrganization.id,
      fields__include: 'data_list_columns',
    },
  })

  const methods = useFormContext()
  const list = methods.watch('params.data_list')
  const properties = paramsSchema.properties || {}

  const listRef = useRef(list) // list always returns a new object, so we use the ref to avoid unnecessary rerenders

  const columns = useMemo(() => {
    if (!isLoading && list) {
      const allColumns = dataLists.find(
        ({ id }) => list.id === id,
      )?.data_list_columns
      return (
        allColumns?.filter(({ is_system_managed }) => !is_system_managed) || []
      )
    }
    return []
  }, [dataLists, isLoading, list])

  useEffect(() => {
    if (JSON.stringify(listRef.current) === JSON.stringify(list)) return

    methods.setValue('params.data_list_columns', [])
    methods.setValue('params.default_prediction_context_columns', [])

    listRef.current = list
  }, [list, methods])

  return (
    <>
      <FormAutocomplete
        required={paramsSchema.required.includes('data_list_id')}
        name="params.data_list"
        label="List"
        autoHighlight
        noOptionsText="No items available."
        textFieldProps={{ variant: 'filled' }}
        options={dataLists}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        getOptionLabel={(option) => option?.name || ''}
        // This is so we can choose the key, otherwise it throws a duplicate key error when we have duplicates
        renderOption={(props, option) => {
          return (
            <ListItem {...props} key={option.id} dense>
              {option.name}
            </ListItem>
          )
        }}
      />
      <FormAutocomplete
        required={paramsSchema.required.includes('data_list_column_names')}
        name="params.data_list_columns"
        label="List Columns"
        multiple
        autoHighlight
        disableCloseOnSelect
        noOptionsText="No items available."
        textFieldProps={{ variant: 'filled' }}
        options={columns}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        getOptionLabel={(option) => option?.name || ''}
      />
      <FormAutocomplete
        required={paramsSchema.required.includes(
          'default_prediction_context_columns',
        )}
        name="params.default_prediction_context_columns"
        label={properties.default_prediction_context_columns.display.label}
        helperText={
          properties.default_prediction_context_columns.display.description
        }
        multiple
        autoHighlight
        disableCloseOnSelect
        noOptionsText="No items available."
        textFieldProps={{ variant: 'filled' }}
        options={columns}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        getOptionLabel={(option) => option?.name || ''}
      />
    </>
  )
}
