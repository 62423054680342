import { getContentCategories } from '@/services/content-categories'
import {
  createContentCategoryItem,
  deleteContentCategoryItem,
  updateContentCategoryItem,
} from '@/services/content-category-items'
import { useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash.clonedeep'
import useMutation from './useMutation'
import useQuery from './useQuery'
import {
  ContentCategory,
  ContentCategoryItem,
} from '@/types/content-categories'

type UseContentCategoriesOptions = {
  projectId: string | undefined
  refetchOnWindowFocus?: boolean
}

export default function useContentCategories({
  projectId,
  refetchOnWindowFocus = false,
}: UseContentCategoriesOptions) {
  const queryKey = ['content-categories', projectId]
  const queryClient = useQueryClient()

  const onError = (
    _err: unknown,
    _data: unknown,
    context: { previous: ContentCategory[] } | undefined,
  ) => {
    queryClient.setQueryData(queryKey, context?.previous)
  }

  const onSettled = () => {
    queryClient.invalidateQueries({ queryKey })
  }
  const query = useQuery({
    queryKey,
    queryFn: () =>
      getContentCategories(projectId as string).then(
        ({ data }) => data.results,
      ),
    enabled: !!projectId,
    refetchOnWindowFocus,
  })

  const createMutation = useMutation({
    mutationFn: createContentCategoryItem,
    onMutate: async (newCategoryItem) => {
      await queryClient.cancelQueries({ queryKey })
      const previous = queryClient.getQueryData(
        queryKey,
      ) as Required<ContentCategory>[]

      let updatedContentCategories = cloneDeep(previous)
      const updatedContCat = updatedContentCategories.find(
        ({ id }) => id === newCategoryItem.project_content_category_id,
      )

      if (updatedContCat) {
        updatedContCat.project_content_category_items.push(newCategoryItem)
      } else {
        updatedContentCategories = [
          {
            id: newCategoryItem.project_content_category_id,
            project_content_category_items: [newCategoryItem],
            // default values
            name: 'Content Category',
            color: 'blue',
            sort_order: 0,
            type: 'Model',
            project_id: projectId as string,
          },
        ]
      }

      queryClient.setQueryData(queryKey, updatedContentCategories)
      return { previous }
    },
    onError,
    onSettled,
  })

  const updateMutation = useMutation({
    mutationFn: updateContentCategoryItem,
    onMutate: async (updatedCategoryItem) => {
      await queryClient.cancelQueries({ queryKey })
      const previous = queryClient.getQueryData(
        queryKey,
      ) as Required<ContentCategory>[]

      const updatedContentCategories = cloneDeep(previous)
      const updatedContCatIndex = updatedContentCategories.findIndex(
        ({ id }) => id === updatedCategoryItem.project_content_category_id,
      )
      const updatedCatItemIndex = updatedContentCategories[
        updatedContCatIndex
      ].project_content_category_items.findIndex(
        ({ id }) => id === updatedCategoryItem.id,
      )
      updatedContentCategories[
        updatedContCatIndex
      ].project_content_category_items[updatedCatItemIndex] =
        updatedCategoryItem

      queryClient.setQueryData(queryKey, updatedContentCategories)
      return { previous }
    },
    onError,
    onSettled,
  })

  const deleteMutation = useMutation({
    mutationFn: (deletedCategoryItem: ContentCategoryItem) =>
      deleteContentCategoryItem(deletedCategoryItem.id),
    onMutate: async (deletedCategoryItem) => {
      await queryClient.cancelQueries({ queryKey })
      const previous = queryClient.getQueryData(
        queryKey,
      ) as Required<ContentCategory>[]
      const updatedContentCategories = cloneDeep(previous)
      const updatedContCat = updatedContentCategories.find(
        ({ id }) => id === deletedCategoryItem.project_content_category_id,
      ) as Required<ContentCategory>
      updatedContCat.project_content_category_items =
        updatedContCat.project_content_category_items.filter(
          ({ id }) => id !== deletedCategoryItem.id,
        )

      queryClient.setQueryData(queryKey, updatedContentCategories)
      return { previous }
    },
    onError,
    onSettled,
  })

  function createCategoryItem(item: ContentCategoryItem) {
    return createMutation.mutateAsync({
      ...item,
      sort_order: 1000001,
    })
  }

  const updateCategoryItem = async (
    updatedCategoryItem: ContentCategoryItem,
  ) => {
    const currentCategoryContents = queryClient.getQueryData(
      queryKey,
    ) as Required<ContentCategory>[]

    const updateCategoryContent = currentCategoryContents.find(
      ({ id }) => id === updatedCategoryItem.project_content_category_id,
    ) as Required<ContentCategory>
    const currentCategoryItem =
      updateCategoryContent.project_content_category_items.find(
        ({ id }) => id === updatedCategoryItem.id,
      ) as ContentCategoryItem

    if (
      JSON.stringify(currentCategoryItem) ===
      JSON.stringify(updatedCategoryItem)
    ) {
      return
    }

    return updateMutation.mutateAsync(updatedCategoryItem)
  }

  return {
    ...query,
    categoriesContent: query.data,
    createCategoryItem,
    updateCategoryItem,
    deleteCategoryItem: deleteMutation.mutateAsync,
    createMutation,
    updateMutation,
    deleteMutation,
  }
}
