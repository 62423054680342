import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import useMutation from './useMutation'
import { bulkDeleteLogixHandles } from '../logix'
import { getQueryKey } from './useLogixBoard'
import { LogixBoard } from '@/types/logix'
import { useBoardContext } from '@/components/flow-editor/BoardProvider'

type UseBulkDeleteLogixHandlesOptions =
  | UseMutationOptions<unknown, unknown, string[], { previous: LogixBoard }>
  | undefined

export default function useBulkDeleteLogixHandles(
  options: UseBulkDeleteLogixHandlesOptions = {},
) {
  const queryClient = useQueryClient()
  const board = useBoardContext()

  const boardQueryKey = getQueryKey(board.id)

  const mutation = useMutation({
    sideEffectQueryKeys: [['logix-board']],
    mutationKey: ['delete-logix-handles'],
    mutationFn: (handleIds: string[]) => bulkDeleteLogixHandles(handleIds),
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: boardQueryKey })
      const previous = queryClient.getQueryData(boardQueryKey) as LogixBoard
      return { previous }
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(boardQueryKey, context?.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: boardQueryKey })
    },
    ...options,
  })

  return { ...mutation, bulkDeleteLogixHandles: mutation.mutateAsync }
}
