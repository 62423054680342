import { ProjectCategory } from '@/types/projects'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<ProjectCategory>({
  pathCategory: 'project_categories',
})

export function useGetProjectCategory({
  id,
  ...options
}: UseGetDetailOptions<ProjectCategory>) {
  const query = useGetDetail<ProjectCategory>({
    id,
    queryKey: queryKeys.projectCategories.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectCategory: query.data,
    ...query,
  }
}

export function useCreateProjectCategory(
  options: UseCreateDetailOptions<ProjectCategory> = {},
) {
  const mutation = useCreateDetail<ProjectCategory>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectCategory: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectCategory(
  options: UseUpdateDetailOptions<ProjectCategory> = {},
) {
  const mutation = useUpdateDetail<ProjectCategory>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectCategory: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectCategory(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<ProjectCategory>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectCategory: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectCategories({
  filters,
  ...options
}: UseGetListOptions<ProjectCategory> = {}) {
  const query = useGetList<ProjectCategory>({
    filters,
    queryKey: queryKeys.projectCategories.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectCategories: query.allData,
    ...query,
  }
}

export function useUpdateProjectCategories(
  options: UseUpdateListOptions<ProjectCategory> = {},
) {
  const mutation = useUpdateList<ProjectCategory>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectCategories: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectCategories({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectCategory>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectCategories: mutation.mutateAsync,
    ...mutation,
  }
}
