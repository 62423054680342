import { ExportJob } from '@/types/export-jobs'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<ExportJob>({
  pathCategory: 'export_jobs',
})

export function useGetExportJob({
  id,
  ...options
}: UseGetDetailOptions<ExportJob>) {
  const query = useGetDetail<ExportJob>({
    id,
    queryKey: queryKeys.exportJobs.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    exportJob: query.data,
    ...query,
  }
}

export function useCreateExportJob(
  options: UseCreateDetailOptions<ExportJob> = {},
) {
  const mutation = useCreateDetail<ExportJob>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createExportJob: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateExportJob(
  options: UseUpdateDetailOptions<ExportJob> = {},
) {
  const mutation = useUpdateDetail<ExportJob>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateExportJob: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteExportJob(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<ExportJob>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteExportJob: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetExportJobs({
  filters,
  ...options
}: UseGetListOptions<ExportJob> = {}) {
  const query = useGetList<ExportJob>({
    filters,
    queryKey: queryKeys.exportJobs.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    exportJobs: query.allData,
    ...query,
  }
}

export function useUpdateExportJobs(
  options: UseUpdateListOptions<ExportJob> = {},
) {
  const mutation = useUpdateList<ExportJob>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateExportJobs: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteExportJobs({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ExportJob>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteExportJobs: mutation.mutateAsync,
    ...mutation,
  }
}
