import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button, ListItem } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { ProjectGridField } from '@/types/fields'
import {
  ProjectModelTrainingField,
  ProjectModelType,
} from '@/types/project-models'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useDeleteProjectModelTrainingFields,
  useUpdateProjectModelTrainingFields,
} from '@/service-library/hooks/project-model-training-fields'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormAutocomplete, PixyDocsForm } from '@/components/forms'

type EditTrainingFieldsDialogProps = {
  overlay: OverlayState
  fields: ProjectGridField[]
  flow: 'in' | 'out'
  listQueryKey: QueryKey
  projectModelId: string
  projectModelType: ProjectModelType
  modelTrainingFields: {
    inputs: ProjectModelTrainingField[]
    outputs: ProjectModelTrainingField[]
  }
  restrictedFieldTypes?: { kind: 'exclude' | 'include'; ids: string[] }
}

export default function EditTrainingFieldsDialog({
  overlay,
  fields,
  flow,
  listQueryKey,
  projectModelId,
  projectModelType,
  modelTrainingFields,
  restrictedFieldTypes,
}: EditTrainingFieldsDialogProps) {
  const formFieldName = `training_${flow}_grid_fields`

  const gridFieldsForCurrentFlow = useMemo(() => {
    return modelTrainingFields[flow === 'in' ? 'inputs' : 'outputs'].map(
      ({ project_grid_field_id }) =>
        fields.find(
          ({ id }) => id === project_grid_field_id,
        ) as ProjectGridField,
    )
  }, [fields, flow, modelTrainingFields])

  const gridFieldsForOtherFlow = useMemo(() => {
    return modelTrainingFields[flow === 'in' ? 'outputs' : 'inputs'].map(
      ({ project_grid_field_id }) =>
        fields.find(
          ({ id }) => id === project_grid_field_id,
        ) as ProjectGridField,
    )
  }, [fields, flow, modelTrainingFields])

  const defaultValues = useMemo(
    () => ({
      [formFieldName]: gridFieldsForCurrentFlow,
    }),
    [formFieldName, gridFieldsForCurrentFlow],
  )

  const methods = useForm({ defaultValues })

  const { formState, reset, watch } = methods

  const { updateProjectModelTrainingFields } =
    useUpdateProjectModelTrainingFields({
      listQueryKey,
      onError: () => {
        showErrorSnackbar('Failed to update training fields.')
      },
    })

  const { deleteProjectModelTrainingFields } =
    useDeleteProjectModelTrainingFields({
      listQueryKey,
      onError: () => {
        showErrorSnackbar('Failed to update training fields.')
      },
    })

  function handleCreateOrDeleteTrainingField(values: {
    [key: string]: ProjectGridField[]
  }) {
    const gridFieldIdsToCreate: string[] = []
    const gridFieldIdsToKeep = new Set()

    const currentGridFieldsIds = new Set(
      gridFieldsForCurrentFlow.map(({ id }) => id),
    )

    values[formFieldName].forEach((field) => {
      const isInCurrent = currentGridFieldsIds.has(field.id)
      if (isInCurrent) {
        gridFieldIdsToKeep.add(field.id)
      } else {
        gridFieldIdsToCreate.push(field.id)
      }
    })

    if (gridFieldIdsToCreate.length) {
      const newTrainingFields = gridFieldIdsToCreate.map((id) => ({
        id: generateUuid(),
        project_model_id: projectModelId,
        project_grid_field_id: id,
        flow,
      }))
      updateProjectModelTrainingFields(newTrainingFields)
    }

    const gridFieldsIdsToDelete =
      currentGridFieldsIds.difference(gridFieldIdsToKeep)

    if (gridFieldsIdsToDelete.size) {
      const trainingFieldIdsToDelete = modelTrainingFields[
        flow === 'in' ? 'inputs' : 'outputs'
      ]
        .filter(({ project_grid_field_id }) =>
          gridFieldsIdsToDelete.has(project_grid_field_id),
        )
        .map(({ id }) => id)
      deleteProjectModelTrainingFields(trainingFieldIdsToDelete)
    }
    overlay.close()
  }

  const selectedGridFields = watch(formFieldName)
  const selectedGridFieldsIds = selectedGridFields.map(({ id }) => id)

  const minFields =
    flow === 'in' ? projectModelType.min_ins : projectModelType.min_outs
  const maxFields =
    flow === 'in' ? projectModelType.max_ins : projectModelType.max_outs

  const filteredFields = restrictedFieldTypes
    ? fields.filter((field) =>
        restrictedFieldTypes.kind === 'include'
          ? restrictedFieldTypes.ids.includes(field.project_grid_field_type_id)
          : !restrictedFieldTypes.ids.includes(
              field.project_grid_field_type_id,
            ),
      )
    : fields

  const { isDirty, isValid } = formState

  const disabledIds = useMemo(() => {
    if (maxFields != null && selectedGridFieldsIds.length >= maxFields) {
      return filteredFields
        .filter(({ id }) => !selectedGridFieldsIds.includes(id))
        .map(({ id }) => id)
    }
    return gridFieldsForOtherFlow.map(({ id }) => id)
  }, [maxFields, selectedGridFieldsIds, gridFieldsForOtherFlow, filteredFields])

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  return (
    <Dialog
      title={`Edit ${flow === 'in' ? 'Input' : 'Output'} Fields`}
      {...overlay}
    >
      <PixyDocsForm
        methods={methods}
        onSubmit={handleCreateOrDeleteTrainingField}
      >
        <DialogContent>
          <FormAutocomplete
            name={formFieldName}
            label="Fields"
            options={filteredFields}
            multiple
            autoHighlight
            disableCloseOnSelect
            noOptionsText="No fields available."
            textFieldProps={{
              variant: 'filled',
              InputLabelProps: { shrink: true },
            }}
            required={minFields != 0}
            isOptionEqualToValue={(option, value) => option?.id === value.id}
            getOptionLabel={(option) => option?.name || ''}
            getOptionDisabled={(option) => disabledIds.includes(option.id)}
            renderOption={(props, option) => {
              return (
                <ListItem {...props} key={option.id} dense>
                  {option.name}
                </ListItem>
              )
            }}
          />
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button
            disabled={
              !isValid ||
              !isDirty ||
              // Sometimes validation is not reliable when the field is not in focus
              // and chips are removed, so we add this condition to account for that
              (minFields != 0 && !selectedGridFieldsIds.length)
            }
            type="submit"
          >
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
