import { GroupOption } from '@/types/metrics'
import {
  ProjectModelVersion,
  TrainingMetricsCountData,
} from '@/types/project-models'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { getList } from '../request-wrappers'
import {
  formatDate,
  getConvertedDatesByGroup,
  getIsEnabled,
} from '@/utils/date-metrics'

const service = buildService<ProjectModelVersion>({
  pathCategory: 'project_model_versions',
})

export function useGetProjectModelVersion({
  id,
  ...options
}: UseGetDetailOptions<ProjectModelVersion>) {
  const query = useGetDetail<ProjectModelVersion>({
    id,
    queryKey: queryKeys.projectModelVersions.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectModelVersion: query.data,
    ...query,
  }
}

export function useCreateProjectModelVersion(
  options: UseCreateDetailOptions<ProjectModelVersion> = {},
) {
  const mutation = useCreateDetail<ProjectModelVersion>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectModelVersion: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectModelVersion(
  options: UseUpdateDetailOptions<ProjectModelVersion> = {},
) {
  const mutation = useUpdateDetail<ProjectModelVersion>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectModelVersion: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectModelVersion(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<ProjectModelVersion>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectModelVersion: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectModelVersions({
  filters,
  ...options
}: UseGetListOptions<ProjectModelVersion> = {}) {
  const query = useGetList<ProjectModelVersion>({
    filters,
    queryKey: queryKeys.projectModelVersions.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectModelVersions: query.allData,
    ...query,
  }
}

export function useUpdateProjectModelVersions(
  options: UseUpdateListOptions<ProjectModelVersion> = {},
) {
  const mutation = useUpdateList<ProjectModelVersion>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectModelVersions: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectModelVersions({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectModelVersion>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectModelVersions: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Get Training Metrics
 * Get training metrics divided by project model version
 */
export function useGetTrainingMetrics({
  projectModelId,
  startDate,
  endDate,
  groupedBy,
  tz = Intl.DateTimeFormat().resolvedOptions().timeZone,
  enabled = true,
  ...options
}: Omit<UseGetListOptions<TrainingMetricsCountData>, 'filters'> & {
  projectModelId?: string
  startDate: Date
  endDate: Date
  groupedBy: GroupOption
  tz?: string
}) {
  const { startDate: newStartDate, endDate: newEndDate } =
    getConvertedDatesByGroup({ startDate, endDate, groupedBy })

  const filters = {
    project_model_id: projectModelId,
    start_date: formatDate(newStartDate as Date),
    end_date: formatDate(newEndDate as Date),
    grouped_by: groupedBy,
    tz,
  }
  const query = useGetList<TrainingMetricsCountData>({
    queryKey: queryKeys.projectModelVersions.list({
      filters,
    }),
    serviceFn: ({ axiosOptions }) => {
      return getList<TrainingMetricsCountData>({
        url: '/v2/pd/project_model_versions/training_metrics',
        filters,
        ...axiosOptions,
      })
    },
    enabled:
      enabled &&
      !!projectModelId &&
      getIsEnabled(startDate, endDate, groupedBy),
    ...options,
  })
  return {
    trainingMetrics: query.data?.pages[0],
    ...query,
  }
}
