import { Button } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import LabeledProgressIndicator from '@/components/labeled-progress-indicator/LabeledProgressIndicator'
import ProcessingDocumentsDialog from './ProcessingDocumentsDialog'
import { useDocumentCount } from '../document-count-provider/DocumentCountProvider'

export default function ProcessingIndicator() {
  const processingDocumentsOverlay = useOverlay()
  const { processingCount, refetch } = useDocumentCount()

  return (
    <>
      {processingCount > 0 && (
        <LabeledProgressIndicator
          color="primary"
          component={Button}
          componentProps={{
            onClick: processingDocumentsOverlay.open,
            sx: { textTransform: 'None' },
            variant: 'text',
          }}
          hideSpinner
        >
          {processingCount} Document{processingCount > 1 ? 's' : ''} Processing
        </LabeledProgressIndicator>
      )}
      <ProcessingDocumentsDialog
        count={processingCount}
        overlay={processingDocumentsOverlay}
        onCancel={() => refetch()}
      />
    </>
  )
}
