import { Team } from '@/types/teams'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<Team>({
  pathCategory: 'teams',
})

export function useGetTeam({ id, ...options }: UseGetDetailOptions<Team>) {
  const query = useGetDetail<Team>({
    id,
    queryKey: queryKeys.teams.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    team: query.data,
    ...query,
  }
}

export function useCreateTeam(options: UseCreateDetailOptions<Team> = {}) {
  const mutation = useCreateDetail<Team>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createTeam: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateTeam(options: UseUpdateDetailOptions<Team> = {}) {
  const mutation = useUpdateDetail<Team>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateTeam: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteTeam({ ...options }: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<Team>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteTeam: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetTeams({
  filters,
  ...options
}: UseGetListOptions<Team> = {}) {
  const query = useGetList<Team>({
    filters,
    queryKey: queryKeys.teams.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    teams: query.allData,
    ...query,
  }
}

export function useUpdateTeams(options: UseUpdateListOptions<Team> = {}) {
  const mutation = useUpdateList<Team>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateTeams: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteTeams({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<Team>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteTeams: mutation.mutateAsync,
    ...mutation,
  }
}
