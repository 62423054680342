import { LogixHandle } from '@/types/logix'
import IntegerField from '@/components/inputs/IntegerField'

type HandleIntegerInputProps = {
  logixHandle: LogixHandle
  updateHandleManualValue: (updatedValue: LogixHandle['manual_value']) => void
}

export default function HandleIntegerInput({
  logixHandle,
  updateHandleManualValue,
}: HandleIntegerInputProps) {
  const { id } = logixHandle

  function onChange(newValue: number | null) {
    updateHandleManualValue(newValue)
  }

  return (
    <IntegerField
      name={`text-${id}`}
      size="small"
      variant="outlined"
      sx={{ width: 100 }}
      value={logixHandle.manual_value as number}
      onChange={onChange}
      color="neutral"
      inputProps={{
        sx: {
          px: 1,
          py: 0.5,
          fontSize: 12,
        },
      }}
    />
  )
}
