import { Fragment, useState } from 'react'
import { Delete, FiberManualRecord } from '@mui/icons-material'
import { DocumentFlag, RowValueFlag } from '@/types/flags'
import {
  ListItem,
  Tooltip,
  IconButton,
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { useDeleteDocumentFlag } from '@/service-library/hooks/document-flags'
import { useDeleteRowValueFlag } from '@/service-library/hooks/row-value-flags'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import useFlagColorMap from './useFlagColorMap'
import FlagLongDescription from './FlagLongDescription'

type FlagListItemBaseProps = {
  flag: DocumentFlag | RowValueFlag
  dismissFlag: () => void
  isDismissing: boolean
  onClick?: () => void
}

function FlagListItemBase({
  flag,
  onClick,
  dismissFlag,
  isDismissing,
}: FlagListItemBaseProps) {
  const colorMap = useFlagColorMap()
  const color = colorMap[flag.flag_level]
  const ListItemWrapper = onClick ? ListItemButton : Fragment
  // This is stupid, but it prevents an error from being shown in the console from passing onClick to a Fragment,
  // even though its undefined 🤦‍♂️
  const listItemWrapperProps = onClick ? { onClick } : {}

  return (
    <ListItem
      dense
      disablePadding={!!onClick}
      secondaryAction={
        flag.flag_level !== 'error' ? (
          <Tooltip title="Dismiss">
            {!isDismissing ? (
              <IconButton
                edge="end"
                size="small"
                sx={{ transform: 'translateX(4px)' }} // Slight adjustment for positioning
                onClick={() => dismissFlag()}
              >
                <Delete fontSize="small" />
              </IconButton>
            ) : (
              <Stack direction="row" alignItems="center">
                <CircularProgress size={16} color="neutral" />
              </Stack>
            )}
          </Tooltip>
        ) : undefined
      }
    >
      <ListItemWrapper {...listItemWrapperProps}>
        <ListItemIcon sx={{ minWidth: 28 }}>
          <FiberManualRecord sx={{ fontSize: 16, color: color.base }} />
        </ListItemIcon>
        <ListItemText
          sx={{ color: color.base }}
          primary={flag.short_description || flag.flag_type?.short_description}
          secondary={
            <FlagLongDescription longDescription={flag.long_description} />
          }
          secondaryTypographyProps={{
            component: 'div',
          }}
        />
      </ListItemWrapper>
    </ListItem>
  )
}

type DocumentFlagListItemProps = {
  flag: DocumentFlag
}

type RowValueFlagListItemProps = {
  flag: RowValueFlag
  onFlagClick?: (
    flag: RowValueFlag,
    cellToFocus: HTMLInputElement | HTMLDivElement | null,
  ) => void
}

const deleteMutateOptions = {
  onSuccess: () => {
    showSuccessSnackbar('Flag Dismissed')
  },
  onError: () => {
    showErrorSnackbar('Error Dismissing Flag. Please try again later.')
  },
}

export function DocumentFlagListItem({ flag }: DocumentFlagListItemProps) {
  const [isDismissing, setIsDismissing] = useState(false)
  const { deleteDocumentFlag } = useDeleteDocumentFlag({
    sideEffectQueryKeys: [
      queryKeys.documentFlags.lists(),
      queryKeys.documents.all,
      queryKeys.documentRows.lists(),
    ],
    ...deleteMutateOptions,
    onSettled: () => setIsDismissing(false),
  })

  return (
    <FlagListItemBase
      flag={flag}
      dismissFlag={() => {
        setIsDismissing(true)
        deleteDocumentFlag(flag.id)
      }}
      isDismissing={isDismissing}
    />
  )
}

export function RowValueFlagListItem({
  flag,
  onFlagClick,
}: RowValueFlagListItemProps) {
  const [isDismissing, setIsDismissing] = useState(false)

  const { deleteRowValueFlag } = useDeleteRowValueFlag({
    sideEffectQueryKeys: [
      queryKeys.rowValueFlags.lists(),
      queryKeys.documents.lists(),
      queryKeys.documentRows.lists(),
      queryKeys.documentRowValues.lists(),
    ],
    ...deleteMutateOptions,
    onSettled: () => setIsDismissing(false),
  })

  const focusOnField = () => {
    let cellToFocus: HTMLInputElement | HTMLDivElement | null =
      document.querySelector(
        `[data-focusid="${flag.document_row_value_id}"] input`,
      )

    if (cellToFocus) {
      if ('disabled' in cellToFocus && cellToFocus.disabled) {
        cellToFocus = cellToFocus.closest('div.focus-container') || cellToFocus
      }
      cellToFocus.focus()
    }
    onFlagClick?.(flag, cellToFocus)
  }

  return (
    <FlagListItemBase
      flag={flag}
      onClick={onFlagClick ? focusOnField : undefined}
      dismissFlag={() => {
        setIsDismissing(true)
        deleteRowValueFlag(flag.id)
      }}
      isDismissing={isDismissing}
    />
  )
}
