import { Dispatch, SetStateAction, useRef } from 'react'
import { Language } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { useGetLanguages } from '@/service-library/hooks/languages'

type LanguageSelectProps = {
  languageIds: string[]
  languageCode: string
  sourceLanguageCode: string
  setLanguageCode: Dispatch<SetStateAction<string>>
}

export default function LanguageSelect({
  languageIds,
  languageCode,
  sourceLanguageCode,
  setLanguageCode,
}: LanguageSelectProps) {
  const { languages: allLanguages, isLoading } = useGetLanguages({
    filters: { limit: '1000' },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  })
  const languages = allLanguages.filter(({ id }) => languageIds.includes(id))

  const overlay = useOverlay()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const selectedLanguage =
    languages.find(({ id }) => id === languageCode) || languages[0]

  return isLoading ? null : (
    <>
      <Button
        startIcon={<Language />}
        onClick={overlay.toggle}
        ref={anchorRef}
        variant="text"
      >
        {selectedLanguage.name}
      </Button>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
        elevation={16}
      >
        {languages.map(({ id, name }) => (
          <MenuItem
            dense
            key={id}
            onClick={() => {
              setLanguageCode(id)
              overlay.close()
            }}
            selected={id === languageCode}
          >
            {name}
            {sourceLanguageCode === id ? ' (Source)' : ''}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
