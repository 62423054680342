import { LogixEdge, LogixNode } from '@/types/logix'
import { Edge, Node } from 'reactflow'

export function convertLogixEdge(logixEdge: LogixEdge): Edge {
  return {
    id: logixEdge.id,
    type: 'edge',
    source: logixEdge.source_node_id,
    target: logixEdge.target_node_id,
    sourceHandle: logixEdge.source_handle_id,
    targetHandle: logixEdge.target_handle_id,
  }
}

export default function convertLogixNode(logixNode: LogixNode): Node {
  return {
    id: logixNode.id,
    type: logixNode.node_type.code,
    height: logixNode.position.h || 200,
    width: logixNode.position.w || 200,
    // TODO: Calculate this based on relative position to the parent node, if there is one (otherwise same as absolute position)
    position: {
      x: logixNode.position.x,
      y: logixNode.position.y,
    },
    // The position from the backend is the absolute position
    positionAbsolute: {
      x: logixNode.position.x,
      y: logixNode.position.y,
    },
    data: {
      logixNode,
    },
  }
}
