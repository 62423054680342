import { forwardRef } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

export type IntegerFieldProps = {
  value?: number | string
  onChange?: (
    newValue: number | '', // allowing to pass an empty string so final value can be restored
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
} & Omit<Partial<NumberFormatProps>, 'onChange' | 'size'> &
  Omit<TextFieldProps, 'onChange' | 'value'>

const IntegerField = forwardRef(function IntegerField(
  { value, onChange, ...rest }: IntegerFieldProps,
  ref,
) {
  return (
    <NumberFormat
      // @ts-expect-error -- Ignore for now
      customInput={TextField}
      isNumericString
      decimalScale={0}
      value={value}
      onChange={(event) => {
        if (onChange) {
          const newValue = parseFloat(event.target.value)
          onChange(isNaN(newValue) ? '' : newValue, event)
        }
      }}
      inputRef={ref}
      {...rest}
    />
  )
})

export default IntegerField
