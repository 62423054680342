// Import the functions you need from the SDKs you need
import { initializeApp } from '@firebase/app'
import {
  getAnalytics,
  isSupported as isSupportedAnalytics,
} from '@firebase/analytics'
import { getRemoteConfig } from '@firebase/remote-config'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCKXV2uUkO2wVgW3YaeEjHIL0i0xwgpmKs',
  authDomain: 'pixydocs.firebaseapp.com',
  projectId: 'pixydocs',
  storageBucket: 'pixydocs.appspot.com',
  messagingSenderId: '574597456348',
  appId: '1:574597456348:web:48476e0e6a987616532f19',
  measurementId: 'G-C4ZNPLJYJB',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = async () =>
  (await isSupportedAnalytics()) && getAnalytics(app)
export const remoteConfig = getRemoteConfig(app)
