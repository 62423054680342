import { useMemo, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import { Organization } from '@/types/organizations'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'
import OrganizationTreeSearchSkeleton from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import OrganizationsFilterTreeSearch from './OrganizationsFilterTreeSearch'

type OrganizationsFilterDialogProps = {
  isFetching: boolean
  overlay: OverlayState
  organizations: Organization[]
  filterContext: ReturnType<typeof useDocumentFiltersContext>
  title?: string
}

const compareIds = (oldIds: string[], newIds: string[]) => {
  if (oldIds.length !== newIds.length) {
    return false
  }
  return oldIds.every((item) => newIds.includes(item))
}

export default function OrganizationsFilterDialog({
  isFetching,
  overlay,
  organizations,
  filterContext,
  title,
}: OrganizationsFilterDialogProps) {
  const { project } = useProjectContext()
  const { documentFilters, includeFilter, handleOnDocumentFilterChange } =
    filterContext

  const [selectedOrgsIds, setSelectedOrgsIds] = useState(
    includeFilter.orgs && documentFilters ? documentFilters.orgIds : [],
  )

  const defaultOrgsToExpand = useMemo(
    () =>
      includeFilter.orgs && documentFilters && documentFilters.orgIds.length > 0
        ? organizations.filter(({ id }) => documentFilters.orgIds.includes(id))
        : [],
    [documentFilters, includeFilter.orgs, organizations],
  )

  const selectedOrgs = useMemo(
    () => organizations.filter((org) => selectedOrgsIds.includes(org.id)),
    [organizations, selectedOrgsIds],
  )

  const onSave = () => {
    handleOnDocumentFilterChange((prev) => {
      const selectedIds =
        selectedOrgsIds.length > 0
          ? selectedOrgsIds
          : organizations.map(({ id }) => id)
      if (!prev) return { flagCodes: [], orgIds: selectedIds, teamIds: [] }

      const newFilters = { ...prev }
      newFilters.orgIds = selectedIds

      return newFilters
    })
  }

  const onClose = () => {
    setSelectedOrgsIds(includeFilter.orgs ? documentFilters?.orgIds || [] : [])
    overlay.close()
  }

  return (
    <Dialog title={title} {...overlay} onClose={onClose} maxWidth="md">
      <DialogContent>
        {isFetching ? (
          <OrganizationTreeSearchSkeleton />
        ) : (
          <OrganizationsFilterTreeSearch
            defaultOrgsToExpand={defaultOrgsToExpand}
            rootTreeOrgId={project.org_id}
            selectedOrgs={selectedOrgs}
            selectedOrgsIds={selectedOrgsIds}
            setSelectedOrgsIds={setSelectedOrgsIds}
            organizations={organizations}
          />
        )}
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip
          enterDelay={500}
          title={
            selectedOrgsIds.length === organizations.length
              ? 'This is the same as having nothing selected'
              : ''
          }
        >
          <div>
            <Button
              type="submit"
              disabled={
                selectedOrgsIds.length === organizations.length
                  ? true
                  : includeFilter.orgs
                  ? compareIds(documentFilters?.orgIds || [], selectedOrgsIds)
                  : !selectedOrgsIds.length
              }
              onClick={() => {
                overlay.close()
                onSave()
              }}
            >
              Save
            </Button>
          </div>
        </Tooltip>
      </DialogFooter>
    </Dialog>
  )
}
