import { Workflow } from '@/types/workflows'
import { DeveloperBoard } from '@mui/icons-material'
import {
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { useDeferredValue, useMemo, useState } from 'react'

type ChildWorkflowMenuBodyProps = {
  rootWorkflow: Workflow
  workflows: Workflow[]
  onClick: (workflowId: string) => void
}

export default function ChildWorkflowMenuBody({
  rootWorkflow,
  workflows,
  onClick,
}: ChildWorkflowMenuBodyProps) {
  const [searchValue, setSearchValue] = useState('')
  const deferredSearchValue = useDeferredValue(searchValue)

  const filteredWorkflows = useMemo(() => {
    return [rootWorkflow, ...workflows].filter((workflow) =>
      workflow.name.toLowerCase().includes(deferredSearchValue.toLowerCase()),
    )
  }, [deferredSearchValue, rootWorkflow, workflows])

  return (
    <div>
      <TextField
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search"
        fullWidth
        variant="outlined"
        size="small"
        autoFocus
        sx={{ px: 2, pt: 1, pb: 2 }}
      />
      <ListSubheader
        key="menu-subheader-category"
        sx={{ background: 'transparent', lineHeight: '1rem', mb: 1 }}
      >
        Category Training
      </ListSubheader>
      {filteredWorkflows.length === 0 && (
        <Typography sx={{ px: 2, pb: 2 }} variant="caption">
          No Workflows Found
        </Typography>
      )}
      {...filteredWorkflows.map((workflow) => {
        return (
          <MenuItem
            key={workflow.id}
            onClick={() => {
              onClick(workflow.id)
            }}
          >
            <ListItemIcon>
              <DeveloperBoard />
            </ListItemIcon>
            <ListItemText>{workflow.name}</ListItemText>
          </MenuItem>
        )
      })}
    </div>
  )
}
