import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react'
import { styled } from '@mui/material/styles'

export type CheckboxProps = {
  checked?: boolean
  onChange?: (value: boolean) => void
  color: string
  indeterminate?: boolean
}

const StyledCheckbox = styled('input')(({ theme, color = 'primary' }) => {
  let checkboxColor = color
  if (color === 'secondary') {
    checkboxColor =
      theme.palette.mode === 'light'
        ? theme.palette.secondary.light
        : theme.palette.secondary.dark
  } else if (color === 'primary') {
    checkboxColor =
      theme.palette.mode === 'light'
        ? theme.palette.primary.light
        : theme.palette.primary.dark
  }

  return {
    margin: 0,
    marginLeft: -2,
    appearance: 'none',
    position: 'relative',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:before': {
      content: '""',
      width: 24,
      height: 24,
      backgroundImage: `${
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z' fill='"
      }${encodeURIComponent(theme.palette.text.secondary)}'/%3E%3C/svg%3E")`,
      display: 'block',
    },
    '&:checked': {
      position: 'relative',
      border: 'none',
      '&:before': {
        content: '""',
        width: 24,
        height: 24,
        backgroundImage: `${
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z' fill='"
        }${encodeURIComponent(checkboxColor)}'/%3E%3C/svg%3E")`,
        display: 'block',
      },
    },
    '&:indeterminate': {
      position: 'relative',
      border: 'none',
      '&:before': {
        content: '""',
        width: 24,
        height: 24,
        backgroundImage: `${
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
          " d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z' fill='"
        }${encodeURIComponent(checkboxColor)}'/%3E%3C/svg%3E")`,
        display: 'block',
      },
    },
  }
})

const Checkbox = forwardRef(
  (
    { checked, onChange, color, indeterminate = false, ...rest }: CheckboxProps,
    ref,
  ) => {
    const [isChecked, setIsChecked] = useState(false)
    const localRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => localRef.current)

    const change = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange !== undefined) {
        onChange(e.target.checked)
      } else {
        setIsChecked(e.target.checked)
      }
    }

    useEffect(() => {
      checked !== undefined && setIsChecked(checked)
    }, [checked])

    useEffect(() => {
      if (localRef.current) localRef.current.indeterminate = indeterminate
    }, [indeterminate])

    return (
      <StyledCheckbox
        ref={localRef}
        type="checkbox"
        checked={isChecked}
        onChange={(e) => change(e)}
        color={color}
        {...rest}
      />
    )
  },
)

export default Checkbox
