import { ReactNode } from 'react'
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { Chip, Stack } from '@mui/material'
import { DocumentChip } from '@/types/documents'
import { useDocumentImagePageContext } from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'

type FieldChipsProps = {
  chips: DocumentChip[]
  onDelete: (chip: DocumentChip) => void
}

export default function FieldChips({ chips, onDelete }: FieldChipsProps) {
  const { clearDocumentChipField, setHoveredChipId } = useDocumentChipsContext()
  const { imagePage, setImagePage } = useDocumentImagePageContext()

  return (
    <Stack
      direction="row"
      spacing={0.25}
      useFlexGap
      sx={{
        justifyContent: 'flex-end',
        pointerEvents: 'none',
        flexWrap: 'wrap',
        maxWidth: '100%',
      }}
    >
      {chips.map((chip) => {
        const chipIsInCurrentPage = imagePage === chip.document_page_number
        let label: ReactNode = chip.text
        let hoverTimeoutId: ReturnType<typeof setTimeout>
        if (label === 'TrueBox') {
          label = <CheckBoxOutlined fontSize="small" />
        } else if (label === 'FalseBox') {
          label = <CheckBoxOutlineBlankOutlined fontSize="small" />
        }
        return (
          <Chip
            key={chip.id}
            id={chip.id}
            sx={{
              // Re-enables click events, but just on the chips
              pointerEvents: 'initial',
              // Aligns icon
              '.MuiChip-label': {
                display: 'flex',
              },
            }}
            label={label}
            color={chipIsInCurrentPage ? 'primary' : 'neutral'}
            size="small"
            tabIndex={-1}
            clickable={!chipIsInCurrentPage}
            onClick={() =>
              !chipIsInCurrentPage &&
              setImagePage(() => chip.document_page_number)
            }
            onDelete={() => {
              clearDocumentChipField(chip)
              setHoveredChipId(null)
              onDelete(chip)
            }}
            // This prevents losing field focus when changing pages
            onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              e.preventDefault()
            }
            onMouseEnter={
              chipIsInCurrentPage
                ? () => {
                    hoverTimeoutId = setTimeout(() => {
                      setHoveredChipId(chip.id)
                    }, 500)
                  }
                : undefined
            }
            onMouseLeave={
              chipIsInCurrentPage
                ? () => {
                    clearTimeout(hoverTimeoutId)
                    setHoveredChipId(null)
                  }
                : undefined
            }
          />
        )
      })}
    </Stack>
  )
}
