import { MouseEvent, ReactNode } from 'react'
import { ContentCopy } from '@mui/icons-material'
import { Button, ButtonProps, IconButton, Tooltip } from '@mui/material'
import copyToClipboard from '@/components/document-actions-menu/copy-to-clipboard'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { showInfoSnackbar } from '@/utils/snackbars'

type CopyIDButtonProps = {
  stringToCopy: string
  asIconButton?: boolean
  isSuperUser?: boolean
  label?: ReactNode
} & ButtonProps

export default function CopyIDButton({
  stringToCopy,
  asIconButton = false,
  isSuperUser,
  label = 'Copy ID',
  size,
  sx,
  ...props
}: CopyIDButtonProps) {
  function handleClick(e: MouseEvent) {
    e.stopPropagation()
    copyToClipboard(stringToCopy)
    showInfoSnackbar('Copied to Clipboard')
  }
  return asIconButton ? (
    <Tooltip title={label}>
      <IconButton
        data-testid="copy-id-button"
        onClick={handleClick}
        size={size}
        sx={{ color: isSuperUser ? SUPER_USER_ONLY_COLOR : undefined }}
      >
        <ContentCopy fontSize={size === 'small' ? 'small' : undefined} />
      </IconButton>
    </Tooltip>
  ) : (
    <Button
      data-testid="copy-id-button"
      variant="text"
      startIcon={<ContentCopy />}
      color="neutral"
      onClick={handleClick}
      size={size}
      sx={{ ...sx, color: isSuperUser ? SUPER_USER_ONLY_COLOR : undefined }}
      {...props}
    >
      {label}
    </Button>
  )
}
