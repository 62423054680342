import { MenuItem } from '@mui/material'
import { FormSelect } from '../forms'
import { useGetDataListColumns } from '@/service-library/hooks/data-list-columns'
import { useFormContext } from 'react-hook-form'
import { useEffect, useRef } from 'react'

type ListColumnSelectProps = {
  paramName: string
  required?: boolean
  label?: string
  includeNoneOption?: boolean
}

export default function ListColumnSelect({
  paramName,
  required = false,
  label = 'Column',
  includeNoneOption = false,
}: ListColumnSelectProps) {
  const { watch, setValue } = useFormContext()
  const selectedDataListId = watch<string>('data_list_id')

  const { dataListColumns = [], isLoading } = useGetDataListColumns({
    filters: {
      limit: '1000',
      data_list_id: selectedDataListId,
      is_system_managed: 'false',
    },
    enabled: !!selectedDataListId,
  })

  const initialLoadRef = useRef(true)
  useEffect(() => {
    if (initialLoadRef.current) {
      initialLoadRef.current = false
      return
    }
    setValue(paramName, '')
    // Watch for changes to selectedDataListId so we can reset the column name
  }, [setValue, selectedDataListId, paramName])

  return (
    <FormSelect
      name={paramName}
      label={label}
      size="small"
      disabled={!selectedDataListId || dataListColumns.length === 0}
      required={required}
      error={!isLoading && dataListColumns.length === 0}
      helperText={
        !isLoading && dataListColumns.length === 0
          ? 'No columns found. Please select a different list.'
          : undefined
      }
      InputLabelProps={{
        shrink: true,
      }}
    >
      {includeNoneOption && (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      )}
      {dataListColumns.map((dataListColumn) => {
        return (
          <MenuItem key={dataListColumn.id} value={dataListColumn.name}>
            {dataListColumn.name}
          </MenuItem>
        )
      })}
    </FormSelect>
  )
}
