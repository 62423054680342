import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Typography } from '@mui/material'
import { Organization } from '@/types/organizations'
import { OverlayState } from '@/hooks/useOverlay'
import DeleteConfirmDialog from '@/components/delete-confirm-dialog/DeleteConfirmDialog'
import { AxiosError } from 'axios'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { useDeleteOrganization } from '@/service-library/hooks/organizations'
import queryKeys from '@/service-library/query-keys'

type DeleteOrganizationDialogProps = {
  overlay: OverlayState
  organization: Organization
}

const cascadeError =
  'The org you are trying to delete has users, teams, lists, documents and/or other types of objects associated with the org. You cannot delete a non-empty org.'

export default function DeleteOrganizationDialog({
  overlay,
  organization,
}: DeleteOrganizationDialogProps) {
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState('')

  const { deleteOrganization, isLoading: isDeleting } = useDeleteOrganization({
    sideEffectQueryKeys: [queryKeys.organizations.lists()],
    onError: (error) => {
      showErrorSnackbar('Unable to delete organization')
      if (
        (error as AxiosError).response?.data?.['displayable-error'] !==
        cascadeError
      ) {
        overlay.close()
      } else {
        setErrorMsg(
          'This organization cannot be deleted. Please remove any attached data to continue.',
        )
      }
    },
    onSuccess: () => {
      showSuccessSnackbar('Organization Deleted')
      navigate(organization.parent_org_id || '', { replace: true })
    },
    onSettled: () => {
      overlay.close()
    },
  })

  const handleDeleteOrganization = () => {
    deleteOrganization(organization.id)
  }

  useEffect(() => {
    overlay.isOpen && setErrorMsg('')
  }, [overlay.isOpen])

  return (
    <DeleteConfirmDialog
      overlay={overlay}
      title="Delete Organization"
      onDelete={handleDeleteOrganization}
      textToMatch={organization.name}
      hideAlert
      isDeleting={isDeleting}
    >
      {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      <Typography sx={{ mt: 1, mb: 2 }}>
        You can only delete an organization if it doesn&apos;t have any data
        attached to it, such as documents. Please remove all associated items
        before deleting.
      </Typography>
    </DeleteConfirmDialog>
  )
}
