import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Add, Close } from '@mui/icons-material'
import { Button, IconButton, Stack } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { CustomVariable } from '@/types/custom-variables'
import { Organization } from '@/types/organizations'
import { OverlayState } from '@/hooks/useOverlay'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import { useUpdateOrganization } from '@/service-library/hooks/organizations'
import queryKeys from '@/service-library/query-keys'

export type GlobalVariablesDialogProps = {
  overlay: OverlayState
  organizationWithVariables: Organization & {
    custom_variables: CustomVariable[]
  }
}

export type FormValues = { variables: CustomVariable[] }

export default function GlobalVariablesDialog({
  overlay,
  organizationWithVariables,
}: GlobalVariablesDialogProps) {
  const { updateOrganization: updateVariablesInOrg } = useUpdateOrganization({
    sideEffectQueryKeys: [queryKeys.organizations.details()],
    onError: () => {
      showErrorSnackbar('Failed to update variables. Please try again later.')
    },
  })
  const methods = useForm({
    defaultValues: {
      variables: organizationWithVariables.custom_variables,
    },
    mode: 'onChange', // triggers validation onChange
  })

  const {
    control,
    formState: { isValid, isDirty, errors, dirtyFields },
    getValues,
    register,
    reset,
    trigger,
  } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'variables',
  })

  const savedCustomVariablesIds = useMemo(
    () => organizationWithVariables.custom_variables.map(({ id }) => id),
    [organizationWithVariables.custom_variables],
  )

  const appendNewCustomVariable = useCallback(() => {
    append({
      id: generateUuid(),
      name: '',
      org_id: organizationWithVariables.id,
    })
    // Need the timeout so that it triggers validation after first field is added
    setTimeout(() => {
      trigger('variables')
    }, 0)
  }, [append, organizationWithVariables.id, trigger])

  function handleSubmit(values: FormValues) {
    updateVariablesInOrg({
      id: organizationWithVariables.id,
      custom_variables: values.variables,
    } as Organization)
    overlay.close()
  }

  useEffect(() => {
    reset({
      variables: organizationWithVariables.custom_variables,
    })
  }, [organizationWithVariables.custom_variables, overlay.isOpen, reset])

  return (
    <Dialog {...overlay} title="Variables" maxWidth="xs">
      <PixyDocsForm methods={methods} onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={1.5}>
            <Grid container rowSpacing={1} columnSpacing={0.5}>
              {fields.map((field, index) => {
                const variableValueId = getValues(`variables.${index}.id`) // react-hook-form field id is different from the value id https://github.com/orgs/react-hook-form/discussions/8935#discussioncomment-3571382
                const fieldIsNew =
                  !savedCustomVariablesIds.includes(variableValueId)
                const fieldHasError = !!errors.variables?.[index]?.name
                const savedFieldHasChanged =
                  !fieldIsNew && !!dirtyFields?.variables?.[index]?.name
                return (
                  <Fragment key={field.id}>
                    <Grid xs={11}>
                      <FormTextField
                        required
                        variant="outlined"
                        error={fieldHasError}
                        helperText={
                          fieldHasError
                            ? 'Required'
                            : savedFieldHasChanged
                            ? 'Draft'
                            : ''
                        }
                        fullWidth
                        sx={
                          !fieldHasError && savedFieldHasChanged
                            ? {
                                '& fieldset': {
                                  borderColor: (theme) =>
                                    `${theme.palette.warning.main} !important`,
                                },
                                '& label, & p': {
                                  color: (theme) =>
                                    `${theme.palette.warning.main}`,
                                },
                              }
                            : undefined
                        }
                        {...register(`variables.${index}.name`, {
                          validate: (value) => value.trim().length > 0,
                        })}
                      />
                    </Grid>
                    <Grid xs={1}>
                      <IconButton onClick={() => remove(index)}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </Fragment>
                )
              })}
            </Grid>
            <Button
              onClick={() => {
                appendNewCustomVariable()
              }}
              startIcon={<Add />}
              variant="text"
              sx={{
                width: 'fit-content',
              }}
            >
              Add Variable
            </Button>
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
