import { Typography, Button, Box, ListItemText, Stack } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'

type DeleteWorkflowStateDialogProps = {
  overlay: OverlayState
  onDelete: () => void
  workflowState: WorkflowState
}

export default function DeleteWorkflowStateDialog({
  overlay,
  onDelete,
  workflowState,
}: DeleteWorkflowStateDialogProps) {
  const getThemeColor = useGetThemeColor()

  return (
    <Dialog {...overlay} title="Delete Workflow State" maxWidth="xs">
      <DialogContent>
        <Typography>
          You are about to delete this workflow state. Are you sure?
        </Typography>

        <Stack spacing={0.5} sx={{ pt: 2 }} direction="row" alignItems="center">
          <Box
            sx={{
              background: getThemeColor(workflowState.color),
              width: 4,
              height: 24,
              borderRadius: 4,
            }}
          />
          <Box
            sx={{
              borderRadius: 2,
              py: 0.25,
              px: 1,
            }}
          >
            <ListItemText sx={{ flexGrow: 1 }} primary={workflowState.name} />
          </Box>
        </Stack>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button onClick={onDelete} variant="contained" color="error">
          Delete
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
