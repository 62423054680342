import { Theme, ButtonProps, TextFieldProps } from '@mui/material'

export default function getComponentOverrides(theme: Theme) {
  return {
    MuiButton: {
      defaultProps: {
        size: 'small' as ButtonProps['size'],
        variant: 'contained' as ButtonProps['variant'],
      },
    },
    MuiDialog: {
      defaultProps: { PaperProps: { sx: { backgroundImage: 'none' } } },
    },
    MuiListItemButton: {
      defaultProps: {
        sx: {
          '&.Mui-selected': { backgroundColor: theme.palette.action.selected },
          '&.Mui-selected:hover': {
            backgroundColor: theme.palette.action.selected,
          },
          '&.Mui-focusVisible': { backgroundColor: theme.palette.action.focus },
          ':hover': { backgroundColor: theme.palette.action.hover },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small' as TextFieldProps['size'],
        variant: 'filled' as TextFieldProps['variant'],
      },
    },
  }
}
