import {
  Navigate,
  Routes,
  Route,
  useSearchParams,
  useParams,
} from 'react-router-dom'
import { Suspense, lazy } from 'react'
import { CircularProgress, Container, Stack } from '@mui/material'
import useLocalStorage from '@/hooks/useLocalStorage'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import AppTopBar from '@/components/app-top-bar/AppTopBar'
import ProjectDashboard from '@/components/project-dashboard/ProjectDashboard'
import FieldConfiguration from '@/components/project-settings/FieldConfiguration'
import ListsPage from './ListsPage'
import NotFound from './NotFound'
import OrganizationsPage from './OrganizationsPage'
import ProjectLayout from '@/components/project-dashboard/ProjectLayout'
import ProjectsPage from './ProjectsPage'
import ProjectSettings from '@/components/project-settings/ProjectSettings'
import ProjectSettingsLayout from '@/components/project-settings/ProjectSettingsLayout'
import Source from '@/components/project-settings/Source'
import GlobalSettingsPage from './GlobalSettingsPage'
import TeamsPage from './TeamsPage'
import UsersPage from './UsersPage'
import ProjectIntegrations from '@/components/project-integrations/ProjectIntegrations'
import FeatureFlagsPage from './FeatureFlagsPage'
import ProjectWizardPage from '@/components/project-wizard/ProjectWizardPage'
import BreadcrumbPortalProvider from '@/components/breadcrumbs/BreadcrumbPortalProvider'
import NavigationRailPortalProvider from '@/components/navigation-rail/NavigationRailPortalProvider'
import DrawerPortalProvider from '@/components/drawer/DrawerPortalProvider'
import AppLayout from '@/components/app-layout/AppLayout'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import ErrorBoundary from '@/components/error-boundary/ErrorBoundary'
import RootOrganizationProvider from '@/components/organizations/RootOrganizationProvider'
import TeamsTable from '@/components/teams/TeamsTable'
import TeamPage from '@/components/teams/TeamPage'
import DataListList from '@/components/data-lists/DataListList'
import DataListPage from '@/components/data-lists/DataListPage'
import DocumentsProcessed from '@/components/data-visualization/DocumentsProcessed'
import RecentOrganizationsProvider from '@/components/organizations/RecentOrganizationsProvider'
import IntegrationMappingEditor from '@/components/project-integrations/IntegrationMappingEditor'
import UsersTable from '@/components/users/UsersTable'
import UserPage from '@/components/users/UserPage'
import AgreementsPage from './AgreementsPage'
import OpenSourcePage from './OpenSourcePage'
import MetricsPage from './MetricsPage'
import ProfilePage from './ProfilePage'
import TermsOfUsePage from './TermsOfUsePage'
import VerifyEmailPage from './VerifyEmailPage'
import CredentialsPage from '@/components/credentials-page/CredentialsPage'
import CredentialAuthenticatedPage from '@/components/credentials-page/CredentialAuthenticatedPage'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import ProjectDashboardRouting from '@/components/project-dashboard/ProjectDashboardRouting'
import TopBarPortalProvider from '@/components/top-bar-portal/TopBarPortalProvider'
import WorkflowStatesPage from '@/components/workflow-states-page/WorkflowStatesPage'
import WorkflowStatePage from '@/components/workflow-state-page/WorkflowStatePage'
import ModelSettings from '@/components/models-page/ModelConfiguration'
import ModelDashboard from '@/components/models-page/ModelDashboard'
import DocumentRulesPage from '@/components//project-settings/document-rules/DocumentRulesPage'
import FieldRulesPage from '@/components/project-settings/field-rules/FieldRulesPage'
import TotalDocumentsPage from '@/components/total-documents-page/TotalDocumentsPage'
import WorkflowSettings from '@/components/project-settings/WorkflowSettings'
import WorkflowStateEventsPage from '@/components/workflow-state-events-page/WorkflowStateEventsPage'
import CustomVariablesPage from './CustomVariablesPage'
import OrganizationTypesPage from './OrganizationTypesPage'
import PreferencesPage from './PreferencesPage'

const AdvancedSettings = lazy(
  () =>
    import('@/components/project-settings/advanced-settings/AdvancedSettings'),
)
const ZerapixManagementPage = lazy(
  () => import('@/components/zerapix-management/ZerapixManagementPage'),
)
const CustomersPage = lazy(
  () => import('@/components/zerapix-management/CustomersPage'),
)
const CustomerPage = lazy(
  () => import('@/components/zerapix-management/CustomerPage'),
)

function OldValidationRedirect() {
  const { projectId, documentId } = useParams()
  return (
    <Navigate
      to={`/projects/${projectId}/documents?document_id=${documentId}`}
      replace
    />
  )
}

export default function Application() {
  const featureFlags = useFeatureFlagContext()
  const [demoMode] = useDemoModeContext()
  const isSuperUser = useIsSuperUser()
  const [projectPreferredTab] = useLocalStorage(
    'project-preferred-tab',
    'documents',
  )

  const [searchParams] = useSearchParams()

  const boardId =
    searchParams.get('boardId') || '018a903d-a8a3-7a92-6110-ba6b0a96970e'

  return (
    <ErrorBoundary>
      <RootOrganizationProvider>
        <NavigationRailPortalProvider>
          <DrawerPortalProvider>
            <TopBarPortalProvider>
              <BreadcrumbPortalProvider>
                <RecentOrganizationsProvider>
                  <AppLayout header={<AppTopBar />}>
                    <Suspense
                      fallback={
                        <Stack alignItems="center" sx={{ p: 4 }}>
                          <CircularProgress />
                        </Stack>
                      }
                    >
                      <Routes>
                        <Route path="projects" element={<ProjectsPage />} />

                        <Route
                          path="project-setup/*"
                          element={<ProjectWizardPage />}
                        />

                        <Route
                          path="projects/:projectId"
                          element={<ProjectLayout />}
                        >
                          {/* If no sub-view is given, send them to "projectPreferredTab" */}
                          <Route
                            index
                            element={
                              <Navigate to={projectPreferredTab} replace />
                            }
                          />

                          <Route
                            path="dashboard"
                            element={<ModelDashboard />}
                          />

                          <Route
                            path="validation/:documentId"
                            element={<OldValidationRedirect />}
                          />

                          <Route
                            path=":view"
                            element={<ProjectDashboardRouting />}
                          >
                            <Route index element={<ProjectDashboard />} />
                          </Route>

                          <Route
                            path="settings"
                            element={<ProjectSettingsLayout />}
                          >
                            <Route
                              index
                              element={<Navigate to="general" replace />}
                            />
                            <Route
                              path="general"
                              element={<ProjectSettings />}
                            />
                            <Route
                              path="workflow-states/:id"
                              element={<WorkflowStatePage />}
                            />
                            <Route
                              path="workflow-states/:id/events"
                              element={<WorkflowStateEventsPage />}
                            />

                            <Route path="sources" element={<Source />} />
                            <Route
                              path="fields"
                              element={<FieldConfiguration />}
                            />
                            <Route
                              path="integrations"
                              element={<ProjectIntegrations />}
                            />
                            {!demoMode && isSuperUser && (
                              <Route
                                path="advanced-settings"
                                element={<AdvancedSettings />}
                              />
                            )}

                            {featureFlags.quality_control_ui && (
                              <>
                                <Route
                                  path="document-rules"
                                  element={<DocumentRulesPage />}
                                />
                                <Route
                                  path="field-rules"
                                  element={<FieldRulesPage />}
                                />
                              </>
                            )}

                            <Route
                              path="workflow-general"
                              element={<WorkflowSettings />}
                            />
                            <Route
                              path="workflow-states"
                              element={<WorkflowStatesPage />}
                            />
                            <Route
                              path="model-configuration"
                              element={<ModelSettings />}
                            />
                          </Route>

                          <Route
                            path="integrations/:integrationId/mapping"
                            element={
                              <IntegrationMappingEditor boardId={boardId} />
                            }
                          />
                          <Route path="*" element={<NotFound />} />
                        </Route>

                        <Route path="settings" element={<GlobalSettingsPage />}>
                          <Route
                            index
                            element={<Navigate to="my-profile" replace />}
                          />

                          <Route path="my-profile" element={<ProfilePage />} />
                          <Route
                            path="my-preferences"
                            element={<PreferencesPage />}
                          />

                          {featureFlags.teams && (
                            <Route path="teams" element={<TeamsPage />}>
                              <Route index element={<TeamsTable />} />
                              <Route path=":id" element={<TeamPage />} />
                            </Route>
                          )}

                          <Route path="users" element={<UsersPage />}>
                            <Route index element={<UsersTable />} />
                            <Route path=":id" element={<UserPage />} />
                          </Route>

                          <Route
                            path="organizations/*"
                            element={<OrganizationsPage />}
                          />

                          <Route
                            path="organization-types"
                            element={<OrganizationTypesPage />}
                          />

                          {featureFlags.org_custom_variables && (
                            <Route
                              path="custom-variables/*"
                              element={<CustomVariablesPage />}
                            />
                          )}

                          <Route path="lists" element={<ListsPage />}>
                            <Route index element={<DataListList />} />
                            <Route path=":id" element={<DataListPage />} />
                          </Route>

                          {featureFlags.customer_page_visible && (
                            <Route
                              path="customer/:id"
                              element={<CustomerPage />}
                            />
                          )}

                          <Route
                            path="credentials"
                            element={<CredentialsPage />}
                          />
                        </Route>

                        <Route
                          path="credential-authenticated"
                          element={<CredentialAuthenticatedPage />}
                        />

                        <Route path="agreements" element={<AgreementsPage />} />
                        <Route
                          path="open-source-licenses"
                          element={<OpenSourcePage />}
                        />
                        <Route
                          path="terms-of-use"
                          element={<TermsOfUsePage />}
                        />

                        <Route
                          path="verify-email"
                          element={<VerifyEmailPage />}
                        />

                        <Route path="metrics" element={<MetricsPage />}>
                          <Route index element={<Navigate to="documents" />} />
                          <Route
                            path="documents"
                            element={
                              <Container sx={{ py: 4 }}>
                                <DocumentsProcessed />
                              </Container>
                            }
                          />
                        </Route>

                        {isSuperUser && (
                          <Route
                            path="zerapix-management"
                            element={<ZerapixManagementPage />}
                          >
                            <Route
                              index
                              element={<Navigate to="customers" />}
                            />
                            <Route
                              path="customers"
                              element={<CustomersPage />}
                            />
                            <Route
                              path="customers/:id"
                              element={<CustomerPage showBackButton />}
                            />
                            <Route
                              path="feature-flags"
                              element={<FeatureFlagsPage />}
                            />
                          </Route>
                        )}

                        {isSuperUser && (
                          <Route
                            path="total-documents"
                            element={<TotalDocumentsPage />}
                          />
                        )}

                        <Route
                          path="/"
                          element={<Navigate to="projects" replace />}
                        />

                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </Suspense>
                  </AppLayout>
                </RecentOrganizationsProvider>
              </BreadcrumbPortalProvider>
            </TopBarPortalProvider>
          </DrawerPortalProvider>
        </NavigationRailPortalProvider>
      </RootOrganizationProvider>
    </ErrorBoundary>
  )
}
