import { useEffect, useRef, useState } from 'react'
import {
  AccountTree,
  Add,
  ArrowDropDown,
  ArrowForwardIosSharp,
  Description,
  DeveloperBoard,
} from '@mui/icons-material'
import {
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import AddEditWorkflowDialog from '@/components/project-settings/AddEditWorkflowDialog'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import { Workflow } from '@/types/workflows'
import ChildWorkflowMenuBody from './ChildWorkflowMenuBody'

export default function WorkflowSelector() {
  const menuOverlay = useOverlay()
  const addOverlay = useOverlay()

  const [rootWorkflow, setRootWorkflow] = useState<Workflow | null>(null)

  const anchorRef = useRef<HTMLDivElement>(null)

  const { getWorkflowsByModelType, getChildWorkflows } = useWorkflowsContext()
  const { selectedWorkflow, setSelectedWorkflow } = useSelectedWorkflowContext()

  const {
    productionWorkflows,
    labelingModelWorkflows,
    categoryModelWorkflows,
  } = getWorkflowsByModelType()

  const modelWorkflowsLength =
    labelingModelWorkflows.length + categoryModelWorkflows.length

  function handleWorkflowChange(workflowId: string) {
    setSelectedWorkflow(workflowId)
    menuOverlay.close()
  }

  useEffect(() => {
    if (menuOverlay.isOpen) {
      setRootWorkflow(null)
    }
  }, [menuOverlay.isOpen])

  return (
    <>
      <ListItemButton
        onClick={menuOverlay.open}
        ref={anchorRef}
        sx={{
          width: 'max-content',
          borderRadius: 2,
          flexGrow: 0,
          flexShrink: 0,
          py: 0.5,
          px: 1,
          textDecoration: 'none',
          '&:visited': {
            textDecoration: 'none',
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <ArrowDropDown fontSize="small" />
          <Typography variant="body1" color="text.secondary">
            {selectedWorkflow.name}
          </Typography>
        </Stack>
      </ListItemButton>

      <Menu
        open={menuOverlay.isOpen}
        anchorEl={anchorRef.current}
        onClose={menuOverlay.close}
        sx={{
          '.MuiDivider-root': {
            my: 1, // Default margin when using it after menu items, but since we have a button, we need to specify the margin
          },
        }}
      >
        {!rootWorkflow && (
          <div>
            {modelWorkflowsLength > 0 && (
              <ListSubheader
                sx={{
                  background: 'transparent',
                  lineHeight: '1rem',
                  mb: 1,
                  mt: 0.5,
                }}
              >
                Document Workflows
              </ListSubheader>
            )}
            {productionWorkflows.map((workflow) => (
              <MenuItem
                key={workflow.id}
                onClick={() => {
                  handleWorkflowChange(workflow.id)
                }}
              >
                <ListItemIcon>
                  <Description />
                </ListItemIcon>
                <ListItemText>{workflow.name}</ListItemText>
              </MenuItem>
            ))}
            <Button
              key="menu-button-add"
              onClick={() => {
                menuOverlay.close()
                addOverlay.open()
              }}
              variant="text"
              startIcon={<Add />}
              sx={{ ml: 1.5, mt: 0.5 }}
            >
              New Workflow
            </Button>
            {labelingModelWorkflows.length > 0 && [
              <Divider key="menu-div-labeling" />,
              <ListSubheader
                key="menu-subheader-labeling"
                sx={{ background: 'transparent', lineHeight: '1rem', mb: 1 }}
              >
                Labeling Training
              </ListSubheader>,
            ]}
            {labelingModelWorkflows.map((workflow) => (
              <MenuItem
                key={workflow.id}
                onClick={() => {
                  handleWorkflowChange(workflow.id)
                }}
              >
                <ListItemIcon>
                  <DeveloperBoard />
                </ListItemIcon>
                <ListItemText>{workflow.name}</ListItemText>
              </MenuItem>
            ))}
            {categoryModelWorkflows.length > 0 && [
              <Divider key="menu-div-category" />,
              <ListSubheader
                key="menu-subheader-category"
                sx={{ background: 'transparent', lineHeight: '1rem', mb: 1 }}
              >
                Category Training
              </ListSubheader>,
            ]}
            {categoryModelWorkflows.map((workflow) => {
              const workflowsForModel = getChildWorkflows(workflow)
              const hasChildren = workflowsForModel.length > 0
              return [
                <MenuItem
                  key={workflow.id}
                  onClick={() => {
                    if (!hasChildren) {
                      handleWorkflowChange(workflow.id)
                    } else {
                      setRootWorkflow(workflow)
                    }
                  }}
                >
                  <ListItemIcon>
                    {hasChildren ? <AccountTree /> : <DeveloperBoard />}
                  </ListItemIcon>
                  <ListItemText>{workflow.name}</ListItemText>
                  {hasChildren && (
                    <ArrowForwardIosSharp
                      sx={{
                        ml: 4,
                        fontSize: 12,
                      }}
                    />
                  )}
                </MenuItem>,
              ]
            })}
          </div>
        )}

        {rootWorkflow && (
          <ChildWorkflowMenuBody
            rootWorkflow={rootWorkflow}
            onClick={handleWorkflowChange}
            workflows={getChildWorkflows(rootWorkflow)}
          />
        )}
      </Menu>

      <AddEditWorkflowDialog overlay={addOverlay} />
    </>
  )
}
