import {
  QueryKey,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import useMutation from './useMutation'
import { LogixBoard, LogixNodeUpdate } from '@/types/logix'
import { updateLogixNode } from '../logix'
import { getQueryKey } from './useLogixBoard'

type UseUpdateLogixNodeOptions = UseMutationOptions<
  LogixNodeUpdate,
  unknown,
  LogixNodeUpdate,
  { previous: LogixBoard }
>

export default function useUpdateLogixNode(
  options: UseUpdateLogixNodeOptions = {},
) {
  const queryClient = useQueryClient()

  function updateLogixNodesInCache(
    queryKey: QueryKey,
    updatedNodes: LogixNodeUpdate[],
  ) {
    const updatedNodeIds = updatedNodes.map((node) => node.id)

    queryClient.cancelQueries({ queryKey })
    queryClient.setQueryData(queryKey, (currentBoard?: LogixBoard) => {
      if (!currentBoard) return
      const currentNodes = currentBoard.nodes

      const newNodesList = currentNodes.map((node) => {
        if (updatedNodeIds.includes(node.id)) {
          return {
            ...node,
            ...updatedNodes.find((updatedNode) => updatedNode.id === node.id),
          }
        }
        return node
      })

      return {
        ...currentBoard,
        nodes: newNodesList,
      } as LogixBoard
    })
  }

  const query = useMutation<
    LogixNodeUpdate,
    unknown,
    LogixNodeUpdate,
    { previous: LogixBoard }
  >({
    sideEffectQueryKeys: [['logix-board']],
    mutationFn: (logixNodeUpdate: LogixNodeUpdate) =>
      updateLogixNode(logixNodeUpdate).then((res) => res.data),
    onMutate: async (updatedNode) => {
      const queryKey = getQueryKey(updatedNode.board_id)

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey) as LogixBoard
      updateLogixNodesInCache(queryKey, [updatedNode])

      return { previous }
    },
    onError: (err, data, context) => {
      const queryKey = getQueryKey(data.board_id)
      queryClient.setQueryData(queryKey, context?.previous)
    },
    onSettled: (data) => {
      const queryKey = getQueryKey(data?.board_id || '')
      queryClient.invalidateQueries({ queryKey })
    },
    ...options,
  })

  return { ...query, updateLogixNode: query.mutate, updateLogixNodesInCache }
}
