import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import useMutation from './useMutation'
import { LogixEdge } from '@/types/logix'
import { createLogixEdge } from '../logix'
import { getQueryKey } from './useLogixEdges'

type UseCreateLogixEdgeOptions = UseMutationOptions<
  LogixEdge,
  unknown,
  LogixEdge,
  { previous: LogixEdge[] }
> & { boardId: string }

export default function useCreateLogixEdge(
  { boardId, ...options }: UseCreateLogixEdgeOptions = { boardId: '' },
) {
  const queryClient = useQueryClient()

  const edgesQueryKey = getQueryKey(boardId)

  const query = useMutation<
    LogixEdge,
    unknown,
    LogixEdge,
    { previous: LogixEdge[] }
  >({
    sideEffectQueryKeys: [['logix-board'], ['logix-edges']],
    mutationFn: (newLogixEdge: LogixEdge) =>
      createLogixEdge(newLogixEdge).then((res) => res.data),
    onMutate: async (newEdge) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: edgesQueryKey })

      const previous = queryClient.getQueryData(edgesQueryKey) as LogixEdge[]
      queryClient.setQueryData(
        edgesQueryKey,
        (currentEdges: LogixEdge[] = []) => [...currentEdges, newEdge],
      )

      return { previous }
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(edgesQueryKey, context?.previous)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: edgesQueryKey })
    },
    ...options,
  })

  return { ...query, createLogixEdge: query.mutate }
}
