import { Link } from 'react-router-dom'
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { useSignOut } from '@/hooks/auth'
import useOverlay from '@/hooks/useOverlay'
import useLocalStorage from '@/hooks/useLocalStorage'
import { DarkMode, LightMode, Logout, Person, Tune } from '@mui/icons-material'

export default function ProfileMenu() {
  const signOut = useSignOut()
  const overlay = useOverlay()

  const [themeMode, setThemeMode] = useLocalStorage(
    'pixydocs-theme-mode',
    'dark',
  )

  const handleLogout = () => {
    overlay.close()
    signOut()
  }

  function handleSwitchTheme() {
    setThemeMode(themeMode === 'dark' ? 'light' : 'dark')
    overlay.close()
  }

  return (
    <div>
      <IconButton onClick={overlay.toggle} size="small">
        <PersonIcon />
      </IconButton>

      <Menu
        id="profile-menu"
        anchorEl={overlay.anchorEl as Element}
        open={overlay.isOpen}
        onClose={overlay.close}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          component={Link}
          onClick={() => {
            overlay.close()
          }}
          to="/settings/my-profile"
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText>My Profile</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={() => {
            overlay.close()
          }}
          to="/settings/my-preferences"
        >
          <ListItemIcon>
            <Tune />
          </ListItemIcon>
          <ListItemText>My Preferences</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleSwitchTheme}>
          <ListItemIcon>
            {themeMode === 'light' ? <DarkMode /> : <LightMode />}
          </ListItemIcon>
          <ListItemText>
            {themeMode === 'light' ? 'Dark' : 'Light'} Theme
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>Sign Out</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}
