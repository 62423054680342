import { GroupOption } from '@/types/metrics'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import { getList } from '../request-wrappers'
import {
  formatDate,
  getConvertedDatesByGroup,
  getIsEnabled,
} from '@/utils/date-metrics'
import { DocumentMetricsCountData } from '@/types/documents'

/**
 * MARK: Action Hook: Get Billing Event Metrics
 * Get billing event metrics divided by project model version
 */
export function useGetBillingEventMetrics({
  startDate,
  endDate,
  groupedBy,
  tz = Intl.DateTimeFormat().resolvedOptions().timeZone,
  enabled = true,
  ...options
}: UseGetListOptions<DocumentMetricsCountData> & {
  startDate: Date
  endDate: Date
  groupedBy: GroupOption
  tz?: string
}) {
  const { startDate: newStartDate, endDate: newEndDate } =
    getConvertedDatesByGroup({ startDate, endDate, groupedBy })

  const mainFilters = {
    start_date: formatDate(newStartDate as Date),
    end_date: formatDate(newEndDate as Date),
    grouped_by: groupedBy,
    tz,
  }
  const query = useGetList<DocumentMetricsCountData>({
    queryKey: queryKeys.billingEvents.list({
      filters: { ...mainFilters, ...options.filters },
    }),
    serviceFn: ({ filters, axiosOptions }) => {
      return getList<DocumentMetricsCountData>({
        url: '/v2/pd/billing_events/prediction_metrics',
        filters: { ...mainFilters, ...filters },
        axiosOptions,
      })
    },
    enabled: enabled && getIsEnabled(startDate, endDate, groupedBy),
    ...options,
  })
  return {
    billingEventMetrics: query.data?.pages[0],
    ...query,
  }
}
