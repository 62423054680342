import { UseQueryOptions } from '@tanstack/react-query'
import useQuery from './useQuery'
import { LogixNodeTypeSection } from '@/types/logix'
import { getLogixNodeTypeSections } from '../logix'

type UseLogixNodeTypeSectionsOptions = UseQueryOptions<LogixNodeTypeSection[]>

export default function useLogixNodeTypeSections(
  options: UseLogixNodeTypeSectionsOptions = {},
) {
  const queryKey = ['logix-node-type-sections']

  const query = useQuery({
    queryKey,
    queryFn: () => {
      return getLogixNodeTypeSections().then((res) => res.data.results)
    },
    ...options,
  })

  return {
    sections: query.data,
    ...query,
  }
}
