import { Container, Stack, Typography } from '@mui/material'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import PageTitle from '@/components/PageTitle'
import { NestedList } from './AgreementsPage'

export default function TermsOfUsePage() {
  return (
    <Container maxWidth="md" sx={{ my: 5, textAlign: 'justify' }}>
      <PageTitle>Terms of Use</PageTitle>
      <Breadcrumb label="Terms of Use" url="/terms-of-use" />

      <Typography align="center" component="h1" variant="h5">
        ZERAPIX, LLC TERMS OF USE
      </Typography>
      <Typography variant="body2" component="p">
        Updated: December 12, 2022
      </Typography>
      <Typography component="div">
        <p>
          THIS IS A BINDING LEGAL CONTRACT BETWEEN ZERAPIX, LLC, AN IDAHO
          LIMITED LIABILITY COMPANY (“<b>COMPANY</b>”) AND YOU, INDIVIDUALLY,
          AND ON BEHALF OF YOUR EMPLOYER (COLLECTIVELY, THE “<b>USER</b>”).
          USERS SHOULD CAREFULLY READ THESE TERMS OF USE (“<b>TERMS OF USE</b>
          ”) BEFORE ACCESSING AND/OR USING ANY OF THE COMPANY&#39;S WEBSITES,
          AND THE COMPANY&#39;S OTHER APPLICATIONS AND PLATFORMS, INCLUDING ANY
          MOBILE APPLICATIONS (COLLECTIVELY, THE “<b>SITES</b>”), OR (2) ANY
          PRODUCTS OR SERVICES PROVIDED BY OR THROUGH THE SITES (COLLECTIVELY,
          THE “<b>SERVICES</b>”). BY ACCESSING OR USING THE SITES OR SERVICES,
          THE USER AGREES TO BE BOUND BY THESE TERMS OF USE, AND BE LIABLE TO
          COMPANY FOR ANY NONCOMPLIANCE WITH THESE TERMS OF USE. IF THE USER
          DOES NOT AGREE TO THESE TERMS OF USE, AS THESE TERMS OF USE MAY BE
          MODIFIED FROM TIME TO TIME, THE USER MAY NOT USE THE SITES OR
          SERVICES.
        </p>
        <p>
          These Terms of Use apply to and are incorporated by reference into
          that, as applicable, Master Software as a Service Agreement made by
          and between the Company and the User (or User&#39;s employer, or other
          organization or entity that User is an agent, consultant or contractor
          of, as applicable) (together with any exhibits and attachments thereto
          and any related documentation between the parties, collectively, the “
          <b>Service Agreement</b>”). To the extent of any conflict or
          inconsistency between these Terms of Use and the applicable Service
          Agreement, the terms of the Terms of Use shall prevail.
        </p>
        <Stack component="ol" spacing={1.5} sx={{ pl: '18px' }}>
          <li>
            <u>Right to Use</u>. Subject to all limitations and restrictions
            contained herein and the applicable Service Agreement, User will
            have a limited, revocable, non-transferable, and non-exclusive right
            to use the Sites and Services solely for its internal business
            purposes and to perform the functions described in the applicable
            Service Agreement. User shall not allow any person who is not an
            Authorized User (defined in Section 6) to frame, syndicate,
            distribute, replicate, or copy any portion of the Sites or Services.
            Unless otherwise expressly permitted in the applicable Service
            Agreement and not including Authorized Users, User shall not permit
            any subsidiaries, affiliated companies, or third parties to access
            or use the Sites or Services.
          </li>
          <li>
            <u>Use Restrictions</u>. User shall not, and shall not permit any
            other person or entity to, access or use the Sites or Services
            except as expressly permitted by these Terms of Use or the
            applicable Service Agreement. For purposes of clarity and without
            limiting the generality of the foregoing, User shall not, except as
            these Terms of Use expressly permit: (a) copy, modify, or create
            derivative works or improvements of the Sites, Services or
            Intellectual Property (as defined in Section 12(b)); (b) rent,
            lease, lend, sell, sublicense, assign, distribute, publish,
            transfer, or otherwise make available any Sites, Services or
            Intellectual Property to any person, including on or in connection
            with the internet or any time-sharing, service bureau, software as a
            service, cloud, or other technology or service; (c) reverse
            engineer, disassemble, decompile, decode, adapt, or otherwise
            attempt to derive or gain access to the source code of the Sites,
            Services or Intellectual Property, in whole or in part; (d) bypass
            or breach any security device or protection used by the Sites,
            Services or Intellectual Property or access or use the Sites,
            Services or Intellectual Property other than through the use of his
            or her own then valid access credentials; (e) input, upload,
            transmit, or otherwise provide to or through the Sites or Services,
            any information or materials that are unlawful or injurious, or
            contain, transmit, or activate any harmful or malicious code; (f)
            damage, destroy, disrupt, disable, impair, interfere with, or
            otherwise impede or harm in any manner the Sites, Services,
            Intellectual Property, or Company&apos;s provision of services to
            any third party, in whole or in part; (g) remove, delete, alter, or
            obscure any trademarks, warranties, or disclaimers, or any
            copyright, trademark, patent, or other intellectual property or
            proprietary rights notices from any Sites, Services or Intellectual
            Property, including any copy thereof; (h) access or use the Sites,
            Services or Intellectual Property in any manner or for any purpose
            that infringes, misappropriates, or otherwise violates any right,
            title, or interest of the Company or other right of any third party
            (including by any unauthorized access to, misappropriation, use,
            alteration, destruction, or disclosure of the data of any other
            customer of the Company), or that violates any applicable law; (j)
            access or use the Sites or Services for purposes of competitive
            analysis of the Sites or Services, the development, provision, or
            use of a competing software service or product or any other purpose
            that is to the Company&apos;s detriment or commercial disadvantage;
            (k) access or use the Sites or Services to store or transmit
            infringing, libelous, or otherwise unlawful or tortious material, or
            (l) otherwise access or use the Sites or Services beyond the scope
            of the authorization granted under these Terms of Use.
          </li>
          <li>
            <u>License to User Data</u>. User hereby grants to Company a
            royalty-free, perpetual, worldwide, and non-exclusive license to
            use, upload, display, copy and store all User Data (defined below).
            <NestedList>
              <li>
                “User Data” means information, data, and other content, in any
                form or medium that is collected, downloaded, or otherwise
                received, directly or indirectly from User or an Authorized User
                by or through the Sites or Services. For the avoidance of doubt,
                Customer Data does not include any Content (defined below)
                submitted by User or an Authorized User to the Company, Sites or
                Services, including: (i) data and information related to User or
                Authorized User&apos;s use of the Sites and/or Services used by
                Company, including to compile statistical and performance
                information related to the provision and operation of the Sites
                and Services; and (ii) any information reflecting the access or
                use of the Sites and/or Services by or on behalf of User or any
                Authorized User.
              </li>
            </NestedList>
          </li>
          <li>
            <u>Submitted Content</u>. The Sites may contain message boards, chat
            rooms, personal web pages or profiles, forums, bulletin boards or
            other interactive features that may allow User to submit, post,
            display, publish or transmit comments, feedback, background
            information, messages, photographs, videos, suggestions, questions,
            reviews and any other related content through the Sites and/or the
            Services (the “Content”). By submitting the Content, User represents
            and warrants that: (x) User is the sole author and owner of the
            Content; (y) User is at least 18 years of age; and (z) the Content
            supplied by User or an Authorized User will not violate these Terms
            of Use, or any applicable law and will not cause any injury to any
            person or entity.
            <NestedList>
              <li>
                User also represents and warrant that the Content is NOT, as
                determined by the Company in its sole discretion, any of the
                following: false, inaccurate, misleading, a violation of any
                local, state, federal, international or other applicable law, or
                otherwise obscene, derogatory, defamatory, threatening,
                harassing, abusive, slanderous, hateful or embarrassing to any
                other person or entity, including as such relates to the
                harassment, degradation, intimidation, or victimization of an
                individual or group of individuals on the basis of religion,
                gender, sexual orientation, race, ethnicity, age or disability;
                an infringement on the rights of others, such as infringements
                on any patent, copyright, trademarks, trade secret, publicity or
                privacy rights; an advertisement, solicitation or spam link to
                other websites or individuals, except if such an advertisement
                or solicitation has been expressly consented to in writing by
                the Company; a chain letter or pyramid scheme, or part of a
                chain letter or pyramid scheme; an impersonation of, and does
                not purport to impersonate, another business, person, or entity,
                including the Company and its employees and agents; or a virus
                or other harmful computer code, and does not contain a virus or
                other harmful code.
              </li>
              <li>
                User expressly agrees that all Content submitted to the Sites
                will not be considered confidential or proprietary and User
                automatically grants and/or warrants the Company a royalty-free,
                perpetual, irrevocable, worldwide, unlimited, and non-exclusive
                license to use, reproduce, create derivative works from, modify,
                publish, edit, translate distribute, perform and display the
                submission and/or the Content in any media or medium, or any
                form, format or forum whether now known or hereafter developed.
              </li>
              <li>
                User further understands and acknowledges that Company has the
                right, but not the obligation, to monitor all Content and any
                submission made to or on the Sites or the Services. Company has
                the right, in its sole discretion and for any reason, to edit,
                delete, move, or to refuse to post any Content or any other
                submission to the Sites or the Services. NOTWITHSTANDING THE
                FOREGOING, USER IS SOLELY RESPONSIBLE FOR ANY CONTENT AND/OR ANY
                OTHER SUBMISSION USER OR AN AUTHORIZED USER MAKES TO OR ON THE
                SITES OR THE SERVICES, AND USER AGREES TO INDEMNIFY THE COMPANY
                FOR ALL CLAIMS RELATED TO OR ARISING FROM SUCH CONTENT AND/OR
                SUBMISSIONS.
              </li>
            </NestedList>
          </li>
          <li>
            <u>Eligibility</u>. The Services are solely for access and use by
            Users and Authorized Users (as defined in Section 6). By accessing
            or using the Services or Sites, User represents and warrants that
            User (a) has the right, authority, and capacity to enter into Terms
            of Use and (b) will abide by all of the terms and conditions of
            these Terms of Use. User hereby expressly acknowledges and agrees
            that User&apos;s eligibility to access and use the Sites and
            Services shall be contingent on User&apos;s continued compliance
            with the terms of the applicable Service Agreement and these Terms
            of Use.
          </li>
          <li>
            <u>Passwords</u>. With respect to Users who are parties to a Service
            Agreement (or such agreement for the piloting of the Sites and/or
            Services), Company will authorize individual employees, consultants,
            contractors and agents of such Users as designated by such Users
            from time to time pursuant to the applicable Service Agreement (each
            an “<b>Authorized User</b>”). Each Authorized User will have access
            to the Services. Users will also be asked to choose a username and a
            password for the User and, as applicable, each Authorized User.
            Company may change any username and password for any User or
            Authorized User at any time, with notice to the User following any
            such change. Each User or Authorized User must use his or her own
            username and password and shall not disclose them to anyone else.
            User shall immediately notify Company of any unauthorized
            disclosure. User is responsible for (a) each Authorized User&apos;s
            compliance with these Terms of Use and (b) any employee of User, any
            person to whom User has given access to the Services, and any person
            who gains access to the Sites or Services as a result of User&apos;s
            failure to use reasonable security precautions, even if such use was
            not authorized by User.
          </li>
          <li>
            <u>Third Party Software</u>. The Sites and Services may contain
            third party software that requires notices and/or additional terms
            and conditions. Such required third party software notices and/or
            additional terms and conditions may be requested from Company and
            are made a part of and incorporated by reference into these Terms of
            Use. By accepting these Terms of Use, User is also accepting the
            additional terms and conditions, if any, set forth therein.
          </li>
          <li>
            <u>Links to Other Websites & Third Party Content</u>. The Sites may
            contain links to or be linked from other websites and resources
            located on servers maintained by third parties over which Company
            has no control (“<b>Linked Websites</b>”). The Linked Websites are
            provided for User&apos;s convenience and information only and, as
            such, User accesses them at its own risk. User agrees and
            acknowledges that the Company is not responsible for, and does not
            endorse or warrant, the content of or anything that may be delivered
            to User or User&apos;s computer as a result of accessing any Linked
            Websites, whether or not the Company is affiliated with the owners
            of such Linked Websites. WITHOUT LIMITING THE GENERALITY OF THE
            FOREGOING, USER SHALL WAIVE ANY CLAIMS RELATED TO, AND THE COMPANY
            IS NOT RESPONSIBLE AND SHALL HAVE NO LIABILITY FOR, USER&apos;S
            ACCESS OF ANY INFORMATION ON OR USE OF THE LINKED WEBSITES,
            INCLUDING BUT NOT LIMITED TO, ANY VIRUSES OR OTHER ILLICIT CODE THAT
            MAY BE DOWNLOADED THROUGH A LINKED WEBSITE, OR BY ACCESSING A LINKED
            WEBSITE.
            <br />
            The information presented on or through the Sites is made available
            solely for general information purposes and the Company does not
            warrant the accuracy, completeness, or usefulness of this
            information. Any reliance User places on such information is
            strictly at User&apos;s own risk. Company disclaims all liability
            and responsibility arising from any reliance placed on such
            materials by the User or any other party who may be informed of any
            the information contained on the Sites. The Sites include
            information and content provided by third parties, including the
            Content and other materials provided by other users, bloggers and
            third-party licensors, syndicators, aggregators, and/or reporting
            services (“
            <b>Third Party Content</b>”). ALL STATEMENTS AND/OR OPINIONS
            EXPRESSED IN THE THIRD PARTY CONTENT, AND ALL ARTICLES AND RESPONSES
            TO QUESTIONS AND OTHER CONTENT, OTHER THAN THE CONTENT AND MATERIALS
            PROVIDED BY THE COMPANY, ARE SOLELY THE OPINIONS AND THE
            RESPONSIBILITY OF THE PERSON OR ENTITY PROVIDING SUCH THIRD PARTY
            CONTENT. THE THIRD PARTY CONTENT DOES NOT NECESSARILY REFLECT THE
            OPINION OF THE COMPANY, AND THE COMPANY IS NOT RESPONSIBLE OR LIABLE
            TO ANY USER OR ANY THIRD PARTY FOR THE ACCURACY OF ANY OF THE THIRD
            PARTY CONTENT.
          </li>
          <li>
            <u>Reservation of Rights</u>. USER EXPRESSLY ACKNOWLEDGES AND AGREES
            THAT NOTHING IN THESE TERMS OF USE GRANTS ANY RIGHT, TITLE, OR
            INTEREST IN OR TO (INCLUDING ANY LICENSE UNDER) ANY INTELLECTUAL
            PROPERTY IN OR RELATING TO, THE SITES, SERVICES, OR ANY MATERIALS
            PROVIDED BY COMPANY TO USER, WHETHER EXPRESSLY, BY IMPLICATION,
            ESTOPPEL, OR OTHERWISE. All right, title, and interest in and to the
            Sites, Services, and any materials provided by Company to User will
            remain with the Company (subject to any limitations associated with
            intellectual property rights of third parties with respect to
            materials provided by such third parties), even if enhancements or
            other changes are suggested or requested by User or any Authorized
            User and become incorporated into the Sites, Services or
            Intellectual Property.
          </li>
          <li>
            <u>Marks and Publicity</u>. Company and User trademarks, trade
            names, service marks, and logos, whether or not registered (“
            <b>Marks</b>”), are the sole and exclusive property of the
            respective owning party, which owns all right, title and interest
            therein. Company may: (a) use the User&apos;s name and/or logo
            within product literature, press release(s), social media, and other
            marketing materials; (b) quote the User&apos;s statements in one or
            more press releases; and/or (c) make such other use of the
            User&apos;s name and/or logo as may be agreed between the parties.
            Additionally, Company may include User&apos;s name and/or logo
            within its list of customers for general promotional purposes.
            Company shall comply with User&apos;s trademark use guidelines as
            such are communicated to the Company in writing and Company shall
            use the User&apos;s Marks in a manner which is consistent with
            industry practice. Neither party grants to the other any title,
            interest or other right in any Marks except as provided in this
            Section.
          </li>
          <li>
            <u>Certain Restrictions</u>. In no event will User disassemble,
            decompile, or reverse engineer the Sites, Services, or Intellectual
            Property (as defined in Section 12(b)) or permit others to do so.
            Disassembling, decompiling, and reverse engineering include, without
            limitation: (a) converting the Intellectual Property from a
            machine-readable form into a human-readable form; (b) disassembling
            or decompiling the Intellectual Property by using any means or
            methods to translate machine-dependent or machine-independent object
            code into the original human-readable source code or any
            approximation thereof; (c) examining the machine-readable object
            code that controls the Intellectual Property&apos;s operation and
            creating the original source code or any approximation thereof by,
            for example, studying the Intellectual Property&apos;s behavior in
            response to a variety of inputs; or (d) performing any other
            activity related to the Intellectual Property that could be
            construed to be reverse engineering, disassembling, or decompiling.
          </li>
          <li>
            <u>Confidential Information</u>. User agrees that it shall take
            reasonable measures to protect the secrecy of and avoid disclosure
            and unauthorized use of the Confidential Information (defined
            below). Without limiting the foregoing, User shall take at least
            those measures that it takes to protect its own confidential
            information of a similar nature, but in no case less than reasonable
            care (including, without limitation, all precautions the User
            employs with respect to its confidential materials). User shall
            limit access to Company&apos;s Confidential Information to those of
            its employees and agents who need such access for purposes
            consistent with the applicable Service Agreement and these Terms of
            Use; <u>provided</u>, <u>however</u>, that User shall ensure that
            its employees and agents who have access to Company&apos;s
            Confidential Information have signed a non-use and non-disclosure
            agreement in content similar to the provisions of these Terms of Use
            or are otherwise legally obligated not to disclose such Confidential
            Information, prior to any disclosure of Confidential Information to
            such employees and agents. User shall not make any copies of the
            Confidential Information except upon the Company&apos;s prior
            written approval. User shall promptly notify Company if User becomes
            aware of any use or disclosure of the Confidential Information in
            violation of these Terms of Use. If User is required by law to make
            any disclosure that is prohibited or otherwise constrained by these
            Terms of Use, User will provide Company with prompt written notice
            of such requirement so that Company may seek a protective order or
            other appropriate relief. Subject to the foregoing sentence, User
            may furnish that portion (and only that portion) of the Confidential
            Information that User is legally compelled or is otherwise legally
            required to disclose; <u>provided</u>, <u>however</u>, that User
            provides such assistance as Company may reasonably request in
            obtaining such order or other relief. FOR THE AVOIDANCE OF DOUBT,
            NOTHING IN THESE TERMS OF USE WILL BE CONSTRUED TO CONVEY ANY RIGHT,
            TITLE OR INTEREST IN AND TO ANY INTELLECTUAL PROPERTY OR
            CONFIDENTIAL INFORMATION OF THE COMPANY. USER SHALL NOT, IN WHOLE OR
            IN PART, SELL, LEASE, LICENSE, ASSIGN, TRANSFER, OR DISCLOSE THE
            CONFIDENTIAL INFORMATION TO ANY THIRD PARTY AND SHALL NOT COPY,
            REPRODUCE OR DISTRIBUTE THE CONFIDENTIAL INFORMATION EXCEPT AS
            EXPRESSLY PERMITTED IN THESE TERMS OF USE. USER HEREBY EXPRESSLY
            ACKNOWLEDGES AND AGREES THAT COMPANY SHALL OWN ALL SUGGESTIONS,
            SOLUTIONS, IMPROVEMENTS, CORRECTIONS, AND OTHER CONTRIBUTIONS
            PROVIDED BY USER REGARDING THE SITES, SERVICES, CONFIDENTIAL
            INFORMATION, INTELLECTUAL PROPERTY OR OTHER MATERIALS PROVIDED BY
            COMPANY TO USER WILL BE OWNED BY COMPANY, AND, IN THE EVENT THAT
            USER IS DEEMED TO HAVE ANY SUCH RIGHT, TITLE OR INTEREST, USER
            HEREBY AGREES TO ASSIGN ANY SUCH RIGHTS, TITLE AND INTEREST TO THE
            COMPANY. NO PROVISION IN THESE TERMS OF USE SHALL PRECLUDE THE
            COMPANY FROM USING IN ANY MANNER OR FOR ANY PURPOSE IT DEEMS
            NECESSARY, THE KNOW-HOW, TECHNIQUES, OR PROCEDURES ACQUIRED OR USED
            BY COMPANY IN THE PERFORMANCE OF THE SERVICES HEREUNDER.
          </li>
          <NestedList>
            <li>
              “<b>Confidential Information</b>” means (a) any information
              disclosed by Company to the User or Authorized Users, either
              directly or indirectly, in writing, orally or by inspection of
              tangible objects, including, without limitation, algorithms,
              business plans, customer data, customer lists, customer names,
              deposit lists, depositor names, lenders, borrowers, designs
              documents, drawings, engineering information, financial analysis,
              forecasts, formulas, hardware configuration information, know how,
              ideas, inventions, market information, marketing plans, processes,
              products, product plans, research, specifications, Intellectual
              Property or any information which is designated as “confidential,”
              “proprietary” or some similar designation (collectively, the “
              <b>Disclosed Materials</b>”) and (b) any information otherwise
              obtained, directly or indirectly, by User or Authorized Users
              through inspection, review or analysis of the Disclosed Materials.
              Confidential Information may also include information of a third
              party that is in Company&apos;s possession and is disclosed to
              User or Authorized Users pursuant to these Terms of Use.
              Confidential Information shall not, however, include any
              information that (1) was publicly known and made generally
              available in the public domain prior to the time of disclosure by
              Company or any third party to the User or Authorized Users; (2)
              becomes publicly known and made generally available after
              disclosure by Company to User or Authorized Users through no
              action or inaction of the User or Authorized Users; (3) is already
              in the possession of the User at the time of disclosure by Company
              as shown by the User&apos;s files and records immediately prior to
              the time of disclosure; (4) is obtained by the User from a third
              party lawfully in possession of such information and without a
              breach of such third party&apos;s obligations of confidentiality;
              or (5) is independently developed by the User without use of or
              reference to Company&apos;s or any third party&apos;s Confidential
              Information, as shown by documents and other competent evidence in
              the User&apos;s possession.
            </li>
            <li>
              “<b>Intellectual Property</b>” means any intellectual property of
              the Company in any jurisdiction throughout the world, including,
              but not limited to: (i) all inventions (whether patentable or
              unpatentable and whether or not reduced to practice), all
              improvements thereto and all patents, patent applications and
              patent disclosures, together with all reissuances, continuations,
              continuations-in-part, revisions, extensions and reexaminations
              thereof, (ii) all trademarks, service marks, trade dress, logos,
              slogans, trade names, corporate names, Internet domain names,
              uniform resource locators and e-mail addresses, and rights in
              telephone numbers, together with all translations, adaptations,
              derivations and combinations thereof and including all goodwill
              associated therewith and all applications, registrations and
              renewals in connection therewith, (iii) all copyrightable works,
              all copyrights, and all applications, registrations and renewals
              in connection therewith, (iv) all trade secrets and confidential
              business information (including ideas, research and development,
              know-how, formulas, compositions, manufacturing and production
              processes and techniques, technical data, designs, drawings,
              specifications, customer and supplier lists, pricing and cost
              information and business and marketing plans and proposals), (v)
              all software, including computer programs, machine-readable
              instruction sets or data in computerized form, whether in source
              code, object code or other form, and all data, databases and
              related documentation, (vi) all other proprietary rights and (vii)
              all copies and tangible embodiments thereof (in whatever form or
              medium).
            </li>
          </NestedList>
          <li>
            <u>DISCLAIMER OF WARRANTIES</u>. EXCEPT FOR THE EXPRESS WARRANTIES
            SET FORTH IN THE APPLICABLE SERVICE AGREEMENT, ALL SERVICES,
            INTELLECTUAL PROPERTY, CONFIDENTIAL INFORMATION, AND ANY OTHER
            TECHNOLOGY OR MATERIALS ARE PROVIDED “AS IS.” COMPANY SPECIFICALLY
            DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES
            ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT
            LIMITING THE FOREGOING, COMPANY MAKES NO WARRANTY OF ANY KIND THAT
            THE SERVICES OR ANY MATERIALS OR TECHNOLOGY FURNISHED BY COMPANY, OR
            ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET USER&apos;S OR
            ANY OTHER PERSON&apos;S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION,
            ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY
            SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE,
            COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. ALL THIRD PARTY
            MATERIALS ARE PROVIDED “AS IS” AND ANY REPRESENTATION OR WARRANTY OF
            OR CONCERNING ANY THIRD PARTY MATERIALS IS STRICTLY BETWEEN USER AND
            THE THIRD PARTY OWNER OR DISTRIBUTOR OF THE THIRD PARTY MATERIALS.
          </li>
          <li>
            <u>Indemnification</u>. User shall indemnify, defend, and hold
            harmless Company and its subcontractors and affiliates, and each of
            its and their respective officers, directors, employees, agents,
            successors, and assigns (each, an “<b>Indemnitee</b>”) from and
            against any and all losses, damages, deficiencies, claims, actions,
            judgments, settlements, interest, awards, penalties, fines, costs,
            or expenses of whatever kind (including reasonable attorneys&apos;
            fees and the costs of enforcing any right to indemnification
            hereunder and the cost of pursuing any insurance providers),
            incurred by any Indemnitee arising out of or resulting from, or are
            alleged to arise out of or result from (collectively, a “
            <b>Claim Against Indemnitee</b>”): (a) Content and User Data,
            including any processing of Content and/or User Data by or on behalf
            of Company in accordance with these Terms of Use; (ii) any other
            materials or information (including any documents, data,
            specifications, software, content, or technology) provided by or on
            behalf of User or any Authorized User, including Company&apos;s
            compliance with any specifications or directions provided by or on
            behalf of User or any Authorized User; (iii) allegation of facts
            that, if true, would constitute User&apos;s breach of any of its
            representations, warranties, covenants, or obligations under these
            Terms of Use or the applicable Service Agreement; (iv) gross
            negligence or more culpable act or omission (including recklessness
            or willful misconduct) by User, any Authorized User, or any third
            party on behalf of User or any Authorized User, in connection with
            these Terms of Use or the applicable Service Agreement; or (v) any
            violation of any right of any third party or the breach of any
            obligation to any third party under any agreement or other
            arrangement between User and/or Authorized User and such third
            party, including, but not limited to, the infringement, violation or
            misappropriation of any copyright, patent, trademark, trade dress,
            trade secret or other proprietary or intellectual property right of
            any third party or the breach of any contract.
          </li>
          <li>
            <u>LIMITATION OF LIABILITY</u>. IN NO EVENT WILL COMPANY OR ANY OF
            ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, OR SUPPLIERS BE LIABLE
            UNDER OR IN CONNECTION WITH THESE TERMS OF USE OR THE SERVICE
            AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY,
            INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
            LIABILITY, AND OTHERWISE, FOR ANY: (i) LOSS OF PRODUCTION, USE,
            BUSINESS, REVENUE, OR PROFIT OR DIMINUTION IN VALUE; (ii)
            IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION OR DELAY OF THE
            SERVICES; (iii) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF DATA, OR
            BREACH OF DATA OR SYSTEM SECURITY; (iv) COST OF REPLACEMENT GOODS OR
            SERVICES; (v) LOSS OF GOODWILL OR REPUTATION; OR (vi) CONSEQUENTIAL,
            INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE
            DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE
            POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE
            OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED
            OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
          </li>
          <li>
            <u>CAP ON LIABILITY</u>. IN NO EVENT WILL THE COLLECTIVE AGGREGATE
            LIABILITY OF COMPANY AND ITS AFFILIATES, LICENSORS, SERVICE
            PROVIDERS, AND SUPPLIERS ARISING OUT OF OR RELATED TO THESE TERMS OF
            USE OR THE SERVICE AGREEMENT, WHETHER ARISING UNDER OR RELATED TO
            BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY,
            OR ANY OTHER LEGAL OR EQUITABLE THEORY, EXCEED THE SUM OF ONE
            THOUSAND DOLLARS ($1,000). THE FOREGOING LIMITATIONS APPLY EVEN IF
            ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE. THE FOREGOING LIMITATIONS
            APPLY EVEN IF COMPANY IS NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGE
            AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY.
          </li>
          <li>
            <u>Term and Termination</u>. The term of these Terms of Use will
            continue until the termination or expiration of the applicable
            Service Agreement. All rights, licenses and obligations provided in
            Sections 3-4, 8-18, 20, 22-23, 25, and 29-31 shall survive any
            termination or expiration of this Agreement, together with any
            remedies for breach, or other rights or obligations of the parties
            that, by their nature, should survive the termination or expiration
            of this Agreement.
          </li>
          <li>
            <u> Modifications and Amendments</u>. These Terms of Use may be
            updated or amended by Company at any time, with or without notice to
            User and User&apos;s continued use of the Sites and Services shall
            constitute User&apos;s acceptance of the updated or amended Terms of
            Use.
          </li>
          <li>
            <u> Assignment</u>. User may not assign all or any part of its
            rights or obligations hereunder, whether by operation of law, change
            of control, or in any other manner, without the prior written
            consent of Company. Any such assignment in violation of this Section
            will be deemed void. The Company may assign, in whole or in part,
            its rights, interests, and obligations hereunder without limitation
            and without providing notice to User.
          </li>
          <li>
            <u>Electronic Communications</u>. The communications between User
            and Company use electronic means, whether User visits the Sites or
            sends Company emails, or whether Company posts notices on the Sites
            or Services or communicates with User via email. For contractual
            purposes, User (a) consents to receive communications from Company
            in an electronic form; and (b) agrees that all terms and conditions,
            agreements, notices, disclosures, and other communications that
            Company provides to User electronically satisfies any legal
            requirement that such communications would satisfy if it were in
            writing. The foregoing does not affect User&apos;s non-waivable
            rights.
          </li>
          <li>
            <u>Third Parties</u>. Company will have the right to use third
            parties, including, but not limited to, employees of Company&apos;s
            affiliates and subsidiaries (“<b>subcontractors</b>
            ”), in performance of its obligations and services hereunder, and
            for purposes of these Terms of Use, all references to Company or its
            employees will be deemed to include such subcontractors.
          </li>
          <li>
            <u>Technical Data</u>. User shall not provide to Company any
            technical data as that term is defined in the International Traffic
            in Arms Regulations (“ITAR”) at 22 CFR 120.10. User shall certify
            that all information provided to Company has been reviewed and
            scrubbed so that all technical data and other sensitive information
            relevant to User&apos;s ITAR regulated project has been removed and
            the information provided is only relevant to bug reports on Company
            products.
          </li>
          <li>
            <u>U.S. EXPORT CONTROLS</u>. NEITHER THE SITES, SERVICES, NOR ANY
            SOFTWARE PROVIDED BY THE COMPANY THROUGH THE SERVICES (“SOFTWARE”)
            MAY BE EXPORTED OR RE-EXPORTED (I) INTO THE TERRITORY OF (OR TO A
            NATIONAL OR RESIDENT OF) CUBA, NORTH KOREA, IRAN, SYRIA, SUDAN, OR
            ANY OTHER COUNTRY TO WHICH THE UNITED STATES HAS EMBARGOED GOODS OR
            SERVICES; OR (II) TO ANYONE ON THE U.S. TREASURY DEPARTMENT&apos;S
            LIST OF SPECIALLY DESIGNATED NATIONALS OR THE U.S. COMMERCE
            DEPARTMENT&apos;S TABLE OF DENY ORDERS. BY ACCESSING THE SITES OR
            SERVICES OR DOWNLOADING OR USING ANY SOFTWARE PROVIDED THROUGH THE
            SITES OR SERVICES, USER REPRESENTS AND WARRANTS THAT USER IS NOT
            LOCATED IN, UNDER THE CONTROL OF, OR A NATIONAL OR RESIDENT OF ANY
            SUCH COUNTRY OR ON ANY SUCH LIST.
          </li>
          <li>
            <u>Compliance with Laws</u>. Both parties agree to comply with all
            applicable laws, regulations, and ordinances relating to such
            party&apos;s performance under these Terms of Use.
          </li>
          <li>
            <u>Force Majeure</u>. In no event will Company be liable or
            responsible to any User, or be deemed to have defaulted under or
            breached these Terms of Use or any Service Agreement, for any
            failure or delay in fulfilling or performing any term of these Terms
            of Use or any Service Agreement when and to the extent such failure
            or delay is caused by any circumstances beyond Company&apos;s
            reasonable control, including, but not limited to, acts of God,
            flood, fire, earthquake or explosion, war, terrorism, invasion, riot
            or other civil unrest, epidemics or pandemics as defined by the
            Centers for Disease Control and Prevention or the World Health
            Organization, embargoes or blockades in effect on or after the date
            of these Terms of Use or any applicable Service Agreement, whichever
            is dated earlier, national or regional emergency, strikes, labor
            stoppages or slowdowns or other industrial disturbances, passage of
            law or any action taken by a governmental or public authority,
            including imposing an embargo, export or import restriction, quota,
            or other restriction or prohibition or any complete or partial
            government shutdown, or national or regional shortage of adequate
            power or telecommunications or transportation, or any other
            emergency beyond the Company&apos;s reasonable control making it
            inadvisable, illegal or impractical to perform its obligations
            hereunder (each, a “<b>Force Majeure Event</b>”).
          </li>
          <li>
            <u>Restricted Rights</u>. Use of the Sites, Services or Software by
            or for the United States Government is conditioned upon the
            Government agreeing that the Sites, Services and Software is subject
            to Restricted Rights as provided under the provisions set forth in
            FAR 52.227-19. User shall be responsible for assuring that this
            provision is included in all agreements with the United States
            Government and that the Sites, Services or Software, when accessed
            by the Government, is correctly marked as required by applicable
            Government regulations governing such Restricted Rights as of such
            access.
          </li>
          <li>
            <u>Independent Contractor</u>. Company is an independent contractor
            and nothing in these Terms of Use will be deemed to make Company an
            agent, employee, partner, or joint venturer of User. Neither party
            will have authority to bind, commit, or otherwise obligate the other
            party in any manner whatsoever.
          </li>
          <li>
            <u>Privacy</u>. User understands and acknowledges that User&apos;s
            personal data will be collected, protected, and used by the Company
            in accordance with the Company&apos;s Privacy Policy, which is
            incorporated into these Terms of Use as if set forth verbatim
            herein.
          </li>
          <li>
            <u>Entire Agreement</u>. These Terms of Use together with the
            Service Agreement and the documents and exhibits attached thereto
            constitute the entire agreement between the parties regarding the
            subject matter hereof and supersedes all proposals and prior
            discussions and writings between the parties with respect to the
            subject matter contained herein.
          </li>
          <li>
            <u>Governing Law</u>. These Terms of Use are governed by and
            construed in accordance with the laws of the State of Utah without
            giving effect to any choice or conflict of law provision or rule
            that would require or permit the application of the laws of any
            jurisdiction other than those of the State of Utah. ANY LEGAL SUIT,
            ACTION, OR PROCEEDING ARISING OUT OF OR RELATED TO THESE TERMS OF
            USE OR THE RIGHTS OR LICENSES GRANTED HEREUNDER WILL BE INSTITUTED
            EXCLUSIVELY IN THE FEDERAL COURTS OF THE UNITED STATES OR THE COURTS
            OF THE STATE OF UTAH IN EACH CASE LOCATED IN THE CITY OF SALT LAKE
            AND COUNTY OF SALT LAKE, AND EACH PARTY IRREVOCABLY SUBMITS TO THE
            EXCLUSIVE JURISDICTION OF SUCH COURTS IN ANY SUCH SUIT, ACTION, OR
            PROCEEDING. Service of process, summons, notice, or other document
            by mail to such party&apos;s address set forth herein shall be
            effective service of process for any suit, action, or other
            proceeding brought in any such court.
          </li>
          <li>
            <u>WAIVER OF JURY TRIAL</u>. EACH PARTY IRREVOCABLY AND
            UNCONDITIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN
            RESPECT OF ANY LEGAL ACTION ARISING OUT OF OR RELATING TO THESE
            TERMS OF USE, THE SERVICE AGREEMENT OR ANY TRANSACTION CONTEMPLATED
            BY THESE TERMS OF USE OR THE SERVICE AGREEMENT.
          </li>
        </Stack>
      </Typography>
    </Container>
  )
}
