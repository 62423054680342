import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { QueryKey } from '@tanstack/react-query'
import { DocumentChip } from '@/types/documents'
import { LogEntry } from '@/types/log-entries'
import {
  useGetDocumentChips,
  useUpdateChipAssignments,
} from '@/service-library/hooks/document-chips'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useDocumentRowValuesContext } from './DocumentRowValuesProvider'
import { useFieldErrorContext } from './FieldErrorProvider'

type FieldIdentifier = `${string}_${string}`

type DocumentChipsContextValue = {
  documentChips: DocumentChip[]
  hoveredChipId: string | null
  fieldsBeingUpdated: Record<FieldIdentifier, boolean>
  updateDocumentChipField: (
    chip: DocumentChip,
    fieldId: string,
    documentRowId: string | null,
  ) => void
  clearDocumentChipField: (chip: DocumentChip) => Promise<DocumentChip[]>
  getFieldChips: (fieldId: string, documentRowId: string) => DocumentChip[]
  refetchDocumentChips: () => void
  setHoveredChipId: Dispatch<SetStateAction<string | null>>
  listQueryKey: QueryKey
}

const DocumentChipsContext = createContext<DocumentChipsContextValue>(
  {} as DocumentChipsContextValue,
)

export const useDocumentChipsContext = () => useContext(DocumentChipsContext)

type DocumentChipsProviderProps = {
  children: ReactNode
}

export default function DocumentChipsProvider({
  children,
}: DocumentChipsProviderProps) {
  const { document } = useDocumentContext()
  const { fieldsMap } = useProjectContext()
  const { refetch } = useDocumentRowValuesContext()
  const { setFieldError } = useFieldErrorContext()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const [hoveredChipId, setHoveredChipId] = useState<string | null>(null)

  const [fieldsBeingUpdated, setFieldsBeingUpdated] = useState<
    Record<FieldIdentifier, true>
  >({})

  const {
    documentChips = [],
    hasNextPage,
    isFetching,
    fetchNextPage,
    refetch: refetchDocumentChips,
    queryKey,
  } = useGetDocumentChips({
    filters: {
      limit: '1000',
      document_id: document?.id as string,
    },
    enabled: !!document?.id,
  })

  const updateDocumentChipsMutation = useUpdateChipAssignments({
    listQueryKey: queryKey,
    workflowId: selectedWorkflow.id,
    createActivityLog: ({ prevData, updatedData }) => {
      const updatedChips = updatedData
      const logEntries: Omit<LogEntry, 'created_at' | 'user_id' | 'id'>[] = []
      let foundChips = 0

      for (const prevChip of prevData) {
        // Break if we've found all the chips
        if (foundChips === updatedChips.length) break

        const updatedChip = updatedChips.find((c) => c.id === prevChip.id)
        if (!updatedChip) continue
        foundChips++

        const prevChipFieldId = prevChip.project_grid_field_id
        const updatedChipFieldId = updatedChip.project_grid_field_id

        const commonProperties = {
          endpoint: '/v2/pd/documents',
          obj_id: updatedChip.document_id,
          service_name: 'pdWeb',
          metadata: {
            document_chip_id: updatedChip.id,
            document_row_id: updatedChip.document_row_id,
            prev_document_row_id: prevChip.document_row_id,
          },
          ref_objs: [
            {
              ep: 'v2/pd/document_chips',
              obj_id: updatedChip.id,
              key: 'chip_text',
              obj: {
                text: updatedChip.text,
              },
            },
          ],
        }

        if (prevChipFieldId !== updatedChipFieldId) {
          if (prevChipFieldId) {
            logEntries.push({
              ...commonProperties,
              code: 'CHIP_UNASSIGNED',
              short_description: `Chip Unassigned from ${fieldsMap[prevChipFieldId].name}`,
              long_description: `A chip with text "${updatedChip.text}" was removed from ${fieldsMap[prevChipFieldId].name}.`,
              ref_objs: [
                ...commonProperties.ref_objs,
                {
                  ep: 'v2/pd/project_grid_fields',
                  obj_id: prevChipFieldId,
                  key: 'field_name',
                  obj: {},
                },
              ],
            })
          }

          if (updatedChipFieldId) {
            logEntries.push({
              ...commonProperties,
              code: 'CHIP_ASSIGNED',
              short_description: `Chip Assigned to ${fieldsMap[updatedChipFieldId].name}`,
              long_description: `A chip with text "${updatedChip.text}" was assigned to ${fieldsMap[updatedChipFieldId].name}.`,
              ref_objs: [
                ...commonProperties.ref_objs,
                {
                  ep: 'v2/pd/project_grid_fields',
                  obj_id: updatedChipFieldId,
                  key: 'field_name',
                  obj: {},
                },
              ],
            })
          }
        } else if (
          updatedChipFieldId &&
          prevChip.document_row_id !== updatedChip.document_row_id
        ) {
          logEntries.push({
            ...commonProperties,
            code: 'CHIP_ASSIGNED_TO_DIFFERENT_ROW',
            short_description: `Chip Assigned to ${fieldsMap[updatedChipFieldId].name}`,
            long_description: `A chip with text "${updatedChip.text}" was assigned to ${fieldsMap[updatedChipFieldId].name} on a different row.`,
            ref_objs: [
              ...commonProperties.ref_objs,
              {
                ep: 'v2/pd/project_grid_fields',
                obj_id: updatedChipFieldId,
                key: 'field_name',
                obj: {},
              },
            ],
          })
        }
      }

      return logEntries
    },
    onError: (e, updatedChips) => {
      const chip = updatedChips[0]
      setFieldError(
        chip.project_grid_field_id || '',
        chip.document_row_id,
        'Unable to update field.',
      )
      chip.project_grid_field_id &&
        setFieldError(
          chip.project_grid_field_id,
          chip.document_row_id,
          'Unable to remove chip.',
        )
    },
    onIdle: () => {
      refetch().finally(() => {
        setFieldsBeingUpdated({})
      })
    },
  })

  const getFieldChips = (fieldId: string, documentRowId: string) => {
    return documentChips.filter(
      (chip) =>
        chip.project_grid_field_id === fieldId &&
        documentRowId === chip.document_row_id,
    )
  }

  const updateDocumentChipField = (
    /** The chip before updates */
    chip: DocumentChip,
    /** The field ID to assign the chip to */
    project_grid_field_id: string | null,
    /** The document row ID to assign the chip to */
    document_row_id: string | null,
  ) => {
    const updatedChip = {
      ...chip,
      project_grid_field_id,
      document_row_id,
    }

    // Save which fields are being updated so we can show it in the UI
    const existingFieldCompoundId: FieldIdentifier = `${chip.project_grid_field_id}_${chip.document_row_id}`
    const updatedFieldCompoundId: FieldIdentifier = `${project_grid_field_id}_${document_row_id}`

    // If we're just removing the chip, then it won't have a project_grid_field_id
    const isAssigningToField = !!chip.project_grid_field_id

    // Is the chip already on a field and is going to a new field? If yes, then two fields are involved in this update.
    const isSwappingToNewField =
      (project_grid_field_id &&
        project_grid_field_id !== chip.project_grid_field_id) ||
      (document_row_id && document_row_id !== chip.document_row_id)

    const fieldIdsBeingUpdated: FieldIdentifier[] = []
    if (isAssigningToField) {
      fieldIdsBeingUpdated.push(existingFieldCompoundId)
    }
    if (isSwappingToNewField) {
      fieldIdsBeingUpdated.push(updatedFieldCompoundId)
    }

    setFieldsBeingUpdated((prev) => ({
      ...prev,
      ...fieldIdsBeingUpdated.reduce<Record<string, boolean>>(
        (acc, compoundId) => {
          acc[compoundId] = true
          return acc
        },
        {},
      ),
    }))

    return updateDocumentChipsMutation.mutateAsync([updatedChip])
  }

  const handleUpdateDocumentChipField = (
    chip: DocumentChip,
    project_grid_field_id: string,
    document_row_id: string | null,
  ) => {
    setFieldError(project_grid_field_id, document_row_id, '')
    chip.project_grid_field_id &&
      setFieldError(chip.project_grid_field_id, chip.document_row_id, '')

    return updateDocumentChipField(chip, project_grid_field_id, document_row_id)
  }

  const clearDocumentChipField = (chip: DocumentChip) => {
    const fieldId = chip.project_grid_field_id as string
    const documentRowId = chip.document_row_id
    setFieldError(fieldId, documentRowId, '')
    return updateDocumentChipField(chip, null, null)
  }

  useNotifications({
    keys: [document?.id],
    callback: ({ action }) => {
      if (
        document?.id &&
        (action === 'document_workflow_state_status_changed' ||
          action === 'document_workflow_state_changed')
      ) {
        refetchDocumentChips()
      }
    },
  })

  useEffect(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  return (
    <DocumentChipsContext.Provider
      value={{
        documentChips,
        hoveredChipId,
        fieldsBeingUpdated,
        updateDocumentChipField: handleUpdateDocumentChipField,
        clearDocumentChipField,
        getFieldChips,
        refetchDocumentChips,
        setHoveredChipId,
        listQueryKey: queryKey,
      }}
    >
      {children}
    </DocumentChipsContext.Provider>
  )
}
