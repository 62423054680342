import { Alert, CircularProgress, Stack } from '@mui/material'
import { Organization } from '@/types/organizations'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import OrganizationDetails from './OrganizationDetails'
import { useGetOrganization } from '@/service-library/hooks/organizations'

type OrganizationPageProps = {
  organizationId: string
  organization?: Organization
}
export default function OrganizationPage({
  organizationId,
  organization,
}: OrganizationPageProps) {
  const {
    organization: currentOrg,
    isLoading,
    isError,
  } = useGetOrganization({
    id: organizationId,
    filters: {
      fields__include: 'org_type',
    },
    initialData: organization,
    staleTime: 0, // Since it doesn't include org type, we want to consider the initial data as stale
  })

  return (
    <>
      {currentOrg && (
        <Breadcrumb
          label={currentOrg.name}
          url={`/settings/organizations/${currentOrg.id}`}
        />
      )}

      {(isError || (!isLoading && !currentOrg)) && (
        <Alert severity="error">
          Unable to load this organization. You may not have access or this
          organization does not exist.
        </Alert>
      )}

      {!isError && isLoading ? (
        <Stack alignItems="center" sx={{ p: 4 }}>
          <CircularProgress />
        </Stack>
      ) : (
        currentOrg && <OrganizationDetails organization={currentOrg} />
      )}
    </>
  )
}
