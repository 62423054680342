import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { User } from '@/types/users'
import { OverlayState } from '@/hooks/useOverlay'
import { useUpdateUser } from '@/service-library/hooks/users'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { PixyDocsForm } from '@/components/forms'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import UserAssignment from './UserAssignment'

type FormValues = {
  teams_ids: string[]
}

type EditUserTeamsDialogProps = {
  overlay: OverlayState
  user: User
  detailQueryKey: QueryKey
  teamsQueryKey: QueryKey
}

export default function EditUserTeamsDialog({
  overlay,
  user,
  detailQueryKey,
  teamsQueryKey,
}: EditUserTeamsDialogProps) {
  const { rootOrganization } = useRootOrganization()

  const { updateUser } = useUpdateUser({
    sideEffectQueryKeys: [teamsQueryKey],
    detailQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update user. Please try again later.')
    },
  })

  const methods = useForm<FormValues>({
    defaultValues: {
      teams_ids: user.teams_ids || [],
    },
  })

  const {
    formState: { isDirty, isValid },
    reset,
  } = methods

  function handleUpdateUser({ teams_ids }: FormValues) {
    const newUser = {
      ...user,
      teams_ids,
    }

    updateUser(newUser)
    overlay.close()
  }

  useEffect(() => {
    if (!overlay.isOpen) {
      reset({
        teams_ids: user.teams_ids || [],
      })
    }
  }, [overlay.isOpen, reset, user.teams_ids])

  return (
    <Dialog {...overlay} maxWidth="md" title="Edit Teams">
      <DialogContent disablePadding>
        <PixyDocsForm
          id="user-form"
          methods={methods}
          onSubmit={handleUpdateUser}
        >
          <UserAssignment rootOrgId={rootOrganization?.id} isEdit />
        </PixyDocsForm>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>

        <Button
          form="user-form"
          disabled={!isDirty || !isValid}
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
