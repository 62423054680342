import BaseDecimalField, {
  DecimalFieldProps as BaseDecimalFieldProps,
} from '@/components/inputs/DecimalField'

type DecimalFieldProps = {
  onChange: (value: string) => void
  value: string
} & Omit<BaseDecimalFieldProps, 'onChange' | 'value'>

export default function DecimalField({
  onChange,
  ...props
}: DecimalFieldProps) {
  return (
    <BaseDecimalField
      autoComplete="off"
      onChange={(value) => onChange(value.toString())} // BaseDecimalField returns the value instead of the event
      {...props}
    />
  )
}
