import { MoreVert, Add } from '@mui/icons-material'
import {
  Typography,
  Card,
  Button,
  Divider,
  Stack,
  IconButton,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { Document } from '@/types/documents'
import { Organization } from '@/types/organizations'
import { Workflow, WorkflowState } from '@/types/workflows'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import {
  useRemoveDocumentsFromWorkflow,
  useSetDocumentsWorkflowState,
} from '@/service-library/hooks/document-workflow-states'
import {
  useArchiveDocuments,
  useGetDocument,
  useUpdateDocument,
} from '@/service-library/hooks/documents'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import DocumentWorkflowsTable from '@/components/document-workflows-table/DocumentWorkflowsTable'
import DocumentActionsMenu from '@/components/document-actions-menu/DocumentActionsMenu'
import EditableDocumentName from '@/components/editable-document-name/EditableDocumentName'
import OrganizationAssignmentSelect from '@/components/organization-select/OrganizationAssignmentSelect'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import DocumentAuditEntries from './DocumentAuditEntries'
import DocumentProjectModelVersions from './DocumentProjectModelVersions'
import StaticDocumentDetails from './StaticDocumentDetails'
import SectionAccordion from './SectionAccordion'
import DocumentLogEntries from './DocumentLogEntries'

const noStateFiller: WorkflowState = {
  id: 'none',
  name: (
    <Stack spacing={1} direction="row" alignItems="center">
      <Add fontSize="small" />
      <Typography variant="body2">Add Document to Workflow</Typography>
    </Stack>
  ) as unknown as string,
  color: 'blue',
  code: 'multiple',
  workflow_id: 'multiple',
  sort_order: 0,
  allowed_document_views: ['validation'],
  default_document_view: 'validation',
}

type DocumentDialogProps = {
  overlay: OverlayState
  documentId: string
  projectId: string
  document?: Document // If not provided, the document will be fetched
  detailQueryKey?: QueryKey

  /** Callback that fires before the document has been deleted. Should not be used to refetch query data. That should be done in the mutation hook. */
  onBeforeDelete?: () => void

  /** Callback that fires when the document has been deleted. Should not be used to refetch query data. That should be done in the mutation hook. */
  onDelete?: () => void
}

export default function DocumentDialog({
  overlay,
  documentId,
  projectId,
  document,
  detailQueryKey,
  onBeforeDelete,
  onDelete,
}: DocumentDialogProps) {
  const actionsMenuOverlay = useOverlay()
  const queryClient = useQueryClient()
  const { workflows, selectedWorkflow } = useSelectedWorkflowContext()

  const { document: dialogDocument, queryKey } = useGetDocument({
    id: documentId || '',
    filters: {
      fields__include: 'created_at,created_by_user,workflow_states_ids',
    },
    enabled: overlay.isOpen && !document,
  })

  const documentToUse = document || dialogDocument
  const detailQueryKeyToUse = detailQueryKey || queryKey

  const { updateDocument } = useUpdateDocument({
    detailQueryKey: detailQueryKeyToUse,
    sideEffectQueryKeys: [
      queryKeys.documents.lists(), // dashboard or validation
      queryKeys.documentRows.lists(), // dashboard
    ],
    onError: () => {
      showErrorSnackbar('Failed to update document')
    },
  })

  const { archiveDocuments } = useArchiveDocuments({
    sideEffectQueryKeys: [
      queryKeys.documents.lists(), // dashboard or validation
      queryKeys.documentRows.lists(), // dashboard
      queryKeys.documentWorkflowStates.details(), // count
    ],
    onSuccess: () => {
      showSuccessSnackbar('Document Deleted')
    },
    onError: () => {
      showErrorSnackbar('Failed to delete document. Please try again later.')
    },
  })

  const { organizations, isFetchingAll } = useOrganizationsContext()

  const organization = organizations.find(
    ({ id }) => id === documentToUse?.owner_org_id,
  ) as Organization

  const { setWorkflowState } = useSetDocumentsWorkflowState({
    onMutate: async ({ patch, current_workflow_state_id }) => {
      if (!detailQueryKeyToUse) return
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: detailQueryKeyToUse })
      const previous = queryClient.getQueryData(detailQueryKeyToUse) as Document
      queryClient.setQueryData(detailQueryKeyToUse, {
        ...(previous as Document),
        workflow_states_ids: [
          ...(previous.workflow_states_ids?.filter(
            (id) => id !== current_workflow_state_id,
          ) || []),
          patch.workflow_state__id,
        ],
      })
      return { previous }
    },
    onError: () => {
      showErrorSnackbar('Failed to add document to workflow')
    },
  })

  const { removeFromWorkflow } = useRemoveDocumentsFromWorkflow({
    onMutate: async ({ filter_criteria }) => {
      if (!detailQueryKeyToUse) return

      const { workflow_state__workflow_id } = filter_criteria
      await queryClient.cancelQueries({ queryKey: detailQueryKeyToUse })
      queryClient.setQueryData<Document>(detailQueryKeyToUse, (oldData) => {
        if (!oldData) return
        return {
          ...oldData,
          workflow_states_ids: oldData.workflow_states_ids?.filter((id) => {
            const workflow = workflows.find(
              ({ id }) => id === workflow_state__workflow_id,
            )
            return !workflow?.workflow_states?.some(
              (workflowState) => id === workflowState.id,
            )
          }),
        }
      })
    },
    onError: () => {
      showErrorSnackbar('Failed to remove document(s) from workflow')
    },
  })

  function handleUpdateOrganization(newOrganization: Organization) {
    if (!documentToUse || newOrganization.id === documentToUse.owner_org_id)
      return

    const updatedDocument = {
      ...documentToUse,
      owner_org_id: newOrganization.id,
    }

    updateDocument(updatedDocument)
  }

  const currentWorkflowStateMap = workflows.reduce<
    Record<string, WorkflowState>
  >((acc, workflow) => {
    const workflowState = workflow.workflow_states?.find(({ id }) => {
      return documentToUse?.workflow_states_ids?.includes(id)
    })
    acc[workflow.id] = workflowState || noStateFiller
    return acc
  }, {})

  function handleUpdateDocumentWorkflowState(
    workflow: Workflow,
    workflowState: WorkflowState,
  ) {
    setWorkflowState({
      patch: {
        workflow_state__code: workflowState.code,
        workflow_state__id: workflowState.id,
      },
      filter_criteria: {
        workflow_state__workflow_id: workflow.id,
        document_id__in: [documentId],
      },
      current_workflow_state_id: currentWorkflowStateMap[workflow.id].id,
    })
  }

  function handleRemoveFromWorkflow(workflow: Workflow) {
    removeFromWorkflow({
      filter_criteria: {
        workflow_state__workflow_id: workflow.id,
        document_id__in: [documentId],
      },
    })
  }

  function handleDelete() {
    overlay.close()
    onDelete?.()
  }

  function handleBeforeDelete() {
    overlay.close()
    onBeforeDelete?.()
  }

  async function handleReprocess() {
    if (detailQueryKeyToUse) {
      await queryClient.cancelQueries({ queryKey: detailQueryKeyToUse })

      queryClient.setQueryData(detailQueryKeyToUse, (oldData?: Document) => {
        if (!oldData) return
        return {
          ...oldData,
          workflow_states_ids: [
            selectedWorkflow?.workflow_states?.find(
              ({ code }) => code === 'processing',
            )?.id || '',
          ],
        }
      })
    }

    overlay.close()
  }

  if (!documentToUse) return null

  return (
    <Dialog
      {...overlay}
      title={
        <Stack direction="row" justifyContent="space-between">
          <EditableDocumentName
            document={documentToUse}
            updateDocument={updateDocument}
            typographyProps={{ variant: 'h6' }}
          />

          <IconButton onClick={actionsMenuOverlay.open}>
            <MoreVert />
          </IconButton>

          <DocumentActionsMenu
            projectId={projectId}
            documentId={documentToUse.id}
            overlay={actionsMenuOverlay}
            onBeforeDelete={handleBeforeDelete}
            onDelete={handleDelete}
            onReprocess={handleReprocess}
          />
        </Stack>
      }
      maxWidth="md"
    >
      <DialogContent>
        <Stack spacing={2.5}>
          <Grid container columnSpacing={2} rowSpacing={{ xs: 2, sm: 0 }}>
            <Grid xs={12} sm={7}>
              <StaticDocumentDetails document={documentToUse} />
            </Grid>

            <Grid xs={12} sm={5}>
              <Stack direction="row" spacing={{ xs: 0, sm: 2 }}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                />
                <Card sx={{ flexGrow: 1, borderRadius: 2 }} variant="outlined">
                  {organization && (
                    <OrganizationAssignmentSelect
                      selectedOrganization={organization}
                      organizations={organizations}
                      isFetching={isFetchingAll}
                      onChange={handleUpdateOrganization}
                    />
                  )}
                </Card>
              </Stack>
            </Grid>
          </Grid>
          <SectionAccordion title="Workflows" spacing={1}>
            <DocumentWorkflowsTable
              workflows={workflows}
              currentWorkflowStateMap={currentWorkflowStateMap}
              onWorkflowStateChange={handleUpdateDocumentWorkflowState}
              onRemoveFromWorkflow={handleRemoveFromWorkflow}
              onDeleteConfirm={() => {
                archiveDocuments([documentToUse.id])
                overlay.close()
              }}
            />
          </SectionAccordion>

          <DocumentProjectModelVersions
            documentId={documentToUse.id}
            enabled={overlay.isOpen}
          />
          <DocumentAuditEntries
            documentId={documentToUse.id}
            enabled={overlay.isOpen}
          />
          <DocumentLogEntries
            documentId={documentToUse.id}
            enabled={overlay.isOpen}
          />
        </Stack>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
