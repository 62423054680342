import { useRef } from 'react'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import useDebouncePromise from './useDebouncePromise'
import batchErrorHandler from '../utils/batch-error-handler'
import generateUuid from '@/utils/generate-uuid'
import { BulkResponse } from '@/types/bulk-response'
import PaginatedResponse from '@/types/paginated-response'

type UseBatchedRequestOptions<DataType, ReturnDataType> = {
  requestFn: (batchedItems: DataType[]) => Promise<ReturnDataType>
  queryKey?: QueryKey
  sideEffectQueryKeys?: (QueryKey | undefined)[]
  onIdle?: (data: ReturnDataType) => void
  batchIdRef?: ReturnType<typeof useRef>
}

export default function useBatchedRequest<
  DataType extends { id: string },
  ReturnDataType = DataType,
>({
  queryKey,
  requestFn,
  sideEffectQueryKeys = [],
  onIdle,
  batchIdRef,
}: UseBatchedRequestOptions<DataType, ReturnDataType>) {
  const queryClient = useQueryClient()

  const batchRef = useRef<Record<string, DataType>>({})
  const latestRequestInFlightRef = useRef<Promise<void>>()

  function cancelSideEffectQueries() {
    return Promise.all(
      sideEffectQueryKeys.map(async (key) => {
        if (!key) return
        queryClient.cancelQueries({ queryKey: key })
      }),
    )
  }

  async function invalidateSideEffectQueries() {
    // Cancel queries first so we don't clobber any optimistic updates we might make in hooks using this hook
    await cancelSideEffectQueries()
    sideEffectQueryKeys.forEach((key) => {
      if (!key) return
      queryClient.invalidateQueries({ queryKey: key })
    })
  }

  const debouncedRequest = useDebouncePromise({
    fn: (resolve, reject) => {
      const batchedItems = Object.values(batchRef.current)

      // Reset the batch as soon as we fire off a request so we get a fresh batch with each request
      batchRef.current = {}
      if (batchIdRef) batchIdRef.current = generateUuid()

      // Always cancel queries before we fire off a request, in case there's a refetch in progress.
      // This only happens if the the invalidation below fires, and _then_ the user starts another update
      // while the refetch from invalidation is still in flight. We cancel here and also on mutation
      // since this is debounced and won't fire until at least one second after the last mutation.
      queryKey && queryClient.cancelQueries({ queryKey })

      const promise = requestFn(batchedItems)
        .then((data) => {
          if (data && Array.isArray(data)) {
            const errorResults = batchErrorHandler<DataType>(
              data as BulkResponse<DataType>,
            )
            if (errorResults.length > 0) {
              reject(new Error('Some items may have failed.'))
            } else {
              resolve(data)
            }
          } else {
            resolve(data)
          }
          // By the time this promise resolves, we might have already started forming another batch to send.
          // If that's the case, we don't want to invalidate yet, since we're about to push out more updates.
          if (
            // Make sure we're not in the middle of setting up another request
            Object.values(batchRef.current).length === 0 &&
            // Make sure we don't have another following request already sent out
            latestRequestInFlightRef.current === promise
          ) {
            onIdle?.(data)
            invalidateSideEffectQueries()
            queryKey &&
              queryClient.invalidateQueries({
                queryKey,
                refetchPage: (
                  page: PaginatedResponse<DataType>,
                  index,
                  allPages,
                ) => {
                  if (index !== allPages.length - 1 && page.next === null) {
                    return true
                  }

                  return page.results.some(({ id }) =>
                    batchedItems.some((item) => item.id === id),
                  )
                },
              })
          }
        })
        .catch((e) => {
          reject(e)
          queryKey && queryClient.invalidateQueries({ queryKey })
        })

      latestRequestInFlightRef.current = promise
      return promise
    },
  })

  function handleCall(items: (DataType & { id: string })[]) {
    items.forEach((item) => {
      batchRef.current[(item.id as string) || generateUuid()] = item
    })
    return new Promise<ReturnDataType>((resolve, reject) => {
      debouncedRequest(resolve, reject)
    })
  }

  return handleCall
}
