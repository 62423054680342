import Breadcrumb from '../breadcrumbs/Breadcrumb'

type BreadcrumbProps = {
  label: string
  url?: string
}

export default function ProjectBreadcrumb(props: BreadcrumbProps) {
  return <Breadcrumb {...props} includeQueryParams />
}
