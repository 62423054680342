import { createContext, ReactNode, useContext, useMemo } from 'react'
import { DocumentRowValue } from '@/types/documents'
import { RowValueFlag } from '@/types/flags'
import { useGetDocumentRowValues } from '@/service-library/hooks/document-row-values'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'

type DocumentRowValuesContextValue = {
  getFieldDocumentRowValue: (
    fieldId: string,
    documentRowId: string,
  ) => DocumentRowValue | undefined
  fieldsWithFlags: string[]
  rowValueFlags: RowValueFlag[]
} & ReturnType<typeof useGetDocumentRowValues>

type DocumentRowValuesProviderProps = {
  children: ReactNode
}

const DocumentRowValuesContext = createContext<DocumentRowValuesContextValue>(
  {} as DocumentRowValuesContextValue,
)

export const useDocumentRowValuesContext = () =>
  useContext(DocumentRowValuesContext)

export default function DocumentRowValuesProvider({
  children,
}: DocumentRowValuesProviderProps) {
  const { document } = useDocumentContext()
  const initialDocumentRowValues = document?.document_rows?.reduce<
    DocumentRowValue[]
  >((acc, { document_row_values = [] }) => [...acc, ...document_row_values], [])

  const { documentRowValues, queryKey, ...rest } = useGetDocumentRowValues({
    filters: {
      document_row__document_id: document?.id,
      limit: '10000',
      fields__include: 'row_value_flags',
      row_value_flags__fields__include: 'flag_type',
    },
    initialListData: initialDocumentRowValues,
    staleTime: 1000,
    enabled: !!document?.id,
  })

  const getFieldDocumentRowValue = (fieldId: string, documentRowId: string) => {
    return documentRowValues.find(
      (documentRowValue) =>
        documentRowValue.document_row_id === documentRowId &&
        documentRowValue.project_grid_field_id === fieldId,
    )
  }

  const rowValueFlags = useMemo(() => {
    return documentRowValues.reduce<RowValueFlag[]>(
      (acc, { row_value_flags }) => {
        if (row_value_flags) return [...acc, ...row_value_flags]
        return acc
      },
      [],
    )
  }, [documentRowValues])

  const fieldsWithFlags = useMemo(() => {
    const tempFieldsWithFlags: string[] = []
    documentRowValues.forEach(({ row_value_flags, project_grid_field_id }) => {
      if (
        row_value_flags?.length &&
        !tempFieldsWithFlags.includes(project_grid_field_id)
      ) {
        tempFieldsWithFlags.push(project_grid_field_id)
      }
    })
    return tempFieldsWithFlags
  }, [documentRowValues])

  return (
    <DocumentRowValuesContext.Provider
      value={{
        queryKey,
        documentRowValues,
        rowValueFlags,
        fieldsWithFlags,
        getFieldDocumentRowValue,
        ...rest,
      }}
    >
      {children}
    </DocumentRowValuesContext.Provider>
  )
}
