// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function debouncePromise<T extends (...args: any[]) => any>({
  fn,
  interval,
  timerRef,
}: {
  fn: T
  interval: number
  timerRef: React.MutableRefObject<NodeJS.Timeout | null>
}) {
  return (...args: Parameters<T>) => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current)
    }
    return new Promise((resolve) => {
      timerRef.current = setTimeout(() => {
        timerRef.current = null
        return resolve(fn(...args))
      }, interval)
    })
  }
}
