import {
  FormDateTimeField,
  FormDecimalField,
  FormIntegerField,
  FormTextField,
} from '@/components/forms'
import { DataListColumn } from '@/types/data-lists'
import { Stack } from '@mui/material'

type DataListColumnFieldsProps = {
  visibleColumns: DataListColumn[]
}

export default function DataListColumnFields({
  visibleColumns,
}: DataListColumnFieldsProps) {
  return (
    <Stack spacing={2}>
      {visibleColumns.map(({ id, name, data_list_column_type }, index) => {
        const type = data_list_column_type.postgres_data_type

        if (type === 'int') {
          return (
            <FormIntegerField
              key={id}
              name={id}
              label={name}
              InputLabelProps={{ shrink: true }}
              variant="filled"
              autoFocus={index === 0}
            />
          )
        }

        if (type === 'decimal') {
          return (
            <FormDecimalField
              key={id}
              name={id}
              label={name}
              InputLabelProps={{ shrink: true }}
              variant="filled"
              autoFocus={index === 0}
            />
          )
        }

        if (type === 'date' || type === 'datetime' || type === 'time') {
          return (
            <FormDateTimeField
              key={id}
              name={id}
              label={name}
              type={type}
              InputLabelProps={{ shrink: true }}
              variant="filled"
              fullWidth
              autoFocus={index === 0}
            />
          )
        }

        // It is text type if none of the others
        return (
          <FormTextField
            key={id}
            name={id}
            variant="filled"
            label={name}
            InputLabelProps={{ shrink: true }}
            autoFocus={index === 0}
          />
        )
      })}
    </Stack>
  )
}
