import { useState } from 'react'
import { Delete, Edit } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { CustomVariableValue as TSCustomVariableValue } from '@/types/custom-variables'
import EditableNameField from '@/components/editable-name/EditableNameField'
import PermissionTooltip from '@/components/tooltip/PermissionTooltip'

type CustomVariableValueProps = {
  canEdit: boolean
  isRootOrg: boolean
  handleOnBlur: (value: string) => void
  onDelete: (variableValueId: string) => void
  variableValue?: TSCustomVariableValue
}

export default function CustomVariableValue({
  canEdit,
  isRootOrg,
  handleOnBlur,
  onDelete,
  variableValue,
}: CustomVariableValueProps) {
  const [isEditing, setIsEditing] = useState(false)
  const value = variableValue?.value || ''

  return (
    <>
      <Grid xs={isEditing ? 11 : 10}>
        {isEditing ? (
          <EditableNameField
            name={value}
            handleOnBlur={(newValue) => {
              handleOnBlur(newValue)
              setIsEditing(false)
            }}
          />
        ) : (
          <Typography
            color={!variableValue ? 'text.secondary' : undefined}
            noWrap
          >
            {!variableValue
              ? isRootOrg
                ? 'No Value Set'
                : 'Inherited'
              : value}
          </Typography>
        )}
      </Grid>
      {!isEditing && (
        <Grid xs={2}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <PermissionTooltip canEdit={canEdit} shortVersion>
              <IconButton
                disabled={!canEdit}
                onClick={(e) => {
                  e.preventDefault()
                  setIsEditing(true)
                }}
                size="small"
              >
                <Edit
                  color={canEdit ? 'primary' : 'disabled'}
                  fontSize="inherit"
                />
              </IconButton>
            </PermissionTooltip>
            {variableValue && (
              <PermissionTooltip canEdit={canEdit} shortVersion>
                <IconButton
                  disabled={!canEdit}
                  onClick={(e) => {
                    e.preventDefault()
                    onDelete(variableValue.id)
                  }}
                  size="small"
                >
                  <Delete
                    color={canEdit ? 'error' : 'disabled'}
                    fontSize="inherit"
                  />
                </IconButton>
              </PermissionTooltip>
            )}
          </Stack>
        </Grid>
      )}
    </>
  )
}
