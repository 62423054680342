import { useRef, useState } from 'react'
import { IconButton, Stack, TextField, Typography } from '@mui/material'
import { Check, Edit } from '@mui/icons-material'
import { useNodeId } from 'reactflow'
import useUpdateLogixNode from '@/services/hooks/useUpdateLogixNode'
import { useBoardContext } from '../BoardProvider'

type NodeHeadingProps = {
  title?: string
  canEditTitle?: boolean
}

export default function NodeHeading({ title, canEditTitle }: NodeHeadingProps) {
  const [editing, setEditing] = useState(false)
  const textFieldRef = useRef<HTMLInputElement>(null)
  const nodeId = useNodeId()
  const { updateLogixNode } = useUpdateLogixNode()
  const board = useBoardContext()

  function saveName() {
    if (!textFieldRef.current || !nodeId) return
    const { value } = textFieldRef.current
    if (value) {
      updateLogixNode({
        id: nodeId,
        board_id: board.id,
        name: value.trim(),
      })
    }
    setEditing(false)
  }

  if (!title) return null

  return (
    <Stack direction="row" sx={{ mb: 0 }} alignItems="center" spacing={1}>
      {editing ? (
        <>
          <TextField
            size="small"
            variant="outlined"
            autoFocus
            defaultValue={title}
            inputRef={textFieldRef}
            // Save on enter and blur
            onKeyDown={({ code }) =>
              (code === 'Enter' || code === 'NumpadEnter') && saveName()
            }
            onBlur={saveName}
          />
          <IconButton size="small" sx={{ p: 1 }} onClick={saveName}>
            <Check sx={{ fontSize: 16 }} />
          </IconButton>
        </>
      ) : (
        <>
          <Typography variant="subtitle1">
            <b>{title}</b>
          </Typography>
          {canEditTitle && (
            <IconButton
              size="small"
              sx={{ p: 0.25 }}
              onClick={() => setEditing(true)}
            >
              <Edit sx={{ fontSize: 16 }} />
            </IconButton>
          )}
        </>
      )}
    </Stack>
  )
}
