import { enqueueSnackbar, OptionsObject } from 'notistack'

export function showSuccessSnackbar(
  message: string,
  options: Omit<OptionsObject, 'variant'> = {},
) {
  return enqueueSnackbar(message, { variant: 'success', ...options })
}

export function showWarningSnackbar(
  message: string,
  options: Omit<OptionsObject, 'variant'> = {},
) {
  return enqueueSnackbar(message, { variant: 'warning', ...options })
}

export function showErrorSnackbar(
  message: string,
  options: Omit<OptionsObject, 'variant'> = {},
) {
  return enqueueSnackbar(message, { variant: 'error', ...options })
}

export function showInfoSnackbar(message: string, options: OptionsObject = {}) {
  return enqueueSnackbar(message, { variant: 'info', ...options })
}
