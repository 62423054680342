import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import useMutation, { UseMutationOptions } from './useMutation'
import PaginatedResponse from '@/types/paginated-response'
import { ListQueryKeyOption } from '../query-types'
import { DeleteRequestOptions } from '../request-types'
import { AxiosResponse } from 'axios'

export type UseDeleteDetailOptions = UseMutationOptions<
  void,
  unknown,
  string,
  unknown
> &
  DeleteRequestOptions &
  ListQueryKeyOption

type ServiceFn = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (
    options: DeleteRequestOptions & { id: string },
  ) => Promise<AxiosResponse<void>>
}

/**
 * This hook is used to delete an item and, optionally, update a related list query with the item removed.
 */
export default function useDeleteDetail<T extends { id: string }>({
  serviceFn,
  sideEffectQueryKeys,
  listQueryKey,
  onMutate,
  onError,
  onSettled,
  axiosOptions,
  ...options
}: UseDeleteDetailOptions & ServiceFn) {
  const queryClient = useQueryClient()

  return useMutation({
    sideEffectQueryKeys,
    mutationFn: (id) => {
      return serviceFn({ id, axiosOptions }).then(({ data }) => data)
    },
    onMutate: async (id) => {
      let previous: InfiniteData<PaginatedResponse<T>> | undefined = undefined
      if (listQueryKey) {
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        previous = queryClient.getQueryData(listQueryKey)

        // Update the list data in cache if it exists
        queryClient.setQueryData<InfiniteData<PaginatedResponse<T>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            const pages = old?.pages.map((page) => {
              const newPage = { ...page }
              newPage.results = page.results.filter(
                (result) => id !== result.id,
              )
              return newPage
            })
            return {
              ...old,
              pages,
            }
          },
        )
      }

      // Run user supplied onMutate function if available
      onMutate?.(id)

      // Return the previous data in case we need to reset it in onError
      return { previous }
    },
    onError: (error, id, context) => {
      // Run user supplied onError function if available
      onError?.(error, id, context)

      if (!listQueryKey || !context?.previous) return
      // Reset
      queryClient.setQueryData(listQueryKey, context?.previous)
    },
    onSettled: (...args) => {
      onSettled?.(...args)

      if (listQueryKey)
        queryClient.invalidateQueries({ queryKey: listQueryKey })
    },
    ...options,
  })
}
