import { ReactNode } from 'react'
import { Stack, Tooltip, Typography } from '@mui/material'

type LabelData = {
  name: string
  description?: string
}

type LabeledFieldValueProps = {
  label: LabelData
  value: ReactNode
  children?: ReactNode
}

export default function FieldInfoLabeledValue({
  label,
  value,
  children,
}: LabeledFieldValueProps) {
  return (
    <Stack sx={{ ml: 0, px: 0.5 }}>
      <Tooltip title={label.description} enterDelay={1000}>
        <Typography
          component="h3"
          variant="body2"
          sx={{ fontSize: 12, lineHeight: '12px', width: 'fit-content' }}
          noWrap
          color="text.secondary"
        >
          {label.name}
        </Typography>
      </Tooltip>
      <Typography
        component="p"
        color={value ? '' : 'gray'}
        sx={{
          display: 'flex',
          fontSize: 14,
          whiteSpace: 'pre-wrap',
          visibility: value === '' ? 'hidden' : 'visible',
        }}
      >
        {!value || typeof value === 'string' ? value || 'None' : value}
      </Typography>
      {children}
    </Stack>
  )
}
