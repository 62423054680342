import { createContext, ReactNode, useContext } from 'react'
import { Container, Alert, Button } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import { Document } from '@/types/documents'
import { useGetDocument } from '@/service-library/hooks/documents'
import { useDisabledDocumentsContext } from '@/components/validation/providers/DisabledDocumentsProvider'

type DocumentProviderValue = ReturnType<typeof useGetDocument> & {
  document?: Document
  documentIsAvailable?: boolean
  isDisabled?: boolean
}

const DocumentContext = createContext<DocumentProviderValue>(
  {} as DocumentProviderValue,
)

export const useDocumentContext = () => useContext(DocumentContext)

type DocumentProviderProps = {
  children: ReactNode
  query: DocumentProviderValue
  isDisabled?: boolean
}

export default function DocumentProvider({
  children,
  query,
  isDisabled,
}: DocumentProviderProps) {
  const { projectId } = useParams()
  const { disabledDocumentIds } = useDisabledDocumentsContext()
  const { error } = query

  if (error)
    return (
      <Container maxWidth="sm">
        <Alert
          severity="error"
          variant="outlined"
          sx={{ m: 4 }}
          action={
            <Button
              color="inherit"
              size="small"
              variant="text"
              component={Link}
              to={`/projects/${projectId}`}
            >
              Go to Project
            </Button>
          }
        >
          We were unable to load this document. It may have been removed or
          there may have been an issue while retrieving the data. Please try
          again later.
        </Alert>
      </Container>
    )

  return (
    <DocumentContext.Provider
      value={{
        ...query,
        isDisabled:
          isDisabled || disabledDocumentIds?.includes(query.document?.id || ''),
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}
