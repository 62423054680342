import { useMemo } from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material'
import { useGetProjectModelVersions } from '@/service-library/hooks/project-model-versions'
import { useGetProjectModels } from '@/service-library/hooks/project-models'
import SectionAccordion from './SectionAccordion'

type DocumentProjectModelVersionsProps = {
  documentId: string
  enabled: boolean
}

export default function DocumentProjectModelVersions({
  documentId,
  enabled,
}: DocumentProjectModelVersionsProps) {
  const { projectModelVersions, isLoading: versionsIsLoading } =
    useGetProjectModelVersions({
      filters: {
        limit: '1000',
        documents__id: documentId,
        fields__only: 'id,version,project_model_id',
      },
      enabled,
    })

  const { projectModels, isLoading: modelsIsLoading } = useGetProjectModels({
    filters: {
      id__in: projectModelVersions
        .map(({ project_model_id }) => project_model_id)
        .join(),
      limit: '1000',
      fields__only: 'id,name',
    },
    enabled: enabled && !!projectModelVersions.length,
  })

  const isLoading =
    versionsIsLoading || (!!projectModelVersions.length && modelsIsLoading)

  const projectModelsMap: Record<string, string> = useMemo(
    () =>
      projectModels.reduce(
        (acc, { id, name }) => ({ ...acc, [id]: [name] }),
        {},
      ),
    [projectModels],
  )

  const modelsWithVersion = useMemo(
    () =>
      projectModelVersions.map(({ id, version, project_model_id }) => ({
        id,
        name: projectModelsMap[project_model_id],
        version: `v${Number.isInteger(version) ? `${version}.0` : version}`,
      })),
    [projectModelVersions, projectModelsMap],
  )

  return (
    <SectionAccordion title="Model Prediction">
      <List dense disablePadding>
        {isLoading && (
          <>
            <ListItem disablePadding>
              <ListItemText
                primary={<Skeleton animation="wave" width="20%" />}
                secondary={<Skeleton animation="wave" width="20%" />}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={<Skeleton animation="wave" width="20%" />}
                secondary={<Skeleton animation="wave" width="20%" />}
              />
            </ListItem>
          </>
        )}
        {!isLoading &&
          (projectModelVersions.length ? (
            modelsWithVersion.map(({ id, name, version }) => {
              return (
                <ListItem key={id} disablePadding>
                  <ListItemText
                    primary={name}
                    secondary={version}
                    primaryTypographyProps={{
                      variant: 'body1',
                    }}
                  />
                </ListItem>
              )
            })
          ) : (
            <Typography color="text.secondary" variant="body2">
              No models were used to predict this document.
            </Typography>
          ))}
      </List>
    </SectionAccordion>
  )
}
