import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { GroupOption } from '@/types/metrics'

dayjs.extend(isSameOrBefore)

export const convertToDate = (date: string, isStartDate = true) => {
  const today = new Date()
  let value = date !== 'start' && date !== 'end' ? new Date(date) : today

  if (isStartDate) {
    if (date === 'start') {
      value.setDate(value.getDate() - 14)
    }
    value.setHours(0, 0, 0)
  } else {
    // We keep the time as current time for when the date is "today" so that we can keep track of the hours
    if (date === 'end' || dayjs().isSame(value, 'day')) {
      value = today
    } else {
      value.setHours(23, 59, 59)
    }
  }
  return value
}

export const formatDate = (date: Date) =>
  dayjs(date).format('YYYY-MM-DDTHH:mm:ss')

export const getNumberOfDaysBetweenDates = (start: Date, end: Date) => {
  const oneDay = 1000 * 60 * 60 * 24
  const diffInTime = end.getTime() - start.getTime()
  const diffInDays = Math.round(diffInTime / oneDay)
  return diffInDays
}

export const getEndDate = (startDate: Date, endDate: Date) => {
  if (getNumberOfDaysBetweenDates(startDate, endDate) > 6) {
    const newEndDate = new Date(startDate)
    newEndDate.setDate(newEndDate.getDate() + 6)
    newEndDate.setHours(23, 59, 59)
    return newEndDate
  }
  return null
}

export const getIsEnabled = (
  startDate: Date,
  endDate: Date,
  groupedBy: GroupOption,
) => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)

  let enabled =
    startDate < tomorrow && endDate >= startDate && endDate < tomorrow

  if (groupedBy === 'hour') {
    const dayAfterEndValidDate = new Date(startDate)
    dayAfterEndValidDate.setDate(dayAfterEndValidDate.getDate() + 7)
    dayAfterEndValidDate.setHours(0, 0, 0, 0)
    enabled = enabled && endDate < dayAfterEndValidDate
  }

  return enabled
}

export const getConvertedDatesByGroup = ({
  startDate,
  endDate,
  groupedBy,
}: {
  startDate?: Date
  endDate?: Date
  groupedBy: GroupOption
}) => {
  switch (groupedBy) {
    case 'month':
      return {
        startDate: startDate
          ? new Date(startDate.getFullYear(), startDate.getMonth(), 1)
          : undefined,
        endDate: endDate
          ? new Date(
              endDate.getFullYear(),
              endDate.getMonth() + 1,
              0,
              23,
              59,
              59,
            )
          : endDate,
      }
    case 'year':
      return {
        startDate: startDate ? new Date(startDate.getFullYear(), 0) : undefined,
        endDate: endDate
          ? new Date(endDate.getFullYear(), 11, 31, 23, 59, 59)
          : undefined,
      }
    default:
      return {
        startDate,
        endDate,
      }
  }
}
