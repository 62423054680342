import { DatePicker } from '@mui/x-date-pickers'
import { TextFieldProps } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'

type DateFieldProps = {
  onChange: (newValue: string) => void
  value: string
  label?: string
  disabled?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  minDate?: string
  maxDate?: string
  isInTable?: boolean
} & Omit<TextFieldProps, 'onChange' | 'value'>

/**
 * Each of the date-time-like fields expect the value to come in as a string.
 * It also needs to be sent back out as a string.
 * The MUI display components, however, expect some kind of date object,
 * based on what adapter is provided to the LocalizationProvider. So each
 * of the date-time-like fields needs to convert from a string and back.
 */

export default function DateField({
  onChange,
  value,
  label,
  disabled,
  inputRef,
  minDate,
  maxDate,
  isInTable,
  ...props
}: DateFieldProps) {
  let displayValue: Dayjs | null = null
  if (value) {
    displayValue = dayjs(value)
  }

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue === null) {
      onChange('')
    } else if (newValue?.isValid()) {
      onChange(newValue.format('YYYY-MM-DD'))
    }
  }

  return (
    <DatePicker<Dayjs>
      value={displayValue}
      onChange={handleChange}
      slotProps={{
        openPickerIcon: {
          fontSize: isInTable ? 'small' : 'medium',
        },
        openPickerButton: {
          size: 'small',
          tabIndex: -1,
        },
        textField: {
          autoComplete: 'off',
          ...props,
        },
      }}
      label={label}
      disabled={disabled}
      inputRef={inputRef}
      minDate={minDate ? dayjs(minDate) : undefined}
      maxDate={maxDate ? dayjs(maxDate) : undefined}
    />
  )
}
