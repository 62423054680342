import { Box, useTheme } from '@mui/material'
import { forwardRef } from 'react'
import { Handle, HandleProps } from 'reactflow'

type BaseExecutionHandleProps = HandleProps & {
  isClickStartHandle?: boolean | null
  style?: React.CSSProperties
}

function BaseExecutionHandle(
  { isClickStartHandle, ...props }: BaseExecutionHandleProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme()
  return (
    <Box
      ref={ref}
      sx={{
        '--handle-color': isClickStartHandle
          ? theme.palette.primary.main
          : 'white',
        '--handle-size': isClickStartHandle ? '1.2' : '1',
        '&:hover': {
          '--handle-color': theme.palette.primary.main,
          '--handle-size': '1.2',
        },
      }}
    >
      <Handle
        {...props}
        style={{
          position: 'unset',
          transition: '0.1s',
          backgroundColor: 'var(--handle-color)',
          transform: 'scale(var(--handle-size))',
          borderRadius: '2px',
          border: 'none',
          height: 12,
          width: 16,
          clipPath: 'polygon(0% 0%, 70% 0%, 100% 50%, 70% 100%, 0% 100%)',
          ...props.style,
        }}
      />
    </Box>
  )
}

export default forwardRef(BaseExecutionHandle)
