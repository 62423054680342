import { ReactNode } from 'react'
import { Tooltip, TooltipProps, Stack, Typography } from '@mui/material'
import useLocalStorage from '@/hooks/useLocalStorage'

type KeyboardShortcutsTooltipProps = {
  children: ReactNode
  keyboardShortcuts?: string[]
} & TooltipProps

export default function KeyboardShortcutsTooltip({
  children,
  title,
  keyboardShortcuts,
  ...props
}: KeyboardShortcutsTooltipProps) {
  const [themeMode] = useLocalStorage('pixydocs-theme-mode', 'dark')
  const noTooltip = title === '' && keyboardShortcuts?.length === 0
  return (
    <Tooltip
      title={
        noTooltip ? (
          ''
        ) : (
          <Stack>
            <Typography variant="caption" sx={{ textAlign: 'center' }}>
              {title}
            </Typography>
            {keyboardShortcuts?.map((shortcutText) => (
              <Typography
                key={shortcutText}
                variant="caption"
                color={themeMode === 'light' ? '#ffffffbe' : 'text.secondary'}
                textAlign="center"
                sx={{ fontSize: 10 }}
              >
                {shortcutText}
              </Typography>
            ))}
          </Stack>
        )
      }
      {...props}
    >
      <span>{children}</span>
    </Tooltip>
  )
}
