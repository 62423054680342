export function getOS(): 'windows' | 'macos' | 'linux' {
  const platform = window.navigator.platform
  if (platform === 'MacIntel') return 'macos'
  if (platform === 'Win32') return 'windows'
  return 'linux'
}

const altHotkeyMap = {
  linux: 'Alt',
  windows: 'Alt',
  macos: 'Option',
}

const modHotkeyMap = {
  linux: 'Ctrl',
  windows: 'Ctrl',
  macos: 'Cmd',
}

const metaHotkeyMap = {
  linux: 'Meta',
  windows: 'Win',
  macos: 'Cmd',
}

export const altHotKeyStr = altHotkeyMap[getOS()]

export const modHotKeyStr = modHotkeyMap[getOS()]

export const metaHotKeyStr = metaHotkeyMap[getOS()]
