import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Close, TripOrigin } from '@mui/icons-material'
import { ListItem, ListItemText, IconButton } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import {
  FormAutocomplete,
  FormCheckbox,
  FormTextField,
} from '@/components/forms'
import { dataTypeColorOptions } from './AddEditObjectNodeDialog'
import PropertiesTable from './PropertiesTable'

type PropertyDataTypeValueProps = {
  name: string
  index: number
  disableDelete: boolean
  onDelete: () => void
  isInSubTable?: boolean
}

export default function PropertyDataTypeValue({
  name,
  index,
  disableDelete,
  onDelete,
}: PropertyDataTypeValueProps) {
  const { register, getValues, setValue } = useFormContext()
  const hasProperties = getValues(`${name}.${index}.properties`)?.length > 0
  const [showSubTable, setShowSubTable] = useState(hasProperties)
  const propertyLevel =
    name.split('.').filter((word) => word === 'properties')?.length || 1
  const nestedLevel = propertyLevel - 1

  return (
    <>
      <Grid xs={3} sx={{ px: 1 }}>
        <FormAutocomplete
          autoFocus={true}
          name={`${name}.${index}.dataType`}
          options={dataTypeColorOptions}
          disableClearable
          label="Data Type"
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(value) => {
            const currentValue = Array.isArray(value) ? value[0] : value // This is just so TS doesn't yell at us (since we are not using multiple, it will not be an array)
            setShowSubTable(currentValue?.id === 'object')
            if (currentValue?.id !== 'object' && hasProperties) {
              setValue(`${name}.${index}.properties`, undefined)
            }
          }}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              dense
              disablePadding
              key={option.id}
              sx={{ columnGap: 1 }}
            >
              <TripOrigin htmlColor={option.color} sx={{ fontSize: 16 }} />
              <ListItemText primary={option.label} />
            </ListItem>
          )}
        />
      </Grid>

      <Grid
        xs={7}
        sx={{
          pl: 1,
          pr: nestedLevel ? `calc(8px + ${nestedLevel * 2.5}px)` : 1,
        }}
      >
        <FormTextField
          fullWidth
          label="Name"
          {...register(`${name}.${index}.label`, {
            validate: (value) => value?.trim().length > 0,
          })}
        />
      </Grid>
      <Grid xs={1} sx={{ textAlign: 'center' }}>
        <FormCheckbox
          name={`${name}.${index}.isArray`}
          formLabelSx={{
            mx: 0,
          }}
          sx={{
            ml: nestedLevel ? `-${nestedLevel * 2.75}px` : 0,
          }}
        />
      </Grid>
      <Grid xs={1} sx={{ textAlign: 'right' }}>
        <IconButton onClick={onDelete} disabled={disableDelete}>
          <Close />
        </IconButton>
      </Grid>
      {showSubTable && (
        <PropertiesTable name={`${name}.${index}.properties`} isInSubTable />
      )}
    </>
  )
}
