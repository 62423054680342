import PaginatedResponse from '@/types/paginated-response'
import useInfinitePaginatedQuery, {
  UseInfinitePaginationQueryOptions,
} from './useInfinitePaginationQuery'
import { AxiosResponse } from 'axios'
import { Filters, RequestOptions } from '../request-types'
import { QueryKey } from '@tanstack/react-query'

export type UseGetListOptions<T> = UseInfinitePaginationQueryOptions<
  PaginatedResponse<T>
> &
  RequestOptions & {
    /** If you have initial data that's an array, use this instead of initialData. */
    initialListData?: T[]
  }

type ServiceFn<T> = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (
    options: RequestOptions,
  ) => Promise<AxiosResponse<PaginatedResponse<T>>>
}

/**
 * This hook fetches a list of items from the backend.
 */
export default function useGetList<T>({
  queryKey,
  serviceFn,
  filters,
  axiosOptions,
  initialListData,
  paginator = 'cursor',
  ...options
}: UseGetListOptions<T> & ServiceFn<T> & { queryKey: QueryKey }) {
  const query = useInfinitePaginatedQuery<T>({
    queryKey,
    queryFn: ({ pageParam, signal }) => {
      const fullFilters: Filters = { ...filters, paginator }
      if (pageParam) fullFilters[paginator] = pageParam
      return serviceFn({
        filters: fullFilters,
        axiosOptions: { signal, ...axiosOptions },
      }).then(({ data }) => data)
    },
    paginator,
    initialData: initialListData
      ? {
          pageParams: [],
          pages: [
            {
              results: initialListData,
              next: null,
              previous: null,
            },
          ],
        }
      : undefined,
    ...options,
  } as UseInfinitePaginationQueryOptions<PaginatedResponse<T>>)

  return {
    ...query,
    queryKey,
  }
}
