import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { ProjectModelTrainingField } from '@/types/project-models'

const service = buildService<ProjectModelTrainingField>({
  pathCategory: 'project_model_training_fields',
})

export function useGetProjectModelTrainingField({
  id,
  ...options
}: UseGetDetailOptions<ProjectModelTrainingField>) {
  const query = useGetDetail<ProjectModelTrainingField>({
    id,
    queryKey: queryKeys.projectModelTrainingFields.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectModelTrainingField: query.data,
    ...query,
  }
}

export function useCreateProjectModelTrainingField(
  options: UseCreateDetailOptions<ProjectModelTrainingField> = {},
) {
  const mutation = useCreateDetail<ProjectModelTrainingField>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectModelTrainingField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectModelTrainingField(
  options: UseUpdateDetailOptions<ProjectModelTrainingField> = {},
) {
  const mutation = useUpdateDetail<ProjectModelTrainingField>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectModelTrainingField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectModelTrainingField(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<ProjectModelTrainingField>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectModelTrainingField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectModelTrainingFields({
  filters,
  ...options
}: UseGetListOptions<ProjectModelTrainingField> = {}) {
  const query = useGetList<ProjectModelTrainingField>({
    filters,
    queryKey: queryKeys.projectModelTrainingFields.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectModelTrainingFields: query.allData,
    ...query,
  }
}

export function useUpdateProjectModelTrainingFields(
  options: UseUpdateListOptions<ProjectModelTrainingField> = {},
) {
  const mutation = useUpdateList<ProjectModelTrainingField>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectModelTrainingFields: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectModelTrainingFields({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectModelTrainingField>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectModelTrainingFields: mutation.mutateAsync,
    ...mutation,
  }
}
