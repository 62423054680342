import {
  DocumentValidationMetricData,
  MetricLog,
  ValidationGroupedBy,
} from '@/types/metrics'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { getList } from '../request-wrappers'
import {
  formatDate,
  getConvertedDatesByGroup,
  getIsEnabled,
} from '@/utils/date-metrics'

const service = buildService<MetricLog>({
  pathCategory: 'metric_logs',
})

export function useGetMetricLog({
  id,
  ...options
}: UseGetDetailOptions<MetricLog>) {
  const query = useGetDetail<MetricLog>({
    id,
    queryKey: queryKeys.metricLogs.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    metricLog: query.data,
    ...query,
  }
}

export function useCreateMetricLog(
  options: UseCreateDetailOptions<MetricLog> = {},
) {
  const mutation = useCreateDetail<MetricLog>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createMetricLog: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateMetricLog(
  options: UseUpdateDetailOptions<MetricLog> = {},
) {
  const mutation = useUpdateDetail<MetricLog>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateMetricLog: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteMetricLog(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<MetricLog>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteMetricLog: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetMetricLogs({
  filters,
  ...options
}: UseGetListOptions<MetricLog> = {}) {
  const query = useGetList<MetricLog>({
    filters,
    queryKey: queryKeys.metricLogs.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    metricLogs: query.allData,
    ...query,
  }
}

export function useUpdateMetricLogs(
  options: UseUpdateListOptions<MetricLog> = {},
) {
  const mutation = useUpdateList<MetricLog>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateMetricLogs: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteMetricLogs({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<MetricLog>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteMetricLogs: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Get Document Validation Metrics
 * Get validation metrics divided by project model version or time period
 */
export function useGetDocumentValidationMetrics({
  projectId,
  groupedBy,
  startDate,
  endDate,
  projectModelId,
  enabled = true,
  ...options
}: UseGetListOptions<DocumentValidationMetricData> & {
  groupedBy: ValidationGroupedBy
  projectId?: string
  startDate?: Date
  endDate?: Date
  projectModelId?: string
}) {
  let mainFilters: {
    project_id: string
    grouped_by: ValidationGroupedBy
    start_date?: string
    end_date?: string
    project_model_id?: string
  } = {
    project_id: projectId as string,
    grouped_by: groupedBy,
  }
  if (groupedBy === 'model_version') {
    mainFilters = {
      ...mainFilters,
      project_model_id: projectModelId,
    }
  } else {
    if (startDate && endDate) {
      const { startDate: newStartDate, endDate: newEndDate } =
        getConvertedDatesByGroup({ startDate, endDate, groupedBy })
      mainFilters = {
        ...mainFilters,
        start_date: formatDate(newStartDate as Date),
        end_date: formatDate(newEndDate as Date),
      }
    }
  }
  const query = useGetList<DocumentValidationMetricData>({
    queryKey: queryKeys.metricLogs.list({
      filters: { ...mainFilters, ...options.filters },
    }),
    serviceFn: ({ filters, axiosOptions }) => {
      return getList<DocumentValidationMetricData>({
        url: '/v2/pd/metric_logs/document_validation_metrics',
        filters: { ...mainFilters, ...filters },
        axiosOptions,
      })
    },
    enabled:
      enabled &&
      !!projectId &&
      (groupedBy === 'model_version'
        ? !!projectModelId
        : getIsEnabled(startDate as Date, endDate as Date, groupedBy)),
    ...options,
  })

  return {
    validationMetrics: query.data?.pages[0],
    ...query,
  }
}
