import { Edit } from '@mui/icons-material'
import { Button, ButtonProps, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import useOverlay from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import CodeEditorDialog from '@/components/code-editor/CodeEditorDialog'

const RULE_SCRIPT_TEMPLATE = `# The function name should match the function name specified in the rule configuration.
# The function should accept a single argument and return a string.
# The first argument "value" is the value of the field as it is after being put through any rules that come before this one.

def run_rule(value):
    return f'mod-{value}'
`

type RuleCodeEditorProps = {
  onSave: (value: string) => void
  isOnlyEditableBySuperUser?: boolean
  color?: ButtonProps['color']
  script?: string
}

export default function RuleCodeEditor({
  onSave,
  isOnlyEditableBySuperUser,
  color,
  script,
}: RuleCodeEditorProps) {
  const codeEditorOverlay = useOverlay()
  const isSuperUser = useIsSuperUser()

  const [value, setValue] = useState(script || RULE_SCRIPT_TEMPLATE)

  function handleSave(value: string) {
    onSave(value)
    codeEditorOverlay.close()
  }

  useEffect(() => {
    setValue(script || RULE_SCRIPT_TEMPLATE)
  }, [script])

  return (
    <>
      <Tooltip
        title={
          isOnlyEditableBySuperUser && !isSuperUser
            ? 'Cannot edit this script. It is managed by Pixydocs engineers.'
            : ''
        }
        enterDelay={1000}
      >
        <div>
          <Button
            disabled={isOnlyEditableBySuperUser && !isSuperUser}
            color={color}
            onClick={codeEditorOverlay.open}
            startIcon={<Edit />}
            variant="text"
            sx={{ width: 'fit-content' }}
          >
            {isOnlyEditableBySuperUser && !isSuperUser ? 'Restricted' : 'Edit'}{' '}
            Script
          </Button>
        </div>
      </Tooltip>

      <CodeEditorDialog
        title="Python Script"
        overlay={codeEditorOverlay}
        value={value}
        setValue={setValue}
        onSave={handleSave}
      />
    </>
  )
}
