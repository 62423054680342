import {
  LogixBoard,
  LogixEdge,
  LogixHandle,
  LogixNode,
  LogixNodeType,
  LogixNodeTypeSection,
  LogixNodeUpdate,
  LogixRelation,
} from '@/types/logix'
import axios from './auth-v2-axios'
import PaginatedResponse from '@/types/paginated-response'
import { AxiosResponse } from 'axios'

export function getLogixBoard({ id }: { id: string }) {
  const searchParams = new URLSearchParams({
    fields__include: 'nodes,owned_handles,board_type',
    nodes__fields__include:
      'node_type,dyn_in_handles_relations,dyn_out_handles_relations,dyn_in_handles,dyn_out_handles,out_edges,in_edges,links,board_type',
    nodes__node_type__fields__include:
      'type_in_handles_relations,type_out_handles_relations,type_in_handles,type_out_handles',
    nodes__dyn_in_handles__fields__include:
      'type_out_nodes_ids,type_in_nodes_ids',
    nodes__dyn_out_handles__fields__include:
      'type_out_nodes_ids,type_in_nodes_ids',
    nodes__node_type__type_in_handles__fields__include:
      'type_out_nodes_ids,type_in_nodes_ids',
    nodes__node__type__type_in_handles__fields__include:
      'type_out_nodes_ids,type_in_nodes_ids',
    board_type__fields__include: 'start_node_type,end_node_type',
  })
  return axios.get<LogixBoard>(
    `/v2/logix/boards/${id}?${searchParams.toString()}`,
  )
}

export function getLogixEdges(boardId: string) {
  const searchParams = new URLSearchParams({
    limit: '1000',
    source_node__board_id: boardId,
  })
  return axios.get<PaginatedResponse<LogixEdge>>(
    `/v2/logix/edges?${searchParams.toString()}`,
  )
}

export function getLogixNodeTypes() {
  const searchParams = new URLSearchParams({
    limit: '1000',
    fields__include:
      'type_in_handles_relations,type_out_handles_relations,type_in_handles,type_out_handles,node_type_section',
  })
  return axios.get<PaginatedResponse<LogixNodeType>>(
    `/v2/logix/node_types?${searchParams.toString()}`,
  )
}

export function getLogixNodeTypeSections() {
  const searchParams = new URLSearchParams({
    limit: '1000',
  })
  return axios.get<PaginatedResponse<LogixNodeTypeSection>>(
    `/v2/logix/node_type_sections?${searchParams.toString()}`,
  )
}

export function createLogixNode(newNode: LogixNode) {
  return axios.post<LogixNode>(`/v2/logix/nodes`, newNode)
}

export function updateLogixNode(updatedNode: LogixNodeUpdate) {
  return axios.patch<LogixNodeUpdate, AxiosResponse<LogixNode>>(
    `/v2/logix/nodes/${updatedNode.id}`,
    updatedNode,
  )
}

export function bulkUpdateLogixNodes(updatedNodes: LogixNodeUpdate[]) {
  return axios.patch<LogixNodeUpdate[], AxiosResponse<LogixNode[]>>(
    `/v2/logix/nodes`,
    updatedNodes,
  )
}

export function bulkDeleteLogixNodes(nodeIds: string[]) {
  return axios.delete(`/v2/logix/nodes?id__in=${nodeIds.join(',')}`)
}

export function createLogixEdge(newEdge: LogixEdge) {
  return axios.post<LogixEdge>(`/v2/logix/edges`, newEdge)
}

export function bulkDeleteLogixEdges(edgeIds: string[]) {
  return axios.delete(`/v2/logix/edges?id__in=${edgeIds.join(',')}`)
}

export function createLogixHandle(newHandle: LogixHandle) {
  return axios.patch<LogixHandle>(`/v2/logix/handles`, newHandle)
}

export function updateLogixHandle(updatedHandle: LogixHandle) {
  return axios.patch<LogixHandle>(
    `/v2/logix/handles/${updatedHandle.id}`,
    updatedHandle,
  )
}

export function bulkUpdateCreateLogixHandles(updatedHandles: LogixHandle[]) {
  return axios.patch<LogixHandle[]>(`/v2/logix/handles`, updatedHandles)
}

export function bulkDeleteLogixHandles(handleIds: string[]) {
  return axios.delete<LogixHandle[]>(
    `/v2/logix/handles?id__in=${handleIds.join(',')}`,
  )
}

export function bulkUpdateCreateDynInRelations(relations: LogixRelation[]) {
  return axios.patch<LogixRelation[]>(
    `/v2/logix/node_dyn_in_handles_relations`,
    relations,
  )
}
export function bulkUpdateCreateDynOutRelations(relations: LogixRelation[]) {
  return axios.patch<LogixRelation[]>(
    `/v2/logix/node_dyn_out_handles_relations`,
    relations,
  )
}
