import { useEffect } from 'react'

type UseValidationNavListProps = {
  documentIds: string[]
  setDocumentId: (documentId: string, replace?: boolean) => void
  currentDocumentId?: string
  rowsCount: number
  fetchNextPage?: () => void
}

export default function useValidationNavList({
  documentIds,
  setDocumentId,
  currentDocumentId,
  rowsCount,
  fetchNextPage,
}: UseValidationNavListProps) {
  const selectedIndex = currentDocumentId
    ? documentIds.findIndex((id) => id === currentDocumentId)
    : -1

  const hasNextDocument = selectedIndex !== documentIds.length - 1
  const hasPreviousDocument = selectedIndex > 0

  function goToPreviousDocument() {
    if (hasPreviousDocument) {
      const nextDocId = documentIds[selectedIndex - 1]
      setDocumentId(nextDocId, false)
    }
  }

  function goToNextDocument() {
    if (hasNextDocument) {
      const nextDocId = documentIds[selectedIndex + 1]
      setDocumentId(nextDocId, false)
    }
  }

  useEffect(() => {
    // We fetch more documents, if we are at the second to last doc in the list, and there are more documents to fetch,
    // or if we have a current document that is not in the list, but only if we have less than 500 rows
    if (
      fetchNextPage &&
      (selectedIndex === documentIds.length - 2 ||
        (currentDocumentId && selectedIndex === -1 && rowsCount < 500))
    ) {
      fetchNextPage()
    }
  }, [
    currentDocumentId,
    documentIds.length,
    fetchNextPage,
    hasNextDocument,
    rowsCount,
    selectedIndex,
  ])

  return {
    nextDocumentId: hasNextDocument
      ? documentIds[selectedIndex + 1]
      : undefined,
    selectedIndex,
    goToNextDocument,
    goToPreviousDocument,
    hasNextDocument,
    hasPreviousDocument,
  }
}
