import { Checkbox } from '@mui/material'
import { HandleStructure } from '../types'
import { ChangeEvent } from 'react'
import { LogixHandle } from '@/types/logix'

type HandleCheckboxInputProps = {
  logixHandle: LogixHandle
  updateHandleManualValue: (
    updatedValue: HandleStructure['manualValue'],
  ) => void
}

export default function HandleCheckboxInput({
  logixHandle,
  updateHandleManualValue,
}: HandleCheckboxInputProps) {
  const { id, manual_value } = logixHandle

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    updateHandleManualValue(event.target.checked)
  }

  return (
    <Checkbox
      name={`checkbox-${id}`}
      size="small"
      sx={{ p: 0 }}
      value={manual_value}
      onChange={onChange}
      color="neutral"
    />
  )
}
