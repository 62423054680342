import { useEffect, useState } from 'react'
import { Button, ListItemButton } from '@mui/material'
import { Organization } from '@/types/organizations'
import useOverlay from '@/hooks/useOverlay'
import { getOrgName } from '@/utils/organization'
import LabeledData from '@/components/labeled-data/LabeledData'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import OrganizationTreeSearch from '@/components/organizations-tree/OrganizationTreeSearch'
import OrganizationTreeSearchSkeleton from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import OrganizationWithParent from './OrganizationWithParent'
import useOrganizationTypeName from './useOrganizationTypeName'

type OrganizationPickerWithDialogProps = {
  isFetching: boolean
  currentOrganization: Organization
  organizations: Organization[]
  rootTreeOrgId: string
  onSave: (newOrganization: Organization) => void
  allNodeIsIncluded?: boolean
  dense?: boolean
  disabledOrgIds?: string[]
  label?: string
  title?: string
  showParentOrg?: boolean
}

export default function OrganizationPickerWithDialog({
  isFetching,
  currentOrganization,
  organizations,
  rootTreeOrgId,
  onSave,
  allNodeIsIncluded,
  dense,
  disabledOrgIds,
  title = 'Choose Organization',
  showParentOrg = false,
}: OrganizationPickerWithDialogProps) {
  const organizationSelectOverlay = useOverlay()
  const [selectedOrgId, setSelectedOrgId] = useState(currentOrganization.id)
  const selectedOrg = organizations.find(
    (org) => org.id === selectedOrgId,
  ) as Organization

  const onClose = () => {
    setSelectedOrgId(currentOrganization.id)
    organizationSelectOverlay.close()
  }

  useEffect(() => {
    setSelectedOrgId(currentOrganization.id)
  }, [currentOrganization.id])

  const organizationTypeName =
    useOrganizationTypeName({
      organization: currentOrganization,
    }) || 'Organization'

  return (
    <>
      <ListItemButton
        sx={{
          px: 1,
          pb: 0.5,
          pt: 1,
          borderRadius: 2,
        }}
        dense
        onClick={organizationSelectOverlay.open}
      >
        {showParentOrg && currentOrganization.parent_org_id ? (
          <OrganizationWithParent
            organization={currentOrganization}
            organizations={organizations}
            dense={dense}
          />
        ) : (
          <LabeledData
            label={organizationTypeName}
            data={getOrgName(currentOrganization)}
            dense={dense}
          />
        )}
      </ListItemButton>
      {organizationSelectOverlay.isOpen && (
        <Dialog
          title={title}
          {...organizationSelectOverlay}
          onClose={onClose}
          maxWidth="md"
        >
          <DialogContent>
            {isFetching ? (
              <OrganizationTreeSearchSkeleton />
            ) : (
              <OrganizationTreeSearch
                allNodeIsIncluded={allNodeIsIncluded}
                disabledOrgIds={disabledOrgIds}
                organizations={organizations}
                rootTreeOrgId={rootTreeOrgId}
                selectedOrgId={selectedOrgId}
                selectedOrganization={selectedOrg}
                setSelectedOrgId={setSelectedOrgId}
              />
            )}
          </DialogContent>
          <DialogFooter>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={selectedOrgId === currentOrganization.id}
              onClick={() => {
                organizationSelectOverlay.close()
                onSave(selectedOrg)
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  )
}
