// Credit: https://github.com/uidotdev/usehooks/blob/main/index.js

import { useCallback, useEffect, useSyncExternalStore } from 'react'

// workaround for typescript error "Type 'T & Function' has no call signatures." https://github.com/microsoft/TypeScript/issues/37663#issuecomment-856961045
type Fn = (...args: unknown[]) => unknown
function getNexState<
  Provided,
  T = Provided extends Fn ? ReturnType<Provided> : Provided,
>(valueOrFn: Provided, store: string): T {
  return typeof valueOrFn === 'function' ? valueOrFn(store) : valueOrFn
}

const dispatchStorageEvent = (key: string, newValue?: string | null) => {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }))
}

const setSessionStorageItem = <T>(key: string, value: T) => {
  const stringifiedValue = JSON.stringify(value)
  window.sessionStorage.setItem(key, stringifiedValue)
  dispatchStorageEvent(key, stringifiedValue)
}

const removeSessionStorageItem = (key: string) => {
  window.sessionStorage.removeItem(key)
  dispatchStorageEvent(key, null)
}

const getSessionStorageItem = (key: string) => {
  return window.sessionStorage.getItem(key)
}

const useSessionStorageSubscribe = (callback: () => void) => {
  window.addEventListener('storage', callback)
  return () => window.removeEventListener('storage', callback)
}

const getSessionStorageServerSnapshot = () => {
  throw Error('useSessionStorage is a client-only hook')
}

export default function useSessionStorage<T>(key: string, initialValue: T) {
  const getSnapshot = () => getSessionStorageItem(key)

  const store = useSyncExternalStore(
    useSessionStorageSubscribe,
    getSnapshot,
    getSessionStorageServerSnapshot,
  )

  const setState = useCallback(
    (v: T | ((val: T) => T)) => {
      try {
        const nextState = getNexState(v, store ? JSON.parse(store) : store)
        if (nextState === undefined || nextState === null) {
          removeSessionStorageItem(key)
        } else {
          setSessionStorageItem(key, nextState)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
      }
    },
    [key, store],
  )

  useEffect(() => {
    if (
      getSessionStorageItem(key) === null &&
      typeof initialValue !== 'undefined'
    ) {
      setSessionStorageItem(key, initialValue)
    }
  }, [key, initialValue])

  return [store ? (JSON.parse(store) as T) : initialValue, setState] as const
}
