import ProjectWizard from './ProjectWizard'
import { useLocation, useSearchParams } from 'react-router-dom'
import usePermission from '@/hooks/usePermission'
import { CircularProgress, Typography } from '@mui/material'
import { Project } from '@/types/projects'
import NotFound from '@/pages/NotFound'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import { useGetProjects } from '@/service-library/hooks/projects'

export default function ProjectWizardPage() {
  const { rootOrganization } = useRootOrganization()
  const { hasEditingPermission } = usePermission()
  const [searchParams] = useSearchParams()
  const projectIdParam = searchParams.get('project_id')
  const { state } = useLocation()
  const { category } = state || {}

  const { projects = [], isLoading } = useGetProjects({
    refetchOnWindowFocus: false,
    filters: {
      org_id: rootOrganization.id,
      limit: '1000',
    },
  })

  const defaultProject: Project = {
    id: '',
    processing_active: false,
    setup_state: 'field_config',
    name: '',
    color: 'blue',
    org_id: rootOrganization.id,
    project_category_id: category?.id,
    project_category: category,
    source_folder_url: '',
  }

  const project = projects.find(
    (projectItem) => projectItem.id === projectIdParam,
  )

  const canAddEditProject = hasEditingPermission('edit_projects')

  if (!canAddEditProject) return <NotFound />

  return isLoading ? (
    <Typography align="center" p={8}>
      <CircularProgress />
    </Typography>
  ) : (
    <ProjectWizard
      projects={projects}
      existingProject={project || defaultProject}
    />
  )
}
