import { useParams, Link } from 'react-router-dom'
import { Button, Alert, Stack, CircularProgress } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useGetTeam } from '@/service-library/hooks/teams'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import TeamDetails from './TeamDetails'

export default function TeamPage() {
  const { id } = useParams()

  const { team, isLoading, queryKey } = useGetTeam({
    id: id as string,
    filters: {
      fields__include: 'visible_orgs_ids,users',
    },
    enabled: !!id,
  })

  return (
    <>
      {team && <Breadcrumb label={team.name} url={`/settings/teams/${id}`} />}

      {!isLoading && !team && (
        <Alert severity="error">
          Unable to load this team. You may not have access or this team does
          not exist.
        </Alert>
      )}

      {isLoading ? (
        <Stack alignItems="center" sx={{ p: 4 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Button
            sx={{ ml: '-4px', my: 2 }}
            startIcon={<ArrowBack />}
            component={Link}
            to="../"
            variant="text"
          >
            Back to Teams
          </Button>
          {team && <TeamDetails team={team} detailQueryKey={queryKey} />}
        </>
      )}
    </>
  )
}
