import { useMemo } from 'react'
import { DataListEntryCell } from '@/types/data-lists'
import { AutocompleteOption } from '@/components/validation/fields/AutocompleteField'
import { useGetDataListEntries } from '@/service-library/hooks/data-list-entries'
import { Filters } from '@/service-library/request-types'
import { useGetDataListEntryCells } from '@/service-library/hooks/data-list-entry-cells'

type UsePickerFieldDataOptions = {
  columnsMapping: Record<string, string>
  dataListColumnNames: string[]
  filterString: string
  useFilter: boolean
  value: AutocompleteOption | null
  dataListId?: string
  enabled?: boolean
  keepPreviousData?: boolean
}

export function getLabel(
  dataListEntryCells: DataListEntryCell[] | undefined,
  dataListColumnNames: string[],
  columnsMapping: Record<string, string>,
) {
  const entryValues = dataListColumnNames.map((columnName) => {
    const value =
      dataListEntryCells?.find(
        (cell) => columnsMapping[cell.data_list_column_id] === columnName,
      )?.data_list_entry_cell_values[0]?.value || ''
    return value
  })

  return entryValues.some((value) => value) ? entryValues.join(' - ') : ''
}

const noneOption = { id: 'None', label: 'None' }

export default function usePickerFieldData({
  columnsMapping,
  dataListId,
  dataListColumnNames,
  filterString,
  useFilter,
  value,
  enabled,
  keepPreviousData,
}: UsePickerFieldDataOptions) {
  const useCellsEndpoint = dataListColumnNames.length === 1

  const cellsFilters: Filters = {
    data_list_entry__data_list_id: dataListId || '',
    data_list_column__name: dataListColumnNames?.[0] || '',
    fields__include: 'data_list_entry_cell_values',
    limit: '200',
    value__icontains: useFilter ? filterString : undefined,
  }

  const entryCellsQuery = useGetDataListEntryCells({
    filters: cellsFilters,
    enabled: !!dataListId && enabled && useCellsEndpoint,
    keepPreviousData,
  })

  const entriesFilters: Filters = {
    data_list_id: dataListId || '',
    fields__include: 'data_list_entry_cells',
    data_list_entry_cells__fields__include: 'data_list_entry_cell_values',
    limit: '200',
    column_names__and__value__icontains: useFilter
      ? `${dataListColumnNames.join()}|${filterString}`
      : undefined,
  }

  const entriesQuery = useGetDataListEntries({
    filters: entriesFilters,
    enabled: !!dataListId && enabled && !useCellsEndpoint,
    keepPreviousData,
  })

  const query = useCellsEndpoint ? entryCellsQuery : entriesQuery

  const options = useMemo(() => {
    const tempOptions: { id: string; label: string }[] = []
    if (useCellsEndpoint) {
      const cells = entryCellsQuery.dataListEntryCells
      if (entryCellsQuery.isLoading) return []
      cells.forEach(({ data_list_entry_cell_values, data_list_entry_id }) => {
        if (
          !data_list_entry_cell_values.length ||
          // We filter out the saved value (when there is not a filter since we will add it to the beginning)
          (!useFilter && data_list_entry_id === value?.id)
        )
          return
        const cellValue = data_list_entry_cell_values[0]
        // We filter out cell values with empty values
        if (cellValue.value) {
          tempOptions.push({
            id: data_list_entry_id,
            label: cellValue.value,
          })
        }
      })
    } else {
      const entries = entriesQuery.dataListEntries
      if (entriesQuery.isLoading) return []

      entries.forEach(({ id, data_list_entry_cells }) => {
        // We filter out the saved value (when there is not a filter since we will add it to the beginning)
        if (!data_list_entry_cells.length || (!useFilter && id === value?.id))
          return

        const label = getLabel(
          data_list_entry_cells,
          dataListColumnNames,
          columnsMapping,
        )

        // We filter out entries with empty values
        if (label) {
          tempOptions.push({
            id,
            label,
          })
        }
      })
    }

    if (useFilter) return tempOptions

    if (!value) {
      return [noneOption, ...tempOptions]
    }

    return value.id === 'None'
      ? [value, ...tempOptions]
      : [noneOption, value, ...tempOptions]
  }, [
    columnsMapping,
    dataListColumnNames,
    entriesQuery.dataListEntries,
    entriesQuery.isLoading,
    entryCellsQuery.dataListEntryCells,
    entryCellsQuery.isLoading,
    useCellsEndpoint,
    useFilter,
    value,
  ])

  return {
    options,
    ...query,
  }
}
