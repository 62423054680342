import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import generateUuid from '@/utils/generate-uuid'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useCreateProjectCategory } from '@/service-library/hooks/project-categories'
import { QueryKey } from '@tanstack/react-query'

type AddProjectCategoryProps = {
  overlay: OverlayState
  listQueryKey: QueryKey
}

type FormValues = {
  name: string
}

export default function AddProjectCategoryDialog({
  overlay,
  listQueryKey,
}: AddProjectCategoryProps) {
  const { rootOrganization } = useRootOrganization()

  const { createProjectCategory } = useCreateProjectCategory({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to create category. Please try again later.')
    },
  })

  const methods = useForm<FormValues>({
    defaultValues: { name: '' },
  })

  const {
    formState: { isDirty, isSubmitSuccessful, isValid },
    register,
    reset,
  } = methods

  function handleCreateProjectCategory(values: FormValues) {
    values.name = values.name.trim()
    createProjectCategory({
      ...values,
      id: generateUuid(),
      sort_order: 1000001,
      org_id: rootOrganization.id,
    })
    overlay.close()
  }

  // From useForm docs: 'It's recommended to reset inside useEffect after submission.'
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ name: '' })
    }
  }, [isSubmitSuccessful, reset])

  return (
    <Dialog title="New Category" {...overlay} maxWidth="sm">
      <PixyDocsForm methods={methods} onSubmit={handleCreateProjectCategory}>
        <DialogContent>
          <FormTextField
            autoFocus
            fullWidth
            label="Name"
            required
            {...register('name', {
              validate: (value) => value.trim().length > 0,
            })}
          />
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
