import { Typography, Button } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { Document } from '@/types/documents'
import { useClearDocumentChips } from '@/service-library/hooks/documents'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useDisabledDocumentsContext } from './providers/DisabledDocumentsProvider'

type ClearAllChipsDialogProps = {
  document: Document
  overlay: OverlayState
}

export default function ClearAllChipsDialog({
  document,
  overlay,
}: ClearAllChipsDialogProps) {
  const { setDisabledDocumentIds } = useDisabledDocumentsContext()
  const { clearDocumentChips } = useClearDocumentChips({
    sideEffectQueryKeys: [
      queryKeys.documents.details(),
      queryKeys.documentChips.lists(),
      queryKeys.documentRowValues.lists(),
    ],
    onError: () => {
      showErrorSnackbar('Failed to clear all chips. Please try again later.')
    },
    onSettled: () => {
      setDisabledDocumentIds((prev) => prev.filter((id) => id !== document.id))
    },
  })
  return (
    <Dialog {...overlay} title="Clear All Chips" maxWidth="xs">
      <DialogContent>
        <Typography>
          You are about to clear all chips assigned to fields for this document.
          Are you sure?
        </Typography>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            setDisabledDocumentIds((prev) => [...prev, document.id])
            clearDocumentChips({ id: document.id })
            overlay.close()
          }}
          variant="text"
          color="error"
        >
          Clear All Chips
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
