import { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { GroupOption } from '@/types/metrics'
import { formatter, getTimeTicks } from '@/utils/chart-utils'
import BarChart, { BarChartProps } from './BarChart'

type TimeBarChartProps = {
  from: Date
  to: Date
  dataSum: number | undefined
  groupedBy: GroupOption
} & Omit<BarChartProps, 'xKey'>

export default function TimeBarChart({
  from,
  to,
  data,
  dataSum,
  groupedBy,
  yKey,
  height = 420,
  margin,
  ...props
}: TimeBarChartProps) {
  const includeYear = useMemo(
    () => from.getFullYear() !== to.getFullYear(),
    [from, to],
  )
  const timeScaleTicks = useMemo(
    () => getTimeTicks(from, to, groupedBy, includeYear),
    [from, groupedBy, includeYear, to],
  )

  return (
    <BarChart
      data={data}
      xKey="date"
      yKey={yKey}
      height={height}
      noData={dataSum === 0}
      axisBottom={{
        format: (value) => {
          const formattedDate = formatter(value, groupedBy, includeYear)
          return data.length <= 140 ||
            (data.length > 140 && timeScaleTicks.includes(formattedDate))
            ? formattedDate
            : ''
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 90,
      }}
      axisLeft={{
        format: (e) => (Math.floor(e) === e ? e : ''),
        legendOffset: -50,
      }}
      margin={{
        bottom: groupedBy === 'hour' ? 110 : 80,
        ...margin,
      }}
      tooltipContent={(data) => (
        <Stack sx={{ p: 1 }}>
          <Typography component="p" noWrap variant="caption">
            <b>Date:</b>{' '}
            {formatter(data.date as string, groupedBy, includeYear)}
          </Typography>
          <Typography component="p" noWrap variant="caption">
            <b>Count:</b> {data[yKey]}
          </Typography>
        </Stack>
      )}
      {...props}
    />
  )
}
