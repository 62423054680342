import { ProjectModelVersion } from '@/types/project-models'
import { Box, useTheme } from '@mui/material'

type AccuracyColorBarProps = {
  currentVersion: ProjectModelVersion
}

const colorMap: Record<number, string> = {
  0.75: 'yellow',
  0.5: 'orange',
  0.25: 'red',
}

export default function AccuracyColorBar({
  currentVersion,
}: AccuracyColorBarProps) {
  const theme = useTheme()

  function getColor(value: number) {
    if (isNaN(value)) value = 0
    // Find the appropriate color
    let barColor = theme.palette.green.main
    Object.entries(colorMap).forEach(([threshold, color]) => {
      if (value < parseFloat(threshold)) {
        // @ts-expect-error -- TS doesn't understand that it is a valid color
        barColor = theme.palette[color].main
      }
    })
    return barColor
  }

  return (
    <Box
      sx={{
        background: getColor(parseFloat(currentVersion.accuracy)),
        width: currentVersion.accuracy
          ? `${Math.floor(+currentVersion.accuracy * 100)}%`
          : 0,
        height: 8,
        borderRadius: '0 8px 8px 0',
      }}
    />
  )
}
