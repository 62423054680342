import { WorkflowStateEvent } from '@/types/workflow-state-events'

export function getWorkflowStateEventGroups(
  workflowStateEvents: WorkflowStateEvent[],
): {
  systemEvents: WorkflowStateEvent[]
  customEvents: WorkflowStateEvent[]
} {
  return workflowStateEvents.reduce<{
    systemEvents: WorkflowStateEvent[]
    customEvents: WorkflowStateEvent[]
  }>(
    (acc, event) => {
      if (event.code === 'on_enter' || event.code === 'on_exit') {
        acc.systemEvents.push(event)
      } else {
        acc.customEvents.push(event)
      }
      return acc
    },
    { systemEvents: [], customEvents: [] },
  )
}
