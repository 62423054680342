import { SvgIconComponent } from '@mui/icons-material'
import { IconProps, Tooltip } from '@mui/material'
import { ReactNode } from 'react'

type DocumentIndicatorProps = {
  Icon: SvgIconComponent
  message: ReactNode
  color?: IconProps['color']
}

export default function DocumentIndicator({
  Icon,
  message,
  color,
}: DocumentIndicatorProps) {
  return (
    <Tooltip title={message}>
      <Icon fontSize="small" color={color} />
    </Tooltip>
  )
}
