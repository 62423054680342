import axios from 'axios'
import { Auth } from 'aws-amplify'
import { CognitoUserSession } from 'amazon-cognito-identity-js'

// There are some endpoints we never call, but we'll keep them just in case.
// https://github.com/zerapix/py-zkit/blob/main/zkit/conf.py#L36
const localPortsForPathPrefixes = {
  '/v1/': 8001, // pixyservice-api
  '/v2/pd/export/': 8002, // pixydocs-exporter
  '/v2/pd/': 8000, // pixydocs-api (docsapi app - Core API / Django)
  '/v2/pd-rules/': 8003, // pixydocs-rules
  '/v2/pd-tray/': 8004, // pd-tray
  '/v2/pd-ocr/': 8005, // pd-ocr
  '/v2/user/': 8006, // auth-manager
  '/v2/notify/': 8007, // ws-api-gw
  '/v2/pd-predict/': 8008, // pd-predict
  '/v2/dr-chrono/': 8009, // dr-chrono
  '/v2/logix/': 8000, // pixydocs-api (logix_api app)
  '/v2/pd-cloner/': 8010, // pd-cloner
  '/v2/pd-workflow/': 8011, // pd-workflow
  '/v2/run-py/': 8012, // run-py
  '/v2/pd-activity/': 8014, // pd-activity
}

function getBaseUrl(url: string) {
  const base = 'http://localhost:'
  let baseUrl = ''
  for (const [path, portNumber] of Object.entries(localPortsForPathPrefixes)) {
    if (url.startsWith(path)) {
      baseUrl = `${base}${portNumber}`
      break
    }
  }
  return baseUrl || import.meta.env.VITE_API_GATEWAY
}

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_GATEWAY,
})

// This sets up axios to always get the user's access token prior to a request and attach it to the request Authorization header
instance.interceptors.request.use(async function (config) {
  const data = (await Auth.currentSession()) as CognitoUserSession
  const accessToken = data.getAccessToken()
  const authorization = accessToken ? `Bearer ${accessToken.getJwtToken()}` : ''
  if (config.headers) {
    config.headers.Authorization = authorization
  } else {
    config.headers = {
      Authorization: authorization,
    }
  }

  if (config.url && import.meta.env.VITE_ENVIRONMENT === 'local') {
    // Dynamically set baseURL based on the URL of the API call
    config.baseURL = getBaseUrl(config.url)
  }

  return config
})

export default instance
