import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { QueryKey } from '@tanstack/react-query'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { Button, Stack } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { useForm } from 'react-hook-form'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import { DataList } from '@/types/data-lists'
import { showErrorSnackbar } from '@/utils/snackbars'
import {
  useCreateDataListWithDefaults,
  useUpdateDataList,
} from '@/service-library/hooks/data-lists'
import generateUuid from '@/utils/generate-uuid'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import { useGetDataListTypes } from '@/service-library/hooks/data-list-types'

type AddEditDataListDialogProps = {
  overlay: OverlayState
  dataList?: DataList
  rootDetailQueryKey?: QueryKey
}

type FormValues = {
  name: string
}

export default function AddEditDataListDialog({
  overlay,
  dataList,
  rootDetailQueryKey,
}: AddEditDataListDialogProps) {
  const navigate = useNavigate()
  const { rootOrganization } = useRootOrganization()

  const { createDataList } = useCreateDataListWithDefaults({
    onError: () => {
      showErrorSnackbar('Unable to create list. Please try again later.')
    },
    onSuccess: (newList) => {
      navigate(`/settings/lists/${newList.id}`)
    },
  })
  const { updateDataList } = useUpdateDataList({
    detailQueryKey: rootDetailQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update list. Please try again later.')
    },
  })

  const defaultValues = useMemo(
    () => ({ name: dataList?.name || '' }),
    [dataList?.name],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const {
    formState: { isDirty, isValid },
    register,
    reset,
  } = methods

  const { dataListTypes } = useGetDataListTypes()
  const listType = dataListTypes?.find(
    (type: { name: string }) => type.name === 'Custom',
  )

  function handleCreateOrUpdateList(values: FormValues) {
    values.name = values.name.trim()
    if (dataList && dataList.name !== values.name) {
      updateDataList({ ...dataList, ...values })
    } else if (!dataList) {
      createDataList({
        id: generateUuid(),
        org_id: rootOrganization.id,
        data_list_type_id: listType?.id || '',
        ...values,
      } as DataList)
    }
    overlay.close()
  }

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  return (
    <Dialog
      title={`${dataList?.name ? 'Edit' : 'New'} List`}
      {...overlay}
      maxWidth="xs"
    >
      <PixyDocsForm methods={methods} onSubmit={handleCreateOrUpdateList}>
        <DialogContent>
          <Stack spacing={4}>
            <FormTextField
              autoFocus
              fullWidth
              label="Name"
              required
              {...register('name', {
                validate: (value) => value.trim().length > 0,
              })}
            />
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
