import { useEffect } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { TextField, MenuItem } from '@mui/material'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import constructQueryParams from './construct-query-params'
import useForeignKeyListEndpoint from './useForeignKeyListEndpoint'
import { PropertyFieldProps } from './PropertyField'

type PropertySelectProps = PropertyFieldProps

const commonCss = {
  content: '""',
  transition: 'all 0.1s',
  width: 4,
  display: 'inline-block',
  height: '90%',
  position: 'absolute',
  top: '5%',
  left: 0,
}

// https://react-hook-form.com/api/usewatch/
// This will return all values from the form, but in a stateful way.
// Whenever a form value changes, it will make PropertySelect rerender.
// This is needed so we can check for values from other fields this one depends on.
const useFormValues = () => {
  const { getValues } = useFormContext()
  return {
    ...useWatch(), // subscribe to form value updates
    ...getValues(), // always merge with latest form values
  }
}

export default function PropertySelectFromForeignKey({
  propertyId,
  property,
  required,
  variant,
  dependencyValues,
}: PropertySelectProps) {
  const { field } = useController({ name: propertyId, rules: { required } })
  const { formState, resetField } = useFormContext()
  const getThemeColor = useGetThemeColor()

  const { errors } = formState

  const { display, foreignKey } = property

  const { listEndpoint, listQueryParams = {} } = foreignKey || {}

  const formValues = useFormValues()

  const { queryParams, hasAllDependencies } = constructQueryParams({
    queryParams: listQueryParams,
    otherDependencyValues: dependencyValues,
    values: formValues,
  })

  const { data = [], isLoading } = useForeignKeyListEndpoint({
    url: `${listEndpoint}?${queryParams?.toString()}&limit=1000`,
    enabled: hasAllDependencies && listEndpoint,
  })

  const currentValue = data.find((item) => item.id === field.value)

  useEffect(() => {
    // If the selected item no longer exists (i.e. the data set changes), reset the value
    // or if no value is selected and autoSelect is enabled, select the first item
    if (data?.length > 0 && !currentValue) {
      resetField(propertyId, {
        defaultValue: display.autoSelect ? data[0].id : '',
      })
    }
  }, [data, propertyId, currentValue, resetField, display.autoSelect])

  return (
    <TextField
      required={required}
      error={!!errors[propertyId]}
      disabled={isLoading || !hasAllDependencies}
      label={display?.label || 'MISSING LABEL'}
      InputLabelProps={{ shrink: true }}
      select
      helperText={display.description}
      SelectProps={{
        sx: display.hasColor
          ? {
              '&::before': {
                background: getThemeColor(currentValue?.color),
                borderRadius: 2,
                ...commonCss,
              },
            }
          : undefined,
        value: currentValue?.id || '',
      }}
      variant={variant}
      {...field}
    >
      {data?.length > 0 ? (
        data.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            sx={
              display.hasColor
                ? {
                    '&::before': {
                      background: getThemeColor(item.color),
                      ...commonCss,
                    },
                  }
                : undefined
            }
          >
            {item.name || item.code}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No items available.</MenuItem>
      )}
    </TextField>
  )
}
