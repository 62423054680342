import { ProjectGrid } from '@/types/projects'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<ProjectGrid>({
  pathCategory: 'project_grids',
})

// We only have GET hooks here since we never update, delete, or create project grids.

export function useGetProjectGrid({
  id,
  ...options
}: UseGetDetailOptions<ProjectGrid>) {
  const query = useGetDetail<ProjectGrid>({
    id,
    queryKey: queryKeys.projectGrids.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectGrid: query.data,
    ...query,
  }
}

export function useGetProjectGrids({
  filters,
  ...options
}: UseGetListOptions<ProjectGrid> = {}) {
  const query = useGetList<ProjectGrid>({
    filters,
    queryKey: queryKeys.projectGrids.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectGrids: query.allData,
    ...query,
  }
}
