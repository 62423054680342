import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'

type SRCardSectionProps = {
  children: ReactNode
  title: string
}

export default function SRCardSection({ children, title }: SRCardSectionProps) {
  return (
    <Box sx={{ borderRadius: 2, py: 2, px: 3, background: 'white' }}>
      <Typography variant="subtitle1" sx={{ pb: 1 }}>
        <b>{title}</b>
      </Typography>
      {children}
    </Box>
  )
}
