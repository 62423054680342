import { ProjectModel } from '@/types/project-models'
import { Dimensions, useNodes } from '@xyflow/react'
import { useEffect, useState } from 'react'

type useNodeSizesProps = {
  projectModel: ProjectModel
}

export default function useModelNodeMeasurements({
  projectModel,
}: useNodeSizesProps) {
  const nodes = useNodes()

  const [modelNodeMeasurements, setModelNodeMeasurements] =
    useState<Dimensions>({
      height: 0,
      width: 0,
    })

  useEffect(() => {
    if (!modelNodeMeasurements.width) {
      const targetNode = nodes.find((node) => node.id === projectModel.id)
      if (targetNode) {
        setModelNodeMeasurements({
          height: targetNode.measured?.height || 0,
          width: targetNode.measured?.width || 0,
        })
      }
    }
  }, [modelNodeMeasurements.width, nodes, projectModel.id])

  return modelNodeMeasurements
}
