import { Dispatch, SetStateAction, useState } from 'react'
import {
  CheckCircleOutline,
  DeleteOutline,
  OpenInFull,
} from '@mui/icons-material'
import { Box, Button, Card, IconButton, Stack } from '@mui/material'
import { DocumentPage } from '@/types/documents'
import useOverlay from '@/hooks/useOverlay'
import { Dialog, DialogFooter } from '@/components/dialog'
import { TILE_WIDTH } from './SplitDocumentDialog'

export default function ImageCard({
  page,
  pagesToDelete,
  setPagesToDelete,
}: {
  page: DocumentPage
  pagesToDelete: number[]
  setPagesToDelete: Dispatch<SetStateAction<number[]>>
}) {
  const fullImageOverlay = useOverlay()
  const [showIcons, setShowIcons] = useState(false)
  const pageIsToBeDeleted = pagesToDelete.includes(page.page_number)
  return (
    /* Display flex so it will get rid of default spacing pixels underneath the image */
    <Card
      sx={{ display: 'flex', position: 'relative' }}
      onMouseEnter={() => {
        !showIcons && setShowIcons(true)
      }}
      onMouseLeave={() => {
        !pageIsToBeDeleted && setShowIcons(false)
      }}
    >
      <img
        loading="lazy"
        src={page.image_urls.thumbnail}
        onError={(event) => {
          const target = event.target as HTMLImageElement
          target.src = page.image_urls.full
          target.onerror = null
        }}
        style={{
          width: TILE_WIDTH,
          filter: pageIsToBeDeleted ? 'brightness(60%)' : undefined,
        }}
      />
      {showIcons && (
        <Stack
          direction="row"
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
          }}
        >
          <IconButton color="primary" onClick={fullImageOverlay.open}>
            <OpenInFull />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              setPagesToDelete((prev) => {
                return pageIsToBeDeleted
                  ? prev.filter((p) => p !== page.page_number)
                  : [...prev, page.page_number]
              })
            }}
          >
            {pageIsToBeDeleted ? <CheckCircleOutline /> : <DeleteOutline />}
          </IconButton>
        </Stack>
      )}
      <Dialog
        title={`Page ${page.page_number}`}
        {...fullImageOverlay}
        onClose={() => {
          fullImageOverlay.close()
          setShowIcons(false)
        }}
        fullScreen
      >
        <Box
          sx={{
            background: 'black',
            height: 'calc(100vh - 128px)', // 128px is the height of the dialog header and footer
            textAlign: 'center',
          }}
        >
          <img
            src={page.image_urls.full}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
          />
        </Box>

        <DialogFooter>
          <Button
            onClick={() => {
              fullImageOverlay.close()
              setShowIcons(false)
            }}
          >
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    </Card>
  )
}
