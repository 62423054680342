import {
  AuthCredential,
  EntrataSecrets,
  OrgCredential,
} from '@/types/credentials'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { createDetail, updateDetail } from '../request-wrappers'

const service = buildService<OrgCredential>({
  pathCategory: 'org_credentials',
})

export function useGetOrgCredential({
  id,
  ...options
}: UseGetDetailOptions<OrgCredential>) {
  const query = useGetDetail<OrgCredential>({
    id,
    queryKey: queryKeys.orgCredentials.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    orgCredential: query.data,
    ...query,
  }
}

export function useCreateOrgCredential(
  options: UseCreateDetailOptions<OrgCredential> = {},
) {
  const mutation = useCreateDetail<OrgCredential>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createOrgCredential: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateOrgCredential(
  options: UseUpdateDetailOptions<OrgCredential> = {},
) {
  const mutation = useUpdateDetail<OrgCredential>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateOrgCredential: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteOrgCredential(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<OrgCredential>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteOrgCredential: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetOrgCredentials({
  filters,
  ...options
}: UseGetListOptions<OrgCredential> = {}) {
  const query = useGetList<OrgCredential>({
    filters,
    queryKey: queryKeys.orgCredentials.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    orgCredentials: query.allData,
    ...query,
  }
}

export function useUpdateOrgCredentials(
  options: UseUpdateListOptions<OrgCredential> = {},
) {
  const mutation = useUpdateList<OrgCredential>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateOrgCredentials: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteOrgCredentials({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<OrgCredential>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteOrgCredentials: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Custom Hook: Create DrChrono Auth Tokens
 * Set DrChrono client ID and secret for the org credential.
 */
export function useCreateDrChronoAuthTokens(
  options: UseCreateDetailOptions<Partial<AuthCredential>>,
) {
  const mutation = useCreateDetail<Partial<AuthCredential>>({
    serviceFn: ({ filters, item, axiosOptions }) => {
      return createDetail<Partial<AuthCredential>, AuthCredential>({
        url: '/v2/dr-chrono/org_credential_secrets',
        item,
        filters: {
          redirect_path: 'credential-authenticated',
          ...filters,
        },
        axiosOptions,
      })
    },
    ...options,
  })

  return {
    createDrChronoAuthTokens: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Custom Hook: Update DrChrono Auth Tokens
 * Update DrChrono client ID and secret for the org credential.
 */
export function useUpdateDrChronoAuthTokens(
  options: UseUpdateDetailOptions<Partial<AuthCredential> & { id: string }>,
) {
  const mutation = useUpdateDetail<Partial<AuthCredential> & { id: string }>({
    serviceFn: ({ filters, item, axiosOptions }) => {
      return updateDetail<Partial<AuthCredential> & { id: string }>({
        url: `/v2/dr-chrono/org_credential_secrets/${item.id}`,
        item,
        filters: {
          redirect_path: 'credential-authenticated',
          ...filters,
        },
        axiosOptions,
      })
    },
    ...options,
  })

  return {
    updateDrChronoAuthTokens: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Custom Hook: Create Entrata Auth Tokens
 * Set/Edit Entrata bearer token for the org credential.
 */
export function useCreateOrUpdateEntrataAuthTokens(
  options: UseCreateDetailOptions<EntrataSecrets>,
) {
  const mutation = useCreateDetail<EntrataSecrets>({
    serviceFn: ({ filters, item }) => {
      return createDetail<EntrataSecrets>({
        url: '/v2/relay-entrata/credential_tokens',
        item,
        filters,
      })
    },
    ...options,
  })

  return {
    createOrUpdateEntrataAuthTokens: mutation.mutateAsync,
    ...mutation,
  }
}
