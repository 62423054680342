import { useGetProjectModel } from '@/service-library/hooks/project-models'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Typography } from '@mui/material'
import PrimaryTextWrapper from './PrimaryTextWrapper'

type RunPredictionModelDisplayProps = {
  action: WorkflowStateAction
}

export default function RunPredictionModelDisplay({
  action,
}: RunPredictionModelDisplayProps) {
  const { projectModel, isLoading } = useGetProjectModel({
    id: action.params.model_id,
    filters: {
      fields__only: 'id,name',
    },
  })

  if (isLoading) return null

  return (
    <Typography variant="body2" color="text.secondary">
      Run the <PrimaryTextWrapper>{projectModel?.name}</PrimaryTextWrapper>{' '}
      prediction model.
    </Typography>
  )
}
