import { BulkResponse } from '@/types/bulk-response'
import PaginatedResponse from '@/types/paginated-response'
import axios from './auth-v2-axios'
import {
  Filters,
  RequestOptions,
  CreateDetailRequestOptions,
  UpdateDetailRequestOptions,
  UpdateListRequestOptions,
  DeleteRequestOptions,
} from './request-types'
import { AxiosResponse } from 'axios'

type PureFilters = Record<string, string>
type URLOption = { url: string }

function purifyFilters(filters?: Filters) {
  if (!filters) return undefined
  const pureFilters = { ...filters }
  Object.keys(pureFilters).forEach((key) => {
    if (!pureFilters[key]) {
      delete pureFilters[key]
    }
  })
  return pureFilters as PureFilters
}

function filtersToQueryString(filters?: Filters) {
  if (!filters) return ''
  return new URLSearchParams(purifyFilters(filters))
    .toString()
    .replace('%21=', '!=') // URLSearchParams encodes the string when using toString, we so need to put != back
}

export function getDetail<T>({
  url,
  filters,
  axiosOptions,
}: URLOption & RequestOptions) {
  const searchParams = filtersToQueryString(filters)
  return axios.get<T>(`${url}?${searchParams}`, axiosOptions)
}

export function createDetail<T, ReturnT = T>({
  url,
  item,
  filters,
  axiosOptions,
}: URLOption & CreateDetailRequestOptions<T>) {
  const searchParams = filtersToQueryString(filters)
  return axios.post<T, AxiosResponse<ReturnT>>(
    `${url}?${searchParams}`,
    item,
    axiosOptions,
  )
}

export function updateDetail<T extends { id: string }>({
  url,
  item,
  filters,
  axiosOptions,
}: URLOption & UpdateDetailRequestOptions<T>) {
  const searchParams = filtersToQueryString(filters)
  return axios.patch<T>(`${url}?${searchParams}`, item, axiosOptions)
}

export function updateDetailPut<T extends { id: string }>({
  url,
  item,
  filters,
  axiosOptions,
}: URLOption & UpdateDetailRequestOptions<T>) {
  const searchParams = filtersToQueryString(filters)
  return axios.put<T>(`${url}?${searchParams}`, item, axiosOptions)
}

export function getList<T>({
  url,
  filters = {},
  axiosOptions,
}: URLOption & RequestOptions) {
  const searchParams = filtersToQueryString(filters)
  return axios.get<PaginatedResponse<T>>(`${url}?${searchParams}`, axiosOptions)
}

export function createList<T extends { id: string }>({
  url,
  items,
  filters,
  axiosOptions,
}: URLOption & UpdateListRequestOptions<T>) {
  const searchParams = filtersToQueryString(filters)
  return axios.post<T[], AxiosResponse<BulkResponse<T>>>(
    `${url}?${searchParams}`,
    items,
    axiosOptions,
  )
}

export function updateList<T extends { id: string }>({
  url,
  items,
  filters,
  axiosOptions,
}: URLOption & UpdateListRequestOptions<T>) {
  const searchParams = filtersToQueryString(filters)
  return axios.patch<T[], AxiosResponse<BulkResponse<T>>>(
    `${url}?${searchParams}`,
    items,
    axiosOptions,
  )
}

export function deleteCall({
  url,
  axiosOptions,
  filters,
}: URLOption & DeleteRequestOptions) {
  const searchParams = filtersToQueryString(filters)
  return axios.delete<void>(`${url}?${searchParams}`, axiosOptions)
}
