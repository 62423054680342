import CheckIcon from '@mui/icons-material/Check'
import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

export type SavingIndicatorProps = {
  saving: boolean
}

export default function SavingIndicator({ saving }: SavingIndicatorProps) {
  const [show, setShow] = useState(false)

  useEffect(() => {
    saving && setShow(true)
  }, [saving])

  return show ? (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {!saving && <CheckIcon fontSize="inherit" htmlColor="gray" />}
      <Typography variant="caption" color="gray">
        {saving ? 'Saving...' : 'Saved'}
      </Typography>
    </Stack>
  ) : (
    <></>
  )
}
