import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'

type ImageZoomPanCenterContextValue = {
  centeredScaleState: [number, Dispatch<SetStateAction<number>>]
  centeredPositionXState: [number, Dispatch<SetStateAction<number>>]
}

type ImageZoomPanCenterProviderProps = {
  children: ReactNode
}

const ImageZoomPanCenterContext = createContext<ImageZoomPanCenterContextValue>(
  [] as unknown as ImageZoomPanCenterContextValue,
)

export const useImageZoomPanCenterContext = () =>
  useContext(ImageZoomPanCenterContext)

export default function ImageZoomPanCenterProvider({
  children,
}: ImageZoomPanCenterProviderProps) {
  const centeredScaleState = useState(1)
  const centeredPositionXState = useState(0)

  return (
    <ImageZoomPanCenterContext.Provider
      value={{
        centeredScaleState,
        centeredPositionXState,
      }}
    >
      {children}
    </ImageZoomPanCenterContext.Provider>
  )
}
