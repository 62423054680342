import { DocumentRowValue } from '@/types/documents'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { createDetail, updateDetail, updateList } from '../request-wrappers'

const service = buildService<DocumentRowValue>({
  pathCategory: 'document_row_values',
})

export function useGetDocumentRowValue({
  id,
  ...options
}: UseGetDetailOptions<DocumentRowValue>) {
  const query = useGetDetail<DocumentRowValue>({
    id,
    queryKey: queryKeys.documentRowValues.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    documentRowValue: query.data,
    ...query,
  }
}

export function useCreateDocumentRowValue(
  options: UseCreateDetailOptions<DocumentRowValue> = {},
) {
  const mutation = useCreateDetail<DocumentRowValue>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDocumentRowValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDocumentRowValue(
  options: UseUpdateDetailOptions<DocumentRowValue> = {},
) {
  const mutation = useUpdateDetail<DocumentRowValue>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDocumentRowValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentRowValue(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<DocumentRowValue>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDocumentRowValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDocumentRowValues({
  filters,
  ...options
}: UseGetListOptions<DocumentRowValue> = {}) {
  const query = useGetList<DocumentRowValue>({
    filters,
    queryKey: queryKeys.documentRowValues.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    documentRowValues: query.allData,
    ...query,
  }
}

export function useUpdateDocumentRowValues(
  options: UseUpdateListOptions<DocumentRowValue> = {},
) {
  const mutation = useUpdateList<DocumentRowValue>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDocumentRowValues: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentRowValues({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DocumentRowValue>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDocumentRowValues: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Create Document Row Value With Cleanup
 * Used to create a document row values with additional cleanup on backend.
 */
export function useCreateDocumentRowValueWithCleanup({
  workflowId,
  ...options
}: UseCreateDetailOptions<DocumentRowValue> & { workflowId: string }) {
  const mutation = useCreateDetail<DocumentRowValue>({
    serviceFn: (serviceFnOptions) => {
      return createDetail({
        url: '/v2/pd/document_row_values/apply_with_cleanup',
        ...serviceFnOptions,
        filters: {
          ...serviceFnOptions.filters,
          workflow_id: workflowId,
        },
      })
    },
    ...options,
  })

  return {
    createDocumentRowValue: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Update Document Row Value With Cleanup
 * Used to update a document row value with additional cleanup on backend.
 */
export function useUpdateDocumentRowValueWithCleanup({
  workflowId,
  ...options
}: UseUpdateDetailOptions<DocumentRowValue> & { workflowId: string }) {
  const mutation = useUpdateDetail<DocumentRowValue>({
    serviceFn: ({ item, ...serviceFnOptions }) => {
      return updateDetail({
        url: `/v2/pd/document_row_values/${item.id}/apply_with_cleanup`,
        item,
        ...serviceFnOptions,
        filters: {
          ...serviceFnOptions.filters,
          workflow_id: workflowId,
        },
      })
    },
    ...options,
  })

  return {
    updateDocumentRowValue: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Create Document Row Values With Cleanup
 * Used to create document rows values with additional cleanup on backend.
 */
export function useCreateUpdateDocumentRowValuesWithCleanup({
  workflowId,
  ...options
}: UseUpdateListOptions<DocumentRowValue> & { workflowId: string }) {
  const mutation = useUpdateList<DocumentRowValue>({
    serviceFn: (serviceFnOptions) => {
      return updateList<DocumentRowValue>({
        url: '/v2/pd/document_row_values/apply_with_cleanup',
        ...serviceFnOptions,
        filters: {
          ...serviceFnOptions.filters,
          workflow_id: workflowId,
        },
      })
    },
    ...options,
  })

  return {
    createOrUpdateDocumentRowValues: mutation.mutateAsync,
    ...mutation,
  }
}
