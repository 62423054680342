import { OverlayState } from '@/hooks/useOverlay'
import { Box, Popover } from '@mui/material'
import NodeSearchAutocomplete from './NodeSearchAutocomplete'
import { useState } from 'react'
import { LogixNodeType } from '@/types/logix'

import useFilteredNodeTypes from './hooks/useFilteredNodeTypes'

type NodeRightClickMenuProps = {
  overlay: OverlayState
  position: {
    mouseX: number
    mouseY: number
  }
  filteredNodeTypes: LogixNodeType[]
  setFilteringValues: ReturnType<
    typeof useFilteredNodeTypes
  >['setFilteringValues']
  onSelect: (nodeType: LogixNodeType | null) => void
}

export default function NodeRightClickMenu({
  overlay,
  position,
  filteredNodeTypes,
  setFilteringValues,
  onSelect,
}: NodeRightClickMenuProps) {
  const [searchValue, setSearchValue] = useState<string>('')

  function handleOnSelect(nodeType: LogixNodeType | null) {
    setSearchValue('')
    onSelect(nodeType)
  }

  return position ? (
    <Popover
      open={overlay.isOpen}
      onClose={() => {
        setSearchValue('')
        setFilteringValues()
        overlay.close()
      }}
      anchorReference="anchorPosition"
      sx={{ width: 280 }}
      transitionDuration={0}
      anchorPosition={
        overlay.isOpen
          ? { top: position.mouseY, left: position.mouseX }
          : undefined
      }
    >
      <Box sx={{ width: 220, pt: 1 }}>
        <NodeSearchAutocomplete
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          alwaysShowOptions
          autoFocus
          autoHighlight
          value={null} // Don't actually need the autocomplete to set a value in this case
          useDefaultListItems
          options={filteredNodeTypes}
          onSelect={handleOnSelect}
        />
      </Box>
    </Popover>
  ) : null
}
