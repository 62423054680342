import { DataListType } from '@/types/data-lists'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<DataListType>({
  pathCategory: 'data_list_types',
})

export function useGetDataListType({
  id,
  ...options
}: UseGetDetailOptions<DataListType>) {
  const query = useGetDetail<DataListType>({
    id,
    queryKey: queryKeys.dataListTypes.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    dataListType: query.data,
    ...query,
  }
}

export function useGetDataListTypes({
  filters,
  ...options
}: UseGetListOptions<DataListType> = {}) {
  const query = useGetList<DataListType>({
    filters,
    queryKey: queryKeys.dataListTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    dataListTypes: query.allData,
    ...query,
  }
}
