import { Box, Card, Stack, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { IntegrationType } from '@/types/integrations'

type IntegrationTypeCardProps = {
  integrationType: IntegrationType
  onAdd: (integrationType: IntegrationType) => void
  selectedIntegrationType: IntegrationType | null
}

export default function IntegrationTypeCard({
  integrationType,
  onAdd,
  selectedIntegrationType,
}: IntegrationTypeCardProps) {
  const { name } = integrationType
  return (
    <Card
      sx={{
        px: 2,
        py: 1,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography>{name}</Typography>

          {/* We don't have descriptions yet, but we will add them at some point. */}
          {/* <Typography variant="caption" color="text.secondary">
            {description}
          </Typography> */}
        </Box>
        <LoadingButton
          variant="text"
          startIcon={<Add />}
          loading={selectedIntegrationType?.id === integrationType.id}
          onClick={() => {
            onAdd(integrationType)
          }}
        >
          Add
        </LoadingButton>
      </Stack>
    </Card>
  )
}
