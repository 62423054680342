import { Check } from '@mui/icons-material'
import {
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material'

type SRTextFieldProps = {
  label: string
  value: string
  fromDocument?: boolean
  onChange: TextFieldProps['onChange']
}

export default function SRTextField({
  label,
  value,
  fromDocument,
  onChange,
}: SRTextFieldProps) {
  const theme = useTheme()
  return (
    <Stack spacing={0.75}>
      <Typography variant="body2">{label}</Typography>
      <TextField
        variant="outlined"
        value={value}
        size="small"
        onChange={onChange}
        helperText={
          fromDocument ? (
            <Stack
              direction="row"
              alignItems="middle"
              spacing={1}
              component="span"
              sx={{ color: theme.palette.success.main }}
            >
              <Check sx={{ fontSize: 16 }} />
              <span>From Utility Bill</span>
            </Stack>
          ) : undefined
        }
      />
    </Stack>
  )
}
