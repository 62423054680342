import { useProjectContext } from '../project-dashboard/ProjectProvider'
import FlowViewport from '../flow-editor/FlowViewport'
import useNodeTypeIndex from '../flow-editor/hooks/useNodeTypeIndex'
import { useParams } from 'react-router-dom'
import { useGetIntegration } from '@/service-library/hooks/integrations'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'

type IntegrationMappingEditorDisplayProps = {
  boardId: string
}

export default function IntegrationMappingEditor({
  boardId,
}: IntegrationMappingEditorDisplayProps) {
  const { integrationId = '' } = useParams()
  const { project } = useProjectContext()
  const { integration } = useGetIntegration({
    id: integrationId,
    enabled: !!integrationId,
  })

  const nodeTypeIndex = useNodeTypeIndex()

  if (Object.keys(nodeTypeIndex).length === 0) return null

  return (
    <>
      <ProjectBreadcrumb
        label="Integrations"
        url={`/projects/${project.id}/settings/integrations`}
      />
      {integration && <ProjectBreadcrumb label={integration.name} />}

      <FlowViewport
        id="integration-mapping-editor"
        nodeTypeIndex={nodeTypeIndex}
        boardId={boardId || '018a903d-a8a3-7a92-6110-ba6b0a96970e'} // Default board ID as backup to make testing and debugging easier for now
      />
    </>
  )
}
