import { Dispatch, SetStateAction } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Icon, Stack, Typography, useTheme } from '@mui/material'
import { darken, lighten } from '@mui/system/colorManipulator'

type SelectAllBannerProps = {
  loadedDocumentsCount: number
  isFetchingDocs: boolean
  selectedAll: boolean
  setSelectedAll: Dispatch<SetStateAction<boolean>>
  setSelectedAllLoaded: Dispatch<SetStateAction<boolean>>
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
  totalDocumentsCount: number
}

export default function SelectAllBanner({
  loadedDocumentsCount,
  isFetchingDocs,
  selectedAll,
  setSelectedAll,
  setSelectedAllLoaded,
  setSelectedRows,
  totalDocumentsCount,
}: SelectAllBannerProps) {
  const theme = useTheme()
  const getColor = theme.palette.mode === 'light' ? darken : lighten

  const onClick = () => {
    if (selectedAll) {
      // Clear all
      setSelectedAllLoaded(false)
      setSelectedAll(false)
      setSelectedRows({})
    } else {
      setSelectedAll(true)
    }
  }

  return (
    <Stack direction="row" spacing={1.5} alignItems="center" px="11px" py={1}>
      <InfoOutlined color="primary" />
      <Typography
        sx={{
          color: getColor(theme.palette.primary.main, 0.6),
        }}
      >
        {selectedAll && !isFetchingDocs
          ? `All ${totalDocumentsCount} documents available with the current filters are selected.`
          : `All ${loadedDocumentsCount} loaded documents are selected.`}
      </Typography>

      <LoadingButton
        variant="text"
        onClick={onClick}
        loading={selectedAll && isFetchingDocs}
        loadingPosition="start"
        startIcon={selectedAll && isFetchingDocs ? <Icon /> : <></>}
      >
        {selectedAll && !isFetchingDocs
          ? 'Clear Selection'
          : `Select all ${totalDocumentsCount} documents available with the current
        filters.`}
      </LoadingButton>
    </Stack>
  )
}
