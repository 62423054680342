import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Alert } from '@mui/material'
import { Project } from '@/types/projects'
import FieldConfiguration from '@/components/project-settings/FieldConfiguration'
import StepActions from './StepActions'
import { useUpdateProject } from '@/service-library/hooks/projects'

type FieldsStepProps = {
  project: Project
}

export default function FieldsStep({ project }: FieldsStepProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)

  const { updateProject, error } = useUpdateProject({
    onSuccess: (updatedProject) => {
      navigate(`/projects/${updatedProject.id}`)
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  const handleNext = () => {
    setLoading(true)
    updateProject({
      ...project,
      setup_state: 'complete',
      processing_active: true,
    })
  }

  const projectHasFields = project.project_grids?.some(
    ({ project_grid_fields }) => {
      return project_grid_fields.length > 0
    },
  )

  return (
    <>
      {error && (
        <Box sx={{ pb: 2 }}>
          <Alert severity="error">
            Unable to activate and complete project setup. Please try again
            later.
          </Alert>
        </Box>
      )}

      <FieldConfiguration project={project} />

      <Box sx={{ px: 2 }}>
        <StepActions
          onNext={handleNext}
          onBack={() => navigate(`../details?${searchParams.toString()}`)}
          nextLoading={loading}
          nextDisabled={!projectHasFields}
          nextLabel="Finish"
          project={project}
        />
      </Box>
    </>
  )
}
