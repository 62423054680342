import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { View } from '@/types/views'

const service = buildService<View>({
  pathCategory: 'saved_views',
})

export function useGetSavedView({ id, ...options }: UseGetDetailOptions<View>) {
  const query = useGetDetail<View>({
    id,
    queryKey: queryKeys.savedViews.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    savedView: query.data,
    ...query,
  }
}

export function useCreateSavedView(options: UseCreateDetailOptions<View> = {}) {
  const mutation = useCreateDetail<View>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createSavedView: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateSavedView(options: UseUpdateDetailOptions<View> = {}) {
  const mutation = useUpdateDetail<View>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateSavedView: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteSavedView(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<View>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteSavedView: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetSavedViews({
  filters,
  ...options
}: UseGetListOptions<View> = {}) {
  const query = useGetList<View>({
    filters,
    queryKey: queryKeys.savedViews.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    savedViews: query.allData,
    ...query,
  }
}

export function useUpdateSavedViews(options: UseUpdateListOptions<View> = {}) {
  const mutation = useUpdateList<View>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateSavedViews: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteSavedViews({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<View>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteSavedViews: mutation.mutateAsync,
    ...mutation,
  }
}
