import { useState, memo } from 'react'
import {
  Box,
  Card,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ClickAwayListener,
} from '@mui/material'
import { Panel } from 'reactflow'
import NodeCategoriesList from './NodeCategoriesList'
import NodeSearchAutocomplete from './NodeSearchAutocomplete'

function NodesPanel() {
  const [searchValue, setSearchValue] = useState<string>('')

  return (
    <Panel position="top-left" style={{ width: 220 }}>
      <Card elevation={4} sx={{ overflowY: 'auto', maxHeight: '75vh' }}>
        <Accordion defaultExpanded disableGutters>
          <AccordionSummary
            sx={{
              p: 0,
              '& .MuiAccordionSummary-content': {
                m: 0,
              },
            }}
          >
            <Typography
              variant="body1"
              sx={{ py: 1, px: 1.5, userSelect: 'none' }}
            >
              Nodes
            </Typography>
          </AccordionSummary>

          <AccordionDetails sx={{ p: 0 }}>
            <Box sx={{ px: 1.5, pb: 1, mt: -1, userSelect: 'none' }}>
              <Typography variant="caption" color="text.secondary">
                Drag and drop items to add them
              </Typography>
            </Box>

            {/* We override Autocomplete's default click-away behavior so drag-and-drop
             * will work, so we re-implement it with ClickAwayListener. */}
            <ClickAwayListener
              onClickAway={() => setSearchValue('')}
              mouseEvent="onPointerUp"
            >
              <Box>
                <NodeSearchAutocomplete
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
              </Box>
            </ClickAwayListener>

            {!searchValue && <NodeCategoriesList />}
          </AccordionDetails>
        </Accordion>
      </Card>
    </Panel>
  )
}

export default memo(NodesPanel)
