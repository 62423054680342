import useOverlay from '@/hooks/useOverlay'
import { Check, Close } from '@mui/icons-material'
import {
  Card,
  Stack,
  Typography,
  Popover,
  CardActionArea,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { memo, useEffect, useRef } from 'react'
import { Panel, useReactFlow } from 'reactflow'
import { useNodeProblems } from './ProblemsProvider'
import useBoardState from './hooks/useBoardState'

type ProblemsPanelProps = {
  setSelection: ReturnType<typeof useBoardState>['setSelection']
}

function ProblemsPanel({ setSelection }: ProblemsPanelProps) {
  const anchorRef = useRef<HTMLDivElement>(null)
  const flowInstance = useReactFlow()
  const overlay = useOverlay()
  const { problems } = useNodeProblems()

  const revealNodeWithProblem = (nodeId: string) => {
    flowInstance.fitView({
      nodes: [{ id: nodeId }],
      duration: 500,
      maxZoom: 1,
    })
    setSelection({
      nodeIds: { [nodeId]: true },
      edgeIds: {},
    })
  }

  useEffect(() => {
    problems.length === 0 && overlay.isOpen && overlay.close()
  }, [overlay, problems.length])

  return (
    <Panel
      position="bottom-center"
      style={{
        transform: 'translateY(-20px)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ transform: 'translateX(-50%)' }} ref={anchorRef}>
        <CardActionArea
          onClick={problems.length > 0 ? overlay.toggle : undefined}
          sx={{ padding: 1.5 }}
        >
          {problems.length === 0 && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Check />
              <Typography>Ready for Use</Typography>
            </Stack>
          )}

          {problems.length > 0 && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Close color="error" />
              <Typography>
                {problems.length} Problem{problems.length > 1 ? 's' : ''}
              </Typography>
            </Stack>
          )}
        </CardActionArea>
      </Card>

      <Popover
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          elevation: 2,
          sx: { maxWidth: 300 },
        }}
      >
        <List>
          {problems.map((problem) => (
            <ListItemButton
              key={problem.id}
              onClick={() => revealNodeWithProblem(problem.nodeId)}
            >
              <ListItemText
                primary={problem.message}
                primaryTypographyProps={{
                  color: problem.severity === 'error' ? 'error' : 'warning',
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Panel>
  )
}

export default memo(ProblemsPanel)
