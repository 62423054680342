import { ReactNode } from 'react'
import { Box, Stack } from '@mui/material'
import { ResponsiveBar, BarSvgProps } from '@nivo/bar'
import TooltipCard from './TooltipCard'
import constants from './chart-constants'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataType = Record<string, any>

export type BarChartProps = {
  xKey: string
  yKey: string
  height?: number | string
  label?: ReactNode
  noData?: boolean
  isLoading?: boolean
  isError?: boolean
  tooltipContent?: (data: DataType) => ReactNode
  // These four are to make small changes instead of passing the axisBottom or axisLeft
  // object to change those, and to match what we are doing for the ScatterPlotChart
  xFormat?: string | ((value: string) => string)
  xLegend?: string
  yFormat?: string | ((value: string) => string)
  yLegend?: string
} & Omit<BarSvgProps<DataType>, 'height' | 'width' | 'label'>

export default function BarChart({
  data,
  xKey,
  yKey,
  keys,
  height = 420,
  label,
  noData,
  isLoading,
  isError,
  axisBottom,
  axisLeft,
  margin,
  tooltip,
  tooltipContent,
  xFormat,
  xLegend,
  yFormat,
  yLegend,
  ...props
}: BarChartProps) {
  const noDataProps =
    data.length === 0 || noData
      ? {
          enableGridY: false,
          maxValue: 1,
          markers: [
            {
              axis: 'y' as const,
              value: 0.5,
              lineStyle: {
                stroke: 'transparent',
              },
              legend: isLoading
                ? 'Loading...'
                : isError
                ? 'Failed to Load Data'
                : 'No Data',
              textStyle: {
                fill: '#ffffff',
              },
              legendPosition: 'top' as const,
              legendOrientation: 'horizontal' as const,
            },
          ],
        }
      : {}

  return (
    <Box sx={{ height }}>
      {label && (
        <Stack direction="row" spacing={0.5} alignItems="center">
          {label}
        </Stack>
      )}
      {/* ResponsiveBar needs to be wrapped by a container with a set height */}
      <ResponsiveBar
        data={data}
        indexBy={xKey}
        indexScale={{ type: 'band', round: false }}
        keys={keys || [yKey]}
        minValue={0}
        axisBottom={{
          format: xFormat,
          legend: xLegend,
          legendPosition: 'middle',
          ...axisBottom,
        }}
        axisLeft={{
          format: yFormat,
          legend: yLegend,
          legendPosition: 'middle',
          legendOffset: -50,
          tickValues: data.length === 0 || noData ? [0, 1] : undefined,
          ...axisLeft,
        }}
        enableLabel={false}
        layout="vertical" // vertical is the default, but if we don't set it explicitly, the animation grows the bars horizontally
        margin={{
          top: constants.MARGIN_TOP,
          right: constants.MARGIN_RIGHT,
          bottom: xLegend
            ? constants.MARGIN_BOTTOM_WITH_LEGEND
            : constants.MARGIN_BOTTOM,
          left: yLegend
            ? constants.MARGIN_LEFT_WITH_LEGEND
            : constants.MARGIN_LEFT,
          ...margin,
        }}
        padding={0.7}
        tooltip={
          tooltipContent
            ? ({ data }) => <TooltipCard>{tooltipContent(data)}</TooltipCard>
            : tooltip
        }
        theme={{
          axis: {
            domain: {
              line: {
                stroke: '#777777',
              },
            },
            legend: {
              text: {
                fontSize: 14,
                fill: '#d2d2d2',
              },
            },
            ticks: {
              text: {
                fill: '#b3b3b3',
              },
            },
          },
          grid: {
            line: {
              stroke: '#686868',
            },
          },
        }}
        {...noDataProps}
        {...props}
      />
    </Box>
  )
}
