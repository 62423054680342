import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Container, Link, Stack, Typography } from '@mui/material'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import PageTitle from '@/components/PageTitle'

type NestedListProps = {
  children: ReactNode
  liContent?: string
}

type NestedListInNestedListProps = {
  children: ReactNode
}

export const NestedList = ({
  children,
  liContent = '"(" counter(list, lower-alpha) ")"',
}: NestedListProps) => {
  return (
    <Stack
      component="ol"
      spacing={1}
      sx={{
        counterReset: 'list',
        listStyle: 'none',
        mt: 1,
        '> li': {
          counterIncrement: 'list',
          padding: '0 0 0 8px',
        },
        '> li::marker': {
          content: liContent,
        },
      }}
    >
      {children}
    </Stack>
  )
}

const NestedListInNestedList = ({ children }: NestedListInNestedListProps) => (
  <NestedList liContent={`"(" counter(list, lower-roman) ")"`}>
    {children}
  </NestedList>
)

export default function AgreementsPage() {
  return (
    <Container maxWidth="md" sx={{ my: 5, textAlign: 'justify' }}>
      <PageTitle>Agreements</PageTitle>
      <Breadcrumb label="Agreements" url="/agreements" />
      <Typography align="center" component="h1" variant="h5">
        MASTER SOFTWARE AS A SERVICE AGREEMENT
      </Typography>
      <Typography sx={{ my: 2 }}>
        This Master Software as a Service Agreement (this “<b>Agreement</b>
        ”), effective as of the date set forth on the signature page hereto (the
        “<b>Effective Date</b>”), is by and between ZERAPIX, LLC, an Idaho
        limited liability company (“<b>Provider</b>”), and the customer whose
        name and address is set forth on the signature page hereto (the “
        <b>Customer</b>”). This Agreement shall govern Customer&apos;s rights to
        access and use the Services and Provider Programs specified in one or
        more Order Forms. Unless otherwise defined herein, capitalized terms
        used in this Agreement shall have the meanings set forth in Section 12
        below.
      </Typography>
      <Typography align="center" component="h1" variant="h5">
        AGREEMENT
      </Typography>
      <Typography component="div">
        <Stack component="ol" spacing={1.5} sx={{ pl: '18px' }}>
          <li>
            <u>Services</u>.
            <NestedList>
              <li>
                <u>Access and Use</u>. Subject to and conditioned on
                Customer&apos;s and the Authorized Users&apos; compliance with
                the terms and conditions of this Agreement, Provider hereby
                grants Customer a non-exclusive, non-transferable right to
                access and use the Services during the Term, solely for use by
                Authorized Users in accordance with the terms and conditions
                herein. Such use is limited to Customer&apos;s internal use.
                Provider shall provide to Customer the Access Credentials within
                a reasonable time following the Effective Date.
              </li>
              <li>
                <u>Use of Documentation</u>. Provider hereby grants to Customer
                a non-exclusive, non-sublicensable, non-transferable license to
                use the Documentation during the Term solely for Customer&apos;s
                internal business purposes in connection with the Services.
              </li>
              <li>
                <u>Service and System Control</u>. Except as otherwise expressly
                provided in this Agreement, as between the parties:
                <NestedListInNestedList>
                  <li>
                    Provider has and will retain sole control over the
                    operation, provision, maintenance, and management of the
                    Provider Programs; and
                  </li>
                  <li>
                    Customer has and will retain sole control over the
                    operation, maintenance, and management of, and all access to
                    and use of, the Customer Systems, and sole responsibility
                    for all access to and use of the Provider Programs by any
                    Person by or through the Customer Systems or any other means
                    controlled by Customer or any Authorized User, including
                    any: (i) information, instructions, or materials provided by
                    any of them to the Services or Provider; (ii) results
                    obtained from any use of the Services or Provider Programs;
                    and (iii) conclusions, decisions, or actions based on such
                    use.
                  </li>
                </NestedListInNestedList>
              </li>
              <li>
                <u>Reservation of Rights</u>.
                <NestedListInNestedList>
                  <li>
                    Nothing in this Agreement grants any right, title, or
                    interest in or to (including any license under) any
                    Intellectual Property Rights in or relating to, the
                    Services, Provider Programs, or Third Party Materials,
                    whether expressly, by implication, estoppel, or otherwise.
                    All right, title, and interest in and to the Services, the
                    Provider Programs, and the Third Party Materials are and
                    will remain with Provider and the respective rights holders
                    in the Third Party Materials.
                  </li>
                  <li>
                    Provider is and will remain the exclusive owner of all
                    right, title and interest in and to the Machine Learning
                    Outputs (as defined in Section 4(d)) and Resultant Data
                    including any Intellectual Property Rights relating thereto.
                    Provider owns and Customer irrevocably transfers, assigns
                    and conveys to Provider any and all right, title and
                    interest in any learning, learned behaviors, modifications,
                    and other derivatives of the Services and Provider Programs,
                    that is a result of processing, analyzing, training and any
                    other interaction of the Customer Data. This is particularly
                    relevant where machine learning technology is used within
                    the Provider Programs. All Intellectual Property Rights in
                    any work arising from or created, produced or developed by
                    Provider (whether alone or jointly with others) under or in
                    the course of this Agreement, including those arising from
                    the Provider Programs, will immediately upon creation or
                    performance vest absolutely in and will be and remain the
                    property of Provider, and Customer will not acquire any
                    right, title or interest in and to the same. All rights not
                    specifically granted in this Agreement to Customer are
                    exclusively reserved to Provider or its licensors.
                  </li>
                  <li>
                    Provider uses machine learning tools and human resources to
                    enhance the Provider Programs, create Trained Model(s), and
                    otherwise develop and improve the Provider Programs and
                    related services and technologies on the basis of Customer
                    Data and Trained Data. All information, data, processes and
                    Intellectual Property created by the machine learning
                    process described in this Section 1(d)(iii) is referred to
                    herein as “<b>AI Data.</b>” Customer acknowledges and agrees
                    that Provider owns all right, title and interest in and to
                    the AI Data, Trained Data and Trained Models.
                  </li>
                </NestedListInNestedList>
              </li>
              <li>
                <u>Changes</u>. Provider reserves the right, in its sole
                discretion, to make any changes to the Services and Provider
                Programs that it deems necessary or useful to: (i) maintain or
                enhance: (1) the quality or delivery of Provider&apos;s services
                to its customers; (2) the competitive strength of or market for
                Provider&apos;s services; or (3) the Services&apos; cost
                efficiency or performance; or (ii) to comply with applicable
                Law. Without limiting the foregoing, either party may, at any
                time during the Term, request in writing changes to the
                Services. No requested changes will be effective unless and
                until memorialized in an addendum hereto or Order Form (or
                addendum thereto) signed by both parties.
              </li>
              <li>
                <u>Suspension or Termination of Services</u>. Provider may,
                directly or indirectly, and by use of a Provider Disabling
                Device or any other lawful means, suspend, terminate, or
                otherwise deny Customer&apos;s, any Authorized User&apos;s, or
                any other Person&apos;s access to or use of all or any part of
                the Services or Provider Programs, without incurring any
                resulting obligation or liability, if: (1) Provider receives a
                judicial or other governmental demand or order, subpoena, or law
                enforcement request that expressly or by reasonable implication
                requires Provider to do so; or (2) Provider believes, in good
                faith and its sole discretion, that: (A) Customer or any
                Authorized User has failed to comply with any material term of
                this Agreement, or accessed or used the Services beyond the
                scope of the rights granted or for a purpose not authorized
                under this Agreement or in any manner that does not comply with
                any material instruction or requirement of the Specifications;
                (B) Customer or any Authorized User is, has been, or is likely
                to be involved in any fraudulent, misleading, or unlawful
                activities; or (C) this Agreement expires or is terminated. This
                Section 1(h) does not limit any of Provider&apos;s other rights
                or remedies, whether at law, in equity, or under this Agreement.
              </li>
              <li>
                <u>Order Forms</u>. Each Order Form shall (1) specify the
                particular Services and/or Provider Program(s) which Customer
                shall have rights to access and use in accordance with the terms
                and condition of such Order Form; (2) constitute a separate and
                distinct contract between Provider and Customer, enforceable
                according to its terms, and each Order form shall, and hereby
                does automatically incorporate the terms and conditions of this
                Agreement (and any addendum hereto) as if set forth verbatim in
                each such Order Form. Notwithstanding the foregoing, Customer
                acknowledges and agrees that the terms and conditions of this
                Agreement shall supersede and render as null and void any
                conflicting or inconsistent terms or conditions in any Order
                Form or which Customer may include in, pre-print on or append to
                any purchase order (even if such Order Form is executed,
                acknowledged, accepted or fulfilled by Provider subsequent to
                the date hereof).
              </li>
            </NestedList>
          </li>
          <li>
            <u>Restrictions</u>.
            <NestedList>
              <li>
                <u>Use Restrictions</u>. Customer shall not, and shall not
                permit any other Person to, access or use the Services or
                Provider Programs except as expressly permitted by this
                Agreement and, in the case of Third Party Materials, the
                applicable third party license agreement. For purposes of
                clarity and without limiting the generality of the foregoing,
                Customer and Authorized Users shall not, except as this
                Agreement expressly permits: (i) copy, modify, or create
                derivative works or improvements of the Services or Provider
                Programs; (ii) rent, lease, lend, sell, sublicense, assign,
                distribute, publish, transfer, or otherwise make available any
                Services or Provider Programs to any Person, including on or in
                connection with the internet or any time-sharing, service
                bureau, software as a service, cloud, or other technology or
                service; (iii) reverse engineer, disassemble, decompile, decode,
                adapt, or otherwise attempt to derive or gain access to the
                source code of the Services or Provider Programs, in whole or in
                part; (iv) bypass or breach any security device or protection
                used by the Services or Provider Programs or access or use the
                Services or Provider Programs other than by an Authorized User
                through the use of his or her own then valid Access Credentials;
                (v) input, upload, transmit, or otherwise provide to or through
                the Services or Provider Systems, any information or materials
                that are unlawful or injurious, or contain, transmit, or
                activate any Harmful Code; (vi) damage, destroy, disrupt,
                disable, impair, interfere with, or otherwise impede or harm in
                any manner the Services, Provider Systems, or Provider&apos;s
                provision of services to any third party, in whole or in part;
                (vii) remove, delete, alter, or obscure any trademarks,
                Specifications, Documentation, warranties, or disclaimers, or
                any copyright, trademark, patent, or other intellectual property
                or proprietary rights notices from any Services or Provider
                Programs, including any copy thereof; (viii) access or use the
                Services or Provider Programs in any manner or for any purpose
                that infringes, misappropriates, or otherwise violates any
                Intellectual Property Right or other right of any third party
                (including by any unauthorized access to, misappropriation, use,
                alteration, destruction, or disclosure of the data of any other
                Provider customer), or that violates any applicable Law; (ix)
                access or use the Services or Provider Programs for purposes of
                competitive analysis of the Services or Provider Programs, the
                development, provision, or use of a competing software service
                or product or any other purpose that is to the Provider&apos;s
                detriment or commercial disadvantage; (x) access or use the
                Service or Provider Programs to store or transmit infringing,
                libelous, or otherwise unlawful or tortious material, or (xi)
                otherwise access or use the Services or Provider Programs beyond
                the scope of the authorization granted under this Section 2(a).
              </li>
              <li>
                <u>Authorized Users</u>. Customer acknowledges and agrees that
                Customer shall (i) be responsible for each of Customer&apos;s
                Authorized Users&apos; compliance with this Agreement, (ii)
                cause all Authorized Users to review and comply with the
                Provider&apos;s Terms of Use and shall otherwise be responsible
                for each of Customer&apos;s Authorized Users&apos; compliance
                thereof, and (iii) not make the Provider Programs or Services
                available to any other Person. In addition to the foregoing,
                Customer acknowledges and agrees that (1) access to or use of
                the Services and/or Provider Programs is subject to the terms
                and conditions of this Agreement, including, without limitation,
                the restrictions set forth hereunder, and the terms and
                conditions of the Terms of Use; (2) any claim, demand or right
                of action arising from an Authorized User&apos;s use of the
                Services and/or Provider Programs shall be brought against
                Customer and not Provider; and (3) Customer shall remain
                directly liable and responsible to Provider and any third party
                for any violation by Customer and/or Authorized Users of this
                Agreement or the Terms of Use, and Customer shall indemnify and
                hold harmless Provider and its Subcontractors and their
                respective officers, directors, employees and agents, from any
                and all demands, costs and liabilities (including all reasonable
                attorneys&apos; fees) arising out of or related to any
                representation, action or omission by any Authorized User
                inconsistent with the terms and conditions of this Agreement.
              </li>
            </NestedList>
          </li>
          <li>
            <u>Customer Obligations</u>.
            <NestedList>
              <li>
                <u>Minimum System Requirements</u>. As a condition precedent to
                the exercise or enjoyment any rights granted hereunder, Customer
                acknowledges and agrees that it must satisfy, at Customer&apos;s
                sole cost and expense, the Minimum System Requirements.
              </li>
              <li>
                <u>Customer Systems and Cooperation</u>. Customer shall at all
                times during the Term: (i) set up, maintain, and operate in good
                repair and in accordance with the Provider Programs, including
                Specifications (if any), all Customer Systems on or through
                which the Services are accessed or used; (ii) provide Provider
                Personnel with such access to Customer&apos;s premises and
                Customer Systems as is necessary for Provider to perform the
                Services in accordance with the Provider Programs and
                Specifications, if any; and (iii) provide all cooperation and
                assistance as Provider may reasonably request to enable Provider
                to exercise its rights and perform its obligations under and in
                connection with this Agreement
              </li>
              <li>
                <u>Effect of Customer Failure or Delay</u>. Provider is not
                responsible or liable for any delay or failure of performance
                caused in whole or in part by Customer&apos;s delay in
                performing, or failure to perform, any of its obligations under
                this Agreement, including Customer&apos;s obligation to maintain
                the Minimum System Requirements (each, a “
                <b>Customer Failure</b>
                ”)
              </li>
              <li>
                <u>Corrective Action and Notice</u>. If Customer becomes aware
                of any actual or threatened activity prohibited by Section 2(a),
                Customer shall, and shall cause its Authorized Users to,
                immediately: (i) take all reasonable and lawful measures within
                their respective control that are necessary to stop the activity
                or threatened activity and to mitigate its effects (including,
                where applicable, by discontinuing and preventing any
                unauthorized access to the Services and Provider Programs and
                permanently erasing from their systems and destroying any data
                to which any of them have gained unauthorized access); and (ii)
                immediately notify Provider of any such actual or threatened
                activity
              </li>
              <li>
                <u>Confidential Information</u>. Customer agrees that it shall
                take reasonable measures to protect the secrecy of and avoid
                disclosure and unauthorized use of the Confidential Information.
                Without limiting the foregoing, Customer shall take at least
                those measures that it takes to protect its own confidential
                information of a similar nature, but in no case less than
                reasonable care (including, without limitation, all precautions
                the Customer employs with respect to its confidential
                materials). Customer shall limit access to Provider&apos;s
                Confidential Information to those of its employees who need such
                access for purposes consistent with this Agreement;{' '}
                <u>provided</u>, <u>however</u>, that Customer shall ensure that
                its employees who have access to Provider&apos;s Confidential
                Information have signed a non-use and non-disclosure agreement
                in content similar to the provisions of this Agreement or are
                otherwise legally obligated not to disclose such Confidential
                Information, prior to any disclosure of Confidential Information
                to such employees. Customer shall not make any copies of the
                Confidential Information except upon the Provider&apos;s prior
                written approval. Customer shall promptly notify Provider if
                Customer becomes aware of any use or disclosure of the
                Confidential Information in violation of this Agreement. If
                Customer is required by law to make any disclosure that is
                prohibited or otherwise constrained by this Agreement, Customer
                will provide Provider with prompt written notice of such
                requirement so that Provider may seek a protective order or
                other appropriate relief. Subject to the foregoing sentence,
                Customer may furnish that portion (and only that portion) of the
                Confidential Information that Customer is legally compelled or
                is otherwise legally required to disclose; <u>provided</u>,
                <u>however</u>, that Customer provides such assistance as
                Provider may reasonably request in obtaining such order or other
                relief. Customer shall not disclose this Agreement or any Order
                Form, to any third party other than its Affiliates and their
                legal counsel and accountants without the Provider&apos;s
                written consent
              </li>
              <li>
                <u>Non-Solicitation</u>. During the Term and for one (1) year
                after, Customer shall not, and shall not assist any other Person
                to, directly or indirectly recruit or solicit (other than by
                general advertisement not directed specifically to any Person or
                Persons) for employment or engagement as an independent
                contractor any Person then or within the prior twelve (12)
                months employed or engaged by Provider or any Subcontractor. In
                the event of a violation of this Section 3(e), Provider will be
                entitled to liquidated damages equal to the compensation paid by
                Provider to the applicable employee or contractor during the
                prior twelve (12) months
              </li>
            </NestedList>
          </li>
          <li>
            <u>Data, Security and Support</u>.
            <NestedList>
              <li>
                <u>Data Backup</u>. The Services do not replace the need for
                Customer to maintain regular data backups or redundant data
                archives. NOTWITHSTANDING ANY PROVISION IN THIS AGREEMENT TO THE
                CONTRARY, PROVIDER HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS,
                ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION, OR RECOVERY OF
                CUSTOMER DATA.
              </li>
              <li>
                <u>Use of Customer Data</u>. Unless otherwise agreed to herein
                or by the Customer in writing, Provider shall not knowingly
                allow Customer Data, including personally identifiable
                information (“<b>PII</b>”), to be used for any purpose other
                than processing and storage under this Agreement. For the
                purposes of this Agreement, “PII” refers to information that can
                be used to distinguish or trace an individual&apos;s identity,
                either alone or when combined with other personal or identifying
                information that is linked or linkable to a specific individual.
              </li>
              <li>
                <u>Resultant and Aggregated Data</u>. Provider shall have the
                right to use Resultant Data and any aggregated Customer Data
                that has been stripped of personally identifiable information
                (as defined by applicable Law) for its own uses, including, but
                not limited to, for studies, white papers, reports and marketing
                materials. Provider may share the data described in this Section
                4(c) with Customer or any third party, provided such data does
                not identify Customer.
              </li>
              <li>
                <u>Machine Learning</u>. The Services include machine learning,
                which are taught and trained largely from Customer&apos;s
                internal data sets. Accordingly, Customer acknowledges and
                agrees the quality of the results and outputs of the machine
                learning portions of the Services (“
                <u>Machine Learning Outputs</u>”) is heavily reliant on the
                quality of the Customer Data. Customer acknowledges and agrees
                that its timely provision of (and access to) its assistance,
                data, information and materials (the “<u>Cooperation</u>”) is
                essential to Provider&apos;s provision of the machine learning
                Services and Machine Learning Outputs. Customer shall execute
                all Cooperation duties reasonably requested by Provider. If no
                timeframe is agreed with respect to a Cooperation duty, Customer
                shall execute the respective Cooperation duty within a timeframe
                appropriate having regard to the importance of its execution for
                the continuation of the machine learning related Services. Any
                delays in the performance of machine learning Services or
                providing the Machine Learning Outputs caused by Customer or the
                late or non-performance of the Cooperation may result in
                additional charge, as determined by Provider in its sole
                discretion. CUSTOMER FURTHER ACKNOWLEDGES AND AGREES THAT
                PROVIDER SHALL BEAR NO RESPONSIBILITY OR LIABILITY FOR THE
                QUALITY OF THE MACHINE LEARNING OUTPUTS, OR FOR CUSTOMER&apos;S
                RELIANCE ON, OR USE OF, THE MACHINE LEARNING OUTPUTS.
              </li>
              <li>
                <u>Information Security</u>. Provider shall maintain appropriate
                administrative, physical, and technical safeguards for
                protection of the security, confidentiality and integrity of
                Customer Data. Provider shall not except as otherwise permitted
                hereunder, (i) modify Customer Data, (ii) disclose Customer Data
                except as compelled by law or as expressly permitted in writing
                by Customer, or (iii) access Customer Data, except to provide
                the Service, Provider Programs, or prevent or address service or
                technical problems, or at the Customer&apos;s request in
                connection with customer support matters.
              </li>
              <li>
                <u>Customer Control and Responsibility</u>. Notwithstanding the
                foregoing, Customer has and will retain sole responsibility for:
                (i) all Customer Data, including its content and use; (ii) all
                information, instructions, and materials provided by or on
                behalf of Customer or any Authorized User in connection with the
                Services; (iii) Customer&apos;s information technology
                infrastructure, including computers, software, databases,
                electronic systems (including database management systems), and
                networks, whether operated directly by Customer or through the
                use of third-party services (“<b>Customer Systems</b>”); (iv)
                the security and use of Customer&apos;s and its Authorized
                Users&apos; Access Credentials; and (v) all access to and use of
                the Services and Provider Programs directly or indirectly by or
                through the Customer Systems or its or its Authorized
                Users&apos; Access Credentials, with or without Customer&apos;s
                knowledge or consent, including all results obtained from, and
                all conclusions, decisions, and actions based on, such access or
                use.
              </li>
              <li>
                <u>Access and Security</u>. Customer shall employ all physical,
                administrative, and technical controls, screening, and security
                procedures and other safeguards necessary to: (i) securely
                administer the distribution and use of all Access Credentials
                and protect against any unauthorized access to or use of the
                Services; and (ii) control the content and use of Customer Data,
                including the uploading or other provision of Customer Data for
                processing by the Services.
              </li>
              <li>
                <u>Service Availability and Support</u>.
                <NestedListInNestedList>
                  <li>
                    <u>Availability</u>. Provider shall use commercially
                    reasonable efforts to make the Services available
                    twenty-four (24) hours a day, seven (7) days a week, except
                    for any: planned downtime, of which Provider will give
                    reasonable notice, and, to the extent practicable, Provider
                    will attempt schedule such downtime outside U.S. business
                    hours; (2) unavailability caused by circumstances beyond
                    Provider&apos;s reasonable control, including without
                    limitation, as required by any applicable Law, Force Majeure
                    Event, or denial of service attacks; (3) any act or omission
                    by Customer or any Authorized User or either&apos;s access
                    to or use of the services by Customer or any Authorized
                    User, or using Customer&apos;s or using Customer&apos;s or
                    an Authorized User&apos;s Access Credentials, that does not
                    strictly comply with the Agreement and the Specifications;
                    (4) failure, interruption, outage, or other problem with any
                    software, hardware, system, network, facility, or other
                    matter not supplied by Provider pursuant to this Agreement;
                    or (5) disabling, suspension, or termination of the Services
                    pursuant to Section 1(h).
                  </li>
                  <li>
                    <u>Basic Support</u>. Provider shall provide to Customer
                    Provider&apos;s basic support for the Services, including
                    (1) upgrades and new releases of any software of the
                    Services and related Documentation; (2) daily back-ups of
                    the Customer Data, though as provided in Section 4(a) above,
                    such back-ups do not replace the need for Customer to
                    maintain regular data backups or redundant data archives;
                    and (3) periodic system and hardware maintenance as needed
                    to maintain the overall system operability. The Services
                    include Providers standard customer support services at the
                    support levels as provided on the Order Form(s).
                  </li>
                  <li>
                    <u>Limitation of Support</u>. For the avoidance of doubt,
                    and notwithstanding any provision in this Agreement to the
                    contrary, Provider&apos;s support for the Services or
                    Provider Programs hereunder shall not extend to any
                    non-Provider software, documentation or materials, and shall
                    in no way cover any hardware, networks, servers, or devices
                    associated with the Customer Systems.
                  </li>
                </NestedListInNestedList>
              </li>
            </NestedList>
          </li>
          <li>
            <u>Fees and Payment</u>.
            <NestedList>
              <li>
                <u>Subscription Fees</u>. Customer shall pay the subscription
                fees set forth in any Order Form agreed between the Parties in
                accordance with this Section 5. Fees and payment obligations are
                non-cancelable and fees paid are non-refundable. Fees are based
                on periodic payments as set forth in an Order Form between the
                Parties.
              </li>
              <li>
                <u>Taxes</u>. All fees and other amounts payable by Customer
                under this Agreement are exclusive of taxes and similar
                assessments. Without limiting the foregoing, Customer is
                responsible for all sales, use, and excise taxes, and any other
                similar taxes, duties, and charges of any kind imposed by any
                federal, state, or local governmental or regulatory authority on
                any amounts payable by Customer hereunder, other than any taxes
                imposed on Provider&apos;s income.
              </li>
              <li>
                <u>Professional Services/Training</u>. It is specifically
                understood and agreed to by Customer that Customer shall be
                responsible for initiating, in writing to Provider, all requests
                regarding its desired training for the Provider Programs,
                including the Customer&apos;s desired scope and level of
                training, and all details relating to timing of such requested
                training. Once Customer has so requested such training, and
                Provider, after consultation with Customer, has reviewed and
                approved all details related to such training, then the parties
                shall mutually cooperate to implement the agreed upon training.
                Provider shall not be responsible for any costs or expenses
                incurred or suffered by Customer, or for any refunds related to
                training services, in the event that Customer fails to initiate
                its request for training in accordance with this Section.
                <NestedListInNestedList>
                  <li>
                    <u>Costs and Expenses</u>. In addition to any fees charged
                    by Provider for the Professional Services, including any
                    training provided by Provider, Customer shall reimburse
                    Provider for all reasonable food, lodging, travel, and
                    incidental expenses incurred by Provider&apos;s employees in
                    conjunction with any on-site Professional Services ordered
                    by Customer (the “<b>Reimbursable Expenses</b>”). If such
                    Professional Services are performed at Provider&apos;s
                    premises, such expenses incurred by Customer&apos;s
                    employees shall be borne by Customer.
                  </li>
                </NestedListInNestedList>
              </li>
              <li>
                <u>Payment</u>. Customer shall pay all fees hereunder, including
                any fees or Reimbursable Expenses for Professional Services,
                within ten (10) days after the date of the invoice therefor.
                Customer shall make all payments hereunder in U.S. dollars.
                Customer is responsible for providing complete and accurate
                billing and contact information to Provider and notifying
                Provider of any changes to such information.
              </li>
              <li>
                <u>Late Payment</u>. If Customer fails to make any payment when
                due then, in addition to all other remedies that may be
                available: (i) Provider may charge interest on the past due
                amount at the rate of one and one-half (1.5%) per month
                calculated daily and compounded monthly or, if lower, the
                highest rate permitted under applicable Law; (ii) Customer shall
                reimburse Provider for all costs incurred by Provider in
                collecting any late payments or interest, including
                attorneys&apos; fees, court costs, and collection agency fees;
                and (iii) if such failure continues for ten (10) days following
                written notice thereof, Provider may suspend performance of the
                Services until all past due amounts and interest thereon have
                been paid, without incurring any obligation or liability to
                Customer or any other Person by reason of such suspension.
              </li>
              <li>
                <u>No Deductions or Setoffs</u>. All amounts payable to Provider
                under this Agreement shall be paid by Customer to Provider in
                full without any setoff, recoupment, counterclaim, deduction,
                debit, or withholding for any reason (other than any deduction
                or withholding of tax as may be required by applicable Law).
              </li>
              <li>
                <u>Fee Increases</u>. Provider may, in its sole discretion,
                increase subscription fees for any contract year during the Term
                or any Renewal Term, by providing written notice to Customer at
                least thirty (30) calendar days prior to the commencement of
                that contract year, and all Order Forms will be deemed amended
                accordingly.
              </li>
              <li>
                <u>Audits</u>. During the term of this Agreement and for a
                period of one year thereafter, Provider shall have the right
                from time to time, but not more than once per quarter, at its
                own expense and under reasonable conditions of time and place,
                to have an independent auditor, under duties of confidentiality,
                audit and copy all records of Customer relating to any of
                Customer&apos;s obligations under this Agreement. In the event
                any such audit discloses any breach of the terms of this
                Agreement by Customer or Customer&apos;s employees, agents
                and/or contractors, including without limitation any utilization
                of the Provider Programs and/or the Services beyond
                Customer&apos;s scope of use hereunder, Customer shall, in
                addition to such other rights and remedies as may be available
                to Provider as the result of such breach, reimburse Provider for
                the full costs and expenses incurred by Provider with respect to
                the audit.
              </li>
            </NestedList>
          </li>
          <li>
            <u>Customer Content</u>. The Services and Provider Programs may
            contain message boards, chat rooms, personal web pages or profiles,
            forums, bulletin boards, or other interactive features that may
            allow Customer and/or Authorized Users to submit, post, display,
            publish or transmit Content to or through the Services and Provider
            Programs. By submitting the Content, the Customer hereby agree as
            follows:
            <NestedList>
              <li>
                Customer grants Provider a royalty-free, perpetual, irrevocable,
                worldwide, unlimited, and non-exclusive license to use,
                reproduce, create derivative works from, modify, publish, edit,
                translate distribute, perform and display the submission and/or
                the Content in any media or medium, or any form, format or forum
                whether now known or hereafter to (i) provide the Services and
                Provider Programs in accordance with this Agreement; (ii)
                create, develop and provide Trained Models and AI Data, (ii)
                satisfy any Applicable Law, legal process or governmental
                request; (iii) enforce this Agreement, including investigation
                of potential violation hereof; (iv) detect, prevent, otherwise
                address fraud, security or technical issues; (v) to respond to
                Customer and Authorized Users&apos; customer support requests;
                or (v) to protect the rights, property or safety of Provider,
                Customer, Authorized Users or any other Person.
              </li>
              <li>
                Customer represents and warrants, with respect to the Content,
                (i) Customer has, or shall have, all Intellectual Property
                Rights necessary for Provider to use, copy, reproduce, display
                and transmit the Content as set forth in Section 6(a); and (ii)
                the Content shall not violate any Intellectual Property Rights
                of any Person.
              </li>
              <li>
                Customer also represents and warrants that the Content shall
                NOT, as determined by the Provider in its sole discretion, be
                any of the following: false, inaccurate, misleading, a violation
                of any local, state, federal, international or other applicable
                Law; obscene, derogatory, defamatory, threatening, harassing,
                abusive, slanderous, hateful or embarrassing to any other person
                or entity, including as such relates to the harassment,
                degradation, intimidation, or victimization of an individual or
                group of individuals on the basis of religion, gender, sexual
                orientation, race, ethnicity, age or disability; an infringement
                on the rights of others, such as infringements on any patent,
                copyright, trademarks, trade secret, publicity, privacy or
                Intellectual Property rights; an advertisement, solicitation or
                spam link to other websites or individuals, except if such an
                advertisement or solicitation has been expressly consented to in
                writing by the Provider; a chain letter or pyramid scheme, or
                part of a chain letter or pyramid scheme; an impersonation of,
                and does not purport to impersonate, another business, person,
                or entity, including the Provider and its employees and agents;
                or a virus or other Harmful Code.
              </li>
              <li>
                Customer acknowledges and agrees that Provider has the right,
                but not the obligation, to monitor all Content and any
                submission made to or on the Provider Programs or the Services.
                Provider has the right, in its sole discretion and for any
                reason, to edit, delete, move, or to refuse to post any Content
                or any other submission to the Sites or the Services.
                NOTWITHSTANDING THE FOREGOING, CUSTOMER IS SOLELY RESPONSIBLE
                FOR ANY CONTENT AND/OR ANY OTHER SUBMISSION CUSTOMER OR AN
                AUTHORIZED USER MAKES TO OR ON THE PROVIDER PROGRAMS OR THE
                SERVICES, AND CUSTOMER AGREES TO INDEMNIFY THE PROVIDER FOR ALL
                CLAIMS RELATED TO OR ARISING FROM SUCH CONTENT AND/OR
                SUBMISSIONS.
              </li>
            </NestedList>
          </li>
          <li>
            <u>Intellectual Property Rights</u>.
            <NestedList>
              <li>
                <u>Provider Programs</u>. All right, title, and interest in and
                to the Provider Programs, including all Intellectual Property
                Rights therein, are and will remain with Provider and, with
                respect to Third-Party Materials, the applicable third-party
                providers own all right, title, and interest, including all
                Intellectual Property Rights, in and to the Third-Party
                Materials. Except as expressly set forth in Section 1(a), and
                subject to Section 2(a), Customer has no right, license, or
                authorization with respect to any of the Provider Programs or
                the applicable third-party license. All other rights in and to
                the Provider Programs are expressly reserved by Provider. In
                furtherance of the foregoing, Customer hereby unconditionally
                and irrevocably grants to Provider an assignment of all right,
                title, and interest in and to the Resultant Data, including all
                Intellectual Property Rights relating thereto.
              </li>
              <li>
                <u>License to Customer Data</u>.
                <NestedListInNestedList>
                  <li>
                    <u>General</u>. Customer hereby grants to Provider a
                    non-exclusive, non-transferable license to use, upload,
                    display, copy and store all Customer Data provided to
                    Provider for storage and access through the Provider Systems
                    pursuant to this Agreement.
                  </li>
                  <li>
                    <u>AI Data</u>. Customer grants Provider a royalty-free,
                    perpetual, irrevocable, worldwide, unlimited, transferable,
                    sublicensable, and non-exclusive license to use, reproduce,
                    create derivative works from, modify, publish, edit,
                    translate distribute, perform and display the submission
                    and/or the Customer Data in any media or medium, or any
                    form, format or forum whether now known or hereafter to
                    create, develop and provide AI Data, Trained Data and
                    Trained Models.
                  </li>
                  <li>
                    <u>Ownership of Customer Data</u>. For the avoidance of
                    doubt, Customer hereby, acknowledges and agrees that
                    Customer shall retain ownership of Customer Data at all
                    times and have sole responsibility and liability for: (a)
                    acquiring any and all authorization necessary for use of
                    Customer Data as contemplated by this Agreement; (b) the
                    completeness and accuracy of all Customer Data and other
                    materials provided to Provider by Customer pursuant to this
                    Agreement; and (c) ensuring that Customer Data does not
                    infringe, violate or misappropriate any patents, copyrights,
                    trademarks, trade secrets, or any other intellectual
                    property rights or proprietary rights of any third party or
                    any of Provider&apos;s Intellectual Property Rights.
                  </li>
                  <li>
                    <u>Consent to Use Customer Data</u>. Customer hereby
                    irrevocably grants all such rights and permissions in or
                    relating to Customer Data as are necessary or useful to
                    Provider, its Subcontractors, and the Provider Personnel to
                    enforce this Agreement and exercise Provider&apos;s, its
                    Subcontractors&apos;, and the Provider Personnel&apos;s
                    rights and perform Provider&apos;s, its
                    Subcontractors&apos;, and the Provider Personnel&apos;s
                    obligations hereunder.
                  </li>
                </NestedListInNestedList>
              </li>
            </NestedList>
          </li>
          <li>
            <u>Representations and Warranties</u>.
            <NestedList>
              <li>
                <u>Mutual Representations and Warranties</u>. Each party
                represents and warrants to the other party that: (i) it is duly
                organized, validly existing, and in good standing as a
                corporation or other entity under the Laws of the jurisdiction
                of its incorporation or other organization; (ii) it has the full
                right, power, and authority to enter into and perform its
                obligations and grant the rights, licenses, consents, and
                authorizations it grants or is required to grant under this
                Agreement; (iii) the execution of this Agreement by its
                representative whose signature is set forth at the end of this
                Agreement has been duly authorized by all necessary corporate or
                organizational action of such party; and (iv) when executed and
                delivered by both parties, this Agreement will constitute the
                legal, valid, and binding obligation of such party, enforceable
                against such party in accordance with its terms.
              </li>
              <li>
                <u>
                  Additional Customer Representations, Warranties, and Covenants
                </u>
                . Customer represents, warrants, and covenants to Provider that
                Customer owns or otherwise has and will have the necessary
                rights and consents in and relating to the Customer Data so
                that, as received by Provider and processed in accordance with
                this Agreement, the Customer Data do not and will not infringe,
                misappropriate, or otherwise violate any Intellectual Property
                Rights, or any privacy or other rights of any third party or
                violate any applicable Law.
              </li>
              <li>
                <u>DISCLAIMER OF WARRANTIES</u>. EXCEPT FOR THE EXPRESS
                WARRANTIES SET FORTH IN SECTION 8(a), ALL SERVICES AND PROVIDER
                PROGRAMS ARE PROVIDED “AS IS.” PROVIDER SPECIFICALLY DISCLAIMS
                ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT; ANY WARRANTY
                REGARDING INTEROPERABILITY OF THE SERVICES OR PROVIDER PROGRAMS;
                AND ALL WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF
                DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE
                FOREGOING, PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE
                SERVICES OR PROVIDER PROGRAMS, OR ANY PRODUCTS OR RESULTS OF THE
                USE THEREOF, WILL MEET CUSTOMER&apos;S OR ANY OTHER
                PERSON&apos;S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION,
                ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY
                SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE,
                COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. ALL THIRD PARTY
                MATERIALS ARE PROVIDED “AS IS” AND ANY REPRESENTATION OR
                WARRANTY OF OR CONCERNING ANY THIRD PARTY MATERIALS IS STRICTLY
                BETWEEN CUSTOMER AND THE THIRD PARTY OWNER OR DISTRIBUTOR OF THE
                THIRD PARTY MATERIALS.
              </li>
            </NestedList>
          </li>
          <li>
            <u>Indemnification</u>.
            <NestedList>
              <li>
                <u>Customer Indemnification</u>. Customer shall indemnify,
                defend, and hold harmless Provider and its Subcontractors and
                Affiliates, and each of its and their respective officers,
                directors, employees, agents, successors, and assigns (each, a “
                <b>Provider Indemnitee</b>”) from and against any and all
                losses, damages, deficiencies, claims, actions, judgments,
                settlements, interest, awards, penalties, fines, costs, or
                expenses of whatever kind (including reasonable attorneys&apos;
                fees and the costs of enforcing any right to indemnification
                hereunder and the cost of pursuing any insurance providers),
                incurred by such Provider Indemnitee resulting from any Action
                by a third party (other than an Affiliate of a Provider
                Indemnitee) arise out of or result from, or are alleged to arise
                out of or result from (collectively, a “
                <b>Claim Against Provider Indemnitee</b>”): (i) Customer Data,
                including any processing of Customer Data by or on behalf of
                Provider in accordance with this Agreement; (ii) any other
                materials or information (including any documents, data,
                specifications, software, content, or technology) provided by or
                on behalf of Customer or any Authorized User, including
                Provider&apos;s compliance with any specifications or directions
                provided by or on behalf of Customer or any Authorized user to
                the extent prepared without any contribution by Provider; (iii)
                allegation of facts that, if true, would constitute
                Customer&apos;s breach (whether such breach is by Customer or
                any Authorized User) of any of its representations, warranties,
                covenants, or obligations under this Agreement; (iv) negligence
                or more culpable act or omission (including gross negligence,
                recklessness or willful misconduct) by Customer, any Authorized
                User, or any third party on behalf of Customer or any Authorized
                user, in connection with this Agreement; and (v) any Claim
                Against Provider Indemnitee brought by any Authorized User
                relating to the Services, including for any alleged loss,
                disclosure or potential loss arising from phishing or fraudulent
                or inauthentic emails, SMS text messages, phone calls, social
                media posts, mobile push notifications that Authorized User or
                any other Person may respond to.
              </li>
              <li>
                <u>SOLE REMEDY</u>. THIS SECTION 9 SETS FORTH CUSTOMER&apos;S
                EXCLUSIVE REMEDY AGAINST THE PROVIDER, AND PROVIDER&apos;S SOLE
                LIABILITY AND OBLIGATION TO CUSTOMER, FOR ANY ACTUAL,
                THREATENED, OR ALLEGED CLAIMS ARISING OUT OF, OR RESULTING FROM,
                THE SERVICES, PROVIDER PROGRAMS, OR ANY SUBJECT MATTER OF THIS
                AGREEMENT.
              </li>
            </NestedList>
          </li>
          <li>
            <u>Limitation of Liability</u>.
            <NestedList>
              <li>
                <u>EXCLUSION OF DAMAGES.</u> IN NO EVENT WILL PROVIDER OR ANY OF
                ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, OR SUPPLIERS BE
                LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT
                MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
                CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND
                OTHERWISE, FOR ANY: (i) LOSS OF PRODUCTION, USE, BUSINESS,
                REVENUE, OR PROFIT OR DIMINUTION IN VALUE; (ii) IMPAIRMENT,
                INABILITY TO USE OR LOSS, INTERRUPTION OR DELAY OF THE SERVICES;
                (iii) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF DATA, OR BREACH OF
                DATA OR SYSTEM SECURITY; (iv) COST OF REPLACEMENT GOODS OR
                SERVICES; (v) LOSS OF GOODWILL OR REPUTATION; OR (vi)
                CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
                ENHANCED, OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH
                PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR
                DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE,
                AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF
                ITS ESSENTIAL PURPOSE.
              </li>
              <li>
                <u>CAP ON LIABILITY.</u> IN NO EVENT WILL THE COLLECTIVE
                AGGREGATE LIABILITY OF PROVIDER AND ITS AFFILIATES, LICENSORS,
                SERVICE PROVIDERS, AND SUPPLIERS ARISING OUT OF OR RELATED TO
                THIS AGREEMENT, WHETHER ARISING UNDER OR RELATED TO BREACH OF
                CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY
                OTHER LEGAL OR EQUITABLE THEORY, EXCEED THE TOTAL AMOUNTS PAID
                TO PROVIDER UNDER THIS AGREEMENT IN THE SIX (6) MONTH PERIOD
                PRECEDING THE EVENT GIVING RISE TO THE CLAIM. THE FOREGOING
                LIMITATIONS APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL
                PURPOSE. THE PARTIES AGREE THAT THE PROVISIONS OF THIS AGREEMENT
                ALLOCATE THE RISKS BETWEEN PROVIDER AND CUSTOMER AND THAT
                PROVIDER&apos;S PRICING REFLECTS THE ALLOCATION OF THIS RISK,
                AND THE PROVIDER WOULD NOT OTHERWISE FURNISH THE SERVICES OR
                PROVIDE CUSTOMER ACCESS TO THE PROVIDER PROGRAMS BUT FOR THE
                INCLUSION OF THIS SECTION 10(b).
              </li>
            </NestedList>
          </li>
          <li>
            <u>Term and Termination</u>.
            <NestedList>
              <li>
                <u>Term</u>. Unless otherwise provided in an Order Form mutually
                agreed to by the Parties, the initial term of this Agreement
                commences as of the Effective Date and, unless terminated
                pursuant to any of the Agreement&apos;s express provisions, will
                continue in effect until twelve (12) months from the Effective
                Date (the “<b>Initial Term</b>”). This Agreement will
                automatically renew for additional successive twelve (12) month
                terms unless earlier terminated pursuant to this
                Agreement&apos;s express provisions or either party gives the
                other party written notice of non-renewal at least sixty (60)
                days prior to the expiration of the then-current term (each, a “
                <b>Renewal Term,</b>” and together with the Initial Term, the “
                <b>Term</b>”).
              </li>
              <li>
                <u>Termination</u>. In addition to any other express termination
                right set forth elsewhere in this Agreement:
                <NestedListInNestedList>
                  <li>
                    Provider may terminate this Agreement, effective on written
                    notice to Customer, if Customer: (1) fails to pay any amount
                    when due hereunder, and such failure continues more than ten
                    (10) days after Provider&apos;s delivery of written notice
                    thereof; or (2) breaches any of its obligations under this
                    Agreement;
                  </li>
                  <li>
                    At any time during the Option Period, but only during the
                    Option Period, Customer may terminate this Agreement upon
                    thirty (30) days advance written notice of such termination
                    to Provider;
                  </li>
                  <li>
                    Either party may terminate this Agreement, effective on
                    written notice to the other party, if the other party
                    materially breaches this Agreement, and such breach: (1) is
                    incapable of cure; or (2) being capable of cure, remains
                    uncured thirty (30) days after the non-breaching party
                    provides the breaching party with written notice of such
                    breach; and
                  </li>
                  <li>
                    Either party may terminate this Agreement, effective
                    immediately upon written notice to the other party, if the
                    other party: (1) becomes insolvent or is generally unable to
                    pay, or fails to pay, its debts as they become due; (2)
                    files or has filed against it, a petition for voluntary or
                    involuntary bankruptcy or otherwise becomes subject,
                    voluntarily or involuntarily, to any proceeding under any
                    domestic or foreign bankruptcy or insolvency Law; (3) makes
                    or seeks to make a general assignment for the benefit of its
                    creditors; or (4) applies for or has appointed a receiver,
                    trustee, custodian, or similar agent appointed by order of
                    any court of competent jurisdiction to take charge of or
                    sell any material portion of its property or business.
                  </li>
                </NestedListInNestedList>
              </li>
              <li>
                <u>Effect of Termination</u>. Upon any expiration or termination
                of this Agreement, except as expressly otherwise provided in
                this Agreement:
                <NestedListInNestedList>
                  <li>
                    All rights, licenses, consents, and authorizations granted
                    by either party to the other hereunder will immediately
                    terminate;
                  </li>
                  <li>
                    Provider shall immediately cease all use of any Customer
                    Data and (1) promptly return to Customer, or at
                    Customer&apos;s written request destroy, all documents and
                    tangible materials containing, reflecting, incorporating, or
                    based on Customer Data; and (2) permanently erase all
                    Customer Data from all systems Provider directly or
                    indirectly controls, provided that, for clarity,
                    Provider&apos;s obligations under this Section 11(c)(ii) do
                    not apply to any Resultant Data;
                  </li>
                  <li>
                    Customer shall immediately cease all use of any Services or
                    Provider Programs and (1) promptly return to Provider, or at
                    Provider&apos;s written request destroy, all documents and
                    tangible materials containing, reflecting, incorporating, or
                    based on any Provider Programs or Provider&apos;s
                    Confidential Information; and (2) permanently erase all
                    Provider Programs and Provider&apos;s Confidential
                    Information from all systems Customer directly or indirectly
                    controls; and (3) certify to Provider in a signed and
                    notarized written instrument that it has complied with the
                    requirements of this Section 11(c)(iii);
                  </li>
                  <li>
                    Notwithstanding anything to the contrary in this Agreement,
                    with respect to information and materials then in its
                    possession or control: (1) Provider may retain Customer Data
                    in its then current state and solely to the extent and for
                    so long as required by applicable Law; (2) Provider may also
                    retain Customer Data in its backups, archives, and disaster
                    recovery systems until such Customer Data is deleted in the
                    ordinary course; and (3) all information and materials
                    described in this Section 11(c)(iv) will remain subject to
                    all confidentiality, security, and other applicable
                    requirements of this Agreement;
                  </li>
                  <li>
                    Provider may disable all Customer and Authorized User access
                    to the Provider Programs;
                  </li>
                  <li>
                    If Customer terminates this Agreement pursuant to Sections
                    11(b)(ii) or (iii), Customer will be relieved of any
                    obligation to pay any fees attributable to the period after
                    the effective date of such termination and Provider will
                    refund to Customer fees paid in advance for Services that
                    Provider has not performed as of the effective date of
                    termination (including the pro-rata portion, if any, of the
                    platform fee); and
                  </li>
                  <li>
                    If Provider terminates this Agreement pursuant to Section
                    11(b)(i) or Section 11(b)(iii), all fees that would have
                    become payable had the Agreement remained in effect until
                    expiration of the Term will become immediately due and
                    payable, and Customer shall pay such fees, together with all
                    previously accrued but not yet paid fees and Reimbursable
                    Expenses, on receipt of Provider&apos;s invoice therefor.
                  </li>
                </NestedListInNestedList>
              </li>
              <li>
                <u>Surviving Terms</u>. The provisions set forth in Sections
                2(a), 3(d), 7, 8(d), 9, 10, 11(c), 11(d), 12 and 13 shall
                survive any expiration or termination of this Agreement together
                with any other right or obligation of the parties in this
                Agreement that, by its nature, should survive termination or
                expiration of this Agreement.
              </li>
            </NestedList>
          </li>
          <li>
            <u>Definitions</u>. For the purposes of this Agreement, the
            following terms have the meaning set forth below:
            <NestedList>
              <li>
                “<b>Access Credentials</b>” means any user name, identification
                number, password, license or security key, security token, PIN,
                or other security code, method, technology, or device used,
                alone or in combination, to verify an individual&apos;s identity
                and authorization to access and use the Services.
              </li>
              <li>
                “<b>Action</b>” means any claim, action, cause of action,
                demand, lawsuit, arbitration, inquiry, audit, notice of
                violation, proceeding, litigation, citation, summons, subpoena,
                or investigation of any nature, civil, criminal, administrative,
                regulatory, or other, whether at law, in equity, or otherwise.
              </li>
              <li>
                “<b>Affiliate</b>” of a Person means any other Person that
                directly or indirectly, through one or more intermediaries,
                controls, is controlled by, or is under common control with,
                such Person. The term “control,” including the terms “controlled
                by” and “under common control with,” means the (i) right to
                exercise, directly or indirectly, more than fifty percent (50%)
                of the Person&apos;s voting securities; or (ii) the direct or
                indirect power to direct or cause the direction of the
                management and policies of a Person by contract or otherwise.
              </li>
              <li>
                “<b>Aggregated Data</b>” means anonymous data in aggregate form
                that is generated from, or based upon, Customer&apos;s use of
                the Provider Programs (including by way of example, amount of
                uptime for the service monthly and annually, number and types of
                system incidents encountered and their trends, analysis of which
                functions within the Provider Programs are more/less frequently
                used, security threats encountered and handled and/or metrics,
                SKUs processed by Provider Systems to provide the Services and
                Provider Programs).
              </li>
              <li>
                “<b>Application</b>” means any software application or software
                program, including any mobile software application or mobile
                software program (together with any applicable documentation
                thereto, and programming and user interfaces therefor),
                including any Updates thereto, owned or controlled by Provider
                and designated in the Order Form(s).
              </li>
              <li>
                “<b>Authorized Users</b>” means Customer&apos;s and its
                Affiliates&apos; employees, consultants, contractors, agents,
                and any other Person (including any volunteer) acting for the
                benefit of the Customer, (i) who are authorized by Customer to
                access and use the Services under the rights granted to Customer
                pursuant to this Agreement; and (ii) for whom access to the
                Services has been purchased hereunder.
              </li>
              <li>
                “<b>Confidential Information</b>” means (i) any information
                disclosed by Provider to the Customer or Authorized Users,
                either directly or indirectly, in writing, orally or by
                inspection of tangible objects, including, without limitation,
                algorithms, AI Data, Trained Models, business plans, customer
                data, customer lists, customer names, deposit lists, depositor
                names, lenders, borrowers, designs documents, drawings,
                engineering information, financial analysis, forecasts,
                formulas, hardware configuration information, know how, ideas,
                inventions, market information, marketing plans, processes,
                products, product plans, research, specifications, Intellectual
                Property or any information which is designated as
                “confidential,” “proprietary” or some similar designation
                (collectively, the “Disclosed Materials”) and (ii) any
                information otherwise obtained, directly or indirectly, by
                Customer or Authorized Users through inspection, review or
                analysis of the Disclosed Materials. Confidential Information
                may also include information of a third party that is in
                Provider&apos;s possession and is disclosed to Customer or
                Authorized Users pursuant to this Agreement. Confidential
                Information shall not, however, include any information that (1)
                was publicly known and made generally available in the public
                domain prior to the time of disclosure by Provider or any third
                party to the Customer or Authorized Users; (2) becomes publicly
                known and made generally available after disclosure by Provider
                to Customer or Authorized Users through no action or inaction of
                the Customer or Authorized Users; (3) is already in the
                possession of the Customer at the time of disclosure by Provider
                as shown by the Customer&apos;s files and records immediately
                prior to the time of disclosure; (4) is obtained by the Customer
                from a third party lawfully in possession of such information
                and without a breach of such third party&apos;s obligations of
                confidentiality; or (5) is independently developed by the
                Customer without use of or reference to Provider&apos;s or any
                third party&apos;s Confidential Information, as shown by
                documents and other competent evidence in the Customer&apos;s
                possession.
              </li>
              <li>
                “<b>Content</b>” means any and all comments, feedback,
                background information, messages, photographs, videos,
                recordings, suggestions, questions, reviews, articles, columns,
                manuscripts, literary works, memoranda or other works of
                authorship in any form or format, and any other related content
                or information submitted by Customer or any Authorized Users to
                or through any message boards, chat rooms, personal web pages or
                profiles, forums, bulletin boards or other interactive features
                on the Provider Programs or the Services.
              </li>
              <li>
                “<b>Customer Data</b>” means the Content any other information,
                data, and other content, in any form or medium that is
                collected, downloaded, or otherwise received, directly or
                indirectly from Customer or an Authorized User by or through the
                Services. For the avoidance of doubt, Customer Data does not
                include Resultant Data or any other information reflecting the
                access or use of the Services by or on behalf of Customer or any
                Authorized User.
              </li>
              <li>
                “<b>Customer Systems</b>” means the Customer&apos;s information
                technology infrastructure, including computers, software,
                hardware, databases, electronic systems (including database
                management systems), and networks, whether operated directly by
                Customer or through the use of third-party services.
              </li>
              <li>
                “<b>Documentation</b>” means any manuals, instructions, or other
                documents or materials that the Provider provides or makes
                available to Customer in any form or medium and which describe
                the functionality, components, features, or requirements of the
                Services or Provider Programs, including any aspect of the
                installation, configuration, integration, operation, use,
                support, or maintenance thereof.
              </li>
              <li>
                “<b>Harmful Code</b>” means any software, hardware, or other
                technology, device, or means, including any virus, worm,
                malware, or other malicious computer code, the purpose or effect
                of which is to (i) permit unauthorized access to, or to destroy,
                disrupt, disable, distort, or otherwise harm or impede in any
                manner any (1) computer, software, firmware, hardware, system,
                or network; or (2) any application or function of any of the
                foregoing or the security, integrity, confidentiality, or use of
                any data Processed thereby; or (ii) prevent Customer or any
                Authorized User from accessing or using the Services or Provider
                Systems as intended by this Agreement. Harmful Code does not
                include any Provider Disabling Device.
              </li>
              <li>
                “<b>Intellectual Property</b>” means any intellectual property
                in any jurisdiction throughout the world, including, but not
                limited to: (i) all inventions (whether patentable or
                unpatentable and whether or not reduced to practice), all
                improvements thereto and all patents, patent applications and
                patent disclosures, together with all reissuances,
                continuations, continuations-in-part, revisions, extensions and
                reexaminations thereof, (ii) all trademarks, service marks,
                trade dress, logos, slogans, trade names, corporate names,
                Internet domain names, uniform resource locators and e-mail
                addresses, and rights in telephone numbers, together with all
                translations, adaptations, derivations and combinations thereof
                and including all goodwill associated therewith and all
                applications, registrations and renewals in connection
                therewith, (iii) all copyrightable works, all copyrights, and
                all applications, registrations and renewals in connection
                therewith, (iv) all trade secrets and confidential business
                information (including ideas, research and development,
                know-how, formulas, compositions, manufacturing and production
                processes and techniques, technical data, designs, drawings,
                specifications, customer and supplier lists, pricing and cost
                information and business and marketing plans and proposals), (v)
                all software, including computer programs, machine-readable
                instruction sets or data in computerized form, whether in source
                code, object code or other form, and all data, databases and
                related documentation, including AI Data, Trained Data and
                Trained Models, (vi) all other proprietary rights and (vii) all
                copies and tangible embodiments thereof (in whatever form or
                medium).
              </li>
              <li>
                “<b>Intellectual Property Rights</b>” means any and all
                registered and unregistered rights granted, applied for, or
                otherwise, now or hereafter in existence under or related to the
                Intellectual Property.
              </li>
              <li>
                “<b>Law</b>” means any statute, law, ordinance, regulation,
                rule, code, order, constitution, treaty, common law, judgment,
                decree, or other requirement of any federal, state, local, or
                foreign government or political subdivision thereof, or any
                arbitrator, court, or tribunal of competent jurisdiction.
              </li>
              <li>
                “<b>Minimum System Requirements</b>” means the minimum hardware,
                operating system, application software, internet access,
                internet browser, Customer Systems access to Third Party
                Materials, including application store fronts and related third
                party programs that Customer must satisfy, at its own cost and
                expense in order to install, execute and use the Provider
                Programs as contemplated in this Agreement.
              </li>
              <li>
                “<b>Option Period</b>” shall mean that thirty (30) day period
                commencing one hundred twenty (120) days following the Effective
                Date and expiring thirty (30) days thereafter.
              </li>
              <li>
                <b>Order Forms</b>” means the Provider approved forms submitted
                by Customer to Provider, evidencing the purchase of the rights
                to access and use the Services and Provider Program(s), as
                executed and delivered by Customer and Provider (including
                execution and delivery by way of online or electronic
                acceptance).
              </li>
              <li>
                “<b>Platforms</b>” means any websites, internet domains
                (including any restricted access portions thereof), and/or any
                software or platform to provide hosted “software as a service”
                for Applications owned or controlled by the Provider.
              </li>
              <li>
                “<b>Provider Disabling Device</b>” means any software, hardware,
                or other technology, device, or means (including any back door,
                time bomb, time out, drop dead device, software routine, or
                other disabling device) used by Provider or its designee to
                disable Customer&apos;s or any Authorized User&apos;s access to
                or use of the Services automatically with the passage of time or
                under the positive control of Provider or its designee.
              </li>
              <li>
                “<b>Person</b>” means any individual, firm, corporation,
                partnership, limited liability company, trust, estate, joint
                venture, association, governmental authority or other entity.
              </li>
              <li>
                “<b>Professional Services</b>” means services provided by
                Provider pursuant to one or more Order Forms that describe any
                Services (including without limitation training in the use and
                application of the Services or Provider Programs),
                modifications, customizations or other changes to the Provider
                Programs for the benefit of Customer and provides the details
                necessary to determine deliverables, acceptance and testing
                criteria, and any milestones or other benchmarks and terms and
                conditions, that the parties may agree upon in writing.
              </li>
              <li>
                “<b>Provider Personnel</b>” means all individuals involved in
                the performance of Services as employees, agents, or independent
                contractors of Provider or any Subcontractor.
              </li>
              <li>
                “<b>Provider Programs</b>” means the Confidential Information,
                Services, Machine Learning Outputs, Applications, Platforms,
                Specifications, Documentation, AI Data, Trained Data, Trained
                Models, and Provider Systems and any and all other Intellectual
                Property, including any deliverables, technical or functional
                descriptions, requirements, plans, or reports, that are provided
                or used by Provider or any Subcontractor in connection with the
                Services or otherwise comprise or relate to the Services or
                Provider Systems. For the avoidance of doubt, Provider Programs
                include Resultant Data and any information, data, or other
                content derived from Provider&apos;s monitoring of
                Customer&apos;s access to or use of the Services, but do not
                include Customer Data.
              </li>
              <li>
                “<b>Provider Systems</b>” means the information technology
                infrastructure used by or on behalf of Provider in performing
                the Services, including all computers, software, hardware,
                databases, electronic systems (including database management
                systems), and networks, whether operated directly by Provider or
                through the use of third-party services.
              </li>
              <li>
                “<b>Resultant Data</b>” means data and information related to
                Customer&apos;s use of the Services that is used by Provider in
                an aggregate and anonymized manner, including Aggregated Data
                and to compile statistical and performance information related
                to the provision and operation of the Services.
              </li>
              <li>
                “<b>Services</b>” means software as a service consisting of
                making the Provider Programs available for use by Customer
                pursuant to the terms hereof and the system administration,
                system management and system monitoring that Provider performs
                for the Provider Programs, including: installing, hosting and
                maintaining the Provider Programs, and storing and maintaining
                Customer Data as necessary for the use of the Provider
                Programs(s), but subject to any data storage limits set forth in
                any Order Form.
              </li>
              <li>
                “<b>Specifications</b>” means the specifications for the
                Services, if any, as set forth in the Order Form(s).
              </li>
              <li>
                “<b>Subcontractor</b>” means any and all third parties engaged
                by the Provider to perform the Services.
              </li>
              <li>
                “<b>Terms of Use</b>” shall mean the Provider&apos;s most recent
                Terms of Use (available at{' '}
                <Link component={RouterLink} to="/terms-of-use">
                  https://pixydocs.zerapix.com/terms-of-use
                </Link>
                ), as may be amended from time to time by the Provider in its
                sole discretion.
              </li>
              <li>
                “<b>Third Party Materials</b>” means materials and information,
                in any form or medium, including any open-source or other
                software, documents, data, content, specifications, products,
                equipment, or components of or relating to the Services that are
                not proprietary to Provider.
              </li>
              <li>
                “<b>Trained Data</b>” means all data, information and
                Intellectual Property created by Provider using key words, key
                terms and instructions provided by Customer for creating the
                Trained Models and AI Data.
              </li>
              <li>
                “<b>Trained Models</b>” means those proprietary learning models,
                statistical models and algorithms for the Services in connection
                with Customer&apos;s use of the Services during the Term.
              </li>
              <li>
                “<b>Updates</b>” means any updates, modifications, enhancement
                or improvements to the Provider Program(s), including any
                correction, bug-fix, patch or other work-around relating to the
                Provider Program(s).
              </li>
            </NestedList>
          </li>
          <li>
            <u>Miscellaneous</u>.
            <NestedList>
              <li>
                <u>Further Assurances</u>. On a party&apos;s reasonable request,
                the other party shall, at the requesting party&apos;s sole cost
                and expense, execute and deliver all such documents and
                instruments, and take all such further actions, as may be
                necessary to give full effect to this Agreement.
              </li>
              <li>
                <u>Relationship of the Parties</u>. The relationship between the
                parties is that of independent contractors. Nothing contained in
                this Agreement shall be construed as creating any agency,
                partnership, joint venture, or other form of joint enterprise,
                employment, or fiduciary relationship between the parties, and
                neither party shall have authority to contract for or bind the
                other party in any manner whatsoever.
              </li>
              <li>
                <u>Public Announcements</u>. Provider and Customer trademarks,
                trade names, service marks, and logos, whether or not registered
                (<b>“Marks”</b>), are the sole and exclusive property of the
                respective owning party, which owns all right, title and
                interest therein. Provider may: (a) use the Customer&apos;s name
                and/or logo within product literature, press release(s), social
                media, and other marketing materials; (b) quote the
                Customer&apos;s statements in one or more press releases; and/or
                (c) make such other use of the Customer&apos;s name and/or logo
                as may be agreed between the parties. Additionally, Provider may
                include Customer&apos;s name and/or logo within its list of
                customers for general promotional purposes. Provider shall
                comply with Customer&apos;s trademark use guidelines as such are
                communicated to the Provider in writing and Provider shall use
                the Customer&apos;s Marks in a manner which is consistent with
                industry practice. Neither party grants to the other any title,
                interest or other right in any Marks except as provided in this
                Section.
              </li>
              <li>
                <u>Notices</u>. Any notice to be given hereunder shall be in
                writing and sent and addressed to the parties as provided on the
                signature page hereto. Notices sent in accordance with this
                Section 13(d) will be deemed effectively given: (i) when
                received, if delivered by hand or sent via certified mail; (ii)
                when received, if sent by a nationally recognized overnight
                courier; (ii) when sent, by email, (in each case, with
                confirmation of transmission), if sent during the
                addressee&apos;s normal business hours, and on the next business
                day, if sent after the addressee&apos;s normal business hours;
                and (iv) in the case of notice sent via certified mail and
                delivery is refused by the recipient, on the date when such
                delivery is refused.
              </li>

              <li>
                <u>Interpretation</u>. For purposes of this Agreement: (i) the
                words ‘include,” “includes,” and “including” are deemed to be
                followed by the words “without limitation”; (ii) the word “or”
                is not exclusive; (iii) the words “herein,” “hereof,” “hereby,”
                “hereto,” and “hereunder” refer to this Agreement as a whole;
                (iv) words denoting the singular have a comparable meaning when
                used in the plural, and vice-versa; and (v) words denoting any
                gender include all genders. Unless the context otherwise
                requires, references in this Agreement: (x) to sections,
                exhibits, schedules, attachments, and appendices mean the
                sections of, and exhibits, schedules, attachments, and
                appendices attached to, this Agreement; (y) to an agreement,
                instrument, or other document means such agreement, instrument,
                or other document as amended, supplemented, and modified from
                time to time to the extent permitted by the provisions thereof;
                and (z) to a statute means such statute as amended from time to
                time and includes any successor legislation thereto and any
                regulations promulgated thereunder. The parties intend this
                Agreement to be construed without regard to any presumption or
                rule requiring construction or interpretation against the party
                drafting an instrument or causing any instrument to be drafted..
              </li>

              <li>
                <u>Headings</u>. The headings in this Agreement are for
                reference only and do not affect the interpretation of this
                Agreement.
              </li>

              <li>
                <u>Entire Agreement</u>. This Agreement, including the Terms of
                Use and all Order Forms between the parties hereto and
                attachments thereto, constitutes the entire agreement between
                the parties and supersedes all prior and contemporaneous
                agreements, proposals or representations, written or oral,
                concerning it subject matter. However, to the extent of any
                conflict or inconsistency between the provisions in the body of
                this Agreement, any Order Form and/or the Terms of Use, the
                terms of this Agreement shall prevail. Notwithstanding any
                language to the contrary therein, no terms or conditions stated
                in Customer&apos;s purchase order or other Customer order
                documentation shall incorporated into or form any part of this
                Agreement, and all such terms or conditions shall be null and
                void.
              </li>
              <li>
                <u>Modification</u>. Any modification to this Agreement shall be
                in writing and signed by both parties. In the event that a term
                or condition this Agreement conflicts with a term or condition
                in an Order Form, the term of this Agreement shall have priority
                unless such conflicting term is explicitly modified by such
                Order Form and such Order Form is executed by both parties.
              </li>
              <li>
                <u>Assignment</u>. Customer shall not assign or otherwise
                transfer any of its rights, or delegate or otherwise transfer
                any of its obligations or performance under this Agreement, in
                each case whether voluntarily, involuntarily, by operation of
                law, or otherwise, without Provider&apos;s prior written
                consent. For purposes of the preceding sentence, and without
                limiting its generality, any merger, consolidation, or
                reorganization involving Customer (regardless of whether
                Customer is a surviving or disappearing entity) will be deemed
                to be a transfer of rights, obligations, or performance under
                this Agreement for which Provider&apos;s prior written consent
                is required. No assignment, delegation, or transfer will relieve
                Customer of any of its obligations or performance under this
                Agreement. Any purported assignment, delegation, or transfer in
                violation of this Section 13(i) is void. For the avoidance of
                doubt, Provider may assign this Agreement, including any rights
                hereunder, to any third party without providing prior notice to,
                receiving prior consent from, the Customer. This Agreement is
                binding upon and inures to the benefit of the parties hereto and
                their respective successors and permitted assigns.
              </li>
              <li>
                <u>Waiver</u>. No waiver by any party of any of the provisions
                hereof shall be effective unless explicitly set forth in writing
                and signed by the party so waiving. Except as otherwise set
                forth in this Agreement, no failure to exercise, or delay in
                exercising, any rights, remedy, power, or privilege arising from
                this Agreement will operate or be construed as a waiver thereof;
                nor shall any single or partial exercise of any right, remedy,
                power, or privilege hereunder preclude any other or further
                exercise thereof or the exercise of any other right, remedy,
                power, or privilege.
              </li>
              <li>
                <u>Force Majeure</u>. In no event will Provider be liable or
                responsible to Customer, or be deemed to have defaulted under or
                breached this Agreement, for any failure or delay in fulfilling
                or performing any term of this Agreement, when and to the extent
                such failure or delay is caused by any circumstances beyond
                Provider&apos;s reasonable control (a “Force Majeure Eventther
                civil unrest, epidemics or pandemics as defined by the United
                States Centers for Disease Control and Prevention, embargoes or
                blockades in effect on or after the date of this Agreement,
                national or regional emergency, strikes, labor stoppages or
                slowdowns or other industrial disturbances, passage of Law or
                any action taken by a governmental or public authority,
                including imposing an embargo, export or import restriction,
                quota, or other restriction or prohibition or any complete or
                partial government shutdown, or national or regional shortage of
                adequate power or telecommunications or transportation. Either
                party may terminate this Agreement if a Force Majeure Event
                continues substantially uninterrupted for a period of thirty
                (30) days or more. In the event of any failure or delay caused
                by a Force Majeure Event, Provider shall give prompt written
                notice to Customer stating the period of time the occurrence is
                expected to continue and use commercially reasonable efforts to
                end the failure or delay and minimize the effects of such Force
                Majeure Event.
              </li>
              <li>
                <u>No Third Party Beneficiaries</u>. This Agreement is for the
                sole benefit of the parties hereto and their respective
                successors and permitted assigns and nothing herein, express or
                implied, is intended to or shall confer upon any other Person
                any legal or equitable right, benefit, or remedy of any nature
                whatsoever under or by reason of this Agreement.
              </li>
              <li>
                <u>Severability</u>. If any term or provision of this Agreement
                is invalid, illegal, or unenforceable in any jurisdiction, such
                invalidity, illegality, or unenforceability shall not affect any
                other term or provision of this Agreement or invalidate or
                render unenforceable such term or provision in any other
                jurisdiction. Upon such determination that any term or other
                provision is invalid, illegal, or unenforceable, the parties
                hereto shall negotiate in good faith to modify this Agreement so
                as to effect the original intent of the parties as closely as
                possible in a mutually acceptable manner in order that the
                transactions contemplated hereby be consummated as originally
                contemplated to the greatest extent possible.
              </li>
              <li>
                <u>Governing Law</u>. This Agreement is governed by and
                construed in accordance with the Laws of the State of Utah
                without giving effect to any choice or conflict of law provision
                or rule that would require or permit the application of the laws
                of any jurisdiction other than those of the State of Utah. ANY
                LEGAL SUIT, ACTION, OR PROCEEDING ARISING OUT OF OR RELATED TO
                THIS AGREEMENT OR THE RIGHTS OR LICENSES GRANTED HEREUNDER WILL
                BE INSTITUTED EXCLUSIVELY IN THE FEDERAL COURTS OF THE UNITED
                STATES OR THE COURTS OF THE STATE OF UTAH IN EACH CASE LOCATED
                IN THE CITY OF SALT LAKE AND COUNTY OF SALT LAKE, AND EACH PARTY
                IRREVOCABLY SUBMITS TO THE EXCLUSIVE JURISDICTION OF SUCH COURTS
                IN ANY SUCH SUIT, ACTION, OR PROCEEDING. Service of process,
                summons, notice, or other document by mail to such party&apos;s
                address set forth herein shall be effective service of process
                for any suit, action, or other proceeding brought in any such
                court.
              </li>
              <li>
                <u>WAIVER OF JURY TRIAL</u>. EACH PARTY IRREVOCABLY AND
                UNCONDITIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY
                IN RESPECT OF ANY LEGAL ACTION ARISING OUT OF OR RELATING TO
                THIS AGREEMENT OR THE TRANSACTION CONTEMPLATED HEREBY.
              </li>
              <li>
                <u>Equitable Relief</u>. Customer acknowledges and agrees that a
                breach or threatened breach by Customer of any of its
                obligations under Sections 2(a), 3(c), 3(d), or 4(e) would cause
                Provider irreparable harm for which monetary damages would not
                be an adequate remedy and that, in the event of such breach or
                threatened breach, Provider shall be entitled to equitable
                relief, including a restraining order, an injunction, specific
                performance, and any other relief that may be available from any
                court, without any requirement to post a bond or other security,
                or to prove actual damages or that monetary damages are not an
                adequate remedy. Such remedies are not exclusive and are in
                addition to all other remedies that may be available at law, in
                equity, or otherwise.
              </li>
              <li>
                <u>Attorneys&apos; Fees</u>. In the event that any action, suit,
                or other legal or administrative proceeding is instituted or
                commenced by either party against the other party arising out of
                or related to this Agreement, the prevailing party is entitled
                to recover its reasonable attorneys&apos; fees and court costs
                from the non-prevailing party.
              </li>
              <li>
                <u>Counterparts</u>. This Agreement may be executed in
                counterparts, each of which is deemed an original, but all of
                which together are deemed to be one and the same agreement. A
                signed copy of this Agreement delivered by facsimile, email, or
                other means of electronic transmission is deemed to have the
                same legal effect as delivery of an original signed copy of this
                Agreement.
              </li>
            </NestedList>
          </li>
        </Stack>
      </Typography>
    </Container>
  )
}
