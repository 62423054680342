import { Stack } from '@mui/material'
import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { Organization } from '@/types/organizations'
import { Team } from '@/types/teams'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { useUpdateTeam } from '@/service-library/hooks/teams'
import {
  useDeleteByTeamAndOrg,
  useUpdateTeamVisibleOrgs,
} from '@/service-library/hooks/team-visible-orgs'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import OrganizationTreeSearchSkeleton, {
  OrgTreeSkeleton,
} from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import OrganizationTreeWithImplicitInheritanceSearch from '@/components/organizations-tree/OrganizationTreeWithImplicitInheritanceSearch'

type TeamOrganizationsProps = {
  canAddRemoveOrgs: boolean
  team: Team
  detailQueryKey: QueryKey
}

export default function TeamOrganizations({
  canAddRemoveOrgs,
  team,
  detailQueryKey,
}: TeamOrganizationsProps) {
  const { visible_orgs_ids: teamOrgIds = [], owner_org_id } = team

  const { organizations: allOrganizations = [], isFetchingAll } =
    useAllOrganizations({
      filters: {
        self_and_descendants_for_id: owner_org_id,
      },
    })

  const rootOrganization = allOrganizations.find(
    (org) => !org.parent_org_id,
  ) as Organization

  const { updateTeam } = useUpdateTeam({ detailQueryKey })
  const queryClient = useQueryClient()

  const { updateTeamVisibleOrgs: createTeamVisibleOrgs } =
    useUpdateTeamVisibleOrgs({
      onMutate: async ([{ org_id }]) => {
        await queryClient.cancelQueries({ queryKey: detailQueryKey })

        queryClient.setQueryData<Team>(detailQueryKey, {
          ...team,
          visible_orgs_ids: [...teamOrgIds, org_id],
        })
      },
      onError: () => {
        showErrorSnackbar(
          'Unable to update organization(s). Please try again later.',
        )
        queryClient.invalidateQueries(detailQueryKey)
      },
    })

  const { deleteByTeamAndOrgs } = useDeleteByTeamAndOrg({
    onMutate: async ([{ org_id }]) => {
      await queryClient.cancelQueries({ queryKey: detailQueryKey })

      queryClient.setQueryData(detailQueryKey, {
        ...team,
        visible_orgs_ids: teamOrgIds.filter((orgId) => orgId !== org_id),
      })
    },
    onError: () => {
      showErrorSnackbar(
        'Unable to remove organization(s). Please try again later.',
      )
      queryClient.invalidateQueries(detailQueryKey)
    },
  })

  function handleAddOrgToTeam(orgId: string) {
    if (!orgId) return
    createTeamVisibleOrgs([
      { id: generateUuid(), team_id: team.id, org_id: orgId },
    ])
  }

  function handleRemoveOrgFromTeam(removedOrgId: string) {
    deleteByTeamAndOrgs([{ team_id: team.id, org_id: removedOrgId }])
  }

  return (
    <Stack spacing={2} direction="row">
      {isFetchingAll &&
        (canAddRemoveOrgs ? (
          <OrganizationTreeSearchSkeleton />
        ) : (
          <OrgTreeSkeleton />
        ))}
      {!isFetchingAll && rootOrganization?.id && (
        <OrganizationTreeWithImplicitInheritanceSearch
          checkboxIsDisabled={!canAddRemoveOrgs}
          label="Add or Remove Organization"
          organizations={allOrganizations}
          rootTreeOrgId={rootOrganization.id}
          selectedOrgsIds={teamOrgIds}
          onDeselectOrg={handleRemoveOrgFromTeam}
          onSelectOrg={handleAddOrgToTeam}
          setSelectedOrgs={(orgs) => {
            const orgIds = orgs.map(({ id }) => id)
            updateTeam({ ...team, visible_orgs_ids: orgIds })
          }}
          showSearchInput={canAddRemoveOrgs}
        />
      )}
    </Stack>
  )
}
