import { DataListColumn } from '@/types/data-lists'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<DataListColumn>({
  pathCategory: 'data_list_columns',
})

export function useGetDataListColumn({
  id,
  ...options
}: UseGetDetailOptions<DataListColumn>) {
  const query = useGetDetail<DataListColumn>({
    id,
    queryKey: queryKeys.dataListColumns.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    dataListColumn: query.data,
    ...query,
  }
}

export function useCreateDataListColumn(
  options: UseCreateDetailOptions<DataListColumn> = {},
) {
  const mutation = useCreateDetail<DataListColumn>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDataListColumn: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDataListColumn(
  options: UseUpdateDetailOptions<DataListColumn> = {},
) {
  const mutation = useUpdateDetail<DataListColumn>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDataListColumn: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataListColumn(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<DataListColumn>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDataListColumn: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDataListColumns({
  filters,
  ...options
}: UseGetListOptions<DataListColumn> = {}) {
  const query = useGetList<DataListColumn>({
    filters,
    queryKey: queryKeys.dataListColumns.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    dataListColumns: query.allData,
    ...query,
  }
}

export function useUpdateDataListColumns(
  options: UseUpdateListOptions<DataListColumn> = {},
) {
  const mutation = useUpdateList<DataListColumn>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDataListColumns: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataListColumns({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DataListColumn>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDataListColumns: mutation.mutateAsync,
    ...mutation,
  }
}
