import { ReactNode } from 'react'
import {
  Box,
  IconButton,
  Drawer as MUIDrawer,
  DrawerProps as MUIDrawerProps,
  Stack,
  Typography,
} from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { useDrawerPortalContext } from './DrawerPortalProvider'
import { createPortal } from 'react-dom'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

type DrawerProps = {
  children: ReactNode
  overlay: OverlayState
  size?: 'sm' | 'md' | 'lg'
  title?: string
  showToggleButton?: boolean
  showMiniDrawer?: boolean
  miniDrawerChildren?: ReactNode
  toggleOpenIcon?: ReactNode
  toggleCloseIcon?: ReactNode
  transparent?: boolean
} & MUIDrawerProps

const MINI_DRAWER_WIDTH = 48

const drawerSizeMap = {
  sm: 240,
  md: 300,
  lg: 360,
}

export default function Drawer({
  anchor = 'left',
  children,
  overlay,
  title,
  size = 'md',
  variant = 'persistent',
  showToggleButton,
  showMiniDrawer,
  miniDrawerChildren,
  toggleOpenIcon = <KeyboardArrowRight />,
  toggleCloseIcon = <KeyboardArrowLeft />,
  transparent,
}: DrawerProps) {
  const { portalNode, secondPortalNode } = useDrawerPortalContext()
  const drawerSize = drawerSizeMap[size]
  const node = anchor === 'left' ? portalNode : secondPortalNode

  let paperWidth = drawerSize
  if (showMiniDrawer && !overlay.isOpen) {
    paperWidth = MINI_DRAWER_WIDTH
  }

  const closedWidth = showMiniDrawer ? MINI_DRAWER_WIDTH : 0
  const drawerWidth = overlay.isOpen ? drawerSize : closedWidth

  return node
    ? createPortal(
        <MUIDrawer
          open={showMiniDrawer ? true : overlay.isOpen}
          variant={variant}
          anchor={anchor}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'hidden',
              width: paperWidth,
              position: 'absolute',
              height: '100%',
              background: transparent ? 'transparent' : undefined,
              border: transparent ? 'none' : undefined,
              transition: 'width 0.2s ease-in-out',
            },
          }}
          sx={{
            width: drawerWidth,
            height: '100%',
            flexShrink: 0,
            transition: 'width 0.2s ease-in-out',
          }}
        >
          {(showMiniDrawer || title) && (
            <Stack
              direction="row"
              sx={{ pt: 1, pl: 2, pr: 1, px: overlay.isOpen ? undefined : 1 }}
              justifyContent={overlay.isOpen ? 'space-between' : 'center'}
            >
              {overlay.isOpen && <Typography variant="h6">{title}</Typography>}
              {showToggleButton && (
                <IconButton onClick={overlay.toggle} size="small">
                  {overlay.isOpen ? toggleCloseIcon : toggleOpenIcon}
                </IconButton>
              )}
            </Stack>
          )}
          <Box
            sx={{
              height: '100%',
              overflowY: 'auto',
              display: showMiniDrawer && !overlay.isOpen ? 'none' : undefined,
            }}
          >
            {/* We hide the children instead of de-rendering them because initial render is expensive */}
            {children}
          </Box>
          {showMiniDrawer && !overlay.isOpen && miniDrawerChildren}
        </MUIDrawer>,
        node,
      )
    : null
}
