import { useEffect, useState } from 'react'
import FileIcon from '@mui/icons-material/InsertDriveFile'
import {
  Button,
  List,
  ListItem,
  Stack,
  ListItemIcon,
  useTheme,
} from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { Organization } from '@/types/organizations'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import OrganizationTreeSearch from '@/components/organizations-tree/OrganizationTreeSearch'
import OrganizationTreeSearchSkeleton from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import { useRecentOrganizationsContext } from '@/components//organizations/RecentOrganizationsProvider'

export type UploadOrgPickerDialogProps = {
  overlay: OverlayState
  rootTreeOrgId: string
  uploadFiles?: FileList
  onConfirm: (targetOrg: Organization) => void
}

export default function UploadOrgPickerDialog({
  overlay,
  rootTreeOrgId,
  uploadFiles,
  onConfirm,
}: UploadOrgPickerDialogProps) {
  const projectOrgsContext = useOrganizationsContext()
  const [, setRecentOrgs] = useRecentOrganizationsContext()

  const hasOrgsProvider = !!Object.keys(projectOrgsContext).length

  const allOrganizationsQuery = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootTreeOrgId,
    },
    enabled: !hasOrgsProvider && overlay.isOpen,
  })

  const { organizations, isFetchingAll } = hasOrgsProvider
    ? projectOrgsContext
    : allOrganizationsQuery

  const theme = useTheme()
  const [selectedOrgId, setSelectedOrgId] = useState<string>(
    organizations[0]?.id || '',
  )
  const selectedOrg = organizations.find((org) => org.id === selectedOrgId)

  function handleContinue(targetOrg?: Organization | null) {
    overlay.close()
    if (!targetOrg) return

    onConfirm(targetOrg)
    setRecentOrgs((curr) => {
      const newValue = [
        targetOrg,
        ...curr.filter(({ id }) => id !== targetOrg.id),
      ]
      if (newValue.length > 5) newValue.pop()
      return newValue
    })
  }

  const displayedFileNames = uploadFiles ? Array.from(uploadFiles) : []

  // Set the selected org to the first org in the list when the dialog is opened
  useEffect(() => {
    if (
      overlay.isOpen &&
      !(selectedOrgId && organizations.find(({ id }) => id === selectedOrgId))
    ) {
      setSelectedOrgId(organizations[0]?.id || '')
    }
  }, [organizations, overlay.isOpen, selectedOrgId])

  return (
    <Dialog {...overlay} title="Upload Documents" maxWidth="md">
      <DialogContent disablePadding>
        <Stack spacing={2} sx={{ mx: 3, pb: 1 }}>
          <List sx={{ maxHeight: '350px', ml: 1.5, overflow: 'auto' }}>
            {displayedFileNames.length > 4 ? (
              <ListItem
                sx={{
                  border: `solid 1px ${theme.palette.divider}`,
                  borderRadius: 1,
                  pl: 1,
                  pr: 3,
                  mb: 1,
                }}
              >
                <ListItemIcon>
                  <FileIcon />
                </ListItemIcon>
                {displayedFileNames.length} documents
              </ListItem>
            ) : (
              displayedFileNames.map((file) => (
                <ListItem
                  key={file.name}
                  sx={{
                    border: `solid 1px ${theme.palette.divider}`,
                    borderRadius: 1,
                    pl: 1,
                    pr: 3,
                    mb: 1,
                  }}
                >
                  <ListItemIcon>
                    <FileIcon />
                  </ListItemIcon>
                  {file.name}
                </ListItem>
              ))
            )}
          </List>
          {isFetchingAll ? (
            <OrganizationTreeSearchSkeleton />
          ) : (
            selectedOrg && (
              <OrganizationTreeSearch
                organizations={organizations}
                rootTreeOrgId={rootTreeOrgId}
                selectedOrgId={selectedOrgId}
                selectedOrganization={selectedOrg}
                setSelectedOrgId={setSelectedOrgId}
              />
            )
          )}
        </Stack>
      </DialogContent>

      <DialogFooter>
        <Button onClick={overlay.close} variant="text">
          Cancel
        </Button>

        <Button
          disabled={!selectedOrg}
          onClick={() => {
            handleContinue(selectedOrg)
          }}
        >
          Continue
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
