import { ReactNode } from 'react'
import { Stack, Typography, TypographyProps } from '@mui/material'

type LabeledDataProps = {
  label: ReactNode
  data: ReactNode
  dataColor?: TypographyProps['color']
  hideLabel?: boolean
  labelColor?: TypographyProps['color']
  dense?: boolean
}

export default function LabeledData({
  label,
  data,
  dataColor,
  hideLabel,
  labelColor = 'text.secondary',
  dense = false,
}: LabeledDataProps) {
  return (
    <Stack sx={{ minWidth: 0 }}>
      {!hideLabel && (
        <Typography
          component="div"
          variant="caption"
          sx={{
            maxWidth: '100%', // Needed for ellipsis truncation
            fontSize: dense ? 11 : undefined,
            lineHeight: dense ? '12px' : '16px',
          }}
          color={labelColor}
          noWrap
        >
          {label}
        </Typography>
      )}

      <Typography
        color={dataColor}
        variant={dense ? 'caption' : 'body2'}
        component="div"
        noWrap
        sx={{
          maxWidth: '100%', // Needed for ellipsis truncation
        }}
      >
        {/* &nbsp; is a non-breaking space, keeps the height as if there was text */}
        {data || <>&nbsp;</>}{' '}
      </Typography>
    </Stack>
  )
}
