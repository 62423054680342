import { Box, Stack, Skeleton, SkeletonProps } from '@mui/material'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'

export function OrgTreeSkeleton(props?: SkeletonProps) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <TreeView
        defaultCollapseIcon={<Skeleton width={30} height={30} />}
        defaultExpandIcon={<Skeleton width={30} height={30} />}
        expanded={['1', '4']}
      >
        <TreeItem nodeId="1" label={<Skeleton width="100%" {...props} />}>
          <TreeItem nodeId="2" label={<Skeleton width="100%" {...props} />} />
          <TreeItem nodeId="3" label={<Skeleton width="100%" {...props} />} />
          <TreeItem nodeId="4" label={<Skeleton width="100%" {...props} />}>
            <TreeItem
              nodeId="10"
              label={<Skeleton width="100%" {...props} />}
            />
            <TreeItem
              nodeId="11"
              label={<Skeleton width="100%" {...props} />}
            />
          </TreeItem>
          <TreeItem nodeId="5" label={<Skeleton width="100%" {...props} />} />
          <TreeItem nodeId="6" label={<Skeleton width="100%" {...props} />} />
          <TreeItem nodeId="7" label={<Skeleton width="100%" {...props} />} />
          <TreeItem nodeId="8" label={<Skeleton width="100%" {...props} />} />
        </TreeItem>
      </TreeView>{' '}
    </Box>
  )
}

export default function OrganizationTreeSearchSkeleton() {
  const commonProps = {
    animation: 'pulse' as SkeletonProps['animation'],
    height: 30,
  }
  return (
    <Stack direction="row" spacing={2} sx={{ flexGrow: 1, minHeight: '300px' }}>
      <Stack sx={{ width: '300px' }}>
        <Skeleton animation="pulse" height={70} sx={{ mt: -1.25 }} />
      </Stack>
      <OrgTreeSkeleton {...commonProps} />
    </Stack>
  )
}
