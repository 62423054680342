import { useEffect, useState } from 'react'
import { TextField } from '@mui/material'

type EditableNameFieldProps = {
  name: string
  handleOnBlur: (name: string) => void
  placeholder?: string
}

export default function EditableNameField({
  name,
  handleOnBlur,
  placeholder,
}: EditableNameFieldProps) {
  const [internalName, setInternalName] = useState(name)

  const updateName = () => {
    const newName = internalName.trim()
    if (!newName) {
      setInternalName(name)
    }
    handleOnBlur(newName)
  }

  useEffect(() => {
    setInternalName(name)
  }, [name])

  return (
    <form
      // Wrapping with a form allows submitting by pressing enter
      style={{ flexGrow: 1 }}
      onSubmit={(e) => {
        e.preventDefault()
        updateName()
      }}
    >
      <TextField
        value={internalName}
        size="small"
        variant="outlined"
        onBlur={() => {
          updateName()
        }}
        fullWidth
        onChange={(e) => setInternalName(e.target.value)}
        autoFocus
        placeholder={placeholder}
      />
    </form>
  )
}
