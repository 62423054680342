import { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import {
  Button,
  Typography,
  Link,
  Stack,
  Select,
  MenuItem,
} from '@mui/material'
import { Organization } from '@/types/organizations'
import { OrgCredential } from '@/types/credentials'
import { IntegrationType } from '@/types/integrations'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetOrgCredentials } from '@/service-library/hooks/org-credentials'

type CredentialPickerDialogProps = {
  integrationType: IntegrationType
  organization: Organization
  overlay: OverlayState
  onClose: () => void
  onConfirm: (credential: OrgCredential) => void
}

export default function CredentialPickerDialog({
  integrationType,
  organization,
  overlay,
  onClose,
  onConfirm,
}: CredentialPickerDialogProps) {
  const [selectedCredential, setSelectedCredential] =
    useState<OrgCredential | null>(null)

  const { orgCredentials } = useGetOrgCredentials({
    filters: {
      limit: '100',
      org_id: organization.id,
      type_id: integrationType.org_credential_type_id,
    },
  })

  useEffect(() => {
    if (!selectedCredential && orgCredentials.length > 0) {
      setSelectedCredential(orgCredentials[0])
    }
  }, [orgCredentials, selectedCredential])

  return (
    <Dialog
      title="Select Credential"
      {...overlay}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogContent>
        <Stack spacing={2}>
          <Select
            value={selectedCredential?.id || ''}
            onChange={(event) =>
              setSelectedCredential(
                orgCredentials.find(
                  (c) => c.id === event.target.value,
                ) as OrgCredential,
              )
            }
          >
            {orgCredentials.length ? (
              orgCredentials.map((credential) => (
                <MenuItem key={credential.id} value={credential.id}>
                  {credential.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No options</MenuItem>
            )}
          </Select>

          <Typography variant="body2">
            Only credentials from the selected organization are shown.
          </Typography>

          <Typography variant="body2">
            Need to create one?{' '}
            <Link href="/settings/credentials" target="_blank">
              Go here
            </Link>
            .
          </Typography>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button
          variant="text"
          onClick={() => {
            onClose()
            overlay.close()
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedCredential}
          onClick={() => {
            if (selectedCredential) {
              onConfirm(selectedCredential)
            }
          }}
        >
          Confirm
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
