import { useRef } from 'react'
import { FiberManualRecord } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { useGetDocumentFlags } from '@/service-library/hooks/document-flags'
import { useGetRowValueFlags } from '@/service-library/hooks/row-value-flags'
import FlagsPopover from './FlagsPopover'
import useFlagPriorityColor, { FlagsCount } from './useFlagPriorityColor'

type FlagsIndicatorProps = {
  documentId: string
  flagsCount: FlagsCount
}

export default function FlagsIndicator({
  documentId,
  flagsCount,
}: FlagsIndicatorProps) {
  const overlay = useOverlay()
  const flagIndicatorRef = useRef(null)

  const { documentFlags, isLoading: documentFlagsIsLoading } =
    useGetDocumentFlags({
      filters: {
        document_id: documentId,
        limit: '1000',
        fields__include: 'flag_type',
      },
      enabled: overlay.isOpen && !!documentId,
    })

  const { rowValueFlags, isLoading: rowValueFlagsIsLoading } =
    useGetRowValueFlags({
      filters: {
        document_id: documentId,
        limit: '1000',
        fields__include: 'flag_type',
      },
      enabled: overlay.isOpen && !!documentId,
    })

  const { color } = useFlagPriorityColor(flagsCount)

  const totalFlagsCount = [
    ...Object.values(flagsCount.documentFlags),
    ...Object.values(flagsCount.rowsFlags),
  ].reduce((a, b) => a + b, 0)

  return (
    <>
      <Tooltip
        title={`${totalFlagsCount} Flag${totalFlagsCount > 1 ? 's' : ''}`}
      >
        <IconButton
          ref={flagIndicatorRef}
          size="small"
          sx={{ p: 0 }}
          onClick={(e) => {
            // This prevent the event from bubbling up to the table row click handler
            e.stopPropagation()
            overlay.open()
          }}
        >
          <FiberManualRecord fontSize="small" sx={{ color: color?.base }} />
        </IconButton>
      </Tooltip>
      {flagIndicatorRef.current && (
        <FlagsPopover
          overlay={overlay}
          anchorEl={flagIndicatorRef.current}
          rowValueFlags={rowValueFlags}
          documentFlags={documentFlags}
          onClick={(e) => {
            // This prevent the event from bubbling up to the table row click handler
            e.stopPropagation()
          }}
          isLoading={documentFlagsIsLoading || rowValueFlagsIsLoading}
        />
      )}
    </>
  )
}
