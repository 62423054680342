import useLocalStorage from '@/hooks/useLocalStorage'
import { Document } from '@/types/documents'
import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
} from 'react'

type ValidationOptionsContextValue = {
  showOCR: boolean
  setShowOCR: Dispatch<SetStateAction<boolean>>
  languageCode: string
  setLanguageCode: Dispatch<SetStateAction<string>>
  showChipFrame: boolean
  setShowChipFrame: Dispatch<SetStateAction<boolean>>
  showAllChipFrames: boolean
  setShowAllChipFrames: Dispatch<SetStateAction<boolean>>
  showRowColumnChipFrames: boolean
  setShowRowColumnChipFrames: Dispatch<SetStateAction<boolean>>
}

type ValidationOptionsProviderProps = {
  children: ReactNode
  localStorageKeyPrefix?: string
  document?: Document
}

const ValidationOptionsContext = createContext<ValidationOptionsContextValue>(
  [] as unknown as ValidationOptionsContextValue,
)

export const useValidationOptionsContext = () =>
  useContext(ValidationOptionsContext)

export default function ValidationOptionsProvider({
  children,
  localStorageKeyPrefix = 'validation',
  document,
}: ValidationOptionsProviderProps) {
  const [showOCR, setShowOCR] = useState(false)
  const [languageCode, setLanguageCode] = useState(
    document?.primary_language_id || '',
  )
  const [showChipFrame, setShowChipFrame] = useLocalStorage(
    `${localStorageKeyPrefix}-show-chip-frame`,
    true,
  )
  const [showAllChipFrames, setShowAllChipFrames] = useLocalStorage(
    `${localStorageKeyPrefix}-show-all-chip-frames`,
    true,
  )
  const [showRowColumnChipFrames, setShowRowColumnChipFrames] = useLocalStorage(
    `${localStorageKeyPrefix}-show-row-column-chip-frames`,
    true,
  )

  useEffect(() => {
    ;(!languageCode || !document?.languages_ids?.includes(languageCode)) &&
      document?.primary_language_id &&
      setLanguageCode(document?.primary_language_id)
  }, [document?.languages_ids, document?.primary_language_id, languageCode])

  return (
    <ValidationOptionsContext.Provider
      value={{
        showOCR,
        setShowOCR,
        languageCode,
        setLanguageCode,
        showChipFrame,
        setShowChipFrame,
        showAllChipFrames,
        setShowAllChipFrames,
        showRowColumnChipFrames,
        setShowRowColumnChipFrames,
      }}
    >
      {children}
    </ValidationOptionsContext.Provider>
  )
}
