import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material'
import {
  Close,
  ContentCut,
  Delete,
  KeyboardAltOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ListAlt,
  MoreVert,
  PlaylistRemove,
  ReadMore,
  Subject,
} from '@mui/icons-material'
import { useQueryClient } from '@tanstack/react-query'
import { Document } from '@/types/documents'
import { Organization } from '@/types/organizations'
import { WorkflowState } from '@/types/workflows'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import useOverlay from '@/hooks/useOverlay'
import {
  useGetDocument,
  useUpdateDocument,
} from '@/service-library/hooks/documents'
import { useSetDocumentsWorkflowState } from '@/service-library/hooks/document-workflow-states'
import { altHotKeyStr, metaHotKeyStr, modHotKeyStr } from '@/utils/get-os'
import { showErrorSnackbar } from '@/utils/snackbars'
import PageCount from '@/components/image-zoom-pan/PageCount'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import DocumentActionsMenu from '@/components/document-actions-menu/DocumentActionsMenu'
import DocumentDialog from '@/components/document-dialog/DocumentDialog'
import EditableDocumentName from '@/components/editable-document-name/EditableDocumentName'
import KeyboardShortcutsDialog, {
  KeyboardShortcutGroup,
} from '@/components/keyboard-shortcuts-popover/KeyboardShortcutsDialog'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import OrganizationAssignmentSelect from '@/components/organization-select/OrganizationAssignmentSelect'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import SplitDocumentDialog from '@/components/split-document-dialog/SplitDocumentDialog'
import WorkflowStateSelectMenu from '@/components/workflow-state-select-menu/WorkflowStateSelectMenu'
import ExportJobsDialog from '@/components/export-jobs/ExportJobsDialog'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import {
  useFailEvent,
  useWorkflowStateEventStatuses,
} from '@/service-library/hooks/events'
import KeyboardShortcutsTooltip from '@/components/keyboard-shortcuts-tooltip/KeyboardShortcutsTooltip'
import DocProjectTagsList from '@/components/project-tags/DocProjectTagsList'
import ClearAllChipsDialog from '@/components/validation/ClearAllChipsDialog'
import DocumentViewControl from '@/components/validation/DocumentViewControl'
import { useDocumentRowValuesContext } from '@/components/validation/providers/DocumentRowValuesProvider'
import { useSelectedFieldContext } from '@/components/validation/providers/SelectedFieldProvider'
import ValidationEventButton from '@/components/validation/ValidationEventButton'
import VisibleFieldsControl from '@/components/validation/VisibleFieldsControl'

type ValidationOverlayHeaderProps = {
  documentQuery: ReturnType<typeof useGetDocument>
  hasPreviousDocument: boolean
  hasNextDocument: boolean
  selectedIndex: number
  goToPreviousDocument: () => void
  goToNextDocument: () => void
  onClose: () => void
  documentsCount?: number
  documentView?: WorkflowState['default_document_view']
}

function getShortcuts(documentView?: WorkflowState['default_document_view']) {
  const documentShortcuts: Record<string, string> = {
    'Close Document': 'Esc',
    'Previous Document': `${altHotKeyStr} + 1`,
    'Next Document': `${altHotKeyStr} + 2`,
    'Previous Page': `${altHotKeyStr} + 3 / ${metaHotKeyStr} + Scroll Up`,
    'Next Page': `${altHotKeyStr} + 4 / ${metaHotKeyStr} + Scroll Down`,
    'Primary Action': `${altHotKeyStr} + D`,
    'Stay on Document on Event': 'Hold Shift',
    'Hold to Hide Chips': `${altHotKeyStr} + V`,
  }

  const shortcuts: KeyboardShortcutGroup[] = [
    {
      title: 'Document Shortcuts',
      shortcuts: documentShortcuts,
    },
  ]

  if (documentView === 'validation') {
    documentShortcuts['Toggle Layout'] = `${altHotKeyStr} + O`

    shortcuts.push(
      {
        title: 'Field Shortcuts',
        shortcuts: {
          'Clear Selected Field Chips': `${modHotKeyStr} + Del`,
          'Focus on First Field': `Ctrl + ${metaHotKeyStr} + Tab`,
          'Multi-Select': 'Shift + Click and Drag',
          'Multi-Deselect': `Shift + ${altHotKeyStr} + Click and Drag`,
        },
      },
      {
        title: 'Table Shortcuts',
        shortcuts: {
          'Insert Row Above': `${modHotKeyStr} + ${altHotKeyStr} + Shift + Up`,
          'Insert Row Below': `${modHotKeyStr} + ${altHotKeyStr} + Shift + Down`,
          'Delete Row': `${modHotKeyStr} + ${altHotKeyStr} + Shift + Del`,
          'Smart Row Multi-Select': `${metaHotKeyStr} + ${altHotKeyStr} + Click and Drag`,
        },
      },
    )
  }
  return shortcuts
}

export default function ValidationOverlayHeader({
  documentView,
  documentQuery,
  hasPreviousDocument,
  hasNextDocument,
  selectedIndex,
  goToPreviousDocument,
  goToNextDocument,
  onClose,
  documentsCount,
}: ValidationOverlayHeaderProps) {
  const { project } = useProjectContext()
  const featureFlags = useFeatureFlagContext()
  const actionsMenuOverlay = useOverlay()
  const clearAllChipsOverlay = useOverlay()
  const documentOverlay = useOverlay()
  const splitDocumentOverlay = useOverlay()
  const exportJobsOverlay = useOverlay()
  const keyboardShortcutsOverlay = useOverlay()

  const [searchParams] = useSearchParams()
  const documentId = searchParams.get('documentId') || ''

  const { document, refetch, queryKey } = documentQuery

  const { updateDocument } = useUpdateDocument({
    detailQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Failed to update document.')
    },
  })

  const { selectedWorkflow } = useSelectedWorkflowContext()

  const workflowStates = selectedWorkflow?.workflow_states || []

  const workflowState = workflowStates.find((state) =>
    document?.workflow_states_ids?.some((id) => id === state.id),
  )

  const queryClient = useQueryClient()
  const { setWorkflowState } = useSetDocumentsWorkflowState({
    sideEffectQueryKeys: [queryKey],
    onMutate: async ({ patch }) => {
      const { workflow_state__id } = patch
      await queryClient.cancelQueries(queryKey)
      const previous = queryClient.getQueryData<Document>(queryKey)
      const newDocument = {
        ...previous,
        workflow_states_ids: [
          workflow_state__id,
          ...(previous?.workflow_states_ids?.filter(
            (workflowStateId) => workflowStateId !== workflowState?.id,
          ) || []),
        ],
      } as Document
      queryClient.setQueryData<Document>(queryKey, newDocument)
    },
    onError: () => {
      showErrorSnackbar('Failed to update document workflow state.')
    },
  })

  const { refetch: refetchRowValues } = useDocumentRowValuesContext()
  const { organizations, isFetchingAll } = useOrganizationsContext()
  const { selectedFieldOverlay } = useSelectedFieldContext()

  function handleUpdateDocumentWorkflowState(newWorkflowState: WorkflowState) {
    if (
      !document ||
      !workflowState ||
      !selectedWorkflow ||
      newWorkflowState.code === workflowState.code
    )
      return
    setWorkflowState({
      patch: {
        workflow_state__id: newWorkflowState.id,
        workflow_state__code: newWorkflowState.code,
      },
      filter_criteria: {
        document_id__in: [document.id],
        workflow_state__workflow_id: selectedWorkflow.id,
      },
      current_workflow_state_id: workflowState.id,
    })
  }

  function handleUpdateOrganization(newOrganization: Organization) {
    if (!document || newOrganization.id === document.owner_org_id) return

    updateDocument({
      ...document,
      owner_org_id: newOrganization.id,
    })
  }

  const isInProcessingState = workflowState?.code === 'processing'

  const organization = organizations.find(
    ({ id }) => id === document?.owner_org_id,
  ) as Organization

  const keyboardShortcuts = useMemo(
    () => getShortcuts(documentView),
    [documentView],
  )

  // Refetch the document if there are document state changes that include the current document
  useNotifications({
    keys: [documentId],
    callback: ({ action }) => {
      if (documentId) {
        refetch()
        if (
          action === 'document_workflow_state_status_changed' ||
          action === 'document_workflow_state_changed'
        ) {
          refetchRowValues()
        }
      }
    },
  })

  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()
  const { eventStatuses = [] } = useWorkflowStateEventStatuses({
    filters: {
      document_id: document?.id,
    },
    enabled: isSuperUser && !demoMode && !!document,
  })
  const { failEvent } = useFailEvent()

  return (
    <Box sx={{ p: 1, px: 2 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{ minHeight: 40 }} // minHeight so it doesn't change height when switching to editing for name
      >
        {document && (
          <EditableDocumentName
            document={document}
            disabled={isInProcessingState}
            updateDocument={updateDocument}
          />
        )}

        {/* MARK: Keyboard Shortcuts Button / More Menu / Field Details Button */}
        <Stack direction="row" spacing={0.25} alignItems="center">
          <Tooltip title="Shortcuts" arrow disableInteractive enterDelay={1000}>
            <IconButton
              onClick={() => {
                keyboardShortcutsOverlay.open()
              }}
            >
              <KeyboardAltOutlined />
            </IconButton>
          </Tooltip>

          {documentView === 'validation' && (
            <Tooltip
              title={isInProcessingState ? '' : 'Field Details'}
              arrow
              disableInteractive
              enterDelay={1000}
            >
              <IconButton
                size="small"
                disabled={isInProcessingState}
                onClick={() => selectedFieldOverlay.toggle()}
                onMouseDown={(e) => e.preventDefault()}
              >
                <ReadMore
                  sx={{
                    transform: `rotate(${
                      selectedFieldOverlay.isOpen ? 0 : '180deg'
                    })`,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}

          <IconButton
            size="small"
            onClick={actionsMenuOverlay.open}
            disabled={!document || isInProcessingState}
          >
            <MoreVert />
          </IconButton>

          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>

          <DocumentActionsMenu
            projectId={project.id}
            documentId={document?.id}
            overlay={actionsMenuOverlay}
            onDelete={onClose}
            onReprocess={() => {
              if (document) {
                onClose()
              }
            }}
            workflowState={workflowState}
          >
            <MenuItem
              onClick={() => {
                documentOverlay.open()
                actionsMenuOverlay.close()
              }}
            >
              <ListItemIcon>
                <Subject />
              </ListItemIcon>
              <ListItemText>Details</ListItemText>
            </MenuItem>
            {document && document.document_pages.length > 1 && (
              <MenuItem
                onClick={() => {
                  splitDocumentOverlay.open()
                  actionsMenuOverlay.close()
                }}
              >
                <ListItemIcon>
                  <ContentCut />
                </ListItemIcon>
                <ListItemText>Split Document</ListItemText>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                clearAllChipsOverlay.open()
                actionsMenuOverlay.close()
              }}
            >
              <ListItemIcon>
                <PlaylistRemove />
              </ListItemIcon>
              <ListItemText>Clear All Chips</ListItemText>
            </MenuItem>

            {isSuperUser && !demoMode && (
              <MenuItem
                sx={{ color: SUPER_USER_ONLY_COLOR }}
                onClick={() => {
                  exportJobsOverlay.open()
                }}
              >
                <ListItemIcon sx={{ color: SUPER_USER_ONLY_COLOR }}>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText>View Export Jobs</ListItemText>
              </MenuItem>
            )}

            {isSuperUser && !demoMode && (
              <MenuItem
                sx={{ color: SUPER_USER_ONLY_COLOR }}
                onClick={() => {
                  eventStatuses.forEach((eventStatus) => {
                    if (
                      eventStatus.status === 'running' ||
                      eventStatus.status === 'waiting'
                    ) {
                      failEvent({ id: eventStatus.id })
                    }
                  })
                }}
              >
                <ListItemIcon sx={{ color: SUPER_USER_ONLY_COLOR }}>
                  <Delete />
                </ListItemIcon>
                <ListItemText>Clear Events</ListItemText>
              </MenuItem>
            )}
          </DocumentActionsMenu>
        </Stack>
      </Stack>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr',
          gap: 1,
          alignItems: 'center',
        }}
      >
        {/* MARK: Workflow State, Organization Menus */}
        <Stack
          direction="row"
          flexWrap="wrap"
          sx={{ minWidth: 0, transform: 'translateX(-8px)' }}
        >
          {workflowState && !isInProcessingState && (
            <Box>
              <WorkflowStateSelectMenu
                dense
                selectedWorkflowState={workflowState}
                workflowStates={workflowStates}
                onChange={handleUpdateDocumentWorkflowState}
              />
            </Box>
          )}

          {organization && (
            <Box>
              <OrganizationAssignmentSelect
                dense
                isFetching={isFetchingAll}
                selectedOrganization={organization}
                organizations={organizations}
                onChange={handleUpdateOrganization}
                disabled={isInProcessingState}
                showParentOrg
              />
            </Box>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <KeyboardShortcutsTooltip
            title="Previous Document"
            keyboardShortcuts={[`${altHotKeyStr} + 1`]}
            arrow
            enterDelay={1000}
          >
            <IconButton
              size="small"
              disabled={!hasPreviousDocument}
              onClick={goToPreviousDocument}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </KeyboardShortcutsTooltip>
          {documentsCount !== undefined && (
            <PageCount page={selectedIndex + 1} total={documentsCount} />
          )}
          <KeyboardShortcutsTooltip
            title="Next Document"
            keyboardShortcuts={[`${altHotKeyStr} + 2`]}
            arrow
            enterDelay={1000}
          >
            <IconButton
              size="small"
              disabled={!hasNextDocument}
              onClick={goToNextDocument}
            >
              <KeyboardArrowRight />
            </IconButton>
          </KeyboardShortcutsTooltip>
        </Stack>

        {/* MARK: Event Button, Flags Button, Visibility Button, Document Tags */}
        <Stack
          direction="row-reverse"
          flexWrap="wrap"
          columnGap={1}
          rowGap={1}
          sx={{ minWidth: 0 }}
        >
          <Box>
            {workflowState && document && (
              <ValidationEventButton
                document={document}
                goToNextDocument={goToNextDocument}
                workflowStateId={workflowState.id}
              />
            )}
          </Box>
          {!isInProcessingState && documentView && (
            <>
              {featureFlags.document_views && <DocumentViewControl />}
              <VisibleFieldsControl documentView={documentView} />
              <Box sx={{ m: 'auto 0 !important', minWidth: 0 }}>
                <DocProjectTagsList
                  docProjectTags={document?.doc_project_tags || []}
                />
              </Box>
            </>
          )}
        </Stack>
      </Box>

      {document && (
        <>
          <DocumentDialog
            overlay={documentOverlay}
            documentId={document.id}
            projectId={project.id}
            document={document}
            detailQueryKey={queryKey}
            onDelete={onClose}
          />
          <ClearAllChipsDialog
            document={document}
            overlay={clearAllChipsOverlay}
          />
          {document.document_pages.length > 1 && (
            <SplitDocumentDialog
              document={document}
              overlay={splitDocumentOverlay}
              onDelete={onClose}
            />
          )}
          <ExportJobsDialog overlay={exportJobsOverlay} document={document} />
        </>
      )}

      <KeyboardShortcutsDialog
        overlay={keyboardShortcutsOverlay}
        keyboardShortcutGroups={keyboardShortcuts}
      />
    </Box>
  )
}
