import { DataListEntry } from '@/types/data-lists'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<DataListEntry>({
  pathCategory: 'data_list_entries',
})

export function useGetDataListEntry({
  id,
  ...options
}: UseGetDetailOptions<DataListEntry>) {
  const query = useGetDetail<DataListEntry>({
    id,
    queryKey: queryKeys.dataListEntries.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    dataListEntry: query.data,
    ...query,
  }
}

export function useCreateDataListEntry(
  options: UseCreateDetailOptions<DataListEntry> = {},
) {
  const mutation = useCreateDetail<DataListEntry>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDataListEntry: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDataListEntry(
  options: UseUpdateDetailOptions<DataListEntry> = {},
) {
  const mutation = useUpdateDetail<DataListEntry>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDataListEntry: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataListEntry(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<DataListEntry>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDataListEntry: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDataListEntries({
  filters,
  ...options
}: UseGetListOptions<DataListEntry> = {}) {
  const query = useGetList<DataListEntry>({
    filters,
    queryKey: queryKeys.dataListEntries.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    dataListEntries: query.allData,
    ...query,
  }
}

export function useUpdateDataListEntries(
  options: UseUpdateListOptions<DataListEntry> = {},
) {
  const mutation = useUpdateList<DataListEntry>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDataListEntries: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataListEntries({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DataListEntry>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDataListEntries: mutation.mutateAsync,
    ...mutation,
  }
}
