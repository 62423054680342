import { QueryKey, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { RequestOptions } from '../request-types'
import { BaseQueryOptions } from '../query-types'

export type UseGetOptions<T> = BaseQueryOptions<T> & RequestOptions

type ServiceFn<T> = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (options: RequestOptions) => Promise<AxiosResponse<T>>
}

/**
 * This hook fetches data from the backend. This version does not require an ID to be passed to it.
 */
export default function useGet<T>({
  queryKey,
  filters,
  axiosOptions,
  serviceFn,
  ...options
}: UseGetOptions<T> & ServiceFn<T> & { queryKey: QueryKey }) {
  const query = useQuery({
    queryKey,
    queryFn: ({ signal }) => {
      return serviceFn({
        filters,
        axiosOptions: { signal, ...axiosOptions },
      }).then((res) => res.data)
    },
    ...options,
  })

  return {
    ...query,
    queryKey,
  }
}
