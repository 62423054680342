import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import { ProjectGridFieldRuleType } from '@/types/rules'

const service = buildService<ProjectGridFieldRuleType>({
  pathCategory: 'rules',
})

export function useGetProjectGridFieldRuleType({
  id,
  ...options
}: UseGetDetailOptions<ProjectGridFieldRuleType>) {
  const query = useGetDetail<ProjectGridFieldRuleType>({
    id,
    queryKey: queryKeys.rules.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    rule: query.data,
    ...query,
  }
}

export function useGetProjectGridFieldRuleTypes({
  filters,
  ...options
}: UseGetListOptions<ProjectGridFieldRuleType> = {}) {
  const query = useGetList<ProjectGridFieldRuleType>({
    filters,
    queryKey: queryKeys.rules.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    rules: query.allData,
    ...query,
  }
}
