import { Button, Typography } from '@mui/material'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { OverlayState } from '@/hooks/useOverlay'
import { useAuthentication } from '../auth/AuthProvider'
import { useProjectContext } from '../project-dashboard/ProjectProvider'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'

type IssueDialogProps = {
  overlay: OverlayState
}

export default function IssueDialog({ overlay }: IssueDialogProps) {
  const { user } = useAuthentication()
  const { project } = useProjectContext()
  const { rootOrganization } = useRootOrganization()

  let body =
    '(Add description of the issue here)\n\n\n***Relevant Info (do not delete)***\n'
  body += `Name: ${user?.name}\n`
  body += `Email: ${user?.email}\n`
  body += `Organization ID: ${user?.owner_org_id}\n`
  body += `User ID: ${user?.id}\n`

  if (project?.id) {
    body += `Project ID: ${project.id}\n`
    body += `Project Name: ${project.name}\n`
  }

  if (rootOrganization?.id) {
    body += `Root Organization ID: ${rootOrganization.id}\n`
    body += `Root Organization Name: ${rootOrganization.name}\n`
  }

  body += `URL of issue: ${window.location.href}\n`

  return (
    <Dialog title="Report an Issue" {...overlay}>
      <DialogContent>
        <Typography variant="body2">
          We&apos;re sorry to hear you have run into an issue! When you click
          &quot;Open Email&quot; below, it will open your email client with a
          pre-filled email.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Please include as much detail as possible about the issue you are
          having. If you are able to include a screenshot, that is also helpful.
        </Typography>
        {/* <TextField
          name="description"
          multiline
          fullWidth
          minRows={4}
          label="Description"
          helperText="Please describe the issue you are having. Include any relevant details, such as where you are seeing the issue or which document it is occuring on, if applicable."
        /> */}
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button
          component="a"
          href={`mailto:pixydocs@zerapix.odoo.com?subject=Pixydocs%20Issue&body=${encodeURIComponent(
            body,
          )}`}
          target="_blank"
          onClick={overlay.close}
        >
          Open Email
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
