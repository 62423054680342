import { useMemo } from 'react'
import { Handle, HandleProps, useNodeId, useStore } from 'reactflow'
import { DataArray, DataObject } from '@mui/icons-material'
import { Stack, Typography, Tooltip, Box } from '@mui/material'
import { LogixHandle } from '@/types/logix'
import { getEdgeConnectedToHandle } from '../helpers/logix-helpers'
import typeColorMap from '../helpers/typeColorMap'
import useSyncInheritances from '../hooks/useSyncInheritances'
import useConditionProblem from '../hooks/useConditionProblem'
import { DataType } from '../types'
import { useDataTypeInheritance } from '../InheritancesProvider'
import { useEdgesContext } from '../EdgesProvider'
import { NodeProblem } from '../ProblemsProvider'
import HandleInput from './HandleInput'

export const dataTypeTooltipMap: Record<DataType, string> = {
  execution: 'Execution Line',
  text: 'Text',
  integer: 'Integer',
  decimal: 'Decimal',
  boolean: 'Boolean',
  date: 'Date',
  time: 'Time',
  datetime: 'Date & Time',
  object: 'Object',
  any: 'Any',
}

type BaseHandleProps = HandleProps & {
  logixHandle: LogixHandle
  style?: React.CSSProperties
}

export default function BaseHandle({ logixHandle, ...props }: BaseHandleProps) {
  const nodeId = useNodeId()
  const { edges } = useEdgesContext()

  const { id, name, allow_manual_value, data_type_code, data_type_match_key } =
    logixHandle
  const { inheritances } = useDataTypeInheritance()

  const inheritedDataType = inheritances[`${data_type_match_key}${nodeId}`]

  const dataType = (inheritedDataType || data_type_code) as DataType

  const connectionClickStartHandle = useStore(
    (state) => state.connectionClickStartHandle,
  )

  const edge = edges ? getEdgeConnectedToHandle(id, edges) : undefined

  const isClickStartHandle =
    connectionClickStartHandle &&
    connectionClickStartHandle.handleId === id &&
    connectionClickStartHandle.nodeId === nodeId

  const { position } = props
  const isInput = position === 'left'

  const { required, is_list } = logixHandle

  useSyncInheritances(logixHandle)

  const direction = isInput ? 'row-reverse' : 'row'

  const justifyContent = isInput ? 'left' : 'right'
  const color = typeColorMap[dataType]
  const isObject = dataType === 'object'

  const arrayHandleStyle =
    is_list || isObject
      ? {
          borderColor: 'transparent',
          height: 8,
          width: 8,
          marginTop: 1,
          marginLeft: isInput ? 0 : 4,
          marginRight: isInput ? 4 : 0,
          transform: isInput ? 'translateX(2px)' : 'translateX(-2px)',
          backgroundColor: edge ? color : 'transparent',
        }
      : {}

  const backgroundColor = edge ? color : '#222'
  const borderColor = color ? color : undefined

  const problem = useMemo(
    () => ({
      condition: !edge && required,
      enabled: isInput,
      problem: {
        message: 'Field Required',
        type: 'required-field',
        handleId: logixHandle.id,
        severity: 'error',
        unique: true,
      } as NodeProblem,
    }),
    [edge, required, isInput, logixHandle.id],
  )

  useConditionProblem(problem)

  const showManualInput = allow_manual_value && (!isInput || !edge)

  return (
    <Stack
      spacing={1}
      sx={{
        borderRadius: '4px 0 0 4px',
        pl: isInput ? 0 : 1,
        pr: isInput ? 1 : 0,
        py: 0,
        position: 'relative',
        pointerEvents: 'initial',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: 'calc(100% - 6px)',
          borderRadius: 2,
          border: 'none',
        },
      }}
    >
      <Stack
        direction={direction}
        alignItems="center"
        spacing={0.5}
        justifyContent={justifyContent}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {/* TODO: Fix this */}
          {showManualInput && <HandleInput logixHandle={logixHandle} />}

          {/* TODO: Fix this */}
          {/* {properties && properties.length > 0 && (
            <ObjectPropertiesIconButton handleStructure={handleStructure} />
          )} */}

          {!showManualInput && (
            <Typography
              variant="caption"
              color={edge ? undefined : 'text.secondary'}
              sx={{ lineHeight: '12px' }}
            >
              {name}
            </Typography>
          )}

          {required && !edge && isInput && (
            <Typography
              variant="caption"
              color="error"
              sx={{
                fontSize: 8,
              }}
            >
              (REQUIRED)
            </Typography>
          )}
        </Stack>

        <Tooltip
          title={
            <Stack spacing={1}>
              <Typography variant="caption">
                {dataTypeTooltipMap[dataType]}
              </Typography>

              {/* TODO: Fix this */}
              {/* {data_type_code === 'object' && (
                <ObjectPropertiesList
                  properties={handleStructure.properties as HandleStructure[]}
                />
              )} */}
            </Stack>
          }
          disableInteractive
          placement="top"
          enterDelay={1500}
        >
          <Box
            sx={{
              position: 'relative',
              transformOrigin: 'center',
              transition: '0.1s',
              transform: isClickStartHandle ? `scale(1.2)` : undefined,
              '&:hover': {
                transform: edge ? undefined : `scale(1.2)`,
              },
            }}
          >
            {is_list && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -5,
                  left: -4,
                  zIndex: 2,
                  pointerEvents: 'none',
                }}
              >
                <DataArray fontSize="small" sx={{ color }} />
              </Box>
            )}
            {!is_list && isObject && (
              <Box
                sx={{
                  position: 'absolute',
                  top: -5,
                  left: -4,
                  zIndex: 2,
                  pointerEvents: 'none',
                }}
              >
                <DataObject fontSize="small" sx={{ color }} />
              </Box>
            )}

            <Handle
              {...props}
              id={id}
              // Outputs can have multiple edges, but inputs can only have one
              isConnectableStart={dataType !== 'any'}
              isConnectable={!edge || (edge && !isInput)}
              style={{
                position: 'unset',
                transform: 'unset',
                transition: '0.15s',
                borderColor,
                borderWidth: 2,
                backgroundColor,
                height: 12,
                width: 12,
                borderRadius: '50%',
                ...props.style,
                ...arrayHandleStyle,
              }}
            />
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
