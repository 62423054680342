import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { Box } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ProjectGridField as TSField } from '@/types/fields'
import { ProjectGrid } from '@/types/projects'
import useLocalStorage from '@/hooks/useLocalStorage'
import useContentCategories from '@/services/hooks/useContentCategories'
import { useGetDocument } from '@/service-library/hooks/documents'
import { useGetProjectGrids } from '@/service-library/hooks/project-grids'
import {
  appendContentCategoriesToGrids,
  appendSubGridFieldsToBaseGridAndCleanFields,
  getBaseGrid,
  sortBySortOrder,
} from '@/utils/field-utils'
import DocumentComparisonDataProvider from '@/components/image-zoom-pan/providers/DocumentComparisonDataProvider'
import DocumentProvider from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import ComparisonViewDocument from './ComparisonViewDocument'
import DocumentRowValuesProvider from './providers/DocumentRowValuesProvider'
import DocumentChipsProvider from './providers/DocumentChipsProvider'
import useDocumentComparisonData from './useDocumentComparisonData'
import DocumentComparisonTextField from './DocumentComparisonTextField'

type ComparisonViewProps = {
  documentQuery: ReturnType<typeof useGetDocument>
}
export type ComparisonModeOptions = 'image' | 'all_fields' | 'relevant_fields'

export default function ComparisonView({ documentQuery }: ComparisonViewProps) {
  const { projectId } = useParams()

  const { document } = documentQuery
  const [selectedComparisonMode] = useLocalStorage<ComparisonModeOptions>(
    'comparison-selected-mode',
    'image',
  )

  const [documentIdToCompare, setDocumentIdToCompare] = useState<string>('')
  const [compareDocumentEnabled, setCompareDocumentEnabled] = useState(false)

  const { project } = useProjectContext()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const { secondaryDocumentQuery, ...rest } = useDocumentComparisonData({
    enabled: compareDocumentEnabled,
    mainDocument: document,
    secondaryDocumentId: documentIdToCompare,
    mode: selectedComparisonMode,
  })

  // Using a ref so that we keep the old data while the new one is loading
  const loadedSecondaryDocumentQueyRef = useRef(secondaryDocumentQuery)

  const { projectGrids = [] } = useGetProjectGrids({
    filters: {
      limit: '1000',
      project_id: projectId,
      fields__include: 'project_grid_fields',
      project_grid_fields__fields__include:
        'project_grid_field_type,project_grid_field_rules',
      project_grid_fields__project_grid_field_rules__fields__include: 'rule',
    },
    initialListData: project?.project_grids,
  })

  const { categoriesContent = [], isLoading: categoriesIsLoading } =
    useContentCategories({
      projectId,
      refetchOnWindowFocus: true,
    })

  const workflowStates = selectedWorkflow?.workflow_states || []

  const workflowState = workflowStates.find((state) =>
    document?.workflow_states_ids?.some((id) => id === state.id),
  )

  const mainProjectGrid = getBaseGrid(projectGrids)

  const fields: TSField[] = useMemo(() => {
    if (projectGrids.length > 0 && !categoriesIsLoading) {
      const newGrids =
        categoriesContent.length > 0
          ? appendContentCategoriesToGrids(
              cloneDeep(projectGrids),
              categoriesContent,
            )
          : cloneDeep(projectGrids)
      const baseGrid = getBaseGrid(newGrids) as ProjectGrid
      const fullBaseGrid = appendSubGridFieldsToBaseGridAndCleanFields({
        baseGrid,
        grids: newGrids,
      })
      //TODO: Remove sorting for when the backend returns data sorted
      return sortBySortOrder(fullBaseGrid.project_grid_fields)
    }
    return []
  }, [categoriesContent, categoriesIsLoading, projectGrids])

  const groupedFields = useMemo(() => {
    return selectedComparisonMode === 'image'
      ? []
      : fields.reduce<TSField[][]>((acc, field) => {
          let isVisible = false
          switch (selectedComparisonMode) {
            case 'relevant_fields':
              // We don't check for children fields here because if all of them are excluded,
              // the parent field id will be excluded as well
              isVisible =
                !workflowState?.excluded_project_grid_fields_ids?.includes(
                  field.id,
                )
              break
            case 'all_fields':
              isVisible = true
              break
          }

          if (isVisible) {
            if (field.fields) {
              const tableFields =
                // :flagged: if the parent field is visible when this option is selected, it means
                // at least one of its children is visible, so we show all of them
                selectedComparisonMode === 'all_fields'
                  ? field.fields
                  : field.fields.filter(
                      (tableField) =>
                        !workflowState?.excluded_project_grid_fields_ids?.includes(
                          tableField.id,
                        ),
                    )
              acc.push([{ ...field, fields: tableFields }])
              acc.push([])
            } else {
              if (acc.length === 0) {
                acc.push([])
              }
              acc[acc.length - 1].push(field)
            }
          }

          return acc
        }, [])
  }, [
    fields,
    selectedComparisonMode,
    workflowState?.excluded_project_grid_fields_ids,
  ])

  const flatFields = project.project_grids?.flatMap(
    (grid) => grid.project_grid_fields,
  )

  useEffect(() => {
    if (
      secondaryDocumentQuery.document?.id &&
      secondaryDocumentQuery.document?.id !==
        loadedSecondaryDocumentQueyRef.current.document?.id
    ) {
      loadedSecondaryDocumentQueyRef.current = secondaryDocumentQuery
    }
  }, [secondaryDocumentQuery])

  if (!document) return null

  const commonProps = {
    flatFields,
    groupedFields,
    mainProjectGridId: mainProjectGrid?.id,
    mode: selectedComparisonMode,
    showDocumentName: !!loadedSecondaryDocumentQueyRef.current.document,
  }

  return (
    <DocumentComparisonDataProvider
      compareDocumentEnabled={compareDocumentEnabled}
      documentIdToCompare={documentIdToCompare}
      secondaryDocumentQuery={secondaryDocumentQuery}
      setCompareDocumentEnabled={setCompareDocumentEnabled}
      setDocumentIdToCompare={setDocumentIdToCompare}
      {...rest}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid2
          container
          sx={{
            flexGrow: 1,
            minHeight: 0,
            minWidth: 0,
            overflowY: 'auto', // This is needed to make the grid scrollable
          }}
        >
          <Grid2
            xs={6}
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
              display: 'flex',
              minHeight: 0,
              minWidth: 0,
              height: '100%',
            }}
          >
            <ComparisonViewDocument
              isMainDocument
              documentQuery={documentQuery}
              {...commonProps}
            />
          </Grid2>
          <Grid2
            xs={6}
            sx={{
              display: 'flex',
              minHeight: 0,
              minWidth: 0,
              height: '100%',
              justifyContent: 'center',
            }}
          >
            {loadedSecondaryDocumentQueyRef.current.document ? (
              <DocumentProvider
                query={loadedSecondaryDocumentQueyRef.current}
                isDisabled
              >
                <DocumentRowValuesProvider>
                  <DocumentChipsProvider>
                    <ComparisonViewDocument
                      documentQuery={loadedSecondaryDocumentQueyRef.current}
                      {...commonProps}
                    />
                  </DocumentChipsProvider>
                </DocumentRowValuesProvider>
              </DocumentProvider>
            ) : (
              <Box sx={{ margin: 'auto', width: '60%' }}>
                <DocumentComparisonTextField />
              </Box>
            )}
          </Grid2>
        </Grid2>
      </LocalizationProvider>
    </DocumentComparisonDataProvider>
  )
}
