import { MRT_ColumnDef, MRT_TableOptions } from 'material-react-table'
import { User } from '@/types/users'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import useZerapixTable from '../zerapix-table/useZerapixTable'

type UserTableProps = {
  users: User[]
  columns?: MRT_ColumnDef<User>[]
  isLoading?: boolean
  renderTableActions?: MRT_TableOptions<User>['renderTopToolbarCustomActions']
} & Omit<MRT_TableOptions<User>, 'data' | 'columns'>

const defaultColumns: MRT_ColumnDef<User>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Email',
    accessorKey: 'email',
  },
]

export default function BaseUsersTable({
  users,
  columns = defaultColumns,
  enableRowSelection = false,
  enableMultiRowSelection = false,
  isLoading = false,
  renderTableActions,
  renderRowActionMenuItems,
  ...props
}: UserTableProps) {
  const table = useZerapixTable<User>({
    title: 'Users',
    columns,
    data: users,
    renderTableActions,
    renderRowActionMenuItems,
    enableColumnResizing: true,
    enableMultiRowSelection,
    enableRowSelection,
    enableRowActions: !!renderRowActionMenuItems,
    enableSorting: true,
    getRowId: (row) => row.id,
    state: {
      showSkeletons: isLoading,
    },
    localization: {
      noRecordsToDisplay: 'No users have been added to this team.',
    },
    ...props,
  })

  return <ZerapixTable<User> table={table} />
}
