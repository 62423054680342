import { useEffect, useMemo } from 'react'
import { Document, DocumentRowValue } from '@/types/documents'
import { useGetDocumentChips } from '@/service-library/hooks/document-chips'
import useGetValidationDocument from '@/services/hooks/useGetValidationDocument'
import { sortBySortOrder } from '@/utils/field-utils'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'
import { ComparisonModeOptions } from './ComparisonView'

type UseDocumentComparisonDataOptions = {
  enabled: boolean
  mode: ComparisonModeOptions
  mainDocument?: Document
  secondaryDocumentId: string
}

type GroupedData<T> = {
  [x: string]: {
    fieldKey: string
    data: T[]
    fieldId: string
    rowId: string
  }
}

function groupDataByFieldAndRow<
  T extends {
    project_grid_field_id: string | null
    document_row_id: string | null
  },
>(data: T[], rowIndexMap: Record<string, number>) {
  return data.reduce<GroupedData<T>>((acc, item) => {
    if (!item.project_grid_field_id || !item.document_row_id) return acc
    const fieldId = item.project_grid_field_id
    const rowId = item.document_row_id
    const rowIndex = rowIndexMap[rowId]
    const key = `${fieldId}_${rowIndex}`
    if (!acc[key]) {
      acc[key] = {
        fieldKey: `${fieldId}_${rowId}`,
        data: [item],
        fieldId,
        rowId,
      }
    } else {
      acc[key].data.push(item)
    }
    return acc
  }, {})
}

const differentFieldKeysComparingRowValuesForAll = {
  fieldIds: new Set(['all']),
}

export default function useDocumentComparisonData({
  enabled,
  mode,
  mainDocument,
  secondaryDocumentId,
}: UseDocumentComparisonDataOptions) {
  const { documentChips: mainDocumentChips } = useDocumentChipsContext()

  const secondaryDocumentQuery = useGetValidationDocument({
    id: secondaryDocumentId,
    enabled: enabled && !!mainDocument,
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false
      return failureCount < 3
    },
  })

  const secondaryDocument = secondaryDocumentQuery.document
  const {
    documentChips: secondaryDocumentChips,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useGetDocumentChips({
    filters: {
      limit: '1000',
      document_id: secondaryDocument?.id as string,
      project_grid_field__isnull: 'false',
      fields__only: 'id,text,project_grid_field_id,document_row_id',
    },
    enabled: enabled && !!secondaryDocument?.id && mode === 'image',
  })

  useEffect(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetching])

  const mainDocumentRowIndexMap = sortBySortOrder(
    mainDocument?.document_rows || [],
  ).reduce((acc, row, index) => {
    acc[row.id] = index
    return acc
  }, {})

  const secondaryDocumentRowIndexMap = sortBySortOrder(
    secondaryDocument?.document_rows || [],
  ).reduce((acc, row, index) => {
    acc[row.id] = index
    return acc
  }, {})

  const differentFieldKeysComparingChips = useMemo(() => {
    if (mode !== 'image' || !secondaryDocument) return undefined
    if (!secondaryDocumentChips.length || !mainDocumentChips.length) {
      return ['all']
    }

    const groupedMainChipsByFieldAndRow = groupDataByFieldAndRow(
      mainDocumentChips,
      mainDocumentRowIndexMap,
    )

    if (Object.keys(groupedMainChipsByFieldAndRow).length === 0) return ['all']

    const groupedSecondaryChipsByFieldAndRows = groupDataByFieldAndRow(
      secondaryDocumentChips,
      secondaryDocumentRowIndexMap,
    )

    const differentFields: string[] = []
    Object.entries(groupedMainChipsByFieldAndRow).forEach(
      ([key, { fieldKey, data: mainChips }]) => {
        const secondaryGroup = groupedSecondaryChipsByFieldAndRows[key]
        if (!secondaryGroup) {
          differentFields.push(fieldKey)
        } else if (secondaryGroup.data.length !== mainChips.length) {
          differentFields.push(fieldKey, secondaryGroup.fieldKey)
        } else {
          const secondaryChips = secondaryGroup.data
          const mainChipsText = mainChips.map((chip) => chip.text).sort()
          const secondaryChipsText = secondaryChips
            .map((chip) => chip.text)
            .sort()
          if (
            !mainChipsText.every(
              (value, index) => value === secondaryChipsText[index],
            )
          ) {
            differentFields.push(fieldKey, secondaryGroup.fieldKey)
          }
        }
      },
    )

    Object.keys(groupedSecondaryChipsByFieldAndRows).forEach((key) => {
      // If the field is not in the main document, it's a different field
      if (!groupedMainChipsByFieldAndRow[key]) {
        differentFields.push(groupedSecondaryChipsByFieldAndRows[key].fieldKey)
      }
    })

    return differentFields
  }, [
    mainDocumentChips,
    mainDocumentRowIndexMap,
    mode,
    secondaryDocument,
    secondaryDocumentChips,
    secondaryDocumentRowIndexMap,
  ])

  const differentFieldsAnsRowsComparingRowValues = useMemo(() => {
    if (mode == 'image' || !secondaryDocument) return undefined

    const mainDocumentRowValues = mainDocument?.document_rows?.reduce<
      DocumentRowValue[]
    >(
      (acc, { document_row_values = [] }) => [...acc, ...document_row_values],
      [],
    )

    if (!mainDocumentRowValues?.length)
      return differentFieldKeysComparingRowValuesForAll

    const secondaryDocumentRowValues = secondaryDocument?.document_rows?.reduce<
      DocumentRowValue[]
    >(
      (acc, { document_row_values = [] }) => [...acc, ...document_row_values],
      [],
    )

    if (!secondaryDocumentRowValues?.length)
      return differentFieldKeysComparingRowValuesForAll

    const groupedMainRowValuesByFieldAndRow = groupDataByFieldAndRow(
      mainDocumentRowValues,
      mainDocumentRowIndexMap,
    )

    if (Object.keys(groupedMainRowValuesByFieldAndRow).length === 0)
      return differentFieldKeysComparingRowValuesForAll

    const groupedSecondaryRowValuesByFieldAndRows = groupDataByFieldAndRow(
      secondaryDocumentRowValues,
      secondaryDocumentRowIndexMap,
    )

    const differentFields: {
      fieldIds: Set<string>
      rowIds: Set<string>
    } = { fieldIds: new Set(), rowIds: new Set() }

    Object.entries(groupedMainRowValuesByFieldAndRow).forEach(
      ([key, { fieldId, rowId, data: mainRowValues }]) => {
        const mainRowValue = mainRowValues[0]
        const mainDocumentFinalRefId = mainRowValue.final_ref_id
        const secondaryGroup = groupedSecondaryRowValuesByFieldAndRows[key]
        const secondaryRowValue = secondaryGroup?.data[0]

        let isDifferent = false
        if (
          !secondaryRowValue ||
          (mainDocumentFinalRefId &&
            mainDocumentFinalRefId !== secondaryRowValue.final_ref_id) ||
          (!mainDocumentFinalRefId &&
            mainRowValue.final_value !== secondaryRowValue.final_value)
        ) {
          isDifferent = true
        }

        if (isDifferent) {
          differentFields.rowIds.add(rowId)
          differentFields.fieldIds.add(fieldId)
          if (secondaryRowValue) {
            differentFields.rowIds.add(secondaryGroup.rowId)
          }
        }
      },
    )

    Object.keys(groupedSecondaryRowValuesByFieldAndRows).forEach((key) => {
      // If the field is not in the main document, it's a different field
      if (!groupedMainRowValuesByFieldAndRow[key]) {
        differentFields.fieldIds.add(
          groupedSecondaryRowValuesByFieldAndRows[key].fieldId,
        )
        differentFields.rowIds.add(
          groupedSecondaryRowValuesByFieldAndRows[key].rowId,
        )
      }
    })

    return differentFields
  }, [
    mainDocument?.document_rows,
    mainDocumentRowIndexMap,
    mode,
    secondaryDocument,
    secondaryDocumentRowIndexMap,
  ])

  const rowsCountByGridId = useMemo(() => {
    if (mode == 'image' || !secondaryDocument) return undefined

    const rowsByGridId: Record<string, Record<string, number>> = {}
    mainDocument?.document_rows?.forEach((row) => {
      if (rowsByGridId[row.project_grid_id]) {
        rowsByGridId[row.project_grid_id].main =
          rowsByGridId[row.project_grid_id].main + 1
      } else {
        rowsByGridId[row.project_grid_id] = { main: 1, secondary: 0 }
      }
    })

    secondaryDocument?.document_rows?.forEach((row) => {
      if (rowsByGridId[row.project_grid_id]) {
        rowsByGridId[row.project_grid_id].secondary =
          rowsByGridId[row.project_grid_id].secondary + 1
      } else {
        rowsByGridId[row.project_grid_id] = { main: 0, secondary: 1 }
      }
    })

    return rowsByGridId
  }, [mainDocument?.document_rows, mode, secondaryDocument])

  return {
    secondaryDocumentQuery,
    // We use field keys (fieldId_rowId) for chips since we highlight each row field (image view), which
    // is different for row values (fields view) where we highlight all columns in a table row if one of
    // the values is different.
    differentFieldKeysComparingChips,
    differentFieldsAnsRowsComparingRowValues,
    rowsCountByGridId,
  }
}
