import { useState } from 'react'
import { Typography, TypographyProps, Tooltip } from '@mui/material'
import { Document } from '@/types/documents'
import { useUpdateDocument } from '@/service-library/hooks/documents'
import EditableNameField from '@/components/editable-name/EditableNameField'

type EditableDocumentNameProps = {
  document: Document
  updateDocument: ReturnType<typeof useUpdateDocument>['updateDocument']
  disabled?: boolean
  typographyProps?: TypographyProps
  onChange?: (updatedDocument: Document) => void
}

export default function EditableDocumentName({
  document,
  updateDocument,
  disabled,
  typographyProps,
  onChange,
}: EditableDocumentNameProps) {
  const [isEditing, setIsEditing] = useState(false)

  function handleOnBlur(name: string) {
    setIsEditing(false)
    if (name && name !== document.name) {
      const updatedDocument = {
        ...document,
        name,
      }
      updateDocument(updatedDocument).then(() => {
        onChange?.(updatedDocument)
      })
    }
  }
  const docName = document?.name

  return isEditing ? (
    <EditableNameField name={docName} handleOnBlur={handleOnBlur} />
  ) : (
    <Tooltip
      title={disabled ? '' : 'Click to edit name'}
      arrow
      enterDelay={1000}
    >
      <Typography
        sx={{ pr: 1, cursor: disabled ? 'default' : 'pointer' }}
        tabIndex={0}
        {...typographyProps}
        onClick={() => !disabled && setIsEditing(true)}
        onFocus={() => !disabled && setIsEditing(true)}
        noWrap
      >
        {docName}
      </Typography>
    </Tooltip>
  )
}
