import { DataList, DataListSyncingStatus } from '@/types/data-lists'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { createDetail, getDetail } from '../request-wrappers'

const service = buildService<DataList>({
  pathCategory: 'data_lists',
})

export function useGetDataList({
  id,
  ...options
}: UseGetDetailOptions<DataList>) {
  const query = useGetDetail<DataList>({
    id,
    queryKey: queryKeys.dataLists.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    dataList: query.data,
    ...query,
  }
}

export function useCreateDataList(
  options: UseCreateDetailOptions<DataList> = {},
) {
  const mutation = useCreateDetail<DataList>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDataList: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDataList(
  options: UseUpdateDetailOptions<DataList> = {},
) {
  const mutation = useUpdateDetail<DataList>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDataList: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataList(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<DataList>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDataList: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDataLists({
  filters,
  ...options
}: UseGetListOptions<DataList> = {}) {
  const query = useGetList<DataList>({
    filters,
    queryKey: queryKeys.dataLists.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    dataLists: query.allData,
    ...query,
  }
}

export function useUpdateDataLists(
  options: UseUpdateListOptions<DataList> = {},
) {
  const mutation = useUpdateList<DataList>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDataLists: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDataLists({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DataList>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDataLists: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Get Child Data List
 * Get the list for an org if available, or its inherited list from one of its parents.
 */
export function useGetChildDataList({
  id,
  orgId,
  ...options
}: UseGetDetailOptions<DataList> & { orgId: string }) {
  const query = useGetDetail<DataList>({
    id,
    queryKey: queryKeys.dataLists.detail({ id, filters: options.filters }, [
      orgId,
    ]),
    serviceFn: (serviceFnOptions) => {
      return getDetail<DataList>({
        url: `/v2/pd/data_lists/${id}/child_data_lists/${orgId}`,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    dataList: query.data,
    ...query,
  }
}

/**
 * MARK: Action Hook: Create Data List with Defaults
 * Creates a new Data List with default related to models.
 */
export function useCreateDataListWithDefaults(
  options: UseCreateDetailOptions<DataList> = {},
) {
  const mutation = useCreateDetail<DataList>({
    serviceFn: (serviceFnOptions) => {
      return createDetail({
        url: '/v2/pd/data_lists/create_list_with_defaults',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    createDataList: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Get Data List Syncing Status
 * Get the syncing status of a Data List.
 */
export function useGetDataListSyncingStatus({
  id,
  ...options
}: UseGetDetailOptions<DataListSyncingStatus> & { id: string }) {
  const query = useGetDetail<DataListSyncingStatus>({
    id,
    queryKey: queryKeys.dataLists.detail({ id, filters: options.filters }, [
      'get-sync-status',
    ]),
    serviceFn: (serviceFnOptions) => {
      return getDetail<DataListSyncingStatus>({
        url: `/v2/pd/data_lists/${id}/get-sync-status`,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    dataListSyncStatus: query.data,
    ...query,
  }
}

/**
 * MARK: Action Hook: Sync Data List
 * Sync a Data List.
 */
export function useSyncDataList(
  options: UseCreateDetailOptions<{ id: string }> = {},
) {
  const mutation = useCreateDetail<{ id: string }>({
    serviceFn: ({ item, ...serviceFnOptions }) => {
      return createDetail({
        url: `/v2/pd/data_lists/${item.id}/do-sync`,
        ...serviceFnOptions,
        item: {},
      })
    },
    ...options,
  })

  return {
    syncDataList: mutation.mutateAsync,
    ...mutation,
  }
}
