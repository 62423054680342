import {
  ListItemIcon,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material'
import { BuildCircle, DragIndicator } from '@mui/icons-material'
import getDataTypeIcon from '@/utils/get-data-type-icon'

export type MovableListItemProps = {
  handleName: string
  Icon: ReturnType<typeof getDataTypeIcon>
  name: string
  onClick: () => void
  selected: boolean
  rules?: number
}

export default function MovableListItem({
  Icon,
  name,
  handleName,
  selected,
  rules,
  ...rest
}: MovableListItemProps) {
  const theme = useTheme()

  return (
    <ListItem
      sx={{
        bgcolor: selected
          ? theme.palette.action.selected
          : theme.palette.background.paper,
      }}
      divider
      secondaryAction={
        <Stack alignItems="center">
          <DragIndicator
            className={handleName}
            sx={{ cursor: 'grab', ':active': { cursor: 'grabbing' } }}
          />
        </Stack>
      }
      {...rest}
    >
      {Icon && (
        <ListItemIcon sx={{ minWidth: 32 }}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={name} />
      {!!rules && (
        <Tooltip
          title={rules > 1 ? `${rules} rules` : `${rules} rule`}
          placement="top"
        >
          <BuildCircle />
        </Tooltip>
      )}
    </ListItem>
  )
}
