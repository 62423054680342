import axios, { ResponseType } from 'axios'
import { Buffer } from 'buffer'
import useQuery from './useQuery'

type UseDocumentImageOptions = {
  documentId: string | undefined
  imageUrl: string | undefined
  imagePage: number
}

export default function useDocumentImage({
  documentId,
  imageUrl,
  imagePage,
}: UseDocumentImageOptions) {
  const config = {
    'Content-Type': 'image/png',
    Accept: 'image/*',
    'Sec-Fetch-Mode': 'no-cors',
    responseType: 'arraybuffer' as ResponseType,
  }
  const query = useQuery({
    // Can't use the imageUrl for the key for some reason - it breaks react-query and makes it think this data isn't being used and garbage collects it
    queryKey: ['document-image', documentId, imagePage],
    queryFn: () =>
      axios.get(imageUrl as string, config).then(({ data }) => {
        return Buffer.from(data, 'binary').toString('base64')
      }),
    enabled: !!imageUrl && !!documentId,
    refetchOnWindowFocus: false,
  })

  return { base64Image: query.data, ...query }
}
