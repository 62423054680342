import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Alert, Box, Stack } from '@mui/material'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { Organization } from '@/types/organizations'
import {
  createOrganizationMap,
  getFilteredOrgsNodesData,
  getNodeDescendantsIds,
  getNodesToExpandForMultipleOrgs,
} from '@/utils/organization'
import OrganizationsSearchInput from '@/components/organizations/OrganizationsSearchInput'
import OrganizationTreeItemWithCheckbox from '@/components/organizations-tree/OrganizationTreeItemWithCheckbox'

type OrganizationsFilterTreeSearchProps = {
  organizations: Organization[]
  defaultOrgsToExpand: Organization[]
  rootTreeOrgId: string
  selectedOrgs: Organization[]
  selectedOrgsIds: string[]
  setSelectedOrgsIds: Dispatch<SetStateAction<string[]>>
}

export default function OrganizationsFilterTreeSearch({
  defaultOrgsToExpand,
  rootTreeOrgId,
  selectedOrgs,
  selectedOrgsIds,
  organizations,
  setSelectedOrgsIds,
}: OrganizationsFilterTreeSearchProps) {
  const [inputValue, setInputValue] = useState('')

  const organizationMap = useMemo(
    () => createOrganizationMap(organizations),
    [organizations],
  )

  const defaultExpanded = useMemo(
    () => getNodesToExpandForMultipleOrgs(defaultOrgsToExpand, organizationMap),
    [defaultOrgsToExpand, organizationMap],
  )

  const [expanded, setExpanded] = useState(defaultExpanded)

  const { visibleOrgIds, nodesToExpand } = useMemo(
    () =>
      getFilteredOrgsNodesData({
        organizations,
        organizationMap,
        defaultExpanded,
        searchedValue: inputValue,
      }),
    [organizations, defaultExpanded, inputValue, organizationMap],
  )

  const handleAddOrRemoveOrgAndDescendants = (
    orgId: string,
    action: 'add' | 'remove' = 'add',
  ) => {
    const selectedIds = [orgId]
    const orgTree = organizationMap[orgId]
    if (orgTree.subOrganizations.length) {
      selectedIds.push(...getNodeDescendantsIds(orgTree))
    }
    setSelectedOrgsIds((prev) => {
      if (action === 'add')
        return [...prev, ...selectedIds.filter((id) => !prev.includes(id))]
      return prev.filter((id) => !selectedIds.includes(id))
    })
  }

  useEffect(() => {
    if (inputValue) {
      setExpanded(nodesToExpand)
    }
  }, [inputValue, nodesToExpand])

  return (
    <Stack spacing={2} direction="row">
      <Box sx={{ width: 300 }}>
        <OrganizationsSearchInput
          inputValue={inputValue}
          setInputValue={setInputValue}
          selected={selectedOrgs}
          setSelected={(orgs) => {
            setExpanded(getNodesToExpandForMultipleOrgs(orgs, organizationMap))
            setSelectedOrgsIds(orgs.map(({ id }) => id))
          }}
          organizations={organizations}
        />
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        {visibleOrgIds.length ? (
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={
              !inputValue && !expanded.length ? [rootTreeOrgId] : expanded
            }
            multiSelect
            selected={selectedOrgsIds}
            onNodeToggle={(e, ids) => {
              // When clicking on a checkbox, onNodeToggle gets triggered before the checkbox
              // onChange, so we prevent default expand/collapse behavior here.
              if ((e.target as HTMLElement).tagName !== 'INPUT') {
                setExpanded(ids)
              }
            }}
          >
            <OrganizationTreeItemWithCheckbox
              orgTree={organizationMap[rootTreeOrgId]}
              searchTerm={inputValue}
              showOnlyButtonOnHover
              getIsVisible={(id: string) => visibleOrgIds.includes(id)}
              getIsChecked={(id: string) => selectedOrgsIds.includes(id)}
              onChange={(checked, orgId) => {
                handleAddOrRemoveOrgAndDescendants(
                  orgId,
                  checked ? 'add' : 'remove',
                )
              }}
              handleClickOnOnly={(orgId) => {
                setSelectedOrgsIds((prev) => {
                  if (prev.includes(orgId))
                    return prev.filter((id) => id !== orgId)
                  return [...prev, orgId]
                })
              }}
            />
          </TreeView>
        ) : (
          <Alert severity="info">No results found.</Alert>
        )}
      </Box>
    </Stack>
  )
}
