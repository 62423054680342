import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { Organization } from '@/types/organizations'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useGetOrganizations } from '@/service-library/hooks/organizations'
import useSessionStorage from '@/hooks/useSessionStorage'

type RootOrganizationContextType = {
  isLoading: boolean
  rootOrganizations: Organization[]
  rootOrganization: Organization
  setRootOrganization: (Organization: Organization) => void
  setRootOrganizationById: (id: string) => void
}

const RootOrganizationContext = createContext<RootOrganizationContextType>(
  {} as RootOrganizationContextType,
)

type RootOrganizationProviderProps = {
  children: ReactNode
}

export const useRootOrganization = () => useContext(RootOrganizationContext)

export default function RootOrganizationProvider({
  children,
}: RootOrganizationProviderProps) {
  const { organizations, isLoading } = useGetOrganizations({
    refetchOnWindowFocus: false,
    filters: {
      parent_org_id__isnull: 'true',
      ordering: 'name',
      limit: '1000',
    },
  })

  const [lastUsedRootOrg, setLastUsedRootOrg] = useLocalStorage<Organization>(
    'root-organization',
    {} as Organization,
  )

  const [rootOrganization, setRootOrganizationInStorage] =
    useSessionStorage<Organization>(
      'root-organization',
      lastUsedRootOrg || ({} as Organization),
    )

  const setRootOrganization = useCallback(
    (organization: Organization) => {
      setLastUsedRootOrg(organization)
      setRootOrganizationInStorage(organization)
    },
    [setLastUsedRootOrg, setRootOrganizationInStorage],
  )

  useEffect(() => {
    if (!rootOrganization.id && organizations.length > 0) {
      setRootOrganization(
        organizations.find(({ id }) => id.startsWith('2bed')) ||
          organizations[0],
      )
    }
  }, [organizations, rootOrganization, setRootOrganization])

  function updateLastUsedRootOrg() {
    setLastUsedRootOrg(rootOrganization)
  }

  /**
   * Update the last used root organization when the window is focused.
   * This way when a new window is opened, the last used root organization is set
   * for the new window's root organization.
   */
  useEffect(() => {
    window.addEventListener('focus', updateLastUsedRootOrg)
    return () => {
      window.removeEventListener('focus', updateLastUsedRootOrg)
    }
  })

  function setRootOrganizationById(id: string) {
    const org = organizations.find((org) => org.id === id)
    if (!org) {
      console.error(
        `Organization with id ${id} not found when switching root organization`,
      )
      return
    }
    setRootOrganization(org)
  }

  return rootOrganization.id ? (
    <RootOrganizationContext.Provider
      value={{
        isLoading,
        rootOrganizations: organizations,
        rootOrganization,
        setRootOrganization,
        setRootOrganizationById,
      }}
    >
      {children}
    </RootOrganizationContext.Provider>
  ) : null
}
