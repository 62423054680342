import axios from '../auth-v2-axios'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import useMutation, { UseMutationOptions } from '../core-hooks/useMutation'
import buildService from '../create-service'
import { TeamUser } from '@/types/team-users'
import { Team } from '@/types/teams'
import { User } from '@/types/users'

const service = buildService<TeamUser>({
  pathCategory: 'team_users',
})

export function useGetTeamUser({
  id,
  ...options
}: UseGetDetailOptions<TeamUser>) {
  const query = useGetDetail<TeamUser>({
    id,
    queryKey: queryKeys.teamUsers.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    teamUsers: query.data,
    ...query,
  }
}

export function useCreateTeamUser(
  options: UseCreateDetailOptions<TeamUser> = {},
) {
  const mutation = useCreateDetail<TeamUser>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createTeamUser: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateTeamUser(
  options: UseUpdateDetailOptions<TeamUser> = {},
) {
  const mutation = useUpdateDetail<TeamUser>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateTeamUser: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteTeamUser(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<TeamUser>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteTeamUser: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetTeamUsers({
  filters,
  ...options
}: UseGetListOptions<TeamUser> = {}) {
  const query = useGetList<TeamUser>({
    filters,
    queryKey: queryKeys.teamUsers.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    teamUsers: query.allData,
    ...query,
  }
}

export function useUpdateTeamUsers(
  options: UseUpdateListOptions<TeamUser> = {},
) {
  const mutation = useUpdateList<TeamUser>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateTeamUsers: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteTeamUsers({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<TeamUser>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteTeamUsers: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Delete By Teams And Users
 * Used to remove users from a team
 */
type MutateVariables = { team: Team; users: User[] }

export function useDeleteByTeamAndUsers(
  options: UseMutationOptions<void, unknown, MutateVariables> = {},
) {
  const query = useMutation<void, unknown, MutateVariables>({
    mutationFn: ({ team, users }: { team: Team; users: User[] }) => {
      const usersToRemove = users.map(({ id }) => ({
        team_id: team.id,
        user_id: id,
      }))
      return axios.delete<{ team_id: string; user_id: string }[], void>(
        '/v2/pd/team_users/delete_by_teams_and_users',
        {
          data: usersToRemove,
        },
      )
    },
    ...options,
  })
  return { ...query, deleteByTeamAndUsers: query.mutate }
}
