import { UseQueryOptions } from '@tanstack/react-query'
import useQuery from './useQuery'
import { LogixBoard } from '@/types/logix'
import { getLogixBoard } from '../logix'

type UseLogixBoardOptions = UseQueryOptions<LogixBoard> & {
  id: string
}

export const getQueryKey = (id: string) => ['logix-board', id]

export default function useLogixBoard({
  id,
  enabled,
  ...options
}: UseLogixBoardOptions) {
  const query = useQuery({
    queryKey: getQueryKey(id),
    queryFn: () => {
      return getLogixBoard({ id }).then((res) => res.data)
    },
    enabled: !!id && enabled,
    ...options,
  })

  return {
    board: query.data,
    ...query,
  }
}
