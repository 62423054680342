import { forwardRef } from 'react'
import { FieldValues, useController, FieldPath } from 'react-hook-form'
import DecimalField, { DecimalFieldProps } from '../inputs/DecimalField'

type FormDecimalFieldProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label: string
} & DecimalFieldProps

const FormDecimalField = forwardRef(function FormDecimalField<
  FormValues extends FieldValues,
>(
  { name, required, ...props }: FormDecimalFieldProps<FormValues>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { field } = useController<FormValues>({
    name,
    rules: {
      required,
    },
  })

  return <DecimalField {...props} {...field} inputRef={ref} />
})

export default FormDecimalField
