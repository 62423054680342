import { useState, useMemo, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { ProjectGrid } from '@/types/projects'
import { useGetProjectMappings } from '@/service-library/hooks/project-mappings'
import { ExportDocumentsBody, exportDocs } from '@/services/export'
import { getBaseGrid, sortBySortOrder } from '@/utils/field-utils'
import { useAuthentication } from '@/components/auth/AuthProvider'
import { useGetProjectGrids } from '@/service-library/hooks/project-grids'

type UseExportDocumentsOptions = {
  projectId: string
  enabled?: boolean
}

export default function useExportDocuments({
  projectId,
  enabled,
}: UseExportDocumentsOptions) {
  const queryClient = useQueryClient()
  const [exportLoading, setExportLoading] = useState(false)
  const [errors, setErrors] = useState<string | null>(null)
  const { getFreshIdToken } = useAuthentication()

  const {
    projectGrids,
    isLoading: gridsIsLoading,
    error: gridsError,
  } = useGetProjectGrids({
    filters: {
      limit: '1000',
      project_id: projectId,
      fields__include: 'project_grid_fields',
      project_grid_fields__fields__only:
        'id,project_grid_field_type,sub_project_grid_id',
    },
    enabled: enabled && !!projectId,
    refetchOnWindowFocus: false,
  })

  const baseGrid = getBaseGrid(projectGrids)

  const allFieldsIds: string[] | null = useMemo(() => {
    if (projectGrids && baseGrid?.project_grid_fields) {
      //TODO: Remove sorting for when the backend returns data sorted
      const sortedBaseGridFields = sortBySortOrder([
        ...baseGrid.project_grid_fields,
      ])
      return sortedBaseGridFields.flatMap((field) => {
        if (field.project_grid_field_type.code === 'grid') {
          const subGrid = projectGrids.find(
            ({ id }) => id === field.sub_project_grid_id,
          ) as ProjectGrid
          //TODO: Remove sorting for when the backend returns data sorted
          const sortedSubGridFields = sortBySortOrder([
            ...subGrid.project_grid_fields,
          ])
          return sortedSubGridFields.map(({ id }) => id)
        }
        return field.id
      })
    }
    return null
  }, [baseGrid?.project_grid_fields, projectGrids])

  const {
    projectMappings,
    isLoading: mappingIsLoading,
    error: mappingError,
  } = useGetProjectMappings({
    filters: {
      project_id: projectId,
      limit: '1',
      project_mapping_type__code: 'csv',
      fields__include: 'project_mapping_fields',
    },
    enabled: enabled && !!projectId,
    refetchOnWindowFocus: false,
  })

  const csvMapping = projectMappings[0]

  const mappingFields = useMemo(
    () => csvMapping?.project_mapping_fields,
    [csvMapping?.project_mapping_fields],
  )

  const mappedFieldIds: string[] | null = useMemo(() => {
    if (mappingFields?.length && allFieldsIds) {
      const includedFieldIds = mappingFields.map(
        (mappingField) => mappingField.project_grid_field_id,
      )
      return allFieldsIds.filter((fieldId) =>
        includedFieldIds.includes(fieldId),
      )
    }
    return null
  }, [mappingFields, allFieldsIds])

  const exportDocuments = async (
    projectId: string,
    exportType: string,
    project_grid_field_ids: string[] | null,
    workflow_id: string,
    workflow_state_id: string,
    documentIds?: string[],
  ) => {
    setExportLoading(true)
    setErrors(null)

    const body: ExportDocumentsBody = {
      group_by_org: true,
      project_id: projectId,
      workflow_id,
      workflow_state_id,
      full_data: exportType === 'full-json',
    }

    if (exportType !== 'full-json') {
      body.project_grid_field_ids = project_grid_field_ids
      body.project_mapping_id = csvMapping?.id as string
    }

    if (documentIds) body.document_ids = documentIds

    return getFreshIdToken()
      .then((token: string) => {
        const config = { headers: { Authorization: `Bearer ${token}` } }
        return exportDocs(exportType === 'csv' ? 'csv' : 'json', body, config)
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: ['export_files', { projectId }],
            })
          })
          .catch((err) => {
            throw err
          })
      })
      .catch((err: Error) => {
        setErrors('Unable to export documents. Please try again later.')
        throw err
      })
      .finally(() => setExportLoading(false))
  }

  useEffect(() => {
    if (mappingError || gridsError) {
      setErrors(
        'There was a problem retrieving field configuration data. Please try again later.',
      )
    }
  }, [mappingError, gridsError])

  return {
    isLoading: gridsIsLoading || mappingIsLoading || exportLoading,
    exportDocuments,
    mappedFieldIds,
    allFieldsIds,
    csvMappingId: csvMapping?.id,
    errors,
    setErrors,
  }
}
