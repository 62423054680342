import { GroupOption } from '@/types/metrics'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'

type GroupedBySelectProps = {
  groupedBy: string
  onChange: (value: GroupOption) => void
}

const options: GroupOption[] = ['hour', 'day', 'month', 'year']

export default function GroupedBySelect({
  groupedBy,
  onChange,
}: GroupedBySelectProps) {
  return (
    <FormControl size="small" variant="filled" sx={{ width: 110 }}>
      <InputLabel id="grouped-by">Grouped By</InputLabel>
      <Select
        labelId="grouped-by"
        value={groupedBy}
        onChange={(e) => onChange(e.target.value as GroupOption)}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Tooltip
              title={
                option === 'hour' ? 'Only accepts a range of up to 7 days' : ''
              }
              enterDelay={500}
              disableInteractive
            >
              <Typography sx={{ width: '100%' }}>
                {option.charAt(0).toUpperCase().concat(option.slice(1))}
              </Typography>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
