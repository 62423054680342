import { useRef, FocusEvent, KeyboardEvent } from 'react'
import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers'
import { SxProps, TextFieldProps } from '@mui/material'
import { Dayjs } from 'dayjs'

const typeMap = {
  date: DatePicker,
  datetime: DateTimePicker,
  time: TimePicker,
}

export type DateTimeValueInputProps<PropsType> = {
  value?: Dayjs
  handleSubmit?: (value: string) => void
  onClose?: () => void
  type?: 'date' | 'datetime' | 'time'
  onKeyDown?: (e: React.KeyboardEvent, callback: () => void) => void
  sx?: SxProps
  inputRef?: React.Ref<HTMLInputElement>
  required?: boolean
  fullWidth?: boolean
  autoFocus?: TextFieldProps['autoFocus']
  variant?: TextFieldProps['variant']
  InputLabelProps?: TextFieldProps['InputLabelProps']
} & PropsType

export default function DateTimeValueInput<PropsType>({
  value,
  handleSubmit,
  onClose,
  type = 'date',
  onKeyDown,
  sx,
  fullWidth,
  InputLabelProps,
  autoFocus = false,
  variant = 'filled',
  ...props
}: DateTimeValueInputProps<PropsType>) {
  const Component = typeMap[type]
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  function prepForSubmit(value?: string | null) {
    if (!value) handleSubmit?.('')
    else {
      const date = new Date(value)
      handleSubmit?.(date.toISOString())
    }
  }

  // This blur handler has to go on the containing element so it will trigger
  // when they tab off the date picker button, not just the input field.
  function handleContainerBlur(
    e: FocusEvent<HTMLDivElement | HTMLButtonElement>,
  ) {
    // This set timeout pushes this logic to the next event cycle,
    // giving the date picker overlay the chance to open (if it is supposed to)
    setTimeout(() => {
      if (
        // Check if the element we're switching focus to is _outside_ of the date picker
        !containerRef.current?.contains(e.relatedTarget) &&
        // Check if the element we're switching focus to is _outside_ of the date picker overlay
        !popperRef.current?.contains(e.relatedTarget) &&
        // Check that we didn't click the date picker button
        e.target !== buttonRef.current
      ) {
        prepForSubmit(inputRef.current?.value)
        onClose?.()
      }
    }, 0)
  }

  function handleKeyDown(e: KeyboardEvent) {
    onKeyDown?.(e, () => prepForSubmit(inputRef.current?.value))
  }

  return (
    <div ref={containerRef} onBlur={handleContainerBlur}>
      <Component
        value={value}
        inputRef={inputRef}
        slotProps={{
          field: {
            clearable: true,
          },
          openPickerButton: {
            ref: buttonRef,
          },
          textField: {
            autoFocus,
            size: 'small',
            onKeyDown: handleKeyDown,
            variant,
            sx,
            fullWidth,
            InputLabelProps,
          },
        }}
        onClose={onClose}
        onAccept={prepForSubmit}
        onChange={() => {}} // Required by the component, but not needed by us
        PopperProps={{ ref: popperRef }}
        {...props}
      />
    </div>
  )
}
