import { Draggable } from 'react-smooth-dnd'
import { Delete, DragIndicator } from '@mui/icons-material'
import {
  Stack,
  Typography,
  IconButton,
  Card,
  Tooltip,
  ListItemButton,
  Switch,
} from '@mui/material'
import { grey } from '@mui/material/colors'

type RuleCardProps = {
  name: string
  description: string
  onClick: () => void
  onDelete: () => void
  funcName?: string
  enabled?: boolean
  isSelected?: boolean
  onEnableDisable?: (checked: boolean) => void
}

export default function RuleCard({
  name,
  description,
  onClick,
  onDelete,
  funcName,
  enabled,
  isSelected,
  onEnableDisable,
}: RuleCardProps) {
  return (
    // @ts-expect-error -- Draggable can have children
    <Draggable>
      <Card variant="outlined" sx={{ mb: 1, background: 'inherit' }}>
        <ListItemButton
          sx={{ p: 2, height: '100%' }}
          selected={isSelected}
          onClick={() => onClick()}
        >
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Tooltip title={enabled ? 'Disable' : 'Enable'} enterDelay={800}>
              <Switch
                checked={enabled}
                onChange={(_e, checked) => {
                  onEnableDisable?.(checked)
                }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </Tooltip>
            <Stack flexGrow={1}>
              <Typography color={enabled ? undefined : 'text.secondary'}>
                {name}
              </Typography>
              {funcName && (
                <Typography color="secondary" variant="caption">
                  {funcName}
                </Typography>
              )}
              <Typography
                variant="caption"
                color={grey[400]}
                component="div"
                sx={{ lineHeight: 1.5 }}
              >
                {description}
              </Typography>
            </Stack>
            <IconButton
              onClick={(e) => {
                onDelete()
                e.stopPropagation()
              }}
              size="small"
            >
              <Delete color="error" fontSize="inherit" />
            </IconButton>
            <DragIndicator
              className="drag-handle"
              sx={{ cursor: 'grab', ':active': { cursor: 'grabbing' } }}
            />
          </Stack>
        </ListItemButton>
      </Card>
    </Draggable>
  )
}
