import { UseQueryOptions } from '@tanstack/react-query'
import { LogixEdge } from '@/types/logix'
import { getLogixEdges } from '@/services/logix'
import useQuery from './useQuery'

type UseLogixEdgesOptions = UseQueryOptions<LogixEdge[]> & {
  boardId: string
}

export const getQueryKey = (boardId: string) => ['logix-edges', boardId]

export default function useLogixEdges({
  boardId,
  enabled,
  ...options
}: UseLogixEdgesOptions) {
  const query = useQuery({
    queryKey: getQueryKey(boardId),
    queryFn: () =>
      getLogixEdges(boardId as string).then(({ data }) => data.results),
    enabled: !!boardId && enabled,
    ...options,
  })

  return {
    edges: query.data,
    ...query,
  }
}
