import { useMemo } from 'react'
import { ProjectDocumentRule, ProjectDocumentRuleType } from '@/types/rules'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetProjectDocumentRuleTypes } from '@/service-library/hooks/project-document-rule-types'
import generateUuid from '@/utils/generate-uuid'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import AddRuleDialog, { FormValues } from '@/components/rules/AddRuleDialog'

type AddDocumentRuleDialogProps = {
  overlay: OverlayState
  onSubmit: (rule: ProjectDocumentRule) => void
}

export default function AddDocumentRuleDialog({
  overlay,
  onSubmit,
}: AddDocumentRuleDialogProps) {
  const { project } = useProjectContext()
  const featureFlags = useFeatureFlagContext()

  const { projectDocumentRuleTypes, isLoading } =
    useGetProjectDocumentRuleTypes({
      filters: { limit: '1000' },
      refetchOnWindowFocus: false,
      enabled: overlay.isOpen,
    })

  const filteredTypes = useMemo(
    () =>
      projectDocumentRuleTypes.filter(({ code }) => {
        return code === 'doc_run_py' ? featureFlags.custom_python_rules : true
      }) || [],
    [featureFlags.custom_python_rules, projectDocumentRuleTypes],
  )

  const handleCreateDocumentRule = ({
    type,
    workflow_states_ids,
    dependent_fields,
    is_superuser_rule,
    ...rest
  }: FormValues<ProjectDocumentRuleType>) => {
    onSubmit({
      project_id: project.id,
      id: generateUuid(),
      sort_order: 1000001,
      type_id: type.id,
      type,
      params: rest || {},
      workflow_states_ids,
      is_enabled: true,
      is_superuser_rule,
      dependent_fields_ids: dependent_fields.map(({ id }) => id),
    })
  }

  return (
    <AddRuleDialog
      isLoading={isLoading}
      overlay={overlay}
      ruleTypes={filteredTypes}
      onSubmit={handleCreateDocumentRule}
    />
  )
}
