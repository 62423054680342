import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'
import { useColumnOrderContext } from '@/components/workflows/ColumnOrderProvider'
import { useColumnSortingContext } from '@/components/workflows/ColumnSortingProvider'
import { useViewsContext } from '@/components/workflows/ViewsProvider'
import ViewNameWithActions from './ViewNameWithActions'
import NewViewRadioOption from './NewViewRadioOption'

export default function ViewsFilter() {
  const viewContext = useViewsContext()
  const {
    allViews,
    canEdit,
    savedEnabled,
    selectedView,
    handleCancelChange,
    setSelectedViewId,
    setSavedEnabled,
    queryKey,
  } = viewContext

  const { resetColumnOrder } = useColumnOrderContext()
  const { resetColumnSorting } = useColumnSortingContext()
  const { resetDocumentFilters } = useDocumentFiltersContext()

  const onCancel = () => {
    resetColumnOrder()
    resetColumnSorting()
    resetDocumentFilters()
  }

  return (
    <Stack spacing={0.5}>
      <Typography variant="body1">Views</Typography>
      <RadioGroup
        value={selectedView.id}
        onChange={(e) => {
          setSelectedViewId(e.target.value)
          handleCancelChange(onCancel)
        }}
        sx={{ ml: '8px !important' }}
      >
        {allViews.map((view) => {
          return (
            <FormControlLabel
              key={view.id}
              value={view.id}
              control={
                <Radio color="neutral" sx={{ padding: 0.5 }} size="small" />
              }
              label={
                <ViewNameWithActions
                  selected={selectedView.id === view.id}
                  view={view}
                  viewsContext={viewContext}
                />
              }
              sx={{
                alignItems: 'flex-center',
                columnGap: 0.5,
                width: 'fit-content',
              }}
            />
          )
        })}
        {savedEnabled && canEdit && (
          <NewViewRadioOption
            listQueryKey={queryKey}
            setSavedEnabled={setSavedEnabled}
            setSelectedViewId={setSelectedViewId}
          />
        )}
      </RadioGroup>
    </Stack>
  )
}
