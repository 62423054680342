import { useMemo } from 'react'
import { ProjectGridField } from '@/types/fields'
import { ProjectGrid } from '@/types/projects'
import { useColumnSortingContext } from '@/components/workflows/ColumnSortingProvider'

type UseColumnOrderingOptions = {
  fields: ProjectGridField[]
  selectedGrids: Record<string, ProjectGrid>
  baseGridId?: string
}

export default function useColumnSortingLogic({
  fields,
  selectedGrids,
  baseGridId,
}: UseColumnOrderingOptions) {
  const { sorting } = useColumnSortingContext()

  const sortingLogic = useMemo(() => {
    if (sorting.length === 0 || !baseGridId)
      return {
        columnIds: [],
        fieldType: undefined,
        gridId: undefined,
      }

    let gridId: undefined | string = undefined

    for (const sortingField of sorting) {
      const isMetadataColumn = sortingField.id.endsWith('ColId')
      if (!gridId && !isMetadataColumn) {
        gridId = fields.find(
          (field) => field.id === sortingField.id,
        )?.project_grid_id
        break
      }
    }

    const selectedGridsIds = Object.keys(selectedGrids)

    let fieldType: 'base' | 'table' = gridId === baseGridId ? 'base' : 'table'

    if (!gridId && selectedGridsIds.includes(baseGridId)) {
      fieldType = selectedGridsIds.length > 1 ? 'base' : 'table'
    }

    const columnIds = sorting.map(
      (sortingField) => `${sortingField.desc ? '-' : ''}${sortingField.id}`,
    )

    return {
      columnIds,
      fieldType,
      gridId,
    }
  }, [baseGridId, fields, selectedGrids, sorting])

  return sortingLogic
}
