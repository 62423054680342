import { useRef } from 'react'
import { useController } from 'react-hook-form'
import { Close } from '@mui/icons-material'
import {
  Stack,
  FormControl,
  FormLabel,
  Typography,
  Button,
  Menu,
  ListSubheader,
  MenuItem,
  List,
  ListItem,
  IconButton,
  Divider,
} from '@mui/material'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { sortBySortOrder } from '@/utils/field-utils'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import LeftBorderedBox from '@/components/validation/LeftBorderedBox'
import { WorkflowState } from '@/types/workflows'

const hiddenWorkflowCodes = ['labeling', 'categorization']

export default function RuleWorkflowStates() {
  const { field } = useController({ name: 'workflow_states_ids' })
  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()
  const [themeMode] = useLocalStorage('pixydocs-theme-mode', 'dark')
  const { workflows } = useWorkflowsContext()
  const getThemeColor = useGetThemeColor()

  const overlay = useOverlay()
  const anchorRef = useRef<HTMLButtonElement>(null)

  function toggleStatesSelection(select: boolean, states?: WorkflowState[]) {
    if (!states) return
    const stateIds = states.map((state) => state.id)
    const newValues = select
      ? [...field.value, ...stateIds]
      : field.value.filter((id: string) => !stateIds.includes(id))
    field.onChange(newValues)
  }

  return (
    <FormControl sx={{ rowGap: 1 }}>
      <FormLabel>Run in Workflow States</FormLabel>

      <Button
        ref={anchorRef}
        onClick={overlay.toggle}
        variant="text"
        sx={{
          width: 'fit-content',
          ml: -0.75,
        }}
      >
        Set Workflow States
      </Button>

      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
        MenuListProps={{ disablePadding: true }}
      >
        {workflows.map((workflow) => {
          const isHidden = hiddenWorkflowCodes?.includes(workflow.code)
          if (isHidden && (!isSuperUser || demoMode)) return
          const allStatesAreSelected = workflow.workflow_states?.every(
            (state) => field.value.includes(state.id),
          )
          return (
            <Stack key={workflow.id} sx={{ minWidth: 250 }}>
              <ListSubheader
                component={Stack}
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  color: isHidden ? SUPER_USER_ONLY_COLOR : undefined,
                  background: (theme) => theme.palette.background.paper, // using the same color as the subheader for table fields
                }}
              >
                <Typography variant="inherit">
                  {workflow.name}
                  {isHidden ? ' (Super User Only)' : ''}
                </Typography>
                <Button
                  variant="text"
                  onClick={() => {
                    toggleStatesSelection(
                      !allStatesAreSelected,
                      workflow.workflow_states,
                    )
                  }}
                >
                  {allStatesAreSelected ? 'Deselect' : 'Select'} All
                </Button>
              </ListSubheader>
              {sortBySortOrder(workflow.workflow_states || []).map((state) => {
                if (state.code === 'processing') return
                const color = getThemeColor(state.color)
                const isSelected = field.value.includes(state.id)
                return (
                  <MenuItem
                    key={state.id}
                    dense
                    onClick={() => {
                      const values = isSelected
                        ? field.value.filter(
                            (item: string) => item !== state.id,
                          )
                        : [...field.value, state.id]

                      field.onChange(values)
                    }}
                    selected={isSelected}
                  >
                    <LeftBorderedBox color={color}>
                      {state.name}
                    </LeftBorderedBox>
                  </MenuItem>
                )
              })}
              {themeMode === 'light' && (
                <Divider sx={{ m: '0px !important' }} />
              )}
            </Stack>
          )
        })}
      </Menu>

      {!field.value.length && (
        <Typography color="text.secondary" variant="body2">
          This rule will not run unless it is set to run in at least one
          workflow state.
        </Typography>
      )}

      {!!field.value.length &&
        workflows.map((workflow) => {
          const isHidden = hiddenWorkflowCodes?.includes(workflow.code)
          if (isHidden && (!isSuperUser || demoMode)) return
          const selectedWorkflowStates =
            workflow.workflow_states?.filter(
              ({ id, code }) =>
                code !== 'processing' && field.value.includes(id),
            ) || []
          if (!selectedWorkflowStates.length) return null
          return (
            <Stack
              key={workflow.id}
              sx={
                isHidden
                  ? {
                      p: 2,
                      border: `solid 1px ${SUPER_USER_ONLY_COLOR}`,
                      borderRadius: 2,
                    }
                  : undefined
              }
            >
              {isHidden && (
                <Typography
                  variant="body2"
                  sx={{ color: SUPER_USER_ONLY_COLOR }}
                >
                  Super User Only
                </Typography>
              )}
              <List dense>
                <Typography>{workflow.name}</Typography>
                {sortBySortOrder(selectedWorkflowStates).map((state) => {
                  const color = getThemeColor(state.color)
                  return (
                    <ListItem key={state.id} sx={{ columnGap: 0.5 }}>
                      <LeftBorderedBox color={color}>
                        {state.name}
                      </LeftBorderedBox>
                      <IconButton
                        onClick={() => {
                          field.onChange(
                            field.value.filter(
                              (item: string) => item !== state.id,
                            ),
                          )
                        }}
                        size="small"
                      >
                        <Close fontSize="inherit" />
                      </IconButton>
                    </ListItem>
                  )
                })}
              </List>
            </Stack>
          )
        })}
    </FormControl>
  )
}
