import { AppBar, Stack, Typography, useTheme } from '@mui/material'

export default function SRAppBar() {
  const theme = useTheme()
  return (
    <AppBar
      position="static"
      elevation={1}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        py: '2px',
        background: 'white',
        color: 'black',
        height: 60,
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ height: '100%', px: 2 }}>
        <Typography variant="h6">SalesRabbit</Typography>
      </Stack>
    </AppBar>
  )
}
