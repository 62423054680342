import { Box } from '@mui/material'
import { ReactNode } from 'react'

type PrimaryTextWrapperProps = {
  children: ReactNode
}

export default function PrimaryTextWrapper({
  children,
}: PrimaryTextWrapperProps) {
  return (
    <Box component="span" sx={{ color: 'text.primary' }}>
      {children}
    </Box>
  )
}
