import { useEffect, RefObject, useState } from 'react'

type UseSizeObserverOptions = {
  containerRef: RefObject<HTMLDivElement>
}

export default function useSizeObserver({
  containerRef,
}: UseSizeObserverOptions) {
  const [size, setSize] = useState({ height: 0, width: 0 })
  const current = containerRef.current

  useEffect(() => {
    if (!current) return
    const observer = new ResizeObserver(() => {
      setSize({
        height: current?.clientHeight || 0,
        width: current?.clientWidth || 0,
      })
    })
    observer.observe(current)

    return () => {
      observer.disconnect()
    }
  }, [current])

  return size
}
