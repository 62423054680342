import { Dispatch, SetStateAction, useMemo } from 'react'
import dayjs from 'dayjs'
import { DateView, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { GroupOption } from '@/types/metrics'
import { convertToDate, getEndDate } from '@/utils/date-metrics'
import DatePicker from '@/components/data-visualization/DatePicker'
import GroupedBySelect from '@/components/data-visualization/GroupedBySelect'
import useMetricsSearchParams from '@/hooks/useMetricsSearchParams'

type MetricsCommonFiltersProps = {
  startDateState: [Date, Dispatch<SetStateAction<Date>>]
  endDateState: [Date, Dispatch<SetStateAction<Date>>]
  groupedByState: [GroupOption, Dispatch<SetStateAction<GroupOption>>]
  updateValues: ReturnType<typeof useMetricsSearchParams>['updateValues']
}

export default function MetricsCommonFilters({
  startDateState,
  endDateState,
  groupedByState,
  updateValues,
}: MetricsCommonFiltersProps) {
  const [startDate, setStartDate] = startDateState
  const [endDate, setEndDate] = endDateState
  const [groupedBy, setGroupedBy] = groupedByState

  const changeEndDate = (initialDate: Date) => {
    const newEndDate = getEndDate(initialDate, endDate)
    if (newEndDate) setEndDate(newEndDate)
    return newEndDate
  }

  const { datePickerFormat, datePickerViews } = useMemo<{
    datePickerFormat: string
    datePickerViews?: DateView[]
  }>(() => {
    switch (groupedBy) {
      case 'month':
        return {
          datePickerFormat: 'MMM YYYY',
          datePickerViews: ['month', 'year'],
        }
      case 'year':
        return { datePickerFormat: 'YYYY', datePickerViews: ['year'] }
      default:
        return {
          datePickerFormat: 'MM/DD/YYYY',
          datePickerViews: undefined,
        }
    }
  }, [groupedBy])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GroupedBySelect
        groupedBy={groupedBy}
        onChange={(value) => {
          setGroupedBy(value)
          const paramsToUpdate: { grouped_by: string; to?: string } = {
            grouped_by: value,
          }
          if (value === 'hour') {
            const newEndDate = changeEndDate(startDate)
            if (newEndDate) paramsToUpdate.to = newEndDate.toString()
          }
          updateValues(paramsToUpdate)
        }}
      />
      {/* Start Date */}
      <DatePicker
        onChange={(date) => {
          const newStartDate = convertToDate(date)
          setStartDate(newStartDate)
          const paramsToUpdate: { from: string; to?: string } = {
            from: newStartDate.toString(),
          }
          if (groupedBy === 'hour') {
            const newEndDate = changeEndDate(newStartDate)
            if (newEndDate) paramsToUpdate.to = newEndDate.toString()
          }
          updateValues(paramsToUpdate)
        }}
        value={startDate}
        label="From"
        format={datePickerFormat}
        maxDate={endDate}
        openTo={datePickerViews?.[0] || 'day'}
        views={datePickerViews}
      />
      {/* End Date */}
      <DatePicker
        onChange={(date) => {
          const newEndDate = convertToDate(date, false)
          setEndDate(newEndDate)
          updateValues({ to: newEndDate.toString() })
        }}
        value={endDate}
        label="To"
        format={datePickerFormat}
        minDate={startDate}
        maxDate={
          groupedBy !== 'hour' ||
          dayjs().isSameOrBefore(dayjs(startDate).add(6, 'day'), 'day')
            ? dayjs()
            : dayjs(startDate).add(6, 'day')
        }
        openTo={datePickerViews?.[0] || 'day'}
        views={datePickerViews}
      />
    </LocalizationProvider>
  )
}
