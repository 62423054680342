import { ListItemButton, ListItemText } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import useLocationHelpers from '@/hooks/useLocationHelpers'

export type SettingsNavigationItemProps = {
  label: string
  to: string
  isSuperUserOnly?: boolean
  keepQueryParams?: boolean
}

export default function SettingsNavigationItem({
  label,
  to,
  isSuperUserOnly,
  keepQueryParams,
}: SettingsNavigationItemProps) {
  const { pathname } = useLocation()
  const { appendCurrentParamsToUrl } = useLocationHelpers()
  const selected = pathname.split('/').includes(to)

  return (
    <ListItemButton
      component={Link}
      to={keepQueryParams ? appendCurrentParamsToUrl(to) : to}
      selected={selected}
      sx={{
        borderRadius: 2,
        py: 0.5,
        px: 1.5,
        color: isSuperUserOnly ? SUPER_USER_ONLY_COLOR : undefined,
      }}
    >
      <ListItemText primary={label} color="primary" />
    </ListItemButton>
  )
}
