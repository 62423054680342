import { useNavigate } from 'react-router-dom'
import { MRT_ColumnDef, MRT_TableOptions } from 'material-react-table'
import { Stack, Typography } from '@mui/material'
import { Organization } from '@/types/organizations'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import useZerapixTable from '../zerapix-table/useZerapixTable'

type ChildrenOrganizationsProps = {
  organizations: Organization[]
  isLoading: boolean
  noRecordsToDisplay?: string
  title?: string
  renderTableActions?: MRT_TableOptions<Organization>['renderTopToolbarCustomActions']
  renderRowActionMenuItems?: MRT_TableOptions<Organization>['renderRowActionMenuItems']
}

const columns: MRT_ColumnDef<Organization>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
    Cell: ({ row }) => (
      <Stack direction="row" spacing={1}>
        <Typography variant="body2">{row.original.name}</Typography>
        {!row.original.parent_org_id && (
          <Typography variant="body2" color="text.secondary">
            (Root)
          </Typography>
        )}
      </Stack>
    ),
  },
  {
    header: 'Code',
    accessorKey: 'code',
  },
]

export default function ChildrenOrganizations({
  organizations,
  isLoading,
  noRecordsToDisplay = 'No Organizations',
  title = 'Organizations',
  renderTableActions,
  renderRowActionMenuItems,
}: ChildrenOrganizationsProps) {
  const navigate = useNavigate()

  const table = useZerapixTable<Organization>({
    title,
    columns,
    data: organizations,
    enableColumnResizing: true,
    renderTableActions,
    renderRowActionMenuItems,
    enableRowActions: !!renderRowActionMenuItems,
    enableRowSelection: !!renderRowActionMenuItems,
    enableMultiRowSelection: !!renderRowActionMenuItems,
    getRowId: (row) => row.id,
    state: {
      showSkeletons: isLoading,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/settings/organizations/${row.original.id}`)
      },
      sx: {
        cursor: 'pointer',
      },
    }),
    localization: { noRecordsToDisplay },
  })

  return <ZerapixTable<Organization> table={table} />
}
