import React, { useEffect } from 'react'
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { SnackbarProvider } from 'notistack'
import { datadogLogs } from '@datadog/browser-logs'
import { makeStyles } from '@mui/styles'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import CssBaseline from '@mui/material/CssBaseline'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import FeatureFlagProvider from '@/feature_flags/FeatureFlagProvider'
import { isLocalDev } from '@/utils/local-dev'
import AuthProvider, { useAuthentication } from '@/components/auth/AuthProvider'
import DemoModeProvider from '@/components/demo-mode-provider/DemoModeProvider'
import { NotificationProvider } from '@/components/notifications/NotificationProvider'
import PageTitle from '@/components/PageTitle'
import Application from './pages/Application'
import ZerapixThemeProvider from './theme'
import SalesRabbitDemo from './components/salesrabbit-demo/SalesRabbitDemo'
import RecentOrganizationsProvider from './components/organizations/RecentOrganizationsProvider'
import RootOrganizationProvider from './components/organizations/RootOrganizationProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

!isLocalDev() &&
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    environment: import.meta.env.VITE_ENVIRONMENT,

    // We recommend adjusting this value in production, or using tracesSampler
    // For finer control
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
  })

!isLocalDev() &&
  datadogLogs.init({
    clientToken: 'pub1ad8354d55ae38a2103b16352b97ac95',
    site: 'us5.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'pixydocs-web',
    env: import.meta.env.VITE_ENVIRONMENT,
    version: import.meta.env.VITE_VERSION,
  })

LicenseInfo.setLicenseKey(
  'e3e642adfa4c51f5def5ab3afb83d584T1JERVI6MzE4NDQsRVhQSVJZPTE2Njc0Mjc3NjEwMDAsS0VZVkVSU0lPTj0x',
)

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

type RequireAuthProps = {
  children: JSX.Element
}

const useStyles = makeStyles({
  root: {
    bottom: 60,
  },
})

function RequireAuth({ children }: RequireAuthProps) {
  const { authenticated, isLoading } = useAuthentication()
  if (isLoading) return null
  if (!authenticated) Auth.federatedSignIn()
  return authenticated ? children : null
}

export default function Main() {
  const classes = useStyles()

  // Normally react-query takes care of garbage collection, but it turns out there are rare circumstances where it doesn't.
  // This is a fall-back to take care of any inactive queries that are not being garbage collected.
  useEffect(() => {
    setInterval(() => {
      // @ts-expect-error - private property
      if (queryClient.queryCache.queries.length > 100) {
        queryClient.removeQueries({
          type: 'inactive',
        })
      }
    }, 1000 * 60 * 15) // 30 minutes
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <PageTitle>Pixydocs</PageTitle>
      <AuthProvider>
        <ZerapixThemeProvider>
          <FeatureFlagProvider>
            <DemoModeProvider>
              <CssBaseline />
              <Router>
                <SentryRoutes>
                  <Route
                    path="/salesrabbit-demo"
                    element={
                      <RequireAuth>
                        <SnackbarProvider>
                          <NotificationProvider>
                            <RootOrganizationProvider>
                              <RecentOrganizationsProvider>
                                <SalesRabbitDemo />
                              </RecentOrganizationsProvider>
                            </RootOrganizationProvider>
                          </NotificationProvider>
                        </SnackbarProvider>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <>
                        <SnackbarProvider
                          autoHideDuration={2500}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          classes={{
                            containerRoot: classes.root,
                          }}
                        >
                          <NotificationProvider>
                            <RequireAuth>
                              <Application />
                            </RequireAuth>
                          </NotificationProvider>
                        </SnackbarProvider>
                        <ReactQueryDevtools position="bottom-right" />
                      </>
                    }
                  />
                </SentryRoutes>
              </Router>
            </DemoModeProvider>
          </FeatureFlagProvider>
        </ZerapixThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}
