import { Group } from '@/types/groups'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<Group>({
  pathCategory: 'groups',
})

export function useGetGroup({ id, ...options }: UseGetDetailOptions<Group>) {
  const query = useGetDetail<Group>({
    id,
    queryKey: queryKeys.groups.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    group: query.data,
    ...query,
  }
}

export function useGetGroups({
  filters,
  ...options
}: UseGetListOptions<Group> = {}) {
  const query = useGetList<Group>({
    filters,
    queryKey: queryKeys.groups.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    groups: query.allData,
    ...query,
  }
}
