import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material'
import { useGetLogEntries } from '@/service-library/hooks/log-entries'
import { useGetUsers } from '@/service-library/hooks/users'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'
import SectionAccordion from './SectionAccordion'
import EntryLongDescription from './EntryLongDescription'

type DocumentLogEntriesProps = {
  documentId: string
  enabled: boolean
}

export default function DocumentLogEntries({
  documentId,
  enabled,
}: DocumentLogEntriesProps) {
  const { logEntries, isLoading: entriesIsLoading } = useGetLogEntries({
    filters: {
      endpoint: '/v2/pd/documents',
      obj_id: documentId,
    },
    enabled,
  })

  const { users, isLoading: usersIsLoading } = useGetUsers({
    filters: {
      id__in: logEntries.map((entry) => entry.user_id).join(),
      fields__only: 'id,name',
    },
    enabled: enabled && !entriesIsLoading && !!logEntries.length,
  })

  const isLoading = logEntries.length ? usersIsLoading : entriesIsLoading

  const usersMap = users.reduce<Record<string, string>>((acc, user) => {
    acc[user.id] = user.name
    return acc
  }, {})

  return (
    <SectionAccordion title="Activity Log Entries">
      <List disablePadding>
        {isLoading && (
          <>
            <ListItem disablePadding>
              <ListItemText
                primary={<Skeleton animation="wave" width="85%" />}
                secondary={<Skeleton animation="wave" width="40%" />}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={<Skeleton animation="wave" width="80%" />}
                secondary={<Skeleton animation="wave" width="40%" />}
              />
            </ListItem>
          </>
        )}
        {!isLoading &&
          (logEntries.length ? (
            logEntries.map((logEntry) => {
              const { id, created_at, user_id } = logEntry
              const userName = usersMap[user_id] || 'System'
              return (
                <ListItem alignItems="flex-start" key={id} disablePadding>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: 'text.primary', display: 'inline' }}
                        >
                          <b>{userName}</b>
                        </Typography>
                        <Typography variant="caption">
                          {' - '}
                          <Typography color="text.secondary" variant="caption">
                            {prettifyDate(created_at)}
                            {' - '}
                          </Typography>
                          <EntryLongDescription {...logEntry} />
                        </Typography>
                      </>
                    }
                    primaryTypographyProps={{
                      component: 'p',
                      sx: {
                        lineHeight: 1,
                      },
                    }}
                    sx={{ my: '2px' }}
                  />
                </ListItem>
              )
            })
          ) : (
            <Typography color="text.secondary" variant="body2">
              None
            </Typography>
          ))}
      </List>
    </SectionAccordion>
  )
}
