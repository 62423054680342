import { ProjectDocumentRule } from '@/types/rules'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<ProjectDocumentRule>({
  pathCategory: 'project_document_rules',
})

export function useGetProjectDocumentRule({
  id,
  ...options
}: UseGetDetailOptions<ProjectDocumentRule>) {
  const query = useGetDetail<ProjectDocumentRule>({
    id,
    queryKey: queryKeys.projectDocumentRules.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectDocumentRules: query.data,
    ...query,
  }
}

export function useCreateProjectDocumentRule(
  options: UseCreateDetailOptions<ProjectDocumentRule> = {},
) {
  const mutation = useCreateDetail<ProjectDocumentRule>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectDocumentRule: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectDocumentRule(
  options: UseUpdateDetailOptions<ProjectDocumentRule> = {},
) {
  const mutation = useUpdateDetail<ProjectDocumentRule>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectDocumentRule: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectDocumentRule(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<ProjectDocumentRule>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectDocumentRule: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectDocumentRules({
  filters,
  ...options
}: UseGetListOptions<ProjectDocumentRule> = {}) {
  const query = useGetList<ProjectDocumentRule>({
    filters,
    queryKey: queryKeys.projectDocumentRules.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectDocumentRules: query.allData,
    ...query,
  }
}

export function useUpdateProjectDocumentRules(
  options: UseUpdateListOptions<ProjectDocumentRule> = {},
) {
  const mutation = useUpdateList<ProjectDocumentRule>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectDocumentRules: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectDocumentRules({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectDocumentRule>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectDocumentRules: mutation.mutateAsync,
    ...mutation,
  }
}
