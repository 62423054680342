import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'

type FieldPortalContextValue = [Element, Dispatch<SetStateAction<Element>>]

type FieldPortalProviderProps = {
  children: ReactNode
  hidePortal?: boolean
}

const FieldPortalContext = createContext<FieldPortalContextValue>(
  [] as unknown as FieldPortalContextValue,
)

export const useFieldPortalContext = () => useContext(FieldPortalContext)

export default function FieldPortalProvider({
  children,
  hidePortal = false,
}: FieldPortalProviderProps) {
  const portalNodeState = useState<Element>(document.createElement('div'))

  return hidePortal ? (
    <>{children}</>
  ) : (
    <FieldPortalContext.Provider value={portalNodeState}>
      {children}
    </FieldPortalContext.Provider>
  )
}
