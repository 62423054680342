import { CustomVariableValue } from '@/types/custom-variables'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<CustomVariableValue>({
  pathCategory: 'custom_variable_values',
})

export function useGetCustomVariableValue({
  id,
  ...options
}: UseGetDetailOptions<CustomVariableValue>) {
  const query = useGetDetail<CustomVariableValue>({
    id,
    queryKey: queryKeys.customVariableValues.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    customVariableValue: query.data,
    ...query,
  }
}

export function useCreateCustomVariableValue(
  options: UseCreateDetailOptions<CustomVariableValue> = {},
) {
  const mutation = useCreateDetail<CustomVariableValue>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createCustomVariableValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateCustomVariableValue(
  options: UseUpdateDetailOptions<CustomVariableValue> = {},
) {
  const mutation = useUpdateDetail<CustomVariableValue>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateCustomVariableValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteCustomVariableValue(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<CustomVariableValue>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteCustomVariableValue: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetCustomVariableValues({
  filters,
  ...options
}: UseGetListOptions<CustomVariableValue> = {}) {
  const query = useGetList<CustomVariableValue>({
    filters,
    queryKey: queryKeys.customVariableValues.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    customVariableValues: query.allData,
    ...query,
  }
}

export function useUpdateCustomVariableValues(
  options: UseUpdateListOptions<CustomVariableValue> = {},
) {
  const mutation = useUpdateList<CustomVariableValue>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateCustomVariableValues: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteCustomVariableValues({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<CustomVariableValue>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteCustomVariableValues: mutation.mutateAsync,
    ...mutation,
  }
}
