import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { ReactNode } from 'react'
import { Card, Container, Typography, Alert, AlertTitle } from '@mui/material'

function ErrorScreen({ error }: { error: Error }) {
  return (
    <Container maxWidth="sm">
      <Card sx={{ my: 2 }}>
        <Alert severity="error" variant="outlined">
          <AlertTitle>Something went wrong.</AlertTitle>
          Please try again later.
          {import.meta.env.VITE_ENVIRONMENT === 'dev' && (
            <>
              <Typography
                component="div"
                variant="body2"
                color="error"
                sx={{ overflow: 'auto' }}
              >
                <code>
                  <pre>{error.stack}</pre>
                </code>
              </Typography>

              <Typography variant="caption" color="text.secondary">
                Note: Error information is only shown in dev environment.
              </Typography>
            </>
          )}
        </Alert>
      </Card>
    </Container>
  )
}

export default function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorScreen}>
      {children}
    </ReactErrorBoundary>
  )
}
