import { OrgCredentialType } from '@/types/credentials'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<OrgCredentialType>({
  pathCategory: 'org_credential_types',
})

export function useGetOrgCredentialType({
  id,
  ...options
}: UseGetDetailOptions<OrgCredentialType>) {
  const query = useGetDetail<OrgCredentialType>({
    id,
    queryKey: queryKeys.orgCredentialTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    orgCredentialType: query.data,
    ...query,
  }
}

export function useGetOrgCredentialTypes({
  filters,
  ...options
}: UseGetListOptions<OrgCredentialType> = {}) {
  const query = useGetList<OrgCredentialType>({
    filters,
    queryKey: queryKeys.orgCredentialTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    orgCredentialTypes: query.allData,
    ...query,
  }
}
