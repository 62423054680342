import { AxiosResponse } from 'axios'

export default (
  deleteFn: (items: string[]) => Promise<AxiosResponse<void>>,
  ids: string[],
) => {
  const groups: string[][] = []

  for (let i = 0; i < ids.length; i += 200) {
    groups.push(ids.slice(i, i + 200))
  }
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    Promise.allSettled(groups.map((group) => deleteFn(group))).then(
      (responses) => {
        if (responses.find(({ status }) => status === 'rejected')) {
          reject('Some items failed to delete')
        } else {
          resolve(
            (responses[0] as PromiseFulfilledResult<AxiosResponse<void>>).value,
          )
        }
      },
    )
  })
}
