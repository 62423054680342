import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { Typography, Button } from '@mui/material'
import { Project } from '@/types/projects'
import { useNavigate } from 'react-router-dom'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { useDeleteProject } from '@/service-library/hooks/projects'
import queryKeys from '@/service-library/query-keys'

type CancelSetupDialogProps = {
  overlay: OverlayState
  project: Project
}

export default function CancelSetupDialog({
  overlay,
  project,
}: CancelSetupDialogProps) {
  const navigate = useNavigate()
  const { deleteProject } = useDeleteProject({
    sideEffectQueryKeys: [queryKeys.projects.lists()],
    onSuccess: () => {
      showSuccessSnackbar('Project Deleted')
    },
    onError: () => {
      showErrorSnackbar('Error Deleting Project. Please try again later.')
    },
  })

  function handleDeleteProject() {
    project && deleteProject(project.id)
    navigate('/projects')
  }

  return (
    <Dialog title="Cancel Project Setup" {...overlay}>
      <DialogContent>
        <Typography>
          This will cancel the setup process for this project and delete it. You
          will lose your progress. Are you sure?
        </Typography>
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Nevermind
        </Button>
        <Button variant="contained" color="error" onClick={handleDeleteProject}>
          Yes, I&apos;m Sure
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
