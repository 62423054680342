import { Project } from '@/types/projects'
import {
  Card,
  CardActionArea,
  Stack,
  Typography,
  Button,
  useTheme,
  Box,
  Tooltip,
  CardActionAreaProps,
  BoxProps,
  Chip,
  ChipProps,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ProjectCardDropZone from '@/components/drop-zone/ProjectCardDropZone'
import { themeColorOptions, themeColors } from '@/theme/usePixydocsTheme'
import { useGetWorkflows } from '@/service-library/hooks/workflows'

export type ProjectCardProps = {
  project: Project
}

export default function ProjectCard({ project }: ProjectCardProps) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { id: projectId, name, setup_state, basic_states_stats } = project
  const { workflows, isLoading } = useGetWorkflows({
    filters: {
      project_id: projectId,
      code: 'basic',
    },
  })

  const isSetupComplete = setup_state === 'complete'

  const Wrapper: React.FC<CardActionAreaProps & BoxProps> = isSetupComplete
    ? CardActionArea
    : Box

  const wrapperProps = isSetupComplete
    ? {
        onClick: () => {
          navigate(projectId)
        },
      }
    : {}

  return (
    <ProjectCardDropZone project={project}>
      <Card sx={{ height: '100%' }}>
        <Wrapper {...wrapperProps} sx={{ height: '100%' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 2 }}
            spacing={2}
          >
            <Stack>
              <Typography variant="subtitle1">{name}</Typography>
              {setup_state !== 'complete' && (
                <Typography
                  variant="caption"
                  color={theme.palette.text.secondary}
                >
                  This project has not been fully configured. Complete the
                  project setup to continue.
                </Typography>
              )}
            </Stack>

            {setup_state === 'complete' && (
              <Stack
                direction="row"
                columnGap={0.75}
                rowGap={1}
                flexWrap="wrap"
                justifyContent="flex-end"
              >
                {basic_states_stats?.map(({ id, code, color, count, name }) => {
                  if (code === 'processing') return
                  // Have to check that the color exists to prevent an error
                  const chipColor =
                    color &&
                    [...themeColorOptions, ...themeColors].includes(color)
                      ? color
                      : 'default'
                  return (
                    <Tooltip key={id} title={name} enterDelay={500}>
                      <Chip
                        disabled={isLoading}
                        label={count}
                        component={Link}
                        onClick={(e) => e.stopPropagation()}
                        to={`${projectId}/documents?workflow=${workflows[0]?.id}&workflow_state=${id}`}
                        size="small"
                        sx={{
                          pointerEvents: 'initial',
                          borderWidth: '2px',
                        }}
                        color={chipColor as ChipProps['color']}
                        variant="outlined"
                      />
                    </Tooltip>
                  )
                })}
              </Stack>
            )}

            {setup_state !== 'complete' && (
              <>
                <Stack spacing={2}>
                  <Button
                    variant="text"
                    component={Link}
                    to={`/project-setup/${setup_state}?project_id=${projectId}`}
                  >
                    Complete Setup
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Wrapper>
      </Card>
    </ProjectCardDropZone>
  )
}
