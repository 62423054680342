import { useState, useRef, useEffect } from 'react'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import { MoreVert, Subject, SwapHoriz } from '@mui/icons-material'
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { DocumentRow } from '@/types/documents'
import { ProjectGridField as TSField } from '@/types/fields'
import useOverlay from '@/hooks/useOverlay'
import { useGetDocument } from '@/service-library/hooks/documents'
import DocumentActionsMenu from '@/components/document-actions-menu/DocumentActionsMenu'
import DocumentDialog from '@/components/document-dialog/DocumentDialog'
import DocumentImagePageProvider from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import ImageZoomPan from '@/components/image-zoom-pan/ImageZoomPan'
import ImageZoomPanControls from '@/components/image-zoom-pan/ImageZoomPanControls'
import ImageZoomPanCenterProvider from '@/components/image-zoom-pan/providers/ImageZoomPanCenterProvider'
import NavigationPanel from '@/components/image-zoom-pan/NavigationPanel'
import ValidationOptionsProvider from '@/components/image-zoom-pan/providers/ValidationOptionsProvider'
import { useDocumentComparisonDataContext } from '@/components/image-zoom-pan/providers/DocumentComparisonDataProvider'
import SizeProvider from '@/components/size-provider/SizeProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import DocumentRowProvider from './providers/DocumentRowProvider'
import FieldsComparison from './FieldsComparison'
import DocumentComparisonTextField from './DocumentComparisonTextField'

type ComparisonViewDocumentProps = {
  documentQuery: ReturnType<typeof useGetDocument>
  showDocumentName: boolean
  flatFields?: TSField[]
  groupedFields?: TSField[][]
  isMainDocument?: boolean
  mainProjectGridId?: string
  mode?: 'image' | 'all_fields' | 'relevant_fields'
}

export default function ComparisonViewDocument({
  documentQuery,
  showDocumentName,
  flatFields = [],
  groupedFields = [],
  isMainDocument = false,
  mainProjectGridId,
  mode = 'image',
}: ComparisonViewDocumentProps) {
  const queryClient = useQueryClient()
  const { document, queryKey } = documentQuery
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { getAllWorkflowStates } = useWorkflowsContext()
  const { setDocumentIdToCompare } = useDocumentComparisonDataContext()
  const actionsMenuOverlay = useOverlay()
  const swapDocumentOverlay = useOverlay()
  const documentOverlay = useOverlay()

  const transformerRef = useRef<ReactZoomPanPinchRef>(null)

  const [drawBoxes, setDrawBoxes] = useState(true)

  const [rotationDegree, setRotationDegree] = useState(0)

  const workflowStates = selectedWorkflow?.workflow_states || []

  const stateInCurrentWorkflow = workflowStates.find((state) =>
    document?.workflow_states_ids?.some((id) => id === state.id),
  )

  // For the secondary document, we first check for the document in the current workflow, then in all workflows
  const workflowState = isMainDocument
    ? stateInCurrentWorkflow
    : stateInCurrentWorkflow ||
      getAllWorkflowStates().find((state) =>
        document?.workflow_states_ids?.some((id) => id === state.id),
      )

  const mainDocumentRow = document?.document_rows?.find(
    ({ project_grid_id }) => project_grid_id === mainProjectGridId,
  ) as DocumentRow

  const rotateImage = () => {
    setRotationDegree((prev) => (prev + 90 === 360 ? 0 : prev + 90))
  }

  useEffect(() => {
    setRotationDegree(0)
    // document.id isn't used in this useEffect, but we want it to fire whenever document.id changes
  }, [document?.id])

  if (!document) return null

  return (
    <ValidationOptionsProvider
      document={documentQuery.document}
      localStorageKeyPrefix={
        isMainDocument ? 'comparison-left' : 'comparison-right'
      }
    >
      <DocumentImagePageProvider document={documentQuery.document}>
        <DocumentRowProvider documentRow={mainDocumentRow}>
          <ImageZoomPanCenterProvider>
            <Stack sx={{ flexGrow: 1, minWidth: 0 }}>
              {showDocumentName && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    flexShrink: 0,
                    minHeight: 0,
                    height: '45px',
                    p: 1,
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                    position: 'sticky',
                    top: 0,
                    backgroundColor: (theme) => theme.palette.background.paper,
                    zIndex: 10000,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      minWidth: 0,
                    }}
                  >
                    <Typography variant="body1" component="h2" noWrap>
                      {document.name}
                    </Typography>
                    {!isMainDocument && (
                      <>
                        <IconButton
                          onClick={swapDocumentOverlay.open}
                          size="small"
                        >
                          <SwapHoriz />
                        </IconButton>
                        <Popover
                          anchorEl={swapDocumentOverlay.anchorEl as Element}
                          open={swapDocumentOverlay.isOpen}
                          onClose={swapDocumentOverlay.close}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          slotProps={{
                            paper: {
                              sx: {
                                width: '350px',
                                p: 1,
                              },
                            },
                          }}
                        >
                          <DocumentComparisonTextField
                            onSuccess={swapDocumentOverlay.close}
                          />
                        </Popover>
                      </>
                    )}
                  </Stack>
                  {!isMainDocument && (
                    <>
                      <IconButton
                        onClick={actionsMenuOverlay.open}
                        size="small"
                      >
                        <MoreVert />
                      </IconButton>

                      <DocumentActionsMenu
                        basicOptions
                        projectId={document.project_id}
                        documentId={document.id}
                        overlay={actionsMenuOverlay}
                        onDelete={() => {
                          // Reset the document to compare when the document is deleted
                          setDocumentIdToCompare('')
                          queryClient.invalidateQueries({ queryKey })
                          actionsMenuOverlay.close()
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            documentOverlay.open()
                            actionsMenuOverlay.close()
                          }}
                        >
                          <ListItemIcon>
                            <Subject />
                          </ListItemIcon>
                          <ListItemText>Details</ListItemText>
                        </MenuItem>
                      </DocumentActionsMenu>

                      <DocumentDialog
                        overlay={documentOverlay}
                        documentId={document.id}
                        projectId={document.project_id}
                        document={document}
                        detailQueryKey={queryKey}
                        onDelete={() => {
                          // Reset the document to compare when the document is deleted
                          setDocumentIdToCompare('')
                          queryClient.invalidateQueries({ queryKey })
                          documentOverlay.close()
                        }}
                      />
                    </>
                  )}
                </Stack>
              )}
              <Stack
                sx={{
                  position: 'relative',
                  minHeight: 0,
                  minWidth: 0,
                  flexGrow: 1,
                }}
              >
                {!workflowState ||
                workflowState?.code === 'processing' ? null : mode ===
                  'image' ? (
                  <>
                    <SizeProvider>
                      <ImageZoomPan
                        key={document.id} // Force remount when document changes so the image will properly update and state will reset
                        drawBoxes={drawBoxes}
                        rotationDegree={rotationDegree}
                        transformerRef={transformerRef}
                        layout="top"
                        fields={flatFields}
                      />
                    </SizeProvider>
                    <NavigationPanel
                      controls={[
                        <ImageZoomPanControls
                          documentView="comparison"
                          key="zoom-pan-controls"
                          hidePaginationControls={
                            !document?.document_pages.length ||
                            document?.document_pages.length <= 1
                          }
                          layout="top"
                          transformerRef={transformerRef}
                          rotateImage={rotateImage}
                          setDrawBoxes={setDrawBoxes}
                        />,
                      ]}
                      layout="top"
                    />
                  </>
                ) : (
                  <FieldsComparison
                    groupedFields={groupedFields}
                    isMainDocument={isMainDocument}
                  />
                )}
              </Stack>
            </Stack>
          </ImageZoomPanCenterProvider>
        </DocumentRowProvider>
      </DocumentImagePageProvider>
    </ValidationOptionsProvider>
  )
}
