import { Tooltip } from '@mui/material'

type PermissionTooltipProps = {
  canEdit: boolean
  children: JSX.Element
  shortVersion?: boolean
}
export default function PermissionTooltip({
  canEdit,
  children,
  shortVersion,
}: PermissionTooltipProps) {
  const tooltipText = shortVersion
    ? 'Insufficient Permission'
    : 'You do not have the necessary permissions to perform this action.'
  return (
    <Tooltip
      enterDelay={1000}
      placement="bottom-start"
      title={canEdit ? '' : tooltipText}
    >
      {/* Added div so tooltip shows for disabled buttons */}
      <div style={{ width: 'fit-content' }}>{children}</div>
    </Tooltip>
  )
}
