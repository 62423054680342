import { useEffect, useRef, useState } from 'react'
import throttle from 'lodash.throttle'
import { MRT_ColumnDef } from 'material-react-table'
import { TaskAlt } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { Document } from '@/types/documents'
import useFetchMoreOnBottomReached from '@/hooks/useFetchMoreOnBottomReached'
import useOverlay from '@/hooks/useOverlay'
import { useGetDocumentWorkflowStates } from '@/service-library/hooks/document-workflow-states'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'

type InFlightDocumentsButtonProps = {
  count: number
  stateId: string
  openValidationOverlay: (documentId: string) => void
}

type InFlightDocument = Partial<Document> & {
  entered_at: string
  id: string
}

const columns: MRT_ColumnDef<InFlightDocument>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },

  {
    header: 'Workflow State Enter Date',
    accessorFn: (row) => prettifyDate(row.entered_at as string),
  },
]

export default function InFlightDocumentsButton({
  count,
  stateId,
  openValidationOverlay,
}: InFlightDocumentsButtonProps) {
  const documentsOverlay = useOverlay()
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const dialogContentOldHeightRef = useRef<number>()
  const [dialogContentHeight, setDialogContentHeight] = useState<number>()
  const [tableHeight, setTableHeight] = useState<number>()

  const {
    documentWorkflowStates = [],
    isLoading,
    isFetching,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useGetDocumentWorkflowStates({
    filters: {
      limit: '100',
      workflow_state_id: stateId,
      fields__only: 'document,entered_at',
      document__fields__only: 'id,name',
      ordering: '-entered_at',
      processing_started_at__isnull: 'false',
    },
    enabled: documentsOverlay.isOpen, // Only fetch the documents once the overlay is opened
  })

  const throttledRefetch = throttle(refetch, 1000)

  const documents = [
    ...documentWorkflowStates.map(({ document, entered_at }) => ({
      ...document,
      id: document?.id as string,
      entered_at,
    })),
  ]

  const { fetchMoreOnBottomReached } = useFetchMoreOnBottomReached({
    isFetching,
    hasNextPage,
    tableContainerRef,
    fetchNextPage,
    offset: 500,
  })

  useEffect(() => {
    if (dialogContentHeight) {
      if (
        !tableHeight ||
        dialogContentOldHeightRef.current !== dialogContentHeight
      ) {
        setTableHeight(dialogContentHeight - 80)
      }
      dialogContentOldHeightRef.current = dialogContentHeight
    }
  }, [dialogContentHeight, tableHeight])

  useNotifications({
    keys: [stateId],
    callback: ({ action }) => {
      if (
        documentsOverlay.isOpen &&
        [
          'pdWorkflow/events.document_active',
          'pdWorkflow/events.document_idle',
        ].includes(action)
      ) {
        throttledRefetch()
      }
    },
  })

  const table = useZerapixTable<InFlightDocument>({
    data: documents,
    columns,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 60,
      },
    },
    enableColumnResizing: true,
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableRowActions: true,
    getRowId: (row) => row.id,
    positionActionsColumn: 'first',
    renderRowActions: ({ row }) => (
      <CopyIDButton asIconButton stringToCopy={row.id} size="small" />
    ),
    state: {
      showSkeletons: isLoading,
      showProgressBars: isFetching && !isRefetching,
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: {
        height: tableHeight,
      },
      onScroll: (
        event, //add an event listener to the table container element
      ) => {
        fetchMoreOnBottomReached(event.target as HTMLDivElement)
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: isLoading ? 'default' : 'pointer',
      },
      onClick: () => {
        !isLoading && openValidationOverlay(row.id)
      },
    }),
  })

  return (
    <>
      <Button disabled={!count} onClick={documentsOverlay.open} variant="text">
        {count} in Flight
      </Button>
      <Dialog
        title={`In-Flight Documents (${count || documents.length})`}
        {...documentsOverlay}
        maxWidth="md"
      >
        <DialogContent setClientHeight={setDialogContentHeight}>
          {!isLoading && documents.length === 0 ? (
            <Stack spacing={2} sx={{ my: 2 }} alignItems="center">
              <TaskAlt sx={{ fontSize: 120 }} color="success" />
              <Typography>No Documents in Flight</Typography>
            </Stack>
          ) : (
            <ZerapixTable<InFlightDocument> table={table} />
          )}
        </DialogContent>

        <DialogFooter>
          <Button
            variant="text"
            onClick={() => {
              documentsOverlay.close()
            }}
          >
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}
