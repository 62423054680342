import {
  DataListColumn,
  DataListEntry,
  DataListEntryCellValue,
} from '@/types/data-lists'

export type CellData = {
  values: DataListEntryCellValue[]
  dataEntryId: string
  entryCellId: string | null
  entryCellValueId: string | null
  cellDataType: string
}

export type RowData = Record<string, string | CellData>

export default function constructTableRows(
  dataListEntries: DataListEntry[],
  dataListColumns: DataListColumn[],
): RowData[] {
  // For every row...
  return dataListEntries.map(
    ({ id: dataEntryId, data_list_entry_cells = [] }) => {
      // For each column, create an empty cell
      const defaults = dataListColumns.reduce<
        Record<string, string | CellData>
      >(
        (acc, { id, data_list_column_type }) => {
          acc[id] = {
            values: [],
            dataEntryId,
            entryCellId: null,
            entryCellValueId: null,
            cellDataType: data_list_column_type.postgres_data_type,
          }
          return acc
        },
        {
          dataEntryId,
        },
      )

      // And every cell...
      return data_list_entry_cells.reduce(
        (acc, { id, data_list_column_id, data_list_entry_cell_values }) => {
          // [sc-10439] To prevent errors when more than one cell in the same row has the same column id
          if (Object.hasOwn(acc, data_list_column_id)) {
            const rowData = acc[data_list_column_id]
            if (typeof rowData !== 'string' && rowData.values.length) {
              return acc
            }
          }

          acc[data_list_column_id] = {
            ...(defaults[id] as CellData),
            values: data_list_entry_cell_values,
            dataEntryId,
            entryCellId: id,
            entryCellValueId: data_list_entry_cell_values?.[0]?.id,
          }
          return acc
        },
        defaults,
      )
    },
  )
}
