import { ReactNode, createContext, useContext } from 'react'
import { NodeData } from './types'

const NodeDataContext = createContext<NodeData>({})

export const useNodeData = () => useContext(NodeDataContext)

type NodeDataProviderProps = {
  data: NodeData
  children: ReactNode
}

export default function NodeDataProvider({
  children,
  data,
}: NodeDataProviderProps) {
  return (
    <NodeDataContext.Provider value={data}>{children}</NodeDataContext.Provider>
  )
}
