import { IconButton, Menu, MenuItem, Button, Typography } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import useOverlay from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type WorkflowActionMenuProps = {
  onRemoveFromWorkflow: () => void
  onDeleteConfirm: () => void
  lastWorkflowState?: boolean
  multipleDocuments?: boolean
}

export default function WorkflowActionsMenu({
  onRemoveFromWorkflow,
  onDeleteConfirm,
  lastWorkflowState = false,
  multipleDocuments = false,
}: WorkflowActionMenuProps) {
  const overlay = useOverlay()
  const deleteOverlay = useOverlay()

  function handleRemoveFromWorkflow() {
    if (lastWorkflowState) {
      deleteOverlay.open()
    } else {
      overlay.close()
      onRemoveFromWorkflow()
    }
  }

  return (
    <>
      <IconButton size="small" onClick={overlay.open}>
        <MoreVert />
      </IconButton>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={overlay.anchorEl as Element}
      >
        <MenuItem onClick={handleRemoveFromWorkflow}>
          Remove From Workflow
        </MenuItem>
      </Menu>

      <Dialog
        title={multipleDocuments ? 'Delete Documents' : 'Delete Document'}
        {...deleteOverlay}
      >
        <DialogContent>
          <Typography>
            {multipleDocuments
              ? 'Removing these documents from the only workflow they are in will delete the documents permanently.'
              : 'Removing this document from the only workflow it is in will delete the document permanently.'}
          </Typography>

          <Typography sx={{ pt: 2 }}>
            Are you sure you wish to continue?
          </Typography>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={deleteOverlay.close}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDeleteConfirm()
              overlay.close()
              deleteOverlay.close()
            }}
            color="error"
          >
            Yes, Continue
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}
