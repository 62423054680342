import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Stack } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { OrgType } from '@/types/organizations'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useCreateOrgType,
  useUpdateOrgType,
} from '@/service-library/hooks/org-types'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'

type AddEditOrgTypeDialogProps = {
  overlay: OverlayState
  listQueryKey: QueryKey
  organizationType?: OrgType
}

type FormValues = {
  name: string
  code?: string
}

export default function AddEditOrgTypeDialog({
  overlay,
  listQueryKey,
  organizationType,
}: AddEditOrgTypeDialogProps) {
  const { rootOrganization } = useRootOrganization()
  const { createOrgType } = useCreateOrgType({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to create type. Please try again later.')
    },
  })
  const { updateOrgType } = useUpdateOrgType({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update type. Please try again later.')
    },
  })

  const defaultValues = useMemo(
    () => ({
      name: organizationType?.name || '',
      code: organizationType?.code || '',
    }),
    [organizationType],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const {
    formState: { isDirty, isValid },
    register,
    reset,
  } = methods

  function handleSubmit(values: FormValues) {
    // Switch to snake case, replace any special characters, replace any multiple underscores with a single underscore
    const code =
      values.code ||
      values.name
        .toLowerCase()
        .replace(/\s/g, '_')
        .replace(/[^a-z0-9_]/g, '')
        .replace(/_+/g, '_')
    if (
      organizationType &&
      (organizationType.name !== values.name ||
        organizationType.code !== values.code)
    ) {
      updateOrgType({ ...organizationType, name: values.name, code })
    } else if (!organizationType) {
      createOrgType({
        id: generateUuid(),
        name: values.name,
        code,
        owner_org_id: rootOrganization.id,
      })
    }
    overlay.close()
  }

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  return (
    <Dialog
      title={`${organizationType?.name ? 'Edit' : 'New'} Type`}
      {...overlay}
      maxWidth="xs"
    >
      <PixyDocsForm methods={methods} onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={4}>
            <FormTextField
              autoFocus
              fullWidth
              label="Name"
              required
              {...register('name', {
                validate: (value) => value.trim().length > 0,
              })}
            />
            <FormTextField
              fullWidth
              label="Code"
              name="code"
              helperText="This code is used by custom python scripts and integrations. If you are not sure what to put here, we recommend it be the same as the type name."
              required={false}
            />
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
