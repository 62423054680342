import { UseMutationOptions, useQueryClient } from '@tanstack/react-query'
import useMutation from './useMutation'
import { LogixBoard, LogixNode } from '@/types/logix'
import { createLogixNode } from '../logix'
import { getQueryKey } from './useLogixBoard'

type UseCreateLogixNodeOptions = UseMutationOptions<
  LogixNode,
  unknown,
  LogixNode,
  { previous: LogixBoard }
>

export default function useCreateLogixNode(
  options: UseCreateLogixNodeOptions = {},
) {
  const queryClient = useQueryClient()

  const query = useMutation<
    LogixNode,
    unknown,
    LogixNode,
    { previous: LogixBoard }
  >({
    sideEffectQueryKeys: [['logix-board']],
    mutationFn: (newLogixNode: LogixNode) =>
      createLogixNode(newLogixNode).then((res) => res.data),
    onMutate: async (newNode) => {
      const queryKey = getQueryKey(newNode.board_id)

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData(queryKey) as LogixBoard
      queryClient.setQueryData(queryKey, (currentBoard?: LogixBoard) => {
        if (!currentBoard) return
        return {
          ...currentBoard,
          nodes: [...currentBoard.nodes, newNode],
        } as LogixBoard
      })

      return { previous }
    },
    onError: (err, data, context) => {
      const queryKey = getQueryKey(data.board_id)
      queryClient.setQueryData(queryKey, context?.previous)
    },
    onSettled: (data) => {
      const queryKey = getQueryKey(data?.board_id || '')
      queryClient.invalidateQueries({ queryKey })
    },
    ...options,
  })

  return { ...query, createLogixNode: query.mutate }
}
