import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Add } from '@mui/icons-material'
import { Typography, Card, Skeleton, Stack, Button } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { OrgType, Organization } from '@/types/organizations'
import { Workflow, WorkflowState } from '@/types/workflows'
import { Document } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useRemoveDocumentsFromWorkflow,
  useSetDocumentsWorkflowState,
} from '@/service-library/hooks/document-workflow-states'
import {
  useArchiveDocuments,
  useUpdateDocuments,
} from '@/service-library/hooks/documents'
import queryKeys from '@/service-library/query-keys'
import useDocumentSet from '@/services/hooks/useDocumentSet'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import DocumentWorkflowsTable from '@/components/document-workflows-table/DocumentWorkflowsTable'
import OrganizationAssignmentSelect from '@/components/organization-select/OrganizationAssignmentSelect'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'

const multipleOrgFiller: Organization = {
  id: 'multiple',
  name: (
    <Typography variant="body2">
      <i>Multiple</i>
    </Typography>
  ) as unknown as string,
  color: 'stormcloud',
  code: 'multiple',
  parent_org_id: 'multiple',
  org_type_id: '',
  org_type: {} as OrgType,
}

const multipleStateFiller: WorkflowState = {
  id: 'multiple',
  name: (
    <Typography variant="body2">
      <i>Multiple</i>
    </Typography>
  ) as unknown as string,
  color: 'stormcloud',
  code: 'multiple',
  workflow_id: 'multiple',
  sort_order: 0,
  default_document_view: 'validation',
  allowed_document_views: ['validation'],
}

const noStateFiller: WorkflowState = {
  id: 'none',
  name: (
    <Stack spacing={1} direction="row" alignItems="center">
      <Add fontSize="small" />
      <Typography variant="body2">Add Document to Workflow</Typography>
    </Stack>
  ) as unknown as string,
  color: 'stormcloud',
  code: 'multiple',
  workflow_id: 'multiple',
  sort_order: 0,
  default_document_view: 'validation',
  allowed_document_views: ['validation'],
}

type BulkDocumentDialogProps = {
  overlay: OverlayState
  documentIds: string[]
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
}

export default function BulkDocumentDialog({
  overlay,
  documentIds,
  setSelectedRows,
}: BulkDocumentDialogProps) {
  const queryClient = useQueryClient()
  const { workflows, selectedWorkflow } = useSelectedWorkflowContext()

  const [internalDocumentIds, setInternalDocumentIds] =
    useState<string[]>(documentIds)
  const [currentWorkflowStateMap, setCurrentWorkflowStateMap] = useState<
    Record<string, WorkflowState>
  >({})
  const overlayIsOpen = useRef(overlay.isOpen)

  const {
    documents = [],
    isError,
    isLoading,
    queryKey,
  } = useDocumentSet({
    documentIds: internalDocumentIds,
    batchLimit: 100, //Reducing the limit since the calls take longer when we include rows [sc-10907]
    includeWorkflowStates: true,
    enabled: overlay.isOpen,
  })

  const { updateDocuments } = useUpdateDocuments({
    sideEffectQueryKeys: [
      queryKeys.documents.lists(),
      queryKeys.documentRows.lists(),
      queryKey, // TODO: update this when we update useDocumentSet
    ],
    onMutate: async (updatedDocuments) => {
      await queryClient.cancelQueries({ queryKey })
      queryClient.setQueryData<Document[]>(queryKey, updatedDocuments)
    },
    onIdle: () => {
      showSuccessSnackbar('Documents Updated')
    },
    onError: () => {
      showErrorSnackbar('Failed to update documents. Please try again later.')
    },
  })

  const { setWorkflowState } = useSetDocumentsWorkflowState({
    onMutate: ({ patch, filter_criteria }) => {
      setCurrentWorkflowStateMap((prev) => {
        const newWorkflowState = workflows
          .find(({ id }) => id === filter_criteria.workflow_state__workflow_id)
          ?.workflow_states?.find(
            (state) => state.id === patch.workflow_state__id,
          ) as WorkflowState
        return {
          ...prev,
          [filter_criteria.workflow_state__workflow_id]: newWorkflowState,
        }
      })
    },
    onSuccess: (_data, { filter_criteria }) => {
      showSuccessSnackbar('Documents Workflow State Updated')
      if (filter_criteria.workflow_state__workflow_id === selectedWorkflow.id)
        setSelectedRows({})
    },
    onError: () => {
      showErrorSnackbar('Failed to update documents. Please try again later.')
    },
  })

  const { removeFromWorkflow } = useRemoveDocumentsFromWorkflow({
    onMutate: ({ filter_criteria }) => {
      setCurrentWorkflowStateMap((prev) => {
        return {
          ...prev,
          [filter_criteria.workflow_state__workflow_id]: noStateFiller,
        }
      })
    },
    onSuccess: (_data, { filter_criteria }) => {
      showSuccessSnackbar('Documents removed from workflow')
      if (filter_criteria.workflow_state__workflow_id === selectedWorkflow.id)
        setSelectedRows({})
    },
    onError: () => {
      showErrorSnackbar(
        'Failed to remove from workflow. Please try again later.',
      )
    },
  })

  const { archiveDocuments } = useArchiveDocuments({
    sideEffectQueryKeys: [
      queryKeys.documents.lists(),
      queryKeys.documentRows.lists(),
      queryKeys.documentWorkflowStates.details(), // To invalidate counts
    ],
    onSuccess: () => {
      showSuccessSnackbar('Documents Deleted')
    },
    onError: () => {
      showErrorSnackbar('Failed to delete documents. Please try again later.')
    },
  })

  const { organizations, isFetchingAll } = useOrganizationsContext()

  const inMultipleOrganizations = !documents?.every(
    ({ owner_org_id }) => owner_org_id === documents[0].owner_org_id,
  )

  const selectedOrganization = inMultipleOrganizations
    ? multipleOrgFiller
    : (organizations.find(
        ({ id }) => id === documents[0]?.owner_org_id,
      ) as Organization)

  function handleBulkUpdateOrganization(newOrganization: Organization) {
    if (newOrganization.id === selectedOrganization.id) return
    updateDocuments(
      documents.map((document) => {
        return {
          ...document,
          owner_org_id: newOrganization.id,
        }
      }),
    )
  }

  function handleBulkUpdateWorkflowState(
    workflow: Workflow,
    workflowState: WorkflowState,
  ) {
    if (workflowState.id === currentWorkflowStateMap[workflow.id].id) return
    setWorkflowState({
      patch: {
        workflow_state__id: workflowState.id,
        workflow_state__code: workflowState.code,
      },
      filter_criteria: {
        document_id__in: internalDocumentIds,
        workflow_state__workflow_id: workflow.id,
      },
      current_workflow_state_id: currentWorkflowStateMap[workflow.id].id,
    })
  }

  function handleBulkRemoveFromWorkflow(workflow: Workflow) {
    removeFromWorkflow({
      filter_criteria: {
        document_id__in: internalDocumentIds,
        workflow_state__workflow_id: workflow.id,
      },
    })
  }

  function handleBulkDeleteDocuments() {
    archiveDocuments(internalDocumentIds)
    overlay.close()
  }

  useEffect(() => {
    if (documents.length === 0) return
    setCurrentWorkflowStateMap(() => {
      return workflows.reduce<Record<string, WorkflowState>>(
        (acc, workflow) => {
          const firstDocumentWorkflowState = workflow.workflow_states?.find(
            (state) => documents[0]?.workflow_states_ids?.includes(state.id),
          )

          // Check if all documents have the same workflow state - if they all match
          // the first document's workflow state for this workflow, then they are all the same
          const allDocumentsMatch = firstDocumentWorkflowState
            ? documents.every(({ workflow_states_ids }) =>
                workflow_states_ids?.includes(firstDocumentWorkflowState?.id),
              )
            : false

          if (!allDocumentsMatch && firstDocumentWorkflowState) {
            acc[workflow.id] = multipleStateFiller
          } else if (firstDocumentWorkflowState) {
            acc[workflow.id] = firstDocumentWorkflowState
          } else if (!firstDocumentWorkflowState) {
            acc[workflow.id] = noStateFiller
          }
          return acc
        },
        {},
      )
    })
  }, [documents, workflows])

  useEffect(() => {
    // This is to avoid internalDocumentIds to get updated when
    //  documentIds changes but dialog was already open
    if (overlay.isOpen && overlay.isOpen !== overlayIsOpen.current) {
      setInternalDocumentIds(documentIds)
    }
    overlayIsOpen.current = overlay.isOpen
  }, [documentIds, overlay.isOpen])

  useEffect(() => {
    if (isError && overlay.isOpen) {
      overlay.close()
      showErrorSnackbar('There was an error. Please contact support.')
    }
  }, [isError, overlay])

  return (
    <Dialog
      title={`Edit Documents (${internalDocumentIds.length})`}
      {...overlay}
    >
      <DialogContent>
        <Stack spacing={3}>
          {/* isError: We will close the dialog if call fails, so it's better to not try to render the other components. */}
          {isLoading || isError || (!selectedOrganization && isFetchingAll) ? (
            <Stack spacing={2}>
              <Skeleton width={300} />
              <Skeleton width={250} />
              <Skeleton width={275} />
            </Stack>
          ) : (
            <Card sx={{ flexGrow: 1 }}>
              <OrganizationAssignmentSelect
                organizations={organizations}
                isFetching={isFetchingAll}
                selectedOrganization={selectedOrganization}
                onChange={handleBulkUpdateOrganization}
                isBulkChange
              />
            </Card>
          )}

          {/* Workflows and workflow states */}
          <DocumentWorkflowsTable
            workflows={workflows}
            currentWorkflowStateMap={currentWorkflowStateMap}
            onWorkflowStateChange={handleBulkUpdateWorkflowState}
            onRemoveFromWorkflow={handleBulkRemoveFromWorkflow}
            onDeleteConfirm={handleBulkDeleteDocuments}
            multipleDocuments
          />
        </Stack>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
