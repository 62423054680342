import { ReactNode, forwardRef } from 'react'
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlProps,
} from '@mui/material'
import { FieldValues, useController, FieldPath } from 'react-hook-form'

type FormCheckboxProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label?: ReactNode
  formLabelSx?: FormControlProps['sx']
} & CheckboxProps

const FormCheckbox = forwardRef(function FormTextField<
  FormValues extends FieldValues,
>(
  { name, label, formLabelSx, ...props }: FormCheckboxProps<FormValues>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { field } = useController<FormValues>({ name })

  return (
    <FormControlLabel
      control={
        <Checkbox {...props} {...field} checked={field.value} ref={ref} />
      }
      label={label}
      sx={formLabelSx}
    />
  )
})

export default FormCheckbox
