import { ReactNode, DragEvent, createContext, useContext } from 'react'
import { Stack, Backdrop, useTheme } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'

const DropOverlayContext = createContext<ReturnType<typeof useOverlay> | null>(
  null,
)

type DropZoneProps = {
  // The children that the drop zone covers
  children: ReactNode

  // Children displayed above the scrim when dragging
  overlayChildren: ReactNode

  onDrop: (files: FileList) => void

  isFullscreen?: boolean
}

export default function DropZone({
  children,
  overlayChildren,
  onDrop,
  isFullscreen,
}: DropZoneProps) {
  const dropOverlay = useOverlay()
  const theme = useTheme()

  const parentOverlay = useContext(DropOverlayContext)

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    if (e.dataTransfer.types.includes('undraggable')) return
    e.preventDefault() // Prevent browser from preparing to open the file
    dropOverlay.open()
  }

  function handleDragLeave() {
    dropOverlay.close()
  }

  function handleDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault() // Prevent browser from trying to open the file
    e.stopPropagation() // Prevent any drop zones underneath this one from receiving the event
    onDrop(e.dataTransfer.files)
    dropOverlay.close()

    // If we're in a sub-dropzone, also close the parent (who doesn't get the event since we stopped propagation)
    if (parentOverlay) {
      // eslint-disable-next-line react/destructuring-assignment
      parentOverlay.close()
    }
  }

  const isFullscreenStyle = {
    bottom: 0,
    height: '100vh',
    left: 0,
    margin: 0,
    maxHeight: '100vh',
    maxWidth: '100vw',
    padding: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100vw',
  } // matching table fullscreen https://www.material-react-table.com/docs/guides/full-screen-toggle#change-z-index-of-full-screen-table

  return (
    <DropOverlayContext.Provider value={dropOverlay}>
      <Stack
        data-testid="dropzone"
        onDragStart={(e) => {
          // This is to prevent dragging table columns from triggering drop zone
          // https://stackoverflow.com/questions/11065803/determine-what-is-being-dragged-from-dragenter-dragover-events/11125533
          if ((e?.target as HTMLButtonElement)?.id === 'table-handle') {
            e.dataTransfer.setData('undraggable', '')
          }
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          position: 'relative',
          flexGrow: 1,
          minHeight: 0,
          alignSelf: 'stretch',
          height: '100%',
        }}
      >
        <Backdrop
          sx={{
            position: 'absolute',
            color: '#fff',
            zIndex: theme.zIndex.drawer + 200,
            height: '100%',
            ...(isFullscreen ? isFullscreenStyle : {}),
          }}
          open={dropOverlay.isOpen}
          onClick={dropOverlay.close}
        >
          {overlayChildren}
        </Backdrop>
        {children}
      </Stack>
    </DropOverlayContext.Provider>
  )
}
