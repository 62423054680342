import {
  BaseEdge as RFEdge,
  EdgeProps,
  getBezierPath,
  useReactFlow,
  Node,
} from 'reactflow'
import typeColorMap from '../helpers/typeColorMap'
import { getHandleFromNode } from '../helpers/logix-helpers'

export default function Edge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  source,
  sourceHandleId = '',
  ...props
}: EdgeProps) {
  const { selected } = props

  const reactFlowInstance = useReactFlow()
  const sourceNode = reactFlowInstance.getNode(source) as Node
  const sourceHandle = getHandleFromNode(
    sourceHandleId || '',
    sourceNode.data.logixNode,
  )

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const data_type_code = sourceHandle?.data_type_code

  const styling: React.CSSProperties =
    data_type_code === 'execution'
      ? {
          ...style,
          transition: 'stroke 0.1s ease-in-out',
          strokeOpacity: selected ? 0.5 : 0.85,
          strokeDasharray: '5 5',
          strokeLinecap: 'round',
          strokeWidth: 3,
          animation: 'dashdraw 1s linear infinite',
        }
      : {
          ...style,
          transition: 'stroke 0.1s ease-in-out',
          stroke: data_type_code ? typeColorMap[data_type_code] : undefined,
          strokeOpacity: selected ? 0.5 : 0.85,
          strokeWidth: data_type_code ? 2 : 4,
        }

  return <RFEdge path={edgePath} style={styling} {...props} />
}
