import { DocumentFlagListItem } from '@/components/flags/FlagListItem'
import { DocumentFlag } from '@/types/flags'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Stack, Typography } from '@mui/material'

type AddDocumentFlagDisplayProps = {
  action: WorkflowStateAction
}

export default function AddDocumentFlagDisplay({
  action,
}: AddDocumentFlagDisplayProps) {
  const flag = {
    short_description: action.params.short_description,
    long_description: action.params.long_description,
    flag_level: action.params.flag_level,
  }

  return (
    <Stack>
      <Typography variant="body2" color="text.secondary">
        Add this flag to the document:
      </Typography>
      <DocumentFlagListItem flag={flag as DocumentFlag} />
    </Stack>
  )
}
