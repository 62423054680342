import { ProjectDocumentRuleType } from '@/types/rules'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'

const service = buildService<ProjectDocumentRuleType>({
  pathCategory: 'project_document_rule_types',
})

export function useGetProjectDocumentRuleTypeTypes({
  id,
  ...options
}: UseGetDetailOptions<ProjectDocumentRuleType>) {
  const query = useGetDetail<ProjectDocumentRuleType>({
    id,
    queryKey: queryKeys.projectDocumentRuleTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectDocumentRuleTypes: query.data,
    ...query,
  }
}

export function useGetProjectDocumentRuleTypes({
  filters,
  ...options
}: UseGetListOptions<ProjectDocumentRuleType> = {}) {
  const query = useGetList<ProjectDocumentRuleType>({
    filters,
    queryKey: queryKeys.projectDocumentRuleTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectDocumentRuleTypes: query.allData,
    ...query,
  }
}
