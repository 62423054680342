import { useGetOrganizations } from '@/service-library/hooks/organizations'
import { useEffect } from 'react'
import { Organization } from '@/types/organizations'
import { UseGetListOptions } from '@/service-library/core-hooks/useGetList'

export default function useAllOrganizations(
  options: UseGetListOptions<Organization> = {},
) {
  const query = useGetOrganizations({
    ...options,
    filters: {
      limit: '500',
      ordering: '-parent_org_id,name,id',
      ...options.filters,
    },
  })
  const { hasNextPage, isFetching, isRefetching, fetchNextPage } = query

  const isFetchingAll = hasNextPage || (isFetching && !isRefetching)

  useEffect(() => {
    if (
      (options.enabled || options.enabled === undefined) &&
      hasNextPage &&
      !isFetching
    ) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetching, options.enabled])

  return { isFetchingAll, ...query }
}
