import { Dispatch, SetStateAction } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Grid,
} from '@mui/material'
import { ProjectGrid } from '@/types/projects'
import { ProjectGridField } from '@/types/fields'
import { OverlayState } from '@/hooks/useOverlay'
import { getNonGridFields, sortBySortOrder } from '@/utils/field-utils'
import Checkbox from '@/components/inputs/Checkbox'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type VisibleFieldsDialogProps = {
  projectGrids: ProjectGrid[]
  overlay: OverlayState
  visibleFieldIds: string[]
  setVisibleFieldIds: Dispatch<SetStateAction<string[]>>
}

export default function VisibleFieldsDialog({
  projectGrids,
  overlay,
  visibleFieldIds,
  setVisibleFieldIds,
}: VisibleFieldsDialogProps) {
  const onGridToggleVisibility = (
    fields: ProjectGridField[],
    checked: boolean,
  ) => {
    setVisibleFieldIds((prev) => {
      const fieldIds = fields.map(({ id }) => id)
      return checked
        ? [...prev, ...fieldIds]
        : prev.filter((id) => !fieldIds.includes(id))
    })
  }

  return (
    <Dialog title="Visible Fields" maxWidth="md" {...overlay}>
      <DialogContent>
        <Stack spacing={3}>
          {projectGrids.map((grid) => {
            //TODO: Remove sorting for when the backend returns data sorted
            const projectGridFields = sortBySortOrder(
              getNonGridFields(grid.project_grid_fields),
            )
            const checkAllFields = projectGridFields.some(
              ({ id }) => !visibleFieldIds.includes(id),
            )
            return (
              <Box key={grid.id}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>
                    {grid.parent_project_grid_id === null
                      ? 'Document'
                      : grid.sub_project_grid_fields[0].name}
                  </Typography>
                  <Tooltip
                    title={`${checkAllFields ? 'Show' : 'Hide'} All`}
                    enterDelay={500}
                    placement="top"
                  >
                    <IconButton
                      size="small"
                      onClick={() =>
                        onGridToggleVisibility(
                          projectGridFields,
                          checkAllFields,
                        )
                      }
                    >
                      {checkAllFields ? (
                        <Visibility fontSize="inherit" />
                      ) : (
                        <VisibilityOff fontSize="inherit" />
                      )}
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Grid container rowSpacing={0.5} sx={{ mt: 1 }}>
                  {projectGridFields.map((field) => {
                    return (
                      <Grid item xs={3} key={field.id}>
                        <FormControlLabel
                          label={field.name}
                          control={
                            <Checkbox
                              color="#ffffffbe"
                              checked={visibleFieldIds.includes(field.id)}
                              onChange={(checked) => {
                                setVisibleFieldIds((prev) =>
                                  checked
                                    ? [...prev, field.id]
                                    : prev.filter((id) => id !== field.id),
                                )
                              }}
                            />
                          }
                          componentsProps={{ typography: { variant: 'body2' } }}
                          sx={{ mx: 0, columnGap: 0.5 }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
            )
          })}
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button onClick={overlay.close} variant="text">
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
