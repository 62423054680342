import { useEffect, useState } from 'react'
import { Chip, Paper, Popper, Stack, Tooltip } from '@mui/material'
import { DocumentChip } from '@/types/documents'
import { useDocumentImagePageContext } from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'

export type ChipsResultsPopperProps = {
  anchorEl: HTMLDivElement | null
  documentRowId: string
  fieldId: string
  open: boolean
  searchValue: string
  onAssign?: () => void
}

export default function ChipsResultsPopper({
  anchorEl,
  documentRowId,
  fieldId,
  open,
  searchValue,
  onAssign,
}: ChipsResultsPopperProps) {
  const [width, setWidth] = useState<number>(0)
  const { imagePage } = useDocumentImagePageContext()
  const { documentChips, setHoveredChipId, updateDocumentChipField } =
    useDocumentChipsContext()

  const matchingChips = documentChips.filter(
    (chip) =>
      (chip.project_grid_field_id !== fieldId ||
        documentRowId !== chip.document_row_id) &&
      chip.text !== 'TrueBox' &&
      chip.text !== 'FalseBox' &&
      chip.text.toLowerCase().includes(searchValue.toLowerCase()),
  )

  const assignChipToField = (targetChip: DocumentChip) => {
    updateDocumentChipField(targetChip, fieldId, documentRowId)
    onAssign?.()
  }

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const clientWidth = anchorEl?.clientWidth || 0
      setWidth(clientWidth)
    })
    if (open && anchorEl) observer.observe(anchorEl)
    return () => {
      observer.disconnect()
    }
  }, [anchorEl, open])

  return anchorEl && matchingChips.length > 0 ? (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }} // To be on top of fields with chips
    >
      <Paper
        elevation={1}
        onMouseDown={(e) => e.preventDefault()}
        sx={{
          maxHeight: 100,
          overflow: 'auto',
          p: 1,
          width,
        }}
      >
        <Stack direction="row" spacing={1} flexWrap="wrap" rowGap={0.5}>
          {matchingChips.map((chip) => {
            const chipIsInCurrentPage = imagePage === chip.document_page_number
            let title = ''
            if (chip.project_grid_field_id) {
              title = chipIsInCurrentPage
                ? 'Chip assigned to a different field.'
                : 'Chip assigned to a different field and on a different page.'
            } else if (imagePage !== chip.document_page_number) {
              title = 'Chip on a different page.'
            }
            return (
              <Tooltip
                key={chip.id}
                title={title}
                disableInteractive
                enterDelay={500}
              >
                <Chip
                  color={chipIsInCurrentPage ? 'primary' : 'neutral'}
                  variant={chip.project_grid_field_id ? 'filled' : 'outlined'}
                  label={chip.text}
                  size="small"
                  onClick={() => assignChipToField(chip)}
                  onMouseEnter={
                    chipIsInCurrentPage
                      ? () => setHoveredChipId(chip.id)
                      : undefined
                  }
                  onMouseLeave={
                    chipIsInCurrentPage
                      ? () => setHoveredChipId(null)
                      : undefined
                  }
                />
              </Tooltip>
            )
          })}
        </Stack>
      </Paper>
    </Popper>
  ) : null
}
