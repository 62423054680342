import { FiberManualRecord } from '@mui/icons-material'
import { List, Stack, Tooltip, Typography } from '@mui/material'
import { RowValueFlag } from '@/types/flags'
import useFlagColorMap from './useFlagColorMap'
import FlagLongDescription from './FlagLongDescription'

type RowValueFlagsListProps = {
  rowValueFlags: RowValueFlag[]
}

export default function FlagsBulletList({
  rowValueFlags,
}: RowValueFlagsListProps) {
  const colorMap = useFlagColorMap()
  return (
    <List component="ul" sx={{ pl: 1 }}>
      {rowValueFlags.map((flag) => {
        return (
          <Tooltip
            title={
              <FlagLongDescription longDescription={flag.long_description} />
            }
            key={flag.id}
            disableInteractive
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              component="li"
            >
              <FiberManualRecord
                sx={{
                  fontSize: 12,
                  flexShrink: 0,
                  color: colorMap[flag.flag_level].base,
                  transform: 'translateY(-1px)',
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  color: colorMap[flag.flag_level].base,
                  display: 'block',
                }}
              >
                {flag.short_description || flag.flag_type?.short_description}
              </Typography>
            </Stack>
          </Tooltip>
        )
      })}
    </List>
  )
}
