import { useCallback, useEffect, useRef, useState } from 'react'
import { useBeforeUnload } from 'react-router-dom'
import {
  useEndUserInteractionDocumentWorkflowState,
  useStartUserInteractionDocumentWorkflowState,
} from '@/service-library/hooks/metrics'

type UseTrackUserActiveTimeOptions = {
  documentId: string
  workflowStateId: string
}

export default function useTrackUserActiveTime({
  documentId,
  workflowStateId,
}: UseTrackUserActiveTimeOptions) {
  const [userInteractionId, setUserInteractionId] = useState<string>()
  const startLogWasCalledRef = useRef(false)
  const triggerDeferredEndLogRef = useRef(false)
  const initialMountRef = useRef(true)
  const timerRef = useRef<ReturnType<typeof setTimeout>>()

  const { endUserInteractionDocumentWorkflowState } =
    useEndUserInteractionDocumentWorkflowState()

  const endLog = useCallback(
    (userInteractionDataId?: string) => {
      if (!userInteractionDataId) {
        // We only want to set deferred end log if the start log was called
        if (startLogWasCalledRef.current) {
          triggerDeferredEndLogRef.current = true
        }
        return
      }
      // Clear the timer since we're ending the log
      clearTimeout(timerRef.current)

      endUserInteractionDocumentWorkflowState({ id: userInteractionDataId })
      setUserInteractionId(undefined)
      triggerDeferredEndLogRef.current = false
    },
    [endUserInteractionDocumentWorkflowState],
  )

  const { startUserInteractionDocumentWorkflowState } =
    useStartUserInteractionDocumentWorkflowState({
      onSuccess: (data) => {
        if (triggerDeferredEndLogRef.current) {
          endLog(data.id)
        } else {
          setUserInteractionId(data.id)
        }
      },
    })

  const resetTimer = useCallback(() => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      endLog(userInteractionId)
    }, 5000)
  }, [endLog, userInteractionId])

  const startLog = useCallback(() => {
    if (!documentId || !workflowStateId || startLogWasCalledRef.current) return

    startLogWasCalledRef.current = true

    // Reset the idle timer since we just created a new log
    resetTimer()

    startUserInteractionDocumentWorkflowState({
      document_id: documentId,
      workflow_state_id: workflowStateId,
    })
  }, [
    documentId,
    resetTimer,
    startUserInteractionDocumentWorkflowState,
    workflowStateId,
  ])

  // End the log when the component unmounts
  useEffect(() => {
    return () => {
      endLog(userInteractionId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this once
  }, [])

  useEffect(() => {
    if (initialMountRef.current) {
      initialMountRef.current = false
    } else {
      endLog(userInteractionId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this when the documentId or workflowStateId changes
  }, [documentId, workflowStateId])

  // We end the log before page unloads
  useBeforeUnload(
    useCallback(() => {
      endLog(userInteractionId)
    }, [endLog, userInteractionId]),
  )

  useEffect(() => {
    const clearTimer = () => {
      // If we have the userInteractionId (user is active),
      // we reset the timer and reset the startLogWasCalledRef
      if (userInteractionId) {
        startLogWasCalledRef.current = false
        resetTimer()
      }
      // If the user was idle, we start a new log
      else {
        startLog()
      }
    }
    window.addEventListener('mousedown', clearTimer)
    window.addEventListener('keydown', clearTimer)
    window.addEventListener('scroll', clearTimer)
    window.addEventListener('mousemove', clearTimer)

    return () => {
      window.removeEventListener('mousedown', clearTimer)
      window.removeEventListener('keydown', clearTimer)
      window.removeEventListener('scroll', clearTimer)
      window.removeEventListener('mousemove', clearTimer)
      clearTimeout(timerRef.current)
    }
  }, [resetTimer, startLog, userInteractionId])
}
