/* eslint-disable @typescript-eslint/no-explicit-any -- Ignore warning for now */
import { ReactNode, RefObject, createContext, useContext, useRef } from 'react'
import { Box, SxProps } from '@mui/material'

const ScrollContainerContext = createContext<RefObject<HTMLDivElement> | null>(
  null,
)

type ScrollContainerProviderProps = {
  children: ReactNode
  component?: React.ElementType<any>
  componentProps?: Record<any, any>
  sx?: SxProps
}

export const useScrollContainer = () => useContext(ScrollContainerContext)

export default function ScrollContainerProvider({
  children,
  component = 'div',
  componentProps = {},
  sx,
}: ScrollContainerProviderProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  return (
    <ScrollContainerContext.Provider value={scrollContainerRef}>
      <Box
        data-scroll-container
        sx={{ flexGrow: 1, overflowY: 'auto', minHeight: 0, ...sx }}
        ref={scrollContainerRef}
        component={component}
        {...componentProps}
      >
        {children}
      </Box>
    </ScrollContainerContext.Provider>
  )
}
