import { ImporterEmail } from '@/types/importer-emails'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<ImporterEmail>({
  pathCategory: 'importer_emails',
})

export function useGetImporterEmail({
  id,
  ...options
}: UseGetDetailOptions<ImporterEmail>) {
  const query = useGetDetail<ImporterEmail>({
    id,
    queryKey: queryKeys.importerEmails.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    importerEmail: query.data,
    ...query,
  }
}

export function useCreateImporterEmail(
  options: UseCreateDetailOptions<ImporterEmail> = {},
) {
  const mutation = useCreateDetail<ImporterEmail>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createImporterEmail: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateImporterEmail(
  options: UseUpdateDetailOptions<ImporterEmail> = {},
) {
  const mutation = useUpdateDetail<ImporterEmail>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateImporterEmail: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteImporterEmail(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<ImporterEmail>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteImporterEmail: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetImporterEmails({
  filters,
  ...options
}: UseGetListOptions<ImporterEmail> = {}) {
  const query = useGetList<ImporterEmail>({
    filters,
    queryKey: queryKeys.importerEmails.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    importerEmails: query.allData,
    ...query,
  }
}

export function useUpdateImporterEmails(
  options: UseUpdateListOptions<ImporterEmail> = {},
) {
  const mutation = useUpdateList<ImporterEmail>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateImporterEmails: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteImporterEmails({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ImporterEmail>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteImporterEmails: mutation.mutateAsync,
    ...mutation,
  }
}
