import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react'
import useDocumentComparisonData from '@/components/validation/useDocumentComparisonData'

type DocumentComparisonDataProviderValue = ReturnType<
  typeof useDocumentComparisonData
> & {
  compareDocumentEnabled: boolean
  documentIdToCompare: string
  setCompareDocumentEnabled: Dispatch<SetStateAction<boolean>>
  setDocumentIdToCompare: Dispatch<SetStateAction<string>>
}

const DocumentComparisonDataContext =
  createContext<DocumentComparisonDataProviderValue>(
    {} as DocumentComparisonDataProviderValue,
  )

export const useDocumentComparisonDataContext = () =>
  useContext(DocumentComparisonDataContext)

type DocumentComparisonDataProviderProps = {
  children: ReactNode
} & DocumentComparisonDataProviderValue

export default function DocumentComparisonDataProvider({
  children,
  ...rest
}: DocumentComparisonDataProviderProps) {
  return (
    <DocumentComparisonDataContext.Provider value={rest}>
      {children}
    </DocumentComparisonDataContext.Provider>
  )
}
