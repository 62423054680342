import { ReactNode } from 'react'
import { Box, SxProps } from '@mui/material'

type LeftBorderedBoxProps = {
  children: ReactNode
  color: string
  showMiniBorder?: boolean
  sx?: SxProps
}

export default function LeftBorderedBox({
  children,
  color,
  showMiniBorder,
  sx,
}: LeftBorderedBoxProps) {
  return (
    <Box
      sx={{
        width: '100%',
        '&::before': {
          content: '""',
          transition: 'all 0.1s',
          width: 4,
          background: color,
          display: 'inline-block',
          height: '90%',
          position: 'absolute',
          top: '5%',
          left: 0,
        },
        '@keyframes shrinkGrow': {
          '0%': { height: '70%', top: '15%' },
          '50%': { height: '40%', top: '30%' },
          '100%': { height: '70%', top: '15%' },
        },
        '&::after': showMiniBorder
          ? {
              content: '""',
              transition: 'height 0.5s ease-in-out',
              width: 2,
              background: color,
              display: 'inline-block',
              position: 'absolute',
              opacity: 0.6,
              left: -3,
              animation: 'shrinkGrow 2.5s infinite',
            }
          : undefined,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
