import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'

type NodeCategoryAccordionProps = {
  children: ReactNode
  title: string
  expanded: boolean
  setAsOpenCategory: (expanded: boolean) => void
}

export default function NodeCategoryAccordion({
  title,
  children,
  expanded,
  setAsOpenCategory,
}: NodeCategoryAccordionProps) {
  return (
    <Accordion
      disableGutters
      square
      expanded={expanded}
      onChange={(e, isExpanded) => {
        setAsOpenCategory(isExpanded)
      }}
      sx={(theme) => ({
        background: 'transparent',
        '&:not(:first-of-type)': {
          borderTop: `1px solid ${theme.palette.divider}`,
        },
        '&:before': {
          display: 'none',
        },
      })}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          flexDirection: 'row-reverse',
          gap: '8px',
          pl: 1,
          py: 0.5,
          minHeight: 0,
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
      >
        <Typography variant="body2">
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, pb: 1 }}>{children}</AccordionDetails>
    </Accordion>
  )
}
