import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import {
  DocumentWorkflowState,
  WorkflowStateDocumentCountInfo,
} from '@/types/document-workflow-states'
import axios from '../auth-v2-axios'
import useMutation, { UseMutationOptions } from '../core-hooks/useMutation'
import { getDetail } from '../request-wrappers'
import useGet, { UseGetOptions } from '../core-hooks/useGet'

const service = buildService<DocumentWorkflowState>({
  pathCategory: 'document_workflow_states',
})

export function useGetDocumentWorkflowState({
  id,
  ...options
}: UseGetDetailOptions<DocumentWorkflowState>) {
  const query = useGetDetail<DocumentWorkflowState>({
    id,
    queryKey: queryKeys.documentWorkflowStates.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    documentWorkflowState: query.data,
    ...query,
  }
}

export function useCreateDocumentWorkflowState(
  options: UseCreateDetailOptions<DocumentWorkflowState> = {},
) {
  const mutation = useCreateDetail<DocumentWorkflowState>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDocumentWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDocumentWorkflowState(
  options: UseUpdateDetailOptions<DocumentWorkflowState> = {},
) {
  const mutation = useUpdateDetail<DocumentWorkflowState>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDocumentWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentWorkflowState(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<DocumentWorkflowState>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDocumentWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDocumentWorkflowStates({
  filters,
  ...options
}: UseGetListOptions<DocumentWorkflowState> = {}) {
  const query = useGetList<DocumentWorkflowState>({
    filters,
    queryKey: queryKeys.documentWorkflowStates.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    documentWorkflowStates: query.allData,
    ...query,
  }
}

export function useUpdateDocumentWorkflowStates(
  options: UseUpdateListOptions<DocumentWorkflowState> = {},
) {
  const mutation = useUpdateList<DocumentWorkflowState>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDocumentWorkflowStates: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentWorkflowStates({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DocumentWorkflowState>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDocumentWorkflowStates: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Set Document(s) Workflow State
 * Used to set document(s) workflow state within a workflow.
 */
type SetWorkflowStateBody = {
  patch: {
    workflow_state__code: string
    workflow_state__id: string
  }
  filter_criteria: {
    workflow_state__workflow_id: string
    document_id__in: string[]
  }
  current_workflow_state_id?: string // Must be included if you want optimistic updates to work
}
export function useSetDocumentsWorkflowState(
  options: UseMutationOptions<
    string[],
    unknown,
    SetWorkflowStateBody,
    unknown
  > = {},
) {
  const mutation = useMutation<string[], unknown, SetWorkflowStateBody>({
    mutationFn: (body: SetWorkflowStateBody) => {
      delete body.current_workflow_state_id
      return axios.patch(
        '/v2/pd-workflow/document_workflow_states/set_document_workflow_state',
        body,
      )
    },
    ...options,
  })

  return {
    setWorkflowState: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Remove Documents from Workflow
 * Used to remove documents from a workflow.
 */
type RemoveFromWorkflowBody = {
  filter_criteria: {
    workflow_state__workflow_id: string
    document_id__in: string[]
  }
}
export function useRemoveDocumentsFromWorkflow(
  options: UseMutationOptions<
    string[],
    unknown,
    RemoveFromWorkflowBody,
    unknown
  > = {},
) {
  const mutation = useMutation<string[], unknown, RemoveFromWorkflowBody>({
    mutationFn: (body: RemoveFromWorkflowBody) => {
      return axios.patch(
        '/v2/pd-workflow/document_workflow_states/remove_from_workflow',
        body,
      )
    },
    ...options,
  })

  return {
    removeFromWorkflow: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Get Workflow State Document Counts
 * Gets the counts for documents within a workflow state.
 */
export function useGetWorkflowStateDocumentCounts({
  project_id,
  enabled = true,
  ...options
}: UseGetOptions<WorkflowStateDocumentCountInfo> & { project_id: string }) {
  const query = useGet<WorkflowStateDocumentCountInfo>({
    queryKey: queryKeys.documentWorkflowStates.detail(
      {
        id: project_id,
        filters: { 'api-consistency': 'weak', ...options.filters },
      },
      ['document_counts'],
    ),
    serviceFn: ({ filters, ...serviceFnOptions }) => {
      return getDetail<WorkflowStateDocumentCountInfo>({
        url: '/v2/pd/document_workflow_states/count',
        filters: {
          project_id,
          'api-consistency': 'weak',
          ...filters,
        },
        ...serviceFnOptions,
      })
    },
    enabled: enabled && !!project_id,
    ...options,
  })

  return {
    documentCounts: query.data,
    ...query,
  }
}
