import { useState } from 'react'
import { Card, Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { ProjectDocumentRule } from '@/types/rules'
import { useUpdateProjectDocumentRule } from '@/service-library/hooks/project-document-rules'
import { useGetProjectGridFields } from '@/service-library/hooks/project-grid-fields'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import RuleSettings, { FormValues } from '@/components/rules/RuleSettings'
import DocumentRules from './DocumentRules'

export default function DocumentRulesPage() {
  const [selectedRule, setSelectedRule] = useState<ProjectDocumentRule | null>(
    null,
  )

  const { project } = useProjectContext()
  const { projectGridFields = [] } = useGetProjectGridFields({
    filters: {
      limit: '1000',
      fields__only: 'id,name',
      project_id: project.id,
      fields__include: 'project_grid_field_type',
      'project_grid_field_type__code__in!': 'grid',
    },
  })

  const { updateProjectDocumentRule, isLoading } = useUpdateProjectDocumentRule(
    {
      sideEffectQueryKeys: [queryKeys.projectDocumentRules.details()],
      onSuccess: () => {
        showSuccessSnackbar('Document Rule Updated')
      },
      onError: () => {
        showErrorSnackbar('Document Rule Update Failed')
      },
    },
  )

  const updateDocumentRule = async ({
    workflow_states_ids,
    dependent_fields,
    is_superuser_rule,
    ...rest
  }: FormValues) => {
    if (selectedRule) {
      return updateProjectDocumentRule({
        ...selectedRule,
        dependent_fields_ids: dependent_fields.map(({ id }) => id),
        workflow_states_ids,
        is_superuser_rule,
        params: rest,
      })
    }
    return
  }

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <ProjectBreadcrumb label="Document Rules" url="../document-rules" />
      <Typography variant="h5" color="textPrimary">
        Document Rules
      </Typography>
      <Card sx={{ p: 2, height: '100%', flexGrow: 1 }}>
        <Grid2 container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid2 xs={6}>
            <DocumentRules
              selectedRule={selectedRule}
              setSelectedRule={setSelectedRule}
            />
          </Grid2>
          {selectedRule && (
            <Grid2 xs={6}>
              <RuleSettings
                key={selectedRule.id} // Completely rerender each time we change the selected rule
                isLoading={isLoading}
                onSubmit={updateDocumentRule}
                rule={selectedRule}
                nonGridFields={projectGridFields}
              />
            </Grid2>
          )}
        </Grid2>
      </Card>
    </Stack>
  )
}
