import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardProps,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'

type GeneralInfoBoxContentProps = {
  label: ReactNode
  value: string | number
  shrinkValueSize?: boolean
  sx?: SxProps
}

type GeneralInfoBoxProps = {
  children?: ReactNode
  onClick?: () => void
  to?: string
} & CardProps &
  Omit<GeneralInfoBoxContentProps, 'sx'>

export function GeneralInfoBoxContent({
  label,
  value,
  shrinkValueSize,
  sx,
}: GeneralInfoBoxContentProps) {
  return (
    <Stack justifyContent="space-between" sx={sx}>
      {typeof label === 'string' ? (
        <Typography component="h2" variant="body2">
          {label}
        </Typography>
      ) : (
        label
      )}
      <Typography variant={shrinkValueSize ? 'subtitle1' : 'h4'}>
        {value}
      </Typography>
    </Stack>
  )
}

export default function GeneralInfoBox({
  label,
  value,
  children,
  onClick,
  shrinkValueSize,
  to,
  ...props
}: GeneralInfoBoxProps) {
  const contents = (
    <GeneralInfoBoxContent
      label={label}
      value={value}
      shrinkValueSize={shrinkValueSize}
      sx={{ p: 1.5, flexGrow: 1 }}
    />
  )

  return (
    <Card
      sx={{
        overflow: 'hidden',
        borderRadius: 2,
        height: '100%',
      }}
      elevation={0}
      {...props}
    >
      {onClick || to ? (
        <CardActionArea
          sx={{ height: '100%', p: 1.5 }}
          onClick={onClick}
          component={to ? Link : 'button'}
          to={to}
        >
          {contents}
          {children}
        </CardActionArea>
      ) : (
        <Stack sx={{ height: '100%' }}>
          {contents}
          {children}
        </Stack>
      )}
    </Card>
  )
}
