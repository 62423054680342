import {
  Switch,
  SwitchProps,
  FormControlLabel,
  Box,
  FormControlProps,
  Tooltip,
} from '@mui/material'
import { ReactNode, forwardRef } from 'react'
import { FieldValues, useController, FieldPath } from 'react-hook-form'

type FormSwitchProps<FormValues extends FieldValues> = {
  name: FieldPath<FormValues>
  label?: ReactNode
  formLabelSx?: FormControlProps['sx']
  tooltipTitle?: string
} & SwitchProps

const FormSwitch = forwardRef(function FormSwitch<
  FormValues extends FieldValues,
>(
  {
    name,
    label,
    formLabelSx,
    tooltipTitle,
    ...props
  }: FormSwitchProps<FormValues>,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { field } = useController<FormValues>({ name })

  return (
    <Box>
      <Tooltip title={tooltipTitle} enterDelay={1000}>
        <FormControlLabel
          control={
            <Switch {...props} {...field} checked={field.value} ref={ref} />
          }
          label={label}
          sx={formLabelSx}
        />
      </Tooltip>
    </Box>
  )
})

export default FormSwitch
