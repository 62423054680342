import { UseQueryOptions } from '@tanstack/react-query'
import useQuery from './useQuery'
import { LogixNodeType } from '@/types/logix'
import { getLogixNodeTypes } from '../logix'

type UseLogixNodeTypesOptions = UseQueryOptions<LogixNodeType[]>

export default function useLogixNodeTypes(
  options: UseLogixNodeTypesOptions = {},
) {
  const queryKey = ['logix-node-types']

  const query = useQuery({
    queryKey,
    queryFn: () => {
      return getLogixNodeTypes().then((res) => res.data.results)
    },
    ...options,
  })

  return {
    nodeTypes: query.data,
    ...query,
  }
}
