import { Box } from '@mui/material'
import { ReactNode } from 'react'

type TabPanelProps = {
  children: ReactNode
  value: number
  index: number
}

export default function TabPanel({ children, value, index }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '100%', minHeight: 0 }}
    >
      {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  )
}
