import { Dispatch, SetStateAction } from 'react'
import { Typography, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { OverlayState } from '@/hooks/useOverlay'
import { useReprocessDocuments } from '@/service-library/hooks/process-documents'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'

type ReprocessDocumentsDialogProps = {
  documentIds: string[]
  overlay: OverlayState
  refetch: () => void
  setSelectedRows: Dispatch<SetStateAction<Record<string, boolean>>>
}

export default function ReprocessDocumentsDialog({
  documentIds,
  overlay,
  refetch,
  setSelectedRows,
}: ReprocessDocumentsDialogProps) {
  const { reprocessDocuments, isLoading } = useReprocessDocuments({
    sideEffectQueryKeys: [
      queryKeys.documents.lists(),
      queryKeys.documentRows.lists(),
      queryKeys.documentWorkflowStates.details(), // count
    ],
    onSuccess: () => {
      overlay.close()
    },
    onError: () => {
      showErrorSnackbar(
        'Failed to reprocess document(s). Please try again later.',
      )
    },
    onIdle: () => {
      setSelectedRows({})
      refetch()
    },
  })

  const handleReprocess = () => {
    reprocessDocuments(
      documentIds.map((id) => ({
        id,
        document_id: id,
      })),
    )
  }

  return (
    <Dialog maxWidth="sm" {...overlay} title="Reprocess Documents">
      <DialogContent>
        <Typography>
          You are about to reprocess these documents. Any changes made to the
          document fields will be lost.
        </Typography>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleReprocess}
        >
          Continue
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
