import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'
import { DataType } from './types'

// The key is always `${data_type_match_key}${nodeId}`
type Inheritances = Record<string, DataType | null>

type InheritancesContextValue = {
  inheritances: Inheritances
  setInheritances: Dispatch<SetStateAction<Inheritances>>
}

const InheritancesContext = createContext<InheritancesContextValue>(
  {} as InheritancesContextValue,
)

export const useDataTypeInheritance = () => useContext(InheritancesContext)

type InheritancesProviderProps = {
  children: ReactNode
}

export default function InheritancesProvider({
  children,
}: InheritancesProviderProps) {
  const [inheritances, setInheritances] = useState<Inheritances>({})

  return (
    <InheritancesContext.Provider value={{ inheritances, setInheritances }}>
      {children}
    </InheritancesContext.Provider>
  )
}
