import 'reactflow/dist/style.css'
import ReactFlow, {
  Background,
  Controls,
  Node,
  ReactFlowProps,
  ReactFlowProvider,
  SelectionMode,
  Edge as EdgeType,
} from 'reactflow'
import { Box } from '@mui/material'
import NodeIndexProvider from './NodeIndexProvider'
import ProblemsProvider from './ProblemsProvider'
import { useCallback, useEffect, useRef, useState } from 'react'
import Edge from './edges/Edge'
import useOverlay from '@/hooks/useOverlay'
import useContextMenuPosition from '@/hooks/useContextMenuPosition'
import LegendPanel from './LegendPanel'
import NodesPanel from './NodesPanel'
import { useHotkeys } from 'react-hotkeys-hook'
import useBoardState from './hooks/useBoardState'
import useEventHandlers from './hooks/useEventHandlers'
import useIsValidConnection from './hooks/useIsValidConnection'
import NodeRightClickMenu from './NodeRightClickMenu'
import useFilteredNodeTypes from './hooks/useFilteredNodeTypes'
import { LogixHandle, LogixNodeType, NodeTypeIndex } from '@/types/logix'
import useConnectHandles from './hooks/useConnectHandles'
import useAddNode from './hooks/useAddNode'
import ProblemsPanel from './ProblemsPanel'
import useConnectAfterCreated from './hooks/useConnectAfterCreated'
import BoardProvider from './BoardProvider'
import DataTypeInheritanceProvider from './InheritancesProvider'
import EdgesProvider from './EdgesProvider'

const deleteKeyCodes = ['Backspace', 'Delete']

const edgeTypeIndex = {
  edge: Edge,
}

type FlowViewportDisplayProps = {
  /** Node types as defined by useNodeTypes. */
  nodeTypeIndex: NodeTypeIndex

  /** All of the board's stateful values. */
  state: ReturnType<typeof useBoardState>

  /** If any panels are to be rendered, they should be passed here. */
  children?: React.ReactNode
} & Omit<ReactFlowProps, 'nodes'>

// const mapChangingData = (properties: HandleStructure[]) => {
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any -- since properties can be deeply nested, and we don't need the real id, typing a new type for that seemed unnecessary
//   const newProperties: Record<string, any>[] = properties
//     .sort((a, b) => {
//       if (!a.label || !b.label) return 0
//       return a.label.localeCompare(b.label)
//     })
//     .map((property, index) => ({
//       ...property,
//       id: index,
//       properties: property.properties
//         ? mapChangingData(property.properties)
//         : undefined,
//     }))
//   return newProperties
// }

// const compareObjectProperties = (
//   sourceProperties: HandleStructure[],
//   targetProperties: HandleStructure[],
// ) => {
//   const sourceComparableProperties = mapChangingData(sourceProperties)
//   const targetComparableProperties = mapChangingData(targetProperties)
//   return (
//     JSON.stringify(sourceComparableProperties) ===
//     JSON.stringify(targetComparableProperties)
//   )
// }

function FlowViewportContent({
  id,
  nodeTypeIndex,
  state,
  containerRef,
  children,
}: FlowViewportDisplayProps & {
  /** Used by event handlers to determine specific boundaries to work within. */
  containerRef: React.RefObject<HTMLDivElement>
}) {
  const { board, nodes, edges, setSelection } = state

  const addNode = useAddNode({ board, containerRef })

  const [snapToGrid, setSnapToGrid] = useState<boolean>(false)

  const reactFlowRef = useRef<HTMLDivElement>(null)

  const contextMenuOverlay = useOverlay()
  const contextMenu = useContextMenuPosition()

  const [connectingInfo, setConnectingInfo] = useState<{
    connectingNode?: Node
    connectingHandle?: LogixHandle
  }>({})
  const { connectingNode, connectingHandle } = connectingInfo

  const connectHandles = useConnectHandles({ containerRef })

  const connectAfterCreated = useConnectAfterCreated({
    connectHandles,
  })

  useHotkeys(
    'shift',
    ({ type }) => {
      setSnapToGrid(type === 'keydown')
    },
    {
      keyup: true,
      keydown: true,
    },
    [],
  )

  // When attempting to connect two nodes, this verifies that they are compatible
  const isValidConnection = useIsValidConnection()

  const { filteredNodeTypes, setFilteringValues } = useFilteredNodeTypes()

  function onPaneContextMenu(event: React.MouseEvent) {
    event.preventDefault()
    contextMenu.handleUpdatePosition(event.clientX, event.clientY)
    contextMenuOverlay.open(event)
  }

  function onNodeContextMenu(event: React.MouseEvent) {
    const isIterationNodePane = (
      event.target as HTMLDivElement
    )?.classList.contains('node-children-container')
    if (isIterationNodePane) onPaneContextMenu(event)
  }

  const eventHandlers = useEventHandlers({
    state,
    reactFlowRef,
    updateContextMenuPosition: contextMenu.handleUpdatePosition,
    contextMenuOverlay,
    setFilteringValues,
    connectingInfo,
    setConnectingInfo,
    containerRef,
  })

  function onSelect(nodeType: LogixNodeType | null) {
    if (!nodeType || !board) return

    const newNodeId = addNode({
      nodeType,
      position: {
        clientX: contextMenu.position?.mouseX || 0,
        clientY: contextMenu.position?.mouseY || 0,
      },
    })

    // If we're pulling off of a handle and creating a new node from it, connect them after the node is created.
    if (connectingHandle && connectingNode) {
      connectAfterCreated({
        nodeId: newNodeId,
        connectingHandle,
        connectingNode,
      })
    }

    setFilteringValues()
    contextMenuOverlay.close()
  }

  // Clear the connecting info whenever we close the suggested nodes menu.
  useEffect(() => {
    if (!contextMenuOverlay.isOpen) {
      setConnectingInfo({})
    }
  }, [contextMenuOverlay.isOpen])

  const [, setSavedToClipboard] = useState<{
    nodes: Node[]
    edges: EdgeType[]
  }>({
    nodes: [],
    edges: [],
  })

  const onKeyDown = useCallback(
    ({ key, metaKey }: React.KeyboardEvent<HTMLDivElement>) => {
      if ((key === 'c' || key === 'v') && metaKey) {
        if (key === 'c') {
          // Save currently selected into our "clipboard"
          setTimeout(() => {
            setSavedToClipboard({
              nodes: nodes.filter(({ selected }) => selected),
              edges: edges.filter(({ selected }) => selected),
            })
          }, 100)
        } else {
          // TODO: Paste saved in "clipboard" to the board
        }
      }
    },
    [edges, nodes],
  )

  if (!board) return null

  return (
    // Has tabIndex so copy/paste will work consistently
    <Box
      sx={{ height: '100%', sx: { '&:focused': { outline: 'none' } } }}
      onKeyDown={onKeyDown}
      tabIndex={1}
    >
      <ReactFlow
        id={id}
        nodes={nodes}
        edges={edges}
        edgeTypes={edgeTypeIndex}
        nodeTypes={nodeTypeIndex}
        isValidConnection={isValidConnection}
        deleteKeyCode={deleteKeyCodes}
        selectionMode={SelectionMode.Partial}
        ref={reactFlowRef}
        minZoom={0.1}
        snapGrid={[25, 25]}
        snapToGrid={snapToGrid}
        onPaneContextMenu={onPaneContextMenu}
        onNodeContextMenu={onNodeContextMenu}
        {...eventHandlers}
      >
        {children}
        <Background gap={50} />
        <Controls />
        <ProblemsPanel setSelection={setSelection} />
        <LegendPanel />
        <NodesPanel />

        {board && (
          <NodeRightClickMenu
            overlay={contextMenuOverlay}
            position={contextMenu.position || { mouseX: 0, mouseY: 0 }}
            filteredNodeTypes={filteredNodeTypes}
            setFilteringValues={setFilteringValues}
            onSelect={onSelect}
          />
        )}
      </ReactFlow>
    </Box>
  )
}

type FlowViewportProps = {
  /** The board ID to render. */
  boardId: string

  /** Node types as defined by useNodeTypes. This is external to FlowViewport so it can be tweaked to meet the board's specific needs. */
  nodeTypeIndex: NodeTypeIndex

  /** If any panels are to be rendered, they should be passed here. */
  children?: React.ReactNode
} & Omit<ReactFlowProps, 'nodes'>

function FlowViewport({ boardId, ...props }: FlowViewportProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  const state = useBoardState({
    boardId,
  })

  return (
    <Box sx={{ height: '100%' }} ref={containerRef}>
      <DataTypeInheritanceProvider>
        {state.board && (
          <BoardProvider board={state.board}>
            <FlowViewportContent
              {...props}
              state={state}
              containerRef={containerRef}
            />
          </BoardProvider>
        )}
      </DataTypeInheritanceProvider>
    </Box>
  )
}

// This needs to be wrapped around FlowViewport so useBoardState can use useReactFlow
export default function FlowViewportWrapper(props: FlowViewportProps) {
  const { boardId } = props
  return (
    <ReactFlowProvider>
      <NodeIndexProvider>
        <ProblemsProvider>
          <EdgesProvider boardId={boardId}>
            <FlowViewport {...props} />
          </EdgesProvider>
        </ProblemsProvider>
      </NodeIndexProvider>
    </ReactFlowProvider>
  )
}
