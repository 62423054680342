import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import useMutation, { UseMutationOptions } from './useMutation'
import { AxiosResponse } from 'axios'
import PaginatedResponse from '@/types/paginated-response'
import { CreateDetailRequestOptions, RequestOptions } from '../request-types'
import { ListQueryKeyOption } from '../query-types'
import { LogEntry } from '@/types/log-entries'
import { useBulkCreateLogEntries } from '../hooks/log-entries'

export type UseCreateDetailOptions<T, ReturnT = T> = UseMutationOptions<
  ReturnT,
  unknown,
  T,
  { previous?: InfiniteData<PaginatedResponse<T>> } | void
> &
  RequestOptions &
  ListQueryKeyOption & {
    // listQueryKey is required to have previous data
    createActivityLog?: (
      data?: ReturnT,
    ) => Omit<LogEntry, 'created_at' | 'user_id' | 'id'>[]
  }

type ServiceFn<T, ReturnT = T> = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (
    options: CreateDetailRequestOptions<T>,
  ) => Promise<AxiosResponse<ReturnT>>
}

/**
 * This hook is used to create a new item and, optionally, update a related list query with the new item.
 */
export default function useCreateDetail<T, ReturnT = T>({
  serviceFn,
  sideEffectQueryKeys,
  listQueryKey,
  filters,
  axiosOptions,
  onMutate,
  onError,
  onSuccess,
  onSettled,
  createActivityLog,
  ...options
}: UseCreateDetailOptions<T, ReturnT> & ServiceFn<T, ReturnT>) {
  const queryClient = useQueryClient()
  const { createLogEntries } = useBulkCreateLogEntries()

  return useMutation<
    ReturnT,
    unknown,
    T,
    { previous?: InfiniteData<PaginatedResponse<T>> }
  >({
    sideEffectQueryKeys,
    mutationFn: (item) => {
      return serviceFn({
        item,
        filters,
        axiosOptions,
      }).then(({ data }) => data)
    },
    onMutate: async (item) => {
      let previous: InfiniteData<PaginatedResponse<T>> | undefined = undefined
      if (listQueryKey) {
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        previous = queryClient.getQueryData(listQueryKey)

        // Update the list data in cache if it exists
        queryClient.setQueryData<InfiniteData<PaginatedResponse<T>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            const pages = old?.pages.map((page, index) => {
              if (index !== old?.pages.length - 1) return page
              return {
                ...page,
                results: [...page.results, item],
              }
            })
            return {
              ...old,
              pages,
            }
          },
        )
      }
      // Run user supplied onMutate function if available
      onMutate?.(item)

      // Return the previous data in case we need to reset it in onError
      return { previous }
    },
    onError: (error, item, context) => {
      // Run user supplied onError function if available
      onError?.(error, item, context)

      if (!listQueryKey || !context?.previous) return
      // Reset
      queryClient.setQueryData(listQueryKey, context?.previous)
    },

    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context)
      const logEntries = createActivityLog?.(data)
      if (logEntries?.length) {
        createLogEntries(logEntries as LogEntry[]).catch(() => {})
      }
    },
    onSettled: (...args) => {
      onSettled?.(...args)

      if (listQueryKey)
        queryClient.invalidateQueries({ queryKey: listQueryKey })
    },
    ...options,
  })
}
