import { IconButton, Badge, Tooltip, useTheme, Box } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import FailedExportsDialog from './FailedExportsDialog'
import { ErrorOutline } from '@mui/icons-material'
import { useSpring, animated } from '@react-spring/web'
import { CSSProperties } from 'react'
import { WorkflowState } from '@/types/workflows'
import { useDocumentCount } from '../document-count-provider/DocumentCountProvider'

type FailedExportsButtonProps = {
  workflowState: WorkflowState
}

export default function FailedExportsButton({
  workflowState,
}: FailedExportsButtonProps) {
  const theme = useTheme()
  const failedDocumentsOverlay = useOverlay()

  const { countByWorkflowStateId } = useDocumentCount()
  const count = countByWorkflowStateId[workflowState.id]?.error || 0

  const popInStyle = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    pause: count === 0, // Pause and reset when we have no failed documents
    reset: count === 0, // Pause and reset when we have no failed documents
    config: {
      tension: 500,
    },
  })

  const colorFlashStyle = useSpring({
    from: { backgroundColor: `${theme.palette.error.main}ff` },
    to: { backgroundColor: `${theme.palette.error.main}11` },
    pause: count === 0, // Pause and reset when we have no failed documents
    reset: count === 0, // Pause and reset when we have no failed documents
    config: {
      tension: 200,
    },
  })

  return count > 0 ? (
    <animated.div style={popInStyle}>
      <Badge
        badgeContent={<Box sx={{ color: 'text.secondary' }}>{count}</Box>}
        overlap="circular"
      >
        <Tooltip title="Failed Exports">
          <IconButton
            component={animated.div}
            style={colorFlashStyle as unknown as CSSProperties}
            color="error"
            size="small"
            onClick={failedDocumentsOverlay.open}
          >
            <ErrorOutline />
          </IconButton>
        </Tooltip>
      </Badge>
      <FailedExportsDialog
        overlay={failedDocumentsOverlay}
        workflowState={workflowState}
      />
    </animated.div>
  ) : null
}
