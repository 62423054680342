import { IconButton, Badge, Tooltip, useTheme, Box } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import FailedDocumentsDialog from './FailedDocumentsDialog'
import { SyncProblem } from '@mui/icons-material'
import { useSpring, animated } from '@react-spring/web'
import { CSSProperties } from 'react'
import { useWorkflowsContext } from '../workflows-provider/WorkflowsProvider'
import { useDocumentCount } from '../document-count-provider/DocumentCountProvider'

export default function FailedDocumentsButton() {
  const theme = useTheme()
  const failedDocumentsOverlay = useOverlay()

  const { getWorkflowStateByCode } = useWorkflowsContext()
  const processingWorkflowState = getWorkflowStateByCode('basic', 'processing')

  const { countByWorkflowStateId } = useDocumentCount()
  const failedCount = processingWorkflowState
    ? countByWorkflowStateId[processingWorkflowState.id]?.error || 0
    : 0

  const popInStyle = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    pause: failedCount === 0, // Pause and reset when we have no failed documents
    reset: failedCount === 0, // Pause and reset when we have no failed documents
    config: {
      tension: 500,
    },
  })

  const colorFlashStyle = useSpring({
    from: { backgroundColor: `${theme.palette.error.main}ff` },
    to: { backgroundColor: `${theme.palette.error.main}11` },
    pause: failedCount === 0, // Pause and reset when we have no failed documents
    reset: failedCount === 0, // Pause and reset when we have no failed documents
    config: {
      tension: 200,
    },
  })

  return failedCount > 0 ? (
    <animated.div style={popInStyle}>
      <Badge
        badgeContent={<Box sx={{ color: 'text.secondary' }}>{failedCount}</Box>}
        overlap="circular"
      >
        <Tooltip title="Failed Documents">
          <IconButton
            component={animated.div}
            style={colorFlashStyle as unknown as CSSProperties}
            color="error"
            size="small"
            onClick={failedDocumentsOverlay.open}
          >
            <SyncProblem />
          </IconButton>
        </Tooltip>
      </Badge>
      {processingWorkflowState && (
        <FailedDocumentsDialog
          count={failedCount}
          overlay={failedDocumentsOverlay}
          processingWorkflowState={processingWorkflowState}
        />
      )}
    </animated.div>
  ) : null
}
