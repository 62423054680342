import { useNavigate } from 'react-router-dom'
import { MRT_ColumnDef, MRT_TableOptions } from 'material-react-table'
import { Add } from '@mui/icons-material'
import { Button, Container, Stack } from '@mui/material'
import { DataList } from '@/types/data-lists'
import useOverlay from '@/hooks/useOverlay'
import InfiniteScrollTable from '@/components/zerapix-table/InfiniteScrollTable'
import useInfiniteScrollZerapixTable from '@/components/zerapix-table/useInfiniteScrollZerapixTable'
import NewListDialog from './AddEditDataListDialog'
import { useGetDataLists } from '@/service-library/hooks/data-lists'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'

export const customDataListTypeID = 'e8bdb642-963d-4d68-88ba-707eb9ec91f9'

const columns: MRT_ColumnDef<DataList>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
]

export default function DataListList() {
  const navigate = useNavigate()
  const { rootOrganization } = useRootOrganization()
  const newListOverlay = useOverlay()

  const userListsQuery = useGetDataLists({
    filters: {
      limit: '1000',
      org_id: rootOrganization.id,
      fields__include: 'data_list_type',
      data_list_type_id__in: customDataListTypeID,
    },
  })
  const systemListsQuery = useGetDataLists({
    filters: {
      limit: '1000',
      org_id: rootOrganization.id,
      fields__include: 'data_list_type',
      'data_list_type_id__in!': customDataListTypeID,
    },
  })

  const tableOptions = {
    query: userListsQuery,
    columns,
    enablePagination: false,
    initialState: { density: 'comfortable' },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/settings/lists/${row.original.id}`)
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  } as Omit<MRT_TableOptions<DataList>, 'data' | 'query'>

  const userListsTable = useInfiniteScrollZerapixTable<DataList>({
    ...tableOptions,
    query: userListsQuery,
    data: userListsQuery.dataLists,
    title: 'Lists',
    renderTableActions: () => {
      return (
        <Button
          onClick={newListOverlay.open}
          startIcon={<Add />}
          variant="text"
        >
          New List
        </Button>
      )
    },
  })

  const systemListsTable = useInfiniteScrollZerapixTable<DataList>({
    ...tableOptions,
    query: systemListsQuery,
    data: systemListsQuery.dataLists,
    title: 'System Managed Lists',
  })

  return (
    <Container maxWidth="sm">
      <Stack spacing={4}>
        <InfiniteScrollTable<DataList>
          query={userListsQuery}
          table={userListsTable}
        />

        {systemListsQuery.dataLists.length > 0 && (
          <InfiniteScrollTable<DataList>
            query={systemListsQuery}
            table={systemListsTable}
          />
        )}
      </Stack>

      <NewListDialog overlay={newListOverlay} />
    </Container>
  )
}
