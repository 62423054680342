import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Search } from '@mui/icons-material'
import { Alert, DialogContent, LinearProgress, TextField } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetDocument } from '@/service-library/hooks/documents'
import { Dialog } from '@/components/dialog'
import useGetDocumentWorkflowAndState from '@/hooks/useGetDocumentWorkflowAndState'

type CommandPaletteDialogProps = {
  overlay: OverlayState
}

export default function CommandPaletteDialog({
  overlay,
}: CommandPaletteDialogProps) {
  const navigate = useNavigate()
  const [docId, setDocId] = useState('')
  const [enabled, setEnabled] = useState(false)
  const { document, isLoading, isError } = useGetDocument({
    id: docId,
    enabled,
    retry: 2,
    filters: {
      fields__only: 'id,project_id,workflow_states_ids',
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const { workflowId, workflowStateId } = useGetDocumentWorkflowAndState({
    document,
    enabled,
  })

  useEffect(() => {
    if (enabled && document && workflowId && workflowStateId) {
      navigate(
        `/projects/${document.project_id}/documents?workflow=${workflowId}&workflow_state=${workflowStateId}&document_id=${document.id}`,
      )
      overlay.close()
    }
  }, [document, enabled, navigate, overlay, workflowId, workflowStateId])

  return (
    <Dialog
      {...overlay}
      title=""
      maxWidth="sm"
      TransitionProps={{
        onExited: () => {
          if (enabled) {
            setEnabled(false)
            setDocId('')
          }
        },
      }}
    >
      <DialogContent>
        {enabled ? (
          <>
            {isLoading && <LinearProgress />}
            {isError && (
              <Alert severity="error" variant="outlined">
                We were unable to load this document. It may have been removed
                or there may have been an issue while retrieving the data.
                Please try again later.
              </Alert>
            )}
          </>
        ) : (
          <form
            // Wrapping with a form allows submitting by pressing enter
            style={{ flexGrow: 1 }}
            onSubmit={(e) => {
              e.preventDefault()
              if (docId.trim()) {
                setEnabled(true)
              }
            }}
          >
            <TextField
              value={docId}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e) => setDocId(e.target.value)}
              autoFocus
              InputProps={{
                startAdornment: <Search />,
                sx: { columnGap: 0.5 },
              }}
              placeholder="Go to Document ID"
            />
          </form>
        )}
      </DialogContent>
    </Dialog>
  )
}
