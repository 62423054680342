import {
  Button,
  Paper,
  Stack,
  ThemeProvider,
  createTheme,
  Typography,
  Box,
  CircularProgress,
  Grow,
} from '@mui/material'
import SRAppBar from './SRAppBar'
import SRCardSection from './SRCardSection'
import SRTextField from './SRTextField'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import SRTempImage from '@/assets/SRTempImage.png'
import AppLayout from '../app-layout/AppLayout'
import SRDrawer from '@/assets/SRDrawer'
import SRPowerConsumption from './SRPowerConsumption'
import FileUploadButton from '@/components/upload-files/FileUploadButton'
import useUploadFiles from '../project-dashboard/useUploadFiles'
import { useNotifications } from '../notifications/NotificationProvider'
import { useEffect, useMemo, useState } from 'react'
import { useGetDocument } from '@/service-library/hooks/documents'
import { DocumentRowValue } from '@/types/documents'
import { useSearchParams } from 'react-router-dom'
import useDocumentImage from '@/services/hooks/useDocumentImage'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export type FormValues = Record<
  string,
  {
    value: string
    fromDocument: boolean
  }
>

function getValue(documentRowValues: DocumentRowValue[] = [], id: string) {
  const value =
    documentRowValues.find((row) => row.project_grid_field_id === id)
      ?.final_value || ''
  return {
    value,
    fromDocument: !!value,
  }
}

export default function SalesRabbitDemo() {
  const { uploadFiles, error } = useUploadFiles()

  const [isProcessing, setIsProcessing] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const docId = searchParams.get('docId')

  const [formValues, setFormValues] = useState<FormValues>({
    firstName: { value: '', fromDocument: false },
    lastName: { value: '', fromDocument: false },
    phone: { value: '', fromDocument: false },
    email: { value: '', fromDocument: false },
    street: { value: '', fromDocument: false },
    city: { value: '', fromDocument: false },
    state: { value: '', fromDocument: false },
    zip: { value: '', fromDocument: false },
    annualKwh: { value: '', fromDocument: false },
    annualCost: { value: '', fromDocument: false },
    JanKwh: { value: '', fromDocument: false },
    FebKwh: { value: '', fromDocument: false },
    MarKwh: { value: '', fromDocument: false },
    AprKwh: { value: '', fromDocument: false },
    MayKwh: { value: '', fromDocument: false },
    JunKwh: { value: '', fromDocument: false },
    JulKwh: { value: '', fromDocument: false },
    AugKwh: { value: '', fromDocument: false },
    SepKwh: { value: '', fromDocument: false },
    OctKwh: { value: '', fromDocument: false },
    NovKwh: { value: '', fromDocument: false },
    DecKwh: { value: '', fromDocument: false },
    JanCost: { value: '', fromDocument: false },
    FebCost: { value: '', fromDocument: false },
    MarCost: { value: '', fromDocument: false },
    AprCost: { value: '', fromDocument: false },
    MayCost: { value: '', fromDocument: false },
    JunCost: { value: '', fromDocument: false },
    JulCost: { value: '', fromDocument: false },
    AugCost: { value: '', fromDocument: false },
    SepCost: { value: '', fromDocument: false },
    OctCost: { value: '', fromDocument: false },
    NovCost: { value: '', fromDocument: false },
    DecCost: { value: '', fromDocument: false },
  })

  const { document } = useGetDocument({
    id: docId || '',
    filters: {
      fields__include:
        'created_at,created_by_user,document_rows,document_pages,document_flags,languages_ids,workflow_states_ids',
      document_rows__fields__include: 'document_row_values',
      document_pages__fields__include: 'image_urls',
      document_rows__document_row_values__fields__include:
        'document_row_id,row_value_flags',
    },
    enabled: !!docId && !isProcessing,
  })

  const [image, setImage] = useState<string | null>(null)
  const { base64Image } = useDocumentImage({
    documentId: docId as string,
    imageUrl: document?.document_pages[0]?.image_urls.full,
    imagePage: 1,
  })

  const [chartImage, setChartImage] = useState<string | null>(null)
  const { base64Image: chartBase64Image } = useDocumentImage({
    documentId: docId as string,
    imageUrl:
      document?.document_pages[document?.document_pages.length - 1]?.image_urls
        .full,
    imagePage: document?.document_pages.length || 1,
  })

  // Get rows for just the month table
  const tableRows = useMemo(
    () =>
      document?.document_rows.filter(
        ({ project_grid_id }) =>
          project_grid_id === '018d6105-9337-e5e5-78a5-32ff4e45681c',
      ) || [],
    [document?.document_rows],
  )

  useEffect(() => {
    if (base64Image) {
      setImage(`data:image/png;base64,${base64Image}`)
    }
  }, [base64Image])

  useEffect(() => {
    if (chartBase64Image) {
      setChartImage(`data:image/png;base64,${chartBase64Image}`)
    }
  }, [chartBase64Image])

  useNotifications({
    keys: [docId || undefined],
    callback: ({ action }) => {
      if (action === 'document_workflow_state_changed') {
        setIsProcessing(false)
      }
    },
  })

  useEffect(() => {
    if (error) {
      setIsProcessing(false)
    }
  }, [error])

  useEffect(() => {
    if (!document) return
    const { document_rows } = document
    const row = document_rows[0]
    if (!row) return

    const monthValues = tableRows.reduce<
      Record<string, { value: string; fromDocument: boolean }>
    >((acc, row) => {
      const monthNumber =
        row.document_row_values?.find(
          ({ project_grid_field_id }) =>
            project_grid_field_id === '018d6105-933d-046d-61e7-f76e51e35eaf',
        )?.final_value || '0'
      const kWhValue = row.document_row_values?.find(
        ({ project_grid_field_id }) =>
          project_grid_field_id === '018d6105-9347-1046-e5a6-e85bca7499cd',
      )?.final_value
      const costValue = row.document_row_values?.find(
        ({ project_grid_field_id }) =>
          project_grid_field_id === '018ae2dc-7a91-2c2e-5230-70079a56f7eb',
      )?.final_value

      const month = months[parseInt(monthNumber) - 1]

      if (kWhValue) {
        acc[`${month}Kwh`] = {
          value: parseFloat(kWhValue).toFixed(1),
          fromDocument: true,
        }
      }
      if (costValue) {
        acc[`${month}Cost`] = {
          value: parseFloat(costValue).toFixed(1),
          fromDocument: true,
        }
      }
      return acc
    }, {})

    setFormValues((current) => ({
      ...current,
      firstName: getValue(
        row.document_row_values,
        '018ae2d6-0ca2-8a7f-8955-77d6ce581a23',
      ),
      lastName: getValue(
        row.document_row_values,
        '018ae2d6-310a-a13e-16db-7ca607176e83',
      ),
      street: getValue(
        row.document_row_values,
        '018ae2d6-bf22-ed5a-89d6-337edb4acaaf',
      ),
      city: getValue(
        row.document_row_values,
        '018ae2d6-d29b-99f7-6b64-c8d906680c75',
      ),
      state: getValue(
        row.document_row_values,
        '018ae2d6-e2f7-e55e-1646-995bc84b9234',
      ),
      zip: getValue(
        row.document_row_values,
        '018ae2d6-ec8d-3721-021f-883ddbbf86fd',
      ),
      annualKwh: getValue(
        row.document_row_values,
        '018ae2d7-e3a0-def9-2c6b-2401949f1b8f',
      ),
      annualCost: getValue(
        row.document_row_values,
        '018ae2d8-05b4-45f9-b1f0-276d2c4f6c17',
      ),
      ...monthValues,
    }))
  }, [document, tableRows])

  function updateFormValue(id: string, value: string) {
    setFormValues((current) => ({
      ...current,
      [id]: {
        value,
        fromDocument: current[id].fromDocument,
      },
    }))
  }

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#f50057',
      },
      secondary: {
        main: '#222',
      },
    },
  })

  const buttonText = document ? 'Replace Utility Bill' : 'Add Utility Bill'

  const featureFlags = useFeatureFlagContext()

  if (!featureFlags.salesrabbit_demo) return null

  return (
    <ThemeProvider theme={theme}>
      {/* Have to put a paper here for it to pick up the "light" mode for some reason */}
      <Paper
        square
        sx={{
          height: '100vh',
          background: '#f7f7f7',
        }}
      >
        <AppLayout header={<SRAppBar />}>
          <Stack direction="row" sx={{ maxWidth: '100vw' }}>
            <SRDrawer />

            <Stack spacing={2} sx={{ py: 2, px: 1, flexGrow: 1 }}>
              <SRCardSection title="Utility Info">
                <Stack spacing={2}>
                  <div>
                    <FileUploadButton
                      label={isProcessing ? 'Processing...' : buttonText}
                      variant="contained"
                      disabled={isProcessing}
                      startIcon={
                        isProcessing ? (
                          <CircularProgress size={16} />
                        ) : undefined
                      }
                      onFileUpload={(files) => {
                        setIsProcessing(true)
                        uploadFiles({
                          projectId: '018ae2d6-056d-18b9-ce6a-fc225dfb460b',
                          orgId: '018ae2d2-ebb2-630e-8929-44937bce8128',
                          files,
                        }).then(([newDocument]) => {
                          setSearchParams({ docId: newDocument.id || '' })
                        })
                      }}
                    />
                    {!!error && (
                      <Typography color="error">
                        {(error as { message: string })?.message}
                      </Typography>
                    )}
                  </div>

                  <Stack direction="row" spacing={2}>
                    {!!image && (
                      <Grow in>
                        <img
                          height="300"
                          src={image}
                          style={{
                            border: 'solid 2px #ddd',
                            borderRadius: 4,
                          }}
                        />
                      </Grow>
                    )}
                  </Stack>
                </Stack>
              </SRCardSection>

              <SRCardSection title="Contact Info">
                <Grid2 container spacing={2}>
                  <Grid2 sm={6} xs={12}>
                    <SRTextField
                      label="First Name"
                      value={formValues.firstName.value}
                      fromDocument={formValues.firstName.fromDocument}
                      onChange={({ target }) =>
                        updateFormValue('firstName', target.value)
                      }
                    />
                  </Grid2>
                  <Grid2 sm={6} xs={12}>
                    <SRTextField
                      label="Last Name"
                      value={formValues.lastName.value}
                      fromDocument={formValues.lastName.fromDocument}
                      onChange={({ target }) =>
                        updateFormValue('lastName', target.value)
                      }
                    />
                  </Grid2>
                  <Grid2 sm={6} xs={12}>
                    <SRTextField
                      label="Phone"
                      value={formValues.phone.value}
                      fromDocument={formValues.phone.fromDocument}
                      onChange={({ target }) =>
                        updateFormValue('phone', target.value)
                      }
                    />
                  </Grid2>
                  <Grid2 sm={6} xs={12}>
                    <SRTextField
                      label="Email"
                      value={formValues.email.value}
                      fromDocument={formValues.email.fromDocument}
                      onChange={({ target }) =>
                        updateFormValue('email', target.value)
                      }
                    />
                  </Grid2>
                </Grid2>
              </SRCardSection>

              <SRCardSection title="Address">
                <Grid2 container spacing={2}>
                  <Grid2 sm={6} xs={12}>
                    <SRTextField
                      label="Street"
                      value={formValues.street.value}
                      fromDocument={formValues.street.fromDocument}
                      onChange={({ target }) =>
                        updateFormValue('street', target.value)
                      }
                    />
                  </Grid2>
                  <Grid2 sm={6} xs={12}>
                    <SRTextField
                      label="City"
                      value={formValues.city.value}
                      fromDocument={formValues.city.fromDocument}
                      onChange={({ target }) =>
                        updateFormValue('city', target.value)
                      }
                    />
                  </Grid2>
                  <Grid2 sm={6} xs={12}>
                    <SRTextField
                      label="State"
                      value={formValues.state.value}
                      fromDocument={formValues.state.fromDocument}
                      onChange={({ target }) =>
                        updateFormValue('state', target.value)
                      }
                    />
                  </Grid2>
                  <Grid2 sm={6} xs={12}>
                    <SRTextField
                      label="ZIP Code"
                      value={formValues.zip.value}
                      fromDocument={formValues.zip.fromDocument}
                      onChange={({ target }) =>
                        updateFormValue('zip', target.value)
                      }
                    />
                  </Grid2>
                </Grid2>
              </SRCardSection>

              <img
                src={SRTempImage}
                style={{ border: 'solid #2221', width: '100%' }}
              />

              <Box>
                <Typography
                  variant="caption"
                  component="div"
                  sx={{ opacity: 0.5 }}
                >
                  This Address supports Aurora Auto Roof
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  sx={{ opacity: 0.5 }}
                >
                  Adjust pin to exact location on map. Areas shaded in green are
                  supported by Aurora Auto Roof.
                </Typography>
              </Box>

              <SRCardSection title="Power Consumption">
                <SRPowerConsumption
                  formValues={formValues}
                  updateFormValue={updateFormValue}
                >
                  {!!chartImage && (
                    <Grow in>
                      <div>
                        <img
                          height="300"
                          src={chartImage}
                          style={{
                            border: 'solid 2px #ddd',
                            borderRadius: 4,
                          }}
                        />
                      </div>
                    </Grow>
                  )}
                </SRPowerConsumption>
              </SRCardSection>

              <Stack direction="row-reverse" spacing={2}>
                <Button variant="contained" color="primary">
                  Save
                </Button>
                <Button variant="text" color="secondary">
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </AppLayout>
      </Paper>
    </ThemeProvider>
  )
}
