import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Stack,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControl,
  Typography,
  Skeleton,
  Tooltip,
} from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetGroups } from '@/service-library/hooks/groups'
import { showErrorSnackbar } from '@/utils/snackbars'
import { FormMultiSelectCheckbox, FormTextField } from '@/components/forms'
import PermissionTooltip from '@/components/tooltip/PermissionTooltip'

type UserCoreInfoProps = {
  overlay: OverlayState
  groupsQuery: ReturnType<typeof useGetGroups>
}

export default function UserCoreInfo({
  overlay,
  groupsQuery,
}: UserCoreInfoProps) {
  const { groups = [], isLoading, isError } = groupsQuery
  const { register } = useFormContext()

  useEffect(() => {
    if (overlay.isOpen && isError) {
      overlay.close()
      showErrorSnackbar('Something went wrong. Please try again later.')
    }
  }, [isError, overlay])

  return (
    <Stack spacing={4} sx={{ px: 3 }}>
      {isLoading ? (
        <Stack spacing={2}>
          <Skeleton variant="rounded" height={30} />
          <Skeleton variant="rounded" height={30} />
          <Skeleton variant="rounded" height={30} />
          <Skeleton variant="rounded" height={30} />
        </Stack>
      ) : (
        <>
          <FormTextField
            autoFocus
            fullWidth
            label="Name"
            required
            {...register('name', {
              validate: (value) => value.trim().length > 0,
            })}
          />
          <FormTextField
            fullWidth
            type="email"
            label="Email"
            required
            {...register('email', {
              validate: (value) =>
                /^[^@ ]+@[0-9A-Za-z-]+(\.[0-9A-Za-z-]+)+$/.test(value),
            })}
          />
          <FormTextField
            fullWidth
            label="Employee ID"
            name="employee_identifier"
          />
          <FormControl sx={{ rowGap: 1 }}>
            <FormLabel>Permission Groups</FormLabel>

            <FormGroup sx={{ rowGap: 1 }}>
              {groups.map((group) => (
                <PermissionTooltip
                  canEdit={group.can_assign_users}
                  key={group.id}
                >
                  <FormMultiSelectCheckbox
                    disabled={!group.can_assign_users}
                    name="pd_groups_ids"
                    label={
                      <Stack>
                        <Typography>{group.name}</Typography>
                        <Typography color="textSecondary" variant="caption">
                          {group.description}
                        </Typography>
                      </Stack>
                    }
                    value={group.id}
                    sx={{ mt: -1, alignSelf: 'flex-start' }}
                  />
                </PermissionTooltip>
              ))}
              <Tooltip
                title="All users have these permissions."
                enterDelay={1000}
              >
                <FormControlLabel
                  label={
                    <Stack>
                      <Typography>User</Typography>
                      <Typography color="textSecondary" variant="caption">
                        Basic user permissions.
                      </Typography>
                    </Stack>
                  }
                  control={<Checkbox disabled checked={true} sx={{ mt: -1 }} />}
                  sx={{
                    alignItems: 'flex-start',
                  }}
                />
              </Tooltip>
            </FormGroup>
          </FormControl>
        </>
      )}
    </Stack>
  )
}
