import {
  purple,
  lightGreen,
  pink,
  lightBlue,
  teal,
  yellow,
  amber,
  orange,
} from '@mui/material/colors'

export default {
  execution: 'white',
  any: 'white',
  text: lightGreen['A400'],
  integer: lightBlue[300],
  decimal: teal['A200'],
  boolean: purple['A700'],
  object: pink[300],
  date: yellow['A400'],
  time: amber['A700'],
  datetime: orange['A700'],
}
